import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { numberWithCommas, getTotalDollars } from '../../../helpers/getDollars'
import 'react-table/react-table.css'
import { Grid, Col, Row, Checkbox, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CSVLink } from 'react-csv';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import windowSize from 'react-window-size';
import * as FontAwesome from 'react-icons/lib/fa';
import { customSortWithMoney, customFilter, customSort } from '../../../helpers/tableHelpers';

const printTooltip = (
    <Tooltip id="tooltip">
        <strong>Print Table</strong>
    </Tooltip>
);

const csvTooltip = (
    <Tooltip id="tooltip">
        <strong>Export to CSV</strong>
    </Tooltip>
);

const closeTooltip = (
    <Tooltip id="tooltip">
        <strong>Close Table</strong>
    </Tooltip>
);

const date = new Date();
//Components are DUMB and should only display the data that was passed from the container that it is within
class CalculatorTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,

        }
        this.printTable = this.printTable.bind(this)

    }

    printTable() {
        console.log('HOA', this.props.data.hoa)
        var tableData = [];

        tableData.push([{ text: 'Month', bold: true }, { text: 'P&I', bold: true }, { text: 'Principal', bold: true }, { text: 'Interest', bold: true }, { text: 'Balance', bold: true }]);

        this.props.data.monthBreakdown.map((month) => {
            tableData.push([
                { text: this.props.monthMap[month.month] },
                { text: '$' + numberWithCommas(month.P_I.toFixed(2)) },
                { text: '$' + numberWithCommas(month.principal.toFixed(2)) },
                { text: '$' + numberWithCommas(month.interest.toFixed(2)) },
                { text: '$' + numberWithCommas(month.balance.toFixed(2)) },
            ])
        });
        const { vfs } = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        var month = date.getMonth() + 1;
        var documentDefinition = {
            header: {
                columns: [
                    { text: 'Payment Breakdown ', style: 'title' }
                ]
            },
            content: [
                { text: 'Estimated Payment: $' + numberWithCommas(this.props.data.estimateMonthlyPayment.toFixed(2)) + '/mo', style: 'subHeader' },
                { text: 'Loan Amount: $' + numberWithCommas(this.props.data.loanAmount), style: 'subHeader' },
                { text: 'Down Payment: $' + numberWithCommas(this.props.data.downPayment), style: 'subHeader' },
                { text: 'Interest Rate: ' + numberWithCommas(this.props.data.interestRate) + ' %', style: 'subHeader' },
                { text: 'Loan Term: ' + numberWithCommas(this.props.data.loanTerm) + ' years', style: 'subHeader' },
                { text: 'Property Tax: ' + numberWithCommas(this.props.data.propertyTaxPercentage) + ' %/yr', style: 'subHeader' },
                { text: 'Homeowner\'s Insurance: ' + this.props.data.insurance + ' $/yr', style: 'subHeader' },
                { text: 'Mortgage Insurance: ' + numberWithCommas(this.props.data.pmi) + ' %/yr until LTV > 80', style: 'subHeader' },
                { text: 'HOA Dues: ' + this.props.data.hoa + ' $/yr', style: 'subHeader' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                        body: tableData
                    },
                    style: 'table'
                },

            ],
            styles: {
                table: {
                    alignment: 'center',
                    margin: [0, 20, 0, 0]
                },
                title: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 15, 0, 0]
                },
                subHeader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                subValue: {
                    bold: false,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };
        pdfMake.createPdf(documentDefinition).print();
    }

    render() {
        const data = this.props.data.monthBreakdown;
        const loanCSVHeaders = [
            'name',
            'numOfLoans',
            'totalDollars'
        ];
        return (
            <div>
                <Grid>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col>
                            <h3 style={{ float: 'left' }}>Payment Schedule</h3>
                        </Col>
                        </Row>
                        <Row>
                        <Col xsOffset={5} style={{ float: 'right' }}>
                            <ButtonGroup>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={printTooltip}>
                                    <Button bsStyle="default" onClick={this.printTable}>
                                        <span style={{ color: '#428bca', fontSize: 30 }}><FontAwesome.FaPrint /></span>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Grid>
                <hr />

                <ReactTable
                    data={data}
                    getTrProps={
                        (state, rowInfo) => {
                            var that = this;

                            if (rowInfo !== undefined) {

                                return {
                                    onClick: (e) => {
                                        that.setState({
                                            selectedRow: rowInfo.index,
                                            selectedRowInfo: rowInfo,
                                            noSelectedRows: false
                                        })
                                    },
                                    style: {
                                        background: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? '#00afec' : 'white' : 'white',
                                        color: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? 'white' : 'black' : 'black',
                                    }
                                }
                            }
                            else {
                                return {
                                    onClick: (e) => {

                                    }

                                }
                            }
                        }}
                    columns={[
                        {

                            columns: [
                                {
                                    Header: "Month",
                                    accessor: "month",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{this.props.monthMap[value]}</p>),
                                },
                                {
                                    Header: "Principal & Interest",
                                    accessor: "P_I",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{'$' + numberWithCommas(value.toFixed(2))}</p>),
                                },
                                {
                                    Header: "Principal",
                                    accessor: "principal",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{'$' + numberWithCommas(value.toFixed(2))}</p>),
                                },
                                {
                                    Header: "Interest",
                                    accessor: "interest",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{'$' + numberWithCommas(value.toFixed(2))}</p>),
                                },
                                {
                                    Header: "Balance",
                                    accessor: "balance",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{'$' + numberWithCommas(value.toFixed(2))}</p>),
                                },

                            ]
                        }
                    ]}
                    style={{
                        height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    defaultPageSize={20}
                    filterable
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSort}
                    className="-striped -highlight"
                    defaultSorted={[
                        {
                            id: "Month",
                            desc: false
                        }
                    ]}
                />
            </div>
        )
    }

}



//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        pipelineInfo: state.pipelineReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(CalculatorTable));