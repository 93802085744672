import React, { Component } from 'react';
import * as FontAwesome from 'react-icons/lib/fa';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//Components are DUMB and should only display the data that was passed from the container that it is within
class LeaderboardReportCard extends Component {
    constructor(props) {
        super();
        this.state = {
        }

    }

    render() {
        var bgColor = "";

        var previousRank = this.props.dataForRow.Rank + this.props.dataForRow.PositionChange
        var message = ""
        if (this.props.dataForRow.PositionChange === 0 || previousRank === 0) {
            bgColor = "#26bfef"
            message = "No Change in Rank"
        }
        else if (this.props.dataForRow.PositionChange > 0) {
            bgColor = "#20a65a";
            message = "Moved Up " //+ previousRank
        }
        else if (this.props.dataForRow.PositionChange < 0) {

            bgColor = "#d4412f"
            message = "Moved Down "// + previousRank
        }

        var tooltip = (
            <Tooltip id="tooltip">
                {message}
            </Tooltip>
        );
        return [
            <tr style={{ height: '100px', backgroundColor: bgColor }} key={this.props.key}>
                {(this.props.dataForRow.PositionChange > 0 && previousRank !== 0) &&
                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={tooltip}>
                        <td style={{ color: '#1a884b', fontSize: '30px', textAlign: 'right' }}><FontAwesome.FaSortAsc /></td>
                    </OverlayTrigger>}
                {(this.props.dataForRow.PositionChange === 0 || previousRank === 0) &&
                    <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={tooltip}>
                        <td style={{ color: '#219cc2', fontSize: '25px', textAlign: 'right' }}><FontAwesome.FaMinus /></td>
                    </OverlayTrigger>}
                {(this.props.dataForRow.PositionChange < 0 && previousRank !== 0) &&
                    <OverlayTrigger style={{ textAlign: 'right' }} placement="bottom" overlay={tooltip}>
                        <td style={{ color: '#9f302d', fontSize: '30px', textAlign: 'right' }}><FontAwesome.FaSortDesc /></td>
                    </OverlayTrigger>}

                <td style={{ fontWeight: 'bold', fontSize: '35px', color: '#fff', textAlign: 'left' }}>{this.props.dataForRow.Rank}</td>

                {this.props.type === 'Individual' &&
                    <td style={{ textAlign: 'left', paddingLeft: '5px', fontSize: '19px', color: '#fff' }}>
                        {this.props.dataForRow.Name}<br />
                        <span style={{ fontWeight: '500' }}>Branch {this.props.dataForRow.Branch}</span>
                    </td>
                }
                {this.props.type === 'Branch' &&
                    <td style={{ textAlign: 'left', paddingLeft: '5px', fontSize: '25px', color: '#fff', fontWeight: '500' }}>
                        Branch {this.props.dataForRow.Branch}
                    </td>
                }
                {this.props.type === 'Region' &&
                    <td style={{ textAlign: 'left', paddingLeft: '5px', fontSize: '25px', color: '#fff', fontWeight: '500' }}>
                        Region {this.props.dataForRow.Branch}
                    </td>
                }
                {this.props.displayType === 'Count' &&
                    <td style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '25px', color: '#fff' }}>{this.props.dataForRow.Count}</td>
                }
                {this.props.displayType === 'PullThroughPercentage' &&
                    <td style={{ fontWeight: 'bold', textAlign: 'left', fontSize: '16px', color: '#fff', paddingRight: '3px' }}>{this.props.dataForRow.Percentage}</td>
                }
            </tr>,
            <tr style={{ height: '10px' }} key={this.props.key + 'SPACE'}>
                <td>{/* Blank Row for Spacing */}</td>
            </tr>
        ];
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        locatorInfo: state.locatorReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardReportCard);