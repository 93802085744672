import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import DepartmentDropdown from '../../dropdowns/DepartmentDropdown';
import DepartmentPageDropdown from '../../dropdowns/DepartmentPageDropdown';
import EmployeeDropdown from '../../dropdowns/EmployeeDropdown';
import LocationDropdown from '../../dropdowns/LocationDropdown';
import TitleDropdown from '../../dropdowns/TitleDropdown';
import Dropzone from 'react-dropzone';
import * as FontAwesome from 'react-icons/lib/fa';


const addModalStylesMobile = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '10%',
        width:'90%',
        height: '90%',
        overlfow: 'scroll'
    }
};

const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '90%',
        height: '90%',
        overlfow: 'scroll'
    }
};
class EditLocationModal extends Component {
    constructor(props) {
        super();
        this.state = {
            employeeChecks: [],
            departmentName: '',
            firstPass: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        var obj = {}

        this.props.hrInfo.allLocationsData.map((location) => {
            obj[location._id] = {
                _id: location._id,
                address1: location.address1,
                address2: location.address2,
                city: location.city,
                state: location.state,
                zip: location.zip
            }
        })
        this.setState({ allLocationsObj: obj })
    }

    handleSubmit() {
        var employeeData = {
            data: {
                itInfo: {
                    linkedApps: this.props.selectedRow.itInfo.linkedApps,
                    encompassEmail: this.props.selectedRow.itInfo.encompassEmail,
                    encompassUsername: this.props.selectedRow.itInfo.encompassUsername,
                    aliasEmails: this.props.selectedRow.itInfo.aliasEmails,
                    ADUsername: this.props.selectedRow.itInfo.ADUsername,
                    ADUserGroups: this.props.selectedRow.itInfo.ADUserGroups,
                    oldEmployeeId: this.props.selectedRow.itInfo.oldEmployeeId,
                    emailDistroGroups: this.props.selectedRow.itInfo.emailDistroGroups,
                    intranetPage: this.state.intranetPage,
                    intranetPageLocation: this.state.intranetPageLocation,
                    intranetPermission: this.props.selectedRow.itInfo.intranetPermission,
                    personas: this.props.selectedRow.itInfo.personas
                },
                licensingInfo: {
                    nmls: this.props.selectedRow.licensingInfo.nmls,
                    licensedStates: this.props.selectedRow.licensingInfo.licensedStates,
                },
                employmentInfo:
                {
                    activeEmployee: this.props.selectedRow.employmentInfo.activeEmployee,
                    directReport: this.state.directReport,
                    directReportId: this.state.directReportId,
                    bio: this.state.bio,
                    website: this.state.website,
                    addToLocator: this.props.selectedRow.employmentInfo.addToLocator,
                    employmentStatus: this.props.selectedRow.employmentInfo.employmentStatus,
                    departmentNumber: this.props.selectedRow.employmentInfo.departmentNumber,
                    departmentName: this.state.departmentName,
                    title: this.state.title,
                    region: this.props.selectedRow.employmentInfo.region,
                    location: this.state.location,
                    workPhone: this.state.workPhone,
                    cellPhone: this.state.cellPhone,
                    hrAgreementInformation: this.props.selectedRow.employmentInfo.hrAgreementInformation,
                    picture:this.props.selectedRow.employmentInfo.picture
                },
                gamingInfo:
                {
                    totalPoints:this.props.selectedRow.gamingInfo.totalPoints,
                    games:this.props.selectedRow.gamingInfo.games
                },
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                userName: this.props.selectedRow.userName,
            },
            _id: this.props.selectedRow._id,
            collection: 'employees'
        };
        this.props.upsertHRData(employeeData)

        //Clears form on Submit
        this.setState({
            firstName: '',
            lastName: '',
            workPhone: '',
            departmentName: '',
            directReport: '',
            location: '',
            title: '',
            employeeID: '',
        });

        this.props.closeModal()

    }

    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                var files = that.state.files;
                var totalFileSize = that.state.totalFileSize
                totalFileSize = totalFileSize + file.size

                if (file.size > 11000000) {
                    alert('File size exceeds 10MB.')
                }
                else {

                    files[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };


                    that.setState({ files: files, totalFileSize: totalFileSize })
                }
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }
    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    clearOutModal() {

        this.props.hrInfo.allLocationsData.map((val) => {
            var tempName = 'checkbox' + val._id
            this.setState({
                [tempName]: false
            })
        })
        this.setState({
            firstName: '',
            lastName: '',
            workPhone: '',
            departmentName: '',
            directReport: '',
            location: '',
            title: '',
            employeeID: '',
            dataIsCleared: true,
            selectedRow: null
        });

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }



    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }



    render() {
        //THE CLEARDATA PROPERTY IS TRUE WHEN WE CLOSE
        if (this.props.clearData && !this.state.dataIsCleared) {
            this.clearOutModal();
        }

        var rowData = this.state.selectedRow;
        //POPULATES THE REGIONS WHEN SOMETHING CHANGED
        if (this.props.selectedRow != this.state.selectedRow && this.props.selectedRow) {
            var address = this.props.selectedRow.employmentInfo.location ? this.state.allLocationsObj[this.props.selectedRow.employmentInfo.location].address1 + ' ' + this.state.allLocationsObj[this.props.selectedRow.employmentInfo.location].address2 + ', ' + this.state.allLocationsObj[this.props.selectedRow.employmentInfo.location].city + ', ' + this.state.allLocationsObj[this.props.selectedRow.employmentInfo.location].state + ' ' + this.state.allLocationsObj[this.props.selectedRow.employmentInfo.location].zip : ''
            rowData = this.props.selectedRow;
            if (rowData) {
                this.setState({
                    selectedRow: this.props.selectedRow,
                    dataIsCleared: false,
                    _id: this.props.selectedRow._id,
                    firstName: this.props.selectedRow.firstName,
                    lastName: this.props.selectedRow.lastName,
                    //EmploymentInfo
                    directReport: this.props.selectedRow.employmentInfo.directReport,
                    directReportId: this.props.selectedRow.employmentInfo.directReportId,
                    workPhone: this.props.selectedRow.employmentInfo.workPhone,
                    cellPhone: this.props.selectedRow.employmentInfo.cellPhone,
                    website: this.props.selectedRow.employmentInfo.website,
                    bio: this.props.selectedRow.employmentInfo.bio,
                    departmentName: this.props.selectedRow.employmentInfo.departmentName ? this.props.selectedRow.employmentInfo.departmentName.split(' ').length > 1 ? this.props.selectedRow.employmentInfo.departmentName.split(' ')[1] : this.props.selectedRow.employmentInfo.departmentName : '',
                    location: this.props.selectedRow.employmentInfo.location ? this.props.selectedRow.employmentInfo.location : '',
                    locationAddress: address,
                    title: this.props.selectedRow.employmentInfo.title,
                    intranetPage: this.props.selectedRow.itInfo.intranetPage,
                    intranetPageLocation: this.props.selectedRow.itInfo.intranetPageLocation,
                    picture: this.props.selectedRow.employmentInfo.picture,


                })
            }
        }

        return (

            <div>
                {rowData &&
                    <Modal
                        isOpen={this.props.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.props.closeModal}
                        style={window.innerWidth > 768? addModalStyles : addModalStylesMobile}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>

                            <div className='try - registration - form'
                                style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                                    <span aria-hidden="false">&times;</span>
                                </button>
                                <h1 style={{ float: 'left' }}>Edit Employee</h1>
                                <br />
                                <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback' >
                                    <Grid >
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>First Name</h3>
                                                <input className='form-control' id='firstName' type='text' name='firstName' placeholder='First Name' value={this.state.firstName} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Last Name</h3>
                                                <input className='form-control' id='lastName' type='text' name='lastName' placeholder="Last Name" value={this.state.lastName} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Cell Phone Number</h3>
                                                <input className='form-control' id='cellPhone' type='text' name='cellPhone' placeholder="Phone Number" value={this.state.cellPhone} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Work Phone Number</h3>
                                                <input className='form-control' id='workPhone' type='text' name='workPhone' placeholder="Phone Number" value={this.state.workPhone} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Direct Report</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <EmployeeDropdown setEmployeeValue={(id, value) => { if (value) this.setState({ directReportId: id, directReport: value }) }} value={this.state.directReport} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3> Department</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <DepartmentDropdown setDepartmentValue={(value) => { if (value) this.setState({ departmentName: value }) }} value={this.state.departmentName} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>

                                            <Col md={6} sm={12}>
                                                <h3>Job Title</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <TitleDropdown setTitleValue={(value) => { if (value) this.setState({ title: value }) }} value={this.state.title} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Location</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={1} sm={1}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <LocationDropdown setLocationValue={(id, value) => { if (value) this.setState({ locationAddress: value, locationID: id }) }} value={this.state.locationAddress} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <h3>Employee Bio</h3>
                                                <textarea id="noter-text-area" name="bio" value={this.state.bio} onChange={this.handleInputChange}></textarea>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Website</h3>
                                                <input className='form-control' id='website' type='text' name='website' placeholder='Website' value={this.state.website} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Intranet Page</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <DepartmentPageDropdown setDepartmentValue={(value) => { if (value) { this.setState({ intranetPage: value }) } else { this.setState({ intranetPage: '' }) } }} value={this.state.intranetPage} />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col md={6} sm={12}>
                                                <h3>Intranet Page Location</h3>
                                                <input className='form-control' id='intranetPageLocation' type='text' name='intranetPageLocation' placeholder='Intranet Page Location' value={this.state.intranetPageLocation} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <br/>
                                        <Row>
                                            <Col  md={4} sm={12}>
                                            <h3></h3>
                                                <img src={this.state.picture} alt="Picture"  height="70%" width="70%"/>
                                            </Col>
                                     
                                            <Col md={8} sm={12}>
                                                <h3>Change Picture:</h3>
                                                <Dropzone style={{ paddingLeft: 40, width: window.innerWidth > 768 ?'40rem':'30rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                                    <div>
                                                        <a> <h2>Click here or Drag in new Picture</h2></a>
                                                    </div>
                                                </Dropzone>
                                            </Col>
                                        </Row>
                                    </Grid>
                                    <br />
                                    <button className='form-control btn btn-success'>Save Changes</button>
                                </form>
                                <br />

                                <div id='error'></div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(EditLocationModal);
