//Static Data
//ERROR CODES NEED TO BE GENERATED FOR THIS REDUCER/SAGA

//Initial Data
const initialState = {
    getIssueStatus:'NOT_STARTED',
    getContactsStatus: 'NOT_STARTED',
    getCodeStatus: 'NOT_STARTED',
    errorMessage: '',
    issueData: {},
    contactData: {},
    codeData: {}
  
  }
  

  
  export default (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_ISSUE_STATUS':
        return {
          ...state,
          getIssueStatus:'NOT_STARTED',
          getContactsStatus: 'NOT_STARTED',
          getCodeStatus: 'NOT_STARTED',
          errorMessage: '',
          issueData: {},
          contactData: {},
          codeData: {}
  
        }
        case 'GET_ISSUE_STARTED':
        return {
          ...state,
          getIssueStatus:'STARTED',
          
        }
        case 'GET_ISSUE_SUCCEEDED':
        return {
          ...state,
          getIssueStatus:'SUCCEEDED',
          issueData: action.issueData
        }
        case 'GET_ISSUE_FAILED':
        return {
          ...state,
          getIssueStatus:'FAILED'
        }
        case 'RESET_CONTACTS_STATUS':
        return {
          ...state,
          getContactsStatus:'NOT_STARTED',
          errorMessage: '',
          contactData: {}
  
        }
        case 'GET_CONTACTS_STARTED':
        return {
          ...state,
          getContactsStatus:'STARTED',
          
        }
        case 'GET_CONTACTS_SUCCEEDED':
        return {
          ...state,
          getContactsStatus:'SUCCEEDED',
          contactData: action.contactData
        }
        case 'GET_CONTACTS_FAILED':
        return {
          ...state,
          getContactsStatus:'FAILED'
        }
        case 'RESET_CODES_STATUS':
        return {
          ...state,
          getCodeStatus:'NOT_STARTED',
          errorMessage: '',
          codeData: {}
  
        }
        case 'GET_CODES_STARTED':
        return {
          ...state,
          getCodeStatus:'STARTED',
          
        }
        case 'GET_CODES_SUCCEEDED':
        return {
          ...state,
          getCodeStatus:'SUCCEEDED',
          codeData: action.codeData
        }
        case 'GET_CODES_FAILED':
        return {
          ...state,
          getCodeStatus:'FAILED'
        }
        case 'RESET_ADD_ISSUE_STATUS':
        return {
          ...state,
          addIssueStatus:'NOT_STARTED',
          errorMessage: '',
          issueData: {}
  
        }
        case 'ADD_ISSUE_STARTED':
        return {
          ...state,
          addIssueStatus:'STARTED',
          
        }
        case 'ADD_ISSUE_SUCCEEDED':
        return {
          ...state,
          addIssueStatus:'SUCCEEDED',
          issueData: action.issueData
        }
        case 'ADD_ISSUE_FAILED':
        return {
          ...state,
          addIssueStatus:'FAILED'
        }
        case 'RESET_RESOLVE_ISSUE_STATUS':
        return {
          ...state,
          resolveIssueStatus:'NOT_STARTED',
          errorMessage: '',
          issueData: {}
  
        }
        case 'RESOLVE_ISSUE_STARTED':
        return {
          ...state,
          resolveIssueStatus:'STARTED',
          
        }
        case 'RESOLVE_ISSUE_SUCCEEDED':
        return {
          ...state,
          resolveIssueStatus:'SUCCEEDED',
          issueData: action.issueData
        }
        case 'RESOLVE_ISSUE_FAILED':
        return {
          ...state,
          resolveIssueStatus:'FAILED'
        }
        case 'GET_ISSUE_TOKEN_FAILED':
        return {
          ...state,
          getIssueStatus:'TOKEN_FAILED',
          errorMessage: action.errorMessage 
  
        }
        case 'GET_ISSUE_TIMEOUT_FAILED':
        return {
          ...state,
          getIssueStatus:'TIMEOUT_FAILED',
          errorMessage: action.errorMessage 
  
        }
        
        
      default:
        return state
    }
  }
  