var date = new Date();
var month = date.getMonth() + 1;
var day = date.getDate();
var year = date.getFullYear();

function removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
        if (unique_array.indexOf(arr[i]) == -1) {
            unique_array.push(arr[i])
        }
    }
    return unique_array
}


function dateCheck(from, to, check) {

    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if ((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}



function processDataForDate(fromDate, toDate, dataToProcess, field) {
    var dataToReturn = {}

    if (dataToProcess.length > 0) {
        dataToProcess.map((loan) => {
            if (loan[field]) {

                var loanDate = loan[field];

                //Convert SQL DateTime To JavaScript DateTime
                if (dateCheck(fromDate, toDate, loanDate)) {
                    //Check to see if loan is already in object if it is then we just append to the array if not then create an array
                    if (loan['LoanAmount']) {
                        if (Object.keys(dataToReturn).indexOf(loanDate) != -1) {

                            dataToReturn[loanDate].push(loan)
                        }
                        else {
                            dataToReturn[loanDate] = [loan];
                        }
                    }
                    else {
                        if (Object.keys(dataToReturn).indexOf(loanDate) != -1) {
                            loan.LoanAmount = 0;
                            dataToReturn[loanDate].push(loan)
                        }
                        else {
                            loan.LoanAmount = 0;
                            dataToReturn[loanDate] = [loan];
                        }
                    }
                }
            }
        });


    }
    return dataToReturn;

}

function processDataForDateTrends(fromDate, toDate, dataToProcess, field) {
    var dataToReturn = {}

    if (dataToProcess.length > 0) {
        dataToProcess.map((loan) => {
            if (loan['saveState'][0]['loanData'][field]) {
                var loanDate = loan['saveState'][0]['loanData'][field];
                //Convert SQL DateTime To JavaScript DateTime
                if (dateCheck(fromDate, toDate, loanDate)) {

                    //Check to see if loan is already in object if it is then we just append to the array if not then create an array
                    if (Object.keys(dataToReturn).indexOf(loanDate) != -1) {

                        dataToReturn[loanDate].push(loan)
                    }
                    else {
                        dataToReturn[loanDate] = [loan];
                    }

                }
            }
        });


    }
    return dataToReturn;

}

function getFormattedSugar(sugarDate) {
    var mMonth = sugarDate.getMonth() + 1;
    var mDay = sugarDate.getDate() < 10? '0'+sugarDate.getDate():sugarDate.getDate();
    var mYear = sugarDate.getFullYear();
    var mySugarDate = mMonth + '/' + mDay + '/' + mYear;
    return mySugarDate;
}

function getFirstOfMonthFormattedSugar(sugarDate) {
    var mMonth = sugarDate.getMonth() + 1;
    var mDay = sugarDate.getDate() < 10? '0'+sugarDate.getDate():sugarDate.getDate();
    var mYear = sugarDate.getFullYear();
    var mySugarDate = mMonth + '/' + '1' + '/' + mYear;
    return mySugarDate;
}

function getFirstOfYearFormattedSurgar(sugarDate) {
    var mMonth = sugarDate.getMonth() + 1;
    var mDay = sugarDate.getDate() < 10? '0'+sugarDate.getDate():sugarDate.getDate();
    var mYear = sugarDate.getFullYear();
    var mySugarDate = '1' + '/' + '1' + '/' + mYear;
    return mySugarDate;
}

function getMilliseconds(dateString) {
    var date = new Date(dateString);
    return date.getTime();
}
//Should produce data for a graph that has the current date and the past week
export function getTodayRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFormattedSugar(Sugar.Date.create('1 Week ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);

    //Determine if key in the dataInRange object is the 1,2,3,4,5,6,7 day of the range
    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day
    var tMin6 = getFormattedSugar(Sugar.Date.create('6 Days ago'));
    var tMin5 = getFormattedSugar(Sugar.Date.create('5 Days ago'));
    var tMin4 = getFormattedSugar(Sugar.Date.create('4 Days ago'));
    var tMin3 = getFormattedSugar(Sugar.Date.create('3 Days ago'));
    var tMin2 = getFormattedSugar(Sugar.Date.create('2 Days ago'));
    var tMin1 = getFormattedSugar(Sugar.Date.create('1 Day ago'));

    var dataForDateRange = {}
    dataForDateRange[tMin6] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin5] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin4] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin3] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[currentDate] = {

        totalAmountForRange: 0, loans: []
    }

    Object.keys(dataInRange).map((date) => {
        if (Object.keys(dataForDateRange).indexOf(date) != -1) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[date]['totalAmountForRange'] = dataForDateRange[date]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[date]['loans'].push(loanData);
            })
        }
    })

    return dataForDateRange;

}


//Should produce data for a graph that has the current date and the past week
export function getWeekRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFormattedSugar(Sugar.Date.create('4 Weeks ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);


    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day
    var tMin4 = getFormattedSugar(Sugar.Date.create('4 Weeks ago'));
    var tMin3 = getFormattedSugar(Sugar.Date.create('3 Weeks ago'));
    var tMin2 = getFormattedSugar(Sugar.Date.create('2 Weeks ago'));
    var tMin1 = getFormattedSugar(Sugar.Date.create('1 Week ago'));

    var dataForDateRange = {}

    dataForDateRange[tMin3] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[currentDate] = {

        totalAmountForRange: 0, loans: []
    }

    Object.keys(dataInRange).map((date) => {

        if (dateCheck(tMin4, tMin3, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin3]['totalAmountForRange'] = dataForDateRange[tMin3]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin3]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin3, tMin2, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, currentDate, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[currentDate]['totalAmountForRange'] = dataForDateRange[currentDate]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[currentDate]['loans'].push(loanData);
            })
        }

    })

    return dataForDateRange;

}

//Should produce data for a graph that has the current date and the past week
export function getMonthRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Months ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);


    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day


    var tMin2 = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Months ago'));
    var tMin1 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of last month'));
    var tMin0 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of this month'));

    var dataForDateRange = {}


    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin0] = {

        totalAmountForRange: 0, loans: []
    }

    Object.keys(dataInRange).map((date) => {

        if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, tMin0, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin0, currentDate, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin0]['totalAmountForRange'] = dataForDateRange[tMin0]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin0]['loans'].push(loanData);
            })
        }


    })

    return dataForDateRange;

}

//Should produce data for a graph that has the current date and the past week
export function get6MonthRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFirstOfMonthFormattedSugar(Sugar.Date.create('5 Months ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);


    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day

    var tMin5 = getFirstOfMonthFormattedSugar(Sugar.Date.create('5 Months ago'));
    var tMin4 = getFirstOfMonthFormattedSugar(Sugar.Date.create('4 Months ago'));
    var tMin3 = getFirstOfMonthFormattedSugar(Sugar.Date.create('3 Months ago'));
    var tMin2 = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Months ago'));
    var tMin1 = getFirstOfMonthFormattedSugar(Sugar.Date.create('1 Months ago'));
    var tMin0 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of this month'));
    var dataForDateRange = {}


    dataForDateRange[tMin5] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin4] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin3] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin0] = {

        totalAmountForRange: 0, loans: []
    }

    Object.keys(dataInRange).map((date) => {

        if (dateCheck(tMin5, tMin4, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin5]['totalAmountForRange'] = dataForDateRange[tMin5]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin5]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin4, tMin3, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin4]['totalAmountForRange'] = dataForDateRange[tMin4]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin4]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin3, tMin2, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin3]['totalAmountForRange'] = dataForDateRange[tMin3]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin3]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, tMin0, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin0, currentDate, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin0]['totalAmountForRange'] = dataForDateRange[tMin0]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin0]['loans'].push(loanData);
            })
        }

    })

    return dataForDateRange;

}

//Should produce data for a graph that has the current date and the past week
export function getYearRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFirstOfMonthFormattedSugar(Sugar.Date.create('11 Months ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);


    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day
    var tMin11 = getFirstOfMonthFormattedSugar(Sugar.Date.create('11 Months ago'));
    var tMin10 = getFirstOfMonthFormattedSugar(Sugar.Date.create('10 Months ago'));
    var tMin9 = getFirstOfMonthFormattedSugar(Sugar.Date.create('9 Months ago'));
    var tMin8 = getFirstOfMonthFormattedSugar(Sugar.Date.create('8 Months ago'));
    var tMin7 = getFirstOfMonthFormattedSugar(Sugar.Date.create('7 Months ago'));
    var tMin6 = getFirstOfMonthFormattedSugar(Sugar.Date.create('6 Months ago'));
    var tMin5 = getFirstOfMonthFormattedSugar(Sugar.Date.create('5 Months ago'));
    var tMin4 = getFirstOfMonthFormattedSugar(Sugar.Date.create('4 Months ago'));
    var tMin3 = getFirstOfMonthFormattedSugar(Sugar.Date.create('3 Months ago'));
    var tMin2 = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Months ago'));
    var tMin1 = getFirstOfMonthFormattedSugar(Sugar.Date.create('1 Months ago'));
    var tMin0 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of this month'));

    var dataForDateRange = {}
    dataForDateRange[tMin11] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin10] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin9] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin8] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin7] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin6] = {

        totalAmountForRange: 0, loans: []
    }

    dataForDateRange[tMin5] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin4] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin3] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin0] = {

        totalAmountForRange: 0, loans: []
    }

    Object.keys(dataInRange).map((date) => {

        if (dateCheck(tMin11, tMin10, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin11]['totalAmountForRange'] = dataForDateRange[tMin11]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin11]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin10, tMin9, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin10]['totalAmountForRange'] = dataForDateRange[tMin10]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin10]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin9, tMin8, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin9]['totalAmountForRange'] = dataForDateRange[tMin9]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin9]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin8, tMin7, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin8]['count'] = dataForDateRange[tMin8]['count'] + 1;
                dataForDateRange[tMin8]['totalAmountForRange'] = dataForDateRange[tMin8]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin8]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin7, tMin6, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin7]['totalAmountForRange'] = dataForDateRange[tMin7]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin7]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin6, tMin5, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin6]['totalAmountForRange'] = dataForDateRange[tMin6]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin6]['loans'].push(loanData);
            })
        }

        else if (dateCheck(tMin5, tMin4, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin5]['totalAmountForRange'] = dataForDateRange[tMin5]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin5]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin4, tMin3, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin4]['count'] = dataForDateRange[tMin4]['count'] + 1;
                dataForDateRange[tMin4]['totalAmountForRange'] = dataForDateRange[tMin4]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin4]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin3, tMin2, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin3]['totalAmountForRange'] = dataForDateRange[tMin3]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin3]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, tMin0, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin0, currentDate, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin0]['totalAmountForRange'] = dataForDateRange[tMin0]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin0]['loans'].push(loanData);
            })
        }


    })
    return dataForDateRange;

}

//Should produce data for a graph that has the current date and the past week
export function get2YearRange(currentDate, dataToProcess, field) {
    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Years ago'))


    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(mySugarDate, currentDate, dataToProcess, field);


    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day



    var tMin2 = getFirstOfMonthFormattedSugar(Sugar.Date.create('2 Years ago'));
    var tMin1 = getFirstOfMonthFormattedSugar(Sugar.Date.create('1 Year ago'));
    var tMin0 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of this year'));
    var dataForDateRange = {}




    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin0] = {

        totalAmountForRange: 0, loans: []
    }


    Object.keys(dataInRange).map((date) => {
        if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, tMin0, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin0, currentDate, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin0]['totalAmountForRange'] = dataForDateRange[tMin0]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin0]['loans'].push(loanData);
            })
        }

    })

    return dataForDateRange;

}

//Should produce data for a graph that has the current date and the past week
export function get5YearRange(currentDate, dataToProcess, field) {

    var dataInRange = {};
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')
    var mySugarDate = getFirstOfYearFormattedSurgar(Sugar.Date.create('5 Years ago'))



    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDateTrends(mySugarDate, currentDate, dataToProcess, field);




    //If contained we will create an object with the total number of loans and $totalAmountForRanges for that day


    var tMin5 = getFirstOfYearFormattedSurgar(Sugar.Date.create('5 Years ago'));
    var tMin4 = getFirstOfYearFormattedSurgar(Sugar.Date.create('4 Years ago'));
    var tMin3 = getFirstOfYearFormattedSurgar(Sugar.Date.create('3 Years ago'));
    var tMin2 = getFirstOfYearFormattedSurgar(Sugar.Date.create('2 Years ago'));
    var tMin1 = getFirstOfYearFormattedSurgar(Sugar.Date.create('1 Year ago'));
    var tMin0 = getFirstOfMonthFormattedSugar(Sugar.Date.create('First Day of this year'));
    var dataForDateRange = {}


    dataForDateRange[tMin5] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin4] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin3] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin2] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin1] = {

        totalAmountForRange: 0, loans: []
    }
    dataForDateRange[tMin0] = {

        totalAmountForRange: 0, loans: []
    }



    Object.keys(dataInRange).map((date) => {
        if (dateCheck(tMin5, tMin4, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin5]['totalAmountForRange'] = dataForDateRange[tMin5]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin5]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin4, tMin3, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin4]['totalAmountForRange'] = dataForDateRange[tMin4]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin4]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin3, tMin2, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin3]['totalAmountForRange'] = dataForDateRange[tMin3]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin3]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin2, tMin1, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin2]['totalAmountForRange'] = dataForDateRange[tMin2]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin2]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin1, tMin0, date)) {
            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin1]['totalAmountForRange'] = dataForDateRange[tMin1]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin1]['loans'].push(loanData);
            })
        }
        else if (dateCheck(tMin0, currentDate, date)) {

            dataInRange[date].map((loanData) => {
                dataForDateRange[tMin0]['totalAmountForRange'] = dataForDateRange[tMin0]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                dataForDateRange[tMin0]['loans'].push(loanData);
            })
        }

    })
    return dataForDateRange;

}


///FOR REPORTING VIEW


export function getReportByDayRange(startDate, endDate, dataToProcess, filterID) {
    var dataInRange = {};
    var dataForDateRange = {}
    //Get Date Lower Limit of range
    var Sugar = require('sugar-date')

    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(startDate, endDate, dataToProcess, filterID);

    Object.keys(dataInRange).map((date) => {
        //Initialize Value for object that will return
        dataForDateRange[date] = {}
        dataForDateRange[date]['loans'] = []
        dataForDateRange[date]['totalAmountForRange'] = 0

        dataInRange[date].map((loanData) => {
            dataForDateRange[date]['totalAmountForRange'] = dataForDateRange[date]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
            dataForDateRange[date]['loans'].push(loanData);
        })
    })

    return dataForDateRange;

}

export function getReportByIntervalRange(startDate, endDate, dataToProcess, filterID, interval) {
    var Sugar = require('sugar-date')
    var dataInRange = {};
    var dataForDateRange = {}
    //Ok so we are goign to use milliseconds to find the week intervals between the start and end date
    const intervalInMilli = {
        'Week': 604800000,
        'Month':
        {
            '1': 2592000000,
            '2': 2332800000,
            '2L': 2419200000,
            '3': 2419200000,
            '4': 2505600000,
            '5': 2592000000,
            '6': 2505600000,
            '7': 2592000000,
            '8': 2592000000,
            '9': 2505600000,
            '10': 2592000000,
            '11': 2505600000,
            '12': 2592000000

        },
        'Year': {
            'Non-Leap': 31536000000,
            'Leap': 31622400000
        }
    };
    var startDateInMilli = getMilliseconds(startDate);
    var endDateInMilli = getMilliseconds(endDate);
    var millisecondCounter = endDateInMilli;
    var intervals = [endDate];

    //Populate array with dates for each interval desired

    if (interval === 'Week') {
        while (millisecondCounter > startDateInMilli) {
            millisecondCounter = millisecondCounter - intervalInMilli[interval];
            var d = new Date();
            d.setTime(millisecondCounter);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var year = d.getFullYear()
            if (millisecondCounter > startDateInMilli) {
                intervals.push(month + '/' + day + '/' + year)
            }
            else {
                intervals.push(startDate)
            }
        }
    }
    else if (interval === 'Month') {

        //If End Date is not the first of that month add first day of that month to the interval list
        if (endDate.split('/')[1] !== '1') {
            intervals.push(endDate.split('/')[0] + '/1/' + endDate.split('/')[2])
            //Get last day of previous month
            var lastDayOfPrevMonth = new Date(endDate.split('/')[0] + '/1/' + endDate.split('/')[2]);
            lastDayOfPrevMonth.setHours(-1);
            var prevMonth = lastDayOfPrevMonth.getMonth() + 1;
            var prevDate = lastDayOfPrevMonth.getDate();
            var prevYear = lastDayOfPrevMonth.getFullYear();
            millisecondCounter = getMilliseconds(prevMonth + '/' + prevDate + '/' + prevYear);
        }

        while (millisecondCounter > startDateInMilli) {
            var prevD = new Date();
            prevD.setTime(millisecondCounter);
            //If it is a Leap Year and it is Feb then we need to adjust days
            var isLeapMonth = (prevD.getFullYear() % 4 === 0 && prevD.getMonth() === 1) ? '2L' : prevD.getMonth() + 1;
            millisecondCounter = millisecondCounter - intervalInMilli[interval][prevD.getMonth() + 1];
            var d = new Date();
            d.setTime(millisecondCounter);
            d.setDate(1)
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var year = d.getFullYear();

            if (millisecondCounter > startDateInMilli) {
                intervals.push(month + '/' + day + '/' + year)
            }
            else {
                intervals.push(startDate)
            }
        }
    }
    else if (interval === 'Year') {
        //If End Date is not the first of that year add first day of that year to the interval list
        if (endDate.split('/')[0] !== '1' && endDate.split('/')[1] !== '1') {
            intervals.push('1/1/' + endDate.split('/')[2])
            //Get last day of previous month
            var lastDayOfPrevMonth = new Date('1/1/' + endDate.split('/')[2]);
            var prevYear = lastDayOfPrevMonth.getFullYear();
            millisecondCounter = getMilliseconds('1/1/' + prevYear);
        }

        while (millisecondCounter > startDateInMilli) {
            var prevD = new Date();
            prevD.setTime(millisecondCounter);
            var isLeap = prevD.getFullYear() % 4 !== 0 ? 'Non-Leap' : 'Leap';
            millisecondCounter = millisecondCounter - intervalInMilli[interval][isLeap];
            var d = new Date();
            d.setTime(millisecondCounter);
            d.setMonth(0)
            d.setDate(1)
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var year = d.getFullYear();

            if (millisecondCounter > startDateInMilli) {
                intervals.push(month + '/' + day + '/' + year)
            }
            else {
                intervals.push(startDate)
            }
        }
    }

    var intervalsToReturn = [];
    intervals.map((interval) => {
        if (getMilliseconds(interval) >= getMilliseconds(startDate)) {
            intervalsToReturn.push(interval)
        }
    })
    intervals = removeDuplicates(intervalsToReturn);

    //Initialize the data that is going to be returned 
    for (var i = 0; i < intervals.length; i++) {

        var startOfRange = '';

        if (intervals[i + 1] && getMilliseconds(intervals[i + 1]) < startDateInMilli) {
            startOfRange = startDate;

        }
        else {
            startOfRange = intervals[i + 1];
        }
        if (startOfRange && (startOfRange !== intervals[i])) {
            dataForDateRange[startOfRange + '-' + intervals[i]] = {}
            dataForDateRange[startOfRange + '-' + intervals[i]]['loans'] = []
            dataForDateRange[startOfRange + '-' + intervals[i]]['totalAmountForRange'] = 0
        }


    }
    //Loop Through all loans to see if it falls in range
    dataInRange = processDataForDate(startDate, endDate, dataToProcess, filterID);




    Object.keys(dataInRange).map((date) => {
        //Loop through the data to determine which interval the date should be put into 
        for (var i = 0; i < intervals.length; i++) {
            var startOfRange = '';

            if (intervals[i + 1] && getMilliseconds(intervals[i + 1]) < startDateInMilli) {
                startOfRange = startDate;

            }
            else {
                startOfRange = intervals[i + 1];

            }


            if (startOfRange && dateCheck(intervals[i + 1], intervals[i], date)) {

                dataInRange[date].map((loanData) => {
                    dataForDateRange[startOfRange + '-' + intervals[i]]['totalAmountForRange'] = dataForDateRange[startOfRange + '-' + intervals[i]]['totalAmountForRange'] + parseInt(loanData['LoanAmount']);
                    dataForDateRange[startOfRange + '-' + intervals[i]]['loans'].push(loanData);
                })
                break;
            }




        }
    })

    //Remove Key that is out of range so that we do not have excess data in the graph
    for (var i = 0; i < intervals.length; i++) {
        var startOfRange = '';

        if (intervals[i + 1] && getMilliseconds(intervals[i + 1]) < startDateInMilli) {
            startOfRange = startDate;
        }
        else {
            startOfRange = intervals[i + 1];
        }

        if (!dateCheck(startDate, endDate, intervals[i])) {
            delete dataForDateRange[startOfRange + '-' + intervals[i]];
        }


    }

    return dataForDateRange;

}


////////////////////////////////////////////////////////////////
//Loan Comparision
////////////////////////////////////////////////////////////////

// export async function compareRevisions(leftDoc, rightDoc) {
//     var sameFields = [];
//     var diffFields = [];


//     if (await convertDate(lefttDoc.loanData.ApplicationDate) != await convertDate(rightDoc.loanData.ApplicationDate)) {
//         diffFields.push('ApplicationDate')
//     }
//     else {
//         sameFields.push('ApplicationDate')
//     }
//     if (await convertDate(leftDoc.loanData.PreApplicationDate) != await convertDate(rightDoc.loanData.PreApplicationDate)) {
//         diffFields.push('PreApplicationDate')
//     } else {
//        sameFields.push('PreApplicationDate')
//     }
//     if (await convertDate(leftDoc.loanData.BuySideLockedDate) != await convertDate(rightDoc.loanData.BuySideLockedDate)) {
//         diffFields.push('BuySideLockedDate')
//     } else {
//         sameFields.push('BuySideLockedDate')
//     }
//     if (await convertDate(leftDoc.loanData.ShippingDate)!= await convertDate(rightDoc.loanData.ShippingDate)) {
//         diffFields.push('ShippingDate')
//     } else {
//         sameFields.push('ShippingDate')
//     }
//     if (await convertDate(leftDoc.loanData.UWApprovalDate) != await convertDate(rightDoc.loanData.UWApprovalDate)) {
//         diffFields.push('UWApprovalDate')
//     } else {
//         sameFields.push('UWApprovalDate')
//     }
//     if (await convertDate(leftDoc.loanData.UWSubmittedDate) != await convertDate(rightDoc.loanData.UWSubmittedDate)) {
//         diffFields.push('UWSubmitted')
//     } else {
//         sameFields.push('UWSubmitted')
//     }
//     if (await convertDate(leftDoc.loanData.UWCreditApprovalDate) != await convertDate(rightDoc.loanData.UWCreditApprovalDate)) {
//         diffFields.push('UWCreditApprovalDate')

//     } else {
//         sameFields.push('UWCreditApprovalDate')
//     }
//     if (await convertDate(leftDoc.loanData.UWCleartoCloseDate) != await convertDate(rightDoc.loanData.UWCleartoCloseDate)) {
//         diffFields.push('UWCleartoCloseDate')
//     } else {
//         sameFields.push('UWCleartoCloseDate')
//     }
//     if (await convertDate(leftDoc.loanData.FundsReleasedDate) != await convertDate(rightDoc.loanData.FundsReleasedDate)) {
//         diffFields.push('FundsReleasedDate')
//     } else {
//         sameFields.push('FundsReleasedDate')
//     }
//     if (await convertDate(leftDoc.loanData.RateLockExpires) != await convertDate(rightDoc.loanData.RateLockExpires)) {
//         diffFields.push('RateLockExpires')
//     } else {
//         sameFields.push('RateLockExpires')
//     }
//     if (await convertDate(leftDoc.loanData.EstClosingDate) != await convertDate(rightDoc.loanData.EstClosingDate)) {
//         diffFields.push('EstClosingDate')
//     } else {
//         sameFields.push('EstClosingDate')
//     }
//     if (leftDoc.loanData.TeamColor != rightDoc.loanData.TeamColor) {
//         diffFields.push('TeamColor')
//     } else {
//         sameFields.push('TeamColor')
//     }
//     if (leftDoc.loanData.Branch != rightDoc.loanData.Branch) {
//         diffFields.push('Branch')
//     } else {
//         sameFields.push('Branch')
//     }
//     if (leftDoc.loanData.UnderwriterName != rightDoc.loanData.UnderwriterName) {
//         diffFields.push('UnderwriterName')
//     } else {
//         sameFields.push('UnderwriterName')
//     }
//     if (leftDoc.loanData.ShipperName != rightDoc.loanData.ShipperName) {
//         diffFields.push('ShipperName')
//     } else {
//         sameFields.push('ShipperName')
//     }
//     //  PUT THIS BACK IN LATER
//     // if ('Fields.1855' != rightDoc.loanData.CloserName) {
//     //   fieldChanged = 1;
//     //   diffFields.push('CloserName 1')
//     //   console.log('Fields.1855']);
//     //   console.log(rightDoc.loanData.CloserName)
//     //       }      else{          sameFields.push('')
//     // }
//     if (leftDoc.loanData.ProcessorAssistantName != rightDoc.loanData.ProcessorAssistantName) {
//         diffFields.push('ProcessorAssistantName')
//     } else {
//         sameFields.push('ProcessorAssistantName')
//     }
//     if (leftDoc.loanData.SubjectPropertStreet != rightDoc.loanData.SubjectPropertStreet) {
//         diffFields.push('SubjectPropertStreet')
//     } else {
//         sameFields.push('SubjectPropertStreet')
//     }
//     if (leftDoc.loanData.SubjectPropertCity != rightDoc.loanData.SubjectPropertCity) {
//         diffFields.push('SubjectPropertCity')
//     } else {
//         sameFields.pushSubjectPropertCity
//     }
//     if (leftDoc.loanData.SubjectPropertState != rightDoc.loanData.SubjectPropertState) {
//         diffFields.push('SubjectPropertState')
//     } else {
//         sameFields.push('SubjectPropertState')
//     }
//     if (leftDoc.loanData.SubjectPropertZip != rightDoc.loanData.SubjectPropertZip) {

//         diffFields.push('SubjectPropertZip')
//     } else {
//         sameFields.push('SubjectPropertZip')
//     }
//     if (leftDoc.loanData.BorrowerFirstName != rightDoc.loanData.BorrowerFirstName) {

//         diffFields.push('BorrowerFirstName')

//     } else {
//         sameFields.push('BorrowerFirstName')
//     }
//     if (leftDoc.loanData.BorrowerLastName != rightDoc.loanData.BorrowerLastName) {

//         diffFields.push('BorrowerLastName')

//     } else {
//         sameFields.push('BorrowerLastName')
//     }
//     if (leftDoc.loanData.BorrowerMiddleName != rightDoc.loanData.BorrowerMiddleName) {

//         diffFields.push('BorrowerMiddleName')
//     } else {
//         sameFields.push('BorrowerMiddleName')
//     }
//     if (leftDoc.loanData.BorrowerSuffixName != rightDoc.loanData.BorrowerSuffixName) {
//         diffFields.push('BorrowerSuffixName')
//     } else {
//         sameFields.push('BorrowerSuffixName')
//     }
//     if (leftDoc.loanData.BorrowerEmail != rightDoc.loanData.BorrowerEmail) {
//         diffFields.push('BorrowerEmail')
//     } else {
//         sameFields.push('BorrowerEmail')
//     }
//     if (leftDoc.loanData.BorrowerPhone != rightDoc.loanData.BorrowerPhone) {
//         diffFields.push('BorrowerPhone')
//     } else {
//         sameFields.push('BorrowerPhone')
//     }
//     if (leftDoc.loanData.CoBorrowerFirstName != rightDoc.loanData.CoBorrowerFirstName) {
//         diffFields.push('CoBorrowerFirstName')
//     } else {
//         sameFields.push('CoBorrowerFirstName')
//     }
//     if (leftDoc.loanData.CoBorrowerLastName != rightDoc.loanData.CoBorrowerLastName) {
//         diffFields.push('CoBorrowerLastName')
//     } else {
//         sameFields.push('CoBorrowerLastName')
//     }
//     if (leftDoc.loanData.CoBorrowerEmail != rightDoc.loanData.CoBorrowerEmail) {
//         diffFields.push('CoBorrowerEmail')
//     } else {
//         sameFields.push('CoBorrowerEmail')
//     }
//     if (leftDoc.loanData.CoBorrowerPhon != rightDoc.loanData.CoBorrowerPhone) {
//         diffFields.push('CoBorrowerPhone')
//     } else {
//         sameFields.push('CoBorrowerPhone')
//     }
//     if (leftDoc.loanData.CoBorrowerMiddleName != rightDoc.loanData.CoBorrowerMiddleName) {
//         diffFields.push('CoBorrowerMiddleName')
//     } else {
//         sameFields.push('CoBorrowerMiddleName')
//     }
//     if (leftDoc.loanData.CoBorrowerSuffixName != rightDoc.loanData.CoBorrowerSuffixName) {
//         diffFields.push('CoBorrowerSuffixName')
//     } else {
//         sameFields.push('CoBorrowerSuffixName')
//     }
//     if (leftDoc.loanData.LoanOfficerName != rightDoc.loanData.LoanOfficerName) {
//         diffFields.push('LoanOfficerName')
//     } else {
//         sameFields.push('LoanOfficerName')
//     }
//     if (leftDoc.loanData.LoanOfficerEmail != rightDoc.loanData.LoanOfficerEmail) {
//         diffFields.push('LoanOfficerEmail')
//     } else {
//         sameFields.push('LoanOfficerEmail')
//     }
//     if (leftDoc.loanData.LoanOfficerPhone != rightDoc.loanData.LoanOfficerPhone) {
//         diffFields.push('LoanOfficerPhone')
//     } else {
//         sameFields.push('LoanOfficerPhone')
//     }
//     if (leftDoc.loanData.ProcessorName != rightDoc.loanData.ProcessorName) {
//         diffFields.push('ProcessorName')
//     } else {
//         sameFields.push('ProcessorName')
//     }
//     if (leftDoc.loanData.ProcessorEmail != rightDoc.loanData.ProcessorEmail) {
//         diffFields.push('ProcessorEmail')
//     } else {
//         sameFields.push('ProcessorEmail')
//     }
//     if (leftDoc.loanData.ProcessorPhone != rightDoc.loanData.ProcessorPhone) {
//         diffFields.push('ProcessorPhone')
//     } else {
//         sameFields.push('ProcessorPhone')
//     }
//     if (leftDoc.loanData.SellerAgentName != rightDoc.loanData.SellerAgentName) {
//         diffFields.push('SellerAgentName')
//     } else {
//         sameFields.push('SellerAgentName')
//     }
//     if (leftDoc.loanData.SellerAgentEmail != rightDoc.loanData.SellerAgentEmail) {
//         diffFields.push('SellerAgentEmail')
//     } else {
//         sameFields.push('SellerAgentEmail')
//     }
//     if (leftDoc.loanData.SellerAgentPhone != rightDoc.loanData.SellerAgentPhone) {
//         diffFields.push('SellerAgentPhone')
//     } else {
//         sameFields.push('SellerAgentPhone')
//     }
//     if (leftDoc.loanData.BuyerAgentName != rightDoc.loanData.BuyerAgentName) {
//         diffFields.push('BuyerAgentName')
//     } else {
//         sameFields.push('BuyerAgentName')
//     }
//     if (leftDoc.loanData.BuyerAgentEmail != rightDoc.loanData.BuyerAgentEmail) {
//         diffFields.push('BuyerAgentEmail')
//     } else {
//         sameFields.push('BuyerAgentEmail')
//     }
//     if (rightDoc.loanData.BuyerAgentPhone != rightDoc.loanData.BuyerAgentPhone) {
//         diffFields.push('BuyerAgentPhone')
//     } else {
//         sameFields.push('BuyerAgentPhone')
//     }
//     if (rightDoc.loanData.LoanPurpose != rightDoc.loanData.LoanPurpose) {
//         diffFields.push('LoanPurpose')
//     } else {
//         sameFields.push('LoanPurpose')
//     }
//     if ('Fields.1172' != rightDoc.loanData.LoanType) {

//         diffFields.push('LoanType')
//         console.log('Fields.1172']);
//         console.log(rightDoc.loanData.LoanType)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.3' != rightDoc.loanData.InterestRate) {

//         diffFields.push('InterestRate')
//         console.log('Fields.3']);
//         console.log(rightDoc.loanData.InterestRate)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.4' != rightDoc.loanData.Term) {

//         diffFields.push('Term')
//         console.log('Fields.4']);
//         console.log(rightDoc.loanData.Term)
//     } else {
//         sameFields.push('')
//     }
//     if ('Loan.LoanAmount' != rightDoc.loanData.LoanAmount) {

//         diffFields.push('LoanAmount')
//         console.log('Loan.LoanAmount']);
//         console.log(rightDoc.loanData.LoanAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Loan.LoanNumber' != rightDoc.loanData.LoanNumber) {

//         diffFields.push('LoanNumber')
//         console.log('Loan.LoanNumber']);
//         console.log(rightDoc.loanData.LoanNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.CX.FLOIFYAPIKEY' != rightDoc.loanData.FloifyAPIKey) {

//         diffFields.push('FloifyAPIKey')
//         console.log('Fields.CX.FLOIFYAPIKEY']);
//         console.log(rightDoc.loanData.FloifyAPIKey)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.CX.FLOIFYURL' != rightDoc.loanData.FloifyApplicationURL) {

//         diffFields.push('FloifyApplicationURL')
//         console.log('Fields..CX.FLOIFYURL']);
//         console.log(rightDoc.loanData.FloifyApplicationURL)
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.CX.APPRORD') != await convertDate(rightDoc.loanData.AppraisalOrdered)) {

//         diffFields.push('AppraisalOrdered')
//         console.log(await convertDate('Fields.CX.APPRORD'));
//         console.log(await convertDate(rightDoc.loanData.AppraisalOrdered))
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.CX.APPRRCD') != await convertDate(rightDoc.loanData.AppraisalReceived)) {

//         diffFields.push('AppraisalReceived')
//         console.log(await convertDate('Fields.CX.APPRRCD'));
//         console.log(await convertDate(rightDoc.loanData.AppraisalReceived))
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.CX.VAFF' != rightDoc.loanData.VAFFFactor) {

//         diffFields.push('VAFFFactor')
//         console.log('Fields.CX.VAFF']);
//         console.log(rightDoc.loanData.VAFFFactor)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.VASUMM.X2' != rightDoc.loanData.VALoanSummEnititlementCode) {

//         diffFields.push('VALoanSummEnititlementCode')
//         console.log('Fields.VASUMM.X2']);
//         console.log(rightDoc.loanData.VALoanSummEnititlementCode)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1107' != rightDoc.loanData.InsuranceMtgInsUpfrontFactor) {

//         diffFields.push('InsuranceMtgInsUpfrontFactor')
//         console.log('Fields.1107']);
//         console.log(rightDoc.loanData.InsuranceMtgInsUpfrontFactor)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.353' != rightDoc.loanData.LoanToValue) {

//         diffFields.push('LoanToValue')
//         console.log('Fields.353']);
//         console.log(rightDoc.loanData.LoanToValue)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.990' != rightDoc.loanData.VALoanSummitDiscountInfo) {

//         diffFields.push('VALoanSummitDiscountInfo')
//         console.log('Fields.990']);
//         console.log(rightDoc.loanData.VALoanSummitDiscountInfo)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.3532' != rightDoc.loanData.ChargesForInsuranceXollectedUpfront) {

//         diffFields.push('ChargesForInsuranceXollectedUpfront')
//         console.log('Fields.3532']);
//         console.log(rightDoc.loanData.ChargesForInsuranceXollectedUpfront)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.VASUMM.X49' != rightDoc.loanData.VALoanSummFirstTimeUseVALoanProgram) {

//         diffFields.push('VALoanSummFirstTimeUseVALoanProgram')
//         console.log('Fields.VASUMM.X49']);
//         console.log(rightDoc.loanData.VALoanSummFirstTimeUseVALoanProgram)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1171' != rightDoc.loanData.IncomeTotalPositiveCashFlow) {

//         diffFields.push('IncomeTotalPositiveCashFlow')
//         console.log('Fields.1171']);
//         console.log(rightDoc.loanData.IncomeTotalPositiveCashFlow)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1135' != rightDoc.loanData.FHANCAWRequiredInvestment) {

//         diffFields.push('FHANCAWRequiredInvestment')
//         console.log('Fields.1135']);
//         console.log(rightDoc.loanData.FHANCAWRequiredInvestment)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.356' != rightDoc.loanData.PropertyAppraised) {

//         diffFields.push('PropertyAppraised')
//         console.log('Fields.356']);
//         console.log(rightDoc.loanData.PropertyAppraised)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1401' != rightDoc.loanData.LoanProgram) {

//         diffFields.push('LoanProgram')
//         console.log('Fields.1401']);
//         console.log(rightDoc.loanData.LoanProgram)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1109' != rightDoc.loanData.LoanAmountDup) {

//         diffFields.push('LoanAmountDup')
//         console.log('Fields.1109']);
//         console.log(rightDoc.loanData.LoanAmountDup)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1765' != rightDoc.loanData.ExpensesCalcMIPPMILock) {

//         diffFields.push('ExpensesCalcMIPPMILock')
//         console.log('Fields.1765']);
//         console.log(rightDoc.loanData.ExpensesCalcMIPPMILock)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.3262' != rightDoc.loanData.RefundProratedUnearnedUpfrontMIPremiums) {

//         diffFields.push('RefundProratedUnearnedUpfrontMIPremiums')
//         console.log('Fields.3262']);
//         console.log(rightDoc.loanData.RefundProratedUnearnedUpfrontMIPremiums)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.SYS.X11' != rightDoc.loanData.FHAProcessManagementSolution) {

//         diffFields.push('FHAProcessManagementSolution')
//         console.log('Fields.SYS.X11']);
//         console.log(rightDoc.loanData.FHAProcessManagementSolution)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.3531' != rightDoc.loanData.ChargesForInsuranceAddedToLoanPayments) {

//         diffFields.push('ChargesForInsuranceAddedToLoanPayments')
//         console.log('Fields.3531']);
//         console.log(rightDoc.loanData.ChargesForInsuranceAddedToLoanPayments)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1199' != rightDoc.loanData.InsuranceMtgInPeriodicFactor1) {

//         diffFields.push('InsuranceMtgInPeriodicFactor1')
//         console.log('Fields.1199']);
//         console.log(rightDoc.loanData.InsuranceMtgInPeriodicFactor1)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1198' != rightDoc.loanData.InsuranceMtgInPeriod1) {

//         diffFields.push('InsuranceMtgInPeriod1')
//         console.log('Fields.1198']);
//         console.log(rightDoc.loanData.InsuranceMtgInPeriod1)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1201' != rightDoc.loanData.InsuranceMtgInPeriodicFactor2) {

//         diffFields.push('InsuranceMtgInPeriodicFactor2')
//         console.log('Fields.1201']);
//         console.log(rightDoc.loanData.InsuranceMtgInPeriodicFactor2)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1200' != rightDoc.loanData.InsuranceMtgInPeriod2) {

//         diffFields.push('InsuranceMtgInPeriod2')
//         console.log('Fields.1200']);
//         console.log(rightDoc.loanData.InsuranceMtgInPeriod2)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1205' != rightDoc.loanData.FeeDetailLine1205BorrowerDidShopFor) {

//         diffFields.push('FeeDetailLine1205BorrowerDidShopFor')
//         console.log('Fields.1205']);
//         console.log(rightDoc.loanData.FeeDetailLine1205BorrowerDidShopFor)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.VAVOB.X72' != rightDoc.loanData.VAVeteranType) {

//         diffFields.push('VAVeteranType')
//         console.log('Fields.VAVOB.X72']);
//         console.log(rightDoc.loanData.VAVeteranType)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.2' != rightDoc.loanData.TotalLoanAmount) {

//         diffFields.push('TotalLoanAmount')
//         console.log('Fields.2']);
//         console.log(rightDoc.loanData.TotalLoanAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.5' != rightDoc.loanData.TotalMonthlyPayment) {

//         diffFields.push('TotalMonthlyPayment')
//         console.log('Fields.5']);
//         console.log(rightDoc.loanData.TotalMonthlyPayment)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.8' != rightDoc.loanData.PMI) {

//         diffFields.push('PMI')
//         console.log('Fields.8']);
//         console.log(rightDoc.loanData.PMI)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.13' != rightDoc.loanData.PropertyCounty) {

//         diffFields.push('PropertyCounty')
//         console.log('Fields.13']);
//         console.log(rightDoc.loanData.PropertyCounty)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.16' != rightDoc.loanData.PropertyNumberOfUnits) {

//         diffFields.push('PropertyNumberOfUnits')
//         console.log('Fields.16']);
//         console.log(rightDoc.loanData.PropertyNumberOfUnits)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.18' != rightDoc.loanData.PropertyYearBuilt) {

//         diffFields.push('PropertyYearBuilt')
//         console.log('Fields.18']);
//         console.log(rightDoc.loanData.PropertyYearBuilt)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.36' != rightDoc.loanData.BorrowerFirstMiddleName) {

//         diffFields.push('BorrowerFirstMiddleName')
//         console.log('Fields.36']);
//         console.log(rightDoc.loanData.BorrowerFirstMiddleName)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.37' != rightDoc.loanData.BorrowerLastSuffixName) {

//         diffFields.push('BorrowerLastSuffixName')
//         console.log('Fields.37']);
//         console.log(rightDoc.loanData.BorrowerLastSuffixName)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.56' != rightDoc.loanData.AttorneyName) {

//         diffFields.push('AttorneyName')
//         console.log('Fields.56']);
//         console.log(rightDoc.loanData.AttorneyName)
//     } else {
//         sameFields.push('')
//     }
//     if (await Secure.encryptUname('Fields.65') != rightDoc.loanData.BorrowerSSN) {

//         diffFields.push('BorrowerSSN')
//         console.log(Secure.encryptUname('Fields.65'));
//         console.log(rightDoc.loanData.BorrowerSSN)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.67' != rightDoc.loanData.BorrowerCreditScore) {

//         diffFields.push('BorrowerCreditScore')
//         console.log('Fields.67']);
//         console.log(rightDoc.loanData.BorrowerCreditScore)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.68' != rightDoc.loanData.CoBorrowerFirstMiddleName) {

//         diffFields.push('CoBorrowerFirstMiddleName')
//         console.log('Fields.68']);
//         console.log(rightDoc.loanData.CoBorrowerFirstMiddleName)
//     } else {
//         sameFields.push('')
//     }
//     // put this back in too!
//     // if ('Fields.69' != rightDoc.loanData.CoBorrowerLastSuffixName) {
//     //   fieldChanged = 1;
//     //   diffFields.push('CoBorrowerLastSuffixName')
//     //   console.log('Fields.69']);
//     //   console.log(rightDoc.loanData.CoBorrowerLastSuffixName)
//     //       }      else{          sameFields.push('')
//     // }
//     if (await convertDate('Fields.78') != await convertDate(rightDoc.loanData.LoanMaturityDate)) {

//         diffFields.push('LoanMaturityDate')
//         console.log(await convertDate('Fields.78'));
//         console.log(await convertDate(rightDoc.loanData.LoanMaturityDate))
//     } else {
//         sameFields.push('')
//     }
//     if (await Secure.encryptUname('Fields.97') != rightDoc.loanData.CoBorrowerSSN) {

//         diffFields.push('CoBorrowerSSN')
//         console.log(Secure.encryptUname('Fields.97'));
//         console.log(rightDoc.loanData.CoBorrowerSSN)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.101' != rightDoc.loanData.BorrowerBaseIncome) {

//         diffFields.push('BorrowerBaseIncome')
//         console.log('Fields.101']);
//         console.log(rightDoc.loanData.BorrowerBaseIncome)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.119' != rightDoc.loanData.ExpensesPresentRent) {

//         diffFields.push('ExpensesPresentRent')
//         console.log('Fields.119']);
//         console.log(rightDoc.loanData.ExpensesPresentRent)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.136' != rightDoc.loanData.PropertyPurchasePrice) {

//         diffFields.push('PropertyPurchasePrice')
//         console.log('Fields.136']);
//         console.log(rightDoc.loanData.PropertyPurchasePrice)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.141' != rightDoc.loanData.Credit1Amount) {

//         diffFields.push('Credit1Amount')
//         console.log('Fields.141']);
//         console.log(rightDoc.loanData.Credit1Amount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.142' != rightDoc.loanData.CashFromBorrower) {

//         diffFields.push('CashFromBorrower')
//         console.log('Fields.142']);
//         console.log(rightDoc.loanData.CashFromBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.155' != rightDoc.loanData.FeesLine801UserDefFee1) {

//         diffFields.push('FeesLine801UserDefFee1')
//         console.log('Fields.155']);
//         console.log(rightDoc.loanData.FeesLine801UserDefFee1)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.220' != rightDoc.loanData.TotalGiftFundsAmount) {

//         diffFields.push('TotalGiftFundsAmount')
//         console.log('Fields.220']);
//         console.log(rightDoc.loanData.TotalGiftFundsAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.228' != rightDoc.loanData.ExpensesProposedMtgPayment) {

//         diffFields.push('ExpensesProposedMtgPayment')
//         console.log('Fields.228']);
//         console.log(rightDoc.loanData.ExpensesProposedMtgPayment)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.229' != rightDoc.loanData.ExpensesProposedOtherPayment) {

//         diffFields.push('ExpensesProposedOtherPayment')
//         console.log('Fields.229']);
//         console.log(rightDoc.loanData.ExpensesProposedOtherPayment)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.230' != rightDoc.loanData.ExpensesProposedHazIns) {

//         diffFields.push('ExpensesProposedHazIns')
//         console.log('Fields.230']);
//         console.log(rightDoc.loanData.ExpensesProposedHazIns)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.232' != rightDoc.loanData.ExpensesProposedMtgIns) {

//         diffFields.push('ExpensesProposedMtgIns')
//         console.log('Fields.232']);
//         console.log(rightDoc.loanData.ExpensesProposedMtgIns)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.233' != rightDoc.loanData.ExpensesProposedHOA) {

//         diffFields.push('ExpensesProposedHOA')
//         console.log('Fields.233']);
//         console.log(rightDoc.loanData.ExpensesProposedHOA)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.234' != rightDoc.loanData.ExpensesProposedOtherHousing) {

//         diffFields.push('ExpensesProposedOtherHousing')
//         console.log('Fields.234']);
//         console.log(rightDoc.loanData.ExpensesProposedOtherHousing)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.247' != rightDoc.loanData.LoanInfoATMLifeCap) {

//         diffFields.push('LoanInfoATMLifeCap')
//         console.log('Fields.247']);
//         console.log(rightDoc.loanData.LoanInfoATMLifeCap)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.300' != rightDoc.loanData.CreditReportRefNumber) {

//         diffFields.push('CreditReportRefNumber')
//         console.log('Fields.300']);
//         console.log(rightDoc.loanData.CreditReportRefNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.305' != rightDoc.loanData.LenderCaseNumber) {

//         diffFields.push('LenderCaseNumber')
//         console.log('Fields.305']);
//         console.log(rightDoc.loanData.LenderCaseNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.323' != rightDoc.loanData.BrokerLenderZip) {

//         diffFields.push('BrokerLenderZip')
//         console.log('Fields.323']);
//         console.log(rightDoc.loanData.BrokerLenderZip)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.334' != rightDoc.loanData.FeesInterestBorrower) {

//         diffFields.push('FeesInterestBorrower')
//         console.log('Fields.334']);
//         console.log(rightDoc.loanData.FeesInterestBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.340' != rightDoc.loanData.FeesLine1008NumberMos) {

//         diffFields.push('FeesLine1008NumberMos')
//         console.log('Fields.340']);
//         console.log(rightDoc.loanData.FeesLine1008NumberMos)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.341' != rightDoc.loanData.FeesLine1009NumberMos) {

//         diffFields.push('FeesLine1009NumberMos')
//         console.log('Fields.341']);
//         console.log(rightDoc.loanData.FeesLine1009NumberMos)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.352' != rightDoc.loanData.InvestorCaseNumber) {

//         diffFields.push('InvestorCaseNumber')
//         console.log('Fields.352']);
//         console.log(rightDoc.loanData.InvestorCaseNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.364' != rightDoc.loanData.LoanNumber2) {

//         diffFields.push('LoanNumber2')
//         console.log('Fields.364']);
//         console.log(rightDoc.loanData.LoanNumber2)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.367' != rightDoc.loanData.FeesLine801UnderwritingFee) {

//         diffFields.push('FeesLine801UnderwritingFee')
//         console.log('Fields.367']);
//         console.log(rightDoc.loanData.FeesLine801UnderwritingFee)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.388' != rightDoc.loanData.OriginalPayments) {

//         diffFields.push('OriginalPayments')
//         console.log('Fields.388']);
//         console.log(rightDoc.loanData.OriginalPayments)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.411' != rightDoc.loanData.TitleCompanyName) {

//         diffFields.push('TitleCompanyName')
//         console.log('Fields.411']);
//         console.log(rightDoc.loanData.TitleCompanyName)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.420' != rightDoc.loanData.LienPosition) {

//         diffFields.push('LienPosition')
//         console.log('Fields.420']);
//         console.log(rightDoc.loanData.LienPosition)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.436' != rightDoc.loanData.FeesLoanDiscount) {

//         diffFields.push('FeesLoanDiscount')
//         console.log('Fields.436']);
//         console.log(rightDoc.loanData.FeesLoanDiscount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.454' != rightDoc.loanData.FeesLoanOriginationFeeBorrower) {

//         diffFields.push('FeesLoanOriginationFeeBorrower')
//         console.log('Fields.454']);
//         console.log(rightDoc.loanData.FeesLoanOriginationFeeBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.558' != rightDoc.loanData.FeesAggregateAdj) {

//         diffFields.push('FeesAggregateAdj')
//         console.log('Fields.558']);
//         console.log(rightDoc.loanData.FeesAggregateAdj)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.559' != rightDoc.loanData.FeesLoanOriginationFeeSeller) {

//         diffFields.push('FeesLoanOriginationFeeSeller')
//         console.log('Fields.559']);
//         console.log(rightDoc.loanData.FeesLoanOriginationFeeSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.561' != rightDoc.loanData.FeesLine901InterestSeller) {

//         diffFields.push('FeesLine901InterestSeller')
//         console.log('Fields.561']);
//         console.log(rightDoc.loanData.FeesLine901InterestSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.569' != rightDoc.loanData.FeesUnderwritingFeeSeller) {

//         diffFields.push('FeesUnderwritingFeeSeller')
//         console.log('Fields.569']);
//         console.log(rightDoc.loanData.FeesUnderwritingFeeSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.593' != rightDoc.loanData.FeesLine1204LocalTaxStampSeller) {

//         diffFields.push('FeesLine1204LocalTaxStampSeller')
//         console.log('Fields.593']);
//         console.log(rightDoc.loanData.FeesLine1204LocalTaxStampSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.594' != rightDoc.loanData.FeesLine1205StateTaxStampSeller) {

//         diffFields.push('FeesLine1205StateTaxStampSeller')
//         console.log('Fields.594']);
//         console.log(rightDoc.loanData.FeesLine1205StateTaxStampSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.640' != rightDoc.loanData.FeesCreditreportBorrower) {

//         diffFields.push('FeesCreditreportBorrower')
//         console.log('Fields.640']);
//         console.log(rightDoc.loanData.FeesCreditreportBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.641' != rightDoc.loanData.FeesAppraisalFeeBorrower) {

//         diffFields.push('FeesAppraisalFeeBorrower')
//         console.log('Fields.641']);
//         console.log(rightDoc.loanData.FeesAppraisalFeeBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.647' != rightDoc.loanData.FeesCityCountyStampBorrower) {

//         diffFields.push('FeesCityCountyStampBorrower')
//         console.log('Fields.647']);
//         console.log(rightDoc.loanData.FeesCityCountyStampBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.648' != rightDoc.loanData.FeesStateTaxStampBorrower) {

//         diffFields.push('FeesStateTaxStampBorrower')
//         console.log('Fields.648']);
//         console.log(rightDoc.loanData.FeesStateTaxStampBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.672' != rightDoc.loanData.LateChargeDays) {

//         diffFields.push('LateChargeDays')
//         console.log('Fields.672']);
//         console.log(rightDoc.loanData.LateChargeDays)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.674' != rightDoc.loanData.LateChargePercentage) {

//         diffFields.push('LateChargePercentage')
//         console.log('Fields.674']);
//         console.log(rightDoc.loanData.LateChargePercentage)
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.682') != await convertDate(rightDoc.loanData.FirstPaymentDate)) {

//         diffFields.push('FirstPaymentDate')
//         console.log(await convertDate('Fields.682'));
//         console.log(await convertDate(rightDoc.loanData.FirstPaymentDate))
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.688' != rightDoc.loanData.LoanInfoARMIndex) {

//         diffFields.push('LoanInfoARMIndex')
//         console.log('Fields.688']);
//         console.log(rightDoc.loanData.LoanInfoARMIndex)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.691' != rightDoc.loanData.LoanInfoARMPaymentAdjCap) {

//         diffFields.push('LoanInfoARMPaymentAdjCap')
//         console.log('Fields.691']);
//         console.log(rightDoc.loanData.LoanInfoARMPaymentAdjCap)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.694' != rightDoc.loanData.LoanInfoARMRateAdjPeriod) {

//         diffFields.push('LoanInfoARMRateAdjPeriod')
//         console.log('Fields.694']);
//         console.log(rightDoc.loanData.LoanInfoARMRateAdjPeriod)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.695' != rightDoc.loanData.LoanInfoARMrateCap) {

//         diffFields.push('LoanInfoARMrateCap')
//         console.log('Fields.695']);
//         console.log(rightDoc.loanData.LoanInfoARMrateCap)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.696' != rightDoc.loanData.LoanInfoARMFirstPeriodChange) {

//         diffFields.push('LoanInfoARMFirstPeriodChange')
//         console.log('Fields.696']);
//         console.log(rightDoc.loanData.LoanInfoARMFirstPeriodChange)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.697' != rightDoc.loanData.LoanInfoARMFirstRateAdjCap) {

//         diffFields.push('LoanInfoARMFirstRateAdjCap')
//         console.log('Fields.697']);
//         console.log(rightDoc.loanData.LoanInfoARMFirstRateAdjCap)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.732' != rightDoc.loanData.AssetsTotalAssets) {

//         diffFields.push('AssetsTotalAssets')
//         console.log('Fields.732']);
//         console.log(rightDoc.loanData.AssetsTotalAssets)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.736' != rightDoc.loanData.IncomeTotalMonthIncome) {

//         diffFields.push('IncomeTotalMonthIncome')
//         console.log('Fields.736']);
//         console.log(rightDoc.loanData.IncomeTotalMonthIncome)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.740' != rightDoc.loanData.QualRatioTop) {

//         diffFields.push('QualRatioTop')
//         console.log('Fields.740']);
//         console.log(rightDoc.loanData.QualRatioTop)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.742' != rightDoc.loanData.QualRatioBottom) {

//         diffFields.push('QualRatioBottom')
//         console.log('Fields.742']);
//         console.log(rightDoc.loanData.QualRatioBottom)
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.748') != await convertDate(rightDoc.loanData.ClosingDate)) {

//         diffFields.push('ClosingDate')
//         console.log(await convertDate('Fields.748'));
//         console.log(await convertDate(rightDoc.loanData.ClosingDate))
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.749') != await convertDate(rightDoc.loanData.CurrentStatusDate)) {

//         diffFields.push('CurrentStatusDate')
//         console.log(await convertDate('Fields.749'));
//         console.log(await convertDate(rightDoc.loanData.CurrentStatusDate))
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.761') != await convertDate(rightDoc.loanData.Lockdate)) {

//         diffFields.push('Lockdate')
//         console.log(await convertDate('Fields.761'));
//         console.log(await convertDate(rightDoc.loanData.Lockdate))
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.799' != rightDoc.loanData.APR) {

//         diffFields.push('APR')
//         console.log('Fields.799']);
//         console.log(rightDoc.loanData.APR)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.912' != rightDoc.loanData.ExpensesProposedTotalHousing) {

//         diffFields.push('ExpensesProposedTotalHousing')
//         console.log('Fields.912']);
//         console.log(rightDoc.loanData.ExpensesProposedTotalHousing)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.976' != rightDoc.loanData.CombLoanToValue) {

//         diffFields.push('CombLoanToValue')
//         console.log('Fields.976']);
//         console.log(rightDoc.loanData.CombLoanToValue)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1039' != rightDoc.loanData.LoanInfoSectionofHousingAct) {

//         diffFields.push('LoanInfoSectionofHousingAct')
//         console.log('Fields.1039']);
//         console.log(rightDoc.loanData.LoanInfoSectionofHousingAct)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1040' != rightDoc.loanData.AgencyCaseNumber) {

//         diffFields.push('AgencyCaseNumber')
//         console.log('Fields.1040']);
//         console.log(rightDoc.loanData.AgencyCaseNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1041' != rightDoc.loanData.PropertyTypeFannieMae) {

//         diffFields.push('PropertyTypeFannieMae')
//         console.log('Fields.1041']);
//         console.log(rightDoc.loanData.PropertyTypeFannieMae)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1051' != rightDoc.loanData.MERSMINNumber) {

//         diffFields.push('MERSMINNumber')
//         console.log('Fields.1051']);
//         console.log(rightDoc.loanData.MERSMINNumber)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1059' != rightDoc.loanData.FHALenderID) {

//         diffFields.push('FHALenderID')
//         console.log('Fields.1059']);
//         console.log(rightDoc.loanData.FHALenderID)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1061' != rightDoc.loanData.FeesLoanDiscountFeePercentage) {

//         diffFields.push('FeesLoanDiscountFeePercentage')
//         console.log('Fields.1061']);
//         console.log(rightDoc.loanData.FeesLoanDiscountFeePercentage)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1095' != rightDoc.loanData.Credit2Amount) {

//         diffFields.push('Credit2Amount')
//         console.log('Fields.1095']);
//         console.log(rightDoc.loanData.Credit2Amount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1296' != rightDoc.loanData.FeesMOrtgageInsNumberOfMonths) {

//         diffFields.push('FeesMOrtgageInsNumberOfMonths')
//         console.log('Fields.1296']);
//         console.log(rightDoc.loanData.FeesMOrtgageInsNumberOfMonths)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1298' != rightDoc.loanData.PropertyProjectName) {

//         diffFields.push('PropertyProjectName')
//         console.log('Fields.1298']);
//         console.log(rightDoc.loanData.PropertyProjectName)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1335' != rightDoc.loanData.DownPaymentAmount) {

//         diffFields.push('DownPaymentAmount')
//         console.log('Fields.1335']);
//         console.log(rightDoc.loanData.DownPaymentAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1386' != rightDoc.loanData.FeesTaxNumberofMonthsReserveRequired) {

//         diffFields.push('FeesTaxNumberofMonthsReserveRequired')
//         console.log('Fields.1386']);
//         console.log(rightDoc.loanData.FeesTaxNumberofMonthsReserveRequired)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1387' != rightDoc.loanData.FeesHazardInsNumberofMonthsReserveRequired) {

//         diffFields.push('FeesHazardInsNumberofMonthsReserveRequired')
//         console.log('Fields.1387']);
//         console.log(rightDoc.loanData.FeesHazardInsNumberofMonthsReserveRequired)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1388' != rightDoc.loanData.FeesFloodInsNumberofMonthsReserveRequired) {

//         diffFields.push('FeesFloodInsNumberofMonthsReserveRequired')
//         console.log('Fields.1388']);
//         console.log(rightDoc.loanData.FeesFloodInsNumberofMonthsReserveRequired)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1393' != rightDoc.loanData.CurrentLoanStatus) {

//         diffFields.push('CurrentLoanStatus')
//         console.log('Fields.1393']);
//         console.log(rightDoc.loanData.CurrentLoanStatus)
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.1402') != await convertDate(rightDoc.loanData.BorrowerDOB)) {

//         diffFields.push('BorrowerDOB')
//         console.log(await convertDate('Fields.1402'));
//         console.log(await convertDate(rightDoc.loanData.BorrowerDOB))
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.1403') != await convertDate(rightDoc.loanData.CoBorrowerDOB)) {

//         diffFields.push('CoBorrowerDOB')
//         console.log(await convertDate('Fields.1403'));
//         console.log(await convertDate(rightDoc.loanData.CoBorrowerDOB))
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1405' != rightDoc.loanData.ExpensesProposedtaxes) {

//         diffFields.push('ExpensesProposedtaxes')
//         console.log('Fields.1405']);
//         console.log(rightDoc.loanData.ExpensesProposedtaxes)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1416' != rightDoc.loanData.BorrowerMailingAddress) {

//         diffFields.push('BorrowerMailingAddress')
//         console.log('Fields.1416']);
//         console.log(rightDoc.loanData.BorrowerMailingAddress)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1544' != rightDoc.loanData.UnderwritingRiskAssessAUSRecomm) {

//         diffFields.push('UnderwritingRiskAssessAUSRecomm')
//         console.log('Fields.1544']);
//         console.log(rightDoc.loanData.UnderwritingRiskAssessAUSRecomm)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1612' != rightDoc.loanData.InterviewerName) {

//         diffFields.push('InterviewerName')
//         console.log('Fields.1612']);
//         console.log(rightDoc.loanData.InterviewerName)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1621' != rightDoc.loanData.FeesProcessFeeBorrower) {

//         diffFields.push('FeesProcessFeeBorrower')
//         console.log('Fields.1621']);
//         console.log(rightDoc.loanData.FeesProcessFeeBorrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1622' != rightDoc.loanData.FeesProcessFeeSeller) {

//         diffFields.push('FeesProcessFeeSeller')
//         console.log('Fields.1622']);
//         console.log(rightDoc.loanData.FeesProcessFeeSeller)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1625' != rightDoc.loanData.FeesLine801UserDefinedFee2BorrowerAmount) {

//         diffFields.push('FeesLine801UserDefinedFee2BorrowerAmount')
//         console.log('Fields.1625']);
//         console.log(rightDoc.loanData.FeesLine801UserDefinedFee2BorrowerAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1629' != rightDoc.loanData.FeesLine1007NumberOfMonths) {

//         diffFields.push('FeesLine1007NumberOfMonths')
//         console.log('Fields.1629']);
//         console.log(rightDoc.loanData.FeesLine1007NumberOfMonths)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1647' != rightDoc.loanData.Credit4Amount) {

//         diffFields.push('Credit4Amount')
//         console.log('Fields.1647']);
//         console.log(rightDoc.loanData.Credit4Amount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1667' != rightDoc.loanData.FeesLine908Borrower) {

//         diffFields.push('FeesLine908Borrower')
//         console.log('Fields.1667']);
//         console.log(rightDoc.loanData.FeesLine908Borrower)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1699' != rightDoc.loanData.LoanInfoARMFloorRate) {

//         diffFields.push('LoanInfoARMFloorRate')
//         console.log('Fields.1699']);
//         console.log(rightDoc.loanData.LoanInfoARMFloorRate)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1712' != rightDoc.loanData.LoanInfoARMRecastPeriod) {

//         diffFields.push('LoanInfoARMRecastPeriod')
//         console.log('Fields.1712']);
//         console.log(rightDoc.loanData.LoanInfoARMRecastPeriod)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1753' != rightDoc.loanData['ExpensesCalcMIP/PMIMidpointPymtCancel']) {

//         diffFields.push('ExpensesCalcMIP/PMIMidpointPymtCancel')
//         console.log('Fields.1753']);
//         console.log(rightDoc.loanData['ExpensesCalcMIP/PMIMidpointPymtCancel'])
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1775' != rightDoc.loanData['ExpensesCalcMIP/PMIBasedOnRemainBal']) {

//         diffFields.push('ExpensesCalcMIP/PMIBasedOnRemainBal')
//         console.log('Fields.1775']);
//         console.log(rightDoc.loanData['ExpensesCalcMIP/PMIBasedOnRemainBal'])
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1785' != rightDoc.loanData.ClosingCostProgram) {

//         diffFields.push('ClosingCostProgram')
//         console.log('Fields.1785']);
//         console.log(rightDoc.loanData.ClosingCostProgram)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1811' != rightDoc.loanData.PropertyOccupancyStatus) {

//         diffFields.push('PropertyOccupancyStatus')
//         console.log('Fields.1811']);
//         console.log(rightDoc.loanData.PropertyOccupancyStatus)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1821' != rightDoc.loanData.PropertyEstValue) {

//         diffFields.push('PropertyEstValue')
//         console.log('Fields.1821']);
//         console.log(rightDoc.loanData.PropertyEstValue)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1839' != rightDoc.loanData.Line801UserDefinedFee3BorrowerAmount) {

//         diffFields.push('Line801UserDefinedFee3BorrowerAmount')
//         console.log('Fields.1839']);
//         console.log(rightDoc.loanData.Line801UserDefinedFee3BorrowerAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1842' != rightDoc.loanData.Line801UserDefinedFee4BorrowerAmount) {

//         diffFields.push('Line801UserDefinedFee4BorrowerAmount')
//         console.log('Fields.1842']);
//         console.log(rightDoc.loanData.Line801UserDefinedFee4BorrowerAmount)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1852' != rightDoc.loanData.CCPaidByBrokerLenderOther) {

//         diffFields.push('CCPaidByBrokerLenderOther')
//         console.log('Fields.1852']);
//         console.log(rightDoc.loanData.CCPaidByBrokerLenderOther)
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1867' != rightDoc.loanData.BorrowerVerstingBorrowerFinalVestingToRead) {

//         diffFields.push('BorrowerVerstingBorrowerFinalVestingToRead')
//         console.log('Fields.1867']);
//         console.log(rightDoc.loanData.BorrowerVerstingBorrowerFinalVestingToRead);
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1871' != rightDoc.loanData.BorrowerVerstingBorrowerFinalVestingType) {

//         diffFields.push('BorrowerVerstingBorrowerFinalVestingType')
//         console.log('Fields.1871']);
//         console.log(rightDoc.loanData.BorrowerVerstingBorrowerFinalVestingType);
//     } else {
//         sameFields.push('')
//     }
//     if ('Fields.1969' != rightDoc.loanData.CopyToLenderCheckBox) {

//         diffFields.push('CopyToLenderCheckBox')
//         console.log('Fields.1969']);
//         console.log(rightDoc.loanData.CopyToLenderCheckBox);
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.1994') != await convertDate(rightDoc.loanData.ClearToCloseDate)) {

//         diffFields.push('ClearToCloseDate')
//         console.log(await convertDate('Fields.1994'));
//         console.log(await convertDate(rightDoc.loanData.ClearToCloseDate));
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.1996') != await convertDate(rightDoc.loanData.FundsOrderedDate)) {

//         diffFields.push('FundsOrderedDate')
//         console.log(await convertDate('Fields.1996'));
//         console.log(await convertDate(rightDoc.loanData.FundsOrderedDate));
//     } else {
//         sameFields.push('')
//     }
//     if (await convertDate('Fields.1997') != await convertDate(rightDoc.loanData.FundsSentDate)) {

//         diffFields.push('FundsSentDate')
//         console.log(await convertDate('Fields.1997'));
//         console.log(await convertDate(rightDoc.loanData.FundsSentDate));
//     } else {
//         sameFields.push('')
//     }
  

//     console.log('Diff Fields', diffFields);
// }

// async function convertDate(varDate){
//     var date = new Date(varDate)
//     var month = date.getMonth() + 1;
//     var day = date.getDate();
//     var year = date.getFullYear();
//     return month + '/' + day + '/' + year;
//   }
   