import React, { Component } from 'react';
import { Col, Grid, Row, Panel, Table, Button, Checkbox } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import Dropzone from 'react-dropzone';
import * as FontAwesome from 'react-icons/lib/fa';
import QuestionTypeDropdown from '../../dropdowns/QuestionTypeDropdown'
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3

    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class AddQuestionModal extends Component {
    constructor(props) {
        super();
        this.state = {
            questionText: "",
            options: [],
            files: {},
            questionType: '',
            correctOption: 9999999

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleQuillChange = this.handleQuillChange.bind(this)
        this.makeid = this.makeid.bind(this);
        this.onDrop = this.onDrop.bind(this)
        this.afterOpenModal = this.afterOpenModal.bind(this)
        this.addOption = this.addOption.bind(this)
        this.updateQuestionType = this.updateQuestionType.bind(this)
    }
    componentDidMount() {

    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    handleSubmit(event) {

        event.preventDefault();
        if (!this.state.questionText) {
            this.questionTextError()
            return
        }
        if (!this.state.questionType) {
            this.questionTypeError()
            return
        }
        if (this.state.questionType == 'Multiple Choice' && this.state.options.length < 2) {
            this.multipleChoiceError()
            return
        }
        if (this.state.questionType == 'Acknowledge' && this.state.options.length > 1) {
            this.acknowledgeError()
            return
        }
        if (this.state.questionType != 'Short Answer' && this.state.correctOption == 9999999) {
            this.correctAnswerError()
            return
        }
        var newQuestionData = {
            files: this.state.files,
            options: this.state.options,
            questionText: this.state.questionText,
            questionUrl: this.state.questionUrl,
            correctOption: this.state.correctOption,
            questionType: this.state.questionType
        }
        this.props.addQuestion(newQuestionData);

        this.props.closeModal()

        return;

    }



    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleOptionInputChange(event, index) {
        const target = event.target;
        const value = target.value;
        var newOptions = this.state.options
        newOptions[index].option = value
        this.setState({ options: newOptions })
    }


    afterOpenModal() {
        this.setState({
            questionText: "",
            files: {}
        })
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
        else if (event.which === 9 /* Tab */) {
            event.preventDefault();
            this.addOption()
        }
    }
    handleQuillChange(value) {
        this.setState({ body: value })
    }
    generatePreviews() {
        var filesPreview = [];
        if (this.state.files) {
            Object.keys(this.state.files).forEach((element) => {
                filesPreview.push(
                    <tr>
                        <td><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                        <td><b style={{ fontSize: '1.25rem' }}>{this.formatBytes(this.state.files[element].size, 2)}</b></td>
                        <td><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                            <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                        </Button></td>
                    </tr>
                )
            })
        }

        return filesPreview;
    }
    removePreview(file) {
        var files = this.state.files;
        var totalFileSize = this.state.totalFileSize;
        totalFileSize = totalFileSize - files[file].size
        delete files[file];

        this.setState({ files: files, totalFileSize: totalFileSize })
    }
    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                var files = that.state.files;
                var totalFileSize = that.state.totalFileSize
                totalFileSize = totalFileSize + file.size

                if (file.size > 11000000) {
                    alert('File size exceeds 10MB.')
                }
                else {

                    files[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };


                    that.setState({ files: files, totalFileSize: totalFileSize })
                }
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }
    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    addOption(e) {

        this.setState((prevState) => ({
            options: [...prevState.options, { option: "" }],
        }));

    }
    removeOption(index) {
        var oldOptions = this.state.options;
        oldOptions.splice(index, 1);
        this.setState({ options: oldOptions })
    }

    updateCheckBox(index) {
        //For The Actual Check Mark
        this.setState({
            correctOption: index
        });

    }


    updateQuestionType(value) {

        if (value) {
            this.setState({ questionType: value })
            if (value == 'True/False') {
                this.setState({ options: [{ option: 'True' }, { option: 'False' }] })
            }
            else if (value == 'Acknowledge') {
                this.setState({ options: [{ option: 'I Acknowledge' }], correctOption: 0 })
            }
        }
        else
            this.setState({ questionType: '' })


    }

    questionTypeError = () => {
        confirmAlert({
            title: 'Uh-Oh',
            message: 'Question Type must be selected.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        })
    };

    questionTextError = () => {
        confirmAlert({
            title: 'Uh-Oh',
            message: 'Question Text must be selected.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        })
    };

    multipleChoiceError = () => {
        confirmAlert({
            title: 'Uh-Oh',
            message: 'Mulitple Choice Questions must have more than one option.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        })
    };
    correctAnswerError = () => {
        confirmAlert({
            title: 'Uh-Oh',
            message: 'Please select a correct option.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        })
    };
    acknowledgeError = () => {
        confirmAlert({
            title: 'Uh-Oh',
            message: 'Acknowledge question types can only have one option which verifies the acknowledgement. Please remove additional options',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                }
            ]
        })
    };
    render() {
        //Clears the Modal when exited
        if (this.props.clearModal && !this.state.modalCleared) {
            this.setState({
                questionText: "",
                options: [],
                files: {},
                questionType: '',
                modalCleared: true,
                correctOption: 9999999,
                scoringWeight: '',
                questionUrl: '',
                modalCleared: true

            })
        }

        if (!this.props.clearModal && this.state.modalCleared) {
            this.setState({
                modalCleared: false

            })
        }
        var attachedPreviews = this.generatePreviews();
        return (
            <Modal
                isOpen={this.props.modalOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={addModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>

                    <div className='try - registration - form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                            <span aria-hidden="false">&times;</span>
                        </button>
                        <h1 style={{ float: 'left' }}>Create Question</h1>
                        <br />
                        <br />
                        <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                            <Grid>
                                <Row>
                                    <Col md={8} sm={8}>
                                        <h3>Question Text:</h3>
                                    </Col>
                                    <Col md={4} sm={4}>
                                        <h3>Question Type:</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8} sm={8}>
                                        <input className='form-control' id='questionText' type='text' name='questionText' placeholder='Question' value={this.state.questionText} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col mdOffset={1} md={3} smOffset={1} sm={3}>
                                        <Row>
                                            <Checkbox name='Short Answer' checked={this.state.questionType == 'Short Answer'} onChange={() => this.updateQuestionType('Short Answer')}>Short Answer</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox name='True/False' checked={this.state.questionType == 'True/False'} onChange={() => this.updateQuestionType('True/False')}>True False</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox name='Multiple Choice' checked={this.state.questionType == 'Multiple Choice'} onChange={() => this.updateQuestionType('Multiple Choice')}>Multiple Choice</Checkbox>
                                        </Row>
                                        <Row>
                                            <Checkbox name='Acknowledge' checked={this.state.questionType == 'Acknowledge'} onChange={() => this.updateQuestionType('Acknowledge')}>Acknowledge</Checkbox>
                                        </Row>
                                    </Col>

                                </Row>
                                <br />
                                <Row>
                                    <Col md={4} sm={6}>
                                        <Button onClick={this.addOption}>
                                            <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaPlus /> Add New Option</span>
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                {this.state.options.length > 0 && this.state.options.map((val, idx) => {
                                    let optionId = `option-${idx}`;
                                    return (
                                        <Row key={idx}>
                                            <Col md={2} sm={4}>
                                                <h3 htmlFor={optionId}>{`Option #${idx + 1}`}</h3>
                                            </Col>
                                            <Col md={3} sm={4}>
                                                <input
                                                    type="text"
                                                    name={optionId}
                                                    data-id={idx}
                                                    id={optionId}
                                                    className="name"
                                                    value={this.state.options[idx].option}
                                                    onChange={(e) => this.handleOptionInputChange(e, idx)}
                                                />
                                            </Col>
                                            <Col md={3} sm={1}>
                                                <Checkbox name='1' checked={this.state.correctOption == idx} onChange={() => this.updateCheckBox(idx)}><h6>This is Correct Answer</h6></Checkbox>
                                            </Col>
                                            <Col md={1} sm={1}>
                                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33' }} onClick={() => this.removeOption(idx)}>
                                                    <span aria-hidden="false">&times;</span>
                                                </button>
                                            </Col>
                                        </Row>
                                    )
                                })}

                                {this.state.options.length < 1 && <h2>No Options for Question</h2>}
                                <br />
                                <Row>
                                    <Col md={12} sm={12}>
                                        <h3>URL Link For Question:</h3>
                                        <input className='form-control' id='questionUrl' type='text' name='questionUrl' placeholder='URL' value={this.state.questionUrl} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                {/* <Row>
                                    <Col md={12} sm={12}>
                                        <h3>Attachments to accompany this question:</h3>
                                        <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                            <div>
                                                <br />
                                                <a> <h2>Click here or drag in files for this question</h2></a>
                                                <h5>Combined File Size must be less than 10MB</h5>
                                                <br />
                                            </div>
                                        </Dropzone>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col style={{ paddingLeft: 40 }}>
                                        <h2><b>AttachedFiles:</b></h2>
                                        <Table responsive style={{ alignSelf: 'left' }}>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Size</th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attachedPreviews}
                                            </tbody>
                                        </Table>

                                    </Col>
                                </Row> */}
                                <br />
                            </Grid>
                            <br />
                            <button className='form-control btn btn-success'>Add Question</button>
                        </form>
                        <br />

                        <div id='error'></div>
                    </div>
                </div>
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionModal);
