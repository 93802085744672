import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Grid, Col, Row, Checkbox, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import windowSize from 'react-window-size';
import { customSort, customFilter } from '../../../helpers/tableHelpers';




//Components are DUMB and should only display the data that was passed from the container that it is within
class ReportTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 200,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false,
            selectedRows: []
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    handleRowClick(event) {
        console.log('state', this.state.selectedRows)
        // console.log('here', event)
        var index = this.state.selectedRows.indexOf(event._original._id);
        //Add ID
        if (index === -1) {
            var tempArray = this.state.selectedRows.concat(event._original._id)
            this.setState({
                selectedRows: tempArray,
                ['row' + event._original._id]: true
            });
        }
        //Remove ID
        else {
            tempArray = this.state.selectedRows;
            tempArray.splice(index, 1);
            this.setState({ selectedRows: tempArray, ['row' + event._original._id]: false })

        }

    }


    handleSubmit() {
        this.props.handleSubmit(this.state.selectedRows)
    }
    createSortedInfo(selectedRows) {
        var finalArr = [];

        this.props.hrInfo.allLocationsData.map((location) => {
            if (selectedRows.indexOf(location._id)!= -1) {
                console.log('GOT', location)
                location['index'] = 1
            }
            else
            {
                location['index'] = 0
            }
            finalArr.push(location)
        })

        return finalArr;
    }

    render() {
        //If something changes then we will update

        if (this.props.selectedRows.Locations != this.state.initialSelectedRows) {
            var dataForTable=[]
            this.props.selectedRows.Locations.map((location) => {
                var tempName = 'row' + location
                this.setState({
                    [tempName]: true
                })
            })
            //Create new Obj that will allow for sorting
            dataForTable = this.createSortedInfo(this.props.selectedRows.Locations)
            this.setState({
                initialSelectedRows: this.props.selectedRows.Locations,
                selectedRows: this.props.selectedRows.Locations,
                dataForTable:dataForTable
            })
        }
        console.log(dataForTable)
        var that = this;
        return (
            <div style={{ marginTop: 25 }}>
                <Button bsStyle='success' onClick={this.handleSubmit}>Save Changes</Button>
                <ReactTable
                    id='CurrentTable'
                    data={this.state.dataForTable}
                    columns={[
                        {
                            accessor: '_id',
                            minWidth: 30,
                            Cell: row => (
                                <div align="center">
                                    <Checkbox id={'row' + row.original._id} checked={this.state['row' + row.original._id]}>  </Checkbox>
                                </div>),
                            sortMethod: (a, b, desc) => {
                                var aNum = this.state['row' + a] ? 1 : 0;
                                var bNum = this.state['row' + b] ? 1 : 0;
                                return aNum > bNum ? 1 : -1;

                            }
                        },
                        {
                            Header: 'Address',
                            accessor: 'address1',
                            filterable: true,
                            minWidth: 70,
                            Cell: row => (
                                <div align="center">
                                    <p style={{ textAlign: "center" }}>{row.original.address1} {row.original.address2}, {row.original.city}, {row.original.state} {row.original.zip}</p>
                                </div>),
                            filterMethod: (filter, row) => {
                                var fullAddress = row._original.address1 + row._original.address2 + row._original.city + row._original.state + row._original.zip;
                                if (fullAddress.toLowerCase().indexOf(filter.value.toLowerCase()) != -1) {
                                    return true;
                                }
                            }

                        },
                    ]}
                    style={{
                        height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    defaultPageSize={this.props.hrInfo.allLocationsData.length}
                    className="-striped -highlight"
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSort}
                    defaultSorted={[
                        {
                            id: "index",
                        }
                    ]}
                    getTrProps={(state, rowInfo) => {

                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: that.handleRowClick.bind(that, rowInfo.row),
                            }
                        } else {
                            return {}
                        }
                    }}
                />

            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ReportTable));