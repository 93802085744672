import React, { Component } from 'react';
import { Col, Grid, Row, Panel, Button, Table, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData, setSelectedApplicant } from '../../actions/hrActions';
import { sendEmail, resetEmailStatus } from '../../actions/communicationActions';
import { addIssue, getCodes, getContacts } from '../../actions/issueActions';
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as FontAwesome from 'react-icons/lib/fa';
import { Form, Icon, Message } from 'semantic-ui-react';

class OnboardingDocumentsForm extends Component {
    constructor(props) {
        super();
        this.state = {


        }
        this.handleBackgroundReceivedToggle = this.handleBackgroundReceivedToggle.bind(this);
        this.handleBackgroundAuthToggle = this.handleBackgroundAuthToggle.bind(this);
        this.handleExclusionaryToggle = this.handleExclusionaryToggle.bind(this);
        this.handleGoogleToggle = this.handleGoogleToggle.bind(this);
    }

    componentDidMount() {
        this.setState({
            backgroundAuth: this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundAuth,
            backgroundCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted,
            googleCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted,
            exclusionaryListCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted
        })
    }

    handleBackgroundAuthToggle() {
        this.setState({ backgroundAuth: !this.state.backgroundAuth })
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.backgroundAuth = !newObj.hiringInfo.backgroundAuth
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
    }

    handleBackgroundReceivedToggle() {
        this.setState({ backgroundCompleted: !this.state.backgroundCompleted })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.backgroundCompleted = !newObj.hiringInfo.backgroundCompleted
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

    }


    handleExclusionaryToggle() {
        this.setState({ exclusionaryListCompleted: !this.state.exclusionaryListCompleted })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.exclusionaryListCompleted = !newObj.hiringInfo.exclusionaryListCompleted
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

    }


    handleGoogleToggle() {
        this.setState({ googleCompleted: !this.state.googleCompleted })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.googleCompleted = !newObj.hiringInfo.googleCompleted
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

    }

    render() {

        return (

            <div>
                <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                    <br />

                    <Grid>
                        <Row>
                            <h3>Authorization received to perform background check?</h3>
                            {this.state.backgroundAuth &&
                                <ButtonGroup>
                                    <Button bsStyle="warning">Yes</Button>
                                    <Button onClick={this.handleBackgroundAuthToggle}>No</Button>
                                </ButtonGroup>
                            }
                            {!this.state.backgroundAuth &&
                                <ButtonGroup>
                                    <Button onClick={this.handleBackgroundAuthToggle}>Yes</Button>
                                    <Button bsStyle="warning">No</Button>
                                </ButtonGroup>
                            }
                        </Row>
                        <br/>
                        <Row>
                            <h3>Background check completed?</h3>
                            {this.state.backgroundCompleted &&
                                <ButtonGroup>
                                    <Button bsStyle="warning">Yes</Button>
                                    <Button onClick={this.handleBackgroundReceivedToggle}>No</Button>
                                </ButtonGroup>
                            }
                            {!this.state.backgroundCompleted &&
                                <ButtonGroup>
                                    <Button onClick={this.handleBackgroundReceivedToggle}>Yes</Button>
                                    <Button bsStyle="warning">No</Button>
                                </ButtonGroup>
                            }
                        </Row>
                        <br/>
                        <Row>
                            <h3>Google check completed?</h3>
                            {this.state.googleCompleted &&
                                <ButtonGroup>
                                    <Button bsStyle="warning">Yes</Button>
                                    <Button onClick={this.handleGoogleToggle}>No</Button>
                                </ButtonGroup>
                            }
                            {!this.state.googleCompleted &&
                                <ButtonGroup>
                                    <Button onClick={this.handleGoogleToggle}>Yes</Button>
                                    <Button bsStyle="warning">No</Button>
                                </ButtonGroup>
                            }
                        </Row>
                        <br/>
                        <Row>
                            <h3>Exclusionary check completed?</h3>
                            {this.state.exclusionaryListCompleted &&
                                <ButtonGroup>
                                    <Button bsStyle="warning">Yes</Button>
                                    <Button onClick={this.handleExclusionaryToggle}>No</Button>
                                </ButtonGroup>
                            }
                            {!this.state.exclusionaryListCompleted &&
                                <ButtonGroup>
                                    <Button onClick={this.handleExclusionaryToggle}>Yes</Button>
                                    <Button bsStyle="warning">No</Button>
                                </ButtonGroup>
                            }
                        </Row>
                    </Grid>
                </div>


            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer,
        communicationInfo: state.communicationReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSelectedApplicant: setSelectedApplicant,
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            sendEmail: sendEmail,
            resetEmailStatus: resetEmailStatus,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDocumentsForm);
