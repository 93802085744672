import React, { Component } from 'react';
import { Col, Row, Grid, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getActivities, addSubmission, resetActivities, upsertActivity, deleteActivity } from '../../actions/activityActions';


const allOrNothingTooltip = (
    <Tooltip id="tooltip">
        <strong>This activity will give all points for participation </strong>
    </Tooltip>
);

const percentageTooltip = (
    <Tooltip id="tooltip">
        <strong>This activity will give a final score based on how many the user gets right or wrong. </strong>
    </Tooltip>
);

class PointsInfoCard extends Component {
    constructor(props) {
        super();
        this.state = {
            minHeight: "600px"
        }


    }


    render() {
        return (
            <Grid style={{ minHeight: this.setState.minHeight }}>
                <Row>
                    <Col md={4} xs={12}>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <h3>Activity Point Value:</h3>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <input className='form-control' id='pointValue' type='text' name='pointValue' placeholder="Activity Name" value={this.props.pointValue} onChange={this.props.handleInputChange} />
                        </Row>
                    </Col>
                    <Col mdOffset={2} md={6} xs={12}>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <h3>Scoring Method:</h3>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            {this.props.scoringMethod == 'AllOrNothing' &&
                                <ButtonGroup>
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={allOrNothingTooltip}>

                                        <Button bsStyle="success" onClick={this.props.setAllOrNothing}>
                                            <span style={{ color: '#000', fontSize: 20 }}>All or Nothing</span>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={percentageTooltip}>
                                        <Button bsStyle="default" onClick={this.props.setPercentage}>
                                            <span style={{ color: '#000', fontSize: 20 }}>Percentage</span>
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonGroup>}
                            {this.props.scoringMethod == 'Percentage' &&
                                <ButtonGroup>
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={allOrNothingTooltip}>

                                        <Button bsStyle="default" onClick={this.props.setAllOrNothing}>
                                            <span style={{ color: '#000', fontSize: 20 }}>All or Nothing</span>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={percentageTooltip}>
                                        <Button bsStyle="success" onClick={this.props.setPercentage}>
                                            <span style={{ color: '#000', fontSize: 20 }}>Percentage</span>
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonGroup>}
                        </Row>
                    </Col>
                </Row>
                <br />
                <br />
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        activitiesInfo: state.activityReducer,
        hrInfo: state.hrReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivities: getActivities,
            addSubmission: addSubmission,
            upsertActivity: upsertActivity,
            resetActivities: resetActivities,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(PointsInfoCard);
