import React, { Component } from 'react';
import { Col, Row, Checkbox, Grid, Button, Tooltip, ButtonGroup, OverlayTrigger, } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { CSSTransitionGroup } from 'react-transition-group';
import ActivityInfoCard from './ActivityInfoCard'
import PointsInfoCard from './PointsInfoCard'
import { getActivities, addSubmission, resetActivities, upsertActivity, deleteActivity } from '../../actions/activityActions';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import QuestionsInfoCard from './QuestionsInfoCard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

var saveTooltip = (
    <Tooltip id="tooltip">
        <strong>Save Changes</strong>
    </Tooltip>
);

var backTooltip = (
    <Tooltip id="tooltip">
        <strong>Back to Table</strong>
    </Tooltip>
);

class NewActivityView extends Component {
    constructor(props) {
        super();
        this.state = {
            selectedIndex: 0,
            hasBeenSaved:false,
            _id: this.makeId()
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleActivityDescriptionChange = this.handleActivityDescriptionChange.bind(this);
        this.handleActivityImageChange = this.handleActivityImageChange.bind(this);
        this.setCreatedByValue = this.setCreatedByValue.bind(this)
        this.setApprovedByValue = this.setApprovedByValue.bind(this)
        this.setAllOrNothing = this.setAllOrNothing.bind(this)
        this.setPercentage = this.setPercentage.bind(this)
        this.setActivityType = this.setActivityType.bind(this)
        this.goToNextSection = this.goToNextSection.bind(this)
        this.goToPrevSection = this.goToPrevSection.bind(this)
        this.handleExpirationDateChange = this.handleExpirationDateChange.bind(this);
        this.setIndexToZero = this.setIndexToZero.bind(this)
        this.setIndexToOne = this.setIndexToOne.bind(this)
        this.setIndexToTwo = this.setIndexToTwo.bind(this)
        this.setIndexToThree = this.setIndexToThree.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.deleteQuestion = this.deleteQuestion.bind(this)
        this.updateQuestion = this.updateQuestion.bind(this)

    }


    getEmployeeById(id) {
        var objToReturn = {
            firstName: '',
            lastName: ''
        };
        if (id) {
            for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
                if (this.props.hrInfo.allEmployeesData[i]._id == id) {
                    objToReturn = this.props.hrInfo.allEmployeesData[i];
                }
            }
            return objToReturn;
        }
        return objToReturn;

    }
    componentWillMount() {
        this.props.resetActivities()
        this.setState({
            _id: this.makeId(),
            activityName: '',
            activityImage:'',
            departments: [],
            pointValue: 0,
            creationDate: new Date().toISOString(),
            createdBy: this.props.loginInfo.currentUser._id,
            createdByName: this.props.loginInfo.currentUser.firstName + ' ' + this.props.loginInfo.currentUser.lastName,
            approvedBy: '',
            expirationDate: new Date().toISOString(),
            scoringMethod: 'AllOrNothing', // All or Nothing or Percentage Based
            activityStatus: '',
            activityType: 'View Only',
            submissions: [],
            awardForActivity: [],
            activityDescription: '<br/>',
            questions: []
        })
    }

    goBack(event) {
        this.props.goToAnotherPage({
            pathname: '/myActivities'
        })
    }
    makeId() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    handleSubmit(status) {


        var activityData = {
            data: {
                "activityName": this.state.activityName,
                "activityImage": this.state.activityImage,
                "departments": this.state.departments,
                "pointValue": this.state.pointValue,
                "creationDate": this.state.creationDate,
                "createdBy": this.state.createdBy,
                "approvedBy": this.state.approvedBy,
                "expirationDate": this.state.expirationDate,
                "scoringMethod": this.state.scoringMethod, // All or Nothing or Percentage Based
                "activityStatus": status,
                "activityType": this.state.activityType,
                "submissions": this.state.submissions,
                "awardForActivity": this.state.awardForActivity,
                "activityDescription": this.state.activityDescription,
                "questions": this.state.questions,
            },
            _id: this.state._id,
            collection: 'activities'
        };

        this.props.upsertActivity(activityData)

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleActivityDescriptionChange(value) {
        if (value) {
            this.setState({ activityDescription: value })
        }
    }
    handleActivityImageChange(value) {
        if (value) {
            this.setState({ activityImage: value })
        }
    }
    handleExpirationDateChange(date) {
        const Sugar = require('sugar-date')


        if (date !== 'Invalid date') {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            this.setState({ expirationDate: date.toISOString() })
        }
        else {
            this.setState({ startDateError: true })
        }
    }
    goToNextSection() {
        if (this.state.selectedIndex < 3) { this.setState({ selectedIndex: this.state.selectedIndex + 1 }) }
    }

    goToPrevSection() {
        if (this.state.selectedIndex > 0) { this.setState({ selectedIndex: this.state.selectedIndex - 1 }) }
    }


    setCreatedByValue(id, value) {
        if (value)
            this.setState({ createdBy: id, createdByName: value })
    }
    setApprovedByValue(id, value) {
        if (value)
            this.setState({ approvedBy: id, approvedByName: value })
    }

    setAllOrNothing() {
        this.setState({ scoringMethod: 'AllOrNothing' })
    }

    setPercentage() {
        { this.setState({ scoringMethod: 'Percentage' }) }
    }
    setActivityType(value) {

        if (value)
            this.setState({ activityType: value })
        else
            this.setState({ activityType: '' })
    }

    setIndexToZero() {
        this.setState({ selectedIndex: 0 })
    }
    setIndexToOne() {
        this.setState({ selectedIndex: 1 })
    }
    setIndexToTwo() {
        this.setState({ selectedIndex: 2 })
    }
    setIndexToThree() {
        this.setState({ selectedIndex: 3 })
    }
    addQuestion(question) {

        var questions = this.state.questions
        questions.push(question)
        this.setState({ questions: questions })
    }

    deleteQuestion(index) {
        var oldQuestions = this.state.questions;
        oldQuestions.splice(index, 1);
        this.setState({ questions: oldQuestions })
    }

    updateQuestion(index, value) {
        var questions = this.state.questions;
        questions[index] = value;
        this.setState({ questions: questions })
    }

    submit = () => {
        if (this.state.activityName) {
            if (this.state.hasBeenSaved) {
                confirmAlert({
                    title: 'Changes',
                    message: 'Save Changes?',
                    buttons: [
                        {
                            label: 'Save and Close',
                            onClick: () => {
                                this.handleSubmit('Created')
                              
                                this.props.goToAnotherPage({
                                    pathname: '/myActivities'
                                })

                            }
                        },
                        {
                            label: 'Save',
                            onClick: () => {
                                this.handleSubmit('Created')
                            }
                        }
                    ]
                })
            }
            else {
                confirmAlert({
                    title: 'Confirm Creation',
                    message: 'Are you sure want to create activity?',
                    buttons: [
                        {
                            label: 'Save and Close',
                            onClick: () => {
                                this.handleSubmit('Created')
                                this.props.goToAnotherPage({
                                    pathname: '/myActivities'
                                })

                            }
                        },
                        {
                            label: 'Save ',
                            onClick: () => {
                                this.handleSubmit('Created')
                                this.setState({hasBeenSaved:true})
                            }
                        },
                        {
                            label: 'Cancel',
                            onClick: () => { }
                        }
                    ]
                })
            }
        }
        else {
            confirmAlert({
                title: 'Missing Title',
                message: 'Activity must have a name',
                buttons: [
                    {
                        label: 'Go Back',
                        onClick: () => { }
                    }
                ]
            })
        }
    };

    saved = () => {
       
    };


    render() {
      
        return (
            <div>

                <Grid>

                    <Row>
                        <Col md={8} xs={8}>
                            <h1>Create Activity</h1>
                        </Col>
                        <Col md={4} xs={4}>
                            <ButtonGroup>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={saveTooltip}>
                                    <Button onClick={this.submit}>
                                        <span style={{ color: '#5cb85c', fontSize: 25 }} ><FontAwesome.FaFloppyO /> Save Activity</span>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={backTooltip}>
                                    <Button onClick={this.goBack}>
                                        <span style={{ color: '#ff0000', fontSize: 25 }} >< Octicons.GoX /> Cancel</span>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <br />
                    {this.state.selectedIndex == 0 &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col >
                                <Button bsStyle="link" onClick={this.setIndexToZero} ><h3>Activity Info</h3></Button>
                            </Col>
                            <Col >
                                <Button bsStyle="link" onClick={this.setIndexToOne} ><h6>Questions</h6></Button>
                            </Col>
                        </Row>}

                    {this.state.selectedIndex == 1 &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col >
                                <Button bsStyle="link" onClick={this.setIndexToZero} ><h6>Activity Info</h6></Button>
                            </Col>
                            <Col >
                                <Button bsStyle="link" onClick={this.setIndexToOne} ><h3>Questions</h3></Button>
                            </Col>
                        </Row>}

                    <hr />
                </Grid>
                <Grid>
                    {this.state.selectedIndex == 0 && <ActivityInfoCard
                        handleActivityDescriptionChange={this.handleActivityDescriptionChange}
                        handleInputChange={this.handleInputChange}
                        handleExpirationDateChange={this.handleExpirationDateChange}
                        handleActivityImageChange={this.handleActivityImageChange}
                        activityName={this.state.activityName}
                        activityImage={this.state.activityImage}
                        activityType={this.state.activityType}
                        activityDescription={this.state.activityDescription}
                        expirationDate={this.state.expirationDate}
                        pointValue={this.state.pointValue}
                        setStatusValue={this.setActivityType}
                        setCreatedByValue={this.setCreatedByValue}
                        setApprovedByValue={this.setApprovedByValue}
                        createdByName={this.state.createdByName}
                        approvedByName={this.state.approvedByName}
                    />}

                   

                    {this.state.selectedIndex == 1 && <QuestionsInfoCard
                        handleInputChange={this.handleInputChange}
                        questions={this.state.questions}
                        addQuestion={this.addQuestion}
                        deleteQuestion={this.deleteQuestion}
                        updateQuestion={this.updateQuestion}

                    />}

                    <br />

                    <Row style={{ display: 'flex', justifyContent: 'center' }}>

                        <Col >
                            <Button onClick={this.submit}>
                                <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaFloppyO /> Save Activity</span>
                            </Button>
                        </Col>
                    </Row>


                </Grid>

                <br />
                <br />

                <br />
            </div >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        activitiesInfo: state.activityReducer,
        hrInfo: state.hrReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivities: getActivities,
            addSubmission: addSubmission,
            upsertActivity: upsertActivity,
            resetActivities: resetActivities,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(NewActivityView);
