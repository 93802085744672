import React, { Component } from 'react';
import { Col, Grid, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getAllHRData, setSelectedApplicant, upsertHRData } from '../../actions/hrActions';

class NotesForm extends Component {
    constructor(props) {
        super();
        this.state = {

            openPosition: false,
            openContact: false,
            notes:[]
        }
        this.addNote = this.addNote.bind(this);
        this.removeNote = this.removeNote.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }


    componentDidMount() {
        if (Object.keys(this.props.hrInfo.selectedApplicant.data.employmentInfo).length > 0) {
            this.setState({
                notes: this.props.hrInfo.selectedApplicant.data.hiringInfo.notes ? this.props.hrInfo.selectedApplicant.data.hiringInfo.notes : []
            })
        }
        else
        {
            this.setState({
                notes: []
            })
        }

    }

    getEmployeeById(id) {
        for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
            if (this.props.hrInfo.allEmployeesData[i]._id === id) {
                return this.props.hrInfo.allEmployeesData[i];
            }
        }
    }
    getLocationById(id) {
        for (var i = 0; i < this.props.hrInfo.allLocationsData.length; i++) {
            if (this.props.hrInfo.allLocationsData[i]._id === id) {
                return this.props.hrInfo.allLocationsData[i];
            }
        }
    }

    makeId() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    addNote(event) {
        event.preventDefault();
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();

        var currentDate = month + '/' + day + '/' + year + ' ' + hour + ':' + minute + ':' + second;
        var notes = this.state.notes
        notes.push({ message: this.state.newNote, timeStamp: currentDate })
        var upsertData =
        {
            _id: this.props.hrInfo.selectedApplicant._id ? this.props.hrInfo.selectedApplicant._id : this.makeId(),
            collection: 'applicants',
            data: {
                personalInfo: this.props.hrInfo.selectedApplicant.data.personalInfo,
                employmentInfo: this.props.hrInfo.selectedApplicant.data.employmentInfo,
                hiringInfo: {
                    hiringStatus:this.props.hrInfo.selectedApplicant.data.hiringInfo.hiringStatus,
                    applicationCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.applicationCompleted,
                    offerLetterSentDate:this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSentDate,
                    offerLetterSigned:this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSigned,
                    estimatedStartDate:this.props.hrInfo.selectedApplicant.data.hiringInfo.estimatedStartDate,
                    iTCredentials: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials,
                    equipmentRequested: this.props.hrInfo.selectedApplicant.data.hiringInfo.equipmentRequested,
                    trainingInGR: this.props.hrInfo.selectedApplicant.data.hiringInfo.trainingInGR,
                    wage:this.props.hrInfo.selectedApplicant.data.hiringInfo.wage,
                    notes:notes,
                    backgroundAuth:this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundAuth,
                    backgroundCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted,
                    exclusionaryListCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted,
                    googleCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted,
                    signedAddendumAndOtherReceived:this.props.hrInfo.selectedApplicant.data.hiringInfo.signedAddendumAndOtherReceived,
                    signedPacketReceieved:this.props.hrInfo.selectedApplicant.data.hiringInfo.signedPacketReceieved,
                    applicantInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted, 
                    employmentInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted, 
                    iTInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted,
                    onboardingDocumentsEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent,
                    offerEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent,
                    welcomeEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent,
                    credentialsEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent,
                },
                licensingInfo: this.props.hrInfo.selectedApplicant.data.licensingInfo,
                itInfo: this.props.hrInfo.selectedApplicant.data.itInfo
            }
        }
        //Update in Collection
        this.props.upsertHRData(upsertData);
        this.props.setSelectedApplicant({ selectedApplicant: upsertData });

    }

    removeNote(index) {
        var notes = this.props.hrInfo.selectedApplicant.data.hiringInfo.notes
        notes.splice(index, 1);
        var upsertData =
        {
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants',
            data: {
                personalInfo: this.props.hrInfo.selectedApplicant.data.personalInfo,
                employmentInfo: this.props.hrInfo.selectedApplicant.data.employmentInfo,
                hiringInfo: {
                    hiringStatus: this.props.hrInfo.selectedApplicant.data.hiringInfo.hiringStatus,
                    applicationCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.applicationCompleted,
                    offerLetterSentDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSentDate,
                    offerLetterSigned: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSigned,
                    estimatedStartDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.estimatedStartDate,
                    trainingOnSite: this.props.hrInfo.selectedApplicant.data.hiringInfo.trainingOnSite,
                    wage: this.props.hrInfo.selectedApplicant.data.hiringInfo.wage,
                    notes: notes,//This is why
                    backgroundAuth: this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundAuth,
                    backgroundCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted,
                    signedAddendumAndOtherReceived: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedAddendumAndOtherReceived,
                    signedPacketReceieved: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedPacketReceieved,
                },
                licensingInfo: this.props.hrInfo.selectedApplicant.data.licensingInfo,
                itInfo: this.props.hrInfo.selectedApplicant.data.itInfo
            }
        }
        //Update in Collection
        this.props.upsertHRData(upsertData);
        this.props.setSelectedApplicant({ selectedApplicant: upsertData });


    }
    onKeyPress(event) {
        if (event.which === 13) {
            event.preventDefault();
        }
    }
    handleChange(event) {
        this.setState({ newNote: event.target.value });
    }


    getNotes() {
        if (this.state.notes) {
            var notes = this.state.notes.map((note, i) => {
                if (note.timeStamp) {
                    return (<Row>
                        <Col md={11} sm={12}>
                            <h3>Note saved on {note.timeStamp}</h3>
                            <h4>{note.message}</h4>
                        </Col>
                        <Col md={1} sm={12}>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={<Tooltip id="tooltip">
                                <strong>Delete Note</strong>
                            </Tooltip>}>
                                <Button bsStyle="default" onClick={() => this.removeNote(i)}>
                                    <span style={{ color: '#d9534f', fontSize: 30 }}><FontAwesome.FaClose /></span>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>)
                }
            })
            return notes;
        }
        else {
            return (<Row></Row>)
        }
    }
    render() {
        var notes = this.getNotes();
        return (
            <div>
                <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                    <h1 style={{ float: 'left' }}>Notes</h1>
                    <br />
                    <br />
                    <form onSubmit={this.addNote} onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                        <br />
                        <Grid>
                            <Row>
                                <textarea value={this.state.newNote} onChange={this.handleChange} />
                            </Row>
                        </Grid>
                        <br />
                        <button className='form-control btn btn-success'>Add Note</button>
                    </form>
                    <br />
                    {notes}
                    <br />
                    <div id='error'></div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData: getAllHRData,
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(NotesForm);
