import React, { Component } from 'react';
import {Col} from 'react-bootstrap'
class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="">
                <hr/>
                   <Col>
                        <p style={{ textAlign: 'center',  }}>
                        Powered By Athena<br />
                        </p>
                    </Col>
            </footer>
        )
    }
}



export default Footer;
