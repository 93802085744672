import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getAllHRData } from '../../actions/hrActions';
import HiringTable from '../tables/hr_tables/HiringTable';
import { Tooltip, OverlayTrigger, Grid, Col, Row, Button, ButtonGroup } from 'react-bootstrap'
import { Form, Icon, Message } from 'semantic-ui-react';
class HiringView extends Component {
    constructor(props) {
        super();
        this.state = {
            borrowerInfo: {},
            gotCompStatus: false,
            formStatus: 'EDITING'
        }

    }


    render() {
        return (

            <div>
                <Grid>
                    <h1>Hiring Pipeline</h1>
                    <HiringTable />
                </Grid>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData: getAllHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HiringView);
