export const resetHRStatus = () => {
    return {
        type: 'RESET_HR_STATUS'
    }
};
export const getAllHRData = (payload) => {
    return {
        type: 'GET_ALL_HR_DATA',
        payload:payload
    }
};
export const upsertHRData = (payload) => {
    return {
        type: 'UPSERT_HR_DATA',
        payload:payload
    }
};
export const deleteHRData = (payload) => {
    return {
        type: 'DELETE_HR_DATA',
        payload:payload
    }
};

export const setSelectedApplicant = (payload) => {
    return {
        type: 'SET_SELECTED_APPLICANT',
        payload:payload
    }
};