
export const sendEmail = (messageData) => {

    return {
        type: 'SEND_EMAIL',
        payload:messageData
    }
};


export const resetEmailStatus = () => {

    return {
        type: 'SEND_EMAIL_RESET'
    }
};

export const sendSMS = () => {

    return {
        type: 'SEND_SMS'
    }
};

export const resetCommunication = () => {

    return {
        type: 'COMMUNICATION_RESET'
    }
};
export const resetSMSStatus = () => {

    return {
        type: 'SEND_SMS_RESET'
    }
};

export const createTicket = (ticketData) => {

    return {
        type: 'CREATE_TICKET',
        payload:ticketData
    }
};

export const resetCreateTicketStatus = () => {

    return {
        type: 'CREATE_TICKET_RESET'
    }
};

export const getTicket = (ticketData) => {

    return {
        type: 'GET_TICKET',
        payload:ticketData
    }
};

export const resetClearGetTicketStatus = () => {

    return {
        type: 'GET_TICKET_RESET'
    }
};

export const uploadExclusionaryFile = (fileData) => {

    return {
        type: 'UPLOAD_EXCLUSIONARY_FILE',
        payload:fileData
    }
};

export const getSurvey = (surveyData) => {

    return {
        type: 'GET_SURVEY',
        payload:surveyData
    }
};