import React, { Component } from 'react';
import { Row, Nav, NavItem, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../actions/loginActions';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import DepartmentsForm from './DepartmentsForm';
import EmployeesForm from './EmployeesForm';
import LocationsForm from './LocationsForm';
import RegionsForm from './RegionsForm';
import RolesForm from './RolesForm';
import TitlesForm from './TitlesForm';
import EmailForm from './EmailForm';
import CredentialOptionsForm from './CredentialOptionsForm';
import EmailDistroGroupsForm from './EmailDistroGroupsForm';
class HRView extends Component {
    constructor(props) {
        super();
        this.state = {
            hrPage: 'EmployeesInfo',
            gotCompStatus: false,
            formStatus: 'EDITING',
            width: 0,
            height: 0
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    handleSelect(hrPage) {
        this.setState({ hrPage: hrPage })
    }
    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        return (
            <div>
                {(this.props.hrInfo.allRolesData && this.props.hrInfo.allRegionsData && this.props.hrInfo.allDepartmentsData && this.props.hrInfo.allTitlesData && this.props.hrInfo.allLocationsData && this.props.hrInfo.allEmployeesData&& this.props.hrInfo.allCredentialsData&& this.props.hrInfo.allEmailDistroGroupsData) &&
                    <Grid style={{ width: '90%' }}>

                        {this.state.width <= 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                            <NavItem eventKey="EmployeesInfo" style={{ textAlign: 'center' }}><FontAwesome.FaUser style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="RegionsInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGlobe style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="RolesInfo" style={{ textAlign: 'center' }}><FontAwesome.FaListUl style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="DepartmentsInfo" style={{ textAlign: 'center' }}><FontAwesome.FaBuilding style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="TitlesInfo" style={{ textAlign: 'center' }}><FontAwesome.FaBookmark style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="LocationsInfo" style={{ textAlign: 'left' }}><FontAwesome.FaCompass style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="EmailInfo" style={{ textAlign: 'center' }}><FontAwesome.FaEnvelope style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="EmailDistroGroupsInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGroup style={{ fontSize: 20 }} /></NavItem>
                                <NavItem eventKey="CredentialsInfo" style={{ textAlign: 'center' }}><FontAwesome.FaCheck style={{ fontSize: 20 }} /></NavItem>   
                            </Nav>

                        </Row>}
                        {this.state.width > 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                            <NavItem eventKey="EmployeesInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaUser style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Employees</Row></NavItem>
                                <NavItem eventKey="RegionsInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaGlobe style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Regions</Row></NavItem>
                                <NavItem eventKey="RolesInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaListUl style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Roles</Row></NavItem>
                                <NavItem eventKey="DepartmentsInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaBuilding style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Departments</Row></NavItem>
                                <NavItem eventKey="TitlesInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaBookmark style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Titles</Row></NavItem>
                                <NavItem eventKey="LocationsInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaCompass style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Locations</Row></NavItem>
                                <NavItem eventKey="EmailInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaEnvelope style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Email Temp.</Row></NavItem>
                                <NavItem eventKey="EmailDistroGroupsInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaGroup style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Email Groups</Row></NavItem>
                                <NavItem eventKey="CredentialsInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaCheck style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Credentials</Row></NavItem>
                            </Nav>

                        </Row>}
                        <Row>
                            {this.state.hrPage === 'EmployeesInfo' && <EmployeesForm />}
                            {this.state.hrPage === 'LocationsInfo' && <LocationsForm />}
                            {this.state.hrPage === 'DepartmentsInfo' && <DepartmentsForm />}
                            {this.state.hrPage === 'RegionsInfo' && <RegionsForm />}
                            {this.state.hrPage === 'RolesInfo' && <RolesForm />}
                            {this.state.hrPage === 'TitlesInfo' && <TitlesForm />}
                            {this.state.hrPage === 'EmailInfo' && <EmailForm />}
                            {this.state.hrPage === 'CredentialsInfo' && <CredentialOptionsForm />}
                            {this.state.hrPage === 'EmailDistroGroupsInfo' && <EmailDistroGroupsForm />}
                        </Row>

                    </Grid>}

                {(this.props.hrInfo.getAllHRDataStatus === 'NOT_STARTED' || this.props.hrInfo.getAllHRDataStatus === 'STARTED') &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HRView);
