import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
// Import the default style
import 'rc-datepicker/lib/style.css';
import React, { Component } from 'react';
import { Button, Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../../actions/loginActions';
import { getPipelineNumbersReport, resetPipeline } from '../../../actions/pipelineActions';
import { getTotalDollars } from '../../../helpers/getDollars';
import { getReportByDayRange, getReportByIntervalRange } from '../../../helpers/getLoans';
import LeaderTableDetailModal from '../../modals/pipeline_modals/LeaderTableDetailModal';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';


const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const graphColors = ['#D5813E', 'gold', '#FFF', 'yellow', '#D5FF33', '#F933FF', '#EEE', '#33FFFF']
const date = new Date();
const curMonth = date.getMonth() + 1;
const curDay = date.getDate();
const curYear = date.getFullYear();
const currentDate = curMonth + '/' + curDay + '/' + curYear;
const Sugar = require('sugar-date')
const startDate = Sugar.Date.create('3 Months ago').getMonth() + 1 + '/' + Sugar.Date.create('3 Months ago').getDate() + '/' + Sugar.Date.create('3 Months ago').getFullYear();
const reportType = {
    'PreApplicationDate': ["745", "Pre-Applications"],
    'ApplicationDate': ["3142", "Applications"],
    'SubmittedToProcDate': ["LOG.MS.DATE.Processing", "Submitted to Processing"],
    'SubmittedToUWDate': ["2298", "Submitted to Underwriting"],
    'LockedDate': ["2149", "Locked"],
    'FundedDate': ["1997", "Funded"],
    'ResubmittalDate': ["Log.MS.DATE.Resubmittal", "Resubmittals"],
    'ShippedDate': ["2014", "Shipped"],
    'DecisionMadeDate': ["2301", "Decisions Made"],
}
class PipelineReportView extends Component {
    constructor(props) {
        super();
        this.state = {
            field: 'PreApplicationDate',
            dataLimitMessage: '',
            filterID: '745',
            filterText: 'Pre-Application',
            graphInterval: undefined,
            sortType: 'LOName',
            startDate: startDate,
            endDate: currentDate,
            graphData: (<h1>Table</h1>),
            labelFontSize: '.5rem',
            isLoading: false,
            leaderList: undefined,
            sortedData: undefined,
            hasError: false,
            showTable: false,
            showDetailModal: false,
            showLinkAppModal: false,
            tableView: '',
            isProcessed: false,
            showCount: false,
            noGraphData: false,
            countGraphData: (
                <div style={{ borderColor: 'white', borderWidth: '3px', borderStyle: 'solid' }}>
                    <h1 style={{ textAlign: 'center' }}>Select Values to display on Graph</h1>
                    <br />
                </div>),
            currentLoan: undefined,
            showGraph: false,
            gotPipeStatus: true,
            gotLinkStatus: false,
            chartWidth: 0,
            xAxisLabel: '',
            appraisalM: '',
            appraisalLM: '',
            appraisalLMT: '',
            Queue: '',
            Est: '',

        }
        this.changeField = this.changeField.bind(this);
        this.changeSort = this.changeSort.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLeaders = this.getLeaders.bind(this);
        this.hideTableView = this.hideTableView.bind(this);
        this.determineInterval = this.determineInterval.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
        this.hideDetailModal = this.hideDetailModal.bind(this);
        this.hideGraph = this.hideGraph.bind(this);
        this.isInRange = this.isInRange.bind(this);
        this.compareGraph = this.compareGraph.bind(this);
        this.getGraphLabelSize = this.getGraphLabelSize.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.getAxisLabel = this.getAxisLabel.bind(this);
        this.logout = this.logout.bind(this);
        this.printTable = this.printTable.bind(this);
    }

    printTable() {

        const { vfs } = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        var month = date.getMonth() + 1;
 
        var documentDefinition = {
            info: {
                title: 'PipelineNumbers'+ month + '_' + date.getDate() + '_' + date.getFullYear(),
              },
            header: {
                columns: [
                    { text: 'Pipeline Numbers: ' + month + '/' + date.getDate() + '/' + date.getFullYear(), style: 'title' }
                ]
            },
            content: [
              {
                columns: [
                  {
                    // auto-sized columns have their widths based on their content
                    width: '30%',
                    text: 'Pipeline'
                  },
                  {
                    // star-sized columns fill the remaining space
                    // if there's more than one star-column, available width is divided equally
                    width: '23%',
                    text: this.props.pipelineInfo.pipelineNumbersReportData.pipeline
                  }
                ],
            },
            {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Working Pipeline'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: this.props.pipelineInfo.pipelineNumbersReportData.working
                    }
                  ],
                },
                {
                    columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Processing Pipeline'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: this.props.pipelineInfo.pipelineNumbersReportData.processing,
                      height: 50
                    }
                  ],
              },
            {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Applications'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.appMTD
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'LMTD ' +this.props.pipelineInfo.pipelineNumbersReportData.appLMTD
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Last month ' +this.props.pipelineInfo.pipelineNumbersReportData.appLMT
                    }
                  ],
              },
              {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Submitted to Processing'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.procMTD
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'LMTD ' +this.props.pipelineInfo.pipelineNumbersReportData.procLMTD
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Last month ' +this.props.pipelineInfo.pipelineNumbersReportData.procLMT
                    }
                  ],
              },
              {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Submitted to Underwriting'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.uwMTD
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'LMTD ' +this.props.pipelineInfo.pipelineNumbersReportData.uwLMTD
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Last month ' +this.props.pipelineInfo.pipelineNumbersReportData.uwLMT
                    }
                  ],
            },
            {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Appraisals'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.appraisalM
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'LMTD ' +this.props.pipelineInfo.pipelineNumbersReportData.appraisalLM
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Last month ' +this.props.pipelineInfo.pipelineNumbersReportData.appraisalLMT
                    }
                  ],
              },
              {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Funding'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.fundedMTD
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'LMTD ' +this.props.pipelineInfo.pipelineNumbersReportData.fundedLMTD
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Last month ' +this.props.pipelineInfo.pipelineNumbersReportData.fundedLMT
                    }
                  ],
              },
              {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '30%',
                      text: 'Est Fundings'
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '23%',
                      text: 'MTD ' +this.props.pipelineInfo.pipelineNumbersReportData.fundedMTD
                    },
                    {
                      // fixed width
                      width: '23%',
                      text: 'Queue ' +this.props.pipelineInfo.pipelineNumbersReportData.Queue
                    },
                    {
                      // % width
                      width: '23%',
                      text: 'Est ' +this.props.pipelineInfo.pipelineNumbersReportData.Est
                    }
                  ],
              },
              {
                columns: [
                    {
                      // auto-sized columns have their widths based on their content
                      width: '20%',
                      text: 'Total',
                      fontSize: 20
                    },
                    {
                      // star-sized columns fill the remaining space
                      // if there's more than one star-column, available width is divided equally
                      width: '20%',
                      text: this.props.pipelineInfo.pipelineNumbersReportData.monthTotal,
                      fontSize: 20
                    }
                  ],
              },
            ],
            styles: {
            
                title: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 15, 50, 0]
                }
            }
          }
        pdfMake.createPdf(documentDefinition).download('PipelineNumbers'+ month + '_' + date.getDate() + '_' + date.getFullYear()+'.pdf');
    }

    componentDidMount() {
        //Checks to see if the user is linked
        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })
        }
        this.setState({
            chartWidth: window.innerWidth,
            isLoading: false
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }
    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }
    changeField(event) {
        this.setState({ field: event.target.value, filterID: reportType[event.target.value][0], filterText: reportType[event.target.value][1], showTable: false, showGraph: false })
    }

    changeSort(event) {
        this.setState({ sortType: event.target.value, showTable: false, showGraph: false })
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        this.setState({ showTable: false, showGraph: false })
        // if (this.state.endDateError || this.state.startDateError) {
        //     alert('Date Range invalid.  Ensure that dates are of the form MM/DD/YYYY.')
        //     return;
        // }
        // if (Date.parse(this.state.startDate) > Date.parse(this.state.endDate)) {
        //     alert('Date Range invalid. Start Date is after end date.')
        //     return;
        // }

        var Sugar = require('sugar-date')

        var today = new Date();
        // var mEndDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()
        var mEndDate = Sugar.Date.create('Last Day of This Month');
        var newDataStartMonth = mEndDate.getMonth() + 1;
        var newDataStartDay = mEndDate.getDate();
        var newDataStartYear = mEndDate.getFullYear();
        var mEndDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;
        //var mEndDate = Sugar.Date.create('Today');
        
        var mStartDate = Sugar.Date.create('First Day of Month');
        var newDataStartMonth = mStartDate.getMonth() + 1;
        var newDataStartDay = mStartDate.getDate();
        var newDataStartYear = mStartDate.getFullYear();
        var mStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var lmStartDate = Sugar.Date.create('First Day of Last Month');
        var newDataStartMonth = lmStartDate.getMonth() + 1;
        var newDataStartDay = lmStartDate.getDate();
        var newDataStartYear = lmStartDate.getFullYear();
        var lmStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var lmEndDate = Sugar.Date.create('Last Day of Last Month');
        var newDataStartMonth = lmEndDate.getMonth() + 1;
        var newDataStartDay = lmEndDate.getDate();
        var newDataStartYear = lmEndDate.getFullYear();
        var lmEndDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var lmTD = Sugar.Date.create('Last Month');
        var newDataStartMonth = lmTD.getMonth() + 1;
        var newDataStartDay = lmTD.getDate();
        var newDataStartYear = lmTD.getFullYear();
        var lmTD = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        // var today = new Date();
        // var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
            mStartDate: mStartDate,
            mEndDate: mEndDate,
            lmStartDate: lmStartDate,
            lmEndDate: lmEndDate,
            lmTD: lmTD,
            appraisalM: this.state.appraisalM,
            appraisalLM: this.state.appraisalLM,
            appraisalLMT: this.state.appraisalLMT,
            Queue: this.state.Queue,
            Est: this.state.Est
            // filter: this.state.filterID,
            // field: this.state.field,
            // sort: this.state.sortType,
            // startDate: this.state.startDate,
            // newDataStartDate: newDataStartDate,
            // endDate: this.state.endDate,
            // todayDate: todayDate
        }
        // if (pipelineParam.filter == "2301") {
        //     this.props.getPipelineReportDecisions(pipelineParam);
        // }
        // else {
        //     this.props.getPipelineReport(pipelineParam);
        // }

        this.props.getPipelineNumbersReport(pipelineParam)
        this.setState({ isLoading: true })

    }

    getAxisLabel(dateString, interval) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')
        if (interval === 'Day') {
            return dateStringParts[0] + '/' + dateStringParts[1]
        }
        else if (interval === 'Week') {
            var lastSplit = dateStringParts[2].split('-');

            var lastDate = this.state.endDate.split('/')[1] === dateStringParts[3] ? parseInt(dateStringParts[3]) : parseInt(dateStringParts[3]) - 1;
            if (dateStringParts[0] + '/' + dateStringParts[1] === lastSplit[1] + '/' + lastDate) {
                return dateStringParts[0] + '/' + dateStringParts[1]
            }
            else {
                return dateStringParts[0] + '/' + dateStringParts[1] + '-' + lastSplit[1] + '/' + lastDate
            }
        }
        else if (interval === 'Month' || interval === 'Year') {

            var startDate = dateString.split('-')[0];
            var startDateParts = startDate.split('/');
            var formattedStartDate = startDateParts[0] + '/' + startDateParts[1];

            var endDate = dateString.split('-')[1];
            var endDateParts = endDate.split('/');
            var formattedEndDate = endDateParts[0] + '/' + endDateParts[1] + '/' + endDateParts[2];

            if (interval === 'Month' && (endDateParts[1] === '1' && startDateParts[1] === '1')) {
                return startDateParts[0] + '/' + startDateParts[2];
            }
            else if (interval === 'Year' && (endDateParts[0] === '1' && startDateParts[0] === '1' && endDateParts[1] === '1' && startDateParts[1] === '1')) {
                return startDateParts[2];
            }
            else {
                return formattedStartDate + '-' + formattedEndDate;
            }
        }
    }

    removeDuplicates(arr) {
        let unique_array = []
        let uniqueGuid = []
        for (let i = 0; i < arr.length; i++) {
            if (uniqueGuid.indexOf(arr[i].loanGuid) == -1) {
                uniqueGuid.push(arr[i].loanGuid)
                unique_array.push(arr[i])
            }
        }
        return unique_array
    }

    getLeaders(loanList) {
        var leaders = {};
        //Creates Object of LOs with their associated Loans
       
        if (loanList.length > 0) {
            loanList.map((loan) => {
               
                if (loan[this.state.sortType]) {

                    if (leaders[loan[this.state.sortType]]) {
                        leaders[loan[this.state.sortType]].push(loan)
                        leaders[loan[this.state.sortType]] = this.removeDuplicates(leaders[loan[this.state.sortType]])
                    }
                    else {
                        leaders[loan[this.state.sortType]] = [loan]
                    }
                }
                else if (leaders['Undefined ' + this.state.sortType]) {
                    leaders['Undefined ' + this.state.sortType].push(loan)
                }
                else {
                    leaders['Undefined ' + this.state.sortType] = [loan];
                }
            });

        }
        //Create Object that contains name, count of loans, total dollar value of each loan
        var finalLeaderInfo = {}
        Object.keys(leaders).map((leader) => {
            finalLeaderInfo[leader] = {
                count: leaders[leader].length,
                totalDollars: getTotalDollars(leaders[leader]),
                loans: leaders[leader]
            }

        })
        return finalLeaderInfo;


    }

    hideTableView() {
        this.setState({ showTable: false });
    }
    hideGraph() {
        this.setState({ showGraph: false });
    }
    getGraphLabelSize(values) {

        if (Object.keys(this.state.sortedData).length <= 6 && values.length <= 2) {
            return '2rem'
        }
        else if (Object.keys(this.state.sortedData).length > 6 && values.length <= 2) {
            return '1.5rem'
        }
        else if (Object.keys(this.state.sortedData).length > 6 && values.length === 3) {
            return '1.5rem'
        }
        else if (Object.keys(this.state.sortedData).length <= 6 && values.length === 3) {
            return '1rem'
        }
        else {
            return '5'
        }
    }
    isInRange(from, to, dateTimeString) {
        if (dateTimeString) {
            var check = dateTimeString.split(' ')[0];

            var fDate, lDate, cDate;
            fDate = Date.parse(from);
            lDate = Date.parse(to);
            cDate = Date.parse(check);

            if ((cDate < lDate && cDate >= fDate)) {
                return true;
            }
            return false;
        }
        else {
            return false
        }
    }


    compareGraph(values) {
        this.setState({ showGraph: true });
        if (values.length > 3) {
            this.setState({ graphMessage: "Graphs that compare more than 3 selections may result in unreadable graphs." })
        }
        else if (values.length === 0) {
            this.setState({
                noGraphData: true,
                countGraphData: (
                    <div style={{ borderColor: 'white', borderWidth: '3px', borderStyle: 'solid' }}>
                        <h1 style={{ textAlign: 'center' }}>Select Values to display on Graph</h1>
                        <br />
                    </div>)
            });
            return;
        }

        var objectsToCompare = {}
        var legendData = [];
        var lines = [];
        var newGraphData = [];

        if (this.state.graphInterval !== 'Day') {
            var newGraphDataPoint = {};
            Object.keys(this.state.sortedData).map((date, index) => {

                var from = date.split('-')[0];
                var to = date.split('-')[1];
                var newGraphDataPoint = {};

                newGraphDataPoint['date'] = this.getAxisLabel(date, this.state.graphInterval);

                values.map((sortVal) => {
                    var tempArray = [];
                    this.props.pipelineInfo.pipelineReportData.map((loan) => {
                        if (loan[this.state.sortType] === sortVal && this.isInRange(from, to, loan[this.state.field])) {
                            tempArray.push(loan)
                            tempArray = this.removeDuplicates(tempArray)
                        }
                    })
                    newGraphDataPoint[sortVal] = tempArray.length;
                })
                newGraphData.push(newGraphDataPoint)
            });
            //This is needed because of the how the data is being sorted
            newGraphData = newGraphData.reverse();
        }
        else {

            Object.keys(this.state.sortedData).map((date, index) => {
                var newGraphDataPoint = {};
                newGraphDataPoint['date'] = this.getAxisLabel(date, this.state.graphInterval);

                values.map((sortVal) => {
                    var tempArray = [];
                    this.props.pipelineInfo.pipelineReportData.map((loan) => {
                        if (loan[this.state.sortType] === sortVal && date === loan[this.state.field]) {
                            tempArray.push(loan)
                        }
                    })
                    newGraphDataPoint[sortVal] = tempArray.length;
                })
                newGraphData.push(newGraphDataPoint)
            });
        }
        //Create Lines for Every Sort Type
        var lines = values.map((sortVal, index) => {
            var lineColor = '';
            //Want to start with the same colors for 5 values that are being compared, but after that we will need to dynamically generate colors
            if (graphColors[index]) {
                lineColor = graphColors[index];
            }
            else {
                lineColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            }
            return (<Line type="monotone" dataKey={sortVal} stroke={lineColor} />)

        })
        //Determine X Axis Label
        var xAxisLabel = ''
        if (this.state.graphInterval === 'Day') {
            xAxisLabel = 'Day';
        }
        else if (this.state.graphInterval === 'Week') {
            xAxisLabel = 'Week Start Date';
        }
        else if (this.state.graphInterval === 'Month') {
            xAxisLabel = 'Month';
        }
        else if (this.state.graphInterval === 'Year') {
            xAxisLabel = 'Year';
        }
        this.setState({
            countGraphData: (
                <div>
                    <ResponsiveContainer width='100%' aspect={4.0 / 2.0}>
                        <LineChart data={newGraphData}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="date" tickLine={false} stroke='white' />
                            <YAxis tickLine={false} stroke='white' />
                            <Tooltip animationEasing='ease-out' wrapperStyle={{ backgroundColor: '#343a40' }}
                                itemSorter={(values) => {
                                    //TODO How do you actually Sort
                                }} />
                            {lines}
                        </LineChart>
                    </ResponsiveContainer>
                    <h3 style={{ textAlign: 'center', color: 'white' }}>{xAxisLabel}</h3>
                </div>)
        })

    }

    changeDateRange() {
        //Close Table if it is open
        this.setState({ showTable: false });

        var interval = this.determineInterval(this.state.startDate, this.state.endDate);
        this.setState({ graphInterval: interval });


        if (interval === 'Day') {
            var todayRange = getReportByDayRange(this.state.startDate, this.state.endDate, this.props.pipelineInfo.pipelineReportData, this.state.field);
            this.setState({ sortedData: todayRange })
        }
        else {
            var intervalRange = getReportByIntervalRange(this.state.startDate, this.state.endDate, this.props.pipelineInfo.pipelineReportData, this.state.field, interval);
            this.setState({ sortedData: intervalRange })
        }

    }

    //Dynamically determine the intervals for displaying the data
    determineInterval(startDate, endDate) {
        var startDateStringParts = startDate.split('/')
        var startDateObj = new Date(startDateStringParts[2], startDateStringParts[0] - 1, startDateStringParts[1]);
        var startDateInMilli = startDateObj.getTime();

        var endDateStringParts = endDate.split('/')
        var endDateObj = new Date(endDateStringParts[2], endDateStringParts[0] - 1, endDateStringParts[1]);
        var endDateInMilli = endDateObj.getTime();

        var range = endDateInMilli - startDateInMilli;
        const twoWeeksMilli = 1209600000;
        const twoMonthsMilli = 4838400000;
        const oneYearMillli = 31449600000;

        // If range is less than 2 weeks group by Day
        if (range < twoWeeksMilli) {
            return 'Day';
        }
        //If range is less than 2 months group by week
        else if (range < twoMonthsMilli) {
            return 'Week';
        }
        //If range is less than 1.5 years group by month
        else if (range < oneYearMillli) {
            return 'Month';
        }
        // If Range is greater than 1.5 years group by year
        else {
            return 'Year';
        }
    }

    handleStartDateChange(date) {
        if (date !== 'Invalid date') {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            this.setState({ startDate: newMonth + '/' + newDate + '/' + newYear, startDateError: false })
        }
        else {
            this.setState({ startDateError: true })
        }
    }
    handleEndDateChange(date) {
        if (date !== 'Invalid date') {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            this.setState({ endDate: newMonth + '/' + newDate + '/' + newYear, endDateError: false })
        }
        else {
            this.setState({ endDateError: true })
        }
    }

    hideDetailModal() {
        this.setState({ showDetailModal: false });
    }
    getDetailModal() {
        return (<LeaderTableDetailModal details={this.state.detailList} modalIsOpen={this.state.showDetailModal} closeModal={this.hideDetailModal} />)
    }
    showDetailModal(detailList) {
        this.setState({ showDetailModal: true, detailList: detailList })
    }
    hideLinkAppModal() {
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }
    render() {
        
        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }


        if (this.props.pipelineInfo.getPipelineNumbersReportStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: true, isLoading: false })
            var leaderList = this.getLeaders(this.props.pipelineInfo.pipelineReportData)
            this.changeDateRange()
            this.setState({ leaderList: leaderList, showTable: true, })
        }
        //Handle Failed Scenarios
        if (this.props.pipelineInfo.getPipelineNumbersReportStatus === 'FAILED' && !this.state.gotPipeStatus) {
            alert('Invalid User')
            this.props.goToAnotherPage({
                pathname: '/login'
            })
            this.setState({ gotPipeStatus: true });
        }
        if (this.props.pipelineInfo.getPipelineNumbersReportStatus === 'ENCOMPASS_FAILED' && !this.state.gotPipeStatus) {
            alert('Your credentials are correct, but encompass is unable to generate your pipeline.')
            this.props.goToAnotherPage({
                pathname: '/login'
            })
            this.setState({ gotPipeStatus: true });
        }
        if (this.props.pipelineInfo.getPipelineNumbersReportStatus === 'PASSWORD_FAILED' && !this.state.gotPipeStatus) {
            this.showLinkAppModal();
            this.setState({ gotPipeStatus: true });
        }


        //Reset gotPipeStatus to false
        if (this.props.pipelineInfo.getPipelineNumbersReportStatus === 'STARTED' && this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true })
        }

        var detailModal = this.getDetailModal();
        var linkAppModal = this.linkAppModal();
        return (
            <div>
            <Grid style={{ width: "90%", color: '#282828' }}>
                    {detailModal}
                    {linkAppModal}
                    <br />
                        {/* <Row style={{ paddingLeft: '15%', paddingRight: '15%' }}> */}
                            <h1 style={{ fontSize: '2rem', textAlign: 'Left' }}>Pipeline Numbers Report</h1>
                        {/* </Row> */}
                        <br />
                        <Row>
                            <h2>Appraisals</h2>
                            <Col md={6}>
                                <h4>Appraisals Month To Date</h4>
                                <input className='form-control' id='appraisalM' type='text' name='appraisalM' placeholder='Month to Date' value={this.state.appraisalM} onChange={this.handleInputChange} />
                            </Col>
                            <Col md={6}>
                                <h4>Appraisals Last Month To Date</h4>
                                <input className='form-control' id='appraisalLM' type='text' name='appraisalLM' placeholder='Last Month to Date' value={this.state.appraisalLM} onChange={this.handleInputChange} />
                            </Col>
                            <Col md={6}>
                                <h4>Appraisals Last Month Total</h4>
                                <input className='form-control' id='appraisalLMT' type='text' name='appraisalLMT' placeholder='Last Month Total' value={this.state.appraisalLMT} onChange={this.handleInputChange} />
                            </Col>
                        </Row><br />
                        <Row>
                        <h2>Month's Estimated Fundings</h2>
                        <Col md={6}>
                                <h4>Queue</h4>
                                <input className='form-control' id='Queue' type='text' name='Queue' placeholder='Queue' value={this.state.Queue} onChange={this.handleInputChange} />
                            </Col>
                            <Col md={6}>
                                <h4>Est</h4>
                                <input className='form-control' id='Est' type='text' name='Est' placeholder='Est' value={this.state.Est} onChange={this.handleInputChange} />
                            </Col>
                        </Row><br />
                        <Row>
                            <Col md={12}>
                                <button className="btn btn-success form-control" onClick={() => this.handleSubmit()}>Submit</button>
                            </Col>
                        </Row>
                        <hr />
                        <br />

{this.props.pipelineInfo.getPipelineNumbersReportStatus === 'SUCCEEDED' && this.state.gotPipeStatus &&
                    <div>
                    <Row>
                    <Button bsStyle="danger "onClick={this.printTable}>Download Report</Button>
                    <h2>Pipeline Numbers</h2>
                    <hr />
                    <Col md={2}><b>Pipeline</b></Col><Col md={2}>{this.props.pipelineInfo.pipelineNumbersReportData.pipeline}</Col><br />
                    <Col md={2}><b>Working Pipeline</b></Col><Col md={2}>{this.props.pipelineInfo.pipelineNumbersReportData.working}</Col><br />
                    <Col md={2}><b>Processing Pipeline</b></Col><Col md={2}>{this.props.pipelineInfo.pipelineNumbersReportData.processing}</Col><br />
                    <hr />
                    </Row>
                    <Row>
                        <Col md={3}><b>Applications</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.appMTD}</Col>
                        <Col md={2}>LMTD {this.props.pipelineInfo.pipelineNumbersReportData.appLMTD}</Col>
                        <Col md={2}>Last Month {this.props.pipelineInfo.pipelineNumbersReportData.appLMT}</Col>
                    </Row>
                    <Row>
                        <Col md={3}><b>Submitted To Processing</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.procMTD}</Col>
                        <Col md={2}>LMTD {this.props.pipelineInfo.pipelineNumbersReportData.procLMTD}</Col>
                        <Col md={2}>Last Month {this.props.pipelineInfo.pipelineNumbersReportData.procLMT}</Col>
                    </Row>
                    <Row>
                        <Col md={3}><b>Submitted To Underwriting</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.uwMTD}</Col>
                        <Col md={2}>LMTD {this.props.pipelineInfo.pipelineNumbersReportData.uwLMTD}</Col>
                        <Col md={2}>Last Month {this.props.pipelineInfo.pipelineNumbersReportData.uwLMT}</Col>
                    </Row>
                    <Row>
                        <Col md={3}><b>Appraisals</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.appraisalM}</Col>
                        <Col md={2}>LMTD {this.props.pipelineInfo.pipelineNumbersReportData.appraisalLM}</Col>
                        <Col md={2}>Last Month {this.props.pipelineInfo.pipelineNumbersReportData.appraisalLMT}</Col>
                    </Row>
                    <Row>
                        <Col md={3}><b>Fundings</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.fundedMTD}</Col>
                        <Col md={2}>LMTD {this.props.pipelineInfo.pipelineNumbersReportData.fundedLMTD}</Col>
                        <Col md={2}>Last Month {this.props.pipelineInfo.pipelineNumbersReportData.fundedLMT}</Col>
                    </Row>
                    <Row>
                        <Col md={3}><b>Estimated Fundings</b></Col>
                        <Col md={2}>MTD  {this.props.pipelineInfo.pipelineNumbersReportData.fundedMTD}</Col>
                        <Col md={2}>Queue {this.props.pipelineInfo.pipelineNumbersReportData.Queue}</Col>
                        <Col md={2}>Est {this.props.pipelineInfo.pipelineNumbersReportData.Est}</Col>
                    </Row>
                    <Row>
                        <h1>Total: {this.props.pipelineInfo.pipelineNumbersReportData.monthTotal}</h1>
                    </Row>
                    </div>
}

                {this.state.isLoading &&
                        <div style={{width: '100%', paddingTop: '50px'}}>
                            <Row>
                                <ProgressBar active striped bsStyle="info" now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`}/>
                            </Row>
                            {/* <Row>
                                <Col style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    <h3 style={{ textAlign: 'center' }}> {this.props.pipelineInfo.progress + '%'}</h3>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ textAlign: 'center' }}>{this.props.pipelineInfo.progressText}
                                    </p>
                                </Col>
                            </Row>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                        </div>}
                    </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPipelineNumbersReport: getPipelineNumbersReport,
            resetPipeline: resetPipeline,
            logoutUser: logoutUser,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(PipelineReportView);
