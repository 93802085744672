import React, { Component } from 'react';
import { Col, Grid, Row, Panel, Button, Table, ButtonGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData, setSelectedApplicant } from '../../actions/hrActions';
import { sendEmail, resetEmailStatus } from '../../actions/communicationActions';
import { addIssue, getCodes, getContacts } from '../../actions/issueActions';
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as FontAwesome from 'react-icons/lib/fa';
import { Form, Icon, Message } from 'semantic-ui-react';

class OnboardingDocumentsForm extends Component {
    constructor(props) {
        super();
        this.state = {
            employeeChecks: [],
            departmentName: '',
            firstPass: true,
            subject: "",
            recipients: "",
            cc: "",
            body: "",
            id: "",
            fileData: {},
            filesPreview: [],
            totalFileSize: 0

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleQuillChange = this.handleQuillChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.removePreview = this.removePreview.bind(this);
        this.handleAdendumReceivedToggle = this.handleAdendumReceivedToggle.bind(this);
        this.handlePacketReceivedToggle = this.handlePacketReceivedToggle.bind(this);
    }

    componentDidMount() {
        this.props.resetEmailStatus()
        //Find OnboardingDocuments Template
        var template = {}
        for (var i = 0; i < this.props.hrInfo.allEmailData.length; i++) {
            if (this.props.hrInfo.allEmailData[i].subject.indexOf('Onboarding') != -1) {
                //Replace Applicant Name in 
                template = this.props.hrInfo.allEmailData[i];
                var applicantName = this.props.hrInfo.selectedApplicant.data.personalInfo.firstName + ' ' + this.props.hrInfo.selectedApplicant.data.personalInfo.lastName
                var applicantEmail = this.props.hrInfo.selectedApplicant.data.personalInfo.email
                var directReportEmail = '';
                for (var j = 0; j < this.props.hrInfo.allEmployeesData.length; j++) {
                    if (this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport == this.props.hrInfo.allEmployeesData[j]._id) {
                        directReportEmail = this.props.hrInfo.allEmployeesData[j].itInfo.aliasEmails[0];
                        break;
                    }
                }

                template['recipients'] = template['recipients'].replace('DirectReportEmail', directReportEmail).replace('ApplicantEmail', applicantEmail)
                template['body'] = template['body'].replace('ApplicantName', applicantName)
                break
            }
        }


        //Get the correct Employee Agreement pending on the title of their position
        //5 Agreements are attached to the template but here we parse through to see which one should be used
        var fileNames = Object.keys(template.files)
        var agreementFile = {};
        if (this.props.hrInfo.selectedApplicant.data.employmentInfo.title === 'Loan Originator Assistant' || this.props.hrInfo.selectedApplicant.data.employmentInfo.title === 'Team Loan Originator') {
            fileNames.map((fileName) => {
                if (fileName === 'Loan_Originator_Assistant_Agreement.pdf') {
                    agreementFile[fileName] = template.files[fileName]
                }
            })
        }
        else if (this.props.hrInfo.selectedApplicant.data.employmentInfo.title === 'Outside Loan Originator') {
            fileNames.map((fileName) => {
                if (fileName === 'Outside_Loan_Originator_Agreement.pdf') {
                    agreementFile[fileName] = template.files[fileName]
                }
            })
        }
        else if (this.props.hrInfo.selectedApplicant.data.employmentInfo.title.indexOf('Loan Originator') !== -1) {
            fileNames.map((fileName) => {
                if (fileName === 'Loan_Originator_Agreement.pdf') {
                    agreementFile[fileName] = template.files[fileName]
                }
            })
        }
        else if (this.props.hrInfo.selectedApplicant.data.employmentInfo.title.indexOf('Branch Manager') !== -1) {
            fileNames.map((fileName) => {
                if (fileName === 'Branch_Manager_Agreement.pdf') {
                    agreementFile[fileName] = template.files[fileName]
                }
            })
        }
        else{
            fileNames.map((fileName) => {
                if (fileName === 'General_Employee_Agreement.pdf') {
                    agreementFile[fileName] = template.files[fileName]
                }
            })
        }

        this.setState({
            template: template,
            dataIsCleared: false,
            subject: template.subject,
            recipients: template.recipients,
            cc: template.cc,
            id: template._id,
            body: template.body,
            fileData: agreementFile,
            signedPacketReceived: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedPacketReceived,
            signedAddendumAndOtherReceived: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedAddendumAndOtherReceived


        })
    }

    removePreview(file) {
        var files = this.state.fileData;
        var totalFileSize = this.state.totalFileSize;
        totalFileSize = totalFileSize - files[file].size
        delete files[file];
        this.setState({ fileData: files, totalFileSize: totalFileSize })
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                var fileData = that.state.fileData
                var totalFileSize = that.state.totalFileSize
                totalFileSize = totalFileSize + file.size

                if (totalFileSize > 11000000) {
                    alert('Combined file size exceeds 10MB.')
                }
                else {

                    fileData[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };

                    that.setState({ fileData: fileData, totalFileSize: totalFileSize })
                }
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }
    generatePreviews() {
        var filesPreview = [];
        Object.keys(this.state.fileData).forEach((element) => {
            filesPreview.push(
                <tr>
                    <td><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                    <td><b style={{ fontSize: '1.25rem' }}>{this.formatBytes(this.state.fileData[element].size, 2)}</b></td>
                    <td><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                        <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                    </Button></td>
                </tr>
            )
        })

        return filesPreview;
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    handleSubmit(event) {
        event.preventDefault();
        // alert('Are you sure you want to send this OnboardingDocuments Email?')

        //Creates Attachment Array
        var attachments = Object.keys(this.state.fileData).map((file) => {
            return {
                filename: file,
                content: this.state.fileData[file].data,
                encoding: 'base64'
            }

        })

        var messageData = {
            data: {
                subject: this.state.subject,
                htmlMessage: this.state.body,
                to: 'dhudson@vandykmortgage.com',
                attachments: attachments
                //to: this.state.recipients.replace(/;/g, ','),
                // cc: this.state.cc.replace(/;/g, ','),
            }
        };
        //Send the email
        this.props.sendEmail(messageData)
        var upsertData =
        {
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants',
            data: {
                personalInfo: this.props.hrInfo.selectedApplicant.data.personalInfo,
                employmentInfo: this.props.hrInfo.selectedApplicant.data.employmentInfo,
                hiringInfo: {
                    hiringStatus: this.props.hrInfo.selectedApplicant.data.hiringInfo.hiringStatus,
                    applicationCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.applicationCompleted,
                    offerLetterSentDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSentDate,
                    offerLetterSigned: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSigned,
                    estimatedStartDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.estimatedStartDate,
                    iTCredentials: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials,
                    equipmentRequested: this.props.hrInfo.selectedApplicant.data.hiringInfo.equipmentRequested,
                    trainingInGR: this.props.hrInfo.selectedApplicant.data.hiringInfo.trainingInGR,
                    wage: this.props.hrInfo.selectedApplicant.data.hiringInfo.wage,
                    notes: this.props.hrInfo.selectedApplicant.data.hiringInfo.notes,
                    backgroundAuth: this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundAuth,
                    backgroundCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted,
                    exclusionaryListCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted,
                    googleCompleted:this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted,
                    signedAddendumAndOtherReceived: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedAddendumAndOtherReceived,
                    signedPacketReceieved: this.props.hrInfo.selectedApplicant.data.hiringInfo.signedPacketReceieved,
                    applicantInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted,
                    employmentInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted,
                    iTInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted,
                    onboardingDocumentsEmailSent: true,//This is why
                    offerEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent,
                    welcomeEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent,//This is why
                    credentialsEmailSent: this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent,
                },
                licensingInfo: this.props.hrInfo.selectedApplicant.data.licensingInfo,
                itInfo: this.props.hrInfo.selectedApplicant.data.itInfo
            }
        }
        //Update in Collection
        this.props.upsertHRData(upsertData);
        this.props.setSelectedApplicant({ selectedApplicant: upsertData });
    }



    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }


    onEditorStateChange(editorState) {
        this.setState({
            editorState
        });
    };
    handleQuillChange(value) {
        this.setState({ body: value })
    }
    handlePacketReceivedToggle() {
        this.setState({ signedPacketReceived: !this.state.signedPacketReceived })
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.signedPacketReceived = !newObj.hiringInfo.signedPacketReceived
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
    }

    handleAdendumReceivedToggle() {
        this.setState({ signedAddendumAndOtherReceived: !this.state.signedAddendumAndOtherReceived })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.signedAddendumAndOtherReceived = !newObj.hiringInfo.signedAddendumAndOtherReceived
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
    }

    render() {

        var attachedPreviews = this.generatePreviews();
        return (

            <div>


                {this.props.communicationInfo.sendEmailStatus === 'STARTED' &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}

                {this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <div>

                    <span style={{ color: '#5cb85c', fontSize: 50 }} ><FontAwesome.FaThumbsUp /></span>
                    <h1> Onboarding Documents have been sent to {this.props.hrInfo.selectedApplicant.data.personalInfo.firstName + ' ' + this.props.hrInfo.selectedApplicant.data.personalInfo.lastName}</h1>
                    <span style={{ color: '#5cb85c', fontSize: 50 }} ><FontAwesome.FaThumbsUp /></span>
                </div>}
                {this.props.communicationInfo.sendEmailStatus === 'ERRORED' && <div>

                    <span style={{ color: '#d9534f', fontSize: 50 }} ><FontAwesome.FaThumbsDown /></span>
                    <h1> Message not sent to {this.props.hrInfo.selectedApplicant.data.personalInfo.firstName + ' ' + this.props.hrInfo.selectedApplicant.data.personalInfo.lastName}</h1>
                    <h2> Contact Administrator</h2>
                    <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={this.props.resetEmailStatus}>Go Back
                                        &nbsp;
                                </span>
                    <br />
                    <span style={{ color: '#d9534f', fontSize: 50 }} ><FontAwesome.FaThumbsDown /></span>
                    {this.props.communicationInfo.sendEmailErrorMessage} </div>}


                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <div>
                    <div className='try - registration - form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <br />

                        <form onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                            <Grid>
                                <Row>
                                    <h3>Signed Packet Received?</h3>
                                    {this.state.signedPacketReceived &&
                                        <ButtonGroup>
                                            <Button bsStyle="warning">Yes</Button>
                                            <Button onClick={this.handlePacketReceivedToggle}>No</Button>
                                        </ButtonGroup>
                                    }
                                    {!this.state.signedPacketReceived &&
                                        <ButtonGroup>
                                            <Button onClick={this.handlePacketReceivedToggle}>Yes</Button>
                                            <Button bsStyle="warning">No</Button>
                                        </ButtonGroup>
                                    }
                                </Row>
                                <Row>
                                    <h3>Signed Addendums and Other Documents Received?</h3>
                                    {this.state.signedAddendumAndOtherReceived &&
                                        <ButtonGroup>
                                            <Button bsStyle="warning">Yes</Button>
                                            <Button onClick={this.handleAdendumReceivedToggle}>No</Button>
                                        </ButtonGroup>
                                    }
                                    {!this.state.signedAddendumAndOtherReceived &&
                                        <ButtonGroup>
                                            <Button onClick={this.handleAdendumReceivedToggle}>Yes</Button>
                                            <Button bsStyle="warning">No</Button>
                                        </ButtonGroup>
                                    }
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={12} sm={12}>
                                        <h3>Subject</h3>
                                        <input className='form-control' id='subject' type='text' name='subject' placeholder='Subject' value={this.state.subject} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={6} sm={12}>
                                        <h3>To</h3>
                                        <input className='form-control' id='recipients' type='text' name='recipients' placeholder="recipients" value={this.state.recipients} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <h3>CC:</h3>
                                        <input className='form-control' id='cc' type='text' name='cc' placeholder="cc" value={this.state.cc} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openAttachment: !this.state.openAttachment })}>Attachments
                                        &nbsp;{this.state.openAttachment && <FontAwesome.FaArrowCircleDown />}{!this.state.openAttachment && <FontAwesome.FaArrowCircleRight />}
                                </span>
                                <Panel id="collapsible-panel-example-1" expanded={this.state.openAttachment}>
                                    <Panel.Collapse >
                                        <Row style={{ paddingLeft: 20 }}>
                                            <Col>
                                                <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                                    <div>
                                                        <br />
                                                        <a> <h2>Click here or Drag files to attach to this email</h2></a>
                                                        <h5>Combined File Size must be less than 10MB</h5>
                                                        <br />
                                                    </div>
                                                </Dropzone>

                                            </Col> </Row>
                                        <br />
                                        <Row>
                                            <Col style={{ paddingLeft: 40 }}>
                                                <h2><b>Files to be Attached:</b></h2>
                                                <Table responsive style={{ alignSelf: 'left' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Size</th>
                                                            <th> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {attachedPreviews}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                    </Panel.Collapse>
                                </Panel>

                                <br />
                                <ReactQuill value={this.state.body}
                                    onChange={this.handleQuillChange} />

                            </Grid>
                            <br />
                            <button className='form-control btn btn-success' onClick={this.handleSubmit}>Send Onboarding Documents Email</button>
                        </form>
                        <br />

                        <div id='error'></div>
                    </div>
                </div>

                }
            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer,
        communicationInfo: state.communicationReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            sendEmail: sendEmail,
            resetEmailStatus: resetEmailStatus,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDocumentsForm);
