import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap'
import Skeleton from 'react-skeleton-loader'
import { getLoanOriginators } from '../../actions/employeeActions';
import { introspectToken } from '../../actions/loginActions';
import ProfileCardView from '../profileComponents/ProfileCardView';

//Components are DUMB and should only display the data that was passed from the container that it is within
class LocatorView extends Component {
    constructor(props) {
        super();
        this.state = {
            name: "",


        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    compare(a,b) {
        if (a.firstName < b.firstName)
            return -1;
        if (a.firstName > b.firstName)
            return 1;
        return 0;
    }

    createCards() {
        var cardsToDisplay = this.props.employeeInfo.allEmployeesData.sort(this.compare).map((lo, i) => {
            if (this.state.name !== "") {
                if (lo.firstName.toLowerCase().indexOf(this.state.name.toLowerCase()) != -1 || lo.lastName.toLowerCase().indexOf(this.state.name.toLowerCase()) != -1) {
                    return (
                        <div>
                            <ProfileCardView user={lo} average={0} showStars={true} />
                            <hr />
                        </div>)
                }
            }



            if (this.state.name === "") {
                return (
                    <div>
                        <ProfileCardView user={lo} average={0} showStars={true} />
                        <hr />
                    </div>)

            }
        })

        return cardsToDisplay;
    }


    render() {


        return (
            <div>
                <Grid style={{ width: "90%", padding: 0, textAlign: "left", color: '#282828' }}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <label style={{ fontSize: '2em', fontFamily: 'Raleway',  textAlign: 'center' }}>Company Directory </label>

                        </Col>
                    </Row>
                    <Row >
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <FormGroup style={{ paddingLeft: '5%', width: '95%' }}>
                                <ControlLabel style={{ fontSize: '1em', fontFamily: 'Raleway',  textAlign: 'center' }}>Filter By Name</ControlLabel>
                                <FormControl type="text" placeholder="Employee Name" name="name" onChange={this.handleInputChange} />
                            </FormGroup>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                        </Col>
                    </Row>
                        <br />
                            {this.createCards()}
                 
                    </Grid>
               

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        employeeInfo: state.employeeReducer,
        encompassInfo: state.encompassReducer,
        loginInfo: state.loginReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getLoanOriginators: getLoanOriginators,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LocatorView);


