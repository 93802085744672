import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { numberWithCommas, getTotalDollars } from '../../../helpers/getDollars'
import 'react-table/react-table.css'
import { Grid, Col, Row, Checkbox, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CSVLink } from 'react-csv';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import windowSize from 'react-window-size';
import * as FontAwesome from 'react-icons/lib/fa';
import { customSortWithMoney, customFilter } from '../../../helpers/tableHelpers';

const printTooltip = (
    <Tooltip id="tooltip">
        <strong>Print Table</strong>
    </Tooltip>
);

const csvTooltip = (
    <Tooltip id="tooltip">
        <strong>Export to CSV</strong>
    </Tooltip>
);

const closeTooltip = (
    <Tooltip id="tooltip">
        <strong>Close Table</strong>
    </Tooltip>
);
const compareTooltip = (
    <Tooltip id="tooltip">
        <strong>Select rows from far right column of table to graphically compare values</strong>
    </Tooltip>
);
const hideGraphTooltip = (
    <Tooltip id="tooltip">
        <strong>Hide Comparison Graph</strong>
    </Tooltip>
);
const updateGraphTooltip = (
    <Tooltip id="tooltip">
        <strong>Select or deselect additional rows from far right column of table to graphically compare values</strong>
    </Tooltip>
);
const date = new Date();
//Components are DUMB and should only display the data that was passed from the container that it is within
class ReportTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false,
            selectedRows: []
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.getNewData = this.getNewData.bind(this);
        this.printTable = this.printTable.bind(this);

        this.getLeaders = this.getLeaders.bind(this);
        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.sendCompareValues = this.sendCompareValues.bind(this);
    }

    printTable() {

        var data = [];

        data.push([{ text: 'LO Name', bold: true }, { text: 'Number of Loans', bold: true }, { text: 'Total Cost Value', bold: true }]);

        this.state.leadersForTable.map((loan) => {
            data.push([
                { text: loan.name },
                { text: loan.numOfLoans },
                { text: loan.totalDollars },
            ])
        });
        const { vfs } = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        var month = date.getMonth() + 1;
        var documentDefinition = {
            header: {
                columns: [
                    { text: this.props.reportType + ' (' + month + '/' + date.getDate() + '/' + date.getFullYear() + '@' + date.toLocaleTimeString() + ')', style: 'title' }
                ]
            },
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: ['33%', '33%', '33%'],
                        body: data
                    },
                    style: 'table'
                },

            ],
            styles: {
                table: {
                    alignment: 'center',
                    margin: [0, 20, 0, 0]
                },
                title: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 15, 0, 0]
                }
            }
        };
        pdfMake.createPdf(documentDefinition).print();
    }


    dollarsToFloat(dollar) {
        if (typeof (dollar) === 'number') { return dollar; }
        else {
            var noSign = dollar.replace('$', '');
            return noSign.replace(/,/g, '');
        }

    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    getTotalCountForTable(leaders) {
        var totalCount = 0;
        Object.keys(leaders).map((leader) => {
            totalCount = totalCount + leaders[leader].count;
        })
        return totalCount;
    }
    getTotalDollarsForTable(leaders) {
        var totalDollars = 0;
        Object.keys(leaders).map((leader) => {
            totalDollars = totalDollars + parseInt(leaders[leader].totalDollars.replace('$', '').replace(/,/g, ''));
        })
        return '$' + this.numberWithCommas(totalDollars) + '.00';
    }
    getNewData() {
        var leadersToDisplay = [];
        var names = Object.keys(this.props.leaders);
        if (names.length > 0) {
            //Determine Table Page Size
            if (names.length > 20) {
                this.setState({ tablePageSize: 20 });
            }
            else {
                this.setState({ tablePageSize: names.length });
            }
            //Create Data For Table
            for (var i = 0; i < names.length; i++) {
                var name = names[i];
                var numOfLoans = this.props.leaders[name].count;
                //Get Total Dollar amount for leader
                var totalDollars = this.props.leaders[name].totalDollars;
                var loans = this.props.leaders[name].loans;
                var formattedObject = {
                    name: name,
                    numOfLoans: numOfLoans,
                    totalDollars: totalDollars,
                    loans: loans
                }

                leadersToDisplay.push(formattedObject)
            }

            this.setState({ leadersForTable: leadersToDisplay });
        }

    }

    componentWillMount() {
        this.setState({ currentLeaders: this.props.leaders })
        this.getNewData()

    }

    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    getLeaders(loanList) {
        var leaders = {};
        //Creates Object of LOs with their associated Loans
        if (loanList.length > 0) {
            loanList.map((loan) => {
                if (leaders[loan['LoanNumber']]) {
                    leaders[loan['LoanNumber']].push(loan)
                }
                else {
                    leaders[loan['LoanNumber']] = [loan]
                }
            });
        }
        //Create Object that contains name, count of loans, total dollar value of each loan
        var finalLeaderInfo = {}
        Object.keys(leaders).map((leader) => {
            finalLeaderInfo[leader] = {
                totalDollars: getTotalDollars(leaders[leader]),
                date: leaders[leader][0][this.props.field],
                borrowerName: leaders[leader][0]['BorrowerFirstName'] + ' ' + leaders[leader][0]['BorrowerLastName']
            }

        })

        return finalLeaderInfo;


    }
    showDetails(e) {
        var leaderList = this.getLeaders(e.value)
        this.props.showDetailModal(leaderList);
    }


    translateHeader(id) {
        if (id === "CX.TEAMCOLOR") {
            return "Team"
        }
        else if (id === "ORGID") {
            return "Branch"
        }
        else if (id === "317") {
            return "LO"
        }
        else if (id === "2574") {
            return "Underwriter"
        }
        else if (id === "2019") {
            return "Shipper"
        }
        else if (id === "1855") {
            return "Closer"
        }
        else if (id === "362") {
            return "Processor"
        }
        else if (id === "LoanTeamMember.Name.Processor Assistant") {
            return "Processor Assistant"
        }
        else {
            return id;
        }


    }
    handleRowCheck(event) {

        var index = this.state.selectedRows.indexOf(event.original.name);
        //Add ID
        if (index === -1) {
            var tempArray = this.state.selectedRows.concat(event.original.name)
            this.setState({ selectedRows: tempArray });
        }
        //Remove ID
        else {
            tempArray = this.state.selectedRows;
            tempArray.splice(index, 1);

            this.setState({ selectedRows: tempArray })
        }
    }
    handleTopRowCheck(event) {
        var tempArray = [];
        var valueOnPage = [];
        console.log('HELP',event.target.checked)
        if (event.target.checked) {
            //If number of rows is greater than the default number of rows
            if (this.state.leadersForTable.length >= this.state.tablePageSize) {
                this.state.leadersForTable.map((sortVal) => {
                    tempArray.push(sortVal.name);
                    if (sortVal.name && document.getElementById("row" + sortVal.name.replace(/' '/g, ''))) {
                        document.getElementById("row" + sortVal.name.replace(/' '/g, '')).checked = true;
                    }
                });
            }
            //If number of rows is less than the default number of rows 
            else {
                valueOnPage = this.state.leadersForTable.slice((this.state.tablePageSize * (this.state.currentPage - 1)), this.state.tablePageSize * this.state.currentPage)
                valueOnPage.map((sortVal) => {
                    tempArray.push(sortVal.name);
                    if (sortVal.name && document.getElementById("row" + sortVal.name.replace(/' '/g, ''))) {
                        document.getElementById("row" + sortVal.name.replace(/' '/g, '')).checked = true;
                    }
                });
            }
            this.setState({ selectedRows: tempArray })
        }
        else if (!event.target.checked && this.state.leadersForTable !== undefined) {
            //If number of rows is greater than the default number of rows
            if (this.state.leadersForTable.length > this.state.tablePageSize) {
                this.state.leadersForTable.map((sortVal) => {
                    tempArray.push(sortVal.name.replace(/' '/g, ''));
                    if (sortVal.name && document.getElementById("row" + sortVal.name.replace(/' '/g, ''))) {
                        document.getElementById("row" + sortVal.name.replace(/' '/g, '')).checked = false;
                    }
                });
            }
            //If number of rows is less than the default number of rows 
            else {
                valueOnPage = this.state.leadersForTable.slice((this.state.tablePageSize * (this.state.currentPage - 1)), this.state.tablePageSize * this.state.currentPage)
                valueOnPage.map((sortVal) => {
                    tempArray.push(sortVal.name.replace(/' '/g, ''));
                    if (sortVal.name && document.getElementById("row" + sortVal.name.replace(/' '/g, ''))) {
                        document.getElementById("row" + sortVal.name.replace(/' '/g, '')).checked = false;
                    }
                });
            }
            this.setState({ selectedRows: [] })
        }
        else {
            this.setState({ selectedRows: [] })
        }



    }

    sendCompareValues() {

        this.props.compareGraph(this.state.selectedRows)
    }

    render() {
        if (this.state.currentLeaders !== this.props.leaders) {
            this.getNewData();
            this.setState({ currentLeaders: this.props.leaders });

        }
        console.log()

        const loanCSVHeaders = [
            'name',
            'numOfLoans',
            'totalDollars'
        ];

        return (
            <div style={{ marginTop: 25 }}>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <p style={{ fontSize: '2rem' }}>{this.props.reportType}</p>
                        <p>{this.props.startDate} to {this.props.endDate}</p>

                    </Col>
                    <Row>
                        <Col style={{ float: 'right' }}>
                            <ButtonGroup>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={printTooltip}>
                                    <Button bsStyle="default" onClick={this.printTable}>
                                        <span style={{ color: '#428bca', fontSize: 30 }}><FontAwesome.FaPrint /></span>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={csvTooltip}>
                                    <CSVLink data={this.state.leadersForTable} headers={loanCSVHeaders} filename={this.props.reportType + ' Table' + date.getHours() + date.getMinutes() + date.getSeconds() + '_' + date.getMonth() + date.getDate() + date.getFullYear() + '.csv'} className="btn btn-default">
                                        <span style={{ color: '#5cb85c', fontSize: 30 }}><FontAwesome.FaTable /></span>
                                    </CSVLink>
                                </OverlayTrigger>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={closeTooltip}>
                                    <Button bsStyle="default" onClick={this.props.hideTableView}>
                                        <span style={{ color: '#d9534f', fontSize: 30 }}><FontAwesome.FaClose /></span>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ float: 'right' }}>
                            <ButtonGroup>
                                {!this.props.showGraph &&
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="bottom" overlay={compareTooltip}>
                                        <Button bsStyle="warning" onClick={this.sendCompareValues}>{'Compare ' + this.state.selectedRows.length + ' ' + this.translateHeader(this.props.columnHeader) + '(s)'}</Button>
                                    </OverlayTrigger>
                                }
                                {this.props.showGraph &&
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="bottom" overlay={updateGraphTooltip}>
                                        <Button bsStyle="warning" onClick={this.sendCompareValues}>{'Update Graph'}</Button>
                                    </OverlayTrigger>
                                }
                                {this.props.showGraph &&
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="bottom" overlay={hideGraphTooltip}>
                                        <Button onClick={this.props.hideGraph}>{'Hide Graph'}</Button>
                                    </OverlayTrigger>
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Row>
                <br />
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} >
                        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Total Number of Loans: {this.getTotalCountForTable(this.props.leaders)}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Total Dollars: {this.getTotalDollarsForTable(this.props.leaders)}</p>
                    </Col>
                </Row>
                <hr />
                {window.innerWidth > 600 &&
                    <ReactTable
                        id='CurrentTable'
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: () => (<div>
                                    {this.state.selectedRows.length > 0 && <Checkbox checked={true} onChange={this.handleTopRowCheck.bind(this)} key={'checkBoxHeader'} id='headerCheckbox'> </Checkbox>}
                                    {this.state.selectedRows.length == 0 && <Checkbox checked={false} onChange={this.handleTopRowCheck.bind(this)} key={'checkBoxHeader'} id='headerCheckbox'> </Checkbox>}
                                </div>),
                                accessor: 'totalDollars',
                                filterable: false,
                                sortable: false,
                                minWidth: 30,
                                Cell: row => (


                                    <div align="center">
                                        {this.state.selectedRows.indexOf(row.original.name) != -1 && <Checkbox checked={true} onChange={this.handleRowCheck.bind(this, row)} id={'row' + row.original.name.replace(/' '/g, '')}> </Checkbox>}
                                        {this.state.selectedRows.indexOf(row.original.name) == -1 && <Checkbox checked={false} onChange={this.handleRowCheck.bind(this, row)} id={'row' + row.original.name.replace(/' '/g, '')}> </Checkbox>}
                                    </div>

                                )
                            },
                            {
                                Header: this.translateHeader(this.props.columnHeader),
                                accessor: 'name',
                                minWidth: 150
                            },
                            {
                                Header: 'Number Of Loans',
                                accessor: 'numOfLoans'
                            },
                            {
                                Header: 'Total Cost Value',
                                accessor: 'totalDollars',
                            },
                            {
                                Header: '',
                                accessor: 'loans',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showDetails.bind(this, row)}>
                                            <span className="fa fa-info-circle" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },

                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSortWithMoney}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }

                {window.innerWidth <= 600 &&
                    <ReactTable
                        id='CurrentTable'
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: () => (<div>
                                    <Checkbox onChange={this.handleTopRowCheck.bind(this)} key={'checkBoxHeader'} id='headerCheckbox'> </Checkbox>
                                </div>),
                                accessor: 'totalDollars',
                                filterable: false,
                                sortable: false,
                                minWidth: 30,
                                Cell: row => (

                                    <div align="center">
                                        {this.state.selectedRows.indexOf(row.original.name) != -1 && <Checkbox checked={true} onChange={this.handleRowCheck.bind(this, row)} id={'row' + row.original.name.replace(/' '/g, '')}> </Checkbox>}
                                        {this.state.selectedRows.indexOf(row.original.name) == -1 && <Checkbox checked={false} onChange={this.handleRowCheck.bind(this, row)} id={'row' + row.original.name.replace(/' '/g, '')}> </Checkbox>}
                                    </div>
                                )
                            },
                            {
                                Header: this.translateHeader(this.props.columnHeader),
                                accessor: 'name',
                                minWidth: 150
                            },
                            {
                                Header: '#',
                                accessor: 'numOfLoans',
                                minWidth: 30
                            },
                            {
                                Header: 'Cost',
                                accessor: 'totalDollars',
                            },
                            {
                                Header: '',
                                accessor: 'loans',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showDetails.bind(this, row)}>
                                            <span className="fa fa-info-circle" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },

                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSortWithMoney}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }

                <hr />
                <hr />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        pipelineInfo: state.pipelineReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ReportTable));