import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPipeline } from '../actions/pipelineActions';
import LocatorView from "../components/hrComponents/LocatorView";
import MenuSideBar from '../components/layoutComponents/MenuSideBar';

class LocatorContainer extends Component {
    componentDidMount() {
        this.props.resetPipeline();
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true })
        this.props.loginUser(this.state.username, this.state.password);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    render() {

        return (
            <div id="locatorPageContainer" >
                <MenuSideBar comp={<LocatorView />} />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
    };
}

//This function links functions/dispatch to the props of the component being processed.
//In this case we are:
//changeUser - grabbing a function from actions.js file 
//goToAnotherPage - utilizing the push command from react-router to go to a page specified in the params
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPipeline:resetPipeline
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LocatorContainer);
