/**
 * Region specific constants
 */

/**
 * Object consisting of keys of branch names with a value of the region key that
 * they are associated with.
 * 
 * @var object
 */
export const regionMap = {
    '131': '002',
    '206': '002',
    '208': '002',
    '302': '002',
    '304': '002',
    '401': '002',
    '426': '002',
    '468': '002',
    '488': '002',
    '511': '002',
    '517': '002',
    '530': '002',
    '560': '002',
    '567': '002',
    '586': '002',
    '589': '002',
    '777': '002',
    '779': '002',
    '803': '002',
    '123': '003',
    '150': '003',
    '159': '003',
    '164': '003',
    '493': '004',
    '721': '005',
    '723': '005',
    '724': '005',
    '775': '005',
    '776': '005',
    '727': '005',
    '728': '005',
    '729': '005',
    '731': '005',
    '732': '005',
    '734': '005',
    '737': '005',
    '738': '005',
    '741': '005',
    '753': '005',
    '759': '005',
    '762': '005',
    '783': '005',
    '203': '006',
    '207': '006',
    '453': '006',
    '551': '006',
    '581': '006',
    '582': '006',
    '600': '006',
    '703': '006',
    '739': '006',
    '747': '006',
    '764': '006',
    '767': '006',
    '768': '006',
    '714': '006',
    '770': '006',
    '771': '006',
    '772': '006',
    '773': '006',
    '774': '006',
    '780': '006',
    '784': '006',
    '200': '007',
    '204': '007',
    '235': '007',
    '545': '007',
    '585': '007',
};

/**
 * List of regions
 * @var array
 */
export const regions = [
    '001',
    '002',
    '003',
    '004',
    '005',
    '006',
    '007'
];