import { connectRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';
import createRootReducer from './reducers/rootReducer';
import rootSaga from "./sagas/sagas";
export const history = createHistory()

//Creates the store with all of the necessary parameters

const initialState = {}
const enhancers = []

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  thunk,
  routerMiddleware(history),
  sagaMiddleware
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composeEnhancers = composeWithDevTools({maxAge:10});

const composedEnhancers = composeEnhancers(
  applyMiddleware(...middleware),
  ...enhancers
)
const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);
sagaMiddleware.run(rootSaga);




export default store;
 

