import React, { Component } from 'react';
import ReactStars from 'react-stars'
import * as FontAwesome from 'react-icons/lib/fa';
import { Col, Row, Form, Grid, Table, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getSurvey } from '../../actions/communicationActions';
import { Icon, Message } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class SurveyView extends Component {
    constructor(props) {
        super();
        this.state = {

        }

    }


    render() {
        return (
            <Grid>
                <br/>
                {this.props.surveys.surveys}
            </Grid >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        communicationInfo: state.communicationReducer

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            getSurvey: getSurvey
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SurveyView);
