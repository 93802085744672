import React, { Component } from 'react';
import { regionMap, regions } from "../../../constants/region";
import { Col, Grid, ProgressBar, Row, Button, ButtonGroup, OverlayTrigger, Tooltip, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getPipelineLeaderboard, resetPipeline, updateProgress } from '../../../actions/pipelineActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';
import LeaderboardReportCard from './LeaderboardReportCard.js'
import LeaderboardYearlyStatsCard from './LeaderboardYearlyStatsCard.js'
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
var Sugar = require('sugar-date')


var todayDate = Sugar.Date.create('Today');
var lastMonthDate = Sugar.Date.create('Last Month');
var thisMonth = months[lastMonthDate.getMonth()]
var thisYear = todayDate.getFullYear()

var pullThroughTooltip = (
    <Tooltip id="tooltip">
        <strong>Percentage</strong> is the ratio of Total Funded Loans to Total Application.
    </Tooltip>
);

var totalCountTooltip = (
    <Tooltip id="tooltip">
        <strong>Count</strong> is the Total Funded Loans.
    </Tooltip>
);


class LeaderboardView extends Component {
    constructor(props) {
        super();
        this.state = {
            isLoading: true,
            filterID: '1997',
            field: 'FundedDate',
            appStatusText: 'Funded',
            sortType: 'LOName',
            monthTable: <div></div>,
            yearTable: <div></div>,
            filterDropdown: <div></div>,
            leaderFilter: 'AllIndividuals',
            displayType: 'Count',
            employeeObj: {},
            gotLocatorStatus: false,
            allTables: {},
            myStats: {
                Branch: '',
                EmployeeID: 0,
                Name: '',
                AllBranchesCountByMonth: {},
                AllIndividualCountByMonth: {},
                AllRegionsCountByMonth: {},
                AllBranchesCountByYear: {},
                AllIndividualCountByYear: {},
                AllRegionsCountByYear: {},
                AllBranchesPercentageByYear: {},
                AllIndividualPercentageByYear: {},
                AllRegionsPercentageByYear: {}
            },
            filterOptions : [],
            numberOfLeaders: 10
        }

        this.getDropDown = this.getDropDown.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.toggleDisplayType = this.toggleDisplayType.bind(this);
        this.getEmployeeIDMap = this.getEmployeeIDMap.bind(this);
        this.getCurrentEmployeeInfo = this.getCurrentEmployeeInfo.bind(this)
        this.getRankChangeByPercentage = this.getRankChangeByPercentage.bind(this);
        this.getAllTables = this.getAllTables.bind(this);
        this.getMyStats = this.getMyStats.bind(this);
        this.getTable = this.getTable.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.handleShowMore = this.handleShowMore.bind(this);
        this.getBranchTable = this.getBranchTable.bind(this)

    }

    componentDidMount() {
        if (this.props.loginInfo.encompassLink === 'LINKED' || (this.props.loginInfo.adUserData.itInfo.linkedApps.length > 0 && this.props.loginInfo.adUserData.itInfo.linkedApps[0].appName === "Encompass") ) {
            // This initializes our actions in order to get the pipeline leaderboard
            if (Object.keys(this.props.pipelineInfo.pipelineLeaderboardData).length === 0) { 
                this.getThePipelineLeaderboard(); 
            }
            this.getEmployeeIDMap();
            this.getCurrentEmployeeInfo();
        }
        this.setState({
            chartWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }
    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }


    getThePipelineLeaderboard() {
        var Sugar = require('sugar-date')
        var dateObj = Sugar.Date.create('Yesterday');
        var newDataStartMonth = dateObj.getMonth() + 1;
        var newDataStartDay = dateObj.getDate();
        var newDataStartYear = dateObj.getFullYear();
        var newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var searchStartData = Sugar.Date.create('2 Years Ago');
        var searchStartMonth = searchStartData.getMonth() + 1;
        var searchStartDay = searchStartData.getDate();
        var searchStartYear = searchStartData.getFullYear();
        var searchStartDate = searchStartMonth + '/' + searchStartDay + '/' + searchStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();

        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
            filter: this.state.filterID,
            field: this.state.field,
            sort: this.state.sortType,
            startDate: searchStartDate,
            newDataStartDate: newDataStartDate,
            endDate: todayDate,
            todayDate: todayDate,

        }
        this.props.getPipelineLeaderboard(pipelineParam);


    }

    changeFilter(event) {
        this.setState({ leaderFilter: event.target.value, numberOfLeaders: 10 })
        this.getAllTables(10)
    }


    toggleDisplayType() {
        if (this.state.displayType === 'Count') {
            this.setState({ displayType: 'PullThroughPercentage' })
        }
        else {
            this.setState({ displayType: 'Count' })
        }

    }

    /**
     * Populates leaderboard type selection dropdown.
     */
    getDropDown() {
        if (this.props.pipelineInfo.pipelineLeaderboardData && 
            this.props.pipelineInfo.pipelineLeaderboardData.Branches) 
        {
            const filterOptionObjects = [
                { label : 'All Branches', value : 'AllBranches' },
                { label : 'All Individuals', value : 'AllIndividuals' },
                { label : 'All Regions', value : 'AllRegions' }
            ]
            .concat(regions.map( region => ({ label : region, value : region })))
            .concat(this.props.pipelineInfo.pipelineLeaderboardData.Branches.map(
                branch => ({ label : branch, value : branch})
            ));

            this.setState({ filterOptions: filterOptionObjects })
        }
    }

    getEmployeeIDMap() {
        var employeeObj = {};
        for (var i = 0; i < this.props.employeeInfo.allEmployeesData.length; i++) {
            employeeObj[this.props.employeeInfo.allEmployeesData[i].encompassEmail] = this.props.employeeInfo.allEmployeesData[i]._id;
        }
        this.setState({ employeeObj: employeeObj });
    }

    getCurrentEmployeeInfo() {

        var myStats = this.state.myStats;

        var myEmails = this.props.loginInfo.adUserData.itInfo.aliasEmails
        for (var i = 0; i < this.props.employeeInfo.allEmployeesData.length; i++) {
            if (myEmails.indexOf(this.props.employeeInfo.allEmployeesData[i].encompassEmail) !== -1) {
                myStats['Branch'] = this.props.employeeInfo.allEmployeesData[i].departmentName.split(' ')[1]
                myStats['Region'] = regionMap[this.props.employeeInfo.allEmployeesData[i].departmentName.split(' ')[1]]
                myStats['EmployeeID'] = this.props.employeeInfo.allEmployeesData[i]._id
                break;
            }
        }
        this.setState({ myStats: myStats });
    }

    sortObjByCount(leaderboardObj) {
        var keysSorted = Object.keys(leaderboardObj).sort(function (a, b) { return leaderboardObj[b].length - leaderboardObj[a].length })
        return keysSorted
    }

    sortObjPercentage(applicationObj) {

        //Create set of Keys from both funded and application
        var applicationKeys = Object.keys(applicationObj)
        var percentageObj = {};
        //Calculate PullThroughPercentage of every Key
        applicationKeys.forEach((key, i) => {

            //Only calculate if application is  funded
            var application = applicationObj[key].length;
            var funded = 0;
            for (var j = 0; j < applicationObj[key].length; j++) {
                if (applicationObj[key][j]['FundedDate']) {
                    funded++;
                }
            }
            var percent = (funded / application) * 100
            percentageObj[key] = { 
                'Percentage': percent, 
                'Total Funded': funded, 
                'Total Applications': application 
            }
        })

        //Remove LOs that have less than 10 loans in a year
        var aboveTenFundedObj = {}
        var keysSorted = [];


        var percentageKeys = Object.keys(percentageObj);
        for (var i = 0; i < percentageKeys.length; i++) {
            if (percentageObj[percentageKeys[i]]['Total Funded'] > 1) {
                aboveTenFundedObj[percentageKeys[i]] = percentageObj[percentageKeys[i]];
            }
        }
        keysSorted = Object.keys(aboveTenFundedObj).sort(function (a, b) { return aboveTenFundedObj[b]['Percentage'] - aboveTenFundedObj[a]['Percentage'] });
        return { 'keys': keysSorted, 'percentageInfo': aboveTenFundedObj };
        // keysSorted = Object.keys(percentageObj).sort(function (a, b) { return percentageObj[b]['Percentage'] - percentageObj[a]['Percentage'] });
        // return { 'keys': keysSorted, 'percentageInfo': percentageObj };


    }
    //Get Object For Display
    getRankChangeByCount(
        pastDataKeys, 
        presentDataKeys, 
        presentData, 
        rankType
    ) {
        var finalArray = [];
        
        //This is needed because some of the keys maybe filtered when performing region and branch checks so this index
        //will be used to determine the actual rank
        var rankingIndex = 0;

        if (rankType !== 'Regions') {
            for (var presentIndex = 0; presentIndex < presentDataKeys.length; presentIndex++) {
                //If we are ranking branches we do not want any regions
                if (rankType === 'Branches' && 
                    regions.indexOf(presentDataKeys[presentIndex]) !== -1) {
                    continue;
                }

                var pastIndex = pastDataKeys.indexOf(presentDataKeys[presentIndex]);

                var change = pastIndex === -1 ? 0 : pastIndex - presentIndex;
                var obj = {};
                obj['Name'] = presentData[presentDataKeys[presentIndex]][0].LOName
                obj['Branch'] = rankType === 'Individuals' ? presentData[presentDataKeys[presentIndex]][0].Branch ? presentData[presentDataKeys[presentIndex]][0].Branch : presentData[presentDataKeys[presentIndex]][0].Team : presentDataKeys[presentIndex]
                obj['Count'] = presentData[presentDataKeys[presentIndex]].length
                obj['Rank'] = rankingIndex + 1
                obj['PositionChange'] = change
                obj['Email'] = presentData[presentDataKeys[presentIndex]][0].LOEmail;
                obj['EmployeeID'] = this.state.employeeObj[presentData[presentDataKeys[presentIndex]][0].LOEmail];
                finalArray.push(obj);
                rankingIndex++;
            }
        }
        else {
            var compare = {}
            //Get Past and Present Ranking
            for (var regionIndex = 0; regionIndex < regions.length; regionIndex++) {
                presentIndex = presentDataKeys.indexOf(regions[regionIndex]);
                pastIndex = pastDataKeys.indexOf(regions[regionIndex]);
                compare[regions[regionIndex]] = {
                    'past': pastIndex,
                    'present': presentIndex
                };
            }
            var past = Object.keys(compare).sort(function (a, b) { return compare[a].past - compare[b].past })
            var present = Object.keys(compare).sort(function (a, b) { return compare[a].present - compare[b].present })

            for (var index = 0; index < present.length; index++) {
                let data = presentData[present[index]];

                //Added to prevent Exception
                if ( !data || data.length < 1) { 
                    continue 
                }

                presentIndex = index
                pastIndex = past.indexOf(present[index]);
                change = pastIndex === -1 ? 0 : pastIndex - presentIndex;
                obj = {};

                obj['Name'] = presentData[present[index]][0].LOName
                obj['Branch'] = rankType === 'Individuals' ? presentData[present[index]][0].Branch : present[index]
                obj['Count'] = presentData[present[index]].length
                obj['Rank'] = rankingIndex + 1
                obj['PositionChange'] = change
                obj['Email'] = presentData[present[index]][0].LOEmail;
                obj['EmployeeID'] = this.state.employeeObj[presentData[present[index]][0].LOEmail];
                finalArray.push(obj);
                rankingIndex++;
            }
        }



        return finalArray;
    }

    getRankChangeByPercentage(pastDataObj, presentDataObj, presentData, rankType) {
        var presentDataKeys = presentDataObj['keys'];
        var pastDataKeys = pastDataObj['keys'];

        var finalArray = [];
        
        //This is needed because some of the keys maybe filtered when performing region and branch checks so this index
        //will be used to determine the actual rank
        var rankingIndex = 0
        if (rankType !== 'Regions') {
            for (var presentIndex = 0; presentIndex < presentDataKeys.length; presentIndex++) {
                //If we are ranking vranches we do not want any regions
                if (rankType === 'Branches' && regions.indexOf(presentDataKeys[presentIndex]) !== -1) {
                    continue;
                }
                var validEmailFromDataObj = this.getValidEmail(presentData[presentDataKeys[presentIndex]]);

                var pastIndex = pastDataKeys.indexOf(presentDataKeys[presentIndex]);
                var change = pastIndex === -1 ? 0 : pastIndex - presentIndex;
                var obj = {};
                obj['Name'] = presentData[presentDataKeys[presentIndex]][0].LOName
                obj['Branch'] = rankType === 'Individuals' ? presentData[presentDataKeys[presentIndex]][0].Branch ? presentData[presentDataKeys[presentIndex]][0].Branch : presentData[presentDataKeys[presentIndex]][0].Team : presentDataKeys[presentIndex]
                obj['FundedCount'] = presentDataObj['percentageInfo'][presentDataKeys[presentIndex]]['Total Funded']
                obj['ApplicationCount'] = presentDataObj['percentageInfo'][presentDataKeys[presentIndex]]['Total Applications']
                obj['Percentage'] = presentDataObj['percentageInfo'][presentDataKeys[presentIndex]]['Percentage'].toFixed(1) + '%'
                obj['Rank'] = rankingIndex + 1
                obj['Email'] = validEmailFromDataObj
                obj['EmployeeID'] = this.getValidEmployeeID(validEmailFromDataObj);
                obj['PositionChange'] = change
                finalArray.push(obj);
                rankingIndex++;
            }
        } else {
            var compare = {}
            //Get Past and Present Ranking
            for (var regionIndex = 0; regionIndex < regions.length; regionIndex++) {
                presentIndex = presentDataKeys.indexOf(regions[regionIndex]);
                pastIndex = pastDataKeys.indexOf(regions[regionIndex]);
                compare[regions[regionIndex]] = {
                    'past': pastIndex,
                    'present': presentIndex
                };
            }
            var past = Object.keys(compare).sort(function (a, b) { return compare[a].past - compare[b].past })
            var present = Object.keys(compare).sort(function (a, b) { return compare[a].present - compare[b].present })

            for (var index = 0; index < present.length; index++) {

                if (!presentDataObj['percentageInfo'][present[index]]) {
                    continue;
                }
                presentIndex = index
                pastIndex = past.indexOf(present[index]);
                change = pastIndex === -1 ? 0 : pastIndex - presentIndex;
                obj = {};
                obj['Name'] = presentData[present[index]][0].LOName
                obj['Branch'] = rankType === 'Individuals' ? presentData[present[index]][0].Branch : present[index]
                obj['FundedCount'] = presentDataObj['percentageInfo'][present[index]]['Total Funded']
                obj['ApplicationCount'] = presentDataObj['percentageInfo'][present[index]]['Total Applications']
                obj['Percentage'] = presentDataObj['percentageInfo'][present[index]]['Percentage'].toFixed(1) + '%'
                obj['Rank'] = rankingIndex + 1
                obj['Email'] = validEmailFromDataObj
                obj['EmployeeID'] = this.getValidEmployeeID(validEmailFromDataObj);
                obj['PositionChange'] = change
                finalArray.push(obj);
                rankingIndex++;
            }

        }
        return finalArray;
    }


    getValidEmail(dataObj) {
        for (var i = 0; i < dataObj.length; i++) {
            if (dataObj[i].LOEmail) {
                return dataObj[i].LOEmail;
            }
        }
    }

    //This is used to ensure that we have the employee ID of all users, but there may be a case where the email on the loan does not correspond to the email in the user DB.
    //For most cases this is ok because this data is just being used for ranking.  In the event that this is the user that is in Athena Online, we will need to find his employee_id
    //based on the alias emails from the vandykEmployees collection in mongo
    getValidEmployeeID(emailFromLoan) {
        var myEmails = this.props.loginInfo.adUserData.itInfo.aliasEmails
        if (this.state.employeeObj[emailFromLoan]) {
            return this.state.employeeObj[emailFromLoan];
        }
        else if (myEmails.indexOf(emailFromLoan) !== 0) {
            for (var i = 0; i < myEmails.length; i++) {
                return this.state.employeeObj[myEmails[i]];
            }

        }
    }
    getBranchTable(interval, displayType, leaderFilter) {
        var tableRows = [];
        //Ensure Object is not empty
        // const { pipelineInfo } = this.props;
        const {
            Month,
            LastMonth,
            Year,
            LastYear
        }  = this.props.pipelineInfo.pipelineLeaderboardData;

        if (Object.keys(this.props.pipelineInfo.pipelineLeaderboardData).length > 0) {

            var sortedPast = [];
            var sortedPresent = [];
            var rankedData = [];
            var pastPullThroughPercentage = {};
            var presentPullThroughPercentage = {};
            // Process scenarios for a month table
            if (interval === 'Month') 
            {
                // Process Count
                if (displayType === 'Count') 
                {
                    // Check to see if branch is in interval/filter pair
                    if (LastMonth.Branch.Funded[leaderFilter] && 
                        Month.Branch.Funded[leaderFilter]) 
                    {
                        var pastDataArray = LastMonth.Branch.Funded[leaderFilter];
                        var presentDataArray = Month.Branch.Funded[leaderFilter];

                        //Create an Object with Key of LO Name
                        var pastDataObj = {};
                        var presentDataObj = {};


                        for (var i = 0; i < pastDataArray.length; i++) 
                        {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }
                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }
                        }

                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals');

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })
                    }
                    else if (!LastMonth.Branch.Funded[leaderFilter] && 
                            Month.Branch.Funded[leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Month.Branch.Funded[leaderFilter];
                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};


                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);

                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })

                    }
                    else 
                    {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='Count' />)
                    }
                }
                //Do not Process Percentage for Month
            }
            //Process scenarios for a year table
            else if (interval === 'Year') {
                //Process Count
                if (displayType === 'Count') {
                    //Process A Single Branch
                     //Check to see if branch is in interval/filter pair
                     if (LastYear.Branch.Funded[this.state.leaderFilter] && Year.Branch.Funded[this.state.leaderFilter]) {
                        pastDataArray = LastYear.Branch.Funded[this.state.leaderFilter];
                        presentDataArray = Year.Branch.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < pastDataArray.length; i++) {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }

                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={i} type='Individual' displayType='Count' />);
                        })
                    }
                    else if (!LastYear.Branch.Funded[this.state.leaderFilter] && 
                            Year.Branch.Funded[this.state.leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Year.Branch.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (var k = 0; k < presentDataArray.length; k++) {
                            if (presentDataObj[presentDataArray[k].LOName]) {
                                presentDataObj[presentDataArray[k].LOName].push(presentDataArray[k])
                            }
                            else {
                                presentDataObj[presentDataArray[k].LOName] = [presentDataArray[k]]
                            }

                        }
                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })

                    }
                    else {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='Count' />)
                    }
                }
                //Process Percentage
                else {

                    //Process A Single Branch
                    //Check to see if branch is in interval/filter pair
                    if (LastYear.Branch.Funded[this.state.leaderFilter] && 
                        Year.Branch.Funded[this.state.leaderFilter]) {
                        pastDataArray = LastYear.Branch.Funded[this.state.leaderFilter];
                        presentDataArray = Year.Branch.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < pastDataArray.length; i++) {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }

                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        pastPullThroughPercentage = this.sortObjPercentage(pastDataObj);
                        presentPullThroughPercentage = this.sortObjPercentage(presentDataObj);
                        rankedData = this.getRankChangeByPercentage(pastPullThroughPercentage, presentPullThroughPercentage, presentDataObj, 'Individuals');

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='PullThroughPercentage' />);
                        })
                    }
                    else if (!LastYear.Branch.Funded[this.state.leaderFilter] && Year.Branch.Funded[this.state.leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Year.Branch.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }
                        }


                        pastPullThroughPercentage = this.sortObjPercentage(pastDataObj);
                        presentPullThroughPercentage = this.sortObjPercentage(presentDataObj);
      
                        rankedData = this.getRankChangeByPercentage(pastPullThroughPercentage, presentPullThroughPercentage, presentDataObj, 'Individuals');

                        tableRows = rankedData.map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={i} type='Individual' displayType='PullThroughPercentage' />);
                        })

                    }
                    else {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='PullThroughPercentage' />)
                    }
                }


            }
        }
       
        return (
            <div>

                <h1>{interval === 'Year'? thisYear +' YTD':thisMonth +' '+todayDate.getFullYear()}</h1>
                <table>
                    <tr>
                        <col width="5%" />
                        <col width="10%" />
                        <col width="65%" />
                        <col width="15%" />
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'left', width: '5%' }}>Rank</th>
                        <th style={{ textAlign: 'left', width: '10%' }}></th>
                        <th style={{ textAlign: 'left', width: '65%' }}>Name</th>
                        {displayType === 'Count' && <th style={{ textAlign: 'left', width: '15%' }}>Funded Loans</th>}
                        {displayType === 'PullThroughPercentage' &&
                            <th style={{ textAlign: 'left', width: '15%' }}>Pull Through</th>
                        }
                    </tr>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>

                <br />
            </div>
        )

    }

    getRegionTable(interval, displayType, leaderFilter) {
        var tableRows = [];
        //Ensure Object is not empty
        const {
            Month,
            LastMonth,
            Year,
            LastYear
        } = this.props.pipelineInfo.pipelineLeaderboardData;

        if (Object.keys(this.props.pipelineInfo.pipelineLeaderboardData).length > 0) {

            var sortedPast = [];
            var sortedPresent = [];
            var rankedData = [];
            var pastPullThroughPercentage = {};
            var presentPullThroughPercentage = {};

            // Process scenarios for a month table
            if (interval === 'Month') 
            {
                // Process Count
                if (displayType === 'Count') 
                {
                    // Check to see if branch is in interval/filter pair
                    if (LastMonth.Region.Funded[leaderFilter] && 
                        Month.Region.Funded[leaderFilter]) {
                        var pastDataArray = LastMonth.Region.Funded[leaderFilter];
                        var presentDataArray = Month.Region.Funded[leaderFilter];

                        //Create an Object with Key of LO Name
                        var pastDataObj = {};
                        var presentDataObj = {};
                        var i;


                        for (i = 0; i < pastDataArray.length; i++) 
                        {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }

                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }
                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })
                    }
                    else if (!LastMonth.Region.Funded[leaderFilter] && 
                            Month.Region.Funded[leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Month.Region.Funded[leaderFilter];
                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};


                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);

                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })

                    }
                    else 
                    {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='Count' />)
                    }
                }
                //Do not Process Percentage for Month
            }
            //Process scenarios for a year table
            else if (interval === 'Year') {
                //Process Count
                if (displayType === 'Count') {
                    //Process A Single Branch
                        //Check to see if branch is in interval/filter pair
                    if (LastYear.Region.Funded[this.state.leaderFilter] && 
                        Year.Region.Funded[this.state.leaderFilter]) {
                        pastDataArray = LastYear.Region.Funded[this.state.leaderFilter];
                        presentDataArray = Year.Region.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < pastDataArray.length; i++) {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }

                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })
                    }
                    else if (!LastYear.Region.Funded[this.state.leaderFilter] && 
                            Year.Region.Funded[this.state.leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Year.Region.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }
                        sortedPast = this.sortObjByCount(pastDataObj);
                        sortedPresent = this.sortObjByCount(presentDataObj);
                        rankedData = this.getRankChangeByCount(sortedPast, sortedPresent, presentDataObj, 'Individuals')

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='Count' />);
                        })

                    }
                    else {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='Count' />)
                    }
                }
                //Process Percentage
                else {

                    //Process A Single Branch
                    //Check to see if branch is in interval/filter pair
                    if (LastYear.Region.Funded[this.state.leaderFilter] && Year.Region.Funded[this.state.leaderFilter]) {
                        pastDataArray = LastYear.Region.Funded[this.state.leaderFilter];
                        presentDataArray = Year.Region.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < pastDataArray.length; i++) {
                            if (pastDataObj[pastDataArray[i].LOName]) {
                                pastDataObj[pastDataArray[i].LOName].push(pastDataArray[i])
                            }
                            else {
                                pastDataObj[pastDataArray[i].LOName] = [pastDataArray[i]]
                            }

                        }

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }

                        pastPullThroughPercentage = this.sortObjPercentage(pastDataObj);
                        presentPullThroughPercentage = this.sortObjPercentage(presentDataObj);
                        rankedData = this.getRankChangeByPercentage(pastPullThroughPercentage, presentPullThroughPercentage, presentDataObj, 'Individuals');

                        tableRows = rankedData.map((data, index) => {
                            return (<LeaderboardReportCard dataForRow={data} key={index} type='Individual' displayType='PullThroughPercentage' />);
                        })
                    }
                    else if (!LastYear.Region.Funded[this.state.leaderFilter] && Year.Region.Funded[this.state.leaderFilter]) {
                        pastDataArray = []
                        presentDataArray = Year.Region.Funded[this.state.leaderFilter];

                        //Create an Object with Key of LO Name
                        pastDataObj = {};
                        presentDataObj = {};

                        for (i = 0; i < presentDataArray.length; i++) {
                            if (presentDataObj[presentDataArray[i].LOName]) {
                                presentDataObj[presentDataArray[i].LOName].push(presentDataArray[i])
                            }
                            else {
                                presentDataObj[presentDataArray[i].LOName] = [presentDataArray[i]]
                            }

                        }


                        pastPullThroughPercentage = this.sortObjPercentage(pastDataObj);
                        presentPullThroughPercentage = this.sortObjPercentage(presentDataObj);

                        rankedData = this.getRankChangeByPercentage(pastPullThroughPercentage, presentPullThroughPercentage, presentDataObj, 'Individuals');

                        tableRows = rankedData.map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={i} type='Individual' displayType='PullThroughPercentage' />);
                        })

                    }
                    else {
                        tableRows.push(<LeaderboardReportCard dataForRow={{ PositionChange: 'No Values To Display' }} key='99999999' type='Individual' displayType='PullThroughPercentage' />)
                    }
                }
            }
        }

        return (
            <div>

                <h1>{interval === 'Year' ? thisYear + ' YTD' : thisMonth + ' ' + todayDate.getFullYear()}</h1>
                <table>
                    <tr>
                        <col width="5%" />
                        <col width="10%" />
                        <col width="65%" />
                        <col width="15%" />
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'left', width: '5%' }}>Rank</th>
                        <th style={{ textAlign: 'left', width: '10%' }}></th>
                        <th style={{ textAlign: 'left', width: '65%' }}>Name</th>
                        {displayType === 'Count' && <th style={{ textAlign: 'left', width: '15%' }}>Funded Loans</th>}
                        {displayType === 'PullThroughPercentage' &&
                            <th style={{ textAlign: 'left', width: '15%' }}>Pull Through</th>
                        }
                    </tr>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>

                <br />
            </div>
        )

    }
    getTable(interval, displayType, leaderFilter, sliceSize) {

        var tableRows = [];
        const { pipelineLeaderboardData } = this.props.pipelineInfo;

        //Ensure Object is not empty
        if (pipelineLeaderboardData && Object.keys(pipelineLeaderboardData).length > 0) {
            const {
                Month,
                LastMonth,
                Year,
                LastYear
            } = pipelineLeaderboardData;
            var sortedPast = [];
            var sortedPresent = [];
            var rankedData = [];
            var pastPullThroughPercentage = {};
            var presentPullThroughPercentage = {};
            //Process scenarios for a month table
            if (interval === 'Month') {
                //Process Count
                if (displayType === 'Count') {
                    //Process AllIndividuals
                    if (leaderFilter === 'AllIndividuals') {
                        //Sort Values
                        sortedPast = this.sortObjByCount(LastMonth.Individual.Funded);
                        sortedPresent = this.sortObjByCount(Month.Individual.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Month.Individual.Funded,
                            'Individuals'
                        )
                        //Save My Current User Stats

                        this.getMyStats(rankedData, 'AllIndividualCountByMonth');
                        //Make LeaderboardCard For Indivduals
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'AICBM' + i} type='Individual' displayType='Count' />);
                        })
                    }
                    //Process AllBranches
                    else if (leaderFilter === 'AllBranches') {
                        sortedPast = this.sortObjByCount(LastMonth.Branch.Funded);
                        sortedPresent = this.sortObjByCount(Month.Branch.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Month.Branch.Funded,
                            'Branches'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllBranchesCountByMonth');
                        //Make LeaderboardCard For Branches
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ABCBM' + i} type='Branch' displayType='Count' />);
                        })
                    }
                    //Process AllRegions
                    else if (leaderFilter === 'AllRegions') {
                        sortedPast = this.sortObjByCount(LastMonth.Region.Funded);
                        sortedPresent = this.sortObjByCount(Month.Region.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Month.Region.Funded,
                            'Regions'
                        )

                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllRegionsCountByMonth');
                        //Make LeaderboardCard For Regions

                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ARCBM' + i} type='Region' displayType='Count' />);
                        })
                    }
                }
                //Do not Process Percentage for Month

            }
            //Process scenarios for a year table
            else if (interval === 'Year') {

                //Process Count
                if (displayType === 'Count') {

                    //Process AllIndividuals
                    if (leaderFilter === 'AllIndividuals') {
                        //Sort Values
                        sortedPast = this.sortObjByCount(LastYear.Individual.Funded);
                        sortedPresent = this.sortObjByCount(Year.Individual.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Year.Individual.Funded,
                            'Individuals'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllIndividualCountByYear');
                        //Make LeaderboardCard For Indivduals
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'AICBY' + i} type='Individual' displayType='Count' />);
                        })
                    }
                    //Process AllBranches
                    else if (leaderFilter === 'AllBranches') {
                        sortedPast = this.sortObjByCount(LastYear.Branch.Funded);
                        sortedPresent = this.sortObjByCount(Year.Branch.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Year.Branch.Funded,
                            'Branches'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllBranchesCountByYear');
                        //Make LeaderboardCard For Branches
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ABCBY' + i} type='Branch' displayType='Count' />);
                        })
                    }
                    //Process AllRegions
                    else if (leaderFilter === 'AllRegions') {
                        sortedPast = this.sortObjByCount(LastYear.Region.Funded);
                        sortedPresent = this.sortObjByCount(Year.Region.Funded);
                        rankedData = this.getRankChangeByCount(
                            sortedPast,
                            sortedPresent,
                            Year.Region.Funded,
                            'Regions'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllRegionsCountByYear');
                        //Make LeaderboardCard For Regions
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ARCBY' + i} type='Region' displayType='Count' />);
                        })
                    }
                }
                //Process Percentage
                else {
                    //Process AllIndividuals
                    if (leaderFilter === 'AllIndividuals') {
                        //Sort Values
                        pastPullThroughPercentage = this.sortObjPercentage(LastYear.Individual.Application);
                        presentPullThroughPercentage = this.sortObjPercentage(Year.Individual.Application);
                        rankedData = this.getRankChangeByPercentage(
                            pastPullThroughPercentage,
                            presentPullThroughPercentage,
                            Year.Individual.Application,
                            'Individuals'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllIndividualPercentageByYear');
                        //Make LeaderboardCard For Indivduals
                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'AIPBY' + i} type='Individual' displayType='PullThroughPercentage' />);
                        })
                    }
                    //Process AllBranches
                    else if (leaderFilter === 'AllBranches') {
                        pastPullThroughPercentage = this.sortObjPercentage(LastYear.Branch.Application);
                        presentPullThroughPercentage = this.sortObjPercentage(Year.Branch.Application);
                        rankedData = this.getRankChangeByPercentage(
                            pastPullThroughPercentage,
                            presentPullThroughPercentage,
                            Year.Branch.Application,
                            'Branches'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllBranchesPercentageByYear');

                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ABPBY' + i} type='Branch' displayType='PullThroughPercentage' />);
                        })
                    }
                    //Process AllBranches
                    else if (leaderFilter === 'AllRegions') {
                        pastPullThroughPercentage = this.sortObjPercentage(LastYear.Region.Application);
                        presentPullThroughPercentage = this.sortObjPercentage(Year.Region.Application);
                        rankedData = this.getRankChangeByPercentage(
                            pastPullThroughPercentage,
                            presentPullThroughPercentage,
                            Year.Region.Application,
                            'Regions'
                        )
                        //Save My Current User Stats
                        this.getMyStats(rankedData, 'AllRegionsPercentageByYear');

                        tableRows = rankedData.slice(0, sliceSize).map((data, i) => {
                            return (<LeaderboardReportCard dataForRow={data} key={'ARPBY' + i} type='Region' displayType='PullThroughPercentage' />);
                        })
                    }
                }
            }
        }

        return (
            <div>
                <h1>{interval === 'Year' ? thisYear + ' YTD' : thisMonth + ' ' + todayDate.getFullYear()}</h1>
                <table>
                    <tbody>
                        <tr>
                            <th style={{ textAlign: 'left', width: '5%' }}>Rank</th>
                            <th style={{ textAlign: 'left', width: '10%' }}></th>
                            <th style={{ textAlign: 'left', width: '65%' }}>Name</th>
                            {displayType === 'Count' && <th style={{ textAlign: 'left', width: '15%' }}>Funded Loans</th>}
                            {displayType === 'PullThroughPercentage' && <th style={{ textAlign: 'left', width: '15%' }}>Pull Through</th>}
                        </tr>
                        {tableRows}
                    </tbody>
                </table>
                <br />
            </div>
        )

    }

    getAllTables(sliceSize) {
        var allTables = {
            'MonthCountAllIndividuals': this.getTable('Month', 'Count', 'AllIndividuals', sliceSize),
            'MonthCountAllBranches': this.getTable('Month', 'Count', 'AllBranches', sliceSize),
            'MonthCountAllRegions': this.getTable('Month', 'Count', 'AllRegions', sliceSize),
            'YearCountAllIndividuals': this.getTable('Year', 'Count', 'AllIndividuals', sliceSize),
            'YearCountAllBranches': this.getTable('Year', 'Count', 'AllBranches', sliceSize),
            'YearCountAllRegions': this.getTable('Year', 'Count', 'AllRegions', sliceSize),
            'YearPullThroughPercentageAllIndividuals': this.getTable('Year', 'PullThroughPercentage', 'AllIndividuals', sliceSize),
            'YearPullThroughPercentageAllBranches': this.getTable('Year', 'PullThroughPercentage', 'AllBranches', sliceSize),
            'YearPullThroughPercentageAllRegions': this.getTable('Year', 'PullThroughPercentage', 'AllRegions', sliceSize),
        }

        this.setState({ allTables: allTables });
    }

    getMyStats(rankedData, intervalAndType) {
        var myEmails = this.props.loginInfo.adUserData.itInfo.aliasEmails
        var statsObj = this.state.myStats;
        for (var i = 0; i < rankedData.length; i++) {

            //If we are doing a branch search we should look to see if the Branch is the same as the current users branch
            if (intervalAndType.indexOf('Branch') !== -1 && rankedData[i].Branch === this.state.myStats['Branch']) {

                statsObj[intervalAndType] = rankedData[i]
                break;
            }
            if (intervalAndType.indexOf('Region') !== -1 && rankedData[i].Branch === this.state.myStats['Region']) {

                statsObj[intervalAndType] = rankedData[i]
                break;
            }
            else if (myEmails.indexOf(rankedData[i].Email) !== -1) {
                statsObj[intervalAndType] = rankedData[i]
                break;
            }
        }
        this.setState({ myStats: statsObj })
    }
    hideLinkAppModal() {
        this.getEmployeeIDMap();
        this.getCurrentEmployeeInfo();
        this.getThePipelineLeaderboard()
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal() {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }
    handleShowMore() {
        var newNumberOfLeaders = this.state.numberOfLeaders + 10
        this.setState({ numberOfLeaders: newNumberOfLeaders });
        this.getAllTables(newNumberOfLeaders)
    }


    render() {
        //Check Encompass Linkage
        if ((this.props.loginInfo.encompassLink === 'LINKED' || 
            (this.props.loginInfo.adUserData.itInfo.linkedApps.length > 0 && 
            this.props.loginInfo.adUserData.itInfo.linkedApps[0].appName === "Encompass")) && 
            !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }

        //Sets SnapShot to Today by default and set gotPipeStatus to true
        if (this.props.pipelineInfo.getPipelineLeaderboardStatus === 'SUCCEEDED' && 
            !this.state.gotPipeStatus) 
        {
            this.getDropDown()
            this.getAllTables(this.state.numberOfLeaders)
            this.setState({ gotPipeStatus: true, isLoading: false })
        }

        //Handle Failed Scenarios
        if (this.props.pipelineInfo.getPipelineLeaderboardStatus === 'ERRORED' && 
            !this.state.gotPipeStatus) 
        {
            alert(this.props.pipelineInfo.getPipelineLeaderboardErrorMessage)
            this.props.goToAnotherPage('/login')
            this.setState({ gotPipeStatus: true });
        }

        //Reset gotPipeStatus to false
        if ((this.props.pipelineInfo.getPipelineLeaderboardStatus === 'STARTED' || 
             this.props.pipelineInfo.getPipelineLeaderboardStatus === 'NOT_STARTED') && 
             this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true, showTable: false })
        }

        var linkAppModal = this.linkAppModal()
        var yearTableToDisplay = (<div></div>)
        var monthTableToDisplay = (<div></div>)

        //Uses allTables Object to quickly go between table views
        if (this.state.leaderFilter === 'AllBranches' || 
            this.state.leaderFilter === 'AllIndividuals' || 
            this.state.leaderFilter === 'AllRegions') 
        {
            var monthTableKey = 'Month' + this.state.displayType + this.state.leaderFilter;
            monthTableToDisplay = this.state.allTables[monthTableKey]
            var yearTableKey = 'Year' + this.state.displayType + this.state.leaderFilter;
            yearTableToDisplay = this.state.allTables[yearTableKey]
        }
        // In our dropdown selection we have an individual region selected
        else if (regions.includes(this.state.leaderFilter))
        {
             //If this is a branch we will call getRegionTable to get the table
             yearTableToDisplay = this.getRegionTable('Year', this.state.displayType, this.state.leaderFilter);
             monthTableToDisplay = this.getRegionTable('Month', this.state.displayType, this.state.leaderFilter);
        }
        // If in our dropdown selection we have an inidividual branch selected
        else 
        {
            //If this is a branch we will call getBranchTable to get the table
            yearTableToDisplay = this.getBranchTable('Year', this.state.displayType, this.state.leaderFilter);
            monthTableToDisplay = this.getBranchTable('Month', this.state.displayType, this.state.leaderFilter);
        }

        return (<div>
            {linkAppModal}
            <Grid style={{ width: "90%", color: '#282828' }}>
                <br />
                <Row>
                    <Col sm={12} md={12}>
                        <h1>Today's {this.state.appStatusText} Leaderboard</h1>
                    </Col>
                </Row>
                <br />

                {!this.state.isLoading && Object.keys(this.state.myStats.AllBranchesCountByYear).length > 0 &&
                    <h2><strong>My Yearly Stats</strong></h2>
                }


                {!this.state.isLoading && Object.keys(this.state.myStats.AllBranchesCountByYear).length > 0 &&
                    <LeaderboardYearlyStatsCard myStats={this.state.myStats} />
                }
                {this.state.isLoading &&
                    <div style={{ width: '100%', paddingTop: '50px' }}>
                        <Row>
                            <ProgressBar active striped bsStyle="info" now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`} />
                        </Row>

                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <p style={{ textAlign: 'center' }}>{this.props.pipelineInfo.progressText}
                                </p>
                            </Col>
                        </Row>
                        <br /><br />
                        <br /><br />
                        <br /><br />
                    </div>}
                {!this.state.isLoading &&
                    <div style={{ width: '100%', paddingTop: '50px' }}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} >
                                <Row>
                                    <h4>Showing:</h4>
                                </Row>
                                <Row>
                                    <select className='form-control ' onChange={this.changeFilter} value={this.state.leaderFilter}>
                                        {this.state.filterOptions.map(
                                            (optionObj, index) => (
                                                <option 
                                                    key={`leader-filter-option-${index + 1}`} 
                                                    value={optionObj.value}>{optionObj.label}</option>))}
                                    </select>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} mdOffset={4} lgOffset={4} >
                                <Row>
                                    <h4>Show Leaders by:</h4>
                                </Row>
                                <Row>
                                    {this.state.displayType === 'Count' &&
                                        <ButtonGroup>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={totalCountTooltip}>
                                                <Button bsStyle="primary">Count</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={pullThroughTooltip}>
                                                <Button onClick={() => this.toggleDisplayType()}>Percentage</Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    }
                                    {this.state.displayType === 'PullThroughPercentage' &&
                                        <ButtonGroup>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={totalCountTooltip}>
                                                <Button onClick={() => this.toggleDisplayType()}>Count</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={pullThroughTooltip}>
                                                <Button bsStyle="primary">Percentage</Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <hr />
                            {this.state.displayType === 'Count' &&
                                <Grid>
                                    {window.innerWidth > 768 && <Row>
                                        <Col sm={12} md={4} mdOffset={2}>{yearTableToDisplay}</Col>
                                        <Col sm={12} md={4}>{monthTableToDisplay}</Col>
                                    </Row>}
                                    {window.innerWidth <= 768 &&
                                        <Row>
                                            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" animation={false}>
                                                <Tab eventKey={1} title="Year">
                                                    <Col sm={12} md={4} mdOffset={2}>{yearTableToDisplay}</Col>
                                                </Tab>
                                                <Tab eventKey={2} title="Month">
                                                    <Col sm={12} md={4}>{monthTableToDisplay}</Col>
                                                </Tab>
                                            </Tabs>
                                        </Row>}

                                    <button className="btn btn-warning form-control" onClick={() => this.handleShowMore()}>Show More</button>
                                </Grid>
                            }
                            {this.state.displayType === 'PullThroughPercentage' &&
                                <Grid>
                                    {window.innerWidth > 768 && <Row>
                                        <Col sm={12} md={4} mdOffset={4}>{yearTableToDisplay}</Col>
                                    </Row>}
                                    {window.innerWidth <= 768 && <Row>
                                        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" animation={false}>
                                            <Tab eventKey={1} title="Year">
                                                <Col sm={12} md={4} mdOffset={2}>{yearTableToDisplay}</Col>
                                            </Tab>
                                        </Tabs>
                                    </Row>}
                                    <button className="btn btn-warning form-control" onClick={() => this.handleShowMore()}>Show More</button>
                                </Grid>
                            }
                        </Row>
                        <br />
                    </div>}
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        pipelineInfo: state.pipelineReducer,
        employeeInfo: state.employeeReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPipelineLeaderboard: getPipelineLeaderboard,
            resetPipeline: resetPipeline,
            updateProgress: updateProgress,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardView);
