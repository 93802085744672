import React from 'react';
import { render } from 'react-dom'
import './index.css';
import App from './containers/App';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import store, { history } from './store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable'

import {  Icon, Message } from 'semantic-ui-react';


render(
<Provider store={store}>
	<PersistGate 
		loading={
			<Message icon>
				<Icon name='circle notched' loading />
			</Message>
		} 
		persistor={persistStore(store)}
	>
		<ConnectedRouter history={history}>
			<App /> 
		</ConnectedRouter>
	</PersistGate>
</Provider>, 

document.getElementById('root'));

