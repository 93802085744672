// Import Datepicker
import { DatePickerInput } from 'rc-datepicker';
// Import the default style
import 'rc-datepicker/lib/style.css';
import React, { Component } from 'react';
import { Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import windowSize from 'react-window-size';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { logoutUser } from '../../../actions/loginActions';
import { getDashboardReport, resetPipeline } from '../../../actions/pipelineActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';
import { customFilter, customSort, customAddressFilter } from '../../../helpers/tableHelpers'
import { StickyContainer, Sticky } from 'react-sticky';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const date = new Date();
const curMonth = date.getMonth() + 1;
const curDay = date.getDate();
const curYear = date.getFullYear();
const currentDate = curMonth + '/' + curDay + '/' + curYear;
const Sugar = require('sugar-date')
const startDate = Sugar.Date.create('1 Week ago').getMonth() + 1 + '/' + Sugar.Date.create('1 Week ago').getDate() + '/' + Sugar.Date.create('1 Week ago').getFullYear();

const totalVals = ['TotalFundedCount', 'TotalFundedAmount', 'TotalPreApplicationCount', 'TotalPreApplicationAmount', 'TotalSubUWCount', 'TotalSubUWAmount']

class DashboardView extends Component {
    constructor(props) {
        super();
        this.state = {

            region: 'All',
            sortType: 'LOName',
            startDate: startDate,
            endDate: currentDate,

            isLoading: false,

            showLinkAppModal: false,


            gotPipeStatus: false,
            gotLinkStatus: false,



        }
        this.changeRegion = this.changeRegion.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.getInitialDashboard = this.getInitialDashboard.bind(this)
        this.updateDashboard = this.updateDashboard.bind(this)
        this.logout = this.logout.bind(this);
        this.generateBreakdownView = this.generateBreakdownView.bind(this)
    }

    componentDidMount() {
        this.getInitialDashboard()
        this.setState({
            chartWidth: window.innerWidth,
            isLoading: false
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }
    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }

    handleStartDateChange(date) {
        const Sugar = require('sugar-date')
        var yearsAgo5 = Sugar.Date.create('12/31/2012');

        if (date !== 'Invalid date' && yearsAgo5.getTime() < date.getTime()) {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            this.setState({ startDate: newMonth + '/' + newDate + '/' + newYear, startDateError: false, showButton: true })
        }
        else if (date !== 'Invalid date' && yearsAgo5.getTime() > date.getTime()) {
            this.before2013Error()
        }
        else {
            this.setState({ startDateError: true })
        }
    }
    handleEndDateChange(date) {
        const Sugar = require('sugar-date')
        var yearsAgo5 = Sugar.Date.create('12/31/2012');
        if (date !== 'Invalid date' && yearsAgo5.getTime() < date.getTime()) {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            this.setState({ endDate: newMonth + '/' + newDate + '/' + newYear, endDateError: false, showButton: true })
        }
        else if (date !== 'Invalid date' && yearsAgo5.getTime() > date.getTime()) {
            alert('Start Date selected is out of range.  Only Dates after 1/1/2013 can be used.')

        }
        else {
            this.setState({ endDateError: true })
        }
    }
    getInitialDashboard() {
        this.setState({ gotPipeStatus: false })

        var Sugar = require('sugar-date')
        var newDataStartDate = Sugar.Date.create('Last Week');
        var newDataStartMonth = newDataStartDate.getMonth() + 1;
        var newDataStartDay = newDataStartDate.getDate();
        var newDataStartYear = newDataStartDate.getFullYear();
        var newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

        var pipelineParam = {
            startDate: newDataStartDate,
            newDataStartDate: newDataStartDate,
            endDate: todayDate,
            todayDate: todayDate,
            region: this.state.region,
        }

        this.props.getDashboardReport(pipelineParam);

        this.setState({ isLoading: true })

    }
    updateDashboard() {
        this.setState({ gotPipeStatus: false, isLoading: true })
        var Sugar = require('sugar-date')
        var newDataStartDate = Sugar.Date.create(this.state.startDate);
        var newDataStartMonth = newDataStartDate.getMonth() + 1;
        var newDataStartDay = newDataStartDate.getDate();
        var newDataStartYear = newDataStartDate.getFullYear();
        var newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

        var end = new Date(this.state.endDate);
        var endDate = end.getMonth() + 1 + '/' + end.getDate() + '/' + end.getFullYear()


        var pipelineParam = {
            startDate: newDataStartDate,
            newDataStartDate: newDataStartDate,
            endDate: endDate,
            todayDate: todayDate,
            region: this.state.region,
        }

        this.props.getDashboardReport(pipelineParam);



    }
    handleSubmit() {
        this.setState({ gotPipeStatus: false })
        if (this.state.endDateError || this.state.startDateError) {
            this.dateFormatError()
            return;
        }
        if (Date.parse(this.state.startDate) > Date.parse(this.state.endDate)) {
            this.dateRangeError()
            return;
        }

        var Sugar = require('sugar-date')
        var newDataStartDate = Sugar.Date.create('Yesterday');
        var newDataStartMonth = newDataStartDate.getMonth() + 1;
        var newDataStartDay = newDataStartDate.getDate();
        var newDataStartYear = newDataStartDate.getFullYear();
        var newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
            field: "FundedDate",//Keep here for config formatting purposes
            filter: "1997",//Keep here for config formatting purposes
            sort: "LOName",
            startDate: this.state.startDate,
            newDataStartDate: newDataStartDate,
            endDate: this.state.endDate,
            todayDate: todayDate,
            region: this.state.region,
            forBankingReport: true
        }

        this.props.getDashboardReport(pipelineParam);

        this.setState({ isLoading: true })

    }


    hideLinkAppModal() {
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }

    dateFormatError = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Date Range invalid.  Ensure that dates are of the form MM/DD/YYYY.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };

    dateRangeError = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Date Range invalid. Start Date is after end date.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };

    before2013Error = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Start Date selected is out of range.  Only Dates after 1/1/2013 can be used.',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };

    /////////////////////////////////////////////////////
    //Write Files to xlsx
    /////////////////////////////////////////////////////
    writeFile(filedata, outFileName) {
        //Add File to Upload List
        var that = this;
        const xl = require('excel4node');


        const wb = new xl.Workbook();
        const dollarStyle = wb.createStyle({ numberFormat: '$#,##0.00; ($#,##0.00); -' });
        const grandTotalCellStyle = wb.createStyle({
            font: {
                bold: true,

            },
            alignment: {
                wrapText: true
            },
            fill: {
                type: 'pattern',
                patternType: 'solid',
                bgColor: '#AAAAAA',
                fgColor: '#AAAAAA',
            }

        });
        const totalCellStyle = wb.createStyle({
            font: {
                bold: true,

            },
            fill: {
                type: 'pattern',
                patternType: 'solid',
                bgColor: '#FFFF00',
                fgColor: '#FFFF00',
            }

        });
        const totalDollarCellStyle = wb.createStyle({
            font: {
                bold: true,
            },
            fill: {
                type: 'pattern',
                patternType: 'solid',
                bgColor: '#FFFF00',
                fgColor: '#FFFF00',
            },
            numberFormat: '$#,##0.00; ($#,##0.00); -'

        });
        const ws = wb.addWorksheet('Funding Report', {
            outline: {
                summaryBelow: false, // Change to False if your summary row is above your item rows
            }
        });

        let curRow = 1;

        for (var i = 0; i < filedata.length; i++) {
            //Header
            if (i == 0) {
                ws.cell(curRow, 1).string(filedata[i][0]).style(grandTotalCellStyle);
                ws.cell(curRow, 2).string(filedata[i][1]).style(grandTotalCellStyle);
                ws.cell(curRow, 3).string(filedata[i][2]).style(grandTotalCellStyle);
                ws.cell(curRow, 4).string(filedata[i][3]).style(grandTotalCellStyle);
                ws.cell(curRow, 5).string(filedata[i][4]).style(grandTotalCellStyle);
                ws.cell(curRow, 6).string(filedata[i][5]).style(grandTotalCellStyle);
                ws.cell(curRow, 7).string(filedata[i][6]).style(grandTotalCellStyle);
                ws.cell(curRow, 8).string(filedata[i][7]).style(grandTotalCellStyle);
                ws.cell(curRow, 9).string(filedata[i][8]).style(grandTotalCellStyle);
                ws.cell(curRow, 10).string(filedata[i][9]).style(grandTotalCellStyle);
                ws.cell(curRow, 11).string(filedata[i][10]).style(grandTotalCellStyle);
                ws.cell(curRow, 12).string(filedata[i][11]).style(grandTotalCellStyle);
                ws.cell(curRow, 13).string(filedata[i][12]).style(grandTotalCellStyle);
                ws.cell(curRow, 14).string(filedata[i][13]).style(grandTotalCellStyle);
                ws.cell(curRow, 15).string(filedata[i][14]).style(grandTotalCellStyle);
                ws.cell(curRow, 16).string(filedata[i][15]).style(grandTotalCellStyle);
                ws.cell(curRow, 17).string(filedata[i][16]).style(grandTotalCellStyle);
                ws.cell(curRow, 18).string(filedata[i][17]).style(grandTotalCellStyle);
                ws.cell(curRow, 19).string(filedata[i][18]).style(grandTotalCellStyle);
                ws.cell(curRow, 20).string(filedata[i][19]).style(grandTotalCellStyle);

            }
            else {

                if (filedata[i][1] === "Grand Total") //Grand Total Row
                {
                    ws.row(curRow).group(0);
                    ws.cell(curRow, 1).string(filedata[i][0]).style(totalCellStyle);
                    ws.cell(curRow, 2).string(filedata[i][1]).style(totalCellStyle);
                    ws.cell(curRow, 3).string(filedata[i][2]).style(totalCellStyle);
                    ws.cell(curRow, 4).string(filedata[i][3]).style(totalCellStyle);
                    ws.cell(curRow, 5).string(filedata[i][4]).style(totalCellStyle);
                    (typeof filedata[i][5] === 'string' || filedata[i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[i][5]) : ws.cell(curRow, 6).number(filedata[i][5]).style(totalCellStyle);
                    (typeof filedata[i][6] === 'string' || filedata[i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[i][6]) : ws.cell(curRow, 7).number(filedata[i][6]).style(totalDollarCellStyle);
                    (typeof filedata[i][7] === 'string' || filedata[i][7] instanceof String) ? ws.cell(curRow, 8).string(filedata[i][7]) : ws.cell(curRow, 8).number(filedata[i][7]).style(totalCellStyle);
                    (typeof filedata[i][8] === 'string' || filedata[i][8] instanceof String) ? ws.cell(curRow, 9).string(filedata[i][8]) : ws.cell(curRow, 9).number(filedata[i][8]).style(totalDollarCellStyle);
                    (typeof filedata[i][9] === 'string' || filedata[i][9] instanceof String) ? ws.cell(curRow, 10).string(filedata[i][9]) : ws.cell(curRow, 10).number(Math.round(filedata[i][9])).style(totalCellStyle);
                    (typeof filedata[i][10] === 'string' || filedata[i][10] instanceof String) ? ws.cell(curRow, 11).string(filedata[i][10]) : ws.cell(curRow, 11).number(Math.round(filedata[i][10])).style(totalCellStyle);
                    ws.cell(curRow, 12).string(filedata[i][11]).style(totalCellStyle);
                    ws.cell(curRow, 13).string(filedata[i][12]).style(totalCellStyle);
                    ws.cell(curRow, 14).string(filedata[i][13]).style(totalCellStyle);
                    ws.cell(curRow, 15).string(filedata[i][14]).style(totalCellStyle);
                    ws.cell(curRow, 16).string(filedata[i][15]).style(totalCellStyle);
                    ws.cell(curRow, 17).string(filedata[i][16]).style(totalCellStyle);
                    ws.cell(curRow, 18).string(filedata[i][17]).style(totalCellStyle);
                    ws.cell(curRow, 19).string(filedata[i][18]).style(totalCellStyle);
                    ws.cell(curRow, 20).string(filedata[i][19]).style(totalCellStyle);
                }
                else if (!filedata[i][0]) //Department Total Row
                {
                    ws.row(curRow).group(1);
                    ws.cell(curRow, 1).string(filedata[i][0]).style(totalCellStyle);
                    ws.cell(curRow, 2).string(filedata[i][1]).style(totalCellStyle);
                    ws.cell(curRow, 3).string(filedata[i][2]).style(totalCellStyle);
                    ws.cell(curRow, 4).string(filedata[i][3]).style(totalCellStyle);
                    ws.cell(curRow, 5).string(filedata[i][4]).style(totalCellStyle);
                    (typeof filedata[i][5] === 'string' || filedata[i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[i][5]) : ws.cell(curRow, 6).number(filedata[i][5]).style(totalCellStyle);
                    (typeof filedata[i][6] === 'string' || filedata[i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[i][6]) : ws.cell(curRow, 7).number(filedata[i][6]).style(totalDollarCellStyle);
                    (typeof filedata[i][7] === 'string' || filedata[i][7] instanceof String) ? ws.cell(curRow, 8).string(filedata[i][7]) : ws.cell(curRow, 8).number(filedata[i][7]).style(totalCellStyle);
                    (typeof filedata[i][8] === 'string' || filedata[i][8] instanceof String) ? ws.cell(curRow, 9).string(filedata[i][8]) : ws.cell(curRow, 9).number(filedata[i][8]).style(totalDollarCellStyle);
                    (typeof filedata[i][9] === 'string' || filedata[i][9] instanceof String) ? ws.cell(curRow, 10).string(filedata[i][9]) : ws.cell(curRow, 10).number(Math.round(filedata[i][9])).style(totalCellStyle);
                    (typeof filedata[i][10] === 'string' || filedata[i][10] instanceof String) ? ws.cell(curRow, 11).string(filedata[i][10]) : ws.cell(curRow, 11).number(Math.round(filedata[i][10])).style(totalCellStyle);
                    ws.cell(curRow, 12).string(filedata[i][11]).style(totalCellStyle);
                    ws.cell(curRow, 13).string(filedata[i][12]).style(totalCellStyle);
                    ws.cell(curRow, 14).string(filedata[i][13]).style(totalCellStyle);
                    ws.cell(curRow, 15).string(filedata[i][14]).style(totalCellStyle);
                    ws.cell(curRow, 16).string(filedata[i][15]).style(totalCellStyle);
                    ws.cell(curRow, 17).string(filedata[i][16]).style(totalCellStyle);
                    ws.cell(curRow, 18).string(filedata[i][17]).style(totalCellStyle);
                    ws.cell(curRow, 19).string(filedata[i][18]).style(totalCellStyle);
                    ws.cell(curRow, 20).string(filedata[i][19]).style(totalCellStyle);
                }
                else //Normal Row
                {
                    ws.row(curRow).group(2);
                    ws.cell(curRow, 1).string(filedata[i][0]);
                    ws.cell(curRow, 2).string(filedata[i][1]);
                    ws.cell(curRow, 3).string(filedata[i][2]);
                    ws.cell(curRow, 4).string(filedata[i][3]);
                    ws.cell(curRow, 5).string(filedata[i][4]);
                    (typeof filedata[i][5] === 'string' || filedata[i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[i][5]) : ws.cell(curRow, 6).number(filedata[i][5]);
                    (typeof filedata[i][6] === 'string' || filedata[i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[i][6]) : ws.cell(curRow, 7).number(filedata[i][6]).style(dollarStyle);
                    (typeof filedata[i][7] === 'string' || filedata[i][7] instanceof String) ? ws.cell(curRow, 8).string(filedata[i][7]) : ws.cell(curRow, 8).number(filedata[i][7]);
                    (typeof filedata[i][8] === 'string' || filedata[i][8] instanceof String) ? ws.cell(curRow, 9).string(filedata[i][8]) : ws.cell(curRow, 9).number(filedata[i][8]).style(dollarStyle);
                    (typeof filedata[i][9] === 'string' || filedata[i][9] instanceof String) ? ws.cell(curRow, 10).string(filedata[i][9]) : ws.cell(curRow, 10).number(filedata[i][9]);
                    (typeof filedata[i][10] === 'string' || filedata[i][10] instanceof String) ? ws.cell(curRow, 11).string(filedata[i][10]) : ws.cell(curRow, 11).number(filedata[i][10]);
                    ws.cell(curRow, 12).string(filedata[i][11]);
                    ws.cell(curRow, 13).string(filedata[i][12]);
                    ws.cell(curRow, 14).string(filedata[i][13]);
                    ws.cell(curRow, 15).string(filedata[i][14]);
                    ws.cell(curRow, 16).string(filedata[i][15]);
                    ws.cell(curRow, 17).string(filedata[i][16]);
                    ws.cell(curRow, 18).string(filedata[i][17]);
                    ws.cell(curRow, 19).string(filedata[i][18]);
                    ws.cell(curRow, 20).string(filedata[i][19]);
                }



            }

            curRow += 1

        }

        wb.writeToBuffer().then(function (buffer) {
            var blob = new Blob([buffer])
            that.downloadBlob(blob, outFileName)
        });



    }
    downloadBlob(blob, filename) {
        // Create an object URL for the blob object
        const url = URL.createObjectURL(blob);

        // Create a new anchor element
        const a = document.createElement('a');

        // Set the href and download attributes for the anchor element
        // You can optionally set other attributes like `title`, etc
        // Especially, if the anchor element will be attached to the DOM
        a.href = url;
        a.download = filename || 'download';

        // Click handler that releases the object URL after the element has been clicked
        // This is required for one-off downloads of the blob content
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);

            }, 150);
        };

        // Add the click event listener on the anchor element
        // Comment out this line if you don't want a one-off download of the blob content
        a.addEventListener('click', clickHandler, false);

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        // Comment out this line if you don't want an automatic download of the blob content
        a.click();

        // Return the anchor element
        // Useful if you want a reference to the element
        // in order to attach it to the DOM or use it in some other way
        return a;
    }
    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }



    changeRegion(event) {
        this.setState({ region: event.target.value, showButton: true })
    }
    formatMoney(oldMoney) {
        if (oldMoney) {
            var partialFormat = parseFloat(oldMoney).toFixed(2)
            var parts = partialFormat.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        else {
            return "0.00"
        }
    }

    generateBreakdownView() {
        if (this.props.pipelineInfo.dashboardReportData) {


            //Add Region Total Accordian Items
            var accRegionItems = Object.keys(this.props.pipelineInfo.dashboardReportData).sort(customSort).map((region) => {

                var accDepartmentItems = Object.keys(this.props.pipelineInfo.dashboardReportData[region]).sort(customSort).map((dept) => {

                    var accLOItems = Object.keys(this.props.pipelineInfo.dashboardReportData[region][dept]).sort(customSort).map((lo) => {

                        var accLoanItems = Object.keys(this.props.pipelineInfo.dashboardReportData[region][dept][lo]).sort(customSort).map((loans) => {
                            if (totalVals.indexOf(lo) === -1) {

                                return Object.keys(this.props.pipelineInfo.dashboardReportData[region][dept][lo][loans]).sort(customSort).map((loanIndex) => {
                                    var loan = this.props.pipelineInfo.dashboardReportData[region][dept][lo][loans][loanIndex]
                                    var loanStatus = '';
                                    var date = ''
                                    if (loan.FundedDate) {
                                        loanStatus = "Funded"
                                        date = loan.FundedDate
                                    }
                                    else if (loan.SubmittedToUWDate) {
                                        loanStatus = " Submitted To UW"
                                        date = loan.SubmittedToUWDate
                                    }
                                    else {
                                        loanStatus = 'Pre-Application'
                                        date = loan.PreApplicationDate
                                    }
                                    return (
                                        <tr>
                                            <td width="20%">{loan.BorrowerFirstName + ' ' + loan.BorrowerLastName}</td>
                                            <td width="20%">{'$'+this.formatMoney(loan.LoanAmount.slice(0, -2))}</td>
                                            <td width="20%">{loanStatus}</td>
                                            <td width="20%">{date}</td>
                                        </tr>
                                    )

                                })

                            }
                        })

                        if (totalVals.indexOf(lo) === -1) {
                            return (<AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton style={{ backgroundColor: '#DDD' }}>
                                        <Row>
                                            <Col style={{ fontWeight: 'bold' }} md={3}>{lo}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalFundedCount']}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalFundedAmount'])}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalSubUWCount']}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalSubUWAmount'])}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalPreApplicationCount']}</Col>
                                            <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept][lo]['TotalPreApplicationAmount'])}</Col>
                                        </Row>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Accordion allowMultipleExpanded allowZeroExpanded><table>
                                        <tr>
                                            <th width="20%">Name</th>
                                            <th width="20%">Loan Amount</th>
                                            <th width="20%">Loan Status</th>
                                            <th width="20%">Date</th>
                                        </tr>
                                        {accLoanItems}
                                    </table></Accordion>
                                </AccordionItemPanel>
                            </AccordionItem>)
                        }

                    })

                    if (totalVals.indexOf(dept) === -1) {
                        return (<AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton style={{ backgroundColor: '#DDD' }}>
                                    <Row>
                                        <Col style={{ fontWeight: 'bold' }} md={3}>{'Dept ' + dept}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept]['TotalFundedCount']}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept]['TotalFundedAmount'])}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept]['TotalSubUWCount']}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept]['TotalSubUWAmount'])}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region][dept]['TotalPreApplicationCount']}</Col>
                                        <Col style={{ fontWeight: 'bold' }} md={2}>{'  $' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region][dept]['TotalPreApplicationAmount'])}</Col>
                                    </Row>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Accordion allowMultipleExpanded allowZeroExpanded>{accLOItems}</Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>)

                    }


                })


                if (totalVals.indexOf(region) === -1) {
                    return (<AccordionItem >
                        <AccordionItemHeading >
                            <AccordionItemButton style={{ backgroundColor: '#DDD' }}>
                                <Row>
                                    <Col style={{ fontWeight: 'bold' }} md={3}>{'Region ' + region}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region]['TotalFundedCount']}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region]['TotalFundedAmount'])}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region]['TotalSubUWCount']}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region]['TotalSubUWAmount'])}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={1}>{this.props.pipelineInfo.dashboardReportData[region]['TotalPreApplicationCount']}</Col>
                                    <Col style={{ fontWeight: 'bold' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData[region]['TotalPreApplicationAmount'])}</Col>
                                </Row>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <Accordion allowMultipleExpanded allowZeroExpanded>{accDepartmentItems}</Accordion>
                        </AccordionItemPanel>
                    </AccordionItem>)
                }

            })


            //Add Overall Totals
            accRegionItems.unshift(
                <Row>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={3}>Total</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={1}>{this.props.pipelineInfo.dashboardReportData['TotalFundedCount']}</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData['TotalFundedAmount'])}</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={1}>{this.props.pipelineInfo.dashboardReportData['TotalSubUWCount']}</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData['TotalSubUWAmount'])}</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={1}>{this.props.pipelineInfo.dashboardReportData['TotalPreApplicationCount']}</Col>
                    <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>{'$' + this.formatMoney(this.props.pipelineInfo.dashboardReportData['TotalPreApplicationAmount'])}</Col>
                </Row>
            )

            return (
                <div>
                    <Row>

                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} mdOffset={3} md={1}>Funded Ct</Col>
                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>Funded Amount</Col>
                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={1}>UW Ct</Col>
                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>UW Amount</Col>
                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={1}>PreApp Ct</Col>
                        <Col style={{ fontWeight: 'bold', fontSize: '1rem' }} md={2}>PreApp Amount</Col>
                    </Row>
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                        {accRegionItems}
                    </Accordion>
                </div>

            )
        }
    }
    render() {
        var linkAppModal = this.linkAppModal();
        var breakdownView = this.generateBreakdownView();
        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }



        if (this.props.pipelineInfo.getDashboardStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
            console.log('GOT IT', this.props.pipelineInfo.dashboardReportData)
            this.setState({ gotPipeStatus: true, isLoading: false });
        }
        //Handle Failed/Errored Scenarios
        else if (this.props.pipelineInfo.getDashboardStatus === 'ERRORED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getBankReportErrorMessage)
            this.setState({ gotPipeStatus: true });
        }
        else if (this.props.pipelineInfo.getDashboardStatus === 'FAILED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getBankReportErrorMessage)
            this.setState({ gotPipeStatus: true });
        }


        //Reset gotPipeStatus to false
        if (this.props.pipelineInfo.getDashboardStatus === 'STARTED' && this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true })
        }


        return (
            <div>
                <Grid style={{ width: "90%", color: '#282828' }}>
                    {linkAppModal}
                    <br />

                    <Col>
                        <h3 style={{ fontSize: '2rem', textAlign: 'left' }}>Region Dashboard</h3>
                    </Col>

                    <br />
                    <Row>
                        <Col md={6}>
                            <h4>Start Date</h4>
                            <DatePickerInput
                                onChange={this.handleStartDateChange}
                                value={this.state.startDate}
                                className='my-custom-datepicker-component'
                            />
                        </Col>
                        <Col md={6}>
                            <h4>End Date</h4>
                            <DatePickerInput
                                onChange={this.handleEndDateChange}
                                value={this.state.endDate}
                                className='my-custom-datepicker-component'
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col md={6}>
                            <h4>Region</h4>
                            <select className='form-control ' onChange={this.changeRegion} value={this.state.region}>
                                <option value="All">All Regions</option>
                                <option value="001">Region 001</option>
                                <option value="002">Region 002</option>
                                <option value="003">Region 003</option>
                                <option value="004">Region 004</option>
                                <option value="005">Region 005</option>
                                <option value="006">Region 006</option>
                                <option value="007">Region 007</option>

                            </select>
                        </Col>
                    </Row>
                    <br />
                    <Col md={12}>
                        <button className="btn btn-success form-control" onClick={() => this.updateDashboard()}>Update Report</button>
                    </Col>
                    <br />
                    <br />



                    {this.props.pipelineInfo.getDashboardStatus !== 'SUCCEEDED' && <Row>
                        <Form success size='large' key='large'>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                            </Message>
                        </Form>
                    </Row>}
                    <hr />
                    <br />

                    {this.props.pipelineInfo.getDashboardStatus === 'SUCCEEDED' && <Row>
                        <Col md={12}>
                            {breakdownView}
                        </Col>
                    </Row>}

                    {this.state.isLoading &&
                        <div style={{ width: '100%', paddingTop: '50px' }}>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ textAlign: 'center' }}>Generating Report
                                    </p>
                                </Col>
                            </Row>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                        </div>}
                </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getDashboardReport: getDashboardReport,
            resetPipeline: resetPipeline,
            logoutUser: logoutUser,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(windowSize(DashboardView));
