import React, { Component } from 'react';


import { Col, Row, Form, Grid, FormControl, FormGroup, ControlLabel, InputGroup, Button } from 'react-bootstrap';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { PieChart, ResponsiveContainer, Pie, Cell, Tooltip, Label, XAxis, YAxis } from 'recharts'
import { isNumber } from 'util';
import { DropDownMenu } from 'material-ui';
import CalculatorTable from '../tables/calculator_tables/CalculatorTable';
import { numberWithCommas } from '../../helpers/getDollars'

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class FundingsView extends Component {
    constructor(props) {
        super();
        this.state = {
            homePrice: 100000,
            downPaymentAmount: 30000,
            downPaymentPercentage: 30,
            loanProgram: '30 Year Fixed',
            loanTerm: 30,
            interestRate: 4.095,
            pmi: .69,
            propertyTaxAmount: 1000,
            propertyTaxPercentage: 1,
            homeInsurance: 0,
            hoa: 0,
            currentBreakdown: [],
            width: 0,
            height: 0,
            modalIsOpen: false,
            fullReportData: {}
        }

        this.changeDownPaymentAmount = this.changeDownPaymentAmount.bind(this)
        this.changeDownPaymentPercentage = this.changeDownPaymentPercentage.bind(this)
        this.changeLoanProgram = this.changeLoanProgram.bind(this)
        this.changeInterestRate = this.changeInterestRate.bind(this)
        this.handleHomePriceChange = this.handleHomePriceChange.bind(this);
        this.handleGenericValueChange = this.handleGenericValueChange.bind(this);
        this.handleDownPaymentAmountChange = this.handleDownPaymentAmountChange.bind(this);
        this.handleDownPaymentPercentageChange = this.handleDownPaymentPercentageChange.bind(this);
        this.handlePropertyTaxAmountChange = this.handlePropertyTaxAmountChange.bind(this);
        this.handlePropertyTaxPercentageChange = this.handlePropertyTaxPercentageChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        this.getFullReport = this.getFullReport.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }



    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidMount() {
        var moment = require('moment');
        var twix = require('twix');
        var Sugar = require('sugar-date')
        var In30Years = Sugar.Date.create('in 30 years');

        var itr = moment.twix(Date.now(), In30Years).iterate(1, "month");
        var monthMap = {}
        var counter = 1;
        while (itr.hasNext()) {
            var dateInterval = itr.next().toDate()
            var month = dateInterval.getMonth() + 1
            var year = dateInterval.getFullYear()
            var finalDateTime = month + '/' + year
            monthMap[counter] = finalDateTime
            counter++;
        }

        this.setState({ monthMap: monthMap })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleHomePriceChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        //Check to see if this is a number
        if (isNaN(+value)) {
            this.setState({
                homePrice: this.state.homePrice,
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                propertyTaxAmount: this.state.propertyTaxAmount,
                propertyTaxPercentage: this.state.propertyTaxPercentage,
                homePriceError: 'Please Enter a Valid Numeric Value'
            });

        }
        else {

            var newDownPaymentPercentage = (this.state.downPaymentAmount / value) * 100
            var newDownPaymentAmount = (newDownPaymentPercentage * value) / 100

            var newPropertyTaxPercentage = (this.state.propertyTaxAmount / value) * 100
            var newPropertyTaxAmount = (newPropertyTaxPercentage * value) / 100
            this.setState({
                homePrice: value,
                downPaymentAmount: newDownPaymentAmount,
                downPaymentPercentage: Math.round(newDownPaymentPercentage),
                propertyTaxAmount: newPropertyTaxAmount,
                propertyTaxPercentage: Math.round(newPropertyTaxPercentage),
                homePriceError: ''
            });
        }
    }
    handleDownPaymentAmountChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (isNaN(+value)) {
            this.setState({
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                downPaymentAmountError: 'Please Enter a Valid Numeric Value',
                downPaymentPercentageError: 'Please Enter a Valid Numeric Value'
            });
        }
        else if (value > this.state.homePrice) {
            this.setState({
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                downPaymentAmountError: 'Down Payment amount can not Be higher than the Home Price',
                downPaymentPercentageError: 'Please Enter a numeric value below 100'

            });

        }
        else {
            var newDownPaymentPercentage = (value / this.state.homePrice) * 100
            this.setState({
                downPaymentAmount: value,
                downPaymentPercentage: Math.round(newDownPaymentPercentage),
                downPaymentAmountError: ''
            });
        }
    }

    handleDownPaymentPercentageChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (isNaN(+value)) {
            this.setState({
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                downPaymentAmountError: 'Please Enter a Valid Numeric Value'

            });
        }
        else if (value > 100) {
            this.setState({
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                downPaymentAmountError: 'Down Payment amount can not Be higher than the Home Price',
                downPaymentPercentageError: 'Please Enter a numeric value below 100'

            });
        }
        else {
            var newDownPaymentAmount = (value * this.state.homePrice) / 100
            this.setState({
                downPaymentAmount: newDownPaymentAmount,
                downPaymentPercentage: Math.round(value),
                downPaymentAmountError: ''
            });
        }
    }



    handlePropertyTaxAmountChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (isNaN(+value)) {
            this.setState({
                propertyTaxAmount: this.state.propertyTaxAmount,
                propertyTaxPercentage: this.state.propertyTaxPercentage,
                propertyTaxAmountError: 'Please Enter a Valid Numeric Value',
                propertyTaxPercentageError: 'Please Enter a Valid Numeric Value'


            });
        }
        else {
            var newPropertyTaxPercentage = (value / this.state.homePrice) * 100
            this.setState({
                propertyTaxAmount: value,
                propertyTaxPercentage: Math.round(newPropertyTaxPercentage),
                propertyTaxAmountError: '',
                propertyTaxPercentageError: '',
            });
        }
    }

    handlePropertyTaxPercentageChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (isNaN(+value)) {
            this.setState({
                propertyTaxAmount: this.state.propertyTaxAmount,
                propertyTaxPercentage: this.state.propertyTaxPercentage,
                propertyTaxPercentageError: 'Please Enter a Valid Numeric Value',
                propertyTaxAmountError: 'Please Enter a Valid Numeric Value',

            });
        }
        else if (value > 100) {
            this.setState({
                downPaymentAmount: this.state.downPaymentAmount,
                downPaymentPercentage: this.state.downPaymentPercentage,
                propertyTaxAmount: this.state.propertyTaxAmount,
                propertyTaxPercentage: this.state.propertyTaxPercentage,
                propertyTaxPercentageError: 'Please Enter a numeric value below 100'
            });
        }
        else {
            var newPropertyTaxAmount = (value * this.state.homePrice) / 100
            this.setState({
                propertyTaxAmount: newPropertyTaxAmount,
                propertyTaxPercentage: Math.round(value),
                propertyTaxPercentageError: '',
                propertyTaxAmountError: ''

            });
        }
    }
    //This is a generic handler because the fields using this do not affect other values
    handleGenericValueChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (isNaN(+value)) {
            this.setState({
                [name]: this.state[name],
                [name + 'Error']: 'Please Enter a Valid Numeric Value'
            });
        }
        else {
            this.setState({
                [name]: value,
                [name + 'Error']: ''
            });
        }

    }

    changeDownPaymentAmount(event) {
        this.setState({ loanProgram: event.target.value, })
    }
    changeDownPaymentPercentage(event) {
        this.setState({ loanProgram: event.target.value, })
    }
    changeLoanProgram(event) {
        var loanTerm = 30;
        if (event.target.value == "15 Year Fixed") {
            loanTerm = 15
        }

        this.setState({ loanProgram: event.target.value, loanTerm: loanTerm })
    }
    changeInterestRate(event) {
        this.setState({ loanProgram: event.target.value, })
    }
    formatMoney(oldMoney) {
        if (oldMoney) {
            var partialFormat = parseFloat(oldMoney).toFixed(2)
            var parts = partialFormat.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        else {
            return "0.00"
        }
    }



    getGraph(grabData) {

        var loanAmount = this.state.homePrice - this.state.downPaymentAmount
        var monthBreakDown = []
        var newMortgage = loanAmount;
        var totalPayments = this.state.loanTerm * 12;
        var interestRate = this.state.interestRate ? this.state.interestRate : 1
        var monthlyInterest = interestRate / 12;
        var monthlyInstallment = loanAmount * ((monthlyInterest / 100) / (1 - Math.pow(1 + monthlyInterest / 100, - totalPayments)))
        var monthlyPropertyTax = this.state.propertyTaxAmount / 12
        var monthlyPMI = (loanAmount * this.state.pmi) / 12 / 100;
        var monthlyInsurance = this.state.homeInsurance / 12
        var yearlyInterest = 0;
        var yearlyPrincipal = 0;
        var monthlyPayment = 0;
        var totalPayment = 0;
        var totalPMI = 0;
        var totalInterest = 0;
        var countPMI = 0;
        var currentInterest = 0;
        var currentPrincipal = 0;
        var currentLTV = 0;
        var currentPMI = 0;
        var currentMonthId = new Date().getMonth();
        // Get current month
        var currentMonth = "";
        var currentYear = new Date().getFullYear();
        for (var i = 0; i < totalPayments; i++) {
            /* Calculate data */
            currentInterest = newMortgage * monthlyInterest / 100;
            currentPrincipal = monthlyInstallment - currentInterest;
            currentLTV = (newMortgage / this.state.loanAmount) * 100;
            totalInterest += currentInterest;
            newMortgage -= currentPrincipal;
            yearlyInterest += currentInterest;
            yearlyPrincipal += currentPrincipal;

            /* Only apply PMI if LTV (Loan To Value) is less than 80 (%) */
            if (currentLTV < 80) {
                currentPMI = 0;
            } else {
                currentPMI = monthlyPMI;
                // sum PMI values
                totalPMI += currentPMI;
                // count PMI payments
                countPMI++;
            }

            monthlyPayment = currentInterest + currentPrincipal + currentPMI + monthlyPropertyTax + monthlyInsurance;

            totalPayment += monthlyPayment;
            var month = {
                month: i + 1,
                principal: currentPrincipal,
                interest: currentInterest,
                P_I: currentPrincipal + currentInterest,
                totalMonthlyPayment: monthlyPayment,
                paidSoFar: totalPayment,
                pmi: currentPMI,
                taxes: monthlyPropertyTax,
                insurance: monthlyInsurance,
                totalInterest: totalInterest,
                hoa: this.state.hoa / 12

            }
            monthBreakDown.push(month)
        }
        var fullMonthBreakdown = [];

        //Add Balance and other Totals while looping through each month
        var finalTotalPMI = 0
        var finalTotalPayment = 0
        var finalTotalInterest = 0
        var finalTotalTaxes = 0
        var finalTotalHOA = 0
        var finalTotalPrincipal = 0
        monthBreakDown.map((month) => {
            month['balance'] = totalPayment - month['paidSoFar']
            fullMonthBreakdown.push(month)
            finalTotalInterest += month['interest']
            finalTotalPrincipal += month['principal']
            finalTotalPMI += month['pmi']
            finalTotalPayment += month['totalMonthlyPayment']
            finalTotalTaxes += month['taxes']
            finalTotalHOA += month['hoa']
        })
        //Data to pass to new page
        var monthlyHOA = this.state.hoa / 12.0
        var monthlyInsurance = this.state.homeInsurance / 12.0
        var paymentInfo = {
            monthBreakdown: fullMonthBreakdown,
            estimateMonthlyPayment: monthBreakDown[0].totalMonthlyPayment,
            loanAmount: this.state.homePrice - this.state.downPaymentAmount,
            downPayment: this.state.downPaymentAmount,
            interestRate: interestRate,
            loanTerm: this.state.loanTerm,
            pmi: this.state.pmi,
            propertyTax: this.state.propertyTaxAmount,
            propertyTaxPercentage: this.state.propertyTaxPercentage,
            insurance: this.state.homeInsurance,
            monthlyInsurance: monthlyInsurance.toFixed(2),
            hoa: this.state.hoa,
            monthlyHOA: monthlyHOA.toFixed(2),
            totalInterest: finalTotalInterest.toFixed(2),
            totalPMI: finalTotalPMI,
            totalPayment: finalTotalPayment,
            totalTaxes: finalTotalTaxes,
            totalHOA: finalTotalHOA,
            totalPrincipal: finalTotalPrincipal.toFixed(2),


        }

        if (grabData) {
            return paymentInfo;
        }
        //Create Data For Graphs
        var allGraphData = [{ name: 'HOA', key: parseFloat(fullMonthBreakdown[0].hoa.toFixed(2)) }, { name: 'PMI', key: parseFloat(fullMonthBreakdown[0].pmi.toFixed(2)) }, { name: 'P&I', key: parseFloat((fullMonthBreakdown[0].P_I).toFixed(2)) }, { name: 'Taxes', key: parseFloat(fullMonthBreakdown[0].taxes.toFixed(2)) }, { name: 'Insurance', key: parseFloat(fullMonthBreakdown[0].insurance.toFixed(2)) }]
        var finalGraphData = []
        var graphTotal = 0;
        //Remove Values that have 0 as the key
        allGraphData.map((point) => {
            if (point.key != 0) {
                finalGraphData.push(point)
                graphTotal += point.key

            }



        })
        var COLORS = ['#23395B', ' #ACD1B6', ' #71A596', ' #40676E', ' #408E61'];

        //We only want to grab the data if the grabData Parameter is passed in and simply return the full month breakdown.  This will be for showing the breakdown.

        return {
            graph: (


                <div className="calcGraph">
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col >
                            <h1>Your Monthly Payment</h1>
                        </Col>
                    </Row>
                    <Row>
                        <ResponsiveContainer width='100%' minWidth='100%' minHeight='100%' aspect={2.0}>
                            <PieChart width={'100%'} height={'100%'} >
                                <Pie
                                    data={finalGraphData}
                                    dataKey="key" nameKey="name"
                                    cx="50%" cy="50%"
                                    innerRadius={'70%'}
                                    outerRadius={'75%'}
                                    fill="#8884d8"
                                    paddingAngle={2}
                                    animationEasing='ease'
                                    labelLine={false}
                                    label={(value) => {
                                        return (value.name + ':  $' + value.key)
                                    }}
                                >
                                    {
                                        finalGraphData.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                    }
                                    <Label position="center" style={{ fontSize: this.state.width > 768 ? 40 : 20 }}>
                                        {'$' + this.formatMoney(graphTotal.toFixed(2))}
                                    </Label>


                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Row>
                </div>
            )
        }
    }


    validateRequiredFields(value) {
        if (value) {
            if (isNaN(+value)) {
                return 'error';
            }
            else {
                return 'success'
            }
        }
        else {
            return 'error'
        }
    }

    validateOptionalFields(value) {
        if (value) {
            if (isNaN(+value)) {
                return 'warning';
            }
            else {
                return 'success'
            }
        }
        else {
            return 'warning'
        }
    }

    getForm() {
        return (<Form>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Col>
                    <h5>* = Required</h5>
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12}>

                    <ControlLabel>Home Price *</ControlLabel>
                    <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.homePrice)}  >
                        <InputGroup>
                            <InputGroup.Addon>
                                $
                                </InputGroup.Addon>
                            <FormControl
                                required
                                type="text"
                                placeholder="Home Price"
                                name='homePrice'
                                value={this.state.homePrice}
                                onChange={this.handleHomePriceChange}

                            />
                        </InputGroup>
                        <ControlLabel>{this.state.homePriceError}</ControlLabel>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>

                    <ControlLabel>Down Payment *</ControlLabel>
                    <Row>
                        <Col md={7} sm={7}>
                            <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.downPaymentAmount)}  >
                                <InputGroup>
                                    <InputGroup.Addon>
                                        $
                                </InputGroup.Addon>
                                    <FormControl
                                        required
                                        type="text"
                                        placeholder="Down Payment $"
                                        name='downPaymentAmount' value={this.state.downPaymentAmount} onChange={this.handleDownPaymentAmountChange}
                                    />
                                </InputGroup>
                                <ControlLabel>{this.state.downPaymentAmountError}</ControlLabel>
                            </FormGroup>
                        </Col>
                        <Col md={5} sm={5}>
                            <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.downPaymentPercentage)}  >
                                <InputGroup>
                                    <FormControl
                                        required
                                        type="text"
                                        placeholder="Down Payment %"
                                        name='downPaymentPercentage' value={this.state.downPaymentPercentage} onChange={this.handleDownPaymentPercentageChange}

                                    />
                                    <InputGroup.Addon>
                                        %
                                </InputGroup.Addon>
                                </InputGroup>
                                <ControlLabel>{this.state.downPaymentPercentageError}</ControlLabel>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>
                    <ControlLabel>Loan Program *</ControlLabel>
                    <br />
                    <select className='form-control ' onChange={this.changeLoanProgram} value={this.state.loanProgram}>
                        <option value="30 Year Fixed">30 Year Fixed</option>
                        <option value="15 Year Fixed">15 Year Fixed</option>
                        <option value="5/1 Arm">5/1 Arm</option>
                    </select>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>
                    <ControlLabel>Interest Rate *</ControlLabel>
                    <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.interestRate)}  >
                        <InputGroup>
                            <FormControl
                                required
                                type="text"
                                placeholder="Interest Rate"
                                name='interestRate' value={this.state.interestRate} onChange={this.handleGenericValueChange}

                            />

                            <InputGroup.Addon>
                                %
                                </InputGroup.Addon>
                        </InputGroup>
                        <ControlLabel>{this.state.interestRateError}</ControlLabel>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12} sm={12}>
                    <ControlLabel>PMI *</ControlLabel>
                    <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.pmi)}  >
                        <InputGroup>
                            <FormControl
                                required
                                type="text"
                                placeholder="PMI"
                                name='pmi' value={this.state.pmi} onChange={this.handleGenericValueChange}

                            />

                            <InputGroup.Addon>
                                %
                                </InputGroup.Addon>
                        </InputGroup>
                        <ControlLabel>{this.state.pmiError}</ControlLabel>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>

                    <ControlLabel>Annual Property Tax</ControlLabel>
                    <Row>
                        <Col md={7} sm={7}>
                            <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.propertyTaxAmount)}  >
                                <InputGroup>
                                    <InputGroup.Addon>
                                        $
                                </InputGroup.Addon>
                                    <FormControl
                                        required
                                        type="text"
                                        placeholder="Property Tax $"
                                        name='propertyTaxAmount' value={this.state.propertyTaxAmount} onChange={this.handlePropertyTaxAmountChange}
                                    />
                                </InputGroup>
                                <ControlLabel>{this.state.propertyTaxAmountError}</ControlLabel>
                            </FormGroup>
                        </Col>
                        <Col md={5} sm={5}>
                            <FormGroup controlId="formBasicText" validationState={this.validateRequiredFields(this.state.propertyTaxPercentage)}  >
                                <InputGroup>
                                    <FormControl
                                        required
                                        type="text"
                                        placeholder="Property Tax %"
                                        name='propertyTaxPercentage' value={this.state.propertyTaxPercentage} onChange={this.handlePropertyTaxPercentageChange}

                                    />
                                    <InputGroup.Addon>
                                        %
                                </InputGroup.Addon>
                                </InputGroup>
                                <ControlLabel>{this.state.propertyTaxPercentageError}</ControlLabel>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>

                    <ControlLabel>Annual Home Insurance</ControlLabel>
                    <FormGroup controlId="formBasicText" validationState={this.validateOptionalFields(this.state.homeInsurance)}  >
                        <InputGroup>
                            <InputGroup.Addon>
                                $
                                </InputGroup.Addon>
                            <FormControl
                                required
                                type="text"
                                placeholder="Home Insurance"
                                name='homeInsurance'
                                value={this.state.homeInsurance}
                                onChange={this.handleGenericValueChange}

                            />
                        </InputGroup>
                        <ControlLabel>{this.state.homeInsuranceError}</ControlLabel>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md={12} sm={12}>

                    <ControlLabel>Annual HOA</ControlLabel>
                    <FormGroup controlId="formBasicText" validationState={this.validateOptionalFields(this.state.hoa)}  >
                        <InputGroup>
                            <InputGroup.Addon>
                                $
                                </InputGroup.Addon>
                            <FormControl
                                required
                                type="text"
                                placeholder="HOA"
                                name='hoa'
                                value={this.state.hoa}
                                onChange={this.handleGenericValueChange}

                            />
                        </InputGroup>
                        <ControlLabel>{this.state.hoaError}</ControlLabel>
                    </FormGroup>
                </Col>
            </Row>
        </Form>)
    }

    getFullReport() {
        var data = this.getGraph(true)
        //Get Data and return what the Full Report View will look like
        this.setState({
            modalIsOpen: true,
            fullReportData: data
        })
    }
    closeModal() {
        this.setState({ modalIsOpen: false })
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    render() {
        var graph = this.getGraph(false)
        var form = this.getForm()

        return (
            <div>
                {/* Modal that will display the Breakdown of Payments */}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.state.closeModal}
                    style={modalStyles}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={true}
                >
                    <div>

                        <div className='try - registration - form'
                            style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                            <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.closeModal}>
                                <span aria-hidden="false">&times;</span>
                            </button>
                            <br />
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Col>
                                    <h1 style={{ float: 'left' }}>Payment Breakdown</h1>
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                <Col>
                                    <h3 style={{ float: 'left' }}>Estimated Payment:</h3>
                                </Col>
                                <Col xsOffset={1}>
                                    {this.state.fullReportData.estimateMonthlyPayment && <h2 style={{ float: 'left' }}>{'$' + numberWithCommas(this.state.fullReportData.estimateMonthlyPayment.toFixed(2)) + '/mo'}</h2>}

                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col mdOffset={1} md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Loan Amount:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{'$' + numberWithCommas(this.state.fullReportData.loanAmount)}</h5>
                                </Col>

                                <Col md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Down Payment:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{'$' + numberWithCommas(this.state.downPaymentAmount)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col mdOffset={1} md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Total principal:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{'$' + numberWithCommas(this.state.fullReportData.totalPrincipal)}</h5>
                                </Col>

                                <Col md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Total Interest:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{'$' + numberWithCommas(this.state.fullReportData.totalInterest)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col mdOffset={1} md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Interest Rate:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{this.state.interestRate + '%'}</h5>
                                </Col>

                                <Col md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Loan Term:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{this.state.loanTerm + ' years'}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col mdOffset={1} md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Property Tax:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{this.state.propertyTaxPercentage + '%/yr'}</h5>
                                </Col>

                                <Col md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Homeowner's Insurance:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{this.state.fullReportData.monthlyInsurance + '$/yr'}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col mdOffset={1} md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>Mortgage Insurance:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{this.state.pmi + '%/yr until LTV > 80'}</h5>
                                </Col>

                                <Col md={3} sm={12}>
                                    <h3 style={{ float: 'left' }}>HOA Dues:</h3>
                                </Col>
                                <Col md={2} sm={12}>
                                    <h5 style={{ float: 'left' }}>{'$' + this.state.fullReportData.monthlyHOA + '/mo'}</h5>
                                </Col>
                            </Row>
                            <hr />
                            <CalculatorTable closeModal={this.closeModal} data={this.state.fullReportData} monthMap={this.state.monthMap}></CalculatorTable>
                            <br />
                            <div id='error'></div>
                        </div>
                    </div>
                </Modal>
                <Grid>

                    <br />
                    <br />
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col xsOffset={1}>
                            <h5>Use our home loan calculator to estimate your mortgage payment, with taxes and insurance. Simply enter the price of the home, your down payment, and details about the home loan to calculate your mortgage payment breakdown, schedule, and more.</h5>
                        </Col>
                    </Row>
                    {this.state.width > 991 && <Row>
                        <Col md={4} >
                            {form}
                        </Col>
                        <Col md={8} >
                            <Row>
                                <Col mdOffset={5} md={6} >
                                    <Button onClick={this.getFullReport}>See Payment Breakdown</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                {graph.graph}
                            </Row>
                        </Col>
                    </Row>}
                    {this.state.width <= 991 && <Row>
                        <Col sm={12} >
                            <Row>
                                <Col smOffset={3} sm={9} xsOffset={3} xs={9}>
                                    <Button onClick={this.getFullReport}>See Payment Breakdown</Button>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                {graph.graph}
                            </Row>
                        </Col>
                        <Col sm={12}>
                            {form}
                        </Col>

                    </Row>}





                </Grid>

            </div >

        )
    }
}

function mapStateToProps(state) {
    return {

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(FundingsView);
