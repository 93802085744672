import { put, takeEvery, call, race } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

//ERROR CODES NEED TO BE GENERATED FOR THIS REDUCER/SAGA

const watchActivities = function* watchGetActivities() {
    yield takeEvery("GET_ACTIVITIES", getActivities);
    yield takeEvery("ADD_ACTIVITY_SUBMISSION", addSubmission);
    yield takeEvery("DELETE_ACTIVITY", deleteActivity);
    yield takeEvery("UPSERT_ACTIVITY", upsertActivity);
    yield takeEvery("UPLOAD_ACTIVITY_IMAGE", uploadActivityImage);

};

const getActivities = function* getActivities(action) {
    var activitiesData = {};

    yield put({ type: "GET_ACTIVITIES_STARTED" });
    //Gets Pipeline
    try {
        const { mongoData } = yield race({
            mongoData: call(executeMongoFindDBQuery, action.payload.collection, { query: {} }),
            timeout: call(delay, 30000)
        })
        yield put({ type: "GET_ACTIVITIES_SUCCEEDED", activitiesData: mongoData });


    } catch (error) {
        yield put({ type: "GET_ACTIVITIES_FAILED", errorMessage: error });
        return;
    }
};

const addSubmission = function* addSubmission(action) {

    yield put({ type: "ADD_SUBMISSION_STARTED" });

    try {

        //Update Activity Collection
        var mongoActivityConfig = {
            query: { "_id": action.payload.activityData._id },
            fieldsToSet: {
                "$set": action.payload.activityData.data
            }
        }
        const { mongoActivityData } = yield race({
            mongoActivityData: call(executeMongoAddDBQuery, action.payload.activityData.collection, mongoActivityConfig),
            timeout: call(delay, 30000)
        })
        //Update Employee Collection
        var mongoEmployeeConfig = {
            query: { "_id": action.payload.employeeData._id },
            fieldsToSet: {
                "$set": action.payload.employeeData.data
            }
        }

        const { mongoEmployeeData } = yield race({
            mongoEmployeeData: call(executeMongoAddDBQuery, action.payload.employeeData.collection, mongoEmployeeConfig),
            timeout: call(delay, 30000)
        })

        //UpdateCurrent User
        var mongoCurrentUserConfig = {
            query: { "_id": action.payload.employeeData._id },
        }
        const { mongoCurrentData } = yield race({
            mongoCurrentData: call(executeMongoFindDBQuery, action.payload.employeeData.collection, mongoCurrentUserConfig),
            timeout: call(delay, 30000)
        })

        yield put({ type: "ADD_SUBMISSION_SUCCEEDED" });
        yield put({ type: "UPDATE_CURRENT_USER", currentUser: mongoCurrentData[0] });


    } catch (error) {
        yield put({ type: "ADD_SUBMISSION_FAILED", errorMessage: error });
        return;
    }
};

const deleteActivity = function* deleteActivity(action) {
    yield put({ type: "DELETE_ACTIVITY_STARTED" });
    try {

        var mongoConfig = {
            query: { "_id": action.payload._id }
        };

        console.log(mongoConfig)
        const { mongoData } = yield race({
            mongoData: call(executeMongoDeleteDBQuery, action.payload.collection, mongoConfig),
            timeout: call(delay, 30000)
        })

        if (!mongoData) {
            yield put({ type: 'DELETE_ACTIVITY_ERRORED', deleteActivityErrorMessage: 'AO.HR.005: Warehouse DB timed out waiting for response for ' + action.payload.collection + '. Contact System Adminstrator.' })
            return;
        }
        //Do stuff!
        yield put({ type: "DELETE_ACTIVITY_SUCCEEDED" });
        //Repopulate Data with changes
        yield call(getActivities, action);

    } catch (error) {
        yield put({ type: "DELETE_ACTIVITY_ERRORED", deleteActivityErrorMessage: 'AO.HR.006: Unable to retrieve information from ' + action.payload.collection + '.  However, you may procceed with Athena Online' });
        return;
    }
}

const upsertActivity = function* upsertActivity(action) {

    yield put({ type: "UPSERT_ACTIVITY_STARTED" });
    try {
        var mongoConfig = {
            query: { "_id": action.payload._id },
            fieldsToSet: {
                "$set": action.payload.data
            }
        };


        const { mongoData } = yield race({
            mongoData: call(executeMongoAddDBQuery, action.payload.collection, mongoConfig),
            timeout: call(delay, 30000)
        })

        if (!mongoData) {
            yield put({ type: 'UPSERT_ACTIVITY_ERRORED', upsertActivityMessage: 'AO.HR.003: Warehouse DB timed out waiting for response for ' + action.payload.collection + '. Contact System Adminstrator.' })
            return;
        }
        //Do stuff!
        yield put({ type: "UPSERT_ACTIVITY_SUCCEEDED" });
        //Repopulate Data with changes
        yield call(getActivities, action);

    } catch (error) {
        yield put({ type: "UPSERT_ACTIVITY_ERRORED", upsertActivityMessage: 'AO.HR.004: Unable to retrieve information from ' + action.payload.collection + '.  However, you may procceed with Athena Online' });
        return;

    }
}

const uploadActivityImage = function* uploadActivityImage(action) {
    yield put({ type: "UPLOAD_ACTIVITY_IMAGE_STARTED" });
    try {
        var uploadActivityImageResponse = yield call(uploadActivityImageData, action.payload);
        console.log('RESPONSE',uploadActivityImageResponse)
        if (uploadActivityImageResponse.status == 200) {
            yield put({ type: "UPLOAD_ACTIVITY_IMAGE_SUCCEEDED", url: uploadActivityImageResponse.url });
        }
        else
        {
            yield put({ type: "UPLOAD_ACTIVITY_IMAGE_ERRORED", uploadActivityImageMessage: 'File Not Uploaded' });
        }
       
    

    } catch (error) {
    yield put({ type: "UPLOAD_ACTIVITY_IMAGE_ERRORED", uploadActivityImageMessage: 'File Not Uploaded' });
    return;

}
}

const uploadActivityImageData = (fileData) => {
    return fetch(settings.URL + settings.QUIZPORT + settings.ENDPOINTS.upload_activity_image, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(fileData)
    }).then(response => response.json()).then(response => {
        return response;
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};

function executeMongoFindDBQuery(collection, configs) {

    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_find, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {
        return response.data;
    })

}

function executeMongoAddDBQuery(collection, configs) {
    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_upsert_one, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {
        return response.data;
    })

}

function executeMongoDeleteDBQuery(collection, configs) {

    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_delete_one, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {

        return response.data;
    })

}

function delay(ms) {
    return new Promise(resolve => setTimeout(() => resolve(true), ms))
}
export default watchActivities;