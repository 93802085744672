import { all} from "redux-saga/effects";
import watchLoginUsers from './loginSaga';
import watchPipeline from './pipelineSaga';
import watchIssues from './issueSaga';
import watchEmployees from './employeesSaga';
import watchCommunications from './communicationSaga';
import watchHr from "./hrSaga";
import watchActivities from "./activitySaga";





///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//Root Saga
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
const rootSaga = function* rootSaga() {
  yield all([
    watchLoginUsers(),
    watchPipeline(),
    watchIssues(),
    watchEmployees(),
    watchCommunications(),
    watchHr(),
    watchActivities()
  ]);
};

export default rootSaga;