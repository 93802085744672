// Pipeline Actions

export const getPipeline = (pipelineParam) => {
  return {
    type: 'GET_PIPELINE',
    payload: pipelineParam
  }
}

export const getPipelineReport = (pipelineParam) => {
  return {
    type: 'GET_PIPELINE_REPORT',
    payload: pipelineParam
  }
}

export const getBankReport = (bankParam) => {
  return {
    type: 'GET_BANK_REPORT',
    payload: bankParam
  }
}

export const getClosingReport = (pipelineParam) => {
  return {
    type: 'GET_CLOSING_REPORT',
    payload: pipelineParam
  }
}

export const getDashboardReport = (dashboardParams) => {
  return {
    type: 'GET_DASHBOARD_REPORT',
    payload: dashboardParams
  }
}

export const getRegionFundingsReport = (regionFundingsParam) => {
  return {
    type: 'GET_REGION_FUNDINGS_REPORT',
    payload: regionFundingsParam
  }
}

export const getPipelineLeaderboard = (pipelineParam) => {
  return {
    type: 'GET_PIPELINE_LEADERBOARD',
    payload: pipelineParam
  }
}

export const getPipelineNumbersReport = (pipelineParam) => {
  return {
    type: 'GET_PIPELINE_NUMBERS_REPORT',
    payload: pipelineParam
  }
}

export const getTrends = (pipelineParam) => {
  return {
    type: 'GET_TRENDS',
    payload: pipelineParam
  }
}

export const getLoanCompareList = (pipelineParam) => {
  return {
    type: 'GET_LOAN_COMPARE_LIST',
    payload: pipelineParam
  }
}

export const getSingleLoanFromWarehouse = (pipelineParam) => {
  return {
    type: 'GET_SINGLE_LOAN_FROM_WAREHOUSE',
    payload: pipelineParam
  }
}

export const getMultipleLoansFromWarehouse = (payload) => {
  return {
    type: 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE',
    payload: payload
  }
}

export const getSingleLoanFromEncompass = (pipelineParam) => {
  return {
    type: 'GET_SINGLE_LOAN_FROM_ENCOMPASS',
    payload: pipelineParam
  }
}

export const setSingleLoanGuid = (pipelineParam) => {
  return {
    type: 'SET_SINGLE_LOAN_GUID',
    payload: pipelineParam
  }
}

export const getFundings = (pipelineParam) => {
  return {
    type: 'GET_FUNDINGS',
    payload: pipelineParam
  }
}

export const getLoanAudit = (pipelineParam) => {
  return {
    type: 'GET_LOAN_AUDIT',
    payload: pipelineParam
  }
}

export const resetLoanAudit = () => {
  return {
    type: 'GET_LOAN_AUDIT_RESET'
  }
}

export const updateProgress = (progress) => {
  return {
    type: 'UPDATE_PROGRESS',
    payload: progress
  }
}

export const resetPipeline = () => {
  return {
    type: 'RESET_PIPELINE_STATUS'
  }
}
