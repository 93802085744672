import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData, getAllHRData } from '../../actions/hrActions';
import Autosuggest from 'react-autosuggest';



function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

class LocationDropdown extends Component {
    constructor(props) {
        super();
        this.state = {
            value: '',
            suggestions: [],
        };

        this.getSuggestions = this.getSuggestions.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
    }
    componentDidMount() {
          this.props.getAllHRData({collection:'locations'});
    }

    getSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        return this.props.hrInfo.allLocationsData.filter(location => {
            var address = location.address1 + ' ' + location.address2 + ', ' + location.city + ', ' + location.state + ' ' + location.zip
            if (address.toLowerCase().indexOf(value.toLowerCase())!= -1) { return true }
        })
    }


    getSuggestionValue(suggestion) {
        this.props.setLocationValue(suggestion._id, suggestion.address1 + ' ' + suggestion.address2 + ', ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zip)
        return suggestion.address1 + ' ' + suggestion.address2 + ', ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zip;
    }
    renderSuggestion(suggestion) {
        return (
            <div id={suggestion.address1 + ' ' + suggestion.address2 + ', ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zip}> <span>{suggestion.address1 + ' ' + suggestion.address2 + ', ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zip}</span></div>
        );


    }
    onChange = (event, { newValue, method }) => {
        //Gets all suggestion when the field value changes
        //When there is only one then that means we have found the one we want.
        var suggestion = this.getSuggestions(newValue);
        if (suggestion.length !== 1) {
            this.props.setLocationValue('', '')
        }
        else {
            this.props.setLocationValue(suggestion[0]._id, suggestion[0].address1 + ' ' + suggestion[0].address2 + ', ' + suggestion[0].city + ', ' + suggestion[0].state + ' ' + suggestion[0].zip)
        }
        this.setState({
            value: newValue
        });
    };


    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });


    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionHighlighted = (value) => {
        if (value.suggestion) {
            var nonHighlightedArray = this.state.suggestions;
            var index = nonHighlightedArray.indexOf(value.suggestion.address1 + ' ' + value.suggestion.address2 + ', ' + value.suggestion.city + ', ' + value.suggestion.state + ' ' + value.suggestion.zip);
            if (index > -1) {
                nonHighlightedArray.splice(index, 1);
            }
            nonHighlightedArray.map((suggestion) => {
                document.getElementById(suggestion.address1 + ' ' + suggestion.address2 + ', ' + suggestion.city + ', ' + suggestion.state + ' ' + suggestion.zip).style.backgroundColor = 'rgba(0, 0, 0, 0)';
            })
            document.getElementById(value.suggestion.address1 + ' ' + value.suggestion.address2 + ', ' + value.suggestion.city + ', ' + value.suggestion.state + ' ' + value.suggestion.zip).style.backgroundColor = '#dddddd';
        }

    };

    render() {
        if (this.props.value && !this.state.gotValue) {
            this.setState({ value: this.props.value, gotValue: true })
        }


        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search Location",
            value,
            onChange: this.onChange,
            style: { width: '100%', height: '50px', borderRadius: '5px', paddingLeft: '5px' }
        };
        return (<Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            inputProps={inputProps} />);
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            getAllHRData:getAllHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LocationDropdown);
