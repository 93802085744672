import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';


class MiscForm extends Component {
    constructor(props) {
        super();
        this.state = {
            borrowerInfo: {},
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.getBorrowerInfo = this.getBorrowerInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    };

    getBorrowerInfo(info) {
        console.log('YEP', info)
        this.setState({ borrowerInfo: info })
    }

    handleSubmit() {
        this.setState({ formStatus: 'SAVING' })
        setTimeout(
            function () {
                this.setState({ formStatus: 'SUCCESS' })
            }
                .bind(this),
            3000
        );

    }
    render() {

        return (
            <div>
               <h1>Misc</h1>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MiscForm);
