import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { introspectToken } from '../../../actions/loginActions';
import { getFundings, resetPipeline } from '../../../actions/pipelineActions';
const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

class FundingsView extends Component {
    constructor(props) {
        super();
        this.state = {
            gotFundingsStatus: true,
            isLoading: false,


        }
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }


    componentDidMount() {
        //Check Token Validity
        var fundingsParam = {
            query: "SELECT * FROM funding_uploads ORDER BY date DESC"

        }
        this.props.getFundings(fundingsParam);
    }

    showHistory() {
        if (this.props.pipelineInfo.fundingsData.response) {
            // remove top item
            var items = this.props.pipelineInfo.fundingsData.response;
            items.shift();

            var itemList = items.map((line,index)=> {
                var date = new Date(line.date)
                var month = date.getMonth() + 1;
                var day = date.getDate();
                var year = date.getFullYear();
                var fullDate = month + '/' + day + '/' + year;
                return <li key={index}>{fullDate}: {line.count} Funded Loans Uploaded</li>;
            })

            return <ul>{itemList}</ul>
        }
        else {
            return <ul><li>No Loans</li></ul>
        }

    }

    convertDate(variable) {
        var date = new Date(variable)
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        return month + '/' + day + '/' + year;
    }

    render() {

        if (this.props.pipelineInfo.getFundingsStatus === 'SUCCEEDED' && !this.state.gotFundingsStatus) {
            this.setState({ gotFundingsStatus: true, isLoading: false })

        }

        //Reset gotPipeStatus to false
        if (this.props.pipelineInfo.getFundingsStatus === 'STARTED' && this.state.gotFundingsStatus) {
            this.setState({ gotFundingsStatus: false, isLoading: true })

        }

        return (
            <div>
                <br />
                <br />
                <div>
                    {!this.state.isLoading && <Row>
                        <Col md={8} mdOffset={2}>
                            {/* {console.log('DATA HERE', this.props.pipelineInfo.fundingsData)}
                        <h1>Most Recent Funding</h1> */}
                            <h2><b>Most Recent Fundings Report</b></h2>

                            {this.props.pipelineInfo.fundingsData.response && <p style={{ fontSize: '24px' }}>
                                {this.convertDate(this.props.pipelineInfo.fundingsData.response[0].date)}: {this.props.pipelineInfo.fundingsData.response[0].count} Funded Loans Uploaded
                        </p>}
                            <br />
                            <h4>History:</h4>
                            {this.showHistory()}
                        </Col>
                    </Row>
                    }

                    {this.state.isLoading &&
                        <div style={{ textAlign: 'center' }}>
                            <br /><br />
                            <br /><br />
                            <h1>Loading History...</h1>
                            <br /><br />
                            <br />

                        </div>}
                </div>


            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPipeline: resetPipeline,
            introspectToken: introspectToken,
            getFundings: getFundings,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(FundingsView);
