import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { customFilter, customSort } from '../../../helpers/tableHelpers'
import 'react-table/react-table.css'
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import { Tooltip, OverlayTrigger, Grid, Col, Row, Button, ButtonGroup } from 'react-bootstrap'

import { setSelectedActivity, deleteActivity } from '../../../actions/activityActions';
import { Form, Icon, Message } from 'semantic-ui-react';
import windowSize from 'react-window-size';
import Modal from 'react-modal';


var editTooltip = (
  <Tooltip id="tooltip">
    <strong>Edit:</strong> Make changes to selected activity.
  </Tooltip>
);



class PendingActivitiesTable extends Component {

  constructor(props) {
    super();
    this.state = {
    }
    this.goToActivityPage = this.goToActivityPage.bind(this);
    this.goToActivityRequestPage = this.goToActivityRequestPage.bind(this);
  }

  goToActivityPage(selectedActivity) {
    //Set the current Activity 
    this.props.setSelectedActivity({ selectedActivity: selectedActivity });
    this.props.goToAnotherPage({
      pathname: '/reviewActivity'
    })

  }
  goToActivityRequestPage() {
    var newActivity = {
      activityName: '',
      departments: [],
      pointValue: 0,
      creationDate: new Date().toISOString(),
      createdBy: this.props.loginInfo.currentUser._id,
      createdByName: this.props.loginInfo.currentUser.firstName + ' ' + this.props.loginInfo.currentUser.lastName,
      approvedBy: '',
      expirationDate: new Date().toISOString(),
      scoringMethod: 'AllOrNothing', // All or Nothing or Percentage Based
      activityStatus: '',
      activityType: 'View Only',
      submissions: [],
      awardForActivity: [],
      activityDescription: '<br/><br/><br/><br/><br/><br/><br/><br/>',
      questions: [{
        "questionType": "ShortAns",
        "questionText": "What is your favorite game to play and why?",
        "questionUrl": "",
        "files": {},
        "options": [],
        "correctOption": "",
        "scoringWeight": "" // for weighted questions
      },]
    }
    this.props.setSelectedActivity({ selectedActivity: newActivity });
    this.props.goToAnotherPage({
      pathname: '/newActivity'
    })

  }
  getEmployeeById(id) {
    var objToReturn = {
      firstName: '',
      lastName: ''
    };
    if (id) {
      for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
        if (this.props.hrInfo.allEmployeesData[i]._id == id) {
          objToReturn = this.props.hrInfo.allEmployeesData[i];
        }
      }
      return objToReturn;
    }
    return objToReturn;

  }



  formatActivityData() {
    var pendingData = []
    if (Object.keys(this.props.activityInfo.activitiesData).length === 0) {
      return pendingData
    }
    else {
      for (var i = 0; i < this.props.activityInfo.activitiesData.length; i++) {
        if (this.props.activityInfo.activitiesData[i].activityStatus == 'Created') {
          var approvedByValue = this.props.activityInfo.activitiesData[i].approvedBy;
          var createdByValue = this.props.activityInfo.activitiesData[i].createdBy;
          //Only Return activities that are pending my approval
          this.props.activityInfo.activitiesData[i]['ApprovedByName'] = this.getEmployeeById(approvedByValue).firstName + ' ' + this.getEmployeeById(approvedByValue).lastName
          this.props.activityInfo.activitiesData[i]['CreatedByName'] = this.getEmployeeById(createdByValue).firstName + ' ' + this.getEmployeeById(createdByValue).lastName
          pendingData.push(this.props.activityInfo.activitiesData[i])
        }

      }
    }
    return pendingData
  }



  render() {

    var formattedActivityData = this.formatActivityData()
    return (
      <div>
        <hr />
        {formattedActivityData.length < 1 &&
          <Message icon  >
            <Icon name='circle notched' loading />
          </Message>}
        {this.props.activityInfo.activitiesData.length > 0 &&
          <ReactTable
            data={formattedActivityData}
            getTrProps={
              (state, rowInfo) => {
                var that = this;

                if (rowInfo !== undefined) {

                  return {
                    onClick: (e) => {
                      this.goToActivityPage(rowInfo.original)
                    },
                    style: {
                      background: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? '#00afec' : 'white' : 'white',
                      color: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? 'white' : 'black' : 'black',
                    }
                  }
                }
                else {
                  return {
                    onClick: (e) => {

                    }

                  }
                }
              }}
            columns={[
              {

                columns: [
                  {
                    Header: "Activity Name",
                    accessor: "activityName",
                    minWidth: 30,
                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                  },
                  {
                    Header: "Created By",
                    accessor: "CreatedByName",
                    minWidth: 30,
                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                  },
                  {
                    Header: "Status",
                    accessor: "activityStatus",
                    minWidth: 30,
                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),

                  },
                  {
                    Header: "Expiration Date",
                    accessor: "expirationDate",
                    minWidth: 30,
                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value ? value.split('T')[0] : ''}</p>),
                  },

                ]
              }
            ]}
            style={{
              height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            defaultPageSize={this.props.activityInfo.activitiesData.length}
            filterable
            defaultFilterMethod={customFilter}
            defaultSortMethod={customSort}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: "expirationDate",
                desc: false
              }
            ]}
          />}


      </div>
    )
  }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
  return {
    activityInfo: state.activityReducer,
    hrInfo: state.hrReducer,
    loginInfo: state.loginReducer

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedActivity: setSelectedActivity,
      deleteActivity: deleteActivity,
      goToAnotherPage: (params) => push(params)
    }
    , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(PendingActivitiesTable));