import React, { Component } from 'react';
import { Button, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import BorrowerInformationFormComponent from './loanEditFormComponents/BorrowerInformationFormComponent';
import PropertyInformationFormComponent from './loanEditFormComponents/PropertyInformationFormComponent';

class LoanEditView extends Component {
    constructor(props) {
        super();
        this.state = {
            borrowerInfo: {},
            propertyInfo: {},
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.getBorrowerInfo = this.getBorrowerInfo.bind(this);
        this.getPropertyInfo = this.getPropertyInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    };

    getBorrowerInfo(info) {

        this.setState({ borrowerInfo: info })
    }

    getPropertyInfo(info) {

        this.setState({ proeprtyInfo: info })
    }
    handleSubmit() {
        this.setState({ formStatus: 'SAVING' })
        setTimeout(
            function () {
                this.setState({ formStatus: 'SUCCESS' })
            }
                .bind(this),
            3000
        );

    }
    render() {

        return (
            <div>
                
                {(this.props.pipelineInfo.getSingleLoanFromEncompassStatus === 'SUCCEEDED') &&
                    <Grid>
                        <BorrowerInformationFormComponent getBorrowerInfo={this.getBorrowerInfo} />
                        <PropertyInformationFormComponent getPropertyInfo={this.getPropertyInfo} />
                        <Button bsStyle="primary" onClick={this.handleSubmit}>Save Changes</Button>

                    </Grid>}

                    {this.state.formStatus === 'SAVING' &&
                        <form>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                                <Message.Header>Just one second</Message.Header>
                            </Message>
                        </form>}
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoanEditView);
