import React, { Component } from 'react';
import { Row, Nav, NavItem, Grid, Button, FormGroup, Col, ControlLabel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../actions/loginActions';
import { getAllHRData, upsertHRData, setSelectedApplicant, deleteHRData } from '../../actions/hrActions';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import ApplicationForm from './ApplicationForm';
import BackgroundForm from './BackgroundForm';
import EmploymentInfoForm from './EmploymentInfoForm';
import WelcomeForm from './WelcomeForm';
import OnboardingDocumentsForm from './OnboardingDocumentsForm';
import OfferLetterForm from './OfferLetterForm';
import CredentialsForm from './CredentialsForm';
import NotesForm from './NotesForm';
import ITForm from './ITForm';
import LicensingForm from './LicensingForm';
import HiringStatusDropdown from '../dropdowns/HiringStatusDropdown';
class HiringView extends Component {
    constructor(props) {
        super();
        this.state = {
            hrPage: 'ApplicationInfo',
            gotCompStatus: false,
            formStatus: 'EDITING',
            width: 0,
            height: 0,
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.convertToEmployee = this.convertToEmployee.bind(this);
    }

    handleSelect(hrPage) {
        this.setState({ hrPage: hrPage })
    }
    componentDidMount() {
        this.props.getAllHRData({ collection: 'locations' });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({
            hiringStatus: this.props.hrInfo.selectedApplicant.data.hiringInfo.hiringStatus

        })

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleHiringInfoDropDownChange(name, value) {
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    getEmployeeById(id) {
        var objToReturn = {
            firstName: '',
            lastName: ''
        };
        if (id) {
            for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
                if (this.props.hrInfo.allEmployeesData[i]._id == id) {
                    objToReturn = this.props.hrInfo.allEmployeesData[i];
                }
            }
            return objToReturn;
        }
        return objToReturn;

    }

    convertToEmployee() {
        var employeeData = {
            data: {
                itInfo: {
                    ADUsername: this.props.hrInfo.selectedApplicant.data.itInfo.ADUsername,
                    ADUserGroups: this.props.hrInfo.selectedApplicant.data.itInfo.ADUserGroups,
                    linkedApps: this.props.hrInfo.selectedApplicant.data.itInfo.linkedApps,
                    aliasEmails: this.props.hrInfo.selectedApplicant.data.itInfo.aliasEmails,
                    encompassEmail: this.props.hrInfo.selectedApplicant.data.itInfo.encompassEmail,
                    encompassUsername: this.props.hrInfo.selectedApplicant.data.itInfo.encompassUsername,
                    emailDistroGroups: this.props.hrInfo.selectedApplicant.data.itInfo.emailDistroGroups,
                    personas: this.props.hrInfo.selectedApplicant.data.itInfo.personas,
                    intranetPage: '',
                    intranetPageLocation: '',
                    intranetPermissions: []
                },
                licensingInfo: {
                    nmls: this.props.hrInfo.selectedApplicant.data.employmentInfo.nmls,
                    licensedStates: this.props.hrInfo.selectedApplicant.data.licensingInfo.licensedStates,
                    seekingLicensedStates: this.props.hrInfo.selectedApplicant.data.licensingInfo.seekingLicensedStates
                },
                employmentInfo: {
                    directReport: this.getEmployeeById(this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport).firstName + ' ' + this.getEmployeeById(this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport).lastName,
                    directReportId: this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport,
                    bio: '',
                    website: this.props.hrInfo.selectedApplicant.data.employmentInfo.website,
                    addToLocator: this.props.hrInfo.selectedApplicant.data.employmentInfo.addToLocator,
                    activeEmployee: true,
                    employeeStatus: this.props.hrInfo.selectedApplicant.data.employmentInfo.employmentStatus,
                    departmentName: this.props.hrInfo.selectedApplicant.data.employmentInfo.department,
                    title: this.props.hrInfo.selectedApplicant.data.employmentInfo.title,
                    region: this.props.hrInfo.selectedApplicant.data.employmentInfo.region,
                    workPhone: this.props.hrInfo.selectedApplicant.data.employmentInfo.workPhone,
                    cellPhone: this.props.hrInfo.selectedApplicant.data.personalInfo.cellPhone,
                    location: this.props.hrInfo.selectedApplicant.data.employmentInfo.location,
                    hrAgreementInformation: {},
                    picture: 'https://image.shutterstock.com/image-vector/uh-oh-450w-92290096.jpg'
                },
                gamingInfo:
                {
                    totalPoints: 0,
                    games: []
                },

                firstName: this.props.hrInfo.selectedApplicant.data.personalInfo.firstName,
                lastName: this.props.hrInfo.selectedApplicant.data.personalInfo.lastName,
                username: '',
            },
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'employees'
        };
        //ADD TO EMPLOYEES COLLECTION
        this.props.upsertHRData(employeeData)

        var deleteData =
        {
            applicantID: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants',
            
        }
        //REMOVE FROM APPLICANTS COLLECTION
        this.props.deleteHRData(deleteData);
        this.props.goToAnotherPage({
            pathname: '/hrHiringPortal'
        })

    }

  

    render() {
        var allDone = this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent &&
            this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent && this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent &&
            this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted &&
            this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.hiringStatus === 'Completed';

        return (
            <div>
                {(this.props.hrInfo.selectedApplicantStatus === 'SUCCEEDED') &&
                    <Grid>
                        <Row>
                            <Col md={9} sm={12}>
                                <h1> {this.props.hrInfo.selectedApplicant.data.personalInfo.firstName + ' ' + this.props.hrInfo.selectedApplicant.data.personalInfo.lastName}</h1>
                            </Col>
                            <Col md={3} sm={12}>
                                <h1>Hiring Status</h1>
                            </Col>
                        </Row>
                        <br />
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>

                            {!allDone && <Col md={9} sm={12}>
                                <Button bsStyle='link' onClick={() => this.props.goToAnotherPage({
                                    pathname: '/hrHiringPortal'
                                })}>
                                    <span style={{ color: '#5cb85c', fontSize: 25 }} ><Octicons.GoArrowLeft />Back To Hiring List</span>
                                </Button>
                            </Col>}
                            {allDone && <Col md={5} sm={12}>
                                <Button bsStyle='link' onClick={() => this.props.goToAnotherPage({
                                    pathname: '/hrHiringPortal'
                                })}>
                                    <span style={{ color: '#5cb85c', fontSize: 25 }} ><Octicons.GoArrowLeft />Back To Hiring List</span>
                                </Button>
                            </Col>}


                            {allDone && <Col md={4} sm={12}>
                                <Button bsStyle='success' onClick={this.convertToEmployee}>
                                    <span style={{ fontSize: 25 }} >Convert To Employee <FontAwesome.FaExchange /></span>
                                </Button>
                            </Col>}
                            <Col md={3} sm={12}>
                                <FormGroup controlId="formBasicText" >
                                    <HiringStatusDropdown setStatusValue={(value) => {
                                        if (value) {
                                            this.handleHiringInfoDropDownChange('hiringStatus', value)

                                        }
                                        else {
                                            this.handleHiringInfoDropDownChange('hiringStatus', '')
                                        }
                                    }} value={this.state.hiringStatus} />
                                    <FormControl.Feedback />
                                </FormGroup>
                            </Col>
                        </Row>
                        <br />
                        {this.state.width < 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted && <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center' }}><FontAwesome.FaListUl style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted && <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center' }}><FontAwesome.FaListUl style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted && <NavItem eventKey="JobInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGlobe style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted && <NavItem eventKey="JobInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGlobe style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent && <NavItem eventKey="Welcome" style={{ textAlign: 'left' }}><FontAwesome.FaEnvelope style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent && <NavItem eventKey="Welcome" style={{ textAlign: 'left' }}><FontAwesome.FaEnvelope style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent && <NavItem eventKey="Offer" style={{ textAlign: 'left' }}><Octicons.GoFileText style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent && <NavItem eventKey="Offer" style={{ textAlign: 'left' }}><Octicons.GoFileText style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <NavItem eventKey="OnboardingDocuments" style={{ textAlign: 'left' }}><Octicons.GoBriefcase style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <NavItem eventKey="OnboardingDocuments" style={{ textAlign: 'left' }}><Octicons.GoBriefcase style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent && <NavItem eventKey="Credentials" style={{ textAlign: 'left' }}><Octicons.GoLock style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent && <NavItem eventKey="Credentials" style={{ textAlign: 'left' }}><Octicons.GoLock style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {(this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted) && <NavItem eventKey="Background" style={{ textAlign: 'left' }}><Octicons.GoGistSecret style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {(!this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted || !this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted || !this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted) && <NavItem eventKey="Background" style={{ textAlign: 'left' }}><Octicons.GoGistSecret style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted && <NavItem eventKey="IT" style={{ textAlign: 'left' }}><Octicons.GoDeviceDesktop style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted && <NavItem eventKey="IT" style={{ textAlign: 'left' }}><Octicons.GoDeviceDesktop style={{ fontSize: 30, color: '#d9534f' }} /></NavItem>}
                                <NavItem eventKey="Licensing" style={{ textAlign: 'left' }}><Octicons.GoCreditCard style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>

                                <NavItem eventKey="Notes" style={{ textAlign: 'left' }}><Octicons.GoBook style={{ fontSize: 30 }} /></NavItem>

                            </Nav>
                            <hr />
                        </Row>}
                        {this.state.width > 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted && <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaListUl style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Application</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.applicantInfoCompleted && <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaListUl style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Application</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted && <NavItem eventKey="JobInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaGlobe style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Employment Info</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted && <NavItem eventKey="JobInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaGlobe style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Employment Info</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent && <NavItem eventKey="Welcome" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaEnvelope style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Welcome Email</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.welcomeEmailSent && <NavItem eventKey="Welcome" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaEnvelope style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Welcome Email</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent && <NavItem eventKey="Offer" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoFileText style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Offer Email</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.offerEmailSent && <NavItem eventKey="Offer" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoFileText style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Offer Email</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <NavItem eventKey="OnboardingDocuments" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoBriefcase style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Onboarding Documents Email</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.onboardingDocumentsEmailSent && <NavItem eventKey="OnboardingDocuments" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoBriefcase style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Onboarding Documents Email</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent && <NavItem eventKey="Credentials" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoLock style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Credentials Email</Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.credentialsEmailSent && <NavItem eventKey="Credentials" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoLock style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Credentials Email</Row></NavItem>}
                                {(this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted && this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted) && <NavItem eventKey="Background" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoGistSecret style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>Background Check</Row></NavItem>}
                                {(!this.props.hrInfo.selectedApplicant.data.hiringInfo.backgroundCompleted || !this.props.hrInfo.selectedApplicant.data.hiringInfo.googleCompleted || !this.props.hrInfo.selectedApplicant.data.hiringInfo.exclusionaryListCompleted) && <NavItem eventKey="Background" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoGistSecret style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>Background Check</Row></NavItem>}
                                {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted && <NavItem eventKey="IT" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoDeviceDesktop style={{ fontSize: 40, paddingRight: '10', color: '#5cb85c' }} /></Row><Row>IT </Row></NavItem>}
                                {!this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted && <NavItem eventKey="IT" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoDeviceDesktop style={{ fontSize: 40, paddingRight: '10', color: '#d9534f' }} /></Row><Row>IT</Row></NavItem>}
                                <NavItem eventKey="Licensing" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoCreditCard style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Licensing</Row></NavItem>
                                <NavItem eventKey="Notes" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoBook style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Notes</Row></NavItem>



                            </Nav>
                            <hr />
                        </Row>}
                        <Row>
                            {this.state.hrPage === 'ApplicationInfo' && <ApplicationForm />}
                            {this.state.hrPage === 'JobInfo' && <EmploymentInfoForm />}
                            {this.state.hrPage === 'IT' && <ITForm />}
                            {this.state.hrPage === 'Welcome' && <WelcomeForm />}
                            {this.state.hrPage === 'Notes' && <NotesForm />}
                            {this.state.hrPage === 'OnboardingDocuments' && <OnboardingDocumentsForm />}
                            {this.state.hrPage === 'Credentials' && <CredentialsForm />}
                            {this.state.hrPage === 'Background' && <BackgroundForm />}
                            {this.state.hrPage === 'Offer' && <OfferLetterForm />}
                            {this.state.hrPage === 'Licensing' && <LicensingForm />}
                        </Row>

                    </Grid>}

                {(this.props.hrInfo.getAllHRDataStatus === 'NOT_STARTED' || this.props.hrInfo.getAllHRDataStatus === 'STARTED') &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            introspectToken: introspectToken,
            getAllHRData: getAllHRData,
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            deleteHRData:deleteHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HiringView);
