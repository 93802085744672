import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { resetPipeline } from '../actions/pipelineActions';
import MenuSideBar from '../components/layoutComponents/MenuSideBar';
import SnapShotView from '../components/pipelineComponents/reportComponents/SnapShotView';
import { changeLocation } from '../actions/globalActions';
import { initialize, loginUser } from '../actions/loginActions';
import { getLoanOriginators, getAllEmployees } from '../actions/employeeActions';
import { Form, Icon, Message } from 'semantic-ui-react';
class SideBarOptionContainer extends Component {

    constructor(props) {
        super();
        this.state = {
            authSucceeded: false,

        }
      
    }
    componentWillMount() {
    }


    componentDidMount() {
        this.props.resetPipeline();
        this.props.getLoanOriginators();
        this.props.getAllEmployees();

        //Get Cookie from Intranet if applicable
        var employeeIdFromCookie = parseInt(document.cookie.split('=')[1]);
        var employeeIdFromCookie = 72;
        //When we actually want to logout via the menu sidebar we should get the parameter that is passed in from the goToAnotherPage function
       // if (!isNaN(employeeIdFromCookie) && !this.props.location.fromLogout && (document.referrer.indexOf('https://vdmc.net') != -1 || document.referrer.indexOf('https://www.vdmc.net') != -1)) {
            //Get Id from post variable
            var currentEmployee = this.props.employeeInfo.allEmployeesData.filter(function (item) { return (item.itInfo.oldEmployeeId == employeeIdFromCookie); })[0];
            var employeeId = currentEmployee.itInfo.ADUsername
            var loginParams = {
                username: currentEmployee.itInfo.ADUsername,
                employeeId: employeeId
            }
            this.props.loginUser(loginParams);
       // }

    }
    render() {

        //Check to see if Athena Auth is finished
        if(this.props.loginInfo.athenaLoginStatus === 'SUCCEEDED' && !this.state.authSucceeded)
        {
            this.setState({authSucceeded:true})
        }
        return (
            <div>
            { this.props.view === 'SnapShot' && this.state.authSucceeded &&
                    <div id="snapShotOuterContainer">
                        <MenuSideBar comp={<SnapShotView />} />
                    </div>
            }
            {!this.state.authSucceeded && <Form success size='large' key='large'>
                                    <Message icon  >
                                        <Icon name='circle notched' loading />
                                    </Message>
                                </Form>}
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        employeeInfo: state.employeeReducer,
    };
}

//This function links functions/dispatch to the props of the component being processed.
//In this case we are:
//changeUser - grabbing a function from actions.js file 
//goToAnotherPage - utilizing the push command from react-router to go to a page specified in the params
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            changeLocation: changeLocation,
            resetPipeline: resetPipeline,
            loginUser: loginUser,
            initialize: initialize,
            getLoanOriginators: getLoanOriginators,
            getAllEmployees: getAllEmployees,
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(SideBarOptionContainer);
