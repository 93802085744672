import React, { Component } from 'react';
import { Row, Nav, NavItem, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../../actions/loginActions';
import { getSingleLoanFromEncompass } from '../../../actions/pipelineActions';

import BorrowerPropertyForm from './BorrowerPropertyForm';
import AssetsForm from './AssetsForm';
import CreditForm from './CreditForm';
import EmploymentForm from './EmploymentForm';
import MiscForm from './MiscForm';
class LoanEditView extends Component {
    constructor(props) {
        super();
        this.state = {
            appName: 'BorrowerPropertyInfo',
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(appName) {
       this.setState({appName:appName})
    }
    componentDidMount() {
        this.props.getSingleLoanFromEncompass({ guid: this.props.pipelineInfo.selectedLoanGuid, username:this.props.loginInfo.athenaUsername })
    };

    render() {
        return (
            <div>
                {(this.props.pipelineInfo.getSingleLoanFromEncompassStatus === 'SUCCEEDED') &&
                    <Grid>
                      
                        <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.appName} onSelect={appName => this.handleSelect(appName)}>
                                <NavItem eventKey="BorrowerPropertyInfo" >Borrower-Property Info</NavItem>
                                <NavItem eventKey="AssetsInfo" >Assets Info</NavItem>
                                <NavItem eventKey="CreditInfo" >Credit Info</NavItem>
                                <NavItem eventKey="EmploymentInfo" >Employment Info</NavItem>
                                <NavItem eventKey="MiscInfo" >Miscellaneous Info</NavItem>
                            </Nav>
                            <hr/>
                        </Row>
                        <Row>
                          {this.state.appName === 'BorrowerPropertyInfo' && <BorrowerPropertyForm />}
                          {this.state.appName === 'AssetsInfo' && <AssetsForm />}
                          {this.state.appName === 'CreditInfo' && <CreditForm />}
                          {this.state.appName === 'EmploymentInfo' && <EmploymentForm />}
                          {this.state.appName === 'MiscInfo' && <MiscForm />}
                        </Row>

                    </Grid>}

                {(this.props.pipelineInfo.getSingleLoanFromEncompassStatus === 'NOT_STARTED' ||this.props.pipelineInfo.getSingleLoanFromEncompassStatus === 'STARTED') &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSingleLoanFromEncompass: getSingleLoanFromEncompass,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoanEditView);
