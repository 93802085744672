import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import RoleAddTable from '../../tables/hr_tables/hr_RoleAddTable';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';


const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class AddRoleModal extends Component {
    constructor(props) {
        super();
        this.state = {
            regionChecks: [],
            roleName: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
    }
    componentDidMount() {

    }
    handleSubmit(event) {
        var roleData = {
            roleName: this.state.roleName,
            regions: event,
            collection: 'roles'
        };

        // this.props.addIssue(issueData);
        this.props.upsertHRData(roleData)

        //Clears form on Submit
        this.setState({
            roleName: '',
            regions: []
        });

        this.props.closeModal()
        return;

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var checks = [];
        checks = this.state.regionChecks;
        if (checks.indexOf(value)!= -1) {
            this.remove(checks, value)
        }
        else {
            checks.push(value)
        }
        this.setState({
            regionChecks: checks
        });
    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    getUniqueCategory(obj) {
        if (obj) {
            var codes = obj.map((e) => {
                return e.IssueCategory
            })
            return [...new Set(codes)]
        }
        else {
            return undefined
        }

    }



    render() {

        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={addModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>

                    <div className='try-registration-form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                            <span aria-hidden="false">&times;</span>
                        </button>
                        <h1 style={{ float: 'left' }}>Add New Role</h1>

                        <br />
                        <br />

                        <br />

                        <form onSubmit={this.handleSubmit} className='try-form' id='callback'>

                            <h3>Role Name</h3>
                            <input className='form-control' id='roleName' type='text' name='roleName' placeholder='Role Name' value={this.state.roleName} onChange={this.handleInputChange} />

                            <br />
                            <h3>Regions:</h3>
                            <RoleAddTable  handleSubmit={this.handleSubmit}/>
                        </form>
                        <div id='error'></div>
                    </div>
                </div>
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        issueInfo: state.issueReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddRoleModal);
