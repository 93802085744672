import React, { Component } from 'react';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import { changeLocation } from '../../../actions/globalActions';
import { logoutUser } from '../../../actions/loginActions';
import { getClosingReport, resetPipeline } from '../../../actions/pipelineActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';
import { Form, Icon, Message } from 'semantic-ui-react';
import { array } from 'prop-types';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const today = new Date();
class SnapShotView extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            isLoading: true,
            hasError: false,
            showTable: false,
            showDetailModal: false,
            showLinkAppModal: false,
            isProcessed: false,
            showCount: false,
            chartDisplaying: 'Count',
            chartNotDisplaying: 'Amount',
            amountGraphData: undefined,
            countGraphData: undefined,
            currentLoan: undefined,
            dataForTable: {},
            currentDateRange: 'Today',
            chartWidth: 0,
            limitMessage: '',
            gotLinkStatus: false


        }
        this.getThePipeline = this.getThePipeline.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentDidMount() {

        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })
            this.getThePipeline()
        }
        this.setState({
            chartWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }

    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }

    getThePipeline() {


        var startDate = '1/1/' + today.getFullYear();
        var endDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()
        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
            closingFilter: '1997',
            cdsSentFilter: '3977',
            complianceDataFilter: ['DISCLOSEDLE.SentDate.1','DISCLOSEDLE.SentDate.2','DISCLOSEDLE.SentDate.3','DISCLOSEDLE.SentDate.4','DISCLOSEDLE.SentDate.5'],
            complianceReviewFilter: 'CX.COMPLIANCESTART',
            startDate: startDate,
            endDate: endDate,
            closingFields: [
                "Fields.1855", // Loan Closer
                "Fields.364", // Loan Number
                "Fields.4002", // Borrower Last Name 
                "Fields.CX.TRIDREADY", // TRSame Day
                "Fields.3977", // Closure Disclosure Sent Date
                "Fields.LOG.MS.DATE.RESUBMITTAL", //  Resubmittal Date Time
                "Fields.LOG.MS.DATETIME.CLEAR TO CLOSE", // Clear To Close Date Time
                "Fields.LOG.MS.DATETIME.DOC PREPARATION", // Doc Preparation Date Time
                "Fields.LOG.MS.DATETIME.HUD APPROVAL", // HUD Approval Date Time
                "Fields.748", // Trans Details Closed Date
                "Fields.1997", // Funds Sent Date
                "Fields.763", // Est Closing Date
                "Fields.ORGID", // Organization Code
            ],
            cdsSentFields: [
                "Fields.1855", // Loan Closer
                "Fields.364", // Loan Number
                "Fields.4002", // Borrower Last Name 
                "Fields.CX.TRIDREADY", // TRSame Day
                "Fields.3977", // Closure Disclosure Sent Date
                "Fields.LOG.MS.DATE.RESUBMITTAL", //  Resubmittal Date Time
                "Fields.LOG.MS.DATETIME.CLEAR TO CLOSE", // Clear To Close Date Time
                "Fields.LOG.MS.DATETIME.DOC PREPARATION", // Doc Preparation Date Time
                "Fields.LOG.MS.DATETIME.HUD APPROVAL", // HUD Approval Date Time
                "Fields.748", // Trans Details Closed Date
                "Fields.1997", // Funds Sent Date
                "Fields.763", // Est Closing Date
                "Fields.CX.RUSH", // Est Closing Date
                "Fields.ORGID", // Organization Code
            ],
            complianceDataFields: [
                "Fields.1855", // Loan Closer
                "Fields.DISCLOSEDLE.SentBy.1", // 
                "Fields.DISCLOSEDLE.SentDate.1", // 
                "Fields.DISCLOSEDLE.DisclosureType.1", // 
                "Fields.DISCLOSEDLE.SentBy.2", // 
                "Fields.DISCLOSEDLE.SentDate.2", // 
                "Fields.DISCLOSEDLE.DisclosureType.2", // 
                "Fields.DISCLOSEDLE.SentBy.3", // 
                "Fields.DISCLOSEDLE.SentDate.3", // 
                "Fields.DISCLOSEDLE.DisclosureType.3", // 
                "Fields.DISCLOSEDLE.SentBy.4", // 
                "Fields.DISCLOSEDLE.SentDate.4", // 
                "Fields.DISCLOSEDLE.DisclosureType.4", // 
                "Fields.DISCLOSEDLE.SentBy.5", // 
                "Fields.DISCLOSEDLE.SentDate.5", // 
                "Fields.DISCLOSEDLE.DisclosureType.5", // 
            ],
            complianceReviewFields: [
                "Fields.1855", // Loan Closer
                "Fields.CX.COMPLIANCESTART", // 
                "Fields.CX.COMPLIANCEUW", // 
            ]
        }
        this.props.getClosingReport(pipelineParam);


    }



    hideLinkAppModal() {
        this.getThePipeline(this.state.filterID, this.state.field);
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }
    /////////////////////////////////////////////////////
    //Write Files to xlsx
    /////////////////////////////////////////////////////

    createCellStyle(wb, color, bold, numberFormat) {
        return wb.createStyle({
            font: {
                bold: bold,
            },
            numberFormat: numberFormat ? '$#,##0.00; ($#,##0.00); -' : '',
            alignment: {
                wrapText: true
            },
            border: {
                left: {
                    style: "thin"
                },
                right: {
                    style: "thin"
                },
                top: {
                    style: "thin"
                },
                bottom: {
                    style: "thin"
                }
            },
            fill: {
                type: 'pattern',
                patternType: 'solid',
                bgColor: color,
                fgColor: color,
            }
        });
    }
    writeComplianceFile(filedata, outFileName) {
        //Add File to Upload List
        var that = this;
        const xl = require('excel4node');
        const wb = new xl.Workbook();
        //Loop through all of the Worksheets
        console.log('FILE DATA', filedata)
        Object.keys(filedata).map((wsName) => {
            //This is a Raw Data Worksheet
            if (wsName.indexOf('Data - ') !== -1) {
                var ws = wb.addWorksheet(wsName, {
                    outline: {
                        summaryBelow: false, // Change to False if your summary row is above your item rows
                    }
                });
                let curRow = 1;

                for (var i = 0; i < filedata[wsName].length; i++) {
                    //Header
                    if (i == 0) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));

                    }
                    else {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                    }
                    curRow += 1
                }
            }
            else {
                var ws = wb.addWorksheet(wsName, {
                    outline: {
                        summaryBelow: false, // Change to False if your summary row is above your item rows
                    }
                });
                //Few columns need to be wider
                ws.column(1).setWidth(25);
                ws.column(5).setWidth(30);
                ws.column(10).setWidth(20);
                ws.column(11).setWidth(20);
                let curRow = 1;

                for (var i = 0; i < filedata[wsName].length; i++) {
                    //Title
                    if (curRow == 1) {
                        ws.cell(curRow, 1, 1, 18, true).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                    }
                    //Header
                    else if (curRow == 2) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#ADD8E6', true, false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#FFFACD', true, false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#FFFACD', true, false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#F08080', true, false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                    }
                    //Totals Row
                    else if (filedata[wsName][i][0] === 'Totals') {

                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][5] === 'string' || filedata[wsName][i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 6).number(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][6] === 'string' || filedata[wsName][i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 7).number(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][7] === 'string' || filedata[wsName][i][7] instanceof String) ? ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 8).number(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][8] === 'string' || filedata[wsName][i][8] instanceof String) ? ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 9).number(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#DDDDDD', false, true));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#DDDDDD', false, true));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#DDDDDD', false, false));

                    }
                    //Name Row check to make sure it is not a month
                    else if (!filedata[wsName][i][5] && filedata[wsName][i][0] && months.indexOf(filedata[wsName][i][0]) === -1) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                    }
                    //Spacer Row
                    else if (!filedata[wsName][i][5] && !filedata[wsName][i][0]) {

                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#000000', false, false));
                    }
                    //Normal Value Cells
                    else {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 5).formula('ROUND((12*D'+curRow+')+(B'+curRow+'*2)-(C'+curRow+'*12),0)').style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        (typeof filedata[wsName][i][5] === 'string' || filedata[wsName][i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 6).number(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        (typeof filedata[wsName][i][6] === 'string' || filedata[wsName][i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 7).number(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        (typeof filedata[wsName][i][7] === 'string' || filedata[wsName][i][7] instanceof String) ? ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 8).number(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        (typeof filedata[wsName][i][8] === 'string' || filedata[wsName][i][8] instanceof String) ? ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 9).number(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 10).formula('SUM(F'+curRow+',(G'+curRow+'*0.8),(H'+curRow+'*0.8),I'+curRow+')').style(this.createCellStyle(wb, '#FFFACD', false, false));
                        ws.cell(curRow, 11).formula('J' + curRow + '/' + 'E' + curRow).style(this.createCellStyle(wb, '#FFFACD', false, false));
                        ws.cell(curRow, 12).formula('J' + curRow + '-' + 'E' + curRow).style(this.createCellStyle(wb, '#9ACD32', false, false));
                        ws.cell(curRow, 13).formula('MAX(0,L' + curRow + '*2)').style(this.createCellStyle(wb, '#9ACD32', false, true));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 17).formula('(N' + curRow + '*10)+(O' + curRow + '*20)+(P' + curRow + '*20)').style(this.createCellStyle(wb, '#F08080', false, true));
                        ws.cell(curRow, 18).formula('MAX(0,M' + curRow + '-Q' + curRow + ')').style(this.createCellStyle(wb, '#9ACD32', false, true));

                    }
                    curRow += 1
                }

            }
        })
        wb.writeToBuffer().then(function (buffer) {
            var blob = new Blob([buffer])
            that.downloadBlob(blob, outFileName)
        });
    }
    writeClosingFile(filedata, outFileName) {
        //Add File to Upload List
        var that = this;
        const xl = require('excel4node');
        const wb = new xl.Workbook();


        //Loop through all of the Worksheets
        console.log('FILE DATA', filedata)
        Object.keys(filedata).map((wsName) => {
            //This is a Raw Data Worksheet
            if (wsName.indexOf('Data - ') !== -1) {
                var ws = wb.addWorksheet(wsName, {
                    outline: {
                        summaryBelow: false, // Change to False if your summary row is above your item rows
                    }
                });
                let curRow = 1;

                for (var i = 0; i < filedata[wsName].length; i++) {
                    //Header
                    if (i == 0) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#DDDDDD', true, false, false));

                    }
                    else {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', false, false));;
                    }
                    curRow += 1
                }
            }
            else {
                var ws = wb.addWorksheet(wsName, {
                    outline: {
                        summaryBelow: false, // Change to False if your summary row is above your item rows
                    }
                });
                //Few columns need to be wider
                ws.column(1).setWidth(25);
                ws.column(5).setWidth(30);
                ws.column(10).setWidth(20);
                ws.column(11).setWidth(20);
                let curRow = 1;

                for (var i = 0; i < filedata[wsName].length; i++) {
                    //Title
                    if (curRow == 1) {
                        ws.cell(curRow, 1, 1, 18, true).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                    }
                    //Header
                    else if (curRow == 2) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#ADD8E6', true, false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#FFFACD', true, false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#FFFACD', true, false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', true, false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#F08080', true, false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#9ACD32', true, false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#FFFACD', true, false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#FFB6C1', true, false, false));
                    }
                    //Totals Row
                    else if (filedata[wsName][i][0] === 'Totals') {

                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][5] === 'string' || filedata[wsName][i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 6).number(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        (typeof filedata[wsName][i][6] === 'string' || filedata[wsName][i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', false)) : ws.cell(curRow, 7).number(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#DDDDDD', false, true));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#DDDDDD', false, true));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#DDDDDD', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#DDDDDD', false, false));

                    }
                    //Name Row check to make sure it is not a month
                    else if (!filedata[wsName][i][5] && filedata[wsName][i][0] && months.indexOf(filedata[wsName][i][0]) === -1) {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#ADD8E6', false, false));
                    }
                    //Spacer Row
                    else if (!filedata[wsName][i][5] && !filedata[wsName][i][0]) {

                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 5).string(filedata[wsName][i][4]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 8).string(filedata[wsName][i][7]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 9).string(filedata[wsName][i][8]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 10).string(filedata[wsName][i][9]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 11).string(filedata[wsName][i][10]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 15).string(filedata[wsName][i][14]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 16).string(filedata[wsName][i][15]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 17).string(filedata[wsName][i][16]).style(this.createCellStyle(wb, '#000000', false, false));
                        ws.cell(curRow, 18).string(filedata[wsName][i][17]).style(this.createCellStyle(wb, '#000000', false, false));
                    }
                    //Normal Value Cells
                    else {
                        ws.cell(curRow, 1).string(filedata[wsName][i][0]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 2).string(filedata[wsName][i][1]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 3).string(filedata[wsName][i][2]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 4).string(filedata[wsName][i][3]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 5).formula('ROUND(((' + curRow + '*D' + curRow + ')+(B' + curRow + '))-(' + curRow + 'C' + curRow + '*' + curRow + '),0)').style(this.createCellStyle(wb, '#ADD8E6', false, false));
                        (typeof filedata[wsName][i][5] === 'string' || filedata[wsName][i][5] instanceof String) ? ws.cell(curRow, 6).string(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 6).number(filedata[wsName][i][5]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        (typeof filedata[wsName][i][6] === 'string' || filedata[wsName][i][6] instanceof String) ? ws.cell(curRow, 7).string(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false)) : ws.cell(curRow, 7).number(filedata[wsName][i][6]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 8).formula('SUM(F' + curRow + ':' + 'G' + curRow + ')').style(this.createCellStyle(wb, '#FFFACD', false, false));
                        ws.cell(curRow, 9).formula('H' + curRow + '/' + 'E' + curRow).style(this.createCellStyle(wb, '#FFFACD', false, false));
                        ws.cell(curRow, 10).formula('H' + curRow + '-' + 'E' + curRow).style(this.createCellStyle(wb, '#9ACD32', false, false));
                        ws.cell(curRow, 11).formula('MAX(0,J' + curRow + '*5)').style(this.createCellStyle(wb, '#9ACD32', false, true));
                        ws.cell(curRow, 12).string(filedata[wsName][i][11]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 13).string(filedata[wsName][i][12]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 14).string(filedata[wsName][i][13]).style(this.createCellStyle(wb, '#FFFFFF', false, false));
                        ws.cell(curRow, 15).formula('(L' + curRow + '*10)+(M' + curRow + '*20)+(N' + curRow + '*20)').style(this.createCellStyle(wb, '#F08080', false, true));
                        ws.cell(curRow, 16).formula('MAX(0,K' + curRow + '-O' + curRow + ')').style(this.createCellStyle(wb, '#9ACD32', false, true));
                        ws.cell(curRow, 17).formula('SUM(L' + curRow + ':N' + curRow + ')/H' + curRow).style(this.createCellStyle(wb, '#FFFACD', false, false));
                        ws.cell(curRow, 18).formula('SUM(L' + curRow + ':N' + curRow + ')').style(this.createCellStyle(wb, '#FFB6C1', false, false));
                    }
                    curRow += 1
                }

            }
        })
        wb.writeToBuffer().then(function (buffer) {
            var blob = new Blob([buffer])
            that.downloadBlob(blob, outFileName)
        });
    }

    downloadBlob(blob, filename) {
        // Create an object URL for the blob object
        const url = URL.createObjectURL(blob);

        // Create a new anchor element
        const a = document.createElement('a');

        // Set the href and download attributes for the anchor element
        // You can optionally set other attributes like `title`, etc
        // Especially, if the anchor element will be attached to the DOM
        a.href = url;
        a.download = filename || 'download';

        // Click handler that releases the object URL after the element has been clicked
        // This is required for one-off downloads of the blob content
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);

            }, 150);
        };

        // Add the click event listener on the anchor element
        // Comment out this line if you don't want a one-off download of the blob content
        a.addEventListener('click', clickHandler, false);

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        // Comment out this line if you don't want an automatic download of the blob content
        a.click();

        // Return the anchor element
        // Useful if you want a reference to the element
        // in order to attach it to the DOM or use it in some other way
        return a;
    }



    render() {
        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }
        //Sets SnapShot to Today by default and set gotPipeStatus to true
        if (this.props.pipelineInfo.getPipelineStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: true, isLoading: false })
            this.props.updateProgress(99);
        }
        //Handle Failed/Errored Scenarios
        else if (this.props.pipelineInfo.getPipelineStatus === 'ERRORED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getPipelineErrorMessage)
            this.props.logoutUser()
            this.setState({ gotPipeStatus: true });
        }
        else if (this.props.pipelineInfo.getPipelineStatus === 'FAILED' && !this.state.gotPipeStatus) {
            this.showLinkAppModal();
            this.setState({ gotPipeStatus: true });
        }

        //Reset gotPipeStatus to false
        if ((this.props.pipelineInfo.getPipelineStatus === 'STARTED' || this.props.pipelineInfo.getPipelineStatus === 'NOT_STARTED') && this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true, showTable: false })
        }

        var linkAppModal = this.linkAppModal()

        console.log('CHECK', this.props.pipelineInfo.closingReportData);
        return (
            <div>
                {linkAppModal}
                {this.props.pipelineInfo.getClosingReportStatus !== 'SUCCEEDED' &&
                    <div>
                        <h1 style={{ textAlign: 'center' }}>Loading Data for Closing and Compliance Reports</h1>
                        <Form success size='large' key='large'>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                            </Message>
                        </Form>
                    </div>
                }
                {this.props.pipelineInfo.getClosingReportStatus === 'SUCCEEDED' &&
                    <Grid>
                        <h1 style={{ textAlign: 'center' }}>Closing and Compliance Reports</h1>
                        <br/>
                        <Row>
                        <Col md={12}>
                            <button className="btn btn-warning form-control" onClick={() => this.writeClosingFile(this.props.pipelineInfo.closingReportData.closingWorkbook, 'Closing Production Figures-' + today.getFullYear() + '.xlsx')}>{'Download Closing Production Figures-' + today.getFullYear() + ' Report'}</button>
                        </Col>
                        </Row>
                        <br/>
                        <Row>
                        <Col md={12}>
                            <button className="btn btn-info form-control" onClick={() => this.writeComplianceFile(this.props.pipelineInfo.closingReportData.complianceWorkbook, 'The OC Production Figures Test-' + today.getFullYear() + '.xlsx')}>{'Download the OC Production Figures-' + today.getFullYear() + ' Report'}</button>
                        </Col>
                        </Row>
                    </Grid>
                }

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeLocation: changeLocation,
            getClosingReport: getClosingReport,
            resetPipeline: resetPipeline,
            logoutUser: logoutUser,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SnapShotView);
