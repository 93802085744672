import React, { Component } from 'react';
import { Grid, Col, Row, ProgressBar } from 'react-bootstrap'

import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import { introspectToken } from '../../../actions/loginActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal'
import LoanListTable from '../../tables/pipeline_tables/LoanListTable'
import { getLoanCompareList, setSingleLoanGuid, getLoanAudit, resetLoanAudit, updateProgress } from '../../../actions/pipelineActions';


class LoanListView extends Component {
    constructor(props) {
        super();
        this.state = {
            gotPipeStatus: false,
            showLinkAppModal: false,
            loanCompareList: <li></li>,
            isLoading: true,
            showCompareView: false,
            selectedGuid: '',
            gotCompStatus: false,
            whatToCompare: 'ALL',
            leftSaveDate: '',
            rightSaveDate: '',
            gotLoanAuditStatus: false,
            isInitialAudit: true
        }
        this.showComparison = this.showComparison.bind(this);
        this.showEdit = this.showEdit.bind(this);
        this.performAudit = this.performAudit.bind(this);
    }

    componentDidMount() {
        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })

            this.props.getLoanCompareList({username: this.props.loginInfo.athenaUsername});
        }
        this.setState({
            chartWidth: window.innerWidth,
            isInitialAudit: false
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }

    componentWillMount() {
        this.props.resetLoanAudit()
    }

    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }

    getThePipeline(filter, field) {

        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
        }
       

    }


    hideLinkAppModal() {
        this.props.getLoanCompareList({username: this.props.loginInfo.athenaUsername});
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }

    showComparison(selectedGuid) {
        this.props.setSingleLoanGuid({ guid: selectedGuid })
        this.props.goToAnotherPage({
            pathname: '/loanRevCompare',
        })

    }
    showEdit(selectedGuid) {
        this.props.setSingleLoanGuid({ guid: selectedGuid })
        this.props.goToAnotherPage({
            pathname: '/loanEdit',
        })

    }
    performAudit(selectedGuid, type) {
        if (type === 'VA') {
            var params = {
                data: selectedGuid,
                audit: type
            }
        }
        else {
            alert(type + ' Loans are not auditable at this time.')
            return;
        }
        this.props.getLoanAudit(params)
        this.setState({ gotLoanAuditStatus: false, isInitialAudit: false })
    }

    render() {
        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }


        if (this.props.pipelineInfo.getLoanCompareStatus === 'STARTED' && this.state.gotPipeStatus && !this.state.isLoading) {
            this.setState({ gotPipeStatus: false, isLoading: true })
        }
        if (this.props.pipelineInfo.getLoanCompareStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: true, isLoading: false })
        }

        if (this.props.pipelineInfo.getSingleLoanFromWarehouseStatus === 'STARTED' && this.state.gotCompStatus) {
            this.setState({ gotCompStatus: false })
        }
        //Handle Failed/Errored Scenarios
        else if (this.props.pipelineInfo.getLoanCompareStatus === 'ERRORED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getLoanCompareErrorMessage)
            this.props.goToAnotherPage({
                pathname: '/login'
            })
            this.setState({ gotPipeStatus: true });
        }
        else if (this.props.pipelineInfo.getLoanCompareStatus === 'FAILED' && !this.state.gotPipeStatus) {
            this.showLinkAppModal();
            this.setState({ gotPipeStatus: true });
        }


        if (this.props.pipelineInfo.getLoanAuditStatus === "SUCCEEDED" && !this.state.gotLoanAuditStatus && !this.state.isInitialAudit) {
            if (this.props.pipelineInfo.auditData.data.Grade) {
                alert("Loan has Passed VA Audit")
            }
            else {
                alert("Loan has Failed VA Audit")
            }
            this.setState({ gotLoanAuditStatus: true })
        }
        return (
            <Grid>
                {!this.state.showCompareView && <div>
                    {this.props.pipelineInfo.getLoanCompareStatus === 'SUCCEEDED' &&
                        <LoanListTable loans={this.props.pipelineInfo.loanCompareDataList} showComparison={this.showComparison} showEdit={this.showEdit} performAudit={this.performAudit} />
                    }

                    {this.props.pipelineInfo.getLoanCompareStatus !== 'SUCCEEDED' &&
                        <Grid>
                            <br/>
                            <Row>
                                <ProgressBar active striped bsStyle="info" now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`} />
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ textAlign: 'center' }}>Grabbing Loans
                                    </p>
                                </Col>
                            </Row></Grid>}
                </div>}
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getLoanCompareList: getLoanCompareList,
            setSingleLoanGuid: setSingleLoanGuid,
            introspectToken: introspectToken,
            getLoanAudit: getLoanAudit,
            resetLoanAudit: resetLoanAudit,
            updateProgress: updateProgress,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoanListView);
