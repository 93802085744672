import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

//Initial Data
const initialState = {
    getAllHRDataStatus: 'NOT_STARTED',
    upsertHRDataStatus: 'NOT_STARTED',
    deleteHRDataStatus: 'NOT_STARTED',
    selectedApplicantStatus:'NOT_STARTED',
    getAllHRDataErrorMessage: '',
    upsertHRDataErrorMessage: '',
    deleteHRDataErrorMessage: '',
    allLocationsData: [],
    allDepartmentsData: [],
    allRolesData: [],
    allRegionsData: [],
    allEmployeesData: [],
    allApplicantsData: [],
    allTitlesData: [],
    allEmailData: [],
    allCredentialsData: [],
    allEmailDistroGroupsData: [],
    selectedApplicant: {}
}

const hrReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_HR_STATUS':
            return {
                ...state,
                getAllHRDataStatus: 'NOT_STARTED',
                upsertHRDataStatus: 'NOT_STARTED',
                deleteHRDataStatus: 'NOT_STARTED',
                getAllHRDataErrorMessage: '',
                upsertHRDataErrorMessage: '',
                deleteHRDataErrorMessage: '',
                allLocationsData: [],
                allDepartmentsData: [],
                allRolesData: [],
                allRegionsData: [],
                allEmployeesData: [],
                allApplicantsData: [],
                allTitlesData: [],
                allEmailData: [],
                allCredentialsData: [],
                allEmailDistroGroupsData: [],
            }

        ////////////////////////////////////////////////////////////////////
        //GET ALL
        ////////////////////////////////////////////////////////////////////
        case 'GET_ALL_HR_DATA_STARTED':
            return {
                ...state,
                getAllHRDataStatus: 'STARTED',
            }
        case 'GET_ALL_HR_DATA_ERRORED':
            return {
                ...state,
                getAllHRDataStatus: 'ERRORED',
                getAllHRDataErrorMessage: action.getAllHRDataErrorMessage
            }
        case 'GET_ALL_ROLES_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allRolesData: action.allRoles
            }
        case 'GET_ALL_REGIONS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allRegionsData: action.allRegions
            }
        case 'GET_ALL_DEPARTMENTS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allDepartmentsData: action.allDepartments
            }
        case 'GET_ALL_EMPLOYEES_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allEmployeesData: action.allEmployees
            }
        case 'GET_ALL_LOCATIONS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allLocationsData: action.allLocations
            }
        case 'GET_ALL_TITLES_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allTitlesData: action.allTitles
            }
        case 'GET_ALL_EMAILS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allEmailData: action.allEmails
            }
            case 'GET_ALL_APPLICANTS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allApplicantsData: action.allApplicants
            }
            case 'GET_ALL_CREDENTIALS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allCredentialsData: action.allCredentials
            }
            case 'GET_ALL_EMAIL_DISTRO_GROUPS_SUCCEEDED':
            return {
                ...state,
                getAllHRDataStatus: 'SUCCEEDED',
                allEmailDistroGroupsData: action.allEmailDistroGroups
            }
        ////////////////////////////////////////////////////////////////////
        //UPSERT
        ////////////////////////////////////////////////////////////////////

        case 'UPSERT_HR_DATA_STARTED':
            return {
                ...state,
                upsertHRDataStatus: 'STARTED',
            }
        case 'UPSERT_HR_DATA_ERRORED':
            return {
                ...state,
                upsertHRDataStatus: 'ERRORED',
                upsertHRDataErrorMessage: action.upsertHRDataErrorMessage
            }
        case 'UPSERT_HR_DATA_SUCCEEDED':
            return {
                ...state,
                upsertHRDataStatus: 'SUCCEEDED',
            }
        ////////////////////////////////////////////////////////////////////
        //DELETE
        ////////////////////////////////////////////////////////////////////
        case 'DELETE_HR_DATA_STARTED':
            return {
                ...state,
                deleteHRDataStatus: 'STARTED',
            }
        case 'DELETE_HR_DATA_ERRORED':
            return {
                ...state,
                deleteHRDataStatus: 'ERRORED',
                deleteHRDataErrorMessage: action.deleteHRDataErrorMessage
            }
        case 'DELETE_HR_DATA_SUCCEEDED':
            return {
                ...state,
                deleteHRDataStatus: 'SUCCEEDED',
            }
        ////////////////////////////////////////////////////////////////////
        //SET SELECTED APPLICANT
        ////////////////////////////////////////////////////////////////////
        case 'SET_SELECTED_APPLICANT':
        return {
            ...state,
            selectedApplicant:action.payload.selectedApplicant,
            selectedApplicantStatus: 'SUCCEEDED',
        }









        default:
            return state
    }
}

const persistConfig = {
    key: 'hr',
    storage: storage,
    whiteList: ['selectedApplicant']
  };
  
  export default persistReducer(persistConfig, hrReducer);