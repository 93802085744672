import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { customFilter,customSort } from '../../../helpers/tableHelpers'
import 'react-table/react-table.css'
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import { Tooltip, OverlayTrigger, Grid,Col, Row, Button, ButtonGroup } from 'react-bootstrap'
// import deleteRegion from '../../actions/hrActions';
import { deleteHRData } from '../../../actions/hrActions';

import { resolveIssue } from '../../../actions/issueActions';
import windowSize from 'react-window-size';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

var addTooltip = (
  <Tooltip id="tooltip">
    <strong>Add:</strong> Create new employee.
  </Tooltip>
);

var editTooltip = (
  <Tooltip id="tooltip">
  <strong>Edit:</strong> Make changes to selected employee.
  </Tooltip>
);
var deleteTooltip = (
  <Tooltip id="tooltip">
  <strong>Delete:</strong> Remove selected employee.
  </Tooltip>
);


class EmailsTable extends Component {

  constructor(props) {
    super();
    this.state = {
    }

    this.closeEditModal = this.closeEditModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);

  }
  openAddModal() {
    this.props.openAddModal();
  }
  openEditModal() {
    this.props.openEditModal(this.state.selectedRowInfo.original);
  }

  closeEditModal() {
    this.props.closeEditModal();
  }


  handleDeleteClick = () => {
    confirmAlert({
        title: 'Delete Item',
        message: 'Are you sure you want to delete this?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                  var emailData = {
                    emailID: this.state.selectedRowInfo.original._id,
                    collection: 'emailTemplates'
                  };
                  this.props.deleteHRData(emailData);
                  this.setState({selectedRowInfo:undefined})
                 }
            },
            {
              label: 'No',
              onClick: () => { }
          },

        ]
    })
};


  render() {
    return (
      <div>
        {this.state.editModal}
        <Grid>
        <Row>
          <Col style={{ float: 'right' }}>
            <ButtonGroup>
              <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={addTooltip}>
                <Button onClick={this.props.openAddModal}>
                  <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaPlus /></span>
                </Button>
              </OverlayTrigger>
              {this.state.selectedRowInfo &&
                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                  <Button onClick={this.openEditModal}>
                    <span style={{ color: '#428bca', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                  </Button>
                </OverlayTrigger>}
              {this.state.selectedRowInfo && <OverlayTrigger style={{ textAlign: 'right' }} placement="right" overlay={deleteTooltip}>
                <Button onClick={this.handleDeleteClick}>
                  <span style={{ color: '#ff0000', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                </Button>
              </OverlayTrigger>
              }
              {!this.state.selectedRowInfo &&
                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                  <Button>
                    <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                  </Button>
                </OverlayTrigger>}

              {!this.state.selectedRowInfo && <OverlayTrigger style={{ textAlign: 'right' }} placement="right" overlay={deleteTooltip}>
                <Button>
                  <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                </Button>
              </OverlayTrigger>
              }
            </ButtonGroup>
          </Col>
        </Row>
        </Grid>
        <hr />

        <ReactTable
          data={this.props.hrInfo.allEmailData}
          getTrProps={
            (state, rowInfo) => {
              var that = this;

              if (rowInfo !== undefined) {

                return {
                  onClick: (e) => {
                    that.setState({
                      selectedRow: rowInfo.index,
                      selectedRowInfo: rowInfo,
                      noSelectedRows: false
                    })
                  },
                  style: {
                    background: that.state.selectedRowInfo?rowInfo.index === that.state.selectedRow ? '#00afec' : 'white':'white',
                    color: that.state.selectedRowInfo?rowInfo.index === that.state.selectedRow ? 'white' : 'black':'black',
                  }
                }
              }
              else {
                return {
                  onClick: (e) => {

                  }

                }
              }
            }}
          columns={[
            {

              columns: [
                {
                  Header: "Subject",
                  accessor: "subject",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                },
                // {
                //   Header: "First Name",
                //   accessor: "firstName",
                //   minWidth: 30,
                //   Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                // },
                // {
                //   Header: "Title",
                //   accessor: "title",
                //   minWidth: 30,
                //   Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                // },
                // {
                //   Header: "Department",
                //   accessor: "departmentName",
                //   minWidth: 30,
                //   Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                // },

              ]
            }
          ]}
          style={{
            height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
          }}
          defaultPageSize={this.props.hrInfo.allEmailData.length + 1}
          filterable
          defaultFilterMethod={customFilter}
          defaultSortMethod={customSort}
          className="-striped -highlight"
          defaultSorted={[
                        {
                            id: "lastName",
                            desc: false
                        }
                    ]}
        />
      </div>
    )
  }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
  return {
    hrInfo: state.hrReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteHRData: deleteHRData,
      resolveIssue: resolveIssue,
      goToAnotherPage: (params) => push(params)
    }
    , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(EmailsTable));