///////////////////////////////////
///Locater Actions
///////////////////////////////////

export const getLoanOriginators = () => {

    return {
        type: 'GET_LOANORIGINATORS'
    }
};
export const getAllEmployees = () => {

    return {
        type: 'GET_ALL_EMPLOYEES'
    }
};
export const resetAllEmployeeData = () => {

    return {
        type: 'RESET_ALL_EMPLOYEE_DATA'
    }
};