import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import EditTitleModal from '../modals/hr_modals/EditTitleModal';
import { getAllHRData} from '../../actions/hrActions';
import TitlesTable from '../tables/hr_tables/TitlesTable';
import AddTitleModal from '../modals/hr_modals/AddTitleModal';

class TitlesForm extends Component {
    constructor(props) {
        super();
        this.state = {
            borrowerInfo: {},
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }


    componentDidMount() {
    };


    handleSubmit() {
        this.setState({ formStatus: 'SAVING' })
        setTimeout(
            function () {
                this.setState({ formStatus: 'SUCCESS' })
            }
                .bind(this),
            3000
        );

    }


    openAddModal() {
        this.setState({
            addModalIsOpen: true
        });
    }

    closeAddModal() {
        this.props.getAllHRData({collection:'titles'});
        this.setState({ addModalIsOpen: false });
    }

    getAddTitleModal() {
        return (<AddTitleModal modalIsOpen={this.state.addModalIsOpen} closeModal={this.closeAddModal.bind(this)} data={this.props.hrInfo}/>)
    }


    openEditModal(row) {
        this.setState({
            editModalIsOpen: true,
            selectedRow:row,
            clearData:false
        });
    }

    closeEditModal() {
        this.setState({ editModalIsOpen: false, clearData:true ,firstPass:false, selectedRow:undefined});
    }

    getEditTitleModal(){
        return (<EditTitleModal modalIsOpen={this.state.editModalIsOpen} closeModal={this.closeEditModal.bind(this)} selectedRow={this.state.selectedRow} clearData={this.state.clearData} />)
      }

    render() {

        var addTitleModal = this.getAddTitleModal();
        var editTitleModal = this.getEditTitleModal();
        return (
            
            <div>
                {addTitleModal}
                {editTitleModal}
                <h1>Titles</h1>
                    <TitlesTable data={this.props.hrInfo} openAddModal={this.openAddModal} openEditModal={this.openEditModal} closeEditModal={this.closeEditModal}/>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData:getAllHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(TitlesForm);
