import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import { Button, Grid, Row, Col } from 'react-bootstrap'

const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class AddLocationModal extends Component {
    constructor(props) {
        super();
        this.state = {

            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
    }
    componentDidMount() {

    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    handleSubmit(event) {


        event.preventDefault();

        // do form stuff
        var locationData = {
            locationID: this.makeid(),
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            collection: 'locations'
        };

        // this.props.addIssue(issueData);
        this.props.upsertHRData(locationData)

        //Clears form on Submit
        this.setState({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        });

        this.props.closeModal()
        return;

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var checks = [];
        checks = this.state.locationsChecks;
        if (checks.indexOf(value)!= -1) {
            this.remove(checks, value)
        }
        else {
            checks.push(value)
        }
        this.setState({
            locationsChecks: checks
        });
    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    getUniqueCategory(obj) {
        if (obj) {
            var codes = obj.map((e) => {
                return e.IssueCategory
            })
            return [...new Set(codes)]
        }
        else {
            return undefined
        }

    }

    render() {
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={addModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>

                    <div className='try-registration-form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                            <span aria-hidden="false">&times;</span>
                        </button>
                        <h1 style={{ float: 'left' }}>Add New Location</h1>
                        <br />
                        <br />
                        <form onSubmit={this.handleSubmit} className='try-form' id='callback'>
                            <Grid>
                                <br />
                                <Row>
                                    <Col md={6} sm={12}>
                                        <h3>Address</h3>
                                        <input className='form-control' id='address1' type='text' name='address1' placeholder='Address' value={this.state.address1} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <h3>Address (Cont'd)</h3>
                                        <input className='form-control' id='address2' type='text' name='address2' placeholder="Address (Cont'd)" value={this.state.address2} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={6} sm={12}>
                                        <h3>City</h3>
                                        <input className='form-control' id='city' type='text' name='city' placeholder='City' value={this.state.city} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <h3>State</h3>
                                        <input className='form-control' id='state' type='text' name='state' placeholder='State' value={this.state.state} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <h3>Zip</h3>
                                        <input className='form-control' id='zip' type='text' name='zip' placeholder='Zip' value={this.state.zip} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                            </Grid>
                            <br />
                            <br />
                            <button className='form-control btn btn-success'>Add Location</button>
                        </form>
                    </div>
                </div>
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddLocationModal);
