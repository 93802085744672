export function customFilter(filter, row, column) {
    const id = filter.pivotId || filter.id
    if (row[id] !== undefined) {

        var rowVal = String(row[id]).toLowerCase();

        return rowVal.indexOf(filter.value.toLowerCase()) >= 0;
    }
    else {
        return true;

    }
}

export function customAddressFilter(filter, row) {
    var fullAddress = row._original.address1 + row._original.address2 + row._original.city + row._original.state + row._original.zip;
    if (fullAddress.toLowerCase().indexOf(filter.value.toLowerCase()) != -1) {
        return true;
    }
}

export function customSort(a, b, desc) {
    // force null and undefined to the bottom
    // a = (a === null || a === undefined) ? -Infinity : a
    // b = (b === null || b === undefined) ? -Infinity : b

    // force any string values to lowercase
    a = a === 'string' ? a.toLowerCase() : a
    b = b === 'string' ? b.toLowerCase() : b
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
        return 1
    }
    if (a < b) {
        return -1
    }
    // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
    return 0
}

export function customSortWithMoney(a, b, desc) {
    // force null and undefined to the bottom
    // a = (a === null || a === undefined) ? -Infinity : a
    // b = (b === null || b === undefined) ? -Infinity : b

    // force any string values to lowercase
    a = a === 'string' ? a.toLowerCase() : a
    b = b === 'string' ? b.toLowerCase() : b
    a = dollarsToFloat(a);
    b = dollarsToFloat(b);
    a = NaN === parseFloat(a) ? a : parseFloat(a);
    b = NaN === parseFloat(b) ? b : parseFloat(b);
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
        return 1
    }
    if (a < b) {
        return -1
    }
    // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
    return 0
}

function dollarsToFloat(dollar) {
    if (typeof (dollar) === 'number') { return dollar; }
    else {
        var noSign = dollar.replace('$', '');
        return noSign.replace(/,/g, '');
    }

}