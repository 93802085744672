import React, { Component } from 'react';
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import { encompassLink, encompassLinkReset } from '../../../actions/loginActions';
import Modal from 'react-modal';

const resolveIssueModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        top: '53%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        //marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '400px',
        maxWidth: '600px',
        height: '465px',
        overlfow: 'none'
    }
};

class ResolveIssueModal extends Component {
    constructor(props) {
        super();
        this.state = {
            issueID: '',
            username: '',
            password: ''

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFail = this.handleFail.bind(this);
        this.handleExit = this.handleExit.bind(this);

    }
    componentDidMount() {

    }
    handleSubmit(event) {

        event.preventDefault();
        var appLoginData = {
            appUsername: this.state.username,
            appPassword: this.state.password,
            athenaUsername: this.props.loginInfo.athenaUsername
        }
        if (this.props.appName === 'Encompass') {
            this.props.encompassLink(appLoginData);
        }
        return;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleFail(event) {
        event.preventDefault();
        this.props.encompassLinkReset();

    }

    handleExit(event) {
        event.preventDefault();
        this.props.closeModal()
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    render() {
        if(this.props.loginInfo.encompassLinkStatus === 'ERRORED')
        {
            alert(this.props.loginInfo.encompassLinkErrorMessage)

        }


        return (
            < Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={resolveIssueModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                {this.props.loginInfo.encompassLinkStatus === 'NOT_STARTED' && <div>
                    <Row  style={{textAlign: 'center'}}>
                        <Col xs={12}>
                            <h2 style={{fontWeight: '800'}}>Link Account with {this.props.appName}</h2>
                        </Col>
                        <Col xs={10} xsOffset={1} style={{marginTop: '-15px'}}>
                            <img src="images/athena_v2.png" height="80px" style={{marginRight: '25px'}} alt='athena img' />
                            <span style={{fontSize: '60px', fontWeight: '800'}}>+</span>
                            <img src="images/encompass.png" height="80px" style={{marginLeft: '30px'}} alt='encompass img' />
                            </Col>
                       
                    </Row><br />
                    <Row>
                    <form onSubmit={this.handleSubmit} className='try-form' id='callback'>
                        <Col xs={8} xsOffset={2} sm={6} md={6} smOffset={3} mdOffset={3}>
                        <input className='form-control' style={{width: '100%'}}id='username' type='text' name='username' placeholder='Username' value={this.state.username} onChange={this.handleInputChange} /><br />
                        <input className='form-control' style={{ width: '100%' }} id='passwords' type='password' name='password' placeholder='Password' value={this.state.password} onChange={this.handleInputChange} /><br />
                        <button type="submit" style={{ width: '100%'}} className="btn btn-success form-control"><b>Link</b></button><br />
                        </Col>
                    </form>
                    </Row>
                    </div>}

                    {this.props.loginInfo.encompassLinkStatus === 'STARTED' && <div>
                    <Row  style={{textAlign: 'center'}}>
                        <Col xs={12}>
                            <h2 style={{fontWeight: '800'}}>Link Account with {this.props.appName}</h2>
                        </Col>
                        <Col xs={10} xsOffset={1} style={{marginTop: '-15px'}}>
                            <img src="images/athena_v2.png" height="80px" style={{marginRight: '25px'}} alt='athena img' />
                            <span style={{fontSize: '60px', fontWeight: '800'}}>+</span>
                            <img src="images/encompass.png" height="80px" style={{marginLeft: '30px'}} alt='encompass img' />
                            </Col>
                       
                    </Row><br />
                    <Row style={{textAlign: 'center'}}>
                    <Col xs={12}>
                    <h3>Linking to {this.props.appName}</h3>
                    </Col>
                    <Col xs={12}>
                    <p>Spinner Loader</p>
                    </Col>
                    </Row>
                    </div>}

                    {this.props.loginInfo.encompassLinkStatus === 'SUCCEEDED' && <div>
                    <Row  style={{textAlign: 'center'}}>
                        <Col xs={12}>
                            <h2 style={{fontWeight: '800'}}>Link Account with {this.props.appName}</h2>
                        </Col>
                        <Col xs={10} xsOffset={1} style={{marginTop: '-15px'}}>
                            <img src="images/athena_v2.png" height="80px" style={{marginRight: '25px'}} alt='athena img' />
                            <span style={{fontSize: '60px', fontWeight: '800'}}>+</span>
                            <img src="images/encompass.png" height="80px" style={{marginLeft: '30px'}} alt='encompass img' />
                            </Col>
                       
                    </Row>
                    <Row style={{textAlign: 'center'}}>
                    <Col xs={12}>
                    <i style={{fontSize: '160px', paddingBottom: '10px', color: '#28B764'}} class="fa fa-check-circle"></i>
                    </Col>
                    <Col xs={8} xsOffset={2}>
                    <button type="submit" onClick={this.handleExit} style={{ width: '100%', fontWeight:'bold'  }} className="btn btn-success form-control">Continue to Athena</button>
                    </Col>
                    </Row>
                    </div>}

                    {this.props.loginInfo.encompassLinkStatus === 'FAILED' && <div>
                    <Row  style={{textAlign: 'center'}}>
                        <Col xs={12}>
                            <h2 style={{fontWeight: '800'}}>Link Account with {this.props.appName}</h2>
                        </Col>
                            <Col xs={10} xsOffset={1} style={{marginTop: '-15px'}}>
                            <img src="images/athena_v2.png" height="80px" style={{marginRight: '25px'}} alt='athena img' />
                            <span style={{fontSize: '60px', fontWeight: '800'}}>+</span>
                            <img src="images/encompass.png" height="80px" style={{marginLeft: '30px'}} alt='encompass img' />
                            </Col>
                       
                    </Row>
                    <Row style={{textAlign: 'center'}}>
                    <Col xs={12}>
                    <br />
                    <h2 style={{fontWeight: '800'}}>Account Link Failed</h2>
                    <br /><br /><br />
                    </Col>
                    <Col xs={8} xsOffset={2}>
                    <button onClick={this.handleFail} type="submit" style={{ width: '100%', fontWeight:'bold'  }} className="btn btn-warning form-control">Try Again</button>
                    </Col>
                    </Row>
                    </div>}
                </div>
            </Modal>
        )
    }
}



//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            encompassLink: encompassLink,
            encompassLinkReset: encompassLinkReset,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(ResolveIssueModal);
