import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { resetActivities } from '../../actions/activityActions';
import MyActivitiesTable from '../tables/activity_tables/MyActivitiesTable';
import { Tooltip, OverlayTrigger, Grid, Col, Row, Button, ButtonGroup } from 'react-bootstrap'
import { Form, Icon, Message } from 'semantic-ui-react';
class HiringView extends Component {
    constructor(props) {
        super();
        this.state = {

        }

    }

    componentWillMount() {
        this.props.resetActivities()
    }


    render() {
        return (
            <div>
                <Grid>
                    <h1>My Activities </h1>
                    <MyActivitiesTable />
                </Grid>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetActivities: resetActivities,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HiringView);
