import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'
import LoginReducer from './loginReducer';
import PipelineReducer from './pipelineReducer';
import IssueReducer from './issueReducer';
import EmployeeReducer from './employeeReducer';
import HRReducer from './hrReducer';
import CommunicationReducer from './communicationReducer';
import ActivityReducer from './activityReducer';
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage' 
const config = {
    key: 'primary',
    storage,
    //blacklist: [],
    blacklist: ['hrReducer','pipelineReducer'],

  }

  export default (history) => persistReducer(config,combineReducers({
    loginReducer:LoginReducer,
    pipelineReducer: PipelineReducer,
    issueReducer: IssueReducer,
    employeeReducer: EmployeeReducer,
    hrReducer: HRReducer,
    communicationReducer: CommunicationReducer,
    activityReducer: ActivityReducer,
    router: connectRouter(history),
    //router: routerReducer,

}));

