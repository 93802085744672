import React, { Component } from 'react';
import { Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { introspectToken } from '../../../actions/loginActions';
import { getSingleLoanFromWarehouse } from '../../../actions/pipelineActions';
import { Form, Icon, Message } from 'semantic-ui-react';


class LoanRevComparisonView extends Component {
    constructor(props) {
        super();
        this.state = {
            gotPipeStatus: false,
            showLinkAppModal: false,
            isLoading: true,
            leftSelection: 1,
            rightSelection: 1,
            whatToCompare: 'ALL',
            leftSaveDate: '',
            rightSaveDate: '',
            gotCompStatus: false
        }
        this.changeLeftSelection = this.changeLeftSelection.bind(this);
        this.changeRightSelection = this.changeRightSelection.bind(this);
        this.getComparisionFields = this.getComparisionFields.bind(this);
    }

    componentDidMount() {
        //This guid value is set from the LoanList View
        //The LoanCompare View uses that guid to display the information
        this.props.getSingleLoanFromWarehouse({ guid: this.props.pipelineInfo.selectedLoanGuid })
    };

    getComparisionFields(leftRev, rightRev, whatToCompare) {
        var diff = require('deep-diff').diff;
        var diffFields = [];
        var leftDoc = this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][leftRev - 1]
        var rightDoc = this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][rightRev - 1]
        var leftSaveDate = leftDoc.savedDate;
        var differences = diff(leftDoc, rightDoc);

        if (differences) {
            for (var i = 0; i < differences.length; i++) {
                if (differences[i].path.length > 1) {
                    diffFields.push(differences[i].path[1])
                }
            }
        }
        var leftDocToDisplay = Object.keys(leftDoc.loanData).map((property, index) => {
            if (whatToCompare === 'ALL') {
                if (diffFields.indexOf(property) != -1) {
                    return (<p style={{ color: 'blue' }} key={'left' + index}>{property + ': ' + leftDoc.loanData[property]}</p>)
                }
                else {

                    return (<p key={'left' + index}>{property + ': ' + leftDoc.loanData[property]}</p>)
                }
            }
            if (whatToCompare === 'DIFF') {
                if (diffFields.indexOf(property) != -1) {
                    return (<p style={{ color: 'blue' }} key={'left' + index}>{property + ': ' + leftDoc.loanData[property]}</p>)
                }
            }
            if (whatToCompare === 'SAME') {
                if (!diffFields.indexOf(property) != -1) {
                    return (<p key={'left' + index}>{property + ': ' + leftDoc.loanData[property]}</p>)
                }
            }
        });
        var rightDocToDisplay = Object.keys(rightDoc.loanData).map((property, index) => {
            if (whatToCompare === 'ALL') {
                if (diffFields.indexOf(property) != -1) {
                    return (<p style={{ color: 'blue' }} key={'right' + index}>{property + ': ' + rightDoc.loanData[property]}</p>)
                }
                else {
                    return (<p key={'right' + index}>{property + ': ' + rightDoc.loanData[property]}</p>)
                }
            }
            if (whatToCompare === 'DIFF') {
                if (diffFields.indexOf(property) != -1) {
                    return (<p style={{ color: 'blue' }} key={'right' + index}>{property + ': ' + rightDoc.loanData[property]}</p>)
                }
            }
            if (whatToCompare === 'SAME') {
                if (!diffFields.indexOf(property) != -1) {
                    return (<p key={'right' + index}>{property + ': ' + rightDoc.loanData[property]}</p>)
                }
            }
        });


        this.setState({ differentFields: diffFields, leftDocToDisplay: leftDocToDisplay, leftSaveDate: leftDoc.savedDate, rightDocToDisplay: rightDocToDisplay, rightSaveDate: rightDoc.savedDate, })
    }


    handleWhatToCompareChange(val) {
        this.getComparisionFields(this.state.leftSelection, this.state.rightSelection, val)
        this.setState({ whatToCompare: val });
    }

    changeLeftSelection(event) {
        this.getComparisionFields(event.target.value, this.state.rightSelection, this.state.whatToCompare)
        this.setState({ leftSelection: event.target.value })
    }
    getLeftSelection() {
        var options = [];
        if (this.props.pipelineInfo.singleLoanFromWarehouse) {
            for (var i = 1; i < this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'].length + 1; i++) {
                options.push(<option key={i} value={i}>{this.convertDateTime(this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][i - 1].savedDate)}</option>)
            }
            return (<select onChange={this.changeLeftSelection} value={this.state.leftSelection}>
                {options}
            </select>)
        }
    }
    changeRightSelection(event) {
        this.getComparisionFields(this.state.leftSelection, event.target.value, this.state.whatToCompare)
        this.setState({ rightSelection: event.target.value })
    }
    getRightSelection() {
        var options = [];
        if (this.props.pipelineInfo.singleLoanFromWarehouse) {
            for (var i = 1; i < this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'].length + 1; i++) {
                options.push(<option key={i} value={i}>{this.convertDateTime(this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][i - 1].savedDate)}</option>)
            }
            return (<select onChange={this.changeRightSelection} value={this.state.rightSelection}>
                {options}
            </select>)
        }
    }
    convertDateTime(dateTime) {
        var formattedDateTime = ''
        var date = new Date(dateTime);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        var second = date.getSeconds();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        if (minute < 10) {
            minute = '0' + minute;
        }
        return month + '-' + dt + '-' + year + ' ' + hour + ':' + minute;

    }

    render() {
        if ((this.props.pipelineInfo.getSingleLoanFromWarehouseStatus === 'SUCCEEDED') && !this.state.gotCompStatus) {
            this.getComparisionFields(1, 1, this.state.whatToCompare)
            this.setState({ gotCompStatus: true })
        }
        if (this.props.pipelineInfo.getSingleLoanFromWarehouseStatus === 'ERRORED') {
            alert(this.props.pipelineInfo.getSingleLoanFromWarehouseErrorMessage)
            this.props.goToAnotherPage('/loanList')
        }
console.log(this.props.pipelineInfo.singleLoanFromWarehouse)
        var leftDropdown = this.getLeftSelection();
        var rightDropDown = this.getRightSelection();
        return (
            <div>
                {this.props.pipelineInfo.getSingleLoanFromWarehouseStatus === 'NOT_STARTED' &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}
                {(this.props.pipelineInfo.getSingleLoanFromWarehouseStatus === 'SUCCEEDED') &&
                    <div>
                        <Grid>
                            <Row>
                                <h1>{this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][0]['loanData']['BorrowerFirstName'] + ' ' + this.props.pipelineInfo.singleLoanFromWarehouse[0]['saveState'][0]['loanData']['BorrowerLastName']}</h1>
                            </Row>
                            <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <button className="btn btn-primary" onClick={() => this.handleWhatToCompareChange('ALL')}>All</button>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <button className="btn btn-warning" onClick={() => this.handleWhatToCompareChange('DIFF')}>Diff</button>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <button className="btn btn-success" onClick={() => this.handleWhatToCompareChange('SAME')}>Same</button>
                                </Col>
                            </Row>
                            <Row>
                                <h2>Showing: {this.state.whatToCompare}</h2>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <h3>Choose Revision to Compare</h3>
                                    {'Saved:' + this.convertDateTime(this.state.leftSaveDate) + '   '}

                                    {leftDropdown}
                                    {this.state.leftDocToDisplay}
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <h3>Choose Revision to Compare</h3>
                                    {'Saved:' + this.convertDateTime(this.state.rightSaveDate) + '   '}
                                    {rightDropDown}
                                    {this.state.rightDocToDisplay}
                                </Col>
                            </Row>
                        </Grid>
                    </div>}
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSingleLoanFromWarehouse: getSingleLoanFromWarehouse,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoanRevComparisonView);
