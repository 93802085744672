import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeLocation } from '../actions/globalActions';
import { initialize, loginUser } from '../actions/loginActions';
import { resetPipeline } from '../actions/pipelineActions';
import { getLoanOriginators, getAllEmployees, resetAllEmployeeData } from '../actions/employeeActions';
import { Form, Icon, Message } from 'semantic-ui-react';



class LoginContainer extends Component {
    constructor(props) {
        super();
        this.state = {
            username: '',
            password: '',
            loading: false,
            cookieCheck: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.gotEmployeeGetCookie = this.gotEmployeeGetCookie.bind(this);
    }
    componentWillMount() {
        this.props.initialize();

    }
    componentDidMount() {
        this.props.resetPipeline();
        this.props.resetAllEmployeeData();
        this.props.getLoanOriginators();
        this.props.getAllEmployees();
        //GetCookies on 
        this.gotEmployeeGetCookie();
    }

    gotEmployeeGetCookie() {
        //Get Cookie from Intranet if applicable
        var employeeIdFromCookie = parseInt(document.cookie.split('=')[1]);

        //When we actually want to logout via the menu sidebar we should get the parameter that is passed in from the goToAnotherPage function
        if (!isNaN(employeeIdFromCookie) && Object.keys(this.props.employeeInfo.allEmployeesData).length > 0 && !this.props.location.fromLogout && (document.referrer.indexOf('https://vdmc.net') != -1 || document.referrer.indexOf('https://www.vdmc.net') != -1)) {
            //Get Id from post variable
            var currentEmployee = this.props.employeeInfo.allEmployeesData.filter(function (item) { return (item.itInfo.oldEmployeeId == employeeIdFromCookie); })[0];
            var employeeId = currentEmployee.itInfo.ADUsername
            var loginParams = {
                username: currentEmployee.itInfo.ADUsername,
                employeeId: employeeId
            }
            this.props.loginUser(loginParams);
        }
        else if (this.props.employeeInfo.allEmployeesData && Object.keys(this.props.employeeInfo.allEmployeesData).length > 0) {
            this.setState({ cookieCheck: true })
        }

    }


    handleSubmit(event) {
        event.preventDefault();

        var loginParams = {
            username: this.state.username,
            password: this.state.password,



        }
        this.props.loginUser(loginParams);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    render() {
        //Check to see if list of employees has been retrieved so that we can check the cookie
        if (!this.state.cookieCheck) {
            setTimeout(this.gotEmployeeGetCookie, 1000);
        }

        if (this.props.loginInfo.athenaLoginStatus === 'SUCCEEDED') {
            this.setState({ loading: true })
            this.props.goToAnotherPage({
                pathname: '/leaderboard'
            })
        }
        return (
            <div className="content container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">

                        {this.state.cookieCheck && <div className="center">

                            <img src="images/athena_v2.png" height="155px" alt='athena img' />
                            <h1>Athena Online</h1>
                            <h4>Business Intelligence and Analytics</h4>
                            <div className="col-md-6 col-md-offset-3">
                                <form className="login-form" id="login-form" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <br />
                                        <br />

                                    </div>

                                    <p style={{ color: 'red' }}>{this.props.loginInfo.athenaLoginErrorMessage}</p>
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="username">Username</label>
                                        <input className='form-control ' id='username' type='text' name='username' placeholder='Your Username' value={this.state.username} onChange={this.handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="password">Password</label>
                                        <input className='form-control ' id='passwords' type='password' name='password' placeholder='Your Password' value={this.state.password} onChange={this.handleInputChange} />
                                    </div>
                                    <button type="submit" className="btn btn-primary form-control">Login</button>

                                </form>
                                {this.props.loginInfo.athenaLoginStatus === 'STARTED' && <Form success size='large' key='large'>
                                    <Message icon  >
                                        <Icon name='circle notched' loading />
                                    </Message>
                                </Form>}
                            </div>
                        </div>}
                        {!this.state.cookieCheck && <Form success size='large' key='large'>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                            </Message>
                        </Form>}

                    </div>
                </div>
            </div>
        )

    }
}


function mapStateToProps(state, ownProps) {
    return {
        cookies: ownProps.cookies,
        loginInfo: state.loginReducer,
        employeeInfo: state.employeeReducer,

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeLocation: changeLocation,
            resetPipeline: resetPipeline,
            loginUser: loginUser,
            initialize: initialize,
            getLoanOriginators: getLoanOriginators,
            getAllEmployees: getAllEmployees,
            resetAllEmployeeData: resetAllEmployeeData,

            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
