// Static Data

// Initial Data
const initialState = {
  getPipelineStatus: 'NOT_STARTED',
  getPipelineErrorMessage: '',
  getPipelineReportStatus: 'NOT_STARTED',
  getClosingReportStatus: 'NOT_STARTED',
  getClosingReportErrorMessage: '',
  getBankReportStatus: 'NOT_STARTED',
  getBankReportErrorMessage: '',
  getDashboardReportStatus: 'NOT_STARTED',
  getDashboardReportErrorMessage: '',
  getPipelineReportErrorMessage: '',
  getPipelineNumbersReportStatus: 'NOT_STARTED',
  getPipelineNumbersReportErrorMessage: '',
  getPipelineLeaderboardStatus: 'NOT_STARTED',
  getPipelineLeaderboardErrorMessage: '',
  getTrendsStatus: 'NOT_STARTED',
  getTrendsErrorMessage: '',
  getFundingsStatus: 'NOT_STARTED',
  getFundingsErrorMessage: '',
  getLoanCompareStatus: 'NOT_STARTED',
  getLoanCompareErrorMessage: '',
  getSingleLoanFromWarehouseStatus: 'NOT_STARTED',
  getSingleLoanFromWarehouseErrorMessage: '',
  singleLoanFromWarehouse: {},
  getMultipleLoansFromWarehouseStatus: 'NOT_STARTED',
  getMultipleLoansFromWarehouseErrorMessage: '',
  multipleLoansFromWarehouse: [],
  getSingleLoanFromEncompassStatus: 'NOT_STARTED',
  getSingleLoanFromEncompassErrorMessage: '',
  singleLoanFromEncompass: {},
  getLoanAuditStatus: 'NOT_STARTED',
  getLoanAuditErrorMessage: '',
  selectedLoanGuid: '',
  errorMessage: '',
  pipelineData: [],
  warehousePipelineData: [],
  loanCompareDataList: [],
  pipelineReportData: {},
  bankReportData: {},
  dashboardReportData: {},
  pipelineLeaderboardData: {},
  trendData: [],
  fundingsData: [],
  auditData: [],
  progress: 50,
  progrssText: '',
  location: '',
  currentProcessor: '',
  currentProcessorTouches: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LOCATION':
      return {
        ...state,
        location: action.location
      }
    case 'RESET_PIPELINE_STATUS':
      return {
        ...state,
        getPipelineStatus: 'NOT_STARTED',
        getPipelineErrorMessage: '',
        getPipelineReportStatus: 'NOT_STARTED',
        getBankReportStatus: 'NOT_STARTED',
        getBankReportErrorMessage: '',
        getDashboardReportStatus: 'NOT_STARTED',
        getDashboardReportErrorMessage: '',
        getPipelineReportErrorMessage: '',
        getPipelineNumbersReportStatus: 'NOT_STARTED',
        getPipelineNumbersReportErrorMessage: '',
        // getPipelineLeaderboardStatus: 'NOT_STARTED',
        // getPipelineLeaderboardErrorMessage: '',
        getTrendsStatus: 'NOT_STARTED',
        getTrendsErrorMessage: '',
        getFundingsStatus: 'NOT_STARTED',
        getFundingsErrorMessage: '',
        getLoanCompareStatus: 'NOT_STARTED',
        getLoanCompareErrorMessage: '',
        getSingleLoanFromWarehouseStatus: 'NOT_STARTED',
        getSingleLoanFromWarehouseErrorMessage: '',
        getMultipleLoansFromWarehouseStatus: 'NOT_STARTED',
        getMultipleLoansFromWarehouseErrorMessage: '',
        multipleLoansFromWarehouse: [],
        getLoanAuditStatus: 'NOT_STARTED',
        getLoanAuditErrorMessage: '',
        errorMessage: '',
        pipelineData: [],
        // pipelineLeaderboardData: {},
        warehousePipelineData: [],
        loanCompareDataList: [],
        pipelineReportData: {},
        bankReportData: {},
        dashboardReportData: {},
        trendData: [],
        progress: 50,
        progrssText: '',
        location: '',
        currentProcessor: '',
        currentProcessorTouches: 0
      }
    case 'UPDATE_PROGRESS':
    {
      return {
        ...state,
        progress: action.progress,
        progressText: action.progressText
      }
    }
    // GET PIPELINE SCENARIOS
    case 'GET_PIPELINE_STARTED':
      return {
        ...state,
        getPipelineStatus: 'STARTED',
        progress: 15
      }
    case 'GET_PIPELINE_SUCCEEDED':
      return {
        ...state,
        getPipelineStatus: 'SUCCEEDED',
        pipelineData: action.pipelineData,
        warehousePipelineData: action.warehousePipelineData,
        progress: 75
      }
    case 'ENCOMPASS_CONNECTION_ERRORED':
      return {
        ...state,
        getPipelineStatus: 'ERRORED',
        getPipelineErrorMessage: action.getPipelineErrorMessage
      }
    case 'ENCOMPASS_LOGIN_FAILED':
      return {
        ...state,
        getPipelineStatus: 'FAILED',
        getPipelineErrorMessage: action.getPipelineErrorMessage
      }
    // GET CLOSING REPORT SCENARIOS
    case 'GET_CLOSING_REPORT_STARTED':
      return {
        ...state,
        getClosingReportStatus: 'STARTED'
      }
    case 'GET_CLOSING_REPORT_SUCCEEDED':
      return {
        ...state,
        getClosingReportStatus: 'SUCCEEDED',
        closingReportData: action.closingReportData
      }
    // GET PIPELINE LEADERBOARD SCENARIOS
    case 'GET_PIPELINE_LEADERBOARD_STARTED':
      return {
        ...state,
        getPipelineLeaderboardStatus: 'STARTED',
        progress: 15
      }
    case 'GET_PIPELINE_LEADERBOARD_SUCCEEDED':
      return {
        ...state,
        getPipelineLeaderboardStatus: 'SUCCEEDED',
        pipelineLeaderboardData: action.pipelineLeaderboardData
      }
    case 'GET_PIPELINE_LEADERBOARD_ERRORED':
      return {
        ...state,
        getPipelineLeaderboardStatus: 'ERRORED',
        getPipelineLeaderboardErrorMessage: action.getPipelineLeaderboardErrorMessage
      }
    // GET BANK REPORT SCENARIOS
    case 'GET_BANK_REPORT_STARTED':
      return {
        ...state,
        getBankReportStatus: 'STARTED',
        progress: 15
      }
    case 'GET_BANK_REPORT_SUCCEEDED':
      return {
        ...state,
        getBankReportStatus: 'SUCCEEDED',
        bankReportData: action.bankReportData
      }
    // GET DASHBOARD SCENARIOS
    case 'GET_PIPELINE_DASHBOARD_STARTED':
      return {
        ...state,
        getDashboardStatus: 'STARTED',
        progress: 15
      }
    case 'GET_PIPELINE_DASHBOARD_SUCCEEDED':
      return {
        ...state,
        getDashboardStatus: 'SUCCEEDED',
        dashboardReportData: action.dashboardReportData
      }
    case 'GET_PIPELINE_DASHBOARD_ERRORED':
      return {
        ...state,
        getDashboardStatus: 'ERRORED',
        getDashboardErrorMessage: action.getDashboardErrorMessage
      }
    // GET PIPELINE REPORT SCENARIOS
    case 'GET_PIPELINE_REPORT_STARTED':
      return {
        ...state,
        getPipelineReportStatus: 'STARTED',
        progress: 15
      }
    case 'GET_PIPELINE_REPORT_SUCCEEDED':
      return {
        ...state,
        getPipelineReportStatus: 'SUCCEEDED',
        pipelineReportData: action.pipelineReportData
      }
    case 'ENCOMPASS_CONNECTION_ERRORED_REPORT':
      return {
        ...state,
        getPipelineReportStatus: 'ERRORED',
        getPipelineReportErrorMessage: action.getPipelineReportErrorMessage
      }
    case 'ENCOMPASS_LOGIN_FAILED_REPORT':
      return {
        ...state,
        getPipelineReportStatus: 'FAILED',
        getPipelineReportErrorMessage: action.getPipelineReportErrorMessage
      }
    // GET PIPELINE NUMBERS REPORT SCENARIOS
    case 'GET_PIPELINE_NUMBERS_REPORT_STARTED':
      return {
        ...state,
        getPipelineNumbersReportStatus: 'STARTED',
        progress: 10
      }
    case 'GET_PIPELINE_NUMBERS_REPORT_SUCCEEDED':
      return {
        ...state,
        getPipelineNumbersReportStatus: 'SUCCEEDED',
        pipelineNumbersReportData: action.pipelineNumbersReportData
      }
    case 'GET_PIPELINE_NUMBERS_REPORT_FAILED':
      return {
        ...state,
        getPipelineNumbersReportStatus: 'FAILED',
        getPipelineNumbersReportErrorMessage: action.getPipelineNumbersReportErrorMessage
      }
    case 'ENCOMPASS_CONNECTION_ERRORED_NUMBERS_REPORT':
      return {
        ...state,
        getPipelineStatus: 'ERRORED',
        getPipelineNumbersReportErrorMessage: action.getPipelineNumbersReportErrorMessage
      }
    // GET TREND SCENARIOS
    case 'GET_TRENDS_STARTED':
      return {
        ...state,
        getTrendsStatus: 'STARTED',
        progress: 15
      }
    case 'GET_TRENDS_SUCCEEDED':
      return {
        ...state,
        getTrendsStatus: 'SUCCEEDED',
        trendData: action.trendData
      }
    case 'GET_TRENDS_ERRORED':
      return {
        ...state,
        getTrendsStatus: 'ERRORED',
        getTrendsErrorMessage: action.getTrendsErrorMessage
      }
    // GET FUNDINGS SCENARIOS
    case 'GET_FUNDINGS_STARTED':
      return {
        ...state,
        getFundingsStatus: 'STARTED',
        progress: 15
      }
    case 'GET_FUNDINGS_SUCCEEDED':
      return {
        ...state,
        getFundingsStatus: 'SUCCEEDED',
        fundingsData: action.fundingsData
      }
    case 'GET_FUNDINGS_ERRORED':
      return {
        ...state,
        getFundingsStatus: 'ERRORED',
        getFundingsErrorMessage: action.getFundingsErrorMessage
      }
    // GET LOAN COMPARE SCENARIOS
    case 'GET_LOAN_COMPARE_LIST_STARTED':
      return {
        ...state,
        getLoanCompareStatus: 'STARTED'
      }
    case 'GET_LOAN_COMPARE_LIST_SUCCEEDED':
      return {
        ...state,
        getLoanCompareStatus: 'SUCCEEDED',
        loanCompareDataList: action.loanCompareDataList
      }
    case 'ENCOMPASS_CONNECTION_ERRORED_LOAN_COMPARE':
      return {
        ...state,
        getLoanCompareStatus: 'ERRORED',
        getLoanCompareErrorMessage: action.getLoanCompareErrorMessage
      }
    case 'ENCOMPASS_LOGIN_FAILED_LOAN_COMPARE':
      return {
        ...state,
        getLoanCompareStatus: 'FAILED',
        getLoanCompareErrorMessage: action.getLoanCompareErrorMessage
      }
    // GET SINGLE_LOAN_FROM_WAREHOUSE SCENARIOS
    case 'GET_SINGLE_LOAN_FROM_WAREHOUSE_STARTED':
      return {
        ...state,
        getSingleLoanFromWarehouseStatus: 'STARTED'
      }
    case 'GET_SINGLE_LOAN_FROM_WAREHOUSE_SUCCEEDED':
      return {
        ...state,
        getSingleLoanFromWarehouseStatus: 'SUCCEEDED',
        singleLoanFromWarehouse: action.singleLoanFromWarehouse
      }
    case 'GET_SINGLE_LOAN_FROM_WAREHOUSE_ERRORED':
      return {
        ...state,
        getSingleLoanFromWarehouseStatus: 'ERRORED',
        getSingleLoanFromWarehouseErrorMessage: action.getSingleLoanFromWarehouseErrorMessage
      }
    case 'SET_SINGLE_LOAN_GUID':
      return {
        ...state,
        selectedLoanGuid: action.payload.guid
      }
    // GET_MULTIPLE_LOANS_FROM_WAREHOUSE SCENARIOS
    case 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_STARTED':
      return {
        ...state,
        getMultipleLoansFromWarehouseStatus: 'STARTED'
      }
    case 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_SUCCEEDED':
      return {
        ...state,
        getMultipleLoansFromWarehouseStatus: 'SUCCEEDED',
        multipleLoansFromWarehouse: action.multipleLoansFromWarehouse
      }
    case 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_ERRORED':
      return {
        ...state,
        getMultipleLoansFromWarehouseStatus: 'ERRORED',
        getMultipleLoansFromWarehouseErrorMessage: action.getMultipleLoansFromWarehouseErrorMessage
      }
    // This is for the Loan Processor View to allow
    // us to set the current processor gloabally
    // to see if there is a change.
    case 'SET_CURRENT_LOAN_PROCESSOR':
      return {
        ...state,
        currentProcessor: action.currentProcessor,
        currentProcessorTouches: action.currentProcessorTouches
      }
    // GET SINGLE_LOAN_FROM_ENCOMPASS SCENARIOS
    case 'GET_SINGLE_LOAN_FROM_ENCOMPASS_STARTED':
      return {
        ...state,
        getSingleLoanFromEncompassStatus: 'STARTED'
      }
    case 'GET_SINGLE_LOAN_FROM_ENCOMPASS_SUCCEEDED':
      return {
        ...state,
        getSingleLoanFromEncompassStatus: 'SUCCEEDED',
        singleLoanFromEncompass: action.singleLoanFromEncompass
      }
    case 'GET_SINGLE_LOAN_FROM_ENCOMPASS_ERRORED':
      return {
        ...state,
        getSingleLoanFromEncompassStatus: 'ERRORED',
        getSingleLoanFromEncompassErrorMessage: action.getSingleLoanFromEncompassErrorMessage
      }
    // GET LOAN_AUDIT SCENARIOS
    case 'GET_LOAN_AUDIT_RESET':
      return {
        ...state,
        getLoanAuditStatus: 'NOT_STARTED'
      }
    case 'GET_LOAN_AUDIT_STARTED':
      return {
        ...state,
        getLoanAuditStatus: 'STARTED'
      }
    case 'GET_LOAN_AUDIT_SUCCEEDED':
      return {
        ...state,
        getLoanAuditStatus: 'SUCCEEDED',
        auditData: action.auditData
      }
    case 'GET_LOAN_AUDIT_ERRORED_WAREHOUSE':
      return {
        ...state,
        getLoanAuditStatus: 'ERRORED',
        getLoanAuditErrorMessage: action.getLoanAuditErrorMessage
      }
    case 'GET_LOAN_AUDIT_ERRORED_ENCOMPASS':
      return {
        ...state,
        getLoanAuditStatus: 'ERRORED',
        getLoanAuditErrorMessage: action.getLoanAuditErrorMessage
      }
    case 'GET_LOAN_AUDIT_ERRORED':
      return {
        ...state,
        getLoanAuditStatus: 'ERRORED',
        getLoanAuditErrorMessage: action.getLoanAuditErrorMessage
      }
    default:
      return state
  }
}
