import React, { Component } from 'react';
import { Col, Grid, Row, Panel, Table, Button } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import Dropzone from 'react-dropzone';
import * as FontAwesome from 'react-icons/lib/fa';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class AddEmployeeModal extends Component {
    constructor(props) {
        super();
        this.state = {

            subject: "",
            recipients: "",
            cc: "",
            body: "",
            emailID: "",
            files:{}

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleQuillChange = this.handleQuillChange.bind(this)
        this.makeid = this.makeid.bind(this);
        this.onDrop = this.onDrop.bind(this)
    }
    componentDidMount() {

    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    handleSubmit(event) {


        event.preventDefault();
        var emailData = {
            subject: this.state.subject,
            body:this.state.body,
            recipients: this.state.recipients,
            cc: this.state.cc,
            emailID: this.makeid(),
            files:this.state.files,
            collection: 'emailTemplates'
        };
         this.props.upsertHRData(emailData)

        //Clears form on Submit
        this.setState({
            subject: '',
            body: '',
            recipients: '',
            cc: '',
            emailID: '',
            collection: ''
            ,
            files:{}
        });

        this.props.closeModal()

        return;

    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }


    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }
    handleQuillChange(value) {
        this.setState({ body: value })
      }
      generatePreviews() {
        var filesPreview = [];
        if (this.state.files) {
            Object.keys(this.state.files).forEach((element) => {
                filesPreview.push(
                    <tr>
                        <td><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                        <td><b style={{ fontSize: '1.25rem' }}>{this.formatBytes(this.state.files[element].size, 2)}</b></td>
                        <td><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                            <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                        </Button></td>
                    </tr>
                )
            })
        }

        return filesPreview;
    }
    removePreview(file) {
        var files = this.state.files;
        var totalFileSize = this.state.totalFileSize;
        totalFileSize = totalFileSize - files[file].size
        delete files[file];

        this.setState({ files: files, totalFileSize: totalFileSize })
    }
    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                var files = that.state.files;
                var totalFileSize = that.state.totalFileSize
                totalFileSize = totalFileSize + file.size

                if (file.size > 11000000) {
                    alert('File size exceeds 10MB.')
                }
                else {

                    files[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };


                    that.setState({ files: files, totalFileSize: totalFileSize })
                }
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }
    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    render() {
        var attachedPreviews = this.generatePreviews();
        return (
            <Modal
                        isOpen={this.props.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.props.closeModal}
                        style={addModalStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>

                            <div className='try - registration - form'
                                style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                                    <span aria-hidden="false">&times;</span>
                                </button>
                                <h1 style={{ float: 'left' }}>Edit Email Template</h1>
                                <br />
                                <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                            <Grid>
                                <Row>
                                    <Col md={12} sm={12}>
                                        <h3>Subject</h3>
                                        <input className='form-control' id='subject' type='text' name='subject' placeholder='Subject' value={this.state.subject} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col md={6} sm={12}>
                                        <h3>To</h3>
                                        <input className='form-control' id='recipients' type='text' name='recipients' placeholder="recipients" value={this.state.recipients} onChange={this.handleInputChange} />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <h3>CC:</h3>
                                        <input className='form-control' id='cc' type='text' name='cc' placeholder="cc" value={this.state.cc} onChange={this.handleInputChange} />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                            <Col md={12} sm={12}>
                                                <h3>Attachments:</h3>
                                                <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                                    <div>
                                                        <br />
                                                        <a> <h2>Click here or Drag in required documents</h2></a>
                                                        <h5>Combined File Size must be less than 10MB</h5>
                                                        <br />
                                                    </div>
                                                </Dropzone>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col style={{ paddingLeft: 40 }}>
                                                <h2><b>AttachedFiles:</b></h2>
                                                <Table responsive style={{ alignSelf: 'left' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Size</th>
                                                            <th> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {attachedPreviews}
                                                    </tbody>
                                                </Table>

                                            </Col>
                                        </Row>
                                    <ReactQuill value={this.state.body}
                                        onChange={this.handleQuillChange} />
                            </Grid>
                            <br />
                            <button className='form-control btn btn-success'>Save Changes</button>
                        </form>
                                <br />

                                <div id='error'></div>
                            </div>
                        </div>
                    </Modal>    
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeModal);
