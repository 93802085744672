// Import Datepicker
import { DatePickerInput } from 'rc-datepicker'
// Import the default style
import 'rc-datepicker/lib/style.css'
import React, { Component } from 'react'
import { Col, Grid, ProgressBar, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import windowSize from 'react-window-size'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { bindActionCreators } from 'redux'
import { logoutUser } from '../../../actions/loginActions'
import { getPipelineReport, resetPipeline } from '../../../actions/pipelineActions'
import { getTotalDollars } from '../../../helpers/getDollars'
import { getReportByDayRange, getReportByIntervalRange } from '../../../helpers/getLoans'
import LeaderTableDetailModal from '../../modals/pipeline_modals/LeaderTableDetailModal'
import LinkAppModal from '../../modals/general_modals/LinkAppModal'
import ReportTable from '../../tables/pipeline_tables/ReportTable'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

// const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

const graphColors = ['#045eb7', '#2c7883', '#2d973c', '#bd9c18', '#9f5813', '#b0290e', '#bd3776', '#8c29a1']

const date = new Date()
const curMonth = date.getMonth() + 1
const curDay = date.getDate()
const curYear = date.getFullYear()
const currentDate = curMonth + '/' + curDay + '/' + curYear

const Sugar = require('sugar-date')
const startDate = Sugar.Date.create('1 Week ago').getMonth() + 1 + '/' + Sugar.Date.create('1 Week ago').getDate() + '/' + Sugar.Date.create('1 Week ago').getFullYear()

const reportType = {
  PreApplicationDate: ['745', 'Pre-Applications'],
  ApplicationDate: ['3142', 'Applications'],
  SubmittedToProcDate: ['LOG.MS.DATE.Processing', 'Submitted to Processing'],
  SubmittedToUWDate: ['2298', 'Submitted to Underwriting'],
  LockedDate: ['2149', 'Locked'],
  FundedDate: ['1997', 'Funded'],
  ResubmittalDate: ['Log.MS.DATE.Resubmittal', 'Resubmittals'],
  ShippedDate: ['2014', 'Shipped'],
  DecisionMadeDate: ['2301', 'Decisions Made (2301)'],
  // Decisions Made
  // Fields.2300 CreditApprovalDate Underwriting Credit Approval Date
  // Fields.2987 DeniedDate Underwriting Denied Date
  // Fields.2303 SuspendedDate Underwriting Suspended Date
  NewDecisionMadeDate: ['2301', 'Decisions Made (2300, 2987, or 2303)']
}

class ReportView extends Component {
  constructor (props) {
    super()

    this.state = {
      field: 'PreApplicationDate',
      dataLimitMessage: '',
      filterID: '745',
      filterText: 'Pre-Application',
      graphInterval: undefined,
      sortType: 'LOName',
      startDate: startDate,
      endDate: currentDate,
      graphData: (<h1>Table</h1>),
      labelFontSize: '.5rem',
      isLoading: false,
      leaderList: undefined,
      sortedData: undefined,
      hasError: false,
      showTable: false,
      showDetailModal: false,
      showLinkAppModal: false,
      tableView: '',
      isProcessed: false,
      showCount: false,
      noGraphData: false,
      countGraphData: (
        <div style={{ borderColor: 'white', borderWidth: '3px', borderStyle: 'solid' }}>
          <h1 style={{ textAlign: 'center' }}>Select Values to display on Graph</h1>
          <br />
        </div>),
      currentLoan: undefined,
      showGraph: false,
      gotPipeStatus: true,
      gotLinkStatus: false,
      chartWidth: 0,
      xAxisLabel: ''
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleSortChange = this.handleSortChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getLeaders = this.getLeaders.bind(this)
    this.hideTableView = this.hideTableView.bind(this)
    this.determineInterval = this.determineInterval.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.showDetailModal = this.showDetailModal.bind(this)
    this.hideDetailModal = this.hideDetailModal.bind(this)
    this.hideGraph = this.hideGraph.bind(this)
    this.isInRange = this.isInRange.bind(this)
    this.compareGraph = this.compareGraph.bind(this)
    this.getGraphLabelSize = this.getGraphLabelSize.bind(this)
    this.showLinkAppModal = this.showLinkAppModal.bind(this)
    this.hideLinkAppModal = this.hideLinkAppModal.bind(this)
    this.getAxisLabel = this.getAxisLabel.bind(this)
    this.logout = this.logout.bind(this)
  }

  componentDidMount () {
    // Checks to see if the user is linked
    if (this.props.loginInfo.encompassLink === 'LINKED') {
      this.setState({ showLinkAppModal: false })
    }

    this.setState({
      chartWidth: window.innerWidth,
      isLoading: false
    })

    // remove this on unmount
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions (event) {
    this.setState({
      chartWidth: event.target.innerWidth
    })
  }

  logout () {
    this.props.logoutUser()
    this.props.goToAnotherPage('/')
  }

  handleFieldChange (event) {
    // ANCHOR Modify Decisions Made Custom Report handleFieldChange
    this.setState({
      field: event.target.value,
      filterID: reportType[event.target.value][0],
      filterText: reportType[event.target.value][1],
      showTable: false,
      showGraph: false
    })
  }

  handleSortChange (event) {
    this.setState({ sortType: event.target.value, showTable: false, showGraph: false })
  }

  handleInputChange (event) {
    const target = event.target
    const name = target.name
    const value = target.value
    this.setState({
      [name]: value
    })
  }

  handleStartDateChange (date) {
    const Sugar = require('sugar-date')

    var yearsAgo5 = Sugar.Date.create('12/31/2012')

    if (date !== 'Invalid date' && yearsAgo5.getTime() < date.getTime()) {
      var newMonth = date.getMonth() + 1
      var newDate = date.getDate()
      var newYear = date.getFullYear()

      this.setState({ startDate: newMonth + '/' + newDate + '/' + newYear, startDateError: false })
    } else if (date !== 'Invalid date' && yearsAgo5.getTime() > date.getTime()) {
      this.before2013Error()
    } else {
      this.setState({ startDateError: true })
    }
  }

  handleEndDateChange (date) {
    const Sugar = require('sugar-date')

    var yearsAgo5 = Sugar.Date.create('12/31/2012')

    if (date !== 'Invalid date' && yearsAgo5.getTime() < date.getTime()) {
      var newMonth = date.getMonth() + 1
      var newDate = date.getDate()
      var newYear = date.getFullYear()

      this.setState({ endDate: newMonth + '/' + newDate + '/' + newYear, endDateError: false })
    } else if (date !== 'Invalid date' && yearsAgo5.getTime() > date.getTime()) {
      window.alert('Start Date selected is out of range.  Only Dates after 1/1/2013 can be used.')
    } else {
      this.setState({ endDateError: true })
    }
  }

  handleSubmit () {
    this.setState({ showTable: false, showGraph: false })

    if (this.state.endDateError || this.state.startDateError) {
      this.dateFormatError()
      return
    }

    if (Date.parse(this.state.startDate) > Date.parse(this.state.endDate)) {
      this.dateRangeError()
      return
    }

    var Sugar = require('sugar-date')

    let newDataStartDate = Sugar.Date.create('Yesterday')

    var newDataStartMonth = newDataStartDate.getMonth() + 1
    var newDataStartDay = newDataStartDate.getDate()
    var newDataStartYear = newDataStartDate.getFullYear()

    newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear

    var today = new Date()
    var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

    // ANCHOR Modify Decisions Made Custom Report pipelineParam
    var pipelineParam = {
      username: this.props.loginInfo.athenaUsername,
      filter: this.state.filterID,
      field: this.state.field,
      sort: this.state.sortType === 'Region' ? 'Branch' : this.state.sortType,
      startDate: this.state.startDate,
      newDataStartDate: newDataStartDate,
      endDate: this.state.endDate,
      todayDate: todayDate
    }

    if (this.state.field === 'NewDecisionMadeDate') {
      pipelineParam.forDecisionsMadeReport = true
    }

    this.props.getPipelineReport(pipelineParam)

    this.setState({ isLoading: true })
  }

  getAxisLabel (dateString, interval) {
    var dateStringParts = dateString.split('/')

    if (interval === 'Day') {
      return dateStringParts[0] + '/' + dateStringParts[1]
    } else if (interval === 'Week') {
      var lastSplit = dateStringParts[2].split('-')

      var lastDate = this.state.endDate.split('/')[1] === dateStringParts[3] ? parseInt(dateStringParts[3]) : parseInt(dateStringParts[3]) - 1

      if (dateStringParts[0] + '/' + dateStringParts[1] === lastSplit[1] + '/' + lastDate) {
        return dateStringParts[0] + '/' + dateStringParts[1]
      } else {
        return dateStringParts[0] + '/' + dateStringParts[1] + '-' + lastSplit[1] + '/' + lastDate
      }
    } else if (interval === 'Month' || interval === 'Year') {
      var startDate = dateString.split('-')[0]
      var startDateParts = startDate.split('/')
      var formattedStartDate = startDateParts[0] + '/' + startDateParts[1]

      var endDate = dateString.split('-')[1]
      var endDateParts = endDate.split('/')
      var formattedEndDate = endDateParts[0] + '/' + endDateParts[1] + '/' + endDateParts[2]

      if (interval === 'Month' && (endDateParts[1] === '1' && startDateParts[1] === '1')) {
        return startDateParts[0] + '/' + startDateParts[2]
      } else if (interval === 'Year' && (endDateParts[0] === '1' && startDateParts[0] === '1' && endDateParts[1] === '1' && startDateParts[1] === '1')) {
        return startDateParts[2]
      } else {
        return formattedStartDate + '-' + formattedEndDate
      }
    }
  }

  removeDuplicates (arr) {
    const uniqueArray = []

    const uniqueGuid = []

    for (let i = 0; i < arr.length; i++) {
      if (uniqueGuid.indexOf(arr[i].loanGuid) === -1) {
        uniqueGuid.push(arr[i].loanGuid)

        uniqueArray.push(arr[i])
      }
    }

    return uniqueArray
  }

  getLeaders (loanList) {
    var leaders = {}

    // Creates Object of LOs with their associated Loans
    if (loanList.length > 0) {
      loanList.map((loan) => {
        if (loan[this.state.sortType]) {
          if (leaders[loan[this.state.sortType]]) {
            leaders[loan[this.state.sortType]].push(loan)
          } else {
            leaders[loan[this.state.sortType]] = [loan]
          }
        } else if (leaders['Undefined ' + this.state.sortType]) {
          leaders['Undefined ' + this.state.sortType].push(loan)
        } else {
          leaders['Undefined ' + this.state.sortType] = [loan]
        }
      })
    }

    // Create Object that contains name, count of loans, total dollar value of each loan
    var finalLeaderInfo = {}

    Object.keys(leaders).map((leader) => {
      finalLeaderInfo[leader] = {
        count: leaders[leader].length,
        totalDollars: getTotalDollars(leaders[leader]),
        loans: leaders[leader]
      }
    })

    return finalLeaderInfo
  }

  hideTableView () {
    this.setState({ showTable: false })
  }

  hideGraph () {
    this.setState({ showGraph: false })
  }

  getGraphLabelSize (values) {
    if (Object.keys(this.state.sortedData).length <= 6 && values.length <= 2) {
      return '2rem'
    } else if (Object.keys(this.state.sortedData).length > 6 && values.length <= 2) {
      return '1.5rem'
    } else if (Object.keys(this.state.sortedData).length > 6 && values.length === 3) {
      return '1.5rem'
    } else if (Object.keys(this.state.sortedData).length <= 6 && values.length === 3) {
      return '1rem'
    } else {
      return '5'
    }
  }

  isInRange (from, to, dateTimeString) {
    if (dateTimeString) {
      var check = dateTimeString.split(' ')[0]

      var fDate, lDate, cDate
      fDate = Date.parse(from)
      lDate = Date.parse(to)
      cDate = Date.parse(check)

      if ((cDate < lDate && cDate >= fDate)) {
        return true
      }
      return false
    } else {
      return false
    }
  }

  compare (a, b) {
    if (a.date < b.date) { return -1 }
    if (a.date > b.date) { return 1 }
    return 0
  }

  compareGraph (values) {
    this.setState({ showGraph: true })

    if (values.length > 3) {
      this.setState({ graphMessage: 'Graphs that compare more than 3 selections may result in unreadable graphs.' })
    } else if (values.length === 0) {
      this.setState({
        noGraphData: true,
        countGraphData: (
          <div style={{ borderColor: 'white', borderWidth: '3px', borderStyle: 'solid' }}>
            <h1 style={{ textAlign: 'center' }}>
                            Select Values to display on Graph
            </h1>
            <br />
          </div>
        )
      })

      return
    }

    var newGraphData = []

    if (this.state.graphInterval !== 'Day') {
      Object.keys(this.state.sortedData).map((date, index) => {
        var from = date.split('-')[0]
        var to = date.split('-')[1]
        var newGraphDataPoint = {}

        newGraphDataPoint.date = this.getAxisLabel(date, this.state.graphInterval)

        values.map((sortVal) => {
          var tempArray = []

          this.props.pipelineInfo.pipelineReportData.map((loan) => {
            if (loan[this.state.sortType] === sortVal && this.isInRange(from, to, loan[this.state.field])) {
              tempArray.push(loan)
            } else if (loan[this.state.sortType] === undefined && this.isInRange(from, to, loan[this.state.field])) {
              tempArray.push(loan)
            }
          })

          newGraphDataPoint[sortVal] = tempArray.length
        })

        newGraphData.push(newGraphDataPoint)
      })

      // This is needed because of the how the data is being sorted
      newGraphData = newGraphData.sort().reverse()
    } else {
      Object.keys(this.state.sortedData).map((date, index) => {
        var newGraphDataPoint = {}

        newGraphDataPoint.date = this.getAxisLabel(date, this.state.graphInterval)

        values.map((sortVal) => {
          var tempArray = []

          this.props.pipelineInfo.pipelineReportData.map((loan) => {
            if (loan[this.state.sortType] === sortVal && date === loan[this.state.field]) {
              tempArray.push(loan)
            } else if (loan[this.state.sortType] === undefined && date === loan[this.state.field]) {
              tempArray.push(loan)
            }
          })

          newGraphDataPoint[sortVal] = tempArray.length
        })

        newGraphData.push(newGraphDataPoint)
      })
    }

    // Create Lines for Every Sort Type
    var lines = values.map((sortVal, index) => {
      var lineColor = ''

      // Want to start with the same colors for 5 values that are being compared, but after that we will need to dynamically generate colors
      if (graphColors[index]) {
        lineColor = graphColors[index]
      } else {
        lineColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
      }

      return (<Line key={index} type='monotone' dataKey={sortVal} stroke={lineColor} />)
    })

    // Determine X Axis Label
    var xAxisLabel = ''
    if (this.state.graphInterval === 'Day') {
      xAxisLabel = 'Day'
    } else if (this.state.graphInterval === 'Week') {
      xAxisLabel = 'Week Start Date'
    } else if (this.state.graphInterval === 'Month') {
      xAxisLabel = 'Month'
    } else if (this.state.graphInterval === 'Year') {
      xAxisLabel = 'Year'
    }

    this.setState({
      countGraphData: (
        <div>
          <ResponsiveContainer width='90%' aspect={3.0}>
            <LineChart
              data={newGraphData}
              margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
            >
              <XAxis dataKey='date' tickLine={false} stroke='#282828' />
              <YAxis tickLine={false} stroke='#282828' />
              <Tooltip animationEasing='ease-out' wrapperStyle={{ backgroundColor: '#d7d7d7' }} />
              {lines}
            </LineChart>
          </ResponsiveContainer>
          <h3 style={{ textAlign: 'center', color: '#282828' }}>{xAxisLabel}</h3>
        </div>)
    })
  }

  changeDateRange () {
    // Close Table if it is open
    this.setState({ showTable: false })

    var interval = this.determineInterval(this.state.startDate, this.state.endDate)
    this.setState({ graphInterval: interval })

    if (interval === 'Day') {
      var todayRange = getReportByDayRange(this.state.startDate, this.state.endDate, this.props.pipelineInfo.pipelineReportData, this.state.field)
      this.setState({ sortedData: todayRange })
    } else {
      var intervalRange = getReportByIntervalRange(this.state.startDate, this.state.endDate, this.props.pipelineInfo.pipelineReportData, this.state.field, interval)
      this.setState({ sortedData: intervalRange })
    }
  }

  // Dynamically determine the intervals for displaying the data
  determineInterval (startDate, endDate) {
    var startDateStringParts = startDate.split('/')
    var startDateObj = new Date(startDateStringParts[2], startDateStringParts[0] - 1, startDateStringParts[1])
    var startDateInMilli = startDateObj.getTime()

    var endDateStringParts = endDate.split('/')
    var endDateObj = new Date(endDateStringParts[2], endDateStringParts[0] - 1, endDateStringParts[1])
    var endDateInMilli = endDateObj.getTime()

    var range = endDateInMilli - startDateInMilli
    const twoWeeksMilli = 1209600000
    const twoMonthsMilli = 4838400000
    const oneYearMillli = 31449600000

    // If range is less than 2 weeks group by Day
    if (range < twoWeeksMilli) {
      return 'Day'
    } else if (range < twoMonthsMilli) {
      // If range is less than 2 months group by week
      return 'Week'
    } else if (range < oneYearMillli) {
      // If range is less than 1.5 years group by month
      return 'Month'
    } else {
      // If Range is greater than 1.5 years group by year
      return 'Year'
    }
  }

  hideDetailModal () {
    this.setState({ showDetailModal: false })
  }

  getDetailModal () {
    return (<LeaderTableDetailModal details={this.state.detailList} modalIsOpen={this.state.showDetailModal} closeModal={this.hideDetailModal} />)
  }

  showDetailModal (detailList) {
    this.setState({ showDetailModal: true, detailList: detailList })
  }

  hideLinkAppModal () {
    this.setState({ showLinkAppModal: false })
  }

  showLinkAppModal (detailList) {
    this.setState({ showLinkAppModal: true })
  }

  linkAppModal () {
    return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
  }

  dateFormatError () {
    confirmAlert({
      title: 'Uh Oh',
      message: 'Date Range invalid.  Ensure that dates are of the form MM/DD/YYYY.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => { }
        }

      ]
    })
  }

  dateRangeError () {
    confirmAlert({
      title: 'Uh Oh',
      message: 'Date Range invalid. Start Date is after end date.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => { }
        }

      ]
    })
  }

  before2013Error () {
    confirmAlert({
      title: 'Uh Oh',
      message: 'Start Date selected is out of range.  Only Dates after 1/1/2013 can be used.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => { }
        }

      ]
    })
  }

  render () {
    // Check Encompass Linkage
    if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
      this.setState({ gotLinkStatus: true, showLinkAppModal: false })
      // If Linked then lets make sure the token hasnt expired
    } else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
      this.props.goToAnotherPage('/login')
    } else if (!this.state.gotLinkStatus) {
      this.setState({ gotLinkStatus: true, showLinkAppModal: true })
    }

    // Sets SnapShot to Today by default and set gotPipeStatus to true
    if (this.props.pipelineInfo.getPipelineReportStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
      this.setState({ gotPipeStatus: true, isLoading: false })

      var leaderList = this.getLeaders(this.props.pipelineInfo.pipelineReportData)

      this.changeDateRange()

      this.setState({ leaderList: leaderList, showTable: true })
    } else if (this.props.pipelineInfo.getPipelineReportStatus === 'ERRORED' && !this.state.gotPipeStatus) {
      // Handle Failed/Errored Scenarios
      window.alert(this.props.pipelineInfo.getPipelineReportErrorMessage)

      this.setState({ gotPipeStatus: true })
    } else if (this.props.pipelineInfo.getPipelineReportStatus === 'FAILED' && !this.state.gotPipeStatus) {
      this.showLinkAppModal()

      this.setState({ gotPipeStatus: true })
    }

    // Reset gotPipeStatus to false
    if (this.props.pipelineInfo.getPipelineReportStatus === 'STARTED' && this.state.gotPipeStatus) {
      this.setState({ gotPipeStatus: false, isLoading: true })
    }

    var detailModal = this.getDetailModal()
    var linkAppModal = this.linkAppModal()

    return (
      <div>
        <Grid style={{ width: '90%', color: '#282828' }}>
          {detailModal}
          {linkAppModal}
          <br />
          {/* <Row style={{ paddingLeft: '15%', paddingRight: '15%' }}> */}
          <Col>
            <h3 style={{ fontSize: '2rem', textAlign: 'left' }}>Report Generator</h3>
          </Col>
          {/* </Row> */}
          <br />
          <Row>
            <Col md={6}>
              <h4>Start Date</h4>
              <DatePickerInput
                onChange={this.handleStartDateChange}
                value={this.state.startDate}
                className='my-custom-datepicker-component'
              />
            </Col>
            <Col md={6}>
              <h4>End Date</h4>
              <DatePickerInput
                onChange={this.handleEndDateChange}
                value={this.state.endDate}
                className='my-custom-datepicker-component'
              />
            </Col>
          </Row><br />
          <Row>
            <Col md={6}>
              <h4>Report Type</h4>
              <select className='form-control' onChange={this.handleFieldChange} value={this.state.field}>
                <option value='PreApplicationDate'>Pre-Applications</option>
                <option value='ApplicationDate'>Applications</option>
                <option value='SubmittedToProcDate'>Submitted to Processing</option>
                <option value='SubmittedToUWDate'>Submitted to Underwriting</option>
                <option value='LockedDate'>Locked</option>
                <option value='FundedDate'>Funded</option>
                <option value='ResubmittalDate'>Resubmittals</option>
                <option value='ShippedDate'>Shipped</option>
                <option value='DecisionMadeDate'>Decisions Made (2301)</option>
                <option value='NewDecisionMadeDate'>Decisions Made (2300, 2987, or 2303)</option>
              </select>
            </Col>
            <Col md={6}>
              <h4>Sort Type</h4>
              <select className='form-control' onChange={this.handleSortChange} value={this.state.sortType}>
                <option value='Team'>Team</option>
                <option value='Department'>Department</option>
                <option value='Region'>Region</option>
                <option value='LOName'>LO</option>
                <option value='Underwriter'>Underwriter</option>
                <option value='Shipper'>Shipper</option>
                <option value='Closer'>Closer</option>
                <option value='Processor'>Processor</option>
              </select>
            </Col>
          </Row><br />
          <Row>
            <Col md={12}>
              <button className='btn btn-success form-control' onClick={() => this.handleSubmit()}>Submit</button>
            </Col>
          </Row>
          <hr />
          <br />
          {!this.state.isLoading &&
            <div>
              {this.state.showGraph && this.state.countGraphData &&
                <div>
                  <Row style={{ flex: '1 0 0px', minHeight: 0, marginBottom: 0 }}>
                    <h2>{reportType[this.state.field][1]} by {this.state.sortType}</h2>
                  </Row>
                  <Row style={{ flex: '1 0 0px', minHeight: 0, marginBottom: 0 }}>
                    {this.state.countGraphData}
                  </Row>
                  <br />
                  <br />
                </div>}
            </div>}

          {this.state.showTable &&
            <ReportTable
              leaders={this.state.leaderList}
              columnHeader={this.state.sortType}
              reportType={this.state.filterText}
              hideTableView={this.hideTableView}
              showGraph={this.state.showGraph}
              hideGraph={this.hideGraph}
              compareGraph={this.compareGraph}
              field={this.state.field}
              showDetailModal={this.showDetailModal}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />}

          {this.state.isLoading &&
            <div style={{ width: '100%', paddingTop: '50px' }}>
              <Row>
                <ProgressBar active striped bsStyle='info' now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`} />
              </Row>
              <Row>
                <Col style={{ textAlign: 'center' }}>
                  <p style={{ textAlign: 'center' }}>
                    {this.props.pipelineInfo.progressText}
                  </p>
                </Col>
              </Row>
            </div>}
        </Grid>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    loginInfo: state.loginReducer,
    encompassInfo: state.encompassReducer,
    pipelineInfo: state.pipelineReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      getPipelineReport: getPipelineReport,
      resetPipeline: resetPipeline,
      logoutUser: logoutUser,
      goToAnotherPage: (params) => push(params)
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ReportView))
