import { put, takeEvery, call, race } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//Employees Saga
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
const watchHr = function* watchHr() {
    yield takeEvery("GET_ALL_HR_DATA", getAllHRData);
    yield takeEvery("UPSERT_HR_DATA", upsertHRData);
    yield takeEvery("DELETE_HR_DATA", deleteHRData);
};

const getAllHRData = function* getAllHRData(action) {
    yield put({ type: "GET_ALL_HR_STARTED" });
    try {
        var mongoConfig = {
            query: {}
        };

        const { mongoData } = yield race({
            mongoData: call(executeMongoFindDBQuery, action.payload.collection, mongoConfig),
            timeout: call(delay, 30000)
        })
        if (!mongoData) {
            yield put({ type: 'GET_ALL_HR_ERRORED', getAllHRDataErrorMessage: 'AO.HR.001: Warehouse DB timed out waiting for response for ' + action.payload.collection + '. Contact System Adminstrator.' })
            return;
        }
        if (action.payload.collection === 'regions') {
            yield put({ type: "GET_ALL_REGIONS_SUCCEEDED", allRegions: mongoData });
        }
        if (action.payload.collection === 'departments') {
            yield put({ type: "GET_ALL_DEPARTMENTS_SUCCEEDED", allDepartments: mongoData });
        }
        if (action.payload.collection === 'roles') {
            yield put({ type: "GET_ALL_ROLES_SUCCEEDED", allRoles: mongoData });
        }
        if (action.payload.collection === 'locations') {
            yield put({ type: "GET_ALL_LOCATIONS_SUCCEEDED", allLocations: mongoData });
        }
        if (action.payload.collection === 'employees') {
            yield put({ type: "GET_ALL_EMPLOYEES_SUCCEEDED", allEmployees: mongoData });
        }
        if (action.payload.collection === 'titles') {
            yield put({ type: "GET_ALL_TITLES_SUCCEEDED", allTitles: mongoData });
        }
        if (action.payload.collection === 'applicants') {
            yield put({ type: "GET_ALL_APPLICANTS_SUCCEEDED", allApplicants: mongoData });
            
        }
        if (action.payload.collection === 'emailTemplates') {
            yield put({ type: "GET_ALL_EMAILS_SUCCEEDED", allEmails: mongoData });
        }
       
        if (action.payload.collection === 'emailDistroGroups') {
            yield put({ type: "GET_ALL_EMAIL_DISTRO_GROUPS_SUCCEEDED", allEmailDistroGroups: mongoData });
        }
        if (action.payload.collection === 'credentials') {
          
            yield put({ type: "GET_ALL_CREDENTIALS_SUCCEEDED", allCredentials: mongoData });
        }
    } catch (error) {
        yield put({ type: "GET_ALL_HR_ERRORED", getAllHRDataErrorMessage: 'AO.HR.002: Unable to retrieve information from ' + action.payload.collection + '.  However, you may procceed with Athena Online' });
        return;
    }
};
const upsertHRData = function* upsertHRData(action) {

    yield put({ type: "UPSERT_HR_DATA_STARTED" });
    try {
        var mongoConfig = {};

        if (action.payload.collection === 'regions') {
            mongoConfig = {
                query: { "Region": action.payload.regionName },
                fieldsToSet: {
                    "$set": {
                        "Region": action.payload.regionName,
                        "Department": action.payload.departments
                    }
                }
            };
        }
        if (action.payload.collection === 'departments') {
            mongoConfig = {
                query: { "Name": action.payload.departmentName },
                fieldsToSet: {
                    "$set": {
                        "Name": action.payload.departmentName,
                        "Locations": action.payload.locations,
                        "Team": "",
                        "Region": ""
                    }
                }
            };
        }
        if (action.payload.collection === 'roles') {
            mongoConfig = {
                query: { "Role": action.payload.roleName },
                fieldsToSet: {
                    "$set": {
                        "Role": action.payload.roleName,
                        "Region": action.payload.regions
                    }
                }
            };
        }
        if (action.payload.collection === 'locations') {
            mongoConfig = {
                query: { "_id": action.payload.locationID },
                fieldsToSet: {
                    "$set": {
                        "address1": action.payload.address1,
                        "address2": action.payload.address2,
                        "city": action.payload.city,
                        "state": action.payload.state,
                        "zip": action.payload.zip,

                    }
                }
            };
        }
        if (action.payload.collection === 'employees') {
      
            mongoConfig = {
                query: { "_id": action.payload._id },
                fieldsToSet: {
                    "$set": action.payload.data
                }
            };
        }

        if (action.payload.collection === 'titles') {

            mongoConfig = {
                query: { "_id": action.payload.titleID },
                fieldsToSet: {
                    "$set": {
                        "Title": action.payload.title,


                    }
                }
            }
        }

        if (action.payload.collection === 'credentials') {

            mongoConfig = {
                query: { "_id": action.payload.credentialID },
                fieldsToSet: {
                    "$set": {
                        "Credential": action.payload.credential,


                    }
                }
            }
        }
        if (action.payload.collection === 'emailDistroGroups') {

            mongoConfig = {
                query: { "_id": action.payload.emailDistroGroupID },
                fieldsToSet: {
                    "$set": {
                        "DistroGroup": action.payload.emailDistroGroup,


                    }
                }
            }
        }
        if (action.payload.collection === 'emailTemplates') {



            mongoConfig = {
                query: { "_id": action.payload.emailID },
                fieldsToSet: {
                    "$set": {
                        "subject": action.payload.subject,
                        "body": action.payload.body,
                        "recipients": action.payload.recipients,
                        "cc": action.payload.cc,
                        "files":action.payload.files
                    }
                }
            }

           
        }
        if (action.payload.collection === 'applicants') {
            mongoConfig = {
                query: { "_id": action.payload._id },
                fieldsToSet: {
                    "$set":  action.payload.data
                }
            }
        }
        const { mongoData } = yield race({
            mongoData: call(executeMongoAddDBQuery, action.payload.collection, mongoConfig),
            timeout: call(delay, 30000)
        })

        if (!mongoData) {
            yield put({ type: 'UPSERT_HR_DATA_ERRORED', upsertHRDataMessage: 'AO.HR.003: Warehouse DB timed out waiting for response for ' + action.payload.collection + '. Contact System Adminstrator.' })
            return;
        }
        //Do stuff!
        yield put({ type: "UPSERT_HR_DATA_SUCCEEDED" });
        //Repopulate Data with changes
       // yield call(getAllHRData, action);

    } catch (error) {
        yield put({ type: "UPSERT_HR_DATA_ERRORED", upsertHRDataMessage: 'AO.HR.004: Unable to retrieve information from ' + action.payload.collection + '.  However, you may procceed with Athena Online' });
        return;

    }
}
const deleteHRData = function* deleteHRData(action) {
    yield put({ type: "DELETE_HR_DATA_STARTED" });
    try {

        var mongoConfig = {};

        if (action.payload.collection === 'regions') {
            mongoConfig = {
                query: { "Region": action.payload.regionName }
            };
        }
        if (action.payload.collection === 'departments') {
            mongoConfig = {
                query: { "Name": action.payload.departmentName }
            };
        }
        if (action.payload.collection === 'roles') {
            mongoConfig = {
                query: { "Role": action.payload.roleName }
            };
        }
        if (action.payload.collection === 'locations') {
            mongoConfig = {
                query: { "_id": action.payload.locationID }
            };
        }
        if (action.payload.collection === 'titles') {
            mongoConfig = {
                query: { "_id": action.payload.titleID }
            };
        }
        if (action.payload.collection === 'credentials') {
            mongoConfig = {
                query: { "_id": action.payload.credentialID }
            };
        }
        if (action.payload.collection === 'emailDistroGroups') {
            mongoConfig = {
                query: { "_id": action.payload.emailDistroGroupID }
            };
        }
        if (action.payload.collection === 'employees') {
            mongoConfig = {
                query: { "_id": action.payload.employeeID }
            };
        }
        if (action.payload.collection === 'emailTemplates') {
            mongoConfig = {
                query: { "_id": action.payload.emailID }
            };
        }
        console.log(action.payload)
        if (action.payload.collection === 'applicants') {
           
            mongoConfig = {
                query: { "_id": action.payload.applicantID }
            };
        }
        const { mongoData } = yield race({
            mongoData: call(executeMongoDeleteDBQuery, action.payload.collection, mongoConfig),
            timeout: call(delay, 30000)
        })

        if (!mongoData) {
            yield put({ type: 'DELETE_HR_DATA_ERRORED', deleteRegionErrorMessage: 'AO.HR.005: Warehouse DB timed out waiting for response for ' + action.payload.collection + '. Contact System Adminstrator.' })
            return;
        }
        //Do stuff!
        yield put({ type: "DELETE_HR_DATA_SUCCEEDED" });
        //Repopulate Data with changes
        yield call(getAllHRData, action);

    } catch (error) {
        yield put({ type: "DELETE_HR_DATA_ERRORED", deleteRegionErrorMessage: 'AO.HR.006: Unable to retrieve information from ' + action.payload.collection + '.  However, you may procceed with Athena Online' });
        return;
    }
}


///////////////////////////////////////////////////////////////////////////////////////////////////
//Database Functions
///////////////////////////////////////////////////////////////////////////////////////////////////
function executeMongoFindDBQuery(collection, configs) {

    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_find, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {

        return response.data;
    })

}

function executeMongoAddDBQuery(collection, configs) {
    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_upsert_one, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {

        return response.data;
    })

}

function executeMongoDeleteDBQuery(collection, configs) {

    return axios({
        method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_delete_one, headers: {
            'Content-type': 'application/json',
            'collection': collection
        }, data: configs
    }).then((response) => {

        return response.data;
    })

}

function delay(ms) {
    return new Promise(resolve => setTimeout(() => resolve(true), ms))
}
export default watchHr;