import React, { Component, } from 'react';
import { DatePickerInput } from 'rc-datepicker';
import { Col, Row, Checkbox, Grid, Button, Carousel, } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getActivities, addSubmission, resetActivities, upsertActivity, deleteActivity, uploadActivityImage, restartUploadActivityImage } from '../../actions/activityActions';
import 'react-quill/dist/quill.snow.css';
import Dropzone from 'react-dropzone';
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react';
import * as FontAwesome from 'react-icons/lib/fa';
import { Icon, Message } from 'semantic-ui-react';

import EmployeeDropdown from '../dropdowns/EmployeeDropdown';
import { CSSTransitionGroup } from 'react-transition-group';
Quill.register('modules/imageResize', ImageResize);


const modules = {
    imageResize: {
        parchment: Quill.import('parchment')
        // See optional "config" below
    },

    toolbar: [
        [{ 'header': [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link',],
        ['clean'],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
    ],
}
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'color', 'background', 'font', 'align'
]
const getMimetype = (signature) => {
    switch (signature) {
        case '89504E47':
            return 'image/png'
        case '47494638':
            return 'image/gif'
        case '25504446':
            return 'application/pdf'
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
            return 'image/jpeg'
        case '504B0304':
            return 'application/zip'
        default:
            return 'Unknown filetype'
    }
}

class FundingsView extends Component {
    constructor(props) {
        super();
        this.state = {
            minHeight: "600px",
            image: {},
            gotImage: false
        }
        this.onDrop = this.onDrop.bind(this)
        this.generatePreviews = this.generatePreviews.bind(this)
        this.uploadImage = this.uploadImage.bind(this)

    }


    generatePreviews() {
        var imagePreview = [];
        if (this.state.image) {
            Object.keys(this.state.image).forEach((element) => {
                imagePreview.push(
                    <tr>
                        <td width="20%"><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                        <td width="20%"><Button bsStyle="success" onClick={() => this.uploadImage(element)}>
                            <span style={{ color: '#fff', fontSize: 20 }} ><FontAwesome.FaUpload /> Upload Image</span>
                        </Button></td>
                        <td width="20%"><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                            <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /> Cancel</span>
                        </Button></td>
                    </tr>
                )
            })
        }

        return imagePreview;
    }
    removePreview(file) {
        var image = this.state.image;

        delete image[file];

        this.setState({ image: image })
    }
    uploadImage(file) {
        this.setState({ gotImage: false, image: {} })
        this.props.restartUploadActivityImage()
        var filetype = file.split('.')[1]

        this.props.uploadActivityImage({
            "string": "data:image/" + filetype + ";base64," + this.state.image[file].data
        })

    }
    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;

        if (acceptedFiles.length > 1) {
            console.log(acceptedFiles.length)
            that.imageError()
            that.setState({ image: {} })
            return
        }
        acceptedFiles.forEach(file => {
            var fileType = ''
            if (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
                fileType = 'jpeg'
            }
            if (file.name.endsWith('.png')) {
                fileType = 'png'
                console.log(file.name)
            }
          
            if (fileType != 'jpeg' && fileType != 'png') {
                that.imageTypeError()
                that.setState({ image: {} })
                return
            }
            const reader = new FileReader();
            reader.onload = () => {
                var image = {}
                image[file.name] = {
                    data: that.arrayBufferToBase64(reader.result),
                    size: file.size,


                };
                that.setState({ image: image })

            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    imageError = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Only one image can be used per post',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };
    imageTypeError = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Only png and jpg files can be used',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };
    render() {
        var attachedPreviews = this.generatePreviews();
        //Get Image when the status is SUCCEEDED and 
        if (this.props.activitiesInfo.uploadActivityImageStatus == 'SUCCEEDED' && !this.state.gotImage) {
            this.props.handleActivityImageChange(this.props.activitiesInfo.activityImageUrl)
            this.setState({ gotImage: true })

        }
        return (
            <Grid style={{ minHeight: this.setState.minHeight }}>
                <Row>
                    <Col md={6} xs={12}>
                        <Row>
                            <h3>Activity Name:</h3>
                        </Row>
                        <Row>
                            <input className='form-control' id='activityName' type='text' name='activityName' placeholder="Activity Name" value={this.props.activityName} onChange={this.props.handleInputChange} />
                        </Row>
                    </Col>
                    <Col mdOffset={1} md={4} xs={12}>
                        <Row >
                            <h3>Activity Point Value:</h3>
                        </Row>
                        <Row >
                            <input className='form-control' id='pointValue' type='text' name='pointValue' placeholder="Activity Point" value={this.props.pointValue} onChange={this.props.handleInputChange} />
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row>
                    <h3>Activity Description:</h3>
                </Row>
                <Row>
                    <ReactQuill theme="snow"
                        modules={modules}
                        formats={formats}
                        value={this.props.activityDescription}
                        onChange={this.props.handleActivityDescriptionChange} />
                </Row>
                <br />
                <Row>
                    <h3>Activity Image:</h3>
                </Row>
                {/* {this.props.activitiesInfo.uploadActivityImageStatus == 'STARTED' && <Row>
                    && <Icon name='circle notched' loading />
                </Row>} */}
                {this.props.activityImage && !this.props.activitiesInfo.activityImageUrl && <Row>
                    <img src={this.props.activityImage} mode='fit' />
                </Row>}
                {!this.props.activityImage && this.props.activitiesInfo.activityImageUrl && <Row>
                    <img src={this.props.activitiesInfo.activityImageUrl} mode='fit' />
                </Row>}
                {this.props.activityImage && this.props.activitiesInfo.activityImageUrl && <Row>
                    <img src={this.props.activitiesInfo.activityImageUrl} mode='fit' />
                </Row>}
                <br />
                <Row>
                    <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                        <div>
                            <br />
                            {!this.props.activityImage && <a> <h2>Click here or drag in image to use for this activity post</h2></a>}
                            {this.props.activityImage && <a> <h2>Click here or drag image to <strong>change</strong> image for this activity post</h2></a>}
                            <br />
                        </div>
                    </Dropzone>
                    <br />
                    {attachedPreviews}
                </Row>

                <br />
                <br />
                <Row>
                    <Col md={3} xs={12}>
                        <Row>
                            <h3>Created By:</h3>
                        </Row>
                        <Row>
                            <EmployeeDropdown setEmployeeValue={this.props.setCreatedByValue} value={this.props.createdByName} />
                        </Row>
                    </Col>
                    <Col mdOffset={1} md={3} xs={12}>
                        <Row>
                            <h3>Approval Needed By:</h3>
                        </Row>
                        <Row>
                            <EmployeeDropdown setEmployeeValue={this.props.setApprovedByValue} value={this.props.approvedByName} />
                        </Row>
                    </Col>
                    <Col mdOffset={1} md={3} xs={12}>
                        <Row>
                            <h3>Expiration Date:</h3>
                        </Row>
                        <Row>
                            <DatePickerInput
                                onChange={this.props.handleExpirationDateChange}
                                value={this.props.expirationDate}
                                className='my-custom-datepicker-component'
                            />
                        </Row>
                    </Col>
                </Row>
                <br />
                <br />
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        activitiesInfo: state.activityReducer,
        hrInfo: state.hrReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivities: getActivities,
            addSubmission: addSubmission,
            upsertActivity: upsertActivity,
            resetActivities: resetActivities,
            uploadActivityImage: uploadActivityImage,
            restartUploadActivityImage: restartUploadActivityImage,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(FundingsView);
