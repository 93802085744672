import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Grid, Col, Row, Checkbox, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import windowSize from 'react-window-size';
import { customSort, customFilter } from '../../../helpers/tableHelpers';


class ReportTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false,
            selectedRows: []
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    handleRowCheck(event) {
        var index = this.state.selectedRows.indexOf(event._original.Region);
        //Add ID
        if (index === -1) {
            var tempArray = this.state.selectedRows.concat(event._original.Region)
            this.setState({
                selectedRows: tempArray,
                ['row' + event._original.Region]: true
            });
        }
        //Remove ID
        else {
            tempArray = this.state.selectedRows;
            tempArray.splice(index, 1);
            this.setState({ selectedRows: tempArray, ['row' + event._original.Region]: false })
        }
    }


    handleSubmit() {
        this.props.handleSubmit(this.state.selectedRows)
    }




    render() {
       
        var that = this;
        return (
            <div style={{ marginTop: 25 }}>
                <ReactTable
                    id='CurrentTable'
                    data={this.props.hrInfo.allRegionsData}
                    columns={[
                        {
                            accessor: 'Region',
                            id: 'no-filter-column',
                            minWidth: 30,
                            Cell: row => (
                                <div align="center">
                                    <Checkbox  id={'row' + row.original.Region} checked={this.state['row' + row.original.Region]}>  </Checkbox>

                                </div>),  sortMethod: (a, b, desc) => {
                                var aNum = this.state['row' + a]?1:0;
                                var bNum = this.state['row' + b]?1:0;
                                return aNum > bNum ? 1 : -1;
                               
                            }
                        },
                        {
                            Header: 'Region',
                            accessor: 'Region',
                            filterable:true,
                            minWidth: 70,
                            Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                        },


                    ]}
                    style={{
                        height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    defaultPageSize={this.props.hrInfo.allRegionsData.length}
                    className="-striped -highlight"
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSort}
                    defaultSorted={[
                        {
                            id: "Region",
                            desc: false
                        }
                    ]}
                    getTrProps={(state, rowInfo) => {
                    
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: that.handleRowCheck.bind(that, rowInfo.row),
                                style: {
                                    background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                }
                            }
                        } else {
                            return {}
                        }
                    }}
                />
                <button onClick={this.handleSubmit} className='form-control btn btn-success'>Add Role</button>
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ReportTable));