import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData, getAllHRData } from '../../../actions/hrActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import { DropdownButton, MenuItem, Grid, Row, Col } from 'react-bootstrap'
import DepartmentDropdown from '../../dropdowns/DepartmentDropdown';
import DepartmentPageDropdown from '../../dropdowns/DepartmentPageDropdown';
import TitleDropdown from '../../dropdowns/TitleDropdown';
import EmployeeDropdown from '../../dropdowns/EmployeeDropdown';
import LocationDropdown from '../../dropdowns/LocationDropdown';
import Dropzone from 'react-dropzone';
import * as FontAwesome from 'react-icons/lib/fa';

const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class AddEmployeeModal extends Component {
    constructor(props) {
        super();
        this.state = {

            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            picture:'https://image.shutterstock.com/image-vector/uh-oh-450w-92290096.jpg'
               
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
    }
    componentDidMount() {

    }

    makeid() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    getEmployeeName(employeeId, employees) {
        var name = '';
        for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {

            if (this.props.hrInfo.allEmployeesData[i]._id == parseInt(employeeId)) {
                return this.props.hrInfo.allEmployeesData[i].firstName + ' ' + this.props.hrInfo.allEmployeesData[i].lastName

            }
        }
        return name;
    }
    handleSubmit(event) {


        event.preventDefault();

        var employeeData = {
            data: {
                itInfo: {
                    linkedApps: [],
                    encompassEmail: '',
                    encompassUsername: '',
                    aliasEmails: [],
                    ADUsername: '',
                    ADUserGroups: [],
                    oldEmployeeId: '',
                    emailDistroGroups: [],
                    intranetPage: this.state.intranetPage,
                    intranetPageLocation: this.state.intranetPageLocation,
                    intranetPermission: [],
                    personas: []
                },
                licensingInfo: {
                    nmls: '',
                    licensedStates: [],
                },
                employmentInfo:
                {
                    activeEmployee: true,
                    directReport: this.state.directReport,
                    directReportId: this.state.directReportId,
                    bio: this.state.bio,
                    website: this.state.website,
                    addToLocator: false,
                    employmentStatus: '',
                    departmentNumber: '',
                    departmentName: this.state.departmentName,
                    title: this.state.title,
                    region: '',
                    location: this.state.locationID,
                    workPhone: this.state.workPhone,
                    cellPhone: this.state.cellPhone,
                    hrAgreementInformation: '',
                    picture:'https://image.shutterstock.com/image-vector/uh-oh-450w-92290096.jpg'
                },
                gamingInfo:
                {
                    totalPoints:0,
                    games:[]
                },
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                userName: '',
            },
            _id: this.makeid(),
            collection: 'employees'
        };
        console.log(employeeData)
        this.props.upsertHRData(employeeData)
        this.props.getAllHRData({collection:'employees'});
        //Clears form on Submit
        this.setState({
            firstName: '',
            lastName: '',
            workPhone: '',
            cellPhone:'',
            website:'',
            departmentName: '',
            directReport: '',
            directReportId: '',
            location: '',
            title: '',
            employeeID: '',
            intranetPage:'',
            intranetPageLocation:''
        });

        this.props.closeModal()

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var checks = [];
        checks = this.state.locationsChecks;
        if (checks.indexOf(value)!= -1) {
            this.remove(checks, value)
        }
        else {
            checks.push(value)
        }
        this.setState({
            locationsChecks: checks
        });
    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }


    render() {
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={addModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>

                    <div className='try-registration-form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                            <span aria-hidden="false">&times;</span>
                        </button>
                        <h1 style={{ float: 'left' }}>Add New Employee</h1>
                        <br />
                        <br />
                        <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback' >
                                    <Grid >
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>First Name</h3>
                                                <input className='form-control' id='firstName' type='text' name='firstName' placeholder='First Name' value={this.state.firstName} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Last Name</h3>
                                                <input className='form-control' id='lastName' type='text' name='lastName' placeholder="Last Name" value={this.state.lastName} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Cell Phone Number</h3>
                                                <input className='form-control' id='cellPhone' type='text' name='cellPhone' placeholder="Phone Number" value={this.state.cellPhone} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Work Phone Number</h3>
                                                <input className='form-control' id='workPhone' type='text' name='workPhone' placeholder="Phone Number" value={this.state.workPhone} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Direct Report</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <EmployeeDropdown setEmployeeValue={(id, value) => { if (value) this.setState({ directReportId: id, directReport: value }) }} value={this.state.directReport} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3> Department</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <DepartmentDropdown setDepartmentValue={(value) => { if (value) this.setState({ departmentName: value }) }} value={this.state.departmentName} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>

                                            <Col md={6} sm={12}>
                                                <h3>Job Title</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <TitleDropdown setTitleValue={(value) => { if (value) this.setState({ title: value }) }} value={this.state.title} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Location</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={1} sm={1}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <LocationDropdown setLocationValue={(id, value) => { if (value) this.setState({ locationAddress: value, locationID: id }) }} value={this.state.locationAddress} />
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <h3>Employee Bio</h3>
                                                <textarea id="noter-text-area" name="bio" value={this.state.bio} onChange={this.handleInputChange}></textarea>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Website</h3>
                                                <input className='form-control' id='website' type='text' name='website' placeholder='Website' value={this.state.website} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Intranet Page</h3>
                                                <Row>
                                                    {window.innerWidth > 768 && <Col md={2} sm={2}>
                                                        <FontAwesome.FaSearch style={{ fontSize: 35 }} />
                                                    </Col>}
                                                    <Col md={10} sm={10}>
                                                        <DepartmentPageDropdown setDepartmentValue={(value) => { if (value) { this.setState({ intranetPage: value }) } else { this.setState({ intranetPage: '' }) } }} value={this.state.intranetPage} />
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col md={6} sm={12}>
                                                <h3>Intranet Page Location</h3>
                                                <input className='form-control' id='intranetPageLocation' type='text' name='intranetPageLocation' placeholder='Intranet Page Location' value={this.state.intranetPageLocation} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <hr/>
                                        <br/>
                                        <Row>
                                            <Col  md={4} sm={12}>
                                            <h3></h3>
                                                <img src={this.state.picture} alt="Picture"  height="70%" width="70%"/>
                                            </Col>
                                     
                                            <Col md={8} sm={12}>
                                                <h3>Change Picture:</h3>
                                                <Dropzone style={{ paddingLeft: 40, width: window.innerWidth > 768 ?'40rem':'30rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                                    <div>
                                                        <a> <h2>Click here or Drag in new Picture</h2></a>
                                                    </div>
                                                </Dropzone>
                                            </Col>
                                        </Row>
                                    </Grid>
                                    <br />
                                    <button className='form-control btn btn-success'>Add Employee</button>
                                </form>
                    </div>
                </div>
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            getAllHRData: getAllHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeeModal);
