import React, { Component } from 'react';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Col, Grid, ProgressBar, Row } from 'react-bootstrap';
import { changeLocation } from '../../../actions/globalActions';
import { logoutUser } from '../../../actions/loginActions';
import { getClosingReport, resetPipeline } from '../../../actions/pipelineActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';
import { Form, Icon, Message } from 'semantic-ui-react';
import { array } from 'prop-types';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const today = new Date();
class SnapShotView extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            isLoading: true,
            hasError: false,
            showTable: false,
            showDetailModal: false,
            showLinkAppModal: false,
            isProcessed: false,
            showCount: false,
            chartDisplaying: 'Count',
            chartNotDisplaying: 'Amount',
            amountGraphData: undefined,
            countGraphData: undefined,
            currentLoan: undefined,
            dataForTable: {},
            currentDateRange: 'Today',
            chartWidth: 0,
            limitMessage: '',
            gotLinkStatus: false


        }
        this.getThePipeline = this.getThePipeline.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentDidMount() {

        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })
            this.getThePipeline()
        }
        this.setState({
            chartWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }

    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }

    getThePipeline() {
    }
    
    hideLinkAppModal() {
        this.getThePipeline(this.state.filterID, this.state.field);
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }

    render() {
        return (
            <div>
                {
                    <div>
                        <iframe width="100%" height="512px" border="none" style={{border: 'none'}} src="https://assets.vandykmortgage.com/functions/closer-production-bonus-report/CloserProductionBonusReport.php" />
                    </div>
                }
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeLocation: changeLocation,
            getClosingReport: getClosingReport,
            resetPipeline: resetPipeline,
            logoutUser: logoutUser,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SnapShotView);
