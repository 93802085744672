import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import { Button, Grid, Row, Col } from 'react-bootstrap'
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';



const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class EditLocationModal extends Component {
    constructor(props) {
        super();
        this.state = {
            locationChecks: [],
            departmentName: '',
            firstPass: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }



    handleSubmit() {
        // do form stuff
        var locationData = {
            locationID: this.state.locationID,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            collection: 'locations'
        };

        this.props.upsertHRData(locationData)

        //Clears form on Submit
        this.setState({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        });

        this.props.closeModal()

    }

    clearOutModal() {

        this.props.hrInfo.allLocationsData.map((val) => {
            var tempName = 'checkbox' + val._id
            this.setState({
                [tempName]: false
            })
        })
        this.setState({
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            dataIsCleared: true,
            selectedRow: null
        });

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }



    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }



    render() {
        //THE CLEARDATA PROPERTY IS TRUE WHEN WE CLOSE
        if (this.props.clearData && !this.state.dataIsCleared) {
            this.clearOutModal();
        }
        var rowData = this.state.selectedRow;

        //POPULATES THE REGIONS WHEN SOMETHING CHANGED
        if ((this.props.selectedRow != this.state.selectedRow)) {
            rowData = this.props.selectedRow;
            if (rowData) {
                this.setState({
                    selectedRow: this.props.selectedRow,
                    dataIsCleared: false,
                    locationID: this.props.selectedRow._id,
                    address1: this.props.selectedRow.address1,
                    address2: this.props.selectedRow.address2,
                    city: this.props.selectedRow.city,
                    state: this.props.selectedRow.state,
                    zip: this.props.selectedRow.zip,
                })
            }
        }

        return (

            <div>
                {rowData &&
                    <Modal
                        isOpen={this.props.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.props.closeModal}
                        style={addModalStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>

                            <div className='try - registration - form'
                                style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                                    <span aria-hidden="false">&times;</span>
                                </button>
                                <h1 style={{ float: 'left' }}>Edit Location</h1>
                                <br />
                                <form onSubmit={this.handleSubmit} className='try-form' id='callback'>
                                   
                                    <br />
                                    <Grid>
                                    
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>Address</h3>
                                                <input className='form-control' id='address1' type='text' name='address1' placeholder='Address' value={this.state.address1} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <h3>Address (Cont'd)</h3>
                                                <input className='form-control' id='address2' type='text' name='address2' placeholder="Address (Cont'd)" value={this.state.address2} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3>City</h3>
                                                <input className='form-control' id='city' type='text' name='city' placeholder='City' value={this.state.city} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={3} sm={12}>
                                                <h3>State</h3>
                                                <input className='form-control' id='state' type='text' name='state' placeholder='State' value={this.state.state} onChange={this.handleInputChange} />
                                            </Col>
                                            <Col md={3} sm={12}>
                                                <h3>Zip</h3>
                                                <input className='form-control' id='zip' type='text' name='zip' placeholder='Zip' value={this.state.zip} onChange={this.handleInputChange} />
                                            </Col>
                                        </Row>
                                    </Grid>
                                    <br/>
                                    <button className='form-control btn btn-success'>Save Changes</button>
                                </form>
                                <br />

                                <div id='error'></div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(EditLocationModal);
