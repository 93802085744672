//Static Data


//Initial Data
const initialState = {
    locatorSearchStatus: 'NOT_STARTED',
    locatorErrorMessage: '',
    locatorData: {},
    allEmployeesSearchStatus: 'NOT_STARTED',
    allEmployeesErrorMessage: '',
    allEmployeesData: {},
    allLocationData: {},
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_ALL_EMPLOYEE_DATA':
            return {
                locatorSearchStatus: 'NOT_STARTED',
                locatorErrorMessage: '',
                locatorData: {},
                allEmployeesSearchStatus: 'NOT_STARTED',
                allEmployeesErrorMessage: '',
                allEmployeesData: {},
                allLocationData: {}
            }
        case 'RESET_LOANORIGINATORS_STATUS':
            return {
                ...state,
                locatorSearchStatus: 'NOT_STARTED',
                locatorErrorMessage: '',
                locatorData: {}
            }
        case 'GET_LOANORIGINATORS':
            return {
                ...state,
                locatorSearchStatus: 'STARTED'
            }
        case 'GET_LOANORIGINATORS_STARTED':
            return {
                ...state,
                locatorSearchStatus: 'STARTED'
            }
        case 'GET_LOANORIGINATORS_SUCCEEDED':
            return {
                ...state,
                locatorSearchStatus: 'SUCCEEDED',
                locatorData: action.loanOriginators
            }
        case 'ATHENA_LO_LOCATOR_CALL_ERRORED':
            return {
                ...state,
                locatorSearchStatus: 'ERRORED',
                locatorErrorMessage: action.locatorErrorMessage
            }
        case 'GET_ALL_EMPLOYEES':
            return {
                ...state,
                allEmployeeSearchStatus: 'STARTED'
            }
        case 'GET_ALL_EMPLOYEES_STARTED':
            return {
                ...state,
                allEmployeeSearchStatus: 'STARTED'
            }
        case 'GET_ALL_EMPLOYEES_SUCCEEDED':
            return {
                ...state,
                allEmployeeSearchStatus: 'SUCCEEDED',
                allEmployeesData: action.allEmployees,
                allLocationData: action.allLocations
            }

        case 'ATHENA_GET_EMPLOYEE_CALL_ERRORED':
            return {
                ...state,
                allEmployeeSearchStatus: 'ERRORED',
                allEmployeeErrorMessage: action.allEmployeeErrorMessage
            }
        default:
            return state
    }
}