import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import 'react-table/react-table.css'
import { Tooltip, OverlayTrigger, Col, Row, Button, ButtonGroup } from 'react-bootstrap'
import windowSize from 'react-window-size';
import { customSort, customFilter } from '../../../helpers/tableHelpers';

var auditTooltip = (
    <Tooltip id="tooltip">
        <strong>VA Loans Only:</strong> Perform Audit on loan to ensure that all required fields have been filled out correctly.
    </Tooltip>
);

var editTooltip = (
    <Tooltip id="tooltip">
        Make changes to fields in loan.
    </Tooltip>
);




//Components are DUMB and should only display the data that was passed from the container that it is within
class LoanListTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false,
            selectedRow: null,
            selectedRowInfo: null,
            noSelectedRows: false
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.getNewData = this.getNewData.bind(this);
        this.showComparison = this.showComparison.bind(this);
        this.showEdit = this.showEdit.bind(this);
        this.performAudit = this.performAudit.bind(this);
    }


    customFilter(filter, row, column) {
        const id = filter.pivotId || filter.id
        if (row[id] !== undefined) {
            var rowVal = String(row[id])
            return rowVal.indexOf(filter.value) >= 0;
        }
        else {
            return true;

        }
    }

    customSort(a, b, desc) {
        // force any string values to lowercase
        a = a === 'string' ? a.toLowerCase() : a
        b = b === 'string' ? b.toLowerCase() : b
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
        // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
        return 0
    }

    getNewData() {
        var loansToDisplay = [];
        var loansWithInfo = {};

        if (this.props.loans.length > 0) {
            //Determine Table Page Size
            if (this.props.loans.length > 20) {
                this.setState({ tablePageSize: 20 });
            }
            else {
                this.setState({ tablePageSize: this.props.loans.length });
            }
            //Create Data For Table
            for (var i = 0; i < this.props.loans.length; i++) {

                var name = this.jsUcfirst(this.props.loans[i]['fields']['Fields.4000'].toLowerCase()) + ' ' + this.jsUcfirst(this.props.loans[i]['fields']['Fields.4002'].toLowerCase());
                var loanNumber = this.props.loans[i]['fields']['Loan.LoanNumber'];
                var type = this.props.loans[i]['fields']['Fields.1172'];
                var guid = this.props.loans[i]['loanGuid'];

                //Object Used for Table
                var formattedObject = {
                    name: name,
                    loanNumber: loanNumber,
                    guid: guid,
                    type: type
                }

                loansWithInfo[guid] = {
                    name: name,
                    loanNumber: loanNumber,
                    type: type
                }

                loansToDisplay.push(formattedObject)
            }
            this.setState({ leadersForTable: loansToDisplay, loansWithInfo: loansWithInfo });
        }

    }
    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    componentWillMount() {
        this.setState({ currentLeaders: this.props.loans })
        this.getNewData()

    }


    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    showComparison() {
        if (this.state.selectedRowInfo) {
            this.props.showComparison(this.state.selectedRowInfo.original.guid);
        }
        else {
            alert('Please select a loan.')
        }
    }

    showEdit() {
        if (this.state.selectedRowInfo) {
            this.props.showEdit(this.state.selectedRowInfo.original.guid);
        }
        else {
            alert('Please select a loan.')
        }
    }
    performAudit() {
        if (this.state.selectedRowInfo) {
            var guid = this.state.selectedRowInfo.original.guid;
            this.props.performAudit(guid, this.state.loansWithInfo[guid]['type']);
        }
        else {
            alert('Please select a loan.')
        }

    }


    render() {

        if (this.state.currentLeaders !== this.props.loans) {
            this.getNewData();
            this.setState({ currentLeaders: this.props.loans });

        }

        var gotOneRow = false;
        return (
            <div style={{ marginTop: 25 }}>
                {/* <br />
                <hr style={{ height: 2 }} /> */}
                <Row>
                    <Col style={{ float: 'left' }}>
                        <p style={{ fontSize: '2rem' }}>{this.props.reportType}</p>
                        <p>{this.props.dayOfWeek}</p>
                    </Col>
                    <Col style={{ float: 'right' }}>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} >
                        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Total Number of Loans: {this.props.loans.length}</p>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xsOffset={3} smOffset={3} mdOffset={5} lgOffset={5}>
                        {this.state.selectedRow && <ButtonGroup>

                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                                <Button onClick={this.showEdit}>
                                    <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={auditTooltip}>
                                <Button onClick={this.performAudit}>
                                    <span style={{ color: '#ffbf00', fontSize: 30 }} ><Octicons.GoChecklist /></span>
                                </Button>
                            </OverlayTrigger>
                        </ButtonGroup>}

                        {!this.state.selectedRow && <ButtonGroup>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                                <Button >
                                    <span style={{ color: '#cccccc', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={auditTooltip}>
                                <Button >
                                    <span style={{ color: '#cccccc', fontSize: 30 }} ><Octicons.GoChecklist /></span>
                                </Button>
                            </OverlayTrigger>
                        </ButtonGroup>}

                    </Col>
                </Row>
                <hr />
                {window.innerWidth > 600 &&
                    <ReactTable
                        getTrProps={
                            (state, rowInfo) => {
                                var that = this;

                                if (rowInfo !== undefined) {

                                    return {
                                        onClick: (e) => {
                                            that.setState({
                                                selectedRow: rowInfo.index,
                                                selectedRowInfo: rowInfo,
                                                noSelectedRows: false
                                            })

                                        },
                                        style: {
                                            background: rowInfo.index === that.state.selectedRow ? '#00afec' : 'white',
                                            color: rowInfo.index === that.state.selectedRow ? 'white' : 'black'
                                        }
                                    }
                                }
                                else {
                                    return {
                                        onClick: (e) => {

                                        }

                                    }
                                }
                            }}
                        id='CurrentTable'
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: 'Loan Number',
                                accessor: 'loanNumber'
                            },
                            {
                                Header: 'Borrower',
                                accessor: 'name',
                            },

                            {
                                Header: 'Loan Type',
                                accessor: 'type'
                            },


                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onFilteredChange={(column, value) => {
                            this.setState({
                                                selectedRow: null,
                                                selectedRowInfo: null,
                                                noSelectedRows: false
                                            })
                        }}
                        
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSort}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }
                {window.innerWidth <= 600 &&
                    <ReactTable
                        id='CurrentTable'
                        getTrProps={(state, rowInfo) => {
                            var that = this;
                            if (rowInfo !== undefined) {
                                return {
                                    onClick: (e) => {
                                        that.setState({
                                            selectedRow: rowInfo.index,
                                            selectedRowInfo: rowInfo,
                                            noSelectedRows: false
                                        })
                                    },
                                    style: {
                                        background: rowInfo.index === that.state.selectedRow ? '#00afec' : 'white',
                                        color: rowInfo.index === that.state.selectedRow ? 'white' : 'black'
                                    }
                                }
                            }
                            else {
                                if (!this.state.noSelectedRows) {
                                    that.setState({
                                        selectedRow: null,
                                        selectedRowInfo: null,
                                        noSelectedRows: true
                                    })
                                }

                                return {
                                    onClick: (e) => {

                                    }

                                }
                            }
                        }}
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: 'Borrower',
                                accessor: 'name',
                            },
                            {
                                Header: 'Loan Number',
                                accessor: 'loanNumber'
                            },
                            {
                                Header: 'Loan Type',
                                accessor: 'type'
                            },
                            {
                                Header: 'Revisions',
                                accessor: 'guid',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showComparison.bind(this, row)}>
                                            <span className="fa fa-search" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },
                            {
                                Header: 'Edit',
                                accessor: 'guid',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showEdit.bind(this, row)}>
                                            <span className="fa fa-edit" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },
                            {
                                Header: 'Audit',
                                accessor: 'guid',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (

                                    <div align='center'>
                                        <button className='btn btn-success' onClick={this.performAudit.bind(this, row)}>Audit</button>
                                    </div>
                                )
                            },

                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSort}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }

                <hr />
                <hr />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(LoanListTable));