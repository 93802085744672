import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//Login Saga
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
const watchLoginUsers = function* watchLoginUsers() {
  yield takeEvery("INITIALIZE", initialize);
  yield takeEvery("ATHENA_LOGIN", loginUser);
  yield takeEvery("LOGOUT_USER", logoutUser);
  yield takeEvery("INTROSPECT_TOKEN", introspectToken);
  yield takeEvery("ENCOMPASS_LINK", encompassLink)
};

const initialize = function* initialize(action) {
  yield put({ type: "RESET_ATHENA_LOGIN" })
  yield put({ type: "RESET_ENCOMPASS_LOGIN" });
  yield put({ type: "RESET_PIPELINE_STATUS" });
  yield put({ type: "ENCOMPASS_LINK_RESET" });
}
const loginUser = function* loginUser(action) {


  //RESET all Applications indivudually 
  yield put({ type: "RESET_ATHENA_LOGIN" })
  yield put({ type: "RESET_ENCOMPASS_LOGIN" });
  yield put({ type: "RESET_PIPELINE_STATUS" });
  yield put({ type: "ENCOMPASS_LINK_RESET" });

  //Authenticate with Active Directory
  yield put({ type: "ATHENA_AUTH_STARTED" });
  //////////////////////////////////////////////
  //User is from Intranet so login is not necessary
  //////////////////////////////////////////////


    var mongoConfig = {
      query: {'itInfo.ADUsername':action.payload.username}
  };
  // var mongoConfig = {
  //   query: { 'itInfo.ADUsername': 'dmagnano' }
  // };
  var currentUser = yield call(getDataFromMongo, mongoConfig, 'employees')
  var userDataResponse;
  if (action.payload.employeeId) {
    //Check to see if user is in Database

    userDataResponse = yield call(getUserDataFromDBTask, action.payload.username);

    if (userDataResponse.data) {
      //NOTE this may grow as we link other applications
      for (var i = 0; i < userDataResponse.data.response.itInfo.linkedApps.length; i++) {
        //////////////////////////////////////////////
        //Check Encompass Link Status
        //////////////////////////////////////////////

        if (userDataResponse.data.response.itInfo.linkedApps[i].appName === 'Encompass') {
          //Is Linked
          yield put({ type: "IS_ENCOMPASS_LINKED_TRUE" });
        }
        else {
          yield put({ type: "IS_ENCOMPASS_LINKED_FALSE" });
        }
      }
      yield put({ type: "ATHENA_AUTH_SUCCEEDED", athenaUsername: action.payload.username, adUserData: userDataResponse.data.response, currentUser: currentUser[0] });
    }
    else {
      yield put({ type: "ATHENA_GET_USER_DB_CALL_ERRORED", athenaLoginErrorMessage: "AO.LGN.003: Athena Server is currently down." });
    }
  }
  else {

    var athenaResponse = yield call(athenaAuthTask, action.payload.username, action.payload.password);

    //There is a network error
    if (athenaResponse.data === undefined) {
      yield put({ type: "AD_SERVER_ERRORED", athenaLoginErrorMessage: "AO.LGN.001: Login Server is Down" });
      return;
    }
    //Credentials Did authenticate
    if (athenaResponse.data.status === 200 && athenaResponse.data.authenticated) {
      //Check to see if user is in Database
      userDataResponse = yield call(getUserDataFromDBTask, action.payload.username);

      if (userDataResponse.data) {
        //NOTE this may grow as we link other applications
        for (i = 0; i < userDataResponse.data.response.itInfo.linkedApps.length; i++) {
          //////////////////////////////////////////////
          //Check Encompass Link Status
          //////////////////////////////////////////////

          if (userDataResponse.data.response.itInfo.linkedApps[i].appName === 'Encompass') {
            //Is Linked
            yield put({ type: "IS_ENCOMPASS_LINKED_TRUE" });
          }
          else {
            yield put({ type: "IS_ENCOMPASS_LINKED_FALSE" });
          }
        }
        yield put({ type: "ATHENA_AUTH_SUCCEEDED", athenaUsername: action.payload.username, adUserData: userDataResponse.data.response, currentUser: currentUser[0] });
      }
      else {
        yield put({ type: "ATHENA_GET_USER_DB_CALL_ERRORED", athenaLoginErrorMessage: "AO.LGN.003: Athena Server is currently down." });
      }

    }
    else {
      yield put({ type: "INVALID_LOGIN_CREDENTIALS", athenaLoginErrorMessage: "AO.LGN.002: Invalid Username or Password" });
    }
  }
};

function athenaAuthTask(username, password) {

  return axios({
    method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.athena_auth, headers: {
      'username': username,
      'password': password
    }
  }).then(response => {
    return response;
  }).catch(function (error) {
    return error
  });;
}
function getUserDataFromDBTask(username) {
  return axios({
    method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.get_ad_user_data, headers: {
      'username': username
    }
  }).then(response => {
    return response;
  }).catch(function (error) {
    return error
  });
}
const logoutUser = function* logoutUser(action) {
  var revokeToken = {};
  yield put({ type: "RESET_ENCOMPASS_LOGIN" });
  yield put({ type: "RESET_ATHENA_LOGIN" });
  yield put({ type: "RESET_PIPELINE_STATUS" });
  yield put({ type: "RESET_ISSUE_STATUS" });
  yield put({ type: "RESET_HR_STATUS" });
  yield put({ type: "RESET_LOANORIGINATORS_STATUS" });
  try {
    revokeToken = yield call(revokeTokenTask, action.token);
  }
  catch (error) {

    return;
  }

}
async function revokeTokenTask(token) {
  var headers =
  {
    headers: {
      'token': token,
    }

  };
  return axios.get(settings.URL + settings.PORT + settings.ENDPOINTS.revoke_token, headers).then(response => {
    return response;
  }).catch(function (error) {
    console.log('REVOKE', error.response)
    return error
  });;
}
const introspectToken = function* introspectToken(action) {
  var introspectTokenResult = {};

  try {
    introspectTokenResult = yield call(introspectTokenTask, action.token);

    if (introspectTokenResult.data.status !== 200) {
      yield put({ type: "ENCOMPASS_TOKEN_EXPIRED", errorMessage: "Token not found, expired or invalid" });
      return;
    }

  }
  catch (error) {

    return;
  }

}
async function introspectTokenTask(token) {
  return axios({
    method: 'GET', url: settings.URL + settings.PORT + settings.ENDPOINTS.introspection_token, headers: {
      'token': token,
    }
  }).then(response => {
    return response;
  }).catch(function (error) {
    return error
  });
}
const encompassLink = function* encompassLink(action) {
  console.log('ENCOMPASS LINK', action )
  var encompassLinkResult = {};
  yield put({ type: "ENCOMPASS_LINK_STARTED" });
  try {
    encompassLinkResult = yield call(encompassLinkTask, action.payload);
    if (encompassLinkResult.data) {
      if (encompassLinkResult.data.status !== 200) {
        yield put({ type: "ENCOMPASS_LINK_FAILED", encompassLinkErrorMessage: encompassLinkResult.data.response });
        return;
      }
      else {
        yield put({ type: "ENCOMPASS_LINK_SUCCEEDED", currentToken: encompassLinkResult.data.access_token });
        return;
      }
    }
    else {
      yield put({ type: "ATHENA_LINK_USER_DB_CALL_ERRORED", encompassLinkErrorMessage: "AO.LGN.004: Athena Server is currently down." });
    }
  }
  catch (error) {
    return;
  }
}
function encompassLinkTask(userData) {
  return axios({
    method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.encompass_link, headers: {
      'appUsername': userData.appUsername,
      'appPassword': userData.appPassword,
      'adUsername': userData.athenaUsername
    }
  }).then(response => {

    return response;
  }).catch(function (error) {
    return error
  });
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//Database Functions
///////////////////////////////////////////////////////////////////////////////////////////////////
function getDataFromMongo(mongoConfig, collection, ) {

  return axios({
    method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_find, headers: {
      'Content-type': 'application/json',
      'collection': collection
    }, data: mongoConfig
  }).then((response) => {

    return response.data;
  })

}

export default watchLoginUsers;