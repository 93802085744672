import React from 'react';
import { Route } from 'react-router-dom'
import LoginContainer from './LoginContainer'; 
import ReportContainer from './ReportContainer';
import BankReportContainer from './BankReportContainer';
import PipelineReportContainer from './PipelineReportContainer';
import LeaderboardContainer from './LeaderboardContainer';
import SnapShotContainer from './SnapShotContainer';
import LocatorContainer from './LocatorContainer';
import TrendsContainer from './TrendsContainer';
import FundingsContainer from './FundingsContainer';
import LoanListContainer from './LoanListContainer';
import LoanRevComparisonContainer from './LoanRevComparisonContainer';
import LoanEditContainer from './LoanEditContainer';
import HRAdminContainer from './HRAdminContainer';
import HRApplicantContainer from './HRApplicantContainer';
import HRApplicantRequestContainer from './HRApplicantRequestContainer';
import HRHiringContainer from './HRHiringContainer';
import SideBarOptionContainer from './SideBarOptionContainer';
import MyActivitiesContainer from './MyActivitiesContainer';
import PendingActivitiesContainer from './PendingActivitiesContainer';
import SampleActivityContainer from './SampleActivityContainer';
import NewActivityContainer from './NewActivityContainer';
import EditActivityContainer from './EditActivityContainer';
import ReviewActivityContainer from './ReviewActivityContainer';
import ApproveActivityContainer from './ApproveActivityContainer';
import MortgageCalculatorContainer from './MortgageCalculatorContainer';
import MortgageCalculatorComponentContainer from './MortgageCalculatorComponentContainer';
import ExlcusionaryContainer from './ExlcusionaryContainer';
import MyProfileContainer from './MyProfileContainer';
import LoanProcessorScorecardContainer from './LoanProcessorScorecardContainer';
import DashboardContainer from './DashboardContainer';
import RegionFundingsReportContainer from './RegionFundingsReportContainer';
import ClosingReportContainer from './ClosingReportContainer';
import ClosingBonusContainer from './ClosingBonusContainer';

const App = () => (


    <div>

        <main>
            <Route exact path='/' component={LoginContainer} />
            <Route exact path='/login' component={LoginContainer} />
            <Route exact path='/snapShot' component={SnapShotContainer} />
            <Route exact path='/trends' component={TrendsContainer} />
            <Route exact path='/fundings' component={FundingsContainer} />
            <Route exact path='/hrAdminPortal' component={HRAdminContainer} />
            <Route exact path='/applicant' component={HRApplicantContainer} />
            <Route exact path='/applicantRequest' component={HRApplicantRequestContainer} />
            <Route exact path='/hrHiringPortal' component={HRHiringContainer} />
            <Route exact path='/report' component={ReportContainer} />
            <Route exact path='/bankReport' component={BankReportContainer} />
            <Route exact path='/RegionFundingsReport' component={RegionFundingsReportContainer} />
            <Route exact path='/loanList' component={LoanListContainer} />
            <Route exact path='/loanRevCompare' component={LoanRevComparisonContainer} />
            <Route exact path='/loanEdit' component={LoanEditContainer} />
            <Route exact path='/pipelineReport' component={PipelineReportContainer} />
            <Route exact path='/leaderboard' component={LeaderboardContainer} />
            <Route exact path='/directory' component={LocatorContainer} />
            <Route exact path='/myActivities' component={MyActivitiesContainer} />
            <Route exact path='/pendingActivities' component={PendingActivitiesContainer} />
            <Route exact path='/sampleActivity' component={SampleActivityContainer} />
            <Route exact path='/newActivity' component={NewActivityContainer} />
            <Route exact path='/editActivity' component={EditActivityContainer} />
            <Route exact path='/reviewActivity' component={ReviewActivityContainer} />
            <Route exact path='/approveActivity' component={ApproveActivityContainer} />
            <Route exact path='/mortgageCalculator' component={MortgageCalculatorContainer} />
            <Route exact path='/mortgageCalculatorComponent' component={MortgageCalculatorComponentContainer} />
            <Route exact path="/intranetSnapshot" render={()=><SideBarOptionContainer view="SnapShot" />}/>
            <Route exact path="/exclusionaryListCreator" component = {ExlcusionaryContainer}/>
            <Route exact path="/loanProcessorScorecard" component = {LoanProcessorScorecardContainer}/>
            <Route exact path='/myProfile' component={MyProfileContainer} />
            <Route exact path='/dashboard' component={DashboardContainer} />
            <Route exact path='/closingReport' component={ClosingReportContainer} />
	        <Route exact path='/closingBonus' component={ClosingBonusContainer} />
        </main>

    </div>
);
export default App;



