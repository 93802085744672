import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Grid, Col, Row, Checkbox, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import windowSize from 'react-window-size';
import { customSort, customFilter } from '../../../helpers/tableHelpers';




//Components are DUMB and should only display the data that was passed from the container that it is within
class ReportTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 200,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false,
            selectedRows: []
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    handleRowClick(event) {
        var index = this.state.selectedRows.indexOf(event.Name);
        //Add ID
        if (index === -1) {
            var tempArray = this.state.selectedRows.concat(event.Name)
            this.setState({
                selectedRows: tempArray,
                ['row' + event.Name]: true
            });
        }
        //Remove ID
        else {
            tempArray = this.state.selectedRows;
            tempArray.splice(index, 1);
            this.setState({ selectedRows: tempArray, ['row' + event.Name]: false })

        }

    }


    handleSubmit() {
        this.props.handleSubmit(this.state.selectedRows)
    }




    render() {

        var that = this;
        return (
            <div style={{ marginTop: 25 }}>

                <ReactTable
                    id='CurrentTable'
                    data={this.props.hrInfo.allDepartmentsData}
                    columns={[
                        {
                            accessor: 'Name',
                            id: 'no-filter-column',
    
                            sortable: true,
                            minWidth: 30,
                            Cell: row => (
                                <div align="center">
                                    <Checkbox  id={'row' + row.original.Name} checked={this.state['row' + row.original.Name]}>  </Checkbox>

                                </div>),  sortMethod: (a, b, desc) => {
                                var aNum = this.state['row' + a]?1:0;
                                var bNum = this.state['row' + b]?1:0;
                                return aNum > bNum ? 1 : -1;
                               
                            }
                        },
                        {
                            Header: 'Department',
                            accessor: 'Name',
                            filterable: true,
                            minWidth: 70,
                            Cell: row => (
                                <div align="center">
                                    {Number.isInteger(parseInt(row.original.Name)) && <p style={{textAlign:"center"}}>Department {row.original.Name}</p>}
                                    {!Number.isInteger(parseInt(row.original.Name)) && <p style={{textAlign:"center"}}>{row.original.Name}</p>}
                                </div>),filterMethod: (filter, row) => {
                                    if (Number.isInteger(parseInt(row._original.Name))) {
                                        var dept = 'Department ' + row._original.Name;
                                        return dept.indexOf(filter.value) != -1;
                                    }
                                    else if (row._original.Name.indexOf(filter.value) != -1) {
                                        return true;
                                    }
                                }
                                
                        },
                    ]}
                    style={{
                        height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    defaultPageSize={this.props.hrInfo.allDepartmentsData.length}
                    className="-striped -highlight"
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSort}
                    defaultSorted={[
                        {
                            id: "Name",
                            desc: false
                        }
                    ]}
                    getTrProps={(state, rowInfo) => {

                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: that.handleRowClick.bind(that, rowInfo.row),
                            }
                        } else {
                            return {}
                        }
                    }}
                />
                <button onClick={this.handleSubmit} className='form-control btn btn-success'>Add Region</button>
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ReportTable));