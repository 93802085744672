import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { customFilter, customSort } from '../../../helpers/tableHelpers'
import 'react-table/react-table.css'
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import { Tooltip, OverlayTrigger, Grid, Col, Row, Button, ButtonGroup } from 'react-bootstrap'
// import deleteRegion from '../../actions/hrActions';
import { setSelectedApplicant, deleteHRData } from '../../../actions/hrActions';
import { Form, Icon, Message } from 'semantic-ui-react';
import { resolveIssue } from '../../../actions/issueActions';
import windowSize from 'react-window-size';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

var editTooltip = (
  <Tooltip id="tooltip">
    <strong>Edit:</strong> Make changes to selected applicant.
  </Tooltip>
);



class HiringTable extends Component {

  constructor(props) {
    super();
    this.state = {
    }
    this.goToApplicantPage = this.goToApplicantPage.bind(this);
    this.goToApplicantRequestPage = this.goToApplicantRequestPage.bind(this);
  }

  goToApplicantPage() {
    //Set the current Applicant 
    var selectedApplicant = {
      data: {
        employmentInfo: this.state.selectedRowInfo.original.employmentInfo,
        hiringInfo: this.state.selectedRowInfo.original.hiringInfo,
        itInfo: this.state.selectedRowInfo.original.itInfo,
        licensingInfo: this.state.selectedRowInfo.original.licensingInfo,
        personalInfo: this.state.selectedRowInfo.original.personalInfo
      },
      _id: this.state.selectedRowInfo.original._id
    }
    this.props.setSelectedApplicant({ selectedApplicant: selectedApplicant });
    this.props.goToAnotherPage({
      pathname: '/applicant'
    })

  }
  goToApplicantRequestPage() {
    //Set the current Applicant 
    var selectedApplicant = {
      data: {
        employmentInfo: {},
        hiringInfo: {},
        itInfo: {},
        licensingInfo: {},
        personalInfo: {}
      },
      _id: ''
    }
    this.props.setSelectedApplicant({ selectedApplicant: selectedApplicant });

    this.props.goToAnotherPage({
      pathname: '/applicantRequest'
    })

  }
  getEmployeeById(id) {
    var objToReturn = {
      firstName: '',
      lastName: ''
    };
    if (id) {
      for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
        if (this.props.hrInfo.allEmployeesData[i]._id == id) {
          objToReturn = this.props.hrInfo.allEmployeesData[i];
        }
      }
      return objToReturn;
    }
    return objToReturn;

  }



  handleDeleteClick = () => {
    confirmAlert({
        title: 'Delete Item',
        message: 'Are you sure you want to delete this?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => {
                  var deleteData =
                  {
                      applicantID: this.state.selectedRowInfo.original._id,
                      collection: 'applicants',
                      
                  }
                  //Update in Collection
                  this.props.deleteHRData(deleteData);
                  this.setState({selectedRowInfo:undefined})
                 }
            },
            {
              label: 'No',
              onClick: () => { }
          },

        ]
    })
};

  render() {
    return (
      <div>
        <Grid>
          <Row>
            <Col style={{ float: 'right' }}>
              <ButtonGroup>
              <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                    <Button onClick={this.goToApplicantRequestPage}>
                      <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaPlus /></span>
                    </Button>
                  </OverlayTrigger>
                {this.state.selectedRowInfo &&
                  <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                    <Button onClick={this.goToApplicantPage}>
                      <span style={{ color: '#428bca', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                    </Button>
                  </OverlayTrigger>}

                {!this.state.selectedRowInfo &&
                  <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                    <Button>
                      <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                    </Button>
                  </OverlayTrigger>}
                  {this.state.selectedRowInfo &&
                  <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                    <Button onClick={this.handleDeleteClick}>
                      <span style={{ color: '#ff0000', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                    </Button>
                  </OverlayTrigger>}

                {!this.state.selectedRowInfo &&
                  <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                    <Button>
                      <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                    </Button>
                  </OverlayTrigger>}
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
        <hr />
        {this.props.hrInfo.allApplicantsData.length < 1 &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}
{this.props.hrInfo.allApplicantsData.length >1 &&
        <ReactTable
          data={this.props.hrInfo.allApplicantsData}
          getTrProps={
            (state, rowInfo) => {
              var that = this;

              if (rowInfo !== undefined) {

                return {
                  onClick: (e) => {
                    that.setState({
                      selectedRow: rowInfo.index,
                      selectedRowInfo: rowInfo,
                      noSelectedRows: false
                    })
                  },
                  style: {
                    background: that.state.selectedRowInfo?rowInfo.index === that.state.selectedRow ? '#00afec' : 'white':'white',
                    color: that.state.selectedRowInfo?rowInfo.index === that.state.selectedRow ? 'white' : 'black':'black',
                  }
                }
              }
              else {
                return {
                  onClick: (e) => {

                  }

                }
              }
            }}
          columns={[
            {

              columns: [
                {
                  Header: "Last Name",
                  accessor: "personalInfo.lastName",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                },
                {
                  Header: "First Name",
                  accessor: "personalInfo.firstName",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                },
                {
                  Header: "Direct Report",
                  accessor: "employmentInfo.directReport",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{this.getEmployeeById(value).firstName + ' ' + this.getEmployeeById(value).lastName}</p>),
                },
                {
                  Header: "Status",
                  accessor: "hiringInfo.hiringStatus",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),

                },
                {
                  Header: "Start Date",
                  accessor: "hiringInfo.estimatedStartDate",
                  minWidth: 30,
                  Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value ? value.split('T')[0] : ''}</p>),
                },

              ]
            }
          ]}
          style={{
            height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
          }}
          defaultPageSize={this.props.hrInfo.allApplicantsData.length}
          filterable
          defaultFilterMethod={customFilter}
          defaultSortMethod={customSort}
          className="-striped -highlight"
          defaultSorted={[
            {
              id: "lastName",
              desc: false
            }
          ]}
        />}


      </div>
    )
  }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
  return {
    hrInfo: state.hrReducer,
  
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedApplicant: setSelectedApplicant,
      resolveIssue: resolveIssue,
      deleteHRData:deleteHRData,
      goToAnotherPage: (params) => push(params)
    }
    , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(HiringTable));