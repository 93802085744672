import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPipeline } from '../actions/pipelineActions';
import LeaderboardView from '../components/pipelineComponents/leaderboardComponents/LeaderboardView';
import MenuSideBar from '../components/layoutComponents/MenuSideBar';


class LeaderboardContainer extends Component {
    componentDidMount() {
        this.props.resetPipeline();
    }

    render() {
        return (
            <div id="leaderboardOuterContainer">
            <MenuSideBar comp={<LeaderboardView />} />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        all:state
    };
}

//This function links functions/dispatch to the props of the component being processed.
//In this case we are:
//changeUser - grabbing a function from actions.js file 
//goToAnotherPage - utilizing the push command from react-router to go to a page specified in the params
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPipeline:resetPipeline
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardContainer);
