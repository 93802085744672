import React, { Component } from 'react';
import { Col, DropdownButton, Grid, MenuItem, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';
import { logoutUser } from '../../../actions/loginActions';
import { changeLocation } from '../../../actions/globalActions';
import { getPipeline, resetPipeline, updateProgress } from '../../../actions/pipelineActions';
import { getTotalDollars } from '../../../helpers/getDollars';
import { getTodayRange } from '../../../helpers/getLoans';
import LeaderTableDetailModal from '../../modals/pipeline_modals/LeaderTableDetailModal';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';
import LeaderboardTable from '../../tables/pipeline_tables/LeaderboardTable';

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

class SnapShotView extends Component {
    constructor(props) { 
        super();
        this.state = {
            appStatusText: 'Funded',
            filterID: '1997',
            field: 'FundedDate',
            graphData: (<h1>Table</h1>),
            loading: false,
            isLoading: true,
            hasError: false,
            showTable: false,
            showDetailModal: false,
            showLinkAppModal: false,
            isProcessed: false,
            showCount: false,
            chartDisplaying: 'Count',
            chartNotDisplaying: 'Amount',
            amountGraphData: undefined,
            countGraphData: undefined,
            currentLoan: undefined,
            dataForTable: {},
            currentDateRange: 'Today',
            chartWidth: 0,
            limitMessage: '',
            gotLinkStatus: false


        }
        this.getThePipeline = this.getThePipeline.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.getLeaders = this.getLeaders.bind(this);
        this.hideTableView = this.hideTableView.bind(this);
        this.showDetailModal = this.showDetailModal.bind(this);
        this.hideDetailModal = this.hideDetailModal.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentDidMount() {

        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })
            this.getThePipeline(this.state.filterID, this.state.field)
        }
        this.setState({
            chartWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }

    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }

    getThePipeline(filter, field) {
        var Sugar = require('sugar-date')
        var newDataStartDate = Sugar.Date.create('Yesterday');
        var newDataStartMonth = newDataStartDate.getMonth() + 1;
        var newDataStartDay = newDataStartDate.getDate();
        var newDataStartYear = newDataStartDate.getFullYear();
        var newDataStartDate = newDataStartMonth + '/' + newDataStartDay + '/' + newDataStartYear;

        var searchStartData = Sugar.Date.create('1 Week Ago');
        var searchStartMonth = searchStartData.getMonth() + 1;
        var searchStartDay = searchStartData.getDate();
        var searchStartYear = searchStartData.getFullYear();
        var searchStartDate = searchStartMonth + '/' + searchStartDay + '/' + searchStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()
        var pipelineParam = {
            username: this.props.loginInfo.athenaUsername,
            filter: filter,
            field: field,
            newDataStartDate: newDataStartDate,
            searchStartDate: searchStartDate,
            endDate: todayDate,
        }
        this.props.getPipeline(pipelineParam);


    }
    changeFilter(filter) {
        if (filter === 'PreApp') {
            this.setState({ appStatusText: 'Pre-Application', filterID: '745', field: 'PreApplicationDate' });
            this.getThePipeline('745', 'PreApplicationDate')
        }
        else if (filter === 'App') {
            this.setState({ appStatusText: 'Application', filterID: '3142', field: 'ApplicationDate' });
            this.getThePipeline('3142', 'ApplicationDate')
        }
        else if (filter === 'Proc') {
            this.setState({ appStatusText: 'Submitted to Processing', filterID: 'LOG.MS.DATE.Processing', field: 'SubmittedToProcDate' });

            this.getThePipeline('LOG.MS.DATE.Processing', 'SubmittedToProcDate')
        }
        else if (filter === 'UW') {
            this.setState({ appStatusText: 'Submitted to Underwriting', filterID: '2298', field: 'SubmittedToUWDate' });
            this.getThePipeline('2298', 'SubmittedToUWDate')
        }
        else if (filter === 'Funded') {
            this.setState({ appStatusText: 'Funded', filterID: '1997', field: 'FundedDate' });
            this.getThePipeline('1997', 'FundedDate')
        }
        else if (filter === 'Locked') {
            this.setState({ appStatusText: 'Locked', filterID: '2149', field: 'LockedDate' });
            this.getThePipeline('2149', 'LockedDate')
        }
    }
    getDayOfWeek(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')

        var date = new Date(dateStringParts[2], dateStringParts[0] - 1, dateStringParts[1]);
        var todayDate = new Date();
        if (date.getDate() === todayDate.getDate()) {
            return 'Today'
        }
        else {
            return days[date.getDay()]
        }
    }

    getMonth(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')

        var date = new Date(dateStringParts[2], dateStringParts[0], dateStringParts[1]);
        return months[dateStringParts[0] - 1]
    }
    getYear(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')
        return dateStringParts[2]
    }

    changeDateRange(dateRange) {
        //If Results yield 25,000 then give message to user to use Report to get accurate numbers
        if (this.props.pipelineInfo.pipelineData.length === 25000) {
            this.setState({ limitMessage: 'Your pipeline yielded over 25,000 loans which may result in inaccurate snapshot numbers.  You should use the Reports tab from the side menu to get an accurate view of your information.' })

        }
        //Close Table if it is open
        this.setState({ showTable: false, currentDateRange: dateRange });

        //Get Current Date
        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var currentDate = month + '/' + day + '/' + year;
        var countToReturn = [];
        var currentLoanNum = [];

        if (dateRange === 'Today') {
            var todayRange = getTodayRange(currentDate, this.props.pipelineInfo.warehousePipelineData, this.state.field);

            var displayForNow = Object.keys(todayRange).map((row, i) => {

                countToReturn.push({ x: this.getDayOfWeek(row), y: todayRange[row].loans.length })
                currentLoanNum.push({ loan: todayRange[row].loans })
                return (
                    <Row key={i}>
                        <h3>{row + '  Count:' + todayRange[row].loans.length + '  Amount:' + todayRange[row].totalAmountForRange}</h3>

                    </Row>
                )
            })
        }

        this.setState({ countGraphData: countToReturn, currentLoan: currentLoanNum })
    }

    getLeaders(loanList) {
        var leaders = {};
        //Creates Object of LOs with their associated Loans
        if (loanList.length > 0) {
            loanList.map((loan) => {
                if (leaders[loan['LOName']]) {
                    leaders[loan['LOName']].push(loan)
                }
                else {
                    leaders[loan['LOName']] = [loan]
                }
            });

        }
        //Create Object that contains name, count of loans, total dollar value of each loan
        var finalLeaderInfo = {}
        Object.keys(leaders).map((leader) => {
            finalLeaderInfo[leader] = {
                count: leaders[leader].length,
                totalDollars: getTotalDollars(leaders[leader]),
                loans: leaders[leader]
            }
        })
        return finalLeaderInfo;
    }

    hideTableView() {
        this.setState({ showTable: false });
    }
    hideDetailModal() {
        this.setState({ showDetailModal: false });
    }
    showDetailModal(detailList) {
        this.setState({ showDetailModal: true, detailList: detailList })
    }
    getDetailModal() {
        return (<LeaderTableDetailModal details={this.state.detailList} modalIsOpen={this.state.showDetailModal} closeModal={this.hideDetailModal} />)
    }

    hideLinkAppModal() {
        this.getThePipeline(this.state.filterID, this.state.field);
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }



    render() {
        const tooltip = (
            <Tooltip id="tooltip">
                Click to Change Report Type
            </Tooltip>
        )
        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }


        //Sets SnapShot to Today by default and set gotPipeStatus to true
        if (this.props.pipelineInfo.getPipelineStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {

            this.changeDateRange('Today');
            this.setState({ gotPipeStatus: true, isLoading: false })
            this.props.updateProgress(99);
        }
        //Handle Failed/Errored Scenarios
        else if (this.props.pipelineInfo.getPipelineStatus === 'ERRORED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getPipelineErrorMessage)
            this.props.goToAnotherPage({
                pathname: '/login'
            })
            this.setState({ gotPipeStatus: true });
        }
        else if (this.props.pipelineInfo.getPipelineStatus === 'FAILED' && !this.state.gotPipeStatus) {
            this.showLinkAppModal();
            this.setState({ gotPipeStatus: true });
        }

        //Reset gotPipeStatus to false
        if ((this.props.pipelineInfo.getPipelineStatus === 'STARTED' || this.props.pipelineInfo.getPipelineStatus === 'NOT_STARTED') && this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true, showTable: false })
        }
        //Add Padding to the chart y axis
        if (this.state.countGraphData !== undefined) {
            var domainMax = this.state.countGraphData.reduce((max, p) => p.y > max ? p.y : max, this.state.countGraphData[0].y)
            domainMax = domainMax + Math.round(domainMax / 3)
        }
        var detailModal = this.getDetailModal();
        var linkAppModal = this.linkAppModal()

        if (window.innerWidth <= 600) {
            //Mobile
            var barRatioSize = 1;
            var graphStyle = {
                flex: '1 0 0px',
                minHeight: 0,
                marginBottom: -50,
                marginTop: 10
            };
        }
        else {
            // Desktop
            var barRatioSize = 0.5;
            var graphStyle = {
                flex: '1 0 0px',
                minHeight: 0,
                marginBottom: -50,
                marginRight: 50,
                marginLeft: 50,
                marginTop: 25
            };
        }
        return (
            <div>
                {linkAppModal}
                {detailModal}
                {/*MOBILE <Grid style={{ width: "85%", color: '#282828' }}> */}
                <Grid style={{ width: "90%", color: '#282828' }}>
                    <br />
                    {/* <Row style={{ paddingLeft: '15%', paddingRight: '15%' }}> */}
                    <Col>
                        <h3 style={{ fontSize: '2rem', textAlign: 'left' }}>{this.state.appStatusText} Snapshot Report </h3>
                    </Col>
                    {/* </Row> */}
                    {this.state.isLoading &&
                        <div style={{ width: '100%', paddingTop: '50px' }}>
                            <Row>
                                <ProgressBar active striped bsStyle="info" now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`} />
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ textAlign: 'center' }}>{this.props.pipelineInfo.progressText}
                                    </p>
                                </Col>
                            </Row>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                        </div>}


                    {!this.state.isLoading &&
                        <div>
                            <Row>
                                <Col>
                                    <OverlayTrigger placement="right" overlay={tooltip}>
                                        <DropdownButton bsSize="large" title={this.state.appStatusText} id="dropdown-size-large" className="reportButton" bsStyle="info">
                                            {this.state.appStatusText !== "Pre-Application" && <MenuItem className="menuItem" eventKey={1.1} onSelect={() => this.changeFilter('PreApp')}>Pre-Applications</MenuItem>}
                                            {this.state.appStatusText !== "Application" && <MenuItem eventKey={1.2} onSelect={() => this.changeFilter('App')}>Applications</MenuItem>}
                                            {this.state.appStatusText !== "Submitted to Processing" && <MenuItem eventKey={1.3} onSelect={() => this.changeFilter('Proc')}>Submitted to Processing</MenuItem>}
                                            {this.state.appStatusText !== "Submitted to Underwriting" && <MenuItem eventKey={1.4} onSelect={() => this.changeFilter('UW')}>Submitted to Underwriting</MenuItem>}
                                            {this.state.appStatusText !== "Locked" && <MenuItem eventKey={1.5} onSelect={() => this.changeFilter('Locked')}>Locked</MenuItem>}
                                            {this.state.appStatusText !== "Funded" && <MenuItem eventKey={1.6} onSelect={() => this.changeFilter('Funded')}>Funded</MenuItem>}
                                        </DropdownButton>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row style={graphStyle}>
                                <svg viewBox={"0 0" + " " + this.state.chartWidth + " " + "200"} >
                                    <VictoryChart
                                        width={this.state.chartWidth}
                                        height={200}
                                        standalone={false}
                                    >
                                        <VictoryAxis
                                            width={300}
                                            height={200}
                                            style={{ axis: { stroke: 'none' }, tickLabels: { fill: '#282828', fontSize: '20' } }}
                                        />
                                        <VictoryBar
                                            style={{ data: { fill: '#282828' }, labels: { fill: '#282828', fontSize: '20' } }}
                                            barRatio={barRatioSize}
                                            labels={(datum) => datum.y}
                                            data={this.state.countGraphData}
                                            events={[
                                                {
                                                    target: "data",
                                                    eventHandlers: {
                                                        onClick: () => {
                                                            return [{
                                                                mutation: (props) => {
                                                                    //Set selectedChartData to bar that was clicked
                                                                    var leaderList = this.getLeaders(this.state.currentLoan[props.index].loan)
                                                                    this.setState({ showTable: true, tableView: (<LeaderboardTable leaders={leaderList} columnHeader="LO Name" reportType={this.state.appStatusText} hideTableView={this.hideTableView} field={this.state.field} showDetailModal={this.showDetailModal} dayOfWeek={props.data[props.index].x} />) })
                                                                }
                                                            }];
                                                        },
                                                        onMouseOver: () => {
                                                            return [{
                                                                //childName: ["pie", "bar"],
                                                                mutation: (props) => {
                                                                    return {
                                                                        style: Object.assign({}, props.style, { fill: "#cbcbcb" })
                                                                    };
                                                                }
                                                            }];
                                                        },
                                                        onMouseOut: () => {
                                                            return [{
                                                                //childName: ["pie", "bar"],
                                                                mutation: () => {
                                                                    return null;
                                                                }
                                                            }];
                                                        },

                                                    }
                                                }
                                            ]}
                                        />
                                    </VictoryChart>
                                </svg>
                            </Row>
                            <br />
                            <Row >
                                <Col>
                                    <h6 style={{ textAlign: 'center' }}>{this.state.limitMessage}</h6>
                                </Col>
                            </Row>
                        </div>}



                    {this.state.showTable && this.state.tableView}
                    <br />
                </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeLocation: changeLocation,
            getPipeline: getPipeline,
            resetPipeline: resetPipeline,
            updateProgress: updateProgress,
            logoutUser: logoutUser,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(SnapShotView);
