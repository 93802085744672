import React, { Component } from 'react';
import ReactStars from 'react-stars'
import * as FontAwesome from 'react-icons/lib/fa';
import { Col, Row, Nav, NavItem, Grid, Table, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getSurvey } from '../../actions/communicationActions';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SurveyView from './SurveyView';
import LicensingView from './LicensingView';
import ProfileCardView from './ProfileCardView';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class MyProfileView extends Component {
    constructor(props) {
        super();
        this.state = {
            profilePage: 'SurveyInfo',
            width: 0,
            height: 0
        }

        this.generateSurveyCards = this.generateSurveyCards.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentWillMount() {
        this.setState({ user: this.props.loginInfo.currentUser })
    }
    translateDate(dateVal) {
        var date = new Date(dateVal)
        var newMonth = date.getMonth() + 1;
        var newDate = date.getDate();
        var newYear = date.getFullYear();

        return newMonth + '/' + newDate + '/' + newYear
    }

    compare(b, a) {
        if (a.dateCompleted < b.dateCompleted)
            return -1;
        if (a.dateCompleted > b.dateCompleted)
            return 1;
        return 0;
    }


    //This is needed in the PArent component because we use the total average 
    generateSurveyCards() {
        if (this.props.user.surveyInfo.surveys.length > 0) {
            var totalValue = 0
            var totalCount = 0
            var sortedSurveys = this.props.user.surveyInfo.surveys.sort(this.compare);
            var surveyCards =
                sortedSurveys.map((survey) => {
                    var value = parseFloat(survey.rating)
                    if (value) {
                        totalValue += value
                        totalCount += 1
                        return (
                            <Row>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ fontSize: '1.5em', fontFamily: 'Raleway' }}>{survey.review}</span>
                                </Row>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Col mdOffset={4} md={4} sm={12}>
                                        <ReactStars count={5} size={20} value={value} color2={'#ffd700'} edit={false} />
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <h6>{this.translateDate(survey.dateCompleted)}</h6>
                                    </Col>
                                </Row>
                                <hr />
                            </Row>)
                    }

                })

            //Average Score
            var average = totalValue / totalCount
            return { surveys: surveyCards, average: average };
        }
        else {
            return { surveys: (<h3>No Surveys for {this.props.user.firstName + ' ' + this.props.user.lastName}</h3>), average: 5 };

        }
    }

    formatPhone(num) {
        var newNum = num.replace('(', '').replace(')', '').replace(/-/g, '').replace(' ', '')
        return newNum
    }

    handleSelect(profilePage) {
        this.setState({ profilePage: profilePage })
    }
    render() {
        console.log(this.props.employeeInfo)
        var surveys = this.generateSurveyCards()
        return (
            <Grid>
                <br />
                <ProfileCardView user={this.props.user} average={surveys.average} />

                <hr />
                {this.state.width <= 768 && <Row>
                    <Nav bsStyle="tabs" activeKey={this.state.profilePage} onSelect={profilePage => this.handleSelect(profilePage)}>
                        <NavItem eventKey="SurveyInfo" style={{ textAlign: 'center' }}><FontAwesome.FaCheckSquareO style={{ fontSize: 20 }} /></NavItem>
                        <NavItem eventKey="GameInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGamepad style={{ fontSize: 20 }} /></NavItem>
                        <NavItem eventKey="Licensing Info" style={{ textAlign: 'center' }}><FontAwesome.FaCreditCard style={{ fontSize: 20 }} /></NavItem>
                    </Nav>
                </Row>}
                {this.state.width > 768 && <Row>
                    <Nav bsStyle="tabs" activeKey={this.state.profilePage} onSelect={profilePage => this.handleSelect(profilePage)}>
                        <NavItem eventKey="SurveyInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaCheckSquareO style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Surveys Info</Row></NavItem>
                        <NavItem eventKey="GameInfo" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaGamepad style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Games Info</Row></NavItem>
                        <NavItem eventKey="Licensing Info" style={{ textAlign: 'center', fontSize: 15, width: '16%' }}><Row><FontAwesome.FaCreditCard style={{ fontSize: 35, paddingRight: '10' }} /></Row><Row>Licensing Info</Row></NavItem>
                    </Nav>

                </Row>}
                <Row>
                    {this.state.profilePage === 'SurveyInfo' && <SurveyView surveys={surveys} />}
                    {this.state.profilePage === 'GameInfo' && <h1>GAMES</h1>}
                    {this.state.profilePage === "Licensing Info" && <LicensingView license={this.props.user.licensingInfo} />}
                </Row>


            </Grid >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        communicationInfo: state.communicationReducer,
        employeeInfo: state.employeeReducer

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            getSurvey: getSurvey
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MyProfileView);
