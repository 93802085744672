import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../actions/hrActions';
import { MenuItem, DropdownButton } from 'react-bootstrap';


const statuses = ['Full-Time Hourly', 'Part-Time Hourly', 'Full-Time Salary', 'Part-Time Salary']
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

class ApplicationStatusDropdown extends Component {
    constructor(props) {
        super();
        this.state = {
      
        };

        this.getSuggestionValue = this.getSuggestionValue.bind(this);
    }

    componentDidMount() {
            this.setState({ selected: this.props.hrInfo.selectedApplicant.data.employmentInfo.employmentStatus })
    }

    

    getSuggestionValue(hiringIndex) {
        this.props.setStatusValue(statuses[hiringIndex])
        this.setState({ selected: statuses[hiringIndex] })
    }

    render() {
        var options = statuses.map((status, i) => {
       
            return (<MenuItem eventKey={i}>{status}</MenuItem>)
        })


        return (<DropdownButton
            bsStyle='default'
            bsSize='large'
            title={this.state.selected}
            key='hiring'
            id={`dropdown-basic-hiring`}
            onSelect={(value) => this.getSuggestionValue(value)}
        >
            {options}
        </DropdownButton>)
    }

}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatusDropdown);
