import { put, takeEvery, call, race } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//Employees Saga
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
const watchCommunication = function* watchHr() {
    yield takeEvery("SEND_EMAIL", sendEmail);
    yield takeEvery("SEND_SMS", sendSMS);
    yield takeEvery("CREATE_TICKET", createTicket);
    yield takeEvery("GET_TICKET", getTicket);
    yield takeEvery("UPLOAD_EXCLUSIONARY_FILE", uploadFile);
    yield takeEvery("GET_SURVEY", getSurvey);

};

const sendSMS = function* sendSMS(action) {
    try {
        yield put({ type: 'SEND_SMS_STARTED' })
        var smsResponse = yield call(sendSMSData, action.payload);
        if (smsResponse.status === 200) {
            yield put({ type: 'SEND_SMS_SUCCEEDED' })
        }
        else {
            yield put({ type: 'SEND_SMS_ERRORED', sendSMSErrorMessage: smsResponse.response })
        }
    }
    catch (error) {
        yield put({ type: 'SEND_SMS_ERRORED', sendSMSErrorMessage: error.message })
    }
}
const sendSMSData = (messageData) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.send_sms, {
        method: 'POST',
        headers: {
            'number': messageData.number,
            'message': messageData.message
        },
    }).then(response => {
        return { status: 200, response: response.response };
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};
const sendEmail = function* sendEmail(action) {

    try {
        yield put({ type: 'SEND_EMAIL_STARTED' })
        var emailResponse = yield call(sendEmailData, action.payload);
        if (emailResponse.status === 200) {
            yield put({ type: 'SEND_EMAIL_SUCCEEDED' })
        }
        else {
            yield put({ type: 'SEND_EMAIL_ERRORED', sendEmailErrorMessage: emailResponse.response })
        }
    }
    catch (error) {
        yield put({ type: 'SEND_EMAIL_ERRORED', sendEmailErrorMessage: error.message })
    }
}
const sendEmailData = (messageData) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.send_email, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(messageData)
    }).then(response => {
        return { status: 200, response: response.response };
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};
const createTicket = function* createTicket(action) {

    try {
        yield put({ type: 'CREATE_TICKET_STARTED' })
        //Create Ticket
        var createTicketResponse = yield call(createTicketData, action.payload.ticketData);
        if (createTicketResponse.status === 200) {

            //Add TicketInfo to Applicant Obj
            var updatedApplicant = action.payload.applicantData;
            updatedApplicant.data.hiringInfo.iTTicketInfo = createTicketResponse.ticketInfo
            //Upsert and update Selected Applicant
            yield put({ type: 'UPSERT_HR_DATA', payload: updatedApplicant })
            yield put({ type: 'SET_SELECTED_APPLICANT', payload: { selectedApplicant: updatedApplicant } })
            yield put({ type: 'CREATE_TICKET_SUCCEEDED' })
        }
        else {
            yield put({ type: 'CREATE_TICKET_ERRORED', createTicketErrorMessage: createTicketResponse.response })
        }
    }
    catch (error) {
        yield put({ type: 'CREATE_TICKET_ERRORED', createTicketErrorMessage: error.message })
    }
}
const createTicketData = (messageData) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.create_ticket, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(messageData)
    }).then(response => response.json()).then(response => {
        return response;
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};

const getTicket = function* getTicket(action) {

    try {
        yield put({ type: 'GET_TICKET_STARTED' })

        var getTicketResponse = yield call(getTicketData, action.payload.trackingId);
        if (getTicketResponse.status === 200) {
            var updatedApplicant = action.payload.applicantData;
            updatedApplicant.data.hiringInfo.iTTicketInfo = getTicketResponse.ticketInfo
            //Upsert and update Selected Applicant
            yield put({ type: 'UPSERT_HR_DATA', payload: updatedApplicant })
            yield put({ type: 'SET_SELECTED_APPLICANT', payload: { selectedApplicant: updatedApplicant } })
            yield put({ type: 'GET_TICKET_SUCCEEDED' })
        }
        else {
            yield put({ type: 'GET_TICKET_ERRORED', getTicketErrorMessage: getTicketResponse.response })
        }
    }
    catch (error) {
        yield put({ type: 'GET_TICKET_ERRORED', getTicketErrorMessage: error.message })
    }
}
const getTicketData = (trackingId) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.get_ticket, {
        method: 'GET',
        headers: {
            'tracking-id': trackingId,

        },
    }).then(response => response.json()).then(response => {
        return response;
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};
const uploadFile = function* uploadFile(action) {

    try {
        yield put({ type: 'UPLOAD_EXCLUSIONARY_FILE_STARTED' })
        var promises = []

        for (var i = 0; i < action.payload.fileData.length; i++) {
            promises.push(uploadFileData(action.payload.fileData[i]))
        }
        var promData = yield Promise.all(promises)
        var hasError = false;
        var errorMessage =''
        promData.map((response) => {
            if (response.status == 400) {
                hasError = true;
                errorMessage = response.errorMessage;
            }
        })
        if (!hasError) {
             yield put({ type: 'UPLOAD_EXCLUSIONARY_FILE_SUCCEEDED' })

        }
        else {
            yield put({ type: 'UPLOAD_EXCLUSIONARY_FILE_ERRORED', uploadFileErrorMessage: errorMessage })
        }
    }
    catch (error) {
        yield put({ type: 'UPLOAD_EXCLUSIONARY_FILE_ERRORED', uploadExclusionaryFileErrorMessage: 'Issue' })
    }
}
const uploadFileData = (fileData) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.upload_exclusionary_file, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fileData)
    }).then(response => response.json()).then(response => {
        return response;
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};

const getSurvey = function* getSurvey(action) {

    try {
        yield put({ type: 'GET_SURVEY_STARTED' })
        var promises = []

        for (var i = 0; i < action.payload.emails.length; i++) {
            promises.push(getSurveyData(action.payload.emails[i]))
        }
        var promData = yield Promise.all(promises)
        var hasError = false;
        var errorMessage =''
        var currentUserSurveys =[]
        promData.map((result) => {
            if (result.status == 400) {
                hasError = true;
                errorMessage = result.errorMessage;
            }
            else
            {
                currentUserSurveys = currentUserSurveys.concat(result.response)

            }
        })
        if (!hasError) {
             yield put({ type: 'GET_SURVEY_SUCCEEDED',currentUserSurveys:currentUserSurveys })
        }
        else {
            yield put({ type: 'GET_SURVEY_ERRORED', uploadFileErrorMessage: errorMessage })
        }
    }
    catch (error) {
        yield put({ type: 'GET_SURVEY_ERRORED', getSurveyErrorMessage: error.message })
    }
}
const getSurveyData = (email) => {
    return fetch(settings.URL + settings.PORT + settings.ENDPOINTS.get_surveys+'/'+email, {
        method: 'GET',
    }).then(response => response.json()).then(response => {
        return response;
    }).catch(function (error) {
        return { status: 400, response: error.message };
    });
};
export default watchCommunication;