export const addSubmission = (data) => {
    return {
        type: 'ADD_ACTIVITY_SUBMISSION',
        payload:data
    }
};
export const getActivities = (data) => {
    return {
        type: 'GET_ACTIVITIES',
        payload:data

    }
};

export const deleteActivity = (data) => {
    return {
        type: 'DELETE_ACTIVITY',
        payload:data

    }
};

export const upsertActivity = (data) => {
    return {
        type: 'UPSERT_ACTIVITY',
        payload:data

    }
};
export const uploadActivityImage = (data) => {
    return {
        type: 'UPLOAD_ACTIVITY_IMAGE',
        payload:data

    }
};
export const restartUploadActivityImage = () => {
    return {
        type: 'UPLOAD_ACTIVITY_IMAGE_STARTED',


    }
};
export const setSelectedActivity = (payload) => {
    return {
        type: 'SET_SELECTED_ACTIVITY',
        payload:payload
    }
};

export const resetActivities = (data) => {
    return {
        type: 'RESET_ACTIVITIES'

    }
};