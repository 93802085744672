import axios from 'axios'
import moment from 'moment'
import 'twix'
import { call, put, race, takeEvery } from 'redux-saga/effects'
import settings from '../settings.js'
import { regions } from '../constants/region.js'

const regionMap = {
  131: '002',
  206: '002',
  208: '002',
  302: '002',
  304: '002',
  401: '002',
  426: '002',
  468: '002',
  488: '002',
  511: '002',
  517: '002',
  530: '002',
  560: '002',
  567: '002',
  586: '002',
  589: '002',
  777: '002',
  779: '002',
  803: '002',
  123: '003',
  150: '003',
  159: '003',
  164: '003',
  493: '004',
  721: '005',
  723: '005',
  724: '005',
  775: '005',
  776: '005',
  727: '005',
  728: '005',
  729: '005',
  731: '005',
  732: '005',
  734: '005',
  737: '005',
  738: '005',
  741: '005',
  753: '005',
  759: '005',
  762: '005',
  783: '005',
  203: '006',
  207: '006',
  453: '006',
  551: '006',
  581: '006',
  582: '006',
  600: '006',
  703: '006',
  739: '006',
  747: '006',
  764: '006',
  767: '006',
  768: '006',
  714: '006',
  770: '006',
  771: '006',
  772: '006',
  773: '006',
  774: '006',
  780: '006',
  784: '006',
  200: '007',
  204: '007',
  235: '007',
  545: '007',
  585: '007'
}

const today = new Date()

const monthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

// Pipeline Saga

const watchPipeline = function * watchGetPipeline () {
  yield takeEvery('GET_PIPELINE', getPipeline)

  yield takeEvery('GET_PIPELINE_REPORT', getPipelineReport)

  // This corresponds to the Funding Report Menu Item....
  // did not change the name because there is something
  // else that was doing Funding reports
  yield takeEvery('GET_BANK_REPORT', getBankReport)

  yield takeEvery('GET_DASHBOARD_REPORT', getDashboardReport)
  yield takeEvery('GET_PIPELINE_LEADERBOARD', getPipelineLeaderboard)
  yield takeEvery('GET_PIPELINE_NUMBERS_REPORT', getPipelineNumbersReport)
  yield takeEvery('GET_LOAN_COMPARE_LIST', getLoanInfoForLoanCompareList)
  yield takeEvery('GET_SINGLE_LOAN_FROM_WAREHOUSE', getSingleLoanFromWarehouse)
  yield takeEvery('GET_MULTIPLE_LOANS_FROM_WAREHOUSE', getMultipleLoansFromWarehouse)
  yield takeEvery('GET_SINGLE_LOAN_FROM_ENCOMPASS', getSingleLoanFromEncompass)
  yield takeEvery('GET_TRENDS', getTrends)
  yield takeEvery('GET_CLOSING_REPORT', getClosingReport)
  yield takeEvery('GET_FUNDINGS', getFundings)
  yield takeEvery('GET_LOAN_AUDIT', auditLoan)
}

// Functions for a View
const getPipeline = function * getPipeline (action) {
  var newDataPipeline = {}
  var loanNumberPipeline = {}

  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_PIPELINE_STARTED' })
  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Pipeline' })

  // Gets Pipeline
  try {
    // Encompass Pipeline Call To get the new data for today/filter type combination
    var encompassConfig = {
      config: {
        filter:
        {
          operator: 'and',
          terms: [
            {
              canonicalName: 'Fields.' + action.payload.filter,
              value: action.payload.searchStartDate,
              matchType: 'greaterThanOrEquals',
              precision: 'day'
            },
            {
              canonicalName: 'Fields.' + action.payload.filter,
              value: action.payload.endDate,
              matchType: 'lessThanOrEquals',
              precision: 'day'
            },
            {
              canonicalName: 'Fields.420',
              value: 'Second Lie',
              matchType: 'exact',
              include: false
            },
            {
              canonicalName: 'Fields.2626',
              value: 'Brokered',
              matchType: 'exact',
              include: false
            },
            {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: '2012 Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Audit Files',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Bonds 2nds',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Consumer Connect',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Denials to be printed',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects (Current)',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'My Pipeline',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Pending Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Servicing',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Withdraws to be printed',
                      matchType: 'exact',
                      include: 'true'
                    }
                  ]
                }
              ]
            }
          ]
        },
        fields: [
          'Loan.LoanNumber' // Loan Number
        ],
        sortOrder: [
          {
            canonicalName: 'Fields.317',
            order: 'asc'
          }
        ]
      }
    }

    // Get Loan Numbers of all loans in users pipeline

    loanNumberPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfig)

    if (loanNumberPipeline.data === undefined) {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002: Encompass Is Down' })
      return
    }

    if (Array.isArray(loanNumberPipeline.data)) {
      var loanNumbers = loanNumberPipeline.data.map((loan) => {
        return loan.loanGuid
      })

      // Get data from Warehouse that is in the desired range using the loan numbers
      var loanNumberChunks = chunkArray(loanNumbers, 1000)

      var config = {
        query: { guid: { $in: loanNumberChunks[0] } },
        project: {
          guid: 1,
          'saveState.loanData.ApplicationDate': 1,
          'saveState.loanData.PreApplicationDate': 1,
          'saveState.loanData.ShippingDate': 1,
          'saveState.loanData.UWCleartoCloseDate': 1,
          'saveState.loanData.UWApprovalDate': 1,
          'saveState.loanData.UWCreditApprovalDate': 1, // Cond Approval
          'saveState.loanData.EstClosingDate': 1,
          'saveState.loanData.FundsReleasedDate': 1,
          'saveState.loanData.RateLockExpires': 1, // Rate Locked
          'saveState.loanData.BuySideLockedDate': 1, // Locked
          'saveState.loanData.UWResubmittalDate': 1,
          'saveState.loanData.SubmittedToProcessingDate': 1,
          'saveState.loanData.UWSubmittedDate': 1,
          'saveState.loanData.BorrowerFirstName': 1,
          'saveState.loanData.BorrowerLastName': 1,
          'saveState.loanData.Branch': 1,
          'saveState.loanData.CloserName': 1,
          'saveState.loanData.ShipperName': 1,
          'saveState.loanData.TeamColor': 1,
          'saveState.loanData.UnderwriterName': 1,
          'saveState.loanData.ProcessorName': 1,
          'saveState.loanData.LoanOfficerName': 1,
          'saveState.loanData.LoanOfficerEmail': 1,
          'saveState.loanData.LoanAmount': 1,
          'saveState.loanData.LoanNumber': 1,
          'saveState.loanData.TotalLoanAmount': 1,
          _id: 0
        }

      }
      var mongoData = yield call(executeMongoFindDBQuery, 'trimmedWarehouse', config)
      // Translate mongoData
      var allMongoData = mongoData.map((doc) => {
        return translateMongoDoc(doc)
      })

      // Only get data that is in the range
      yield put({ type: 'GET_PIPELINE_SUCCEEDED', pipelineData: newDataPipeline, warehousePipelineData: allMongoData })
    } else if (loanNumberPipeline.data.status === 401) {
      // This case is for when a users Password has changed
      yield put({ type: 'ENCOMPASS_LOGIN_FAILED', getPipelineErrorMessage: 'AO.PLN.001:' + loanNumberPipeline.data.response })
      yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })
    } else if (loanNumberPipeline.data.status === 400) {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002:' + loanNumberPipeline.data.response })
    } else {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002:' + loanNumberPipeline.data.response })
    }
  } catch (error) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002: Something really bad happened and Encompass doesn\'t know!' })
  }
}

// Get Pipeline From Encompass
const getPipelineReport = function * getPipelineReport (action) {
  yield put({ type: 'RESET_PIPELINE_STATUS' })

  yield put({ type: 'GET_PIPELINE_REPORT_STARTED' })

  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Loan Data From Warehouse...' })

  var loanNumberConfigs = getLoanNumberReportConfigs(action.payload)

  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Pipeline from Encompass this may take a few minutes...' })

  var promises = []

  for (let i = 0; i < loanNumberConfigs.length; i++) {
    promises.push(fetchLoanNumberPipelineData(action.payload, loanNumberConfigs[i]))
  }

  var encompassPromiseData = yield Promise.all(promises)

  var loanNumbers = []
  var errorArray = []

  encompassPromiseData.map((promiseResult) => {
    if (Array.isArray(promiseResult.data)) {
      promiseResult.data.map((promiseGuid) => {
        loanNumbers.push(promiseGuid.loanGuid)
      })
    } else {
      errorArray.push(promiseResult.data)
    }
  })

  // Handle Errors that were found during Promise All, it is safe to assume that grabing the first result will suffice
  if (errorArray.length > 0 && errorArray[0].status === 401) {
    // This case is for when a users Password has changed
    yield put({ type: 'ENCOMPASS_LOGIN_FAILED_REPORT', getPipelineReportErrorMessage: 'AO.PLN.003:' + errorArray[0].response })

    yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })

    return
  } else if (errorArray.length > 0 && errorArray[0].status !== 200) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_REPORT', getPipelineReportErrorMessage: 'AO.PLN.004:' + errorArray[0].response })

    return
  }

  yield put({ type: 'UPDATE_PROGRESS', progress: 50, progressText: 'Got Pipeline...Getting Loan Information...' })

  // Get Loans from Mongo
  // We have to split up the loannumbers because it can be to much data to return back
  promises = []

  var loanNumberChunks = chunkArray(loanNumbers, 1000)

  var badGuids = []

  for (let j = 0; j < loanNumberChunks.length; j++) {
    var config = {
      query: { guid: { $in: loanNumberChunks[j] } },
      project: {
        guid: 1,
        // Underwriting Credit Approval Date (Decisions Made)
        'saveState.loanData.UWCreditApprovalDate': 1,
        // Underwriting Denied Date (Decisions Made)
        'saveState.loanData.UWDeniedDate': 1,
        // Underwriting Suspended Date (Decisions Made)
        'saveState.loanData.UWSuspendedDate': 1,
        'saveState.loanData.ApplicationDate': 1,
        'saveState.loanData.PreApplicationDate': 1,
        'saveState.loanData.ShippingDate': 1,
        'saveState.loanData.UWCleartoCloseDate': 1,
        'saveState.loanData.UWApprovalDate': 1,
        'saveState.loanData.EstClosingDate': 1,
        'saveState.loanData.FundsReleasedDate': 1,
        'saveState.loanData.RateLockExpires': 1, // Rate Locked
        'saveState.loanData.BuySideLockedDate': 1, // Locked
        'saveState.loanData.UWResubmittalDate': 1,
        'saveState.loanData.SubmittedToProcessingDate': 1,
        'saveState.loanData.UWSubmittedDate': 1,
        'saveState.loanData.BorrowerFirstName': 1,
        'saveState.loanData.BorrowerLastName': 1,
        'saveState.loanData.Branch': 1,
        'saveState.loanData.Team': 1,
        'saveState.loanData.Region': 1,
        'saveState.loanData.Department': 1,
        'saveState.loanData.CloserName': 1,
        'saveState.loanData.ShipperName': 1,
        'saveState.loanData.TeamColor': 1,
        'saveState.loanData.UnderwriterName': 1,
        'saveState.loanData.ProcessorName': 1,
        'saveState.loanData.LoanOfficerName': 1,
        'saveState.loanData.LoanOfficerEmail': 1,
        'saveState.loanData.LoanAmount': 1,
        'saveState.loanData.LoanNumber': 1,
        'saveState.loanData.TotalLoanAmount': 1,
        _id: 0
      }

    }
    promises.push(executeMongoFindDBQuery('trimmedWarehouse', config))
  }
  var mongoPromiseData = yield Promise.all(promises)
  var mergedMongoPromiseData = [].concat.apply([], mongoPromiseData)

  // Translate and Return mongoData
  yield put({ type: 'UPDATE_PROGRESS', progress: 90, progressText: 'Just about there...' })

  var allMongoData = mergedMongoPromiseData.map((doc) => {
    if (doc.saveState[0]) {
      return translateMongoDoc(doc)
    } else {
      badGuids.push(doc.guid)
      console.log('THIS IS A PROBLEM')
    }
  })

  yield put({ type: 'GET_PIPELINE_REPORT_SUCCEEDED', pipelineReportData: allMongoData })
}

const getBankReport = function * getBankReport (action) {
  yield put({ type: 'RESET_PIPELINE_STATUS' })

  yield put({ type: 'GET_BANK_REPORT_STARTED' })

  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Loan Data From Warehouse...' })

  var loanNumberConfigs = getLoanNumberReportConfigs(action.payload)

  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Pipeline from Encompass this may take a few minutes...' })
  var promises = []
  for (let i = 0; i < loanNumberConfigs.length; i++) {
    promises.push(fetchLoanNumberPipelineData(action.payload, loanNumberConfigs[i]))
  }
  var encompassPromiseData = yield Promise.all(promises)

  var loanGuids = []
  var errorArray = []
  var bankWarehouseMapping = {}

  encompassPromiseData.map((promiseResult) => {
    if (Array.isArray(promiseResult.data)) {
      promiseResult.data.map((promiseResultData) => {
        loanGuids.push(promiseResultData.loanGuid)
        bankWarehouseMapping[promiseResultData.fields['Loan.LoanNumber']] = promiseResultData.fields['Fields.VEND.X200']
      })
    } else { errorArray.push(promiseResult.data) }
  })
  // Handle Errors that were found during Promise All, it is safe to assume that grabing the first result will suffice
  if (errorArray.length === encompassPromiseData.length && errorArray[0].status === 401) {
    // This case is for when a users Password has changed
    yield put({ type: 'ENCOMPASS_LOGIN_FAILED_REPORT', getPipelineReportErrorMessage: 'AO.PLN.003:' + errorArray[0].response })

    yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })

    return
  } else if (errorArray.length === encompassPromiseData.length && errorArray[0].status !== 200) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_REPORT', getPipelineReportErrorMessage: 'AO.PLN.004:' + errorArray[0].response })

    return
  }

  yield put({ type: 'UPDATE_PROGRESS', progress: 50, progressText: 'Got Pipeline...Getting Loan Information...' })

  // Get Loans from Mongo
  // We have to split up the loannumbers because it can be to much data to return back
  promises = []
  var loanGuidChunks = chunkArray(loanGuids, 1000)
  var badGuids = []
  for (let j = 0; j < loanGuidChunks.length; j++) {
    var config = {
      query: action.payload.region !== 'All' ? { $and: [{ guid: { $in: loanGuidChunks[j] } }, { 'saveState.loanData.Region': action.payload.region }] } : { $and: [{ guid: { $in: loanGuidChunks[j] } }] },
      project: {
        guid: 1,
        'saveState.loanData.FundsReleasedDate': 1,
        'saveState.loanData.BorrowerFirstName': 1,
        'saveState.loanData.BorrowerLastName': 1,
        'saveState.loanData.Team': 1,
        'saveState.loanData.Region': 1,
        'saveState.loanData.Department': 1,
        'saveState.loanData.LoanOfficerName': 1,
        'saveState.loanData.LoanAmount': 1,
        'saveState.loanData.LoanNumber': 1,
        'saveState.loanData.Investor': 1,
        'saveState.loanData.TotalLoanAmount': 1,
        'saveState.loanData.LoanProgram': 1,
        'saveState.loanData.LoanPurpose': 1,
        'saveState.loanData.LoanType': 1,
        'saveState.loanData.LoanInfoChannel': 1,
        'saveState.loanData.SubjectPropertyState': 1,
        _id: 0
      },
      sort: {
        'saveState.loanData.Department': 1
      }

    }
    promises.push(executeMongoFindDBQuery('trimmedWarehouse', config))
  }
  var mongoPromiseData = yield Promise.all(promises)
  var mergedMongoPromiseData = [].concat.apply([], mongoPromiseData)

  // Translate and Return mongoData
  yield put({ type: 'UPDATE_PROGRESS', progress: 90, progressText: 'Just about there...' })

  var allMongoData = mergedMongoPromiseData.map((doc) => {
    if (doc.saveState[0]) {
      return translateMongoBankDoc(doc)
    } else {
      badGuids.push(doc.guid)
    }
  })

  // Initialize to capture the Grand Totals for all Loans
  var bankOrganizedData = {
    TotalCount: 0,
    TotalLoanVolume: 0,
    BankedCount: 0,
    BankedLoanVolume: 0,
    '%CapturedBankedLoanCount': 0,
    '%CapturedBankedLoanVolume': 0
  }

  // Organize Data
  allMongoData.map((loan) => {
    var currentDepartment = loan.Department
    if (currentDepartment === '212' || currentDepartment === '203') {
      currentDepartment = '203/212'
    }
    if (currentDepartment === '121' || currentDepartment === '206') {
      currentDepartment = '206/121'
    }

    // If a bankOrganizedData does not contain the department as a key we will go ahead and create an object that will be used to add values
    if (!bankOrganizedData[currentDepartment]) {
      // Initialize to get all the totals for a particular Department
      bankOrganizedData[currentDepartment] = {
        Loans: [],
        TotalCount: 0,
        TotalLoanVolume: 0,
        BankedCount: 0,
        BankedLoanVolume: 0,
        '%CapturedBankedLoanCount': 0,
        '%CapturedBankedLoanVolume': 0
      }
    }

    // Add info to the Loans Array
    var bankInfo = {}
    bankInfo.LoanNumber = loan.LoanNumber
    bankInfo.Department = currentDepartment
    bankInfo.Division = loan.Region
    bankInfo.Team = loan.Team
    bankInfo.LoanOfficerName = loan.LoanOfficerName
    bankInfo.TotalCount = 1
    bankInfo.TotalLoanVolume = loan.TotalLoanAmount
    bankInfo.BankedCount = loan.LoanInfoChannel === 'Banked - Retail' ? 1 : 0
    bankInfo.BankedLoanVolume = loan.LoanInfoChannel === 'Banked - Retail' ? loan.TotalLoanAmount : 0
    bankInfo['%CapturedBankedLoanCount'] = ''
    bankInfo['%CapturedBankedLoanVolume'] = ''
    bankInfo.BorrowerName = loan.BorrowerFirstName + ' ' + loan.BorrowerLastName
    bankInfo.SubjectPropertyState = loan.SubjectPropertyState
    bankInfo.FundsSentDate = loan.FundedDate
    bankInfo.LoanType = loan.LoanType
    bankInfo.LoanPurpose = loan.LoanPurpose
    bankInfo.Investor = loan.Investor
    bankInfo.LoanInfoChannel = loan.LoanInfoChannel
    bankInfo.LoanProgram = loan.LoanProgram

    // Add this to the Loans Object for that department
    bankOrganizedData[currentDepartment].Loans.push(bankInfo)

    // Add this loan to the total department value
    bankOrganizedData[currentDepartment].TotalCount = bankOrganizedData[currentDepartment].TotalCount + 1
    bankOrganizedData[currentDepartment].BankedCount = loan.LoanInfoChannel === 'Banked - Retail' ? bankOrganizedData[currentDepartment].BankedCount + 1 : bankOrganizedData[currentDepartment].BankedCount // If this is Banked we will increment otherwise keep the same
    bankOrganizedData[currentDepartment].TotalLoanVolume = (parseFloat(bankOrganizedData[currentDepartment].TotalLoanVolume) + parseFloat(bankInfo.TotalLoanVolume))
    bankOrganizedData[currentDepartment].BankedLoanVolume = loan.LoanInfoChannel === 'Banked - Retail' ? (parseFloat(bankOrganizedData[currentDepartment].BankedLoanVolume) + parseFloat(bankInfo.BankedLoanVolume)) : bankOrganizedData[currentDepartment].BankedLoanVolume// If this is Banked we will increment otherwise keep the same
    bankOrganizedData[currentDepartment]['%CapturedBankedLoanCount'] = (bankOrganizedData[currentDepartment].BankedCount / bankOrganizedData[currentDepartment].TotalCount) * 100
    bankOrganizedData[currentDepartment]['%CapturedBankedLoanVolume'] = (bankOrganizedData[currentDepartment].BankedLoanVolume / bankOrganizedData[currentDepartment].TotalLoanVolume) * 100

    // Add this object to the grand total values
    bankOrganizedData.TotalCount = bankOrganizedData.TotalCount + 1
    bankOrganizedData.BankedCount = loan.LoanInfoChannel === 'Banked - Retail' ? bankOrganizedData.BankedCount + 1 : bankOrganizedData.BankedCount // If this is Banked we will increment otherwise keep the same
    bankOrganizedData.TotalLoanVolume = (parseFloat(bankOrganizedData.TotalLoanVolume) + parseFloat(bankInfo.TotalLoanVolume))
    bankOrganizedData.BankedLoanVolume = loan.LoanInfoChannel === 'Banked - Retail' ? (parseFloat(bankOrganizedData.BankedLoanVolume) + parseFloat(bankInfo.BankedLoanVolume)) : bankOrganizedData.BankedLoanVolume// If this is Banked we will increment otherwise keep the same
    bankOrganizedData['%CapturedBankedLoanCount'] = (bankOrganizedData.BankedCount / bankOrganizedData.TotalCount) * 100
    bankOrganizedData['%CapturedBankedLoanVolume'] = (bankOrganizedData.BankedLoanVolume / bankOrganizedData.TotalLoanVolume) * 100
  })

  // Write Data for xlsx
  var bankReportData = []
  var headerData = ['Loan #', 'Department', 'Division', 'Team', 'Loan Officer Name', ' Total Loan Count', 'Total Loan Volume', 'BankedCount', 'Banked Volume', '% Captured Banked Loan Count', '% Captured Banked Loan Volume', ' Borrower Name', 'Subject Property State', 'Funds Sent Date', 'Loan Type', 'Loan Purpose', 'Investor Name', 'Loan Info Channel', 'Warehouse Co Name', 'Loan Program']
  bankReportData.push(headerData) // Adds Header
  bankReportData.push(['', 'Grand Total', '', '', '', bankOrganizedData.TotalCount, bankOrganizedData.TotalLoanVolume, bankOrganizedData.BankedCount, bankOrganizedData.BankedLoanVolume, bankOrganizedData['%CapturedBankedLoanCount'], bankOrganizedData['%CapturedBankedLoanVolume'], '', '', '', '', '', '', '', '', '']) // Adds Grand Total Row
  // Loop through all of the departments
  Object.keys(bankOrganizedData).map((department) => {
    if (bankOrganizedData[department].Loans) {
      // Add Department Total
      bankReportData.push(['', department + ' Total', '', '', '', bankOrganizedData[department].TotalCount, bankOrganizedData[department].TotalLoanVolume, bankOrganizedData[department].BankedCount, bankOrganizedData[department].BankedLoanVolume, bankOrganizedData[department]['%CapturedBankedLoanCount'], bankOrganizedData[department]['%CapturedBankedLoanVolume'], '', '', '', '', '', '', '', '', '']) // Adds Grand Total Row
      // Loop through all Loans to Add those
      bankOrganizedData[department].Loans.map((loan) => {
        bankReportData.push([
          loan.LoanNumber,
          loan.Department,
          loan.Division,
          loan.Team,
          loan.LoanOfficerName,
          loan.TotalCount,
          parseFloat(loan.TotalLoanVolume),
          loan.BankedCount,
          parseFloat(loan.BankedLoanVolume),
          loan['%CapturedBankedLoanCount'],
          loan['%CapturedBankedLoanVolume'],
          loan.BorrowerName,
          loan.SubjectPropertyState,
          loan.FundsSentDate,
          loan.LoanType,
          loan.LoanPurpose,
          loan.Investor,
          loan.LoanInfoChannel,
          bankWarehouseMapping[loan.LoanNumber],
          loan.LoanProgram
        ])
      })
    }
  })

  yield put({ type: 'GET_BANK_REPORT_SUCCEEDED', bankReportData: bankReportData })
}

const getDashboardReport = function * getDashboardReport (action) {
  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_PIPELINE_DASHBOARD_STARTED' })
  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Loan Data From Warehouse...' })

  // Get Loans from Mongo
  // We have to split up the loannumbers because it can be to much data to return back

  var start = convertDate(action.payload.startDate, 'start')
  var end = convertDate(action.payload.endDate, 'end')

  var query = {}
  if (action.payload.region === 'All') {
    query = {
      $or: [
        { $and: [{ 'saveState.loanData.PreApplicationDate': { $gte: start } }, { 'saveState.loanData.PreApplicationDate': { $lte: end } }] },
        { $and: [{ 'saveState.loanData.FundsReleasedDate': { $gte: start } }, { 'saveState.loanData.FundsReleasedDate': { $lte: end } }] },
        { $and: [{ 'saveState.loanData.UWSubmittedDate': { $gte: start } }, { 'saveState.loanData.UWSubmittedDate': { $lte: end } }] }]
    }
  } else {
    query = {
      $and: [{ 'saveState.loanData.Region': action.payload.region },
        {
          $or: [
            { $and: [{ 'saveState.loanData.PreApplicationDate': { $gte: start } }, { 'saveState.loanData.PreApplicationDate': { $lte: end } }] },
            { $and: [{ 'saveState.loanData.FundsReleasedDate': { $gte: start } }, { 'saveState.loanData.FundsReleasedDate': { $lte: end } }] },
            { $and: [{ 'saveState.loanData.UWSubmittedDate': { $gte: start } }, { 'saveState.loanData.UWSubmittedDate': { $lte: end } }] }]
        }
      ]
    }
  }
  // All Application
  var mongoConfig = {
    query: query,
    project: {
      guid: 1,
      'saveState.loanData.PreApplicationDate': 1,
      'saveState.loanData.FundsReleasedDate': 1,
      'saveState.loanData.UWSubmittedDate': 1,
      'saveState.loanData.Team': 1,
      'saveState.loanData.BorrowerFirstName': 1,
      'saveState.loanData.BorrowerLastName': 1,
      'saveState.loanData.Department': 1,
      'saveState.loanData.Region': 1,
      'saveState.loanData.Branch': 1,
      'saveState.loanData.LoanOfficerName': 1,
      'saveState.loanData.LoanNumber': 1,
      'saveState.loanData.LoanAmount': 1,
      _id: 0
    }
  }

  const { mongoData } = yield race({
    mongoData: call(executeMongoFindDBQuery, 'trimmedWarehouse', mongoConfig),
    timeout: call(delay, 90000)
  })

  // Translate and Return mongoData
  yield put({ type: 'UPDATE_PROGRESS', progress: 90, progressText: 'Just about there...' })

  var allMongoData = mongoData.map((doc) => {
    if (doc.saveState[0]) {
      return translateMongoDoc(doc)
    }
  })

  // Initialize to capture the Grand Totals for all Loans
  var dashboardData = {
    TotalFundedCount: 0,
    TotalFundedAmount: 0,
    TotalPreApplicationCount: 0,
    TotalPreApplicationAmount: 0,
    TotalSubUWCount: 0,
    TotalSubUWAmount: 0
  }
  // Organize Data
  allMongoData.map((loan) => {
    var currentDepartment = loan.Department
    var currentRegion = loan.Region
    var currentLO = loan.LOName
    // Create Region Objects
    if (!dashboardData[currentRegion]) {
      // Initialize to capture the Grand Totals for all regions
      dashboardData[currentRegion] = {
        TotalFundedCount: 0,
        TotalFundedAmount: 0,
        TotalPreApplicationCount: 0,
        TotalPreApplicationAmount: 0,
        TotalSubUWCount: 0,
        TotalSubUWAmount: 0
      }
    }

    // Create Department Objects in the Region Objects
    if (!dashboardData[currentRegion][currentDepartment]) {
      // Initialize to capture the Grand Totals for all Departments in a region
      dashboardData[currentRegion][currentDepartment] = {
        TotalFundedCount: 0,
        TotalFundedAmount: 0,
        TotalPreApplicationCount: 0,
        TotalPreApplicationAmount: 0,
        TotalSubUWCount: 0,
        TotalSubUWAmount: 0
      }
    }

    // Create LO Objects in the Department Objects
    if (!dashboardData[currentRegion][currentDepartment][currentLO]) {
      // Initialize to capture the Grand Totals for all LOs in a department
      dashboardData[currentRegion][currentDepartment][currentLO] = {
        TotalFundedCount: 0,
        TotalFundedAmount: 0,
        TotalPreApplicationCount: 0,
        TotalPreApplicationAmount: 0,
        TotalSubUWCount: 0,
        TotalSubUWAmount: 0,
        Loans: []
      }
    }

    // Check FundsReleasedDate
    if (dateCheck(start, end, loan.FundedDate)) {
      // Increase Counts and Totals
      dashboardData.TotalFundedCount = dashboardData.TotalFundedCount + 1
      dashboardData[currentRegion].TotalFundedCount = dashboardData[currentRegion].TotalFundedCount + 1
      dashboardData[currentRegion][currentDepartment].TotalFundedCount = dashboardData[currentRegion][currentDepartment].TotalFundedCount + 1
      dashboardData[currentRegion][currentDepartment][currentLO].TotalFundedCount = dashboardData[currentRegion][currentDepartment][currentLO].TotalFundedCount + 1
      dashboardData.TotalFundedAmount = (parseFloat(dashboardData.TotalFundedAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion].TotalFundedAmount = (parseFloat(dashboardData[currentRegion].TotalFundedAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment].TotalFundedAmount = (parseFloat(dashboardData[currentRegion][currentDepartment].TotalFundedAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].TotalFundedAmount = (parseFloat(dashboardData[currentRegion][currentDepartment][currentLO].TotalFundedAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].Loans.push(loan)
    }

    // Check PreAppDate
    if (dateCheck(start, end, loan.PreApplicationDate)) {
      // Increase Counts and Totals
      dashboardData.TotalPreApplicationCount = dashboardData.TotalPreApplicationCount + 1
      dashboardData[currentRegion].TotalPreApplicationCount = dashboardData[currentRegion].TotalPreApplicationCount + 1
      dashboardData[currentRegion][currentDepartment].TotalPreApplicationCount = dashboardData[currentRegion][currentDepartment].TotalPreApplicationCount + 1
      dashboardData[currentRegion][currentDepartment][currentLO].TotalPreApplicationCount = dashboardData[currentRegion][currentDepartment][currentLO].TotalPreApplicationCount + 1
      dashboardData.TotalPreApplicationAmount = !loan.LoanAmount ? parseFloat(dashboardData.TotalPreApplicationAmount) : (parseFloat(dashboardData.TotalPreApplicationAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion].TotalPreApplicationAmount = !loan.LoanAmount ? parseFloat(dashboardData[currentRegion].TotalPreApplicationAmount) : (parseFloat(dashboardData[currentRegion].TotalPreApplicationAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment].TotalPreApplicationAmount = !loan.LoanAmount ? parseFloat(dashboardData[currentRegion][currentDepartment].TotalPreApplicationAmount) : (parseFloat(dashboardData[currentRegion][currentDepartment].TotalPreApplicationAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].TotalPreApplicationAmount = !loan.LoanAmount ? parseFloat(dashboardData[currentRegion][currentDepartment][currentLO].TotalPreApplicationAmount) : (parseFloat(dashboardData[currentRegion][currentDepartment][currentLO].TotalPreApplicationAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].Loans.push(loan)
    }

    // Check FundsSubmittedToUWDate
    if (dateCheck(start, end, loan.SubmittedToUWDate)) {
      // Increase Counts and Totals
      dashboardData.TotalSubUWCount = dashboardData.TotalSubUWCount + 1
      dashboardData[currentRegion].TotalSubUWCount = dashboardData[currentRegion].TotalSubUWCount + 1
      dashboardData[currentRegion][currentDepartment].TotalSubUWCount = dashboardData[currentRegion][currentDepartment].TotalSubUWCount + 1
      dashboardData[currentRegion][currentDepartment][currentLO].TotalSubUWCount = dashboardData[currentRegion][currentDepartment][currentLO].TotalSubUWCount + 1
      dashboardData.TotalSubUWAmount = (parseFloat(dashboardData.TotalSubUWAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion].TotalSubUWAmount = (parseFloat(dashboardData[currentRegion].TotalSubUWAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment].TotalSubUWAmount = (parseFloat(dashboardData[currentRegion][currentDepartment].TotalSubUWAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].TotalSubUWAmount = (parseFloat(dashboardData[currentRegion][currentDepartment][currentLO].TotalSubUWAmount) + parseFloat(loan.LoanAmount))
      dashboardData[currentRegion][currentDepartment][currentLO].Loans.push(loan)
    }
  })

  yield put({ type: 'GET_PIPELINE_DASHBOARD_SUCCEEDED', dashboardReportData: dashboardData })
}

/**
 * Saga that returns all funded loans within a certain date.
 * @param {object} action object containing data about the action that we are sending to the saga
 */
const getPipelineLeaderboard = function * getPipelineLeaderboard (action) {
  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_PIPELINE_LEADERBOARD_STARTED' })
  yield put({ type: 'UPDATE_PROGRESS', progress: 50, progressText: 'Getting Loan Information...' })

  // Get Loans from Mongo
  // We have to split up the loannumbers because it can be to much data to return back

  var start = convertDate(action.payload.startDate, 'start')
  var end = convertDate(action.payload.endDate, 'end')

  // All Application
  var mongoConfig = {
    query: {
      $and: [{
        'saveState.loanData.ApplicationDate': {
          $gte: start
        }
      },
      {
        'saveState.loanData.ApplicationDate': {
          $lte: end
        }
      }]
    },
    project: {
      guid: 1,
      'saveState.loanData.ApplicationDate': 1,
      'saveState.loanData.FundsReleasedDate': 1,
      'saveState.loanData.Team': 1,
      'saveState.loanData.Department': 1,
      'saveState.loanData.Region': 1,
      'saveState.loanData.Branch': 1,
      'saveState.loanData.LoanOfficerName': 1,
      'saveState.loanData.LoanOfficerEmail': 1,
      'saveState.loanData.LoanNumber': 1,
      _id: 0
    }
  }

  const { mongoData } = yield race({
    mongoData: call(
      executeMongoFindDBQuery, 
      'trimmedWarehouse', 
      mongoConfig
    ),
    timeout: call(delay, 90000)
  })

  if (!mongoData) {
    yield put({ 
      type: 'GET_PIPELINE_LEADERBOARD_ERRORED', 
      getPipelineLeaderboardErrorMessage: 'AO.PLN.005: Warehouse DB timed out waiting for response. Contact System Adminstrator.' 
    });

    return;
  }

  // Translate and Return mongoData
  yield put({
    type: 'UPDATE_PROGRESS',
    progress: 90,
    progressText: 'Just about there...'
  });

  // Process the leader pipeline
  try {
    var formattedData = processLeaderboardPipeline(mongoData)
  } catch (error) {
    console.log('ERROR', error)
  }

  yield put({
    type: 'GET_PIPELINE_LEADERBOARD_SUCCEEDED',
    pipelineLeaderboardData: formattedData
  })
}
const getPipelineNumbersReport = function * getPipelineNumbersReport (action) {
  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_PIPELINE_NUMBERS_REPORT_STARTED' })
  yield put({ type: 'UPDATE_PROGRESS', progress: 10, progressText: 'Getting Pipeline Numbers......' })
  var mStartDate = action.payload.mStartDate
  var mEndDate = action.payload.mEndDate
  var lmStartDate = action.payload.lmStartDate
  var lmEndDate = action.payload.lmEndDate
  var lmTD = action.payload.lmTD
  var appraisalM = 0

  console.log('endDate', mEndDate)

  if (!isNaN(parseInt(action.payload.appraisalM))) {
    appraisalM = parseInt(action.payload.appraisalM)
  }
  var appraisalLM = 0
  if (!isNaN(parseInt(action.payload.appraisalLM))) {
    appraisalLM = parseInt(action.payload.appraisalLM)
  }
  var appraisalLMT = 0
  if (!isNaN(parseInt(action.payload.appraisalLMT))) {
    appraisalLMT = parseInt(action.payload.appraisalLMT)
  }
  var Queue = 0
  if (!isNaN(parseInt(action.payload.Queue))) {
    Queue = parseInt(action.payload.Queue)
  }
  var Est = 0
  if (!isNaN(parseInt(action.payload.Est))) {
    Est = parseInt(action.payload.Est)
  }

  // api config
  var config = {
    config:
    {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Loan.LoanFolder',
            value: 'My Pipeline',
            matchType: 'exact',
            include: 'true'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          }

        ]
      },
      fields: [
        'Loan.LoanNumber',
        'Loan.LoanAmount',
        'Loan.CurrentMilestoneName',
        'Fields.317',
        'Fields.11'
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }
  var appConfig = {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.3142',
            value: lmStartDate,
            matchType: 'greaterThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.3142',
            value: mEndDate,
            matchType: 'lessThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: '2012 Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Audit Files',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Bonds 2nds',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Consumer Connect',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Denials to be printed',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects (Current)',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Pending Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Servicing',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Withdraws to be printed',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: [
        'Fields.3142',
        'Fields.317'
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }
  var procConfig = {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.Log.MS.DATE.Processing',
            value: lmStartDate,
            matchType: 'greaterThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.Log.MS.DATE.Processing',
            value: mEndDate,
            matchType: 'lessThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: '2012 Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Audit Files',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Bonds 2nds',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Consumer Connect',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Denials to be printed',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects (Current)',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Pending Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Servicing',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Withdraws to be printed',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: [
        'Fields.Log.MS.DATE.Processing',
        'Fields.317'
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }
  var uwConfig = {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.2298',
            value: lmStartDate,
            matchType: 'greaterThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.2298',
            value: mEndDate,
            matchType: 'lessThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: '2012 Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Audit Files',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Bonds 2nds',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Consumer Connect',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Denials to be printed',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects (Current)',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Pending Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Servicing',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Withdraws to be printed',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: [
        'Fields.2298',
        'Fields.317'
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }
  var fundedConfig = {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.1997',
            value: lmStartDate,
            matchType: 'greaterThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.1997',
            value: mEndDate,
            matchType: 'lessThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: '2012 Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Audit Files',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Bonds 2nds',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Consumer Connect',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Denials to be printed',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects (Current)',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Pending Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Servicing',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Withdraws to be printed',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: [
        'Fields.1997',
        'Fields.317'
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }
  // variables
  var pipelineNumbersData = fetchPipelineNumbersData(config, action.payload)
  var appData = fetchPipelineNumbersData(appConfig, action.payload)
  var procData = fetchPipelineNumbersData(procConfig, action.payload)
  var uwData = fetchPipelineNumbersData(uwConfig, action.payload)
  var fundedData = fetchPipelineNumbersData(fundedConfig, action.payload)

  yield put({ type: 'UPDATE_PROGRESS', progress: 70, progressText: 'Getting Data...' })

  var promdata = yield Promise.all([pipelineNumbersData, appData, procData, uwData, fundedData])
  yield put({ type: 'UPDATE_PROGRESS', progress: 99, progressText: 'Getting Data...' })

  var pipelineCount = 0
  var workingCount = 0
  var processingCount = 0
  // Go through promData to see if any errors have been found
  var errorArray = []
  promdata.map((promiseResult) => {
    if (!Array.isArray(promiseResult.data)) { errorArray.push(promiseResult.data) }
  })
  // Handle Errors that were found during Promise All, it is safe to assume that grabing the first result will suffice
  if (errorArray.length > 0 && errorArray[0].status === 401) {
    // This case is for when a users Password has changed
    yield put({ type: 'ENCOMPASS_LOGIN_FAILED_NUMBERS_REPORT', getPipelineNumbersReportErrorMessage: 'AO.PLN.006:' + errorArray[0].response })
    yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })
    return
  } else if (errorArray.length > 0 && errorArray[0].status !== 200) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_NUMBERS_REPORT', getPipelineNumbersReportErrorMessage: 'AO.PLN.007:' + errorArray[0].response })
    return
  }

  console.log('GOT HERE')
  // pipelineNumbersData.data.map((loan) => {
  promdata[0].data.map((loan) => {
    pipelineCount++

    if (loan.fields['Fields.11'] === 'TBD') {
      workingCount++
    }

    if (loan.fields['Loan.CurrentMilestoneName'] === 'Started' ||
      loan.fields['Loan.CurrentMilestoneName'] === 'TBD Qualification' ||
      loan.fields['Loan.CurrentMilestoneName'] === 'TBD Processing' ||
      loan.fields['Loan.CurrentMilestoneName'] === 'TBD Submittal' ||
      loan.fields['Loan.CurrentMilestoneName'] === 'TBD Decision' ||
      loan.fields['Loan.CurrentMilestoneName'] === 'Qualification'
    ) {
      processingCount++
    }
  })

  workingCount = pipelineCount - workingCount
  processingCount = pipelineCount - processingCount

  // applications
  var appMTD = 0
  var appLMTD = 0
  var appLMT = 0
  // appData.data.map((loan) => {
  promdata[1].data.map((loan) => {
    if (dateCheck(mStartDate, mEndDate, loan.fields['Fields.3142'])) {
      appMTD++
    }
    if (dateCheck(lmStartDate, lmTD, loan.fields['Fields.3142'])) {
      appLMTD++
    }
    if (dateCheck(lmStartDate, lmEndDate, loan.fields['Fields.3142'])) {
      appLMT++
    }
  })

  // processing
  var procMTD = 0
  var procLMTD = 0
  var procLMT = 0
  // procData.data.map((loan) => {
  promdata[2].data.map((loan) => {
    if (dateCheck(mStartDate, mEndDate, loan.fields['Fields.Log.MS.DATE.Processing'])) {
      procMTD++
    }
    if (dateCheck(lmStartDate, lmTD, loan.fields['Fields.Log.MS.DATE.Processing'])) {
      procLMTD++
    }
    if (dateCheck(lmStartDate, lmEndDate, loan.fields['Fields.Log.MS.DATE.Processing'])) {
      procLMT++
    }
  })

  // UW
  var uwMTD = 0
  var uwLMTD = 0
  var uwLMT = 0
  // uwData.data.map((loan) => {
  promdata[3].data.map((loan) => {
    if (dateCheck(mStartDate, mEndDate, loan.fields['Fields.2298'])) {
      uwMTD++
    }
    if (dateCheck(lmStartDate, lmTD, loan.fields['Fields.2298'])) {
      uwLMTD++
    }
    if (dateCheck(lmStartDate, lmEndDate, loan.fields['Fields.2298'])) {
      uwLMT++
    }
  })

  // UW
  var fundedMTD = 0
  var fundedLMTD = 0
  var fundedLMT = 0
  // fundedData.data.map((loan) => {
  promdata[4].data.map((loan) => {
    if (dateCheck(mStartDate, mEndDate, loan.fields['Fields.1997'])) {
      fundedMTD++
    }
    if (dateCheck(lmStartDate, lmTD, loan.fields['Fields.1997'])) {
      fundedLMTD++
    }
    if (dateCheck(lmStartDate, lmEndDate, loan.fields['Fields.1997'])) {
      fundedLMT++
    }
  })

  var monthTotal = fundedMTD + Queue + Est
  // console.log("Total", monthTotal)
  var allData = {
    pipeline: pipelineCount,
    working: workingCount,
    processing: processingCount,
    appMTD: appMTD,
    appLMTD: appLMTD,
    appLMT: appLMT,
    procMTD: procMTD,
    procLMTD: procLMTD,
    procLMT: procLMT,
    uwMTD: uwMTD,
    uwLMTD: uwLMTD,
    uwLMT: uwLMT,
    fundedMTD: fundedMTD,
    fundedLMTD: fundedLMTD,
    fundedLMT: fundedLMT,
    appraisalM: appraisalM,
    appraisalLM: appraisalLM,
    appraisalLMT: appraisalLMT,
    Queue: Queue,
    Est: Est,
    monthTotal: monthTotal
  }

  console.log(allData)

  yield put({ type: 'UPDATE_PROGRESS', progress: 100, progressText: 'Finishing...' })

  yield put({ type: 'GET_PIPELINE_NUMBERS_REPORT_SUCCEEDED', pipelineNumbersReportData: allData })
}
const getLoanInfoForLoanCompareList = function * getLoanInfoForLoanCompareList (action) {
  // console.log('got here');
  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_LOAN_COMPARE_LIST_STARTED' })

  var encompassConfig = {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            canonicalName: 'Fields.2626',
            value: 'Brokered',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [

                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: [
        'Loan.LoanNumber', // Loan Number
        'Fields.4000', // Borrower First Name
        'Fields.4002', // Borrower Last Name
        'Fields.1172' // LoanType

      ],
      sortOrder: [
        {
          canonicalName: 'Loan.LoanNumber',
          order: 'asc'
        }
      ]
    }
  }

  // Get Info for List
  var loanNumberPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfig)
  if (loanNumberPipeline.data === undefined) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_LOAN_COMPARE', getLoanCompareErrorMessage: 'AO.PLN.009: Encompass Down' })
    return
  }

  if (Array.isArray(loanNumberPipeline.data)) {
    yield put({ type: 'GET_LOAN_COMPARE_LIST_SUCCEEDED', loanCompareDataList: loanNumberPipeline.data })
  } else if (loanNumberPipeline.data.status === 401) {
    // This case is for when a users Password has changed
    yield put({ type: 'ENCOMPASS_LOGIN_FAILED_LOAN_COMPARE', getLoanCompareErrorMessage: 'AO.PLN.008:' + loanNumberPipeline.data.response })
    yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })
  } else if (loanNumberPipeline.data.status !== 400) {
    console.log('400', loanNumberPipeline.data)
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_LOAN_COMPARE', getLoanCompareErrorMessage: 'AO.PLN.009:' + loanNumberPipeline.data.response })
  } else {
    console.log('pipelineFailed')
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED_LOAN_COMPARE', getLoanCompareErrorMessage: 'AO.PLN.009:' + loanNumberPipeline.data.response })
  }
}
const getSingleLoanFromWarehouse = function * getSingleLoanFromWarehouse (action) {
  yield put({ type: 'GET_SINGLE_LOAN_FROM_WAREHOUSE_STARTED' })

  var mongoConfig = {
    query: { guid: action.payload.guid }
  }
  const { mongoData } = yield race({
    mongoData: call(executeMongoFindDBQuery, 'trimmedWarehouse', mongoConfig),
    timeout: call(delay, 90000)
  })
  if (mongoData) { yield put({ type: 'GET_SINGLE_LOAN_FROM_WAREHOUSE_SUCCEEDED', singleLoanFromWarehouse: mongoData }) } else { yield put({ type: 'GET_SINGLE_LOAN_FROM_WAREHOUSE_ERRORED', getSingleLoanFromWarehouseErrorMessage: 'AO.PLN.012: Warehouse DB timed out waiting for response.' }) }
}

const getMultipleLoansFromWarehouse = function * getMultipleLoansFromWarehouse (action) {
  yield put({ type: 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_STARTED' })
  yield put({ type: 'SET_CURRENT_LOAN_PROCESSOR', currentProcessor: '' })
  const { mongoData } = yield race({
    mongoData: call(executeMongoFindDBQuery, 'trimmedWarehouse', action.payload.query),
    timeout: call(delay, 90000)
  })
  if (mongoData) {
    // If we are getting values for the Loan Processor View we want to sent the global currentProcessor
    if (action.payload.currentProcessor) {
      // Get Resubmittal Count from the loan in Encompass.
      // This is used for the Loan Processor View
      var loanGuids = mongoData.map((loan) => {
        return loan.guid
      })

      var encompassConfig = {
        config: {
          loanGuids: loanGuids,
          fields: [
            'Fields.CX.RESUB'
          ]
        }
      }

      var resubs = yield call(fetchLoanNumberPipelineData, { username: action.payload.username }, encompassConfig)

      var processorTouches = 0

      if (Array.isArray(resubs.data)) {
        resubs.data.map((resub) => {
          processorTouches += parseInt(resub.fields['Fields.CX.RESUB'])
        })

        processorTouches = processorTouches / mongoData.length
      }

      yield put({ type: 'SET_CURRENT_LOAN_PROCESSOR', currentProcessor: action.payload.currentProcessor, currentProcessorTouches: processorTouches })
      yield put({ type: 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_SUCCEEDED', multipleLoansFromWarehouse: mongoData })
    }
  } else {
    yield put({ type: 'GET_MULTIPLE_LOANS_FROM_WAREHOUSE_ERRORED', getMultipleLoansFromWarehouseErrorMessage: 'AO.PLN.012: Warehouse DB timed out waiting for response.' })
  }
}

const getSingleLoanFromEncompass = function * getSingleLoanFromEncompass (action) {
  yield put({ type: 'GET_SINGLE_LOAN_FROM_ENCOMPASS_STARTED' })

  var singleLoan = {}

  // Encompass Pipeline Call To get the new data for today/filter type combination
  var encompassConfig = {
    config: {
      loanGuids: [
        action.payload.guid
      ],
      fields: [
        'Fields.LOG.MS.CURRENTMILESTONE', // Current Milestone
        'Fields.3142', // GFE Application Date
        'Fields.745', // Application Date (Pre App)
        'Fields.LOG.MS.DATE.Processing', // Submitted to Processing Date
        'Fields.2149', // Buy Side Locked Date
        'Fields.2014', // Shipping Date
        'Fields.2301', // Underwriting Approval Date
        'Fields.2298', // Underwriting Submitted Date
        'Fields.2300', // Underwriting Credit Approval Date (Cond Approval)
        'Fields.2305', // Underwriting Clear to Close Date
        'Fields.1999', // Funds Released Date
        'Fields.762', // Rate Lock Expires
        'Fields.763', // Est Closing Date
        'Fields.4074', // Date Authorized
        'Fields.3840', // is Coborrower
        'Fields.35', // Income Assets BorrowerSpouse
        'Fields.307', // Income Assets Not Borrower
        'Fields.4073', // Authorized Credit Report
        'Fields.CX.TEAMCOLOR', // Team Color
        'Fields.ORGID', // Branch
        'Fields.2574', // Underwriter Name
        'Fields.2019', // Shipper Name
        'Fields.1855', // Closer Name
        'Fields.CX.JRPROC', // Processor Assistant Name
        'Fields.11', // Subject Property Street
        'Fields.12', // Subject Property City
        'Fields.14', // Subject Property State
        'Fields.15', // Subject Property Zip
        'Fields.4000', // Borrower First Name
        'Fields.4002', // Borrower Last Name
        'Fields.4001', // Borrower Middle Name
        'Fields.4003', // Borrower Suffix Name
        'Fields.4008', // Borrower Type
        'Fields.52', // Marital Status
        'Fields.53', // Dependents
        'Fields.54', // Ages
        'Fields.39', // YrsOfSchool
        'Fields.1240', // Borrower Email
        'Fields.1490', // Borrower Cell Phone
        'Fields.66', // Borrower Home Phone
        'Fields.4004', // CoBorrower First Name
        'Fields.4006', // CoBorrower Last Name
        'Fields.1268', // CoBorrower Email
        'Fields.1480', // CoBorrower Phone
        'Fields.4005', // Co-Borrower Middle Name
        'Fields.4007', // Co-Borrower Suffix Name
        'Fields.317', // Loan Officer Name
        'Fields.1407', // Loan Officer Email Also seen as Fields.3968
        'Fields.1406', // Loan Officer Phone
        'Fields.362', // Processor Name
        'Fields.1409', // Processor Email
        'Fields.1408', // Processor Phone
        'Fields.VEND.X150', // Seller Agent Name
        'Fields.VEND.X152', // Seller Agent Email
        'Fields.VEND.X501', // Seller Agent Phone
        'Fields.VEND.X139', // Buyer Agent Name
        'Fields.VEND.X141', // Buyer Agent Email
        'Fields.VEND.X500', // Buyer Agent Phone
        'Fields.19', // Loan Purpose
        'Fields.1172', // Loan Type
        'Fields.3', // Interest Rate
        'Fields.4', // Term (Mos)
        'Loan.LoanAmount', // Loan Amount
        'Loan.LoanNumber', // Loan Number
        'Fields.CX.FLOIFYAPIKEY', // Floify API Key
        'Fields.CX.FLOIFYURL', // Floify Application URL
        'Fields.CX.APPRORD', // Appraisal Ordered
        'Fields.CX.APPRRCD', // Appraisal Received
        'Fields.CX.VAFF', // VAFF Factor
        'Fields.VASUMM.X2', // VA Loan Summ Entitlement Code
        'Fields.1107', // Insurance Mtg Ins Upfront Factor
        'Fields.353', // Loan to Value (LTV)
        'Fields.990', // VA Loan Summ Discount Info
        'Fields.3532', // Charges for the insurance are collected upfront at loan closing
        'Fields.VASUMM.X49', // VA Loan Summ 1st Time Use VA Loan Program
        'Fields.1171', // Income Total Positive Cash Flow
        'Fields.1135', // FHA MCAW Required Investment
        'Fields.356', // Subject Property Appraised Value
        'Fields.1401', // Loan Program
        'Fields.1109', // Loan Amt
        'Fields.1765', // Expenses Calc MIP/PMI Lock
        'Fields.3262', // Refund prorated unearned Up Front MI Premiums
        'Fields.SYS.X11', // FHA Process Management Solution Round to $50
        'Fields.3531', // Charges for the insurance are added to your loan payments
        'Fields.1199', // Insurance Mtg in Periodic Factor 1
        'Fields.1198', // Insurance Mtg Ins Period 1
        'Fields.1201', // Insurance Mtg in Periodic Factor 2
        'Fields.1200', // Insurance Mtg Ins Period 2
        'Fields.1205', // Fee Detail - Line 1205 - Borrower Did Shop For
        'Fields.VAVOB.X72', // VA Veteran Type
        'Fields.2', // Total Loan Amt (w/ MIP/FF)
        'Fields.5', // Total Monthly Payment
        'Fields.8', // Insurance PMI
        'Fields.13', // Subject Property County
        'Fields.16', // Subject Property # Units
        'Fields.18', // Subject Property Yr Built
        'Fields.36', // Borrower First/Middle Name
        'Fields.37', // Borrower Last Name/Suffix
        'Fields.56', // Attorney Co Name
        'Fields.65', // Borr SSN
        'Fields.67', // Borr Experian FICO (Co-Mortgagor Pair: 2)
        'Fields.68', // Co-Borrower First/Middle Name
        'Fields.78', // Loan Maturity Date
        'Fields.97', // Co-Borr SSN
        'Fields.101', // Income Borr Base Income
        'Fields.119', // Expenses Present Rent
        'Fields.136', // Subject Property Purchase Price
        'Fields.141', // Credit 1 Amt
        'Fields.142', // Cash From Borr
        'Fields.155', // Fees Line 801 User Def Fee 1 Borr
        'Fields.220', // Total Gift Funds Amt
        'Fields.228', // Expenses Proposed Mtg Pymt
        'Fields.229', // Expenses Proposed Other Pymt
        'Fields.230', // Expenses Proposed Haz Ins
        'Fields.232', // Expenses Proposed Mtg Ins
        'Fields.233', // Expenses Proposed HOA
        'Fields.234', // Expenses Proposed Other Housing
        'Fields.247', // Loan Info ARM Life Cap
        'Fields.300', // Credit Rpt Ref #
        'Fields.305', // Lender Case #
        'Fields.323', // Broker Lender Zip
        'Fields.334', // Fees Interest Borr
        'Fields.340', // Fees Line 1008 # Mos
        'Fields.341', // Fees Line 1009 # Mos
        'Fields.352', // Investor Case/Loan #
        'Fields.364', // Loan #
        'Fields.367', // Fees Line 801 Underwriting Fees
        'Fields.388', // Orig Pts
        'Fields.411', // Title Co Name
        'Fields.420', // Lien Position
        'Fields.436', // Fees Loan Discount Fee + $
        'Fields.454', // Fees Loan Origination Fee Borr
        'Fields.558', // Fees Aggregate Adj
        'Fields.559', // Fees Loan Origination Fee Seller
        'Fields.561', // Fees Line 901 Interest Seller
        'Fields.569', // Fees Underwriting Fee Seller
        'Fields.593', // Fees Line 1204 Local Tax/Stamp Seller
        'Fields.594', // Fees Line 1205 State Tax/Stamp Seller
        'Fields.640', // Fees Credit Report Borr
        'Fields.641', // Fees Appraisal Fee Borr
        'Fields.647', // Fees City/County/Stamps Borr
        'Fields.648', // Fees State Tax/Stamps Borr
        'Fields.672', // Late Charge Days
        'Fields.674', // Late Charge %
        'Fields.682', // First Pymt Date
        'Fields.688', // Loan Info ARM Index
        'Fields.689', // Loan Info ARM Margin
        'Fields.691', // Loan Info ARM Pymt Adj Cap
        'Fields.694', // Loan Info ARM Rate Adj Period
        'Fields.695', // Loan Info ARM Rate Cap
        'Fields.696', // Loan Info ARM First Period Change
        'Fields.697', // Loan Info ARM First Rate Adj Cap
        'Fields.732', // Assets Total Assets
        'Fields.736', // Income Total Mo Income
        'Fields.740', // Qual Ratio Top
        'Fields.742', // Qual Ratio Bottom
        'Fields.748', // Closing Date
        'Fields.749', // Current Status Date
        'Fields.761', // Lock Date
        'Fields.799', // APR
        'Fields.912', // Expenses Proposed Total Housing
        'Fields.976', // Comb Loan to Value (CLTV)
        'Fields.1039', // Loan Info Section of Housing Act
        'Fields.1040', // Agency Case #
        'Fields.1041', // Subject Property Type Fannie Mae
        'Fields.1966', // Subject Property Type FHA
        'Fields.2356', // Subject Property Form
        'Fields.1541', // Subject Property Review
        'Fields.1051', // MERS MIN #
        'Fields.1059', // FHA Lender ID
        'Fields.1061', // Fees Loan Discount Fee %
        'Fields.1095', // Credit 2 Amt
        'Fields.1296', // Fees Mortgage Ins # of Mos
        'Fields.1298', // Subject Property Project Name
        'Fields.1335', // Down Pymt Amt
        'Fields.1386', // Fees Tax # of Mos Reserve Required
        'Fields.1387', // Fees Hazard Ins # of Mos Reserve Required
        'Fields.1388', // Fees Flood Ins # of Mos Reserve Required
        'Fields.1393', // Current Loan Status
        'Fields.1402', // Borr DOB
        'Fields.1403', // Co-Borr DOB
        'Fields.1405', // Expenses Proposed Taxes
        'Fields.1416', // Borrower Mailing Addr
        'Fields.1544', // Underwriting Risk Assess AUS Recomm
        'Fields.1612', // Interviewer Name
        'Fields.1621', // Fees Process Fee Borr
        'Fields.1622', // Fees Process Fee Seller
        'Fields.1625', // Fees Line 801 User Defined Fee 2 - Borr Amt
        'Fields.1629', // Fees Line 1007 # Mos
        'Fields.1647', // Credit 4 Amt
        'Fields.1667', // Fees Line 908 Borr
        'Fields.1699', // Loan Info ARM Floor Rate
        'Fields.1712', // Loan Info ARM Recast Period
        'Fields.1753', // Expenses Calc MIP/PMI Midpoint Pymt Cancel
        'Fields.1775', // Expenses Calc MIP/PMI Based On Remain Bal
        'Fields.1785', // Closing Cost Program
        'Fields.1811', // Subject Property Occupancy Status
        'Fields.1821', // Subject Property Est Value
        'Fields.456', // Subject Property Appr Value
        'Fields.17', // Subject Property Appr Value
        'Fields.1839', // Line 801 - User Defined Fee 3 - Borr Amt
        'Fields.1842', // Line 801 - User Defined Fee 4 - Borr Amt
        'Fields.1852', // CC Paid By Broker/Lender/Other
        'Fields.1867', // Borrower Vesting Borr Final Vesting to Read
        'Fields.1871', // Borrower Vesting Borr Vesting Type
        'Fields.1969', // Copy to Lender Check Box
        'Fields.1994', // Clear to Close Date
        'Fields.1996', // Funds Ordered Date
        'Fields.1997', // Funds Sent Date
        'Fields.2000', // Funding Funds Release Number
        'Fields.2018', // Shipping Pkg Tracking Number
        'Fields.2160', // Net Buy Rate
        'Fields.2218', // Total Buy Price
        'Fields.2295', // Total Sell Price
        'Fields.2299', // Underwriting Resubmitted Date
        'Fields.2303', // Underwriting Suspended Date
        'Fields.2304', // Underwriting Sign Off Date
        'Fields.2353', // Underwriting Appraisal Completed Date
        'Fields.2370', // Purchase Advice Date
        'Fields.2553', // Closing Docs REGZ Loan Info Disbursement Date
        'Fields.2625', // Loan Info ARM Max Life Interest Cap
        'Fields.2626', // Loan Info Channel
        'Fields.2825', // Rate Registration Investor Name
        'Fields.3054', // First Pymt Adj Date
        'Fields.3121', // Disclosed APR
        'Fields.3122', // Rans Details Disclosed Date
        'Fields.3150', // GFE Redisclosure Provided Date
        'Fields.3154', // TIL Redisclosure Provided Date
        'Fields.3156', // TIL Last Disclosed Date
        'Fields.3166', // GFE Changed Circumstance Comments
        'Fields.3168', // GFE Changed Circumstance Chkbx
        'Fields.3169', // GFE Changed Circumstance Drpdwn
        'Fields.3170', // GFE Date for Printed Form
        'Fields.3239', // Interviewer ID
        'Fields.3514', // Purchase Advice Date First Payment to Investor
        'Fields.3560', // Financed Guarantee Fee - Guarantee Percentage
        'Fields.3624', // Date the Appraisal was provided to the Borrower
        'Fields.3977', // Closing Disclosure Sent Date
        'Fields.3979', // Revised Closing Disclosure sent date
        'Fields.CD1.X71', // Closing Disclosure - MIC Reference
        'Fields.ConsumerConnectSiteID', // Consumer Connect SiteID
        'Fields.CX.CONTRACTDELAY', // Contract Delay
        'Fields.CX.APPRPREPAID1', // Appraisal Pre-Paid (Entry 1)
        'Fields.CX.PAYMETHOD2', // Payment Method 2
        'Fields.CX.1STPAYMENTDATE', // Payment Method 2
        'Fields.CX.9999', // Address Verify
        'Fields.CX.APPRAISALTYPE1', // Appraisal Type 1
        'Fields.CX.APPRAISALTYPE2', // Appraisal Type 2
        'Fields.CX.APPRFEE1', // Appraisal Fee (Entry 1)
        'Fields.CX.APPRFEE2', // Appraisal Fee (Entry 2)
        'Fields.CX.APPRFEEADD1', // Appraisal Fee Added (Entry 1)
        'Fields.CX.APPRFEEADD2', // Appraisal Fee Added (Entry 2)
        'Fields.CX.APPRPREPAID2', // Appraisal Pre-Paid (Entry 2)
        'Fields.CX.CDSCHEDULED', // CD Scheduled
        'Fields.CX.CLOESCROWHOLDBACK', // Escrow Holdback Required
        'Fields.CX.CLORQDATE', // Closing Request Date
        'Fields.CX.COMPLIANCEFINISHED', // Compliance UW Finished Date
        'Fields.CX.CONTACTEDTITLE', // Contacted Title
        'Fields.CX.CONTRACTDATE', // Contract Date
        'Fields.CX.CUFINISHED', // Collateral UW finished
        'Fields.CX.DC1', // Pre-Close Specialist
        'Fields.Document.Status.Vandyk Mortgage (Loan Status Notification)', // Document Status - VanDyk Mortgage (Loan Status Notification)
        'Fields.CX.INITIALDISCLOSURE', // Initial Disclosure
        'Fields.CX.LE1115', // 1115
        'Fields.CX.LEADSTATUS', // Lead Status
        'Fields.CX.LOANCOMDATE', // Loan Committment Date
        'Fields.CX.MCC', // MCC Indicator
        'Fields.CX.MCC2', // MCC Indicator
        'Fields.CX.MIOPTIONS', // MI Options
        'Fields.CX.OCS.DATETIME', // OCS DateTime
        'Fields.CX.OCS1', // OCS - Initial
        'Fields.CX.OCS10', // OCS - Re-Disclosure
        'Fields.CX.OCS3', // OCS - ReDisclose 1
        'Fields.CX.OCS4', // OCS - Date 2
        'Fields.CX.OCS5', // OCS - ReDisclose 2
        'Fields.CX.OCS6', // OCS - Date 3
        'Fields.CX.OCS7', // OSC - Initial Status
        'Fields.CX.PAYMETHOD1', // Payment Method 1
        'Fields.CX.RETODAYSDATE', // Date Reference Field
        'Fields.CX.SUSPENDED', // File was suspended upon submittal
        'Fields.CX.TRIDREADY', // TRID Ready
        'Fields.CX.UWRUSH', // UW Rush
        'Fields.CX.UWRUSH2', // UW Rush (2)
        'Fields.CX.UWRUSH3', // UW Rush (3)
        'Fields.CX.VOECOMM', // VOE Comments
        'Fields.DOCUMENT.COMMENTS.IRS 4506T - REQUEST FOR TRANSCRIPT OF TAX RETURN (CLASSIC)', // Document Comments - IRS 4506T - Request for Transcript of Tax Return (Classic)
        'Fields.DOCUMENT.DATERECEIVED.PROPERTY - PURCHASE AGREEMENT', // Document Date Received - Property - Purchase Agreement
        'Fields.DOCUMENT.DATEUNDERWRITINGREADY.APPRAISAL', // Document Date Underwriting Ready - Appraisal
        'Fields.DOCUMENT.DATEUNDERWRITINGREADY.MORTGAGE INSURANCE', // Document Date Underwriting Ready - Mortgage Insurance
        'Fields.FR0204', // Co-Borr Present Addr (Co-Mortgagor Pair: 2)
        'Fields.FR0104', // Borr Present Addr
        'Fields.HMDA.X12', // Preapprovals
        'Fields.HUD26', // HUD Total Escrow Reserves
        'Fields.L111', // HUD1 Borr - Adj for Seller PIA Oth 1 Amt
        'Fields.L228', // Fees MI Applic Fee Borr
        'Fields.L267', // Fees City Prop Tax # Mos
        'Fields.L427', // Closing Documents Trustee Name
        'Fields.L726', // Disclosed Sales Price
        'Fields.L770', // Document Date
        'Fields.L89', // HUD1 Borr - Gross Amt Due from Borr Oth 2 Amt
        'Fields.LE1.X1', // Loan Estimate - LE Date Issued
        'Fields.LENPCC', // Fees Total Closing Costs Lender
        'Fields.LOANFOLDER', // Loan Folder Name
        'Fields.LOG.MS.DATE.APPROVAL', // Milestone Date - Approval
        'Fields.LOG.MS.DATE.COND. APPROVAL', // Milestone Date - Cond. Approval
        'Fields.LOG.MS.DATE.RESUBMITTAL', // Milestone Date - Resubmittal
        'Fields.LOG.MS.DATE.QUALIFICATION', // Milestone Date - Qualification
        'Fields.LOG.MS.DATE.TBD DECISION', // Milestone Date - TBD Decision
        'Fields.LOG.MS.DATE.TBD PROCESSING', // Milestone Date - TBD Processing
        'Fields.LOG.MS.DATE.TBD SUBMITTAL', // Milestone Date - TBD Submittal
        'Fields.LOG.MS.DATETIME.APPROVAL', // Milestone Date/Time - Approval
        'Fields.LOG.MS.DATETIME.COND. APPROVAL', // Milestone Date/Time - Cond. Approval
        'Fields.LOG.MS.DATETIME.RESUBMITTAL', // Milestone Date/Time - Resubmittal
        'Fields.LOG.MS.DATETIME.QUALIFICATION', // Milestone Date/Time - Qualification
        'Fields.LOG.MS.DATETIME.TBD DECISION', // Milestone Date/Time - TBD Decision
        'Fields.LOG.MS.DATETIME.TBD PROCESSING', // Milestone Date/Time - TBD Processing
        'Fields.LOG.MS.DATETIME.TBD SUBMITTAL', // Milestone Date/Time - TBD Submittal
        'Fields.LOG.MS.STATUS.APPROVAL', // Milestone Status - Approval
        'Fields.LOG.MS.STATUS.CLEAR TO CLOSE', // Milestone Status - Clear to Close
        'Fields.LOG.MS.STATUS.COMPLETION', // Milestone Status - Completion
        'Fields.LOG.MS.STATUS.COND. APPROVAL', // Milestone Status - Cond. Approval
        'Fields.LOG.MS.STATUS.DOC PREPARATION', // Milestone Status - Doc Preparation
        'Fields.LOG.MS.STATUS.DOCS SIGNING', // Milestone Status - Docs Signing
        'Fields.LOG.MS.STATUS.FUNDING', // Milestone Status - Funding
        'Fields.LOG.MS.STATUS.PROCESSING', // Milestone Status - Processing
        'Fields.LOG.MS.STATUS.PURCHASED', // Milestone Status - Purchased
        'Fields.LOG.MS.STATUS.RESUBMITTAL', // Milestone Status - Resubmittal
        'Fields.LOG.MS.STATUS.SHIP 2 INVESTOR', // Milestone Status - Ship 2 Investor
        'Fields.LOG.MS.STATUS.SUBMITTAL', // Milestone Status - submittal
        'Fields.LOG.MS.STATUS.SUSPENDED', // Milestone Status - Suspended
        'Fields.LOG.MS.STATUS.SUSPENSE RESUBMITTED', // Milestone Status - Suspense resubmitted
        'Fields.LOG.MS.STATUS.TBD DECISION', // Milestone Status - TBD Decision
        'Fields.LOG.MS.STATUS.TBD PROCESSING', // Milestone Status - TBD Processing
        'Fields.LOG.MS.STATUS.TBD QUALIFICATION', // Milestone Status - TBD Qualification
        'Fields.LOG.MS.STATUS.TBD SUBMITTAL', // Milestone Status - TBD Submittal
        'Fields.MS.LOCKDAYS', // Days to Expire
        'Fields.MS.APP', // Approved Milestone Date
        'Fields.MS.APP.DUE', // Approved Milestone Due Date
        'Fields.MS.CLO', // Completed Milestone Date
        'Fields.MS.CLO.DUE', // Completed Milestone Due Date
        'Fields.MS.DOC', // Doc Signed Milestone Date
        'Fields.MS.DOC.DUE', // Doc Signed Milestone Due Date
        'Fields.MS.FUN', // Funded Milestone Date
        'Fields.MS.FUN.DUE', // Funded Milestone Due Date
        'Fields.MS.PROC', // Processed Milestone Date
        'Fields.MS.START', // File Started Milestone Date
        'Fields.MS.STATUS', // Last Finished Milestone
        'Fields.MS.STATUSDATE', // Last Finished Milestone Date
        'Fields.MS.SUB', // Submitted Milestone Date
        'Fields.NEWHUD.X1706', // Fees Line 1010 Guarantee Fee # Mos
        'Fields.NEWHUD.X808', // 2010 Escrow Fee
        'Fields.NEWHUD.X1067', // Fees Bona Fide Indicator
        'Fields.NEWHUD.X1150', // LO Compensation - Discount Point % Line 1
        'Fields.NEWHUD.X1151', // LO Compensation - Borrower Paid Discount Point Amount Line 1
        'Fields.NEWHUD.X1152', // LO Compensation - Seller Paid Discount Point Amount Line 1
        'Fields.NEWHUD.X1227', // LO Compensation - Discount Point Additional Amount Line 1
        'Fields.NEWHUD.X1237', // Line 801 - User Defined Fee 6 - Amt
        'Fields.NEWHUD.X1245', // Line 801 - User Defined Fee 7 - Amt
        'Fields.NEWHUD.X1253', // Line 801 - User Defined Fee 8 - Amt
        'Fields.NEWHUD.X1261', // Line 801 - User Defined Fee 9 - Amt
        'Fields.NEWHUD.X1269', // Line 801 - User Defined Fee 10 - Amt
        'Fields.NEWHUD.X1277', // Line 801 - User Defined Fee 11 - Amt
        'Fields.NEWHUD.X1285', // Line 801 - User Defined Fee 12 - Amt
        'Fields.NEWHUD.X15', // Fees Orig Charge Amt Borr
        'Fields.NEWHUD.X1713', // Fees Section 1000 Seller Paid Total Amount
        'Fields.NEWHUD.X1719', // Fees Section 1000 Borrower Paid Total Amount
        'Fields.NEWHUD.X277', // Borr Total Closing Costs
        'Fields.NEWHUD.X278', // Seller Total Closing Costs
        'Fields.NEWHUD.X572', // Fees Line 1103 Borr
        'Fields.NEWHUD.X639', // Fees Line 1104 Borr
        'Fields.NEWHUD.X731', // Fees Line 1203 Applied to GFE
        'Fields.NEWHUD.X733', // Line 801 - User Defined Fee 5 - Amt
        'Fields.NEWHUD.X787', // Fees Line 1203 Seller
        'Fields.NEWHUD.X788', // Fees Line 802 Orig Charge Amt Seller
        'Fields.NEWHUD2.X11', // Line 1102a Borr Paid Amount
        'Fields.NEWHUD2.X14', // Line 1102b Borr Paid Amount
        'Fields.NOTICES.X48', // Property Community participates in NFIP
        'Fields.POPT.X13', // Section 800 Line 804 POC and PTC Indicator
        'Fields.TASK.DATECOMPLETED.SHIPPER - SHIPPING READY', // Date Task Completed - Shipper - Shipping Ready
        'Fields.UWC.AC', // Underwriting Conditions AC
        'Fields.VASUMM.X23', // VA Loan Summ Credit Score
        'Fields.VEND.X117', // Buyers Attorney Contact Name
        'Fields.VEND.X119', // Buyers Attorney Email
        'Fields.VEND.X263', // Investor
        'Fields.VEND.X45', // Financial Planner Contact Name
        'Fields.VEND.X993', // Buyer's Agent Add to CD Contact Info
        'Fields.VEND.X994', // Seller's Agent Add to CD Contact Info
        'Fields.CX.SA29', // AC Branch Margin
        'Fields.CX.SA30', // AC LO Price
        'Fields.CX.SA31', // AC Lender Credit
        'Fields.CX.SA32', // AC Net Buy Price
        'Fields.CX.SA33', // AC Net Income
        'Fields.3375', // Rate Lock Buy Side Branch Price Concession
        'Fields.CX.SA41', // AC Branch Margin $
        'Fields.CX.SA42', // AC LO Price $
        'Fields.CX.SA43', // AC Lender Credit $
        'Fields.CX.SA44', // AC Net Buy Price $
        'Fields.CX.SA45', // AC Net Income $
        'Fields.CX.SA76', // AC Branch Concession
        'Fields.CX.MSRP', // MSR Value
        'Fields.CX.MSRD', // MSR in $
        'Fields.910', // Income Borr Total Income
        'Fields.911' // Income Co-Borr Total Income
      ],
      sortOrder: [
        {
          canonicalName: 'Fields.317',
          order: 'asc'
        }
      ]
    }
  }

  singleLoan = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfig)
  if (singleLoan.data === undefined) {
    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002: Encompass Is Down' })
  } else {
    yield put({ type: 'GET_SINGLE_LOAN_FROM_ENCOMPASS_SUCCEEDED', singleLoanFromEncompass: singleLoan.data })
  }
}

const getTrends = function * getTrends (action) {
  var field = action.payload.field
  // console.log('got here');
  yield put({ type: 'RESET_PIPELINE_STATUS' })
  yield put({ type: 'GET_TRENDS_STARTED' })

  // var intervals = determineIntervals(action.payload.startDate, action.payload.endDate)

  yield put({ type: 'UPDATE_PROGRESS', progress: 10, progressText: 'Getting Timeframe...' })

  var intervals = getIntervalSegmentsForTrends(action.payload.startDate, action.payload.endDate)
  var queries = []

  let allData = []

  // Trends Count
  if (action.payload.valueType === 'Count') {
    for (let i = 0; i < intervals.length; i++) {
      const start = convertDate(intervals[i], 'start')
      let end = ''
      let config = {}

      if (i === (intervals.length - 1)) {
        end = convertDate(action.payload.endDate, 'end')
      } else {
        end = convertDate(getDayBefore(intervals[i + 1], action.payload.endDate), 'end')
      }

      // queries.push("SELECT " + field + ", loanGuid FROM data_store WHERE " + field + " >= '" + start + "' AND " + field + " <= '" + end + "' ORDER BY " + field + " ASC")

      if (field === 'FundsReleasedDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.FundsReleasedDate': { $gte: start } }, { 'saveState.loanData.FundsReleasedDate': { $lte: end } }] }
        }
      }
      if (field === 'FundsSentDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.FundsSentDate': { $gte: start } }, { 'saveState.loanData.FundsSentDate': { $lte: end } }] }
        }
      }
      if (field === 'PreApplicationDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.PreApplicationDate': { $gte: start } }, { 'saveState.loanData.PreApplicationDate': { $lte: end } }] }
        }
      }
      if (field === 'ApplicationDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.ApplicationDate': { $gte: start } }, { 'saveState.loanData.ApplicationDate': { $lte: end } }] }
        }
      }
      if (field === 'SubmittedToProcessingDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.SubmittedToProcessingDate': { $gte: start } }, { 'saveState.loanData.SubmittedToProcessingDate': { $lte: end } }] }
        }
      }
      if (field === 'UWSubmittedDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.UWSubmittedDate': { $gte: start } }, { 'saveState.loanData.UWSubmittedDate': { $lte: end } }] }
        }
      }
      if (field === 'BuySideLockedDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.BuySideLockedDate': { $gte: start } }, { 'saveState.loanData.BuySideLockedDate': { $lte: end } }] }
        }
      }

      queries.push(config)
    }

    yield put({ type: 'UPDATE_PROGRESS', progress: 30, progressText: 'Building Queries...' })

    // // map thru results
    const promises = []

    // Get data from Warehouse that is in the desired range using the loan numbers
    for (let i = 0; i < queries.length; i++) {
      promises.push(executeMongoCountDBQuery('trimmedWarehouse', queries[i]))
    }

    const promdata = yield Promise.all(promises)

    const totalsGraph = {}
    const compareGraph = [
      { month: 'Jan' },
      { month: 'Feb' },
      { month: 'Mar' },
      { month: 'Apr' },
      { month: 'May' },
      { month: 'Jun' },
      { month: 'Jul' },
      { month: 'Aug' },
      { month: 'Sep' },
      { month: 'Oct' },
      { month: 'Nov' },
      { month: 'Dec' }
    ]

    for (let j = 0; j < promdata.length; j++) {
      if (!promdata[j].count) {
        yield put({ type: 'GET_TRENDS_ERRORED', getTrendsErrorMessage: 'AO.PLN.010: Mongo Query Invalid' })
      }

      // Populate Totals
      var year = intervals[j].split('/')[2].split(' ')[0]
      var month = intervals[j].split('/')[0] - 1

      if (Object.keys(totalsGraph).indexOf(year) !== -1) {
        totalsGraph[year] = totalsGraph[year] + promdata[j].count
      } else {
        totalsGraph[year] = promdata[j].count
      }

      // Populate Compare Graph
      compareGraph[month][year] = promdata[j].count
    }

    // Putting the object in the formate needed for recharts
    const finalTotals = Object.keys(totalsGraph).map((totalGraphElement) => {
      return { date: totalGraphElement, Count: totalsGraph[totalGraphElement] }
    })

    allData = [finalTotals, compareGraph]

    yield put({ type: 'UPDATE_PROGRESS', progress: 99, progressText: 'Finishing...' })
  } else if (action.payload.valueType === 'Percent') {
    for (let i = 0; i < intervals.length; i++) {
      const start = convertDate(intervals[i], 'start')
      let end = ''
      let config = {}

      if (i === (intervals.length - 1)) {
        end = convertDate(action.payload.endDate, 'end')
      } else {
        end = convertDate(getDayBefore(intervals[i + 1], action.payload.endDate), 'end')
      }

      if (field === 'ApplicationDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.PreApplicationDate': { $gte: start } }, { 'saveState.loanData.PreApplicationDate': { $lte: end } }] },
          project: {
            guid: 1,
            'saveState.loanData.ApplicationDate': 1,
            _id: 0
          }
        }
      } else if (field === 'PreApplicationDate') {
        config = {
          query: { $and: [{ 'saveState.loanData.PreApplicationDate': { $gte: start } }, { 'saveState.loanData.PreApplicationDate': { $lte: end } }] },
          project: {
            guid: 1,
            'saveState.loanData.PreApplicationDate': 1,
            _id: 0
          }
        }
      } else {
        config = {
          query: { $and: [{ 'saveState.loanData.ApplicationDate': { $gte: start } }, { 'saveState.loanData.ApplicationDate': { $lte: end } }] },
          project: {
            guid: 1,
            'saveState.loanData.FundsReleasedDate': 1,
            'saveState.loanData.SubmittedToProcessingDate': 1,
            'saveState.loanData.UWSubmittedDate': 1,
            'saveState.loanData.BuySideLockedDate': 1,
            _id: 0
          }
        }
      }

      queries.push(config)
    }

    yield put({ type: 'UPDATE_PROGRESS', progress: 30, progressText: 'Building Queries for Percentage...this may take a few moments' })

    // map thru results
    const promises = []

    // Get data from Warehouse that is in the desired range using the loan numbers
    for (let i = 0; i < queries.length; i++) {
      promises.push(executeMongoFindDBQuery('trimmedWarehouse', queries[i]))
    }

    const promdata = yield Promise.all(promises)

    const totalsGraph = {}
    const compareGraph = [
      { month: 'Jan' },
      { month: 'Feb' },
      { month: 'Mar' },
      { month: 'Apr' },
      { month: 'May' },
      { month: 'Jun' },
      { month: 'Jul' },
      { month: 'Aug' },
      { month: 'Sep' },
      { month: 'Oct' },
      { month: 'Nov' },
      { month: 'Dec' }
    ]

    let runningTotal = 0
    let runningVal = 0

    for (let j = 0; j < promdata.length; j++) {
      // Populate Totals
      const year = intervals[j].split('/')[2].split(' ')[0]
      const month = intervals[j].split('/')[0] - 1

      // Get Percentage of
      let total = 0
      let val = 0
      for (let k = 0; k < promdata[j].length; k++) {
        total++
        if (!promdata[j][k].saveState[0]) {
          console.log(promdata[j][k])
        }
        if (promdata[j][k].saveState[0] && promdata[j][k].saveState[0].loanData[field]) {
          val++
        }
      }

      if (Object.keys(totalsGraph).indexOf(year) !== -1) {
        runningTotal = runningTotal + total
        runningVal = runningVal + val
        totalsGraph[year] = ((runningVal / runningTotal) * 100).toFixed(1)
      } else {
        runningTotal = total
        runningVal = val
        totalsGraph[year] = ((runningVal / runningTotal) * 100).toFixed(1)
      }

      // Populate Compare Graph
      compareGraph[month][year] = parseFloat(((val / total) * 100).toFixed(1))
    }

    // Putting the object in the formate needed for recharts
    const finalTotals = Object.keys(totalsGraph).map((totalGraphElement) => {
      return { date: totalGraphElement, Percentage: parseFloat(totalsGraph[totalGraphElement]) }
    })

    allData = [finalTotals, compareGraph]

    yield put({ type: 'UPDATE_PROGRESS', progress: 99, progressText: 'Finishing...' })
  }

  yield put({ type: 'GET_TRENDS_SUCCEEDED', trendData: allData })
}
const getFundings = function * getFundings (action) {
  var fundingsData = {}

  yield put({ type: 'RESET_FUNDINGS_STATUS' })

  yield put({ type: 'GET_FUNDINGS_STARTED' })

  // Gets Pipeline
  try {
    // get data info and build query
    fundingsData = yield call(fetchFundingsData, action.payload)

    if (fundingsData.status === 400) {
      yield put({ type: 'GET_FUNDINGS_ERRORED', getFundingsErrorMessage: 'AO.PLN.011: ' + fundingsData.response.error_description })

      return
    } else {
      yield put({ type: 'GET_FUNDINGS_SUCCEEDED', fundingsData: fundingsData })
    }
  } catch (error) {
    yield put({ type: 'GET_FUNDINGS_ERRORED', getFundingsErrorMessage: 'AO.PLN.011: Unknown' })
  }
}
const auditLoan = function * auditLoan (action) {
  var auditData = {}

  // yield put({ type: "RESET_LOAN_AUDIT"});
  yield put({ type: 'GET_LOAN_AUDIT_STARTED' })

  // create scenarios
  if (action.payload.audit === 'VA') {
    var scenariosObj = {
      vaCheck: true,
      vaffCheck: true
    }

    // get mongoObj
    var mongoConfig = {
      query: { guid: action.payload.data },
      project: {
        guid: 1,
        'saveState.loanData.VAFFFactor': 1,
        'saveState.loanData.LoanToValue': 1,
        'saveState.loanData.InsuranceMtgInsUpfrontFactor': 1,
        'saveState.loanData.VALoanSummitDiscountInfo': 1,
        'saveState.loanData.VALoanSummEnititlementCode': 1,
        _id: 0
      }
    }

    const { mongoData } = yield race({
      mongoData: call(executeMongoFindDBQuery, 'trimmedWarehouse', mongoConfig),
      timeout: call(delay, 90000)
    })
    if (!mongoData) {
      yield put({ type: 'GET_LOAN_AUDIT_ERRORED_WAREHOUSE', getLoanAuditErrorMessage: 'AO.PLN.014: Warehouse DB timed out waiting for response.' })
      return
    }

    // create obj
    var fieldsObj = {
      CXVAFF: mongoData[0].saveState[0].loanData.VAFFFactor,
      F353: mongoData[0].saveState[0].loanData.LoanToValue,
      F1107: mongoData[0].saveState[0].loanData.InsuranceMtgInsUpfrontFactor,
      F990: mongoData[0].saveState[0].loanData.VALoanSummitDiscountInfo,
      VASUMMX2: mongoData[0].saveState[0].loanData.VALoanSummEnititlementCode
    }
  }

  try {
    auditData = yield call(fetchLoanAuditData, scenariosObj, fieldsObj)
    if (auditData.status === 400) {
      yield put({ type: 'GET_LOAN_AUDIT_ERRORED_ENCOMPASS', getLoanAuditErrorMessage: 'AO.PLN.013: ' + auditData.response.error_description })
      return
    } else {
      yield put({ type: 'GET_LOAN_AUDIT_SUCCEEDED', auditData: auditData })
      return
    }
  } catch (error) {
    yield put({ type: 'GET_LOAN_AUDIT_ERRORED', getLoanAuditErrorMessage: 'AO.PLN.15: ' + error })
  }
}

const getClosingReport = function * getClosingReport (action) {
  yield put({ type: 'GET_CLOSING_REPORT_STARTED' })
  yield put({ type: 'UPDATE_PROGRESS', progress: 25, progressText: 'Getting Pipeline' })
  // Gets Pipeline
  try {
    // Encompass Pipeline Call To get the new data for Closing

    var encompassConfigForClosing = getClosingReportConfigs(action.payload.closingFilter, action.payload.startDate, action.payload.endDate, action.payload.closingFields)
    var encompassConfigForCDsSent = getClosingReportConfigs(action.payload.cdsSentFilter, action.payload.startDate, action.payload.endDate, action.payload.cdsSentFields)
    var encompassConfigForComplianceData = getClosingReportConfigs(action.payload.complianceDataFilter, action.payload.startDate, action.payload.endDate, action.payload.complianceDataFields)
    var encompassConfigForComplianceReview = getClosingReportConfigs(action.payload.complianceReviewFilter, action.payload.startDate, action.payload.endDate, action.payload.complianceReviewFields)

    // Get Loan Numbers of all loans in users pipeline

    var closingPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfigForClosing)
    var cdsSentPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfigForCDsSent)
    var complianceDataPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfigForComplianceData)
    var complianceReviewPipeline = yield call(fetchLoanNumberPipelineData, action.payload, encompassConfigForComplianceReview)

    console.log('DATA', complianceDataPipeline.data)
    if (closingPipeline.data === undefined || complianceDataPipeline.data === undefined || complianceReviewPipeline.data === undefined || cdsSentPipeline.data === undefined) {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002: Encompass Is Down' })
      return
    }
    // Ensure that closing, cds sent, and compliance data is in the form of an array
    if (Array.isArray(closingPipeline.data) && Array.isArray(cdsSentPipeline.data) && Array.isArray(complianceDataPipeline.data) && Array.isArray(complianceReviewPipeline.data)) {
      var closingReportData = {}
      var closingMonthlyData = {}
      var cdsSentMonthlyData = {}

      // Process Closing Data

      closingPipeline.data.forEach((loan) => {
        var month = loan.fields['Fields.' + action.payload.closingFilter].split('/')[0]
        // This data will be used for the Monthly Raw Data Work Sheets
        if (Object.keys(closingMonthlyData).indexOf(month) === -1) {
          closingMonthlyData[month] = [loan.fields]
        } else {
          closingMonthlyData[month].push(loan.fields)
        }

        // Creates Key for closer if it doesnt exist
        if (Object.keys(closingReportData).indexOf(loan.fields['Fields.1855']) === -1) {
          closingReportData[loan.fields['Fields.1855']] = { Closings: { [month]: 1 } }
        }

        // Creates Key for Closings if it doesnt exist
        if (Object.keys(closingReportData[loan.fields['Fields.1855']]).indexOf('Closings') === -1) {
          closingReportData[loan.fields['Fields.1855']].Closings = {}
        }

        // Creates a Closings Key for the Closer
        if (Object.keys(closingReportData[loan.fields['Fields.1855']].Closings).indexOf(month) === -1) {
          closingReportData[loan.fields['Fields.1855']].Closings[month] = 1
        } else {
          // Increment the count of Closings for that Closer
          closingReportData[loan.fields['Fields.1855']].Closings[month] += 1
        }
      })

      // Process CDs Sent Data
      cdsSentPipeline.data.forEach((loan) => {
        // Using the respective filter as that is the date that we want to get the counts for
        var month = loan.fields['Fields.' + action.payload.cdsSentFilter].split('/')[0]
        // This data will be used for the Monthly Raw Data Work Sheets
        if (Object.keys(cdsSentMonthlyData).indexOf(month) === -1) {
          cdsSentMonthlyData[month] = [loan.fields]
        } else {
          cdsSentMonthlyData[month].push(loan.fields)
        }

        // Creates Key for closer if it doesnt exist
        if (Object.keys(closingReportData).indexOf(loan.fields['Fields.1855']) === -1) {
          closingReportData[loan.fields['Fields.1855']] = { 'CDs Sent': { [month]: 1 } }
        }
        // Creates Key for 'CDs Sent' if it doesnt exist
        if (Object.keys(closingReportData[loan.fields['Fields.1855']]).indexOf('CDs Sent') === -1) {
          closingReportData[loan.fields['Fields.1855']]['CDs Sent'] = {}
        }

        // Creates a CDs Sent Key for the Closer
        if (Object.keys(closingReportData[loan.fields['Fields.1855']]['CDs Sent']).indexOf(month) === -1) {
          closingReportData[loan.fields['Fields.1855']]['CDs Sent'][month] = 1
        } else {
          // Increment the count of CDs Sent for that Closer
          closingReportData[loan.fields['Fields.1855']]['CDs Sent'][month] += 1
        }
      })

      var complianceReviewMonthlyData = {}
      var complianceDataMonthlyData = {}
      // Process Compliance Data
      complianceDataPipeline.data.forEach((loan) => {
        // For compliance data we will have several filter fields LE SENT DATE 1, LE SENT DATE 2, etc
        action.payload.complianceDataFilter.forEach((filter) => {
          // We first need to check to see if this LE SET DATE is in the current year.
          var justDate = loan.fields['Fields.' + filter].split(' ')[0]
          var year = justDate.split('/')[2]
          if (year !== today.getFullYear()) {
            return
          }
          // Because the field names are very similar we can replace strings for each of the filter values to use for
          var month = loan.fields['Fields.' + filter].split('/')[0]

          var disclsureType = loan.fields['Fields.' + filter.replace('SentDate', 'DisclosureType')]
          var closerName = loan.fields['Fields.' + filter.replace('SentDate', 'SentBy')].split('(')[0]

          // This data will be used for the Monthly Raw Data Work Sheets
          if (Object.keys(complianceDataMonthlyData).indexOf(month) === -1) {
            complianceDataMonthlyData[month] = [loan.fields]
          } else {
            complianceDataMonthlyData[month].push(loan.fields)
          }

          // Creates Key for closer if it doesnt exist
          if (Object.keys(closingReportData).indexOf(closerName) === -1) {
            closingReportData[closerName] = { ['Compliance Data ' + disclsureType]: { [month]: 1 } }
          }

          // Creates Key for Compliance if it doesnt exist
          if (Object.keys(closingReportData[closerName]).indexOf('Compliance Data ' + disclsureType) === -1) {
            closingReportData[closerName]['Compliance Data ' + disclsureType] = {}
          }

          // Creates a Compliance Key for the Closer
          if (Object.keys(closingReportData[closerName]['Compliance Data ' + disclsureType]).indexOf(month) === -1) {
            closingReportData[closerName]['Compliance Data ' + disclsureType][month] = 1
          } else {
            // Increment the count of Compliance for that Closer
            closingReportData[closerName]['Compliance Data ' + disclsureType][month] += 1
          }
        })
      })

      // Process Compliance Review
      complianceReviewPipeline.data.forEach((loan) => {
        // Using the respective filter as that is the date that we want to get the counts for
        var month = loan.fields['Fields.' + action.payload.complianceReviewFilter].split('/')[0]
        // This data will be used for the Monthly Raw Data Work Sheets
        if (Object.keys(complianceReviewMonthlyData).indexOf(month) === -1) {
          complianceReviewMonthlyData[month] = [loan.fields]
        } else {
          complianceReviewMonthlyData[month].push(loan.fields)
        }

        // Creates Key for closer if it doesnt exist
        if (Object.keys(closingReportData).indexOf(loan.fields['Fields.CX.COMPLIANCEUW']) === -1) {
          closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']] = { CRs: { [month]: 1 } }
        }

        // Creates Key for Compliance if it doesnt exist
        if (Object.keys(closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']]).indexOf('CRs') === -1) {
          closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']].CRs = {}
        }

        // Creates a Compliance Key for the Closer
        if (Object.keys(closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']].CRs).indexOf(month) === -1) {
          closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']].CRs[month] = 1
        } else {
          // Increment the count of Compliance for that Closer
          closingReportData[loan.fields['Fields.CX.COMPLIANCEUW']].CRs[month] += 1
        }
      })

      console.log(closingReportData)

      // Put Closing Report Data in a format for the spreadsheet this first section is for the Closing Report
      var closingWorkBookData = {}

      Object.keys(closingMonthlyData).forEach((month) => {
        // We only want to include the previous month in the report
        if (month - 1 !== today.getMonth()) {
          return
        }
        // Create Closing Packages Monthly Summary Worksheets
        if (!closingWorkBookData[monthMap[month - 1] + ' Data - Pkgs']) {
          closingWorkBookData[monthMap[month - 1] + ' Data - Pkgs'] = [['Loan Closer', 'Loan Number', 'Borrower Last Name', 'TRID Ready', 'Same Day', 'Closing Disclosure Sent Date', 'Milestone Date - Resubmittal', 'Milestone Date/Time - Clear to Close', 'Milestone Date/Time - Doc Preparation', 'Milestone Date/Time - HUD Approval', 'Trans Details Closed Date', 'Funds Sent Date', 'Est Closing Date', 'Company - Users Organization Code']]
        }
        closingMonthlyData[month - 1].forEach((loan) => {
          closingWorkBookData[monthMap[month - 1] + ' Data - Pkgs'].push([loan['Fields.1855'], loan['Fields.364'], loan['Fields.4002'], loan['Fields.CX.TRIDREADY'].split(' ')[0], loan['Fields.CX.SAMEDAY'], loan['Fields.3977'].split(' ')[0], loan['Fields.LOG.MS.DATE.RESUBMITTAL'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.CLEAR TO CLOSE'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.DOC PREPARATION'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.HUD APPROVAL'].split(' ')[0], loan['Fields.748'].split(' ')[0], loan['Fields.1997'].split(' ')[0], loan['Fields.763'].split(' ')[0], loan['Fields.ORGID']])
        })

        // Create CDs Sent Monthly Summary Worksheets
        if (!closingWorkBookData[monthMap[month - 1] + ' Data - CDs']) {
          closingWorkBookData[monthMap[month - 1] + ' Data - CDs'] = [['Loan Closer', 'Loan Number', 'Borrower Last Name', 'TRID Ready', 'Same Day', 'Closing Disclosure Sent Date', 'Milestone Date - Resubmittal', 'Milestone Date/Time - Clear to Close', 'Milestone Date/Time - Doc Preparation', 'Milestone Date/Time - HUD Approval', 'Trans Details Closed Date', 'Funds Sent Date', 'Rush Approved', 'Company - Users Organization Code']]
        }
        cdsSentMonthlyData[month - 1].forEach((loan) => {
          closingWorkBookData[monthMap[month - 1] + ' Data - CDs'].push([loan['Fields.1855'], loan['Fields.364'], loan['Fields.4002'], loan['Fields.CX.TRIDREADY'].split(' ')[0], loan['Fields.CX.SAMEDAY'], loan['Fields.3977'].split(' ')[0], loan['Fields.LOG.MS.DATE.RESUBMITTAL'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.CLEAR TO CLOSE'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.DOC PREPARATION'].split(' ')[0], loan['Fields.LOG.MS.DATETIME.HUD APPROVAL'].split(' ')[0], loan['Fields.748'].split(' ')[0], loan['Fields.1997'].split(' ')[0], loan['Fields.CX.RUSH'].split(' ')[0], loan['Fields.ORGID']])
        })
      })

      let summaryHeader = ['NAME', 'OT', 'PTO Days', 'WORKING DAYS AVAILABLE FOR THE MONTH', 'BASELINE = 4 Units per day, plus 1 unit for every hour of OT used. (Subtract 4 units per PTO day).', 'CD\'S SENT', 'PKGs SENT', 'TOTAL Units', '% of Baseline Achieved', 'Bonus Units (Units above baseline).', 'Gross Bonus Amount ($5 Per qualifying unit).', 'ERRORS (-$10)', 'MAJOR ERRORS (-$20)', 'POST CLOSE ISSUES (-$20)', 'Total Violations', 'Net BONUS DUE (less mistakes)', 'Total Error %', 'Total # of Errors']

      // Create Individual and Yearly Summary Worksheets
      closingWorkBookData['Yearly Totals'] = [['Closing Production & Bonus Report - 2019', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], summaryHeader]

      Object.keys(closingReportData).forEach((closer) => {
        // Check to make sure that this closer has a CDs Sent and Closings object if it does not have either then this closer should not be added to the spreadsheet else we want to add that to our workbook data
        if (!closingReportData[closer].Closings && !closingReportData[closer]['CDs Sent']) {
          return
        } else if (!closingWorkBookData[closer]) {
          closingWorkBookData[closer] = [['Closing Production & Bonus Report - 2019', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], summaryHeader]
        }
        // Add Information to both the individual sheet and yearly
        closingWorkBookData['Yearly Totals'].push([closer, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
        closingWorkBookData[closer].push([closer, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])

        // Go through every month in our month map that is defined at the top of the file
        var yearlyTotalClosings = 0
        var yearlyTotalCDsSent = 0
        Object.keys(monthMap).forEach((monthIndex) => {
          // Check to see if this closer has values for that particular by looking at the keys in the closer object and the mon
          var closings = 0
          var cdsSent = 0
          if (closingReportData[closer].Closings && closingReportData[closer].Closings[monthIndex]) {
            yearlyTotalClosings = closingReportData[closer].Closings[monthIndex] + yearlyTotalClosings
            closings = closingReportData[closer].Closings[monthIndex]
          }
          if (closingReportData[closer]['CDs Sent'] && closingReportData[closer]['CDs Sent'][monthIndex]) {
            yearlyTotalCDsSent = closingReportData[closer]['CDs Sent'][monthIndex] + yearlyTotalCDsSent
            cdsSent = closingReportData[closer]['CDs Sent'][monthIndex]
          }

          closingWorkBookData['Yearly Totals'].push([monthMap[monthIndex], '', '', '', '', cdsSent, closings, '', '', '', '', '', '', '', '', '', '', ''])
          closingWorkBookData[closer].push([monthMap[monthIndex], '', '', '', '', cdsSent, closings, '', '', '', '', '', '', '', '', '', '', ''])
        })

        closingWorkBookData['Yearly Totals'].push(['Totals', '', '', '', '', yearlyTotalCDsSent, yearlyTotalClosings, '', '', '', '', '', '', '', '', '', '', ''])
        closingWorkBookData[closer].push(['Totals', '', '', '', '', yearlyTotalCDsSent, yearlyTotalClosings, '', '', '', '', '', '', '', '', '', '', ''])

        closingWorkBookData['Yearly Totals'].push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
        closingWorkBookData[closer].push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
      })

      // Put Compliance Report Data in a format for the spreadsheet this first section is for the Compliance Report
      var complianceWorkBookData = {}

      Object.keys(complianceDataMonthlyData).forEach((month) => {
        // We only want to include the previous month in the report
        if (month - 1 !== today.getMonth()) {
          return
        }
        // Create Compliance Data Monthly Summary Worksheets
        if (!complianceWorkBookData[monthMap[month - 1] + ' Data - Compliance']) {
          complianceWorkBookData[monthMap[month - 1] + ' Data - Compliance'] = [['LE Sent By - 1', 'LE Sent Date - 1', 'LE Disclosure Type - 1', 'LE Sent By - 2', 'LE Sent Date - 2', 'LE Disclosure Type - 2', 'LE Sent By - 3', 'LE Sent Date - 3', 'LE Disclosure Type - 3', 'LE Sent By - 4', 'LE Sent Date - 4', 'LE Disclosure Type - 4', 'LE Sent By - 5', 'LE Sent Date - 5', 'LE Disclosure Type - 5']]
        }
        complianceDataMonthlyData[month - 1].forEach((loan) => {
          complianceWorkBookData[monthMap[month - 1] + ' Data - Compliance'].push(
            [loan['Fields.DISCLOSEDLE.SentBy.1'], loan['Fields.DISCLOSEDLE.SentDate.1'].split(' ')[0], loan['Fields.DISCLOSEDLE.DisclosureType.1'], loan['Fields.DISCLOSEDLE.SentBy.2'], loan['Fields.DISCLOSEDLE.SentDate.2'].split(' ')[0], loan['Fields.DISCLOSEDLE.DisclosureType.2'], loan['Fields.DISCLOSEDLE.SentBy.3'], loan['Fields.DISCLOSEDLE.SentDate.3'].split(' ')[0], loan['Fields.DISCLOSEDLE.DisclosureType.3'], loan['Fields.DISCLOSEDLE.SentBy.4'], loan['Fields.DISCLOSEDLE.SentDate.4'].split(' ')[0], loan['Fields.DISCLOSEDLE.DisclosureType.4'], loan['Fields.DISCLOSEDLE.SentBy.5'], loan['Fields.DISCLOSEDLE.SentDate.5'].split(' ')[0], loan['Fields.DISCLOSEDLE.DisclosureType.5']]
          )
        })
      })

      Object.keys(complianceReviewMonthlyData).forEach((month) => {
        // We only want to include the previous month in the report
        if (month - 1 !== today.getMonth()) {
          return
        }
        // Create Compliance Review Monthly Summary Worksheets
        if (!complianceWorkBookData[monthMap[month - 1] + ' Data - CRs']) {
          complianceWorkBookData[monthMap[month - 1] + ' Data - CRs'] = [['Compliance Underwriter', 'Compliance UW Start Date']]
        }
        complianceReviewMonthlyData[month - 1].forEach((loan) => {
          complianceWorkBookData[monthMap[month - 1] + ' Data - CRs'].push([loan['Fields.CX.COMPLIANCEUW'], loan['Fields.CX.COMPLIANCESTART'].split(' ')[0]])
        })
      })

      summaryHeader = ['NAME', 'OT', 'PTO Days', 'WORKING DAYS AVAILABLE FOR THE MONTH', 'BASELINE = 4 Units per day, plus 1 unit for every hour of OT used. (Subtract 4 units per PTO day).', 'Initials Sent', 'Revised Sent', 'TRID Reviews', 'Additional Responsibilities.', 'TOTAL Units', '% of Baseline Achieved', 'Bonus Units (Units above baseline).', 'Gross Bonus Amount ($5 Per qualifying unit).', 'ERRORS (-$10)', 'CHECKLIST VIOLATIONS (-$20)', 'POST CLOSE ISSUES (-$20)', 'Total Violations', 'Net BONUS DUE (less mistakes)']

      // Create Individual and Yearly Summary Worksheets
      complianceWorkBookData['Yearly Totals'] = [['The OC Production & Bonus Report - 2019', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], summaryHeader]

      Object.keys(closingReportData).forEach((closer) => {
        // Creates title for Worksheet
        // Check to make sure that this closer has a CDs Sent and Closings object if it does not have either then this closer should not be added to the spreadsheet else we want to add that to our workbook data
        if (!closingReportData[closer].CRs && !closingReportData[closer]['Compliance Data Revised'] && !closingReportData[closer]['Compliance Data Initial']) {
          return
        } else if (!complianceWorkBookData[closer]) {
          complianceWorkBookData[closer] = [['The OC Production & Bonus Report - 2019', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''], summaryHeader]
        }

        // Add Information to both the individual sheet and yearly
        complianceWorkBookData['Yearly Totals'].push([closer, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
        complianceWorkBookData[closer].push([closer, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])

        // Go through every month in our month map that is defined at the top of the file
        var yearlyTotalCRs = 0
        var yearlyTotalComplianceDataInitial = 0
        var yearlyTotalComplianceDataRevised = 0
        Object.keys(monthMap).forEach((monthIndex) => {
          // Check to see if this closer has values for that particular by looking at the keys in the closer object and the mon
          var cRs = 0
          var complianceDataInitial = 0
          var complianceDataRevised = 0
          if (closingReportData[closer].CRs && closingReportData[closer].CRs[monthIndex]) {
            yearlyTotalCRs = closingReportData[closer].CRs[monthIndex] + yearlyTotalCRs
            cRs = closingReportData[closer].CRs[monthIndex]
          }
          if (closingReportData[closer]['Compliance Data Initial'] && closingReportData[closer]['Compliance Data Initial'][monthIndex]) {
            yearlyTotalComplianceDataInitial = closingReportData[closer]['Compliance Data Initial'][monthIndex] + yearlyTotalComplianceDataInitial
            complianceDataInitial = closingReportData[closer]['Compliance Data Initial'][monthIndex]
          }
          if (closingReportData[closer]['Compliance Data Revised'] && closingReportData[closer]['Compliance Data Revised'][monthIndex]) {
            yearlyTotalComplianceDataRevised = closingReportData[closer]['Compliance Data Revised'][monthIndex] + yearlyTotalComplianceDataRevised
            complianceDataRevised = closingReportData[closer]['Compliance Data Revised'][monthIndex]
          }
          complianceWorkBookData['Yearly Totals'].push([monthMap[monthIndex], '', '', '', '', complianceDataInitial, complianceDataRevised, cRs, 0, '', '', '', '', '', '', '', '', ''])
          complianceWorkBookData[closer].push([monthMap[monthIndex], '', '', '', '', complianceDataInitial, complianceDataRevised, cRs, 0, '', '', '', '', '', '', '', '', ''])
        })

        complianceWorkBookData['Yearly Totals'].push(['Totals', '', '', '', '', yearlyTotalComplianceDataInitial, yearlyTotalComplianceDataRevised, yearlyTotalCRs, 0, '', '', '', '', '', '', '', '', ''])
        complianceWorkBookData[closer].push(['Totals', '', '', '', '', yearlyTotalComplianceDataInitial, yearlyTotalComplianceDataRevised, yearlyTotalCRs, 0, '', '', '', '', '', '', '', '', ''])

        // Spacer Row
        complianceWorkBookData['Yearly Totals'].push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
        complianceWorkBookData[closer].push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
      })

      // this contains the data for both the Closing and Compliance Reports
      yield put({ type: 'GET_CLOSING_REPORT_SUCCEEDED', closingReportData: { closingWorkbook: closingWorkBookData, complianceWorkbook: complianceWorkBookData } })
    } else if (closingPipeline.data.status === 401) {
      // This case is for when a users Password has changed
      yield put({ type: 'ENCOMPASS_LOGIN_FAILED', getPipelineErrorMessage: 'AO.PLN.001:' + closingPipeline.data.response })
      yield put({ type: 'IS_ENCOMPASS_LINKED_FALSE' })
    } else if (closingPipeline.data.status === 400) {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002:' + closingPipeline.data.response })
    } else {
      yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002:' + closingPipeline.data.response })
    }
  } catch (error) {
    console.log(error)

    yield put({ type: 'ENCOMPASS_CONNECTION_ERRORED', getPipelineErrorMessage: 'AO.PLN.002: Something really bad happened and Encompass doesn\'t know!' })
  }
}

async function fetchLoanAuditData (scenarios, fields) {
  return axios({
    method: 'POST',
    url: settings.URL + settings.PORT + settings.ENDPOINTS.audit,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      audit: fields,
      scenario: scenarios
    }
  }).then(response => {
    return response
  }).catch(function (error) {
    return error
  })
}

// Axios Calling Functions

async function fetchLoanNumberPipelineData (pipelineParam, config) {
  return axios({
    method: 'POST',
    url: settings.URL + settings.PORT + settings.ENDPOINTS.pipeline,
    headers: {
      'Content-Type': 'application/json',
      username: pipelineParam.username
    },
    data: config
  }).then(response => {
    return response
  }).catch(function (error) {
    return error
  })
}

async function fetchFundingsData (queryParams) {
  var config = {
    headers: {
      'Content-Type': 'application/json'
    },
    query: queryParams.query
  }

  return axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.fundings, config).then(response => {
    return response.data
  })
}

function fetchPipelineNumbersData (configs, pipelineParam) {
  function request () {
    return axios({
      method: 'POST',
      url: settings.URL + settings.PORT + settings.ENDPOINTS.pipeline,
      headers: {
        'Content-Type': 'application/json',
        username: pipelineParam.username
      },
      data: configs
    })
  }
  return request()
}

// Encompass Config Builder Functions
function getLoanNumberReportConfigs (pipelineParam) {
  // Get Intervals
  const configs = []

  if (pipelineParam.forDecisionsMadeReport) {
    if (pipelineParam.startDate === pipelineParam.endDate) {
      configs.push(
        {
          config: {
            filter: {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    // Fields.2300 CreditApprovalDate Underwriting Credit Approval Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2300',
                          value: pipelineParam.startDate,
                          matchType: 'greaterThanOrEquals',
                          precision: 'exact'
                        },
                        {
                          canonicalName: 'Fields.2300',
                          value: pipelineParam.endDate,
                          matchType: 'lessThanOrEquals',
                          precision: 'exact'
                        }
                      ]
                    },
                    // Fields.2987 DeniedDate Underwriting Denied Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2987',
                          value: pipelineParam.startDate,
                          matchType: 'greaterThanOrEquals',
                          precision: 'exact'
                        },
                        {
                          canonicalName: 'Fields.2987',
                          value: pipelineParam.endDate,
                          matchType: 'lessThanOrEquals',
                          precision: 'exact'
                        }
                      ]
                    },
                    // Fields.2303 SuspendedDate Underwriting Suspended Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2303',
                          value: pipelineParam.startDate,
                          matchType: 'greaterThanOrEquals',
                          precision: 'exact'
                        },
                        {
                          canonicalName: 'Fields.2303',
                          value: pipelineParam.endDate,
                          matchType: 'lessThanOrEquals',
                          precision: 'exact'
                        }
                      ]
                    }
                  ]
                },
                {
                  canonicalName: 'Fields.420',
                  value: 'Second Lie',
                  matchType: 'exact',
                  include: false
                },
                {
                  canonicalName: 'Fields.2626',
                  value: 'Brokered',
                  matchType: 'exact',
                  include: false
                },
                {
                  operator: 'and',
                  terms: [
                    {
                      operator: 'or',
                      terms: [
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: '2012 Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Audit Files',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Bonds 2nds',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Consumer Connect',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Denials to be printed',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects (Current)',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'My Pipeline',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Pending Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Servicing',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Withdraws to be printed',
                          matchType: 'exact',
                          include: 'true'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            fields: [
              'Loan.LoanNumber'
            ]
          }
        })
    } else {
    // Custom Report - Decisions Made
    // Fields.2300 CreditApprovalDate Underwriting Credit Approval Date
    // Fields.2987 DeniedDate Underwriting Denied Date
    // Fields.2303 SuspendedDate Underwriting Suspended Date

      const intervals = getIntervalSegmentsForReport(pipelineParam.startDate, pipelineParam.endDate)

      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i] === pipelineParam.endDate) {
          continue
        }

        configs.push(
          {
            config: {
              filter:
            {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    // Fields.2300 CreditApprovalDate Underwriting Credit Approval Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2300',
                          value: intervals[i],
                          matchType: 'greaterThanOrEquals',
                          precision: 'day'
                        },
                        {
                          canonicalName: 'Fields.2300',
                          value: getDayBefore(intervals[i + 1], pipelineParam.endDate),
                          matchType: 'lessThanOrEquals',
                          precision: 'day'
                        }
                      ]
                    },
                    // Fields.2987 DeniedDate Underwriting Denied Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2987',
                          value: intervals[i],
                          matchType: 'greaterThanOrEquals',
                          precision: 'day'
                        },
                        {
                          canonicalName: 'Fields.2987',
                          value: getDayBefore(intervals[i + 1], pipelineParam.endDate),
                          matchType: 'lessThanOrEquals',
                          precision: 'day'
                        }
                      ]
                    },
                    // Fields.2303 SuspendedDate Underwriting Suspended Date (Decisions Made)
                    {
                      operator: 'and',
                      terms: [
                        {
                          canonicalName: 'Fields.2303',
                          value: intervals[i],
                          matchType: 'greaterThanOrEquals',
                          precision: 'day'
                        },
                        {
                          canonicalName: 'Fields.2303',
                          value: getDayBefore(intervals[i + 1], pipelineParam.endDate),
                          matchType: 'lessThanOrEquals',
                          precision: 'day'
                        }
                      ]
                    }
                  ]
                },
                {
                  canonicalName: 'Fields.420',
                  value: 'Second Lie',
                  matchType: 'exact',
                  include: false
                }, {
                  canonicalName: 'Fields.2626',
                  value: 'Brokered',
                  matchType: 'exact',
                  include: false
                },
                {
                  operator: 'and',
                  terms: [
                    {
                      operator: 'or',
                      terms: [
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: '2012 Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Audit Files',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Bonds 2nds',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Consumer Connect',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Denials to be printed',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects (Current)',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'My Pipeline',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Pending Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Servicing',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Withdraws to be printed',
                          matchType: 'exact',
                          include: 'true'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
              fields: [
                'Loan.LoanNumber'
              ]
            }
          })
      }
    }
  } else {
    if (pipelineParam.startDate !== pipelineParam.endDate && pipelineParam.forBankingReport) {
      const intervals = getIntervalSegmentsForReport(pipelineParam.startDate, pipelineParam.endDate)

      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i] === pipelineParam.endDate) {
          continue
        }

        configs.push(
          {
            config: {
              filter:
          {
            operator: 'and',
            terms: [
              {
                canonicalName: 'Fields.' + pipelineParam.filter,
                value: intervals[i],
                matchType: 'greaterThanOrEquals',
                precision: 'day'
              },
              {
                canonicalName: 'Fields.' + pipelineParam.filter,
                value: getDayBefore(intervals[i + 1], pipelineParam.endDate),
                matchType: 'lessThanOrEquals',
                precision: 'day'
              },
              {
                canonicalName: 'Fields.420',
                value: 'Second Lie',
                matchType: 'exact',
                include: false
              },
              {
                operator: 'and',
                terms: [
                  {
                    operator: 'or',
                    terms: [
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: '2012 Completed Loans',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Adverse Loans',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Audit Files',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Bonds 2nds',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Closed Loans',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Closed Special',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Completed Loans',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Consumer Connect',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Denials to be printed',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Inactive Prospects',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Inactive Prospects (Current)',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'My Pipeline',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Pending Adverse Loans',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Prospects',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Servicing',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Special',
                        matchType: 'exact',
                        include: 'true'
                      },
                      {
                        canonicalName: 'Loan.LoanFolder',
                        value: 'Withdraws to be printed',
                        matchType: 'exact',
                        include: 'true'
                      }
                    ]
                  }
                ]
              }
            ]
          },
              fields: [
                'Loan.LoanNumber',
                'Fields.VEND.X200'

              ],
              sortOrder: [
                {
                  canonicalName: 'Fields.' + pipelineParam.filter,
                  order: 'asc'
                }
              ]
            }
          })
      }
    } else if (pipelineParam.startDate === pipelineParam.endDate && pipelineParam.forBankingReport) {
      // If the start date and end date are the same and this is for a banking report we want to include brokered loans so that term is removed from the config and no intervals are needed
      configs.push(
        {
          config: {
            filter:
        {
          operator: 'and',
          terms: [
            {
              canonicalName: 'Fields.' + pipelineParam.filter,
              value: pipelineParam.startDate,
              matchType: 'greaterThanOrEquals',
              precision: 'exact'
            },

            {
              canonicalName: 'Fields.420',
              value: 'Second Lie',
              matchType: 'exact',
              include: false
            }, {
              canonicalName: 'Fields.' + pipelineParam.filter,
              value: pipelineParam.endDate,
              matchType: 'lessThanOrEquals',
              precision: 'exact'
            },
            {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: '2012 Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Audit Files',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Bonds 2nds',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Consumer Connect',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Denials to be printed',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects (Current)',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'My Pipeline',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Pending Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Servicing',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Withdraws to be printed',
                      matchType: 'exact',
                      include: 'true'
                    }
                  ]
                }
              ]
            }
          ]
        },
            fields: [
              'Loan.LoanNumber',
              'Fields.VEND.X200'
            ],
            sortOrder: [
              {
                canonicalName: 'Fields.' + pipelineParam.filter,
                order: 'asc'
              }
            ]
          }
        })
    } else if (pipelineParam.startDate === pipelineParam.endDate && !pipelineParam.forBankingReport) {
      // If the start date and end date are the same and this is NOT for a banking report we want do not want to include brokered loans and no intervals are needed
      configs.push(
        {
          config: {
            filter:
        {
          operator: 'and',
          terms: [
            {
              canonicalName: 'Fields.' + pipelineParam.filter,
              value: pipelineParam.startDate,
              matchType: 'greaterThanOrEquals',
              precision: 'exact'
            },
            {
              canonicalName: 'Fields.420',
              value: 'Second Lie',
              matchType: 'exact',
              include: false
            },
            {
              canonicalName: 'Fields.' + pipelineParam.filter,
              value: pipelineParam.endDate,
              matchType: 'lessThanOrEquals',
              precision: 'exact'
            },
            {
              canonicalName: 'Fields.2626',
              value: 'Brokered',
              matchType: 'exact',
              include: false
            },
            {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: '2012 Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Audit Files',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Bonds 2nds',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Consumer Connect',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Denials to be printed',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects (Current)',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'My Pipeline',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Pending Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Servicing',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Withdraws to be printed',
                      matchType: 'exact',
                      include: 'true'
                    }
                  ]
                }
              ]
            }
          ]
        },
            fields: [
              'Loan.LoanNumber'
            ],
            sortOrder: [
              {
                canonicalName: 'Fields.' + pipelineParam.filter,
                order: 'asc'
              }
            ]
          }
        })
    } else {
      // If the start date and end date are not the same and this is NOT for a banking report we want do not want to include brokered loans and we need to create intervals for large date ranges
      const intervals = getIntervalSegmentsForReport(pipelineParam.startDate, pipelineParam.endDate)

      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i] === pipelineParam.endDate) {
          continue
        }

        configs.push(
          {
            config: {
              filter:
            {
              operator: 'and',
              terms: [
                {
                  canonicalName: 'Fields.' + pipelineParam.filter,
                  value: intervals[i],
                  matchType: 'greaterThanOrEquals',
                  precision: 'day'
                },
                {
                  canonicalName: 'Fields.' + pipelineParam.filter,
                  value: getDayBefore(intervals[i + 1], pipelineParam.endDate),
                  matchType: 'lessThanOrEquals',
                  precision: 'day'
                },
                {
                  canonicalName: 'Fields.420',
                  value: 'Second Lie',
                  matchType: 'exact',
                  include: false
                }, {
                  canonicalName: 'Fields.2626',
                  value: 'Brokered',
                  matchType: 'exact',
                  include: false
                },
                {
                  operator: 'and',
                  terms: [
                    {
                      operator: 'or',
                      terms: [
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: '2012 Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Audit Files',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Bonds 2nds',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Closed Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Completed Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Consumer Connect',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Denials to be printed',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Inactive Prospects (Current)',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'My Pipeline',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Pending Adverse Loans',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Prospects',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Servicing',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Special',
                          matchType: 'exact',
                          include: 'true'
                        },
                        {
                          canonicalName: 'Loan.LoanFolder',
                          value: 'Withdraws to be printed',
                          matchType: 'exact',
                          include: 'true'
                        }
                      ]
                    }
                  ]
                }
              ]
            },
              fields: [
                'Loan.LoanNumber'
              ],
              sortOrder: [
                {
                  canonicalName: 'Fields.' + pipelineParam.filter,
                  order: 'asc'
                }
              ]
            }
          })
      }
    }
  }

  return configs
}

function getClosingReportConfigs (filter, startDate, endDate, fields) {
  // For Compliance Data we need to search across multiple fields so this needs a special encompassConfig
  if (Array.isArray(filter)) {
    return {
      config: {
        filter:
        {
          operator: 'and',
          terms: [
            {
              operator: 'or',
              terms: [
                {
                  operator: 'and',
                  terms: [{
                    canonicalName: 'Fields.' + filter[0],
                    value: startDate,
                    matchType: 'greaterThanOrEquals',
                    precision: 'day'
                  },
                  {
                    canonicalName: 'Fields.' + filter[0],
                    value: endDate,
                    matchType: 'lessThanOrEquals',
                    precision: 'day'
                  }]
                },
                {
                  operator: 'and',
                  terms: [{
                    canonicalName: 'Fields.' + filter[1],
                    value: startDate,
                    matchType: 'greaterThanOrEquals',
                    precision: 'day'
                  },
                  {
                    canonicalName: 'Fields.' + filter[1],
                    value: endDate,
                    matchType: 'lessThanOrEquals',
                    precision: 'day'
                  }]
                },
                {
                  operator: 'and',
                  terms: [{
                    canonicalName: 'Fields.' + filter[2],
                    value: startDate,
                    matchType: 'greaterThanOrEquals',
                    precision: 'day'
                  },
                  {
                    canonicalName: 'Fields.' + filter[2],
                    value: endDate,
                    matchType: 'lessThanOrEquals',
                    precision: 'day'
                  }]
                },
                {
                  operator: 'and',
                  terms: [{
                    canonicalName: 'Fields.' + filter[3],
                    value: startDate,
                    matchType: 'greaterThanOrEquals',
                    precision: 'day'
                  },
                  {
                    canonicalName: 'Fields.' + filter[3],
                    value: endDate,
                    matchType: 'lessThanOrEquals',
                    precision: 'day'
                  }]
                },
                {
                  operator: 'and',
                  terms: [{
                    canonicalName: 'Fields.' + filter[4],
                    value: startDate,
                    matchType: 'greaterThanOrEquals',
                    precision: 'day'
                  },
                  {
                    canonicalName: 'Fields.' + filter[4],
                    value: endDate,
                    matchType: 'lessThanOrEquals',
                    precision: 'day'
                  }]
                }
              ]
            },
            {
              canonicalName: 'Fields.420',
              value: 'Second Lie',
              matchType: 'exact',
              include: false
            },
            {
              canonicalName: 'Fields.2626',
              value: 'Brokered',
              matchType: 'exact',
              include: false
            },
            {
              operator: 'and',
              terms: [
                {
                  operator: 'or',
                  terms: [
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: '2012 Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Audit Files',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Bonds 2nds',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Closed Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Completed Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Consumer Connect',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Denials to be printed',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Inactive Prospects (Current)',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'My Pipeline',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Pending Adverse Loans',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Prospects',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Servicing',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Special',
                      matchType: 'exact',
                      include: 'true'
                    },
                    {
                      canonicalName: 'Loan.LoanFolder',
                      value: 'Withdraws to be printed',
                      matchType: 'exact',
                      include: 'true'
                    }
                  ]
                }
              ]
            }
          ]
        },
        fields: fields,
        sortOrder: [
          {
            canonicalName: 'Fields.1997',
            order: 'asc'
          }
        ]
      }
    }
  }

  return {
    config: {
      filter:
      {
        operator: 'and',
        terms: [
          {
            canonicalName: 'Fields.' + filter,
            value: startDate,
            matchType: 'greaterThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.' + filter,
            value: endDate,
            matchType: 'lessThanOrEquals',
            precision: 'day'
          },
          {
            canonicalName: 'Fields.420',
            value: 'Second Lie',
            matchType: 'exact',
            include: false
          },
          {
            canonicalName: 'Fields.2626',
            value: 'Brokered',
            matchType: 'exact',
            include: false
          },
          {
            operator: 'and',
            terms: [
              {
                operator: 'or',
                terms: [
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: '2012 Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Audit Files',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Bonds 2nds',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Closed Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Completed Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Consumer Connect',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Denials to be printed',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Inactive Prospects (Current)',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'My Pipeline',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Pending Adverse Loans',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Prospects',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Servicing',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Special',
                    matchType: 'exact',
                    include: 'true'
                  },
                  {
                    canonicalName: 'Loan.LoanFolder',
                    value: 'Withdraws to be printed',
                    matchType: 'exact',
                    include: 'true'
                  }
                ]
              }
            ]
          }
        ]
      },
      fields: fields,
      sortOrder: [
        {
          canonicalName: 'Fields.1997',
          order: 'asc'
        }
      ]
    }
  }
}

// This function is used to make each of the calls
// seperately and concatenate the results to return
// all the results in one object.

// Database Functions
function executeMongoFindDBQuery (collection, configs) {
  return axios({
    method: 'POST',
    url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_find,
    headers: {
      'Content-Type': 'application/json',
      collection: collection
    },
    data: configs
  }).then((response) => {
    return response.data
  })
}

function executeMongoCountDBQuery (collection, configs) {
  return axios({
    method: 'POST',
    url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_count,
    headers: {
      'Content-Type': 'application/json',
      collection: collection
    },
    data: configs
  }).then((response) => {
    return response.data
  })
}

// Helper Functions
// Splits large array into chunks
function chunkArray (myArray, chunkSize) {
  var results = []

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize))
  }

  return results
}

function convertMongoDate (date) {
  if (date) {
    var justDate = date.split('T')[0]
    var dateParts = justDate.split('-')

    if (parseInt(dateParts[1]) < 10) {
      return dateParts[1].replace(/^0+/, '') + '/' + dateParts[2] + '/' + dateParts[0]
    } else {
      return dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0]
    }
  } else {
    return ''
  }
}

/**
 * Constructor function that creates a domain specific Loan Document object.
 * 
 * @param {object} doc Document object that is sourced from MongoDB
 */
function translateMongoDoc (doc) {
  return {
    loanGuid: doc.guid,
    // Fields.2300
    // UWCreditApprovalDate
    // CreditApprovalDate
    // Underwriting Credit Approval Date (Decisions Made)
    CreditApprovalDate: convertMongoDate(doc.saveState[0].loanData.UWCreditApprovalDate),
    // Fields.2987
    // UWDeniedDate
    // DeniedDate
    // Underwriting Denied Date (Decisions Made)
    DeniedDate: convertMongoDate(doc.saveState[0].loanData.UWDeniedDate),
    // Fields.2303
    // UWSuspendedDate
    // SuspendedDate
    // Underwriting Suspended Date (Decisions Made)
    SuspendedDate: convertMongoDate(doc.saveState[0].loanData.UWSuspendedDate),
    ApplicationDate: convertMongoDate(doc.saveState[0].loanData.ApplicationDate),
    PreApplicationDate: convertMongoDate(doc.saveState[0].loanData.PreApplicationDate),
    ShippedDate: convertMongoDate(doc.saveState[0].loanData.ShippingDate),
    ClearToCloseDate: convertMongoDate(doc.saveState[0].loanData.UWCleartoCloseDate),
    DecisionMadeDate: convertMongoDate(doc.saveState[0].loanData.UWApprovalDate),
    CondApprovalDate: convertMongoDate(doc.saveState[0].loanData.UWCreditApprovalDate), // Cond Approval
    EstCloseDate: convertMongoDate(doc.saveState[0].loanData.EstClosingDate),
    FundedDate: convertMongoDate(doc.saveState[0].loanData.FundsReleasedDate),
    LockExpDate: convertMongoDate(doc.saveState[0].loanData.RateLockExpires), // Rate Locked
    LockedDate: convertMongoDate(doc.saveState[0].loanData.BuySideLockedDate), // Locked
    ResubmittalDate: convertMongoDate(doc.saveState[0].loanData.UWResubmittalDate),
    SubmittedToProcDate: convertMongoDate(doc.saveState[0].loanData.SubmittedToProcessingDate),
    SubmittedToUWDate: convertMongoDate(doc.saveState[0].loanData.UWSubmittedDate),
    BorrowerFirstName: doc.saveState[0].loanData.BorrowerFirstName,
    BorrowerLastName: doc.saveState[0].loanData.BorrowerLastName,
    Branch: doc.saveState[0].loanData.Branch,
    Region: doc.saveState[0].loanData.Region,
    Team: doc.saveState[0].loanData.Team,
    Department: doc.saveState[0].loanData.Department,
    Closer: doc.saveState[0].loanData.CloserName,
    Shipper: doc.saveState[0].loanData.ShipperName,
    Underwriter: doc.saveState[0].loanData.UnderwriterName,
    Processor: doc.saveState[0].loanData.ProcessorName,
    LOName: doc.saveState[0].loanData.LoanOfficerName,
    LOEmail: doc.saveState[0].loanData.LoanOfficerEmail,
    LoanAmount: doc.saveState[0].loanData.LoanAmount,
    LoanNumber: doc.saveState[0].loanData.LoanNumber,
    TotalLoanAmount: doc.saveState[0].loanData.TotalLoanAmount
  }
}

function translateMongoBankDoc (doc) {
  return {
    loanGuid: doc.guid,
    FundedDate: convertMongoDate(doc.saveState[0].loanData.FundsReleasedDate),
    BorrowerFirstName: doc.saveState[0].loanData.BorrowerFirstName,
    BorrowerLastName: doc.saveState[0].loanData.BorrowerLastName,
    Region: doc.saveState[0].loanData.Region,
    Team: doc.saveState[0].loanData.Team,
    Department: doc.saveState[0].loanData.Department,
    LOName: doc.saveState[0].loanData.LoanOfficerName,
    LoanAmount: doc.saveState[0].loanData.LoanAmount,
    LoanNumber: doc.saveState[0].loanData.LoanNumber,
    TotalLoanAmount: doc.saveState[0].loanData.TotalLoanAmount,
    LoanOfficerName: doc.saveState[0].loanData.LoanOfficerName,
    Investor: doc.saveState[0].loanData.Investor,
    LoanInfoChannel: doc.saveState[0].loanData.LoanInfoChannel,
    LoanProgram: doc.saveState[0].loanData.LoanProgram,
    LoanType: doc.saveState[0].loanData.LoanType,
    LoanPurpose: doc.saveState[0].loanData.LoanPurpose,
    SubjectPropertyState: doc.saveState[0].loanData.SubjectPropertyState
  }
}

function getFormattedSugarDate (sugarDate) {
  var currentDay = new Date(sugarDate)
  var dayBefore = new Date(currentDay)
  dayBefore.setDate(currentDay.getDate())
  var mMonth = dayBefore.getMonth() + 1
  var mDay = dayBefore.getDate() < 10 ? '0' + dayBefore.getDate() : dayBefore.getDate()
  var mYear = dayBefore.getFullYear()
  var mySugarDate = mMonth + '/' + mDay + '/' + mYear

  return mySugarDate
}

function getFormattedSugarDayBefore (sugarDate) {
  var currentDay = new Date(sugarDate)
  var dayBefore = new Date(currentDay)
  dayBefore.setDate(currentDay.getDate() - 1)
  var mMonth = dayBefore.getMonth() + 1
  var mDay = dayBefore.getDate() < 10 ? '0' + dayBefore.getDate() : dayBefore.getDate()
  var mYear = dayBefore.getFullYear()
  var mySugarDate = mMonth + '/' + mDay + '/' + mYear

  return mySugarDate
}

function getFormattedLastMonthStart (sugarDate) {
  var currentDay = new Date(sugarDate)
  var mMonth = currentDay.getMonth()
  var mDay = '01'
  var mYear = currentDay.getFullYear()
  var mySugarDate = mMonth + '/' + mDay + '/' + mYear

  return mySugarDate
}

// Breaks a given interval into specified segments
function getIntervalSegmentsForTrends (startDate, endDate) {
  var itr = moment.twix(startDate, endDate).iterate(1, 'month')

  return handleITR(itr, startDate)
}

function getIntervalSegmentsForReport (startDate, endDate) {
  var startDateObj = Date.parse(startDate)
  var endDateObj = Date.parse(endDate)

  var endToStartInMilli = endDateObj - startDateObj
  const twoWeeksMilli = 1209600000
  const twoMonthsMilli = 4838400000
  const oneYearMillli = 31449600000

  // If range is less than 2 weeks group by Day
  if (endToStartInMilli < twoWeeksMilli) {
    return [startDate, endDate]
  } else if (endToStartInMilli < twoMonthsMilli) {
    // If range is less than 2 months group by week
    const itr = moment.twix(startDateObj, endDateObj).iterate(1, 'week')
    return handleITR(itr, startDate)
  } else if (endToStartInMilli < oneYearMillli) {
    // If range is less than 1.5 years group by month
    const itr = moment.twix(startDateObj, endDateObj).iterate(1, 'month')
    return handleITR(itr, startDate)
  } else {
    // If Range is greater than 1.5 years group by year
    const itr = moment.twix(startDateObj, endDateObj).iterate(4, 'month')
    return handleITR(itr, startDate)
  }
}

function handleITR (itr, startDate) {
  const range = []

  while (itr.hasNext()) {
    const dateInterval = itr.next().toDate()
    const month = dateInterval.getMonth() + 1
    const date = dateInterval.getDate()
    const year = dateInterval.getFullYear()
    // const hour = dateInterval.getHours()
    // const minute = dateInterval.getMinutes() === 0 ? '00' : dateInterval.getMinutes()

    const finalDateTime = month + '/' + date + '/' + year

    range.push(finalDateTime)
  }

  if (range[0] !== startDate) {
    range[0] = startDate
  }

  return range
}

function dateCheck (from, to, check) {
  const fDate = Date.parse(from)
  const lDate = Date.parse(to)
  const cDate = Date.parse(check)

  if ((cDate <= lDate && cDate >= fDate)) {
    return true
  }

  return false
}

function getDayBefore (dateString, endDate) {
  // returns the day before for the encompass calls.
  // If the day is the same as the end date then we will just return the end date
  if (dateString && dateString !== endDate) {
    var currentDay = new Date(dateString)
    var dayBefore = new Date(currentDay)

    dayBefore.setDate(currentDay.getDate() - 1)

    return dayBefore.getMonth() + 1 + '/' + dayBefore.getDate() + '/' + dayBefore.getFullYear()
  } else {
    return endDate
  }
}

function convertDate (userDate, location) {
  // convert parameter to a date
  var returnDate = new Date(userDate)

  // get the day, month and year
  var y = returnDate.getFullYear()
  var m = returnDate.getMonth() + 1
  var d = returnDate.getDate()

  // converting the integer values we got above to strings
  y = y.toString()
  m = m.toString()
  d = d.toString()

  // making days or months always 2 digits
  if (m.length === 1) {
    m = '0' + m
  }

  if (d.length === 1) {
    d = '0' + d
  }

  if (location === 'start') {
    returnDate = y + '-' + m + '-' + d + 'T00:00:00.000Z'
  } else {
    returnDate = y + '-' + m + '-' + d + 'T23:59:59.000Z'
  }

  return returnDate
}

function makeFormattedLeaderboardPipelineData( dataObj ) {
  const {
    individualMonthApplied,
    branchMonthApplied,
    regionMonthApplied,
    individualLastMonthApplied,
    branchLastMonthApplied,
    regionLastMonthApplied,
    individualYearApplied,
    branchYearApplied,
    regionYearApplied,
    individualLastYearApplied,
    branchLastYearApplied,
    regionLastYearApplied,
    individualMonthFunded,
    branchMonthFunded,
    regionMonthFunded,
    individualLastMonthFunded,
    branchLastMonthFunded,
    regionLastMonthFunded,
    individualYearFunded,
    branchYearFunded,
    regionYearFunded,
    individualLastYearFunded,
    branchLastYearFunded,
    regionLastYearFunded,
  } = dataObj;
  
  return ({
    Year: {
      Region: {
        Funded: regionYearFunded,
        Application: regionYearApplied
      },
      Branch: {
        Funded: branchYearFunded,
        Application: branchYearApplied
      },
      Individual: {
        Funded: individualYearFunded,
        Application: individualYearApplied
      }
    },
    LastYear: {
      Region : {
        Funded: regionLastYearFunded,
        Application: regionLastYearApplied
      },
      Branch: {
        Funded: branchLastYearFunded,
        Application: branchLastYearApplied
      },
      Individual: {
        Funded: individualLastYearFunded,
        Application: individualLastYearApplied
      }
    },
    Month: {
      Region: {
        Funded: regionMonthFunded,
        Application: regionMonthApplied
      },
      Branch: {
        Funded: branchMonthFunded,
        Application: branchMonthApplied
      },
      Individual: {
        Funded: individualMonthFunded,
        Application: individualMonthApplied
      }
    },
    LastMonth: {
      Region: {
        Funded: regionLastMonthFunded,
        Application: regionLastMonthApplied
      },
      Branch: {
        Funded: branchLastMonthFunded,
        Application: branchLastMonthApplied
      },
      Individual: {
        Funded: individualLastMonthFunded,
        Application: individualLastMonthApplied
      }
    }
  })
}

function insertLoan( obj, key, value ) {
  let newObj = { ...obj };

  if ( ! newObj.hasOwnProperty(key) )
  {
    newObj[key] = [];
  }

  newObj[key].push(value);

  return newObj;
}

function processLeaderboardPipeline(dataFromMongo) {
  var Sugar = require('sugar-date')
  var today = getFormattedSugarDate(Sugar.Date.create('Today'))

  var lastMonthStart = getFormattedSugarDate(Sugar.Date.create('First day of last month'))
  var lastMonthEnd = getFormattedSugarDate(Sugar.Date.create('Last day of last month'))
  var twoMonthsStart = getFormattedLastMonthStart(Sugar.Date.create('last month'))
  var twoMonthsEnd = getFormattedSugarDayBefore(lastMonthStart)
  var firstDayThisYear = getFormattedSugarDate(Sugar.Date.create('First day of this year'))
  var firstDayLastYear = getFormattedSugarDate(Sugar.Date.create('First day of last year'))
  var yearAgoToday = getFormattedSugarDate(Sugar.Date.create('1 Year Ago'))

  var teamList = []

  let individualMonthApplied = {},
      branchMonthApplied = {},
      regionMonthApplied = {},
      individualLastMonthApplied = {},
      branchLastMonthApplied = {},
      regionLastMonthApplied = {},
      individualYearApplied = {},
      branchYearApplied = {},
      regionYearApplied = {},
      individualLastYearApplied = {},
      branchLastYearApplied = {},
      regionLastYearApplied = {},
      individualMonthFunded = {},
      branchMonthFunded = {},
      regionMonthFunded = {},
      individualLastMonthFunded = {},
      branchLastMonthFunded = {},
      regionLastMonthFunded = {},
      individualYearFunded = {},
      branchYearFunded = {},
      regionYearFunded = {},
      individualLastYearFunded = {},
      branchLastYearFunded = {},
      regionLastYearFunded = {};

  dataFromMongo.forEach((doc) => {
    if (doc.saveState[0]) {
      // If Funded Date is in This Year add to the all mon
      let singleVal = translateMongoDoc(doc);
      let loanOriginatorName = singleVal.LOName;
      let teamNumber = singleVal.Team ? singleVal.Team : singleVal.Branch
      let regionNumber = singleVal.Region ? singleVal.Region : regionMap[singleVal.Branch]

      if (regionNumber === undefined) {
        return
      }

      if (teamNumber === '000') {
        return;
      }

      // Mutate the team list to add items onto it
      if (teamList.indexOf(teamNumber) === -1) 
      {
        teamList.push(teamNumber)
      }
      // Add Application Date

      // Month Ago Today through the day before a month ago
      if (dateCheck(lastMonthStart, lastMonthEnd, singleVal.ApplicationDate)) {
        individualMonthApplied = insertLoan(individualMonthApplied, loanOriginatorName, singleVal);
        branchMonthApplied = insertLoan(branchMonthApplied, teamNumber, singleVal);
        regionMonthApplied = insertLoan(regionMonthApplied, regionNumber, singleVal);
      }

      // 2 Months Ago 1 Year Ago through the day before a 2 Months ago
      if (dateCheck(twoMonthsStart, twoMonthsEnd, singleVal.ApplicationDate)) {
        individualLastMonthApplied = insertLoan(individualLastMonthApplied, loanOriginatorName, singleVal);
        branchLastMonthApplied = insertLoan(branchLastMonthApplied, teamNumber, singleVal);
        regionLastMonthApplied = insertLoan(regionLastMonthApplied, regionNumber, singleVal);
      }

      // Year Ago Today through the day before a year ago
      if (dateCheck(firstDayThisYear, today, singleVal.ApplicationDate)) {
        individualYearApplied = insertLoan(individualYearApplied, loanOriginatorName,singleVal);
        branchYearApplied = insertLoan(branchYearApplied, teamNumber,singleVal);
        regionYearApplied = insertLoan(regionYearApplied, regionNumber, singleVal);
      }

      // 2 Years Ago 1 Year Ago through the day before a 2 Years ago
      if (dateCheck(firstDayLastYear, yearAgoToday, singleVal.ApplicationDate)) {
        individualLastYearApplied = insertLoan(individualLastYearApplied, loanOriginatorName, singleVal);
        branchLastYearApplied = insertLoan(branchLastYearApplied, teamNumber, singleVal);
        regionLastYearApplied = insertLoan(regionLastYearApplied, regionNumber, singleVal);
      }

      // Add Funded Date

      /**
       * This will not add the current value to the funded date if there is none listed
       */
      if ( ! singleVal.FundedDate.trim() )
      {
        return;
      }

      /**
       * Below code seems to go take every piece of pipeline data,
       * check each pipeline to see what kind of date it falls in and apply
       * to a specific formatted data property
       */
      // Month Ago Today through the day before a month ago
      if (dateCheck(lastMonthStart, lastMonthEnd, singleVal.FundedDate)) {
        individualMonthFunded = insertLoan(individualMonthFunded, loanOriginatorName, singleVal);
        branchMonthFunded = insertLoan(branchMonthFunded, teamNumber, singleVal);
        regionMonthFunded = insertLoan(regionMonthFunded, regionNumber, singleVal);
      }

      // 2 Months Ago 1 Year Ago through the day before a 2 Months ago
      if (dateCheck(twoMonthsStart, twoMonthsEnd, singleVal.FundedDate)) {
        individualLastMonthFunded = insertLoan(individualLastMonthFunded, loanOriginatorName, singleVal);
        branchLastMonthFunded = insertLoan(branchLastMonthFunded, teamNumber, singleVal);
        regionLastMonthFunded = insertLoan(regionLastMonthFunded, regionNumber, singleVal);
      }

      // Year Ago Today through the day before a year ago
      if (dateCheck(firstDayThisYear, today, singleVal.FundedDate)) {
        individualYearFunded = insertLoan(individualYearFunded, loanOriginatorName, singleVal);
        branchYearFunded = insertLoan(branchYearFunded, teamNumber, singleVal);
        regionYearFunded = insertLoan(regionYearFunded, regionNumber, singleVal);
      }

      // 2 Years Ago 1 Year Ago through the day before a 2 Years ago
      if (dateCheck(firstDayLastYear, yearAgoToday, singleVal.FundedDate)) {
        individualLastYearFunded = insertLoan(individualLastYearFunded, loanOriginatorName, singleVal);
        branchLastYearFunded = insertLoan(branchLastYearFunded, teamNumber, singleVal);
        regionLastYearFunded = insertLoan(regionLastYearFunded, regionNumber, singleVal);
      }
    }
  })

  let formattedData = makeFormattedLeaderboardPipelineData({
      individualMonthApplied,
      branchMonthApplied,
      regionMonthApplied,
      individualLastMonthApplied,
      branchLastMonthApplied,
      regionLastMonthApplied,
      individualYearApplied,
      branchYearApplied,
      regionYearApplied,
      individualLastYearApplied,
      branchLastYearApplied,
      regionLastYearApplied,
      individualMonthFunded,
      branchMonthFunded,
      regionMonthFunded,
      individualLastMonthFunded,
      branchLastMonthFunded,
      regionLastMonthFunded,
      individualYearFunded,
      branchYearFunded,
      regionYearFunded,
      individualLastYearFunded,
      branchLastYearFunded,
      regionLastYearFunded
  })
  // Add Branch list to FormattedData
  formattedData.Branches = teamList.sort();

  return formattedData
}

function delay (ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms))
}

export default watchPipeline
