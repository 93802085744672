import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { customFilter, customSort } from '../../../helpers/tableHelpers'
import 'react-table/react-table.css'
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import { Tooltip, OverlayTrigger, Grid, Col, Row, Button, ButtonGroup } from 'react-bootstrap'

import { setSelectedActivity, deleteActivity } from '../../../actions/activityActions';
import { Form, Icon, Message } from 'semantic-ui-react';
import windowSize from 'react-window-size';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


var addTooltip = (
    <Tooltip id="tooltip">
        <strong>Add:</strong> Create new question.
    </Tooltip>
);

var editTooltip = (
    <Tooltip id="tooltip">
        <strong>Edit:</strong> Make changes to selected question.
    </Tooltip>
);
var deleteTooltip = (
    <Tooltip id="tooltip">
        <strong>Delete:</strong> Remove selected question.
    </Tooltip>
);

class ActivityQuestionsTable extends Component {

    constructor(props) {
        super();
        this.state = {
        }
    }

    componentWillMount() {
        this.setState({ questions: this.props.questions })
    }


    getEmployeeById(id) {
        var objToReturn = {
            firstName: '',
            lastName: ''
        };
        if (id) {
            for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
                if (this.props.hrInfo.allEmployeesData[i]._id == id) {
                    objToReturn = this.props.hrInfo.allEmployeesData[i];
                }
            }
            return objToReturn;
        }
        return objToReturn;

    }



    formatActivityData() {
        return this.props.activityInfo.activitiesData.map((activity) => {
            var value = activity.createdBy;
            activity['CreatedByName'] = this.getEmployeeById(value).firstName + ' ' + this.getEmployeeById(value).lastName
            return activity
        })
    }


    delete = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this question?',
            buttons: [

                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteQuestion(this.state.selectedRow) 
                                    this.forceUpdate()}
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        })
    };

    render() {
        return (
            <div>
                <Grid>
                    <Row>
                        <Col style={{ float: 'right' }}>
                            <ButtonGroup>
                                <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={addTooltip}>
                                    <Button onClick={this.props.openAddModal}>
                                        <span style={{ color: '#5cb85c', fontSize: 30 }} ><FontAwesome.FaPlus /></span>
                                    </Button>
                                </OverlayTrigger>
                                {this.state.selectedRowInfo &&
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                                        <Button onClick={()=>this.props.openEditModal(this.state.selectedRowInfo)}>
                                            <span style={{ color: '#428bca', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                                        </Button>
                                    </OverlayTrigger>}
                                {this.state.selectedRowInfo && <OverlayTrigger style={{ textAlign: 'right' }} placement="right" overlay={deleteTooltip}>
                                    <Button onClick={this.delete}>
                                        <span style={{ color: '#ff0000', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                                    </Button>
                                </OverlayTrigger>
                                }
                                {!this.state.selectedRowInfo &&
                                    <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={editTooltip}>
                                        <Button>
                                            <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaEdit /></span>
                                        </Button>
                                    </OverlayTrigger>}

                                {!this.state.selectedRowInfo && <OverlayTrigger style={{ textAlign: 'right' }} placement="right" overlay={deleteTooltip}>
                                    <Button>
                                        <span style={{ color: '#dddddd', fontSize: 30 }} ><FontAwesome.FaTrashO /></span>
                                    </Button>
                                </OverlayTrigger>
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Grid>
                <hr />

                {<ReactTable
                    data={this.state.questions}
                    getTrProps={
                        (state, rowInfo) => {
                            var that = this;

                            if (rowInfo !== undefined) {

                                return {
                                    onClick: (e) => {
                                        that.setState({
                                            selectedRow: rowInfo.index,
                                            selectedRowInfo: rowInfo,
                                            noSelectedRows: false
                                        })
                                    },
                                    style: {
                                        background: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? '#00afec' : 'white' : 'white',
                                        color: that.state.selectedRowInfo ? rowInfo.index === that.state.selectedRow ? 'white' : 'black' : 'black',
                                    }
                                }
                            }
                            else {
                                return {
                                    onClick: (e) => {

                                    }

                                }
                            }
                        }}
                    columns={[
                        {

                            columns: [
                                {
                                    Header: "Question",
                                    accessor: "questionText",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),
                                },
                                {
                                    Header: "Question Type",
                                    accessor: "questionType",
                                    minWidth: 30,
                                    Cell: ({ value, row }) => (<p style={{ textAlign: 'center' }}>{value}</p>),

                                },

                            ]
                        }
                    ]}
                    style={{
                        height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                    }}
                    defaultPageSize={this.state.questions.length}
                    filterable
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSort}
                    className="-striped -highlight"
                    defaultSorted={[
                        {
                            id: "expirationDate",
                            desc: false
                        }
                    ]}
                />}


            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        activityInfo: state.activityReducer,
        hrInfo: state.hrReducer

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSelectedActivity: setSelectedActivity,
            deleteActivity: deleteActivity,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ActivityQuestionsTable));