export const numberWithCommas = (x) => {
    if(x){
    if (x == 0) {
        return '0'
    }
    else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
else
{
    return '';
}
}

export function getTotalDollars(pipelineData) {
    var loanAmounts = 0;
    if (pipelineData.length > 0) {
        pipelineData.map((loan) => {

            var loanAmount = parseFloat(loan['TotalLoanAmount'])

            if (loanAmount) {
                loanAmounts += loanAmount
            }
        });
    }
    return '$' + numberWithCommas(loanAmounts.toFixed(2))
}