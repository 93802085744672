import { put, takeEvery, call } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

//ERROR CODES NEED TO BE GENERATED FOR THIS REDUCER/SAGA

const watchIssues = function* watchGetIssues() {
    yield takeEvery("GET_ISSUES", getIssues);
    yield takeEvery("ADD_ISSUE", addIssue);
    yield takeEvery("RESOLVE_ISSUE", resolveIssue);
    yield takeEvery("GET_CONTACTS", getContacts);
    yield takeEvery("GET_CODES", getCodes);
  };
  
  const getIssues = function* getIssues(action) {
    var issueData = {};
  
    yield put({ type: "RESET_ISSUE_STATUS" });
    yield put({ type: "GET_ISSUE_STARTED" });
    //Gets Pipeline
    try {
        
      issueData = yield call(fetchIssueData, action.payload);
      //console.log("ISSUE RESPONSE", issueData)
        if (issueData.status === 400) {
          yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:issueData.response.error_description });

          return;
        }
        else
          {
            yield put({ type: "GET_ISSUE_SUCCEEDED", issueData: issueData});
          }
  
    } catch (error) {
      yield put({ type: "GET_ISSUE_FAILED", errorMessage: error });
      return;
    }
  };
  async function fetchIssueData(issueParams) {
    var config = {
      headers: {
        'Content-type': 'application/json'
      },
      query: issueParams.query
    };

    return await axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.issue, config).then(response => {
      return response.data;
    });
  }
  const getContacts = function* getContacts(action) {
    var contactData = {};
  
    yield put({ type: "RESET_CONTACTS_STATUS" });
    yield put({ type: "GET_CONTACTS_STARTED" });
    //Gets Pipeline
    try {
        
      contactData = yield call(fetchContactsData, action.payload);
//console.log("CONTACT RESPONSE", contactData)
        if (contactData.status === 400) {
          yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:contactData.response.error_description });

          return;
        }
        else
          {
            yield put({ type: "GET_CONTACTS_SUCCEEDED", contactData: contactData});
          }
  
    } catch (error) {
      yield put({ type: "GET_CONTACTS_FAILED", errorMessage: error });
      return;
    }
  };
  async function fetchContactsData(issueParams) {
    var config = {
      headers: {
        'Content-type': 'application/json'
      },
      query: issueParams.query
    };

    return await axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.issue, config).then(response => {
      return response.data;
    });
  }

  const getCodes = function* getCodes(action) {
    var codeData = {};
  
    yield put({ type: "RESET_CODES_STATUS" });
    yield put({ type: "GET_CODES_STARTED" });
    //Gets Pipeline
    try {
        
      codeData = yield call(fetchCodeData, action.payload);
      //console.log("Code RESPONSE", codeData)
        if (codeData.status === 400) {
          yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:codeData.response.error_description });

          return;
        }
        else
          {
            yield put({ type: "GET_CODES_SUCCEEDED", codeData: codeData});
          }
  
    } catch (error) {
      yield put({ type: "GET_CODES_FAILED", errorMessage: error });
      return;
    }
  };
  async function fetchCodeData(issueParams) {
    var config = {
      headers: {
        'Content-type': 'application/json'
      },
      query: issueParams.query
    };

    return await axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.issue, config).then(response => {
      return response.data;
    });
  }
  
  const addIssue = function* addIssue(action) {
    var issueData = {};
  
    yield put({ type: "RESET_ADD_ISSUE_STATUS" });
    yield put({ type: "ADD_ISSUE_STARTED" });
    //Gets Pipeline
    try {
        
      issueData = yield call(addIssueData, action.payload);
        if (issueData.status === 400) {
          yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:issueData.response.error_description });

          return;
        }
        else
          {
            yield put({ type: "ADD_ISSUE_SUCCEEDED", issueData: issueData});

            var issueData = {};
  
            yield put({ type: "RESET_ISSUE_STATUS" });
            yield put({ type: "GET_ISSUE_STARTED" });
            //Gets Pipeline
            try {
              
              
              
              issueData = yield call(fetchIssueData, {query: "SELECT * FROM IssueList2017 as i JOIN IssueList as l ON i.IssueIssueNumber = l.IssueIssueNumber JOIN IssueContact as c ON i.IssueContactName = c.IssueContactName ORDER BY i.ID ASC"});
              //console.log("ISSUE RESPONSE", issueData)
                if (issueData.status === 400) {
                  yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:issueData.response.error_description });

                  return;
                }
                else
                  {
                    yield put({ type: "GET_ISSUE_SUCCEEDED", issueData: issueData});
                  }
          
            } catch (error) {
              yield put({ type: "GET_ISSUE_FAILED", errorMessage: error });
              return;
            }
          }
          
  
    } catch (error) {
      yield put({ type: "ADD_ISSUE_FAILED", errorMessage: error });
      return;
    }
  };
  async function addIssueData(issueParams) {
   // console.log("QUERY", issueParams)
    var config = {
      headers: {
        'Content-type': 'application/json'
      },
      issueParams
    };

    return await axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.add_issue, config).then(response => {
      return response.data;
    });
  }

  const resolveIssue = function* resolveIssue(action) {
    var issueData = {};
  
    yield put({ type: "RESET_RESOLVE_ISSUE_STATUS" });
    yield put({ type: "RESOLVE_ISSUE_STARTED" });
    //Gets Pipeline
    try {
        
      issueData = yield call(resolveIssueData, action.payload);
        if (issueData.status === 400) {
          yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:issueData.response.error_description });

          return;
        }
        else
          {
            yield put({ type: "RESOLVE_ISSUE_SUCCEEDED", issueData: issueData});

            var issueData = {};
  
            yield put({ type: "RESET_ISSUE_STATUS" });
            yield put({ type: "GET_ISSUE_STARTED" });
            //Gets Pipeline
            try {
              
              
              
              issueData = yield call(fetchIssueData, {query: "SELECT * FROM IssueList2017 as i JOIN IssueList as l ON i.IssueIssueNumber = l.IssueIssueNumber JOIN IssueContact as c ON i.IssueContactName = c.IssueContactName ORDER BY i.ID ASC"});
              //console.log("ISSUE RESPONSE", issueData)
                if (issueData.status === 400) {
                  yield put({ type: "GET_ISSUE_TOKEN_FAILED", errorMessage:issueData.response.error_description });

                  return;
                }
                else
                  {
                    yield put({ type: "GET_ISSUE_SUCCEEDED", issueData: issueData});
                  }
          
            } catch (error) {
              yield put({ type: "GET_ISSUE_FAILED", errorMessage: error });
              return;
            }
          }
  
    } catch (error) {
      yield put({ type: "RESOLVE_ISSUE_FAILED", errorMessage: error });
      return;
    }
  };
  async function resolveIssueData(issueParams) {
    //console.log("QUERY", issueParams)
    var config = {
      headers: {
        'Content-type': 'application/json'
      },
      issueParams
    };

    return await axios.post(settings.URL + settings.PORT + settings.ENDPOINTS.resolve_issue, config).then(response => {
      return response.data;
    });
  }
  

    
  export default watchIssues;