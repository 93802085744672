import { put, takeEvery, call, race } from "redux-saga/effects";
import axios from "axios";
import settings from '../settings.js'

///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
//Employees Saga
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
const watchEmployees = function* watchEmployees() {
  yield takeEvery("GET_LOANORIGINATORS", getLoanOriginators);
  yield takeEvery("GET_ALL_EMPLOYEES", getAllEmployees);
};

const getLoanOriginators = function* getLoanOriginators(action) {
  var loanOriginators = {};

  try {
    loanOriginators = yield call(fetchLoanOriginators);
    if (loanOriginators.data.status === 400) {
      yield put({ type: "ATHENA_LO_LOCATOR_CALL_ERRORED", locatorErrorMessage: 'AO.LCT.005: Unable to retrieve LO Information.  However, you may procceed with Athena Online' });
      return;
    }
    else {
      yield put({ type: "GET_LOANORIGINATORS_SUCCEEDED", loanOriginators: loanOriginators.data });
    }

  } catch (error) {
    yield put({ type: "ATHENA_LO_LOCATOR_CALL_ERRORED", locatorErrorMessage: 'AO.LCT.005: Unable to retrieve LO Information.  However, you may procceed with Athena Online' });
    return;
  }
};
async function fetchLoanOriginators() {
  var headers =
  {
    headers: {
      'content-type': "application/json"
    }

  };
  return axios.get(settings.URL + settings.PORT + settings.ENDPOINTS.locator, headers).then(response => {
    return response;
  }).catch(function (error) {
    console.log('No Results Found', error)
    return error
  });;
}

const getAllEmployees = function* getAllEmployees(action) {
  //var allEmployees = {};

  try {
   // allEmployees = yield call(fetchAllEmployees);
    var mongoConfig = {
      query: {}
    };
    const { employeeMongoData } = yield race({
      employeeMongoData: call(executeMongoFindDBQuery, 'employees', mongoConfig),
      timeout: call(delay, 30000)
    })
    const { locationsMongoData } = yield race({
      locationsMongoData: call(executeMongoFindDBQuery, 'locations', mongoConfig),
      timeout: call(delay, 30000)
    })
    //get all employees from mongo
    if (!employeeMongoData) {
      yield put({ type: 'ATHENA_GET_EMPLOYEES_CALL_ERRORED', getPipelineLeaderboardErrorMessage: 'AO.LCT.005: Warehouse DB timed out waiting for response. Contact System Adminstrator.' })
      return;
    }
    else {
      yield put({ type: "GET_ALL_EMPLOYEES_SUCCEEDED", allEmployees: employeeMongoData, allLocations:locationsMongoData });
    }

    // if (allEmployees.data.status === 400) {
    //   yield put({ type: "ATHENA_GET_EMPLOYEES_CALL_ERRORED", allEmployeesErrorMessage: 'AO.LCT.005: Unable to retrieve LO Information.  However, you may procceed with Athena Online' });
    //   return;
    // }
    // else {
    //   yield put({ type: "GET_ALL_EMPLOYEES_SUCCEEDED", allEmployees: allEmployees.data });
    // }

  } catch (error) {
    yield put({ type: "ATHENA_GET_EMPLOYEES_CALL_ERRORED", allEmployeesErrorMessage: 'AO.LCT.005: Unable to retrieve LO Information.  However, you may procceed with Athena Online' });
    return;
  }
};
async function fetchAllEmployees() {
  var headers =
  {
    headers: {
      'content-type': "application/json"
    }

  };
  return axios.get(settings.URL + settings.PORT + settings.ENDPOINTS.employees, headers).then(response => {
    return response;
  }).catch(function (error) {
    console.log('No Results Found', error)
    return error
  });;
}

function executeMongoFindDBQuery(collection, configs) {

  return axios({
    method: 'POST', url: settings.URL + settings.PORT + settings.ENDPOINTS.mongo_find, headers: {
      'Content-type': 'application/json',
      'collection': collection
    }, data: configs
  }).then((response) => {

    return response.data;
  })

}
function delay(ms) {
  return new Promise(resolve => setTimeout(() => resolve(true), ms))
}
export default watchEmployees;


