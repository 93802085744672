import React, { Component } from 'react';
import { Button, ButtonGroup, Col, Grid, Row, FormControl, ControlLabel, FormGroup, Table } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { resetEmailStatus, sendEmail } from '../../actions/communicationActions';
import { upsertHRData } from '../../actions/hrActions';
import { addIssue, getCodes, getContacts } from '../../actions/issueActions';
import SelectUSState from 'react-select-us-states';
import * as FontAwesome from 'react-icons/lib/fa';
class OnboardingDocumentsForm extends Component {
    constructor(props) {
        super();
        this.state = {
     
            seekingLicensedStates: [] 

        }
        this.addToSeekingLicensed = this.addToSeekingLicensed.bind(this);
        this.handleLicensingRequestToggle = this.handleLicensingRequestToggle.bind(this)
    }

    componentDidMount() {
        this.setState({
            seekingLicensedStates: this.props.hrInfo.selectedApplicant.data.licensingInfo.seekingLicensedStates ? this.props.hrInfo.selectedApplicant.data.licensingInfo.seekingLicensedStates : [],
            licensingRequest: this.props.hrInfo.selectedApplicant.data.licensingInfo.licensingRequest ? this.props.hrInfo.selectedApplicant.data.licensingInfo.licensingRequest : false

        })
    }

    handleLicensingRequestToggle() {
        this.setState({ licensingRequest: !this.state.licensingRequest })
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.licensingInfo.licensingRequest = !newObj.licensingInfo.licensingRequest
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
    }

    addToSeekingLicensed(newValue) {
        var seekingLicensedStates = this.state.seekingLicensedStates;

        //Check To see if State is in list
        if (seekingLicensedStates.indexOf(newValue) === -1) {
            seekingLicensedStates.push(newValue);
        }
        this.setState({ seekingLicensedStates: seekingLicensedStates })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.licensingInfo.seekingLicensedStates = seekingLicensedStates;
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
    }

    removeFromSeekingLicensed(removeMe) {
        var seekingLicensedStates = this.state.seekingLicensedStates;
        var index = seekingLicensedStates.indexOf(removeMe)
        seekingLicensedStates.splice(index, 1);
        this.setState({ seekingLicensedStates: seekingLicensedStates })
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.licensingInfo.seekingLicensedStates = seekingLicensedStates;
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
    }

    render() {
       
        var seekingLicensedStates = this.state.seekingLicensedStates.map((state,i) => {
            return (<tr>
                <td><b style={{ fontSize: '1.25rem' }}>{state}</b></td>
                <td><Button bsStyle="link" onClick={() => this.removeFromSeekingLicensed(i)}>
                    <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                </Button></td>
            </tr>)
        })
        return (

            <div>
                <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                    <br />

                    <Grid>
                        <Row>
                            <Col md={12} sm={12}>
                                <h3>Is Applicant requesting to be licensed?</h3>
                                {this.state.licensingRequest &&
                                    <ButtonGroup>
                                        <Button bsStyle="warning">Yes</Button>
                                        <Button onClick={this.handleLicensingRequestToggle}>No</Button>
                                    </ButtonGroup>
                                }
                                {!this.state.licensingRequest &&
                                    <ButtonGroup>
                                        <Button onClick={this.handleLicensingRequestToggle}>Yes</Button>
                                        <Button bsStyle="warning">No</Button>
                                    </ButtonGroup>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup controlId="formBasicText"  >
                                    <ControlLabel>Add states where seeking license:</ControlLabel>
                                    <SelectUSState id="myId" className="myClassName" onChange={this.addToSeekingLicensed} />
                                    <FormControl.Feedback />
                                </FormGroup>
                                <Table responsive style={{ alignSelf: 'left' }}>
                                    <thead>
                                        <tr>
                                            <th>Seeking License In</th>
                                            <th> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seekingLicensedStates}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Grid>
                </div>


            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer,
        communicationInfo: state.communicationReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            sendEmail: sendEmail,
            resetEmailStatus: resetEmailStatus,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDocumentsForm);
