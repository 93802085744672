import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
//import { getEmployees, addEmployee, deleteEmployee, getEmployeeNotes, addEmployeeNote, getAllEmployeeNotes } from '../../actions/employeeActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import RegionEditTable from '../../tables/hr_tables/hr_RegionEdittable';


const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%',
        overlfow: 'scroll'
    }
};

class EditRegionModal extends Component {
    constructor(props) {
        super();
        this.state = {
            departmentChecks: [],
            regionName: '',
            firstPass: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
        this.clearOutModal = this.clearOutModal.bind(this);

    }

    handleSubmit(updatedDepartments) {
        // do form stuff
        var regionData = {
            regionName: this.state.regionName,
            departments: updatedDepartments,
            collection: 'regions'
        };

        this.props.upsertHRData(regionData)

        //Clears form on Submit

        this.clearOutModal(true,updatedDepartments)
        this.props.closeModal()
        return;

    }

    clearOutModal(fromSubmit, updatedDepartments) {
 
        if (fromSubmit) {
            this.setState({ departmentChecks: updatedDepartments })
        }
        else
        {
            this.setState({ departmentChecks: this.state.originalChecks })
        }

        this.props.hrInfo.allDepartmentsData.map((val) => {
            var tempName = 'checkbox' + val.Name
            this.setState({
                [tempName]: false
            })
        })
        this.setState({
            dataIsCleared: true
        });

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {

        const target = event.target;
        const name = target.name;
        const value = target.value;

        var checks = [];
        checks = this.state.departmentChecks;
        if (checks.indexOf(value)!= -1) {
            this.remove(checks, value)
            this.setState({
                departmentChecks: checks,
                [name]: false
            });
        }
        else {
            checks.push(value)
            this.setState({
                departmentChecks: checks,
                [name]: true
            });
        }

    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }



    render() {

        //THE CLEARDATA PROPERTY IS TRUE WHEN WE CLOSE
        if (this.props.clearData && !this.state.dataIsCleared) {

            this.clearOutModal(false,[]);
        }
        var rowData = this.state.selectedRow;

        //POPULATES THE REGIONS WHEN SOMETHING CHANGED
        if ((this.props.selectedRow != this.state.selectedRow) || (this.state.originalChecks != this.state.departmentChecks)) {
            rowData = this.props.selectedRow;
            if (rowData) {

                rowData.Department.map((department) => {
                    var tempName = 'checkbox' + department
                    this.setState({
                        [tempName]: true
                    })
                })
                this.setState({
                    regionName: this.props.selectedRow.Region,
                    departmentChecks: this.props.selectedRow.Department,
                    originalChecks: this.props.selectedRow.Department,
                    selectedRow: this.props.selectedRow,
                    dataIsCleared: false

                })
            }
        }


        return (

            <div>
                {rowData &&
                    <Modal
                        isOpen={this.props.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.props.closeModal}
                        style={addModalStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>

                            <div className='try - registration - form'
                                style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                                    <span aria-hidden="false">&times;</span>
                                </button>
                                <h1 style={{ float: 'left' }}>Edit Region</h1>
                                <br />
                                <br />
                                <h3>Region Name</h3>
                                <input className='form-control' id='regionName' type='text' name='regionName' placeholder='Region Name' value={this.state.regionName} onChange={this.handleInputChange} />
                                <br />
                                <RegionEditTable selectedRows={this.props.selectedRow.Department} handleSubmit={this.handleSubmit} />
                                <br />
                                <div id='error'></div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(EditRegionModal);
