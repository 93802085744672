// Import Datepicker
import { DatePickerInput } from 'rc-datepicker';
// Import the default style
import 'rc-datepicker/lib/style.css';
import React, { Component } from 'react';
import { Button, Col, Grid, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import * as FontAwesome from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { resetEmailStatus, sendEmail } from '../../actions/communicationActions';
import { getMultipleLoansFromWarehouse } from '../../actions/pipelineActions';
import { start } from 'pretty-error';

var closedLoansTip = (
    <Tooltip id="tooltip">
        Number of loans closed in the past month.
    </Tooltip>
);
var closedRateTip = (
    <Tooltip id="tooltip">
        Number of loans closed on or before the ECD of the loan.
    </Tooltip>
);
var touchesTip = (
    <Tooltip id="tooltip">
        Average of Submittal and Resubmittal that have pushed through by processors.
    </Tooltip>
);
var communicationTip = (
    <Tooltip id="tooltip">
        Points value depends on user discression; based on quality and effectiveness of communication.
    </Tooltip>
)
var knowledgeTip = (
    <Tooltip id="tooltip">
        Points value depends on user discression; based on ones knowledge of the job.
    </Tooltip>
)
var timeManagementTip = (
    <Tooltip id="tooltip">
        Points value depends on user discression; based on ability to complete work on time.
    </Tooltip>
)
var initiativeTip = (
    <Tooltip id="tooltip">
        Points value depends on user discression; based on tardiness, unexused absenses, ability to go beyond, and resoursefulness.
    </Tooltip>
)

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class LoanProcessorView extends Component {
    constructor(props) {
        super();
        this.state = {
            loanProcessors: [],
            currentProcessor: {
                firstName: '',
                lastName: ''
            },
            tags: [],
            suggestions: [],
            communicationPts: 0,
            knowledgePts: 0,
            timeManagementPts: 0,
            initiativePts: 0,
            testPts: 0,
            startDate: '',
            endDate: '',
            tileOpen:false
        }
        this.sendEmail = this.sendEmail.bind(this);
        this.getValues = this.getValues.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.changeDropDown = this.changeDropDown.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        var moment = require('moment');
        var twix = require('twix');
        var Sugar = require('sugar-date')
        var In30Years = Sugar.Date.create('in 30 years');
        var itr = moment.twix(Date.now(), In30Years).iterate(1, "month");

        var loanProcessors = []
        this.props.employeesInfo.allEmployeesData.map((employee) => {
            //Grab The Current Users ID and see if they are a direct report for a processor
            //If so we will add them to the table. For now we will just use all processors

            if (employee.employmentInfo.title.indexOf("Processor")!== -1) {
                loanProcessors.push(employee);
            }

        })
        this.setState({ loanProcessors: loanProcessors })

    }

    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }
    validEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    sendEmail() {
        var scoreData = this.getValues()
        //Email Checks
        if (this.state.tags.length < 1) {
            confirmAlert({
                title: 'Uh Oh',
                message: 'Please enter an email.',
                buttons: [

                    {
                        label: 'Ok',
                        onClick: () => { }
                    },

                ],
            })
            return;
        }

        var tags = [];
        for (var i = 0; i < this.state.tags.length; i++) {
            if (this.validEmail(this.state.tags[i].id)) {
                tags.push(this.state.tags[i].id)
            }
            else {
                confirmAlert({
                    title: 'Uh Oh',
                    message: '"Invalid Email:" + this.state.tags[i].id.',
                    buttons: [

                        {
                            label: 'Ok',
                            onClick: () => { }
                        },

                    ]
                })
                return;
            }
        }
        //Construct Message
        var scoreData = this.getValues()

        var htmlMessage = [];

        var date = new Date();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();


        var currentDate = month + '/' + day + '/' + year;
        var totalScoreBoardPts = scoreData.totalPoints + this.state.communicationPts + this.state.knowledgePts + this.state.timeManagementPts + this.state.initiativePts;
        htmlMessage.push(
            "<html>",
            "<body>",
            '<h1>' + this.state.currentProcessor.firstName + ' ' + this.state.currentProcessor.lastName + ' Processor Score Card</h1>',
            '<h2> ' + currentDate + ' </h2><br/>',
            '<table style=\"width:75%\"><tr><th><h2>Category</h2></th><th><h2>Value</h2></th><th><h2>Score</h2></th></tr>',
            '<tr><td>Number of Loans Closed</td><td><h2>' + scoreData.numOfClosedLoans + '</h2></td><td><h2>' + scoreData.closedLoansPts + '</h2></td></tr>',
            '<tr><td>On Time Close Rate</td><td><h2>' + scoreData.onTimeClosedRate + '</h2></td><td><h2>' + scoreData.onTimeClosedRatePts + '</h2></td></tr>',
            '<tr><td>Average Number Of Touches</td><td><h2>' + scoreData.numberTouches + '</h2></td><td><h2>' + scoreData.numberTouchesPts + '</h2></td></tr>',
            '<tr><td>Communication</td><td><h2>' + " " + '</h2></td><td><h2>' + this.state.communicationPts + '</h2></td></tr>',
            '<tr><td>Knowledge</td><td><h2>' + " " + '</h2></td><td><h2>' + this.state.knowledgePts + '</h2></td></tr>',
            '<tr><td>Time Management</td><td><h2>' + " " + '</h2></td><td><h2>' + this.state.timeManagementPts + '</h2></td></tr>',
            '<tr><td>Innitiative</td><td><h2>' + " " + '</h2></td><td><h2>' + this.state.initiativePts + '</h2></td></tr>',
            '<tr><td><h1>Total Points</h1></td><td></td><td><h1>' + totalScoreBoardPts + '</h1></td></tr>',
            '</table >')


        htmlMessage.push(
            "</body>",
            "</html>");
        htmlMessage = htmlMessage.join("")


        var messageData = {
            data: {
                subject: "Loan Processor Report For " + this.state.currentProcessor.firstName + ' ' + this.state.currentProcessor.lastName,
                htmlMessage: htmlMessage,
                to: tags,
            }
        };


        //Send the email
        this.props.sendEmail(messageData)
        this.setState({ tags: [] })

    }

    getValues() {
        //Generate Number of Loans Closed for a particular Processor
        var numOfClosedLoans = this.props.pipelineInfo.multipleLoansFromWarehouse.length;
        var closedLoansPts = 0;
        if (numOfClosedLoans < 5) {
            closedLoansPts = numOfClosedLoans * 2;
        }
        else if (numOfClosedLoans <= 8) {
            closedLoansPts = 10;
        }
        else if (numOfClosedLoans <= 12) {
            closedLoansPts = 20;
        }
        else if (numOfClosedLoans <= 15) {
            closedLoansPts = 25;
        } else {
            closedLoansPts = 30;
        }


        //Generate Number of Touches
        var numberTouches = Math.round(this.props.pipelineInfo.currentProcessorTouches);
        var numberTouchesPts = 0;
        if (numberTouches >= 4) {
            numberTouchesPts = 0;
        }
        else if (numberTouches == 3) {
            numberTouchesPts = 5;
        } else {
            numberTouchesPts = 10;
        }



        //Generate On Time Close Rate
        //Determine if ECD < FundsSentDate

        var onTimeClosedLoans = this.props.pipelineInfo.multipleLoansFromWarehouse.map((loan) => {
            var closeDate = Date.parse(loan.saveState[0].loanData.FundsSentDate);
            var ecDate = Date.parse(loan.saveState[0].loanData.EstClosingDate);
            if (ecDate < closeDate) {
                return loan;
            }


        });


        var onTimeClosedRate = (onTimeClosedLoans.length / numOfClosedLoans) * 100
        var onTimeClosedRatePts = 0;
        if (onTimeClosedRate < 50 || isNaN(onTimeClosedRate)) {
            onTimeClosedRatePts = 0;
            onTimeClosedRate = 0
        }
        else if (onTimeClosedRate <= 60) {
            onTimeClosedRatePts = 4;
        }
        else if (onTimeClosedRate <= 70) {
            onTimeClosedRatePts = 8;
        }
        else if (onTimeClosedRate <= 80) {
            onTimeClosedRatePts = 12;
        }
        else if (onTimeClosedRate <= 90) {
            onTimeClosedRatePts = 15;
        } else {
            onTimeClosedRatePts = 20;
        }




        var totalPoints = closedLoansPts + numberTouchesPts + onTimeClosedRatePts;
        return {
            closedLoansPts: closedLoansPts,
            numberTouchesPts: numberTouchesPts,
            onTimeClosedRatePts: onTimeClosedRatePts,
            onTimeClosedRate: onTimeClosedRate,
            numberTouches: numberTouches,
            numOfClosedLoans: numOfClosedLoans,
            totalPoints: totalPoints
        }
    }

    handleDateChange(date) {
        const Sugar = require('sugar-date')
        var yearsAgo5 = Sugar.Date.create('12/31/2012');

        if (date !== 'Invalid date' && yearsAgo5.getTime() < date.getTime()) {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            var finalDate = newMonth + '/' + newDate + '/' + newYear
            //Get the month a month ago from
            var hmm = Date.parse(finalDate) - 2592000000
            var endDate = new Date(hmm)

            this.setState({ startDate: endDate.toISOString(), endDate: date.toISOString(), startDateError: false })
        }
        else if (date !== 'Invalid date' && yearsAgo5.getTime() > date.getTime()) {
            this.before2013Error()
        }
        else {
            this.setState({ startDateError: true })
        }

        if (this.state.tileOpen) {
            this.refreshTile(this.state.currentProcessor,endDate.toISOString(),date.toISOString());
        }

    }

    //added method handlers for event clicks

    changeDropDown(event) {
        var dropDownPts = 0
        if (event.target.value == "0") {
            dropDownPts = 0;
        }
        else if (event.target.value == "5") {
            dropDownPts = 5;
        }
        else if (event.target.value == "10") {
            dropDownPts = 10;
        }
        this.setState({ [event.target.name + "Pts"]: dropDownPts })
    }


    generateTile() {
        var scoreData = this.getValues()
        var totalScoreBoardPts = scoreData.totalPoints + this.state.communicationPts + this.state.knowledgePts + this.state.timeManagementPts + this.state.initiativePts;
        const { tags, suggestions } = this.state;
        return (<div style={{ borderBottomColor: '#000' }}>

            {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus !== 'SUCCEEDED' && <Form success size='large' key='large'>
                <Message icon  >
                    <Icon name='circle notched' loading />

                </Message>
            </Form>}
            {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Grid>
                <Row>
                    <Button style={{ borderColor: 'transparent', alignContents: 'right', marginLeft: '90%' }} onClick={() => this.setState({ tileOpen: false })}>
                        <span style={{ color: 'red', fontSize: 30 }} ><FontAwesome.FaTimesCircle /></span>
                    </Button>
                </Row>
                <Row>
                    <Col xs={6} >
                        <h1 style={{ paddingLeft: '3%' }}>{this.state.currentProcessor.firstName + ' ' + this.state.currentProcessor.lastName}</h1>
                    </Col>
                    <Col xs={2} >
                        <h5>Value</h5>
                    </Col>
                    <Col xs={2} >
                        <h5 style={{ paddingRight: '3%' }}>Points</h5>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={closedLoansTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Number of Loans Closed:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.numOfClosedLoans}</h2>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.closedLoansPts}</h2>
                    </Col>}

                </Row>
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={closedRateTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>On Time Close Rate:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.onTimeClosedRate + '%'}</h2>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.onTimeClosedRatePts}</h2>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={touchesTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Average Number of Touches:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.numberTouches}</h2>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>{scoreData.numberTouchesPts}</h2>
                    </Col>}
                </Row>


                {/* __________________________________ added code here to expand the existing scorecard __________________________________*/}
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={communicationTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Communication:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <select className='communication' name="communication" onChange={this.changeDropDown} value={this.state.communicationPts}>
                            <option value="0">0</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                        </select>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={knowledgeTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Knowledge:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <select className='Knowledge' name='knowledge' onChange={this.changeDropDown} value={this.state.knowledgePts}>
                            <option value="0">0</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                        </select>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={timeManagementTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Time Management:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <select className='Time Management' name='timeManagement' onChange={this.changeDropDown} value={this.state.timeManagementPts}>
                            <option value="0">0</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                        </select>
                    </Col>}
                </Row>
                <Row>
                    <Col xs={6} >
                        <OverlayTrigger style={{ textAlign: 'right' }} placement="left" overlay={initiativeTip}>
                            <h2 style={{ paddingLeft: '3%', fontSize: "1.5em" }}>Initiative:</h2>
                        </OverlayTrigger>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <select className='Initiative' name="initiative" onChange={this.changeDropDown} value={this.state.initiativePts}>
                            <option value="0">0</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                        </select>
                    </Col>}
                </Row>


                <hr />
                <Row>
                    <Col xs={6} >
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em", fontWeight: 'bold' }}>Total Points:</h2>
                    </Col>
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                    </Col>}
                    {this.props.pipelineInfo.getMultipleLoansFromWarehouseStatus === 'SUCCEEDED' && <Col xs={2}>
                        <h2 style={{ paddingLeft: '3%', fontSize: "1.5em", fontWeight: 'bold' }}>{totalScoreBoardPts}</h2>
                    </Col>}
                </Row>
                <hr />
                <h4>Add emails of individuals that you would like to see this scorecard:</h4>
                <Row>
                    <Col xs={6} >
                        <ReactTags
                            style={{ fontSize: "1.5em", width:'90%'}}
                            tags={tags}
                            suggestions={suggestions}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            delimiters={delimiters}
                            inputFieldPosition="top"
                            placeholder="Add Email" />
                    </Col>
                    <Col xs={6} >
                        <Button bsStyle="success" style={{ fontSize: '1.5em' }} onClick={this.sendEmail}>
                            Send Scorecard
                    </Button>
                    </Col>
                </Row>
                {this.props.communicationInfo.sendEmailStatus == 'SUCCEEDED' && <Row>
                    <h1>Email Sent</h1>
                </Row>}
            </Grid>}
        </div>
        )
    }


    openTile(processor) {
        //Clears Email Status in reducer
        this.props.resetEmailStatus()
        var Sugar = require('sugar-date')
        var startDate, endDate = '';
        if (!this.state.startDate && !this.state.endDate) {
            startDate = Sugar.Date.create('1 month ago').toISOString();
            endDate = (new Date()).toISOString()
        }
        else {
            startDate = this.state.startDate
            endDate = this.state.endDate
        }
        //Find all loans from the past month with current Processor as processor
        var payloadForSaga = {
            query: { query: { '$and': [{ 'saveState.loanData.ProcessorEmail': processor.itInfo.encompassEmail }, { 'saveState.loanData.FundsReleasedDate': { '$lte': endDate } }, { 'saveState.loanData.FundsReleasedDate': { '$gte': startDate } }] } },
            currentProcessor: processor._id,
            username: this.props.loginInfo.athenaUsername

        }
        this.props.getMultipleLoansFromWarehouse(payloadForSaga)
        this.setState({ tileOpen: true, currentProcessor: processor })
    }

    refreshTile(processor, startDate, endDate) {
        //Clears Email Status in reducer
        this.props.resetEmailStatus()
        //Find all loans from the past month with current Processor as processor
        var payloadForSaga = {
            query: { query: { '$and': [{ 'saveState.loanData.ProcessorEmail': processor.itInfo.encompassEmail }, { 'saveState.loanData.FundsReleasedDate': { '$lte': endDate } }, { 'saveState.loanData.FundsReleasedDate': { '$gte': startDate } }] } },
            currentProcessor: processor._id,
            username: this.props.loginInfo.athenaUsername
        }
        this.props.getMultipleLoansFromWarehouse(payloadForSaga)
        this.setState({ tileOpen: true, currentProcessor: processor })
    }

    compare(a, b) {
        if (a.lastName < b.lastName) {
            return -1;
        }
        if (a.lastName > b.lastName) {
            return 1;
        }
        return 0;
    }

    generateLoanProcessorList() {
        //Ensure that loanProcessors are found
        if (this.state.loanProcessors) {
            var allProcessors = this.state.loanProcessors.sort(this.compare).map((processor, i) => {

                return (<div key={i}><a onClick={() => this.openTile(processor)}>{processor.firstName + ' ' + processor.lastName} </a><br /></div>)
            })
            return (<div>{allProcessors}</div>)
        }

    }

    render() {
        var processors = this.generateLoanProcessorList()
        var tile = this.generateTile()
        return (<div>
            <Grid>
                <Row>
                    <Col md={4}  xs={10}>
                        <h2 style={{ paddingLeft: '3%' }}>Loan Processors</h2>
                    </Col>
                    <Col md={4}  xs={10}>
                    <Row>
                            <h4>Filter Start Date</h4>
                            <DatePickerInput
                                disabled
                                value={this.state.startDate}
                                className='my-custom-datepicker-component'
                                style={{ width: '20%' }}
                            />
                        </Row>
                    </Col>
                    <Col md={4}  xs={10}>
                    <Row>
                            <h4>Filter End Date</h4>
                            <DatePickerInput
                                onChange={this.handleDateChange}
                                value={this.state.endDate}
                                style={{ width: '20%' }}
                                className='my-custom-datepicker-component'
                            />
                        </Row>
                        
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Row>
                            {processors}
                        </Row>
                    </Col>

                    <Col xs={9}>{this.state.tileOpen && tile}</Col>
                </Row>
            </Grid>
        </div >

        )
    }
}

function mapStateToProps(state) {
    return {
        employeesInfo: state.employeeReducer,
        communicationInfo: state.communicationReducer,
        loginInfo: state.loginReducer,
        pipelineInfo: state.pipelineReducer,


    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            getMultipleLoansFromWarehouse: getMultipleLoansFromWarehouse,
            sendEmail: sendEmail,
            resetEmailStatus: resetEmailStatus
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoanProcessorView);
