
import React, { Component } from 'react';
import { push as Menu } from 'react-burger-menu'
import { logoutUser } from '../../actions/loginActions';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import Footer from './Footer';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import { Collapse } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa'
import Idle from 'react-idle';

var styles = {
  bmCross: {
    background: '#333333'
  },
  bmMenu: {
    background: '#272d33',
    color: '#999999',
    padding: '0em 1.5em 0em ',
    fontSize: '150%',
    width: '100%'
  },
}
class MenuSideBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      reportingCollapse: false,
      hRCollapse: false,
      activityCollapse: false,
      toolCollapse: false
    }
    this.logout = this.logout.bind(this);
    this.toggleReportingCollapse = this.toggleReportingCollapse.bind(this);
    this.toggleHRCollapse = this.toggleHRCollapse.bind(this);
    this.toggleActivitiesCollapse = this.toggleActivitiesCollapse.bind(this);
    this.toggleToolsCollapse = this.toggleToolsCollapse.bind(this);
  }
  logout(e) {
    e.preventDefault();
    console.log('LOGOUT')
    this.props.logoutUser(this.props.loginInfo.currentToken);
    this.props.goToAnotherPage({ pathname: '/', fromLogout: true });

  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false })
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  toggleReportingCollapse() {
    this.setState({ reportingCollapse: !this.state.reportingCollapse })
  }

  toggleHRCollapse() {
    this.setState({ hRCollapse: !this.state.hRCollapse })
  }
  toggleActivitiesCollapse() {
    this.setState({ activityCollapse: !this.state.activityCollapse })
  }

  toggleToolsCollapse() {
    this.setState({ toolCollapse: !this.state.toolCollapse })
  }


  render() {
    return (

      <div>
        <Menu
          customBurgerIcon={false}
          styles={styles} pageWrapId={"page-wrap"} outerContainerId={this.props.outerContainerId}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          width={250}
        >
          <Idle
            // time out after 15 minutes
            timeout={900000}
            onChange={({ idle }) => {
              if (idle && this.props.loginInfo.athenaLoginStatus === "SUCCEEDED") {
                alert("You are being logged out due to inactivity")
                this.props.logoutUser(this.props.loginInfo.currentToken);
                this.props.goToAnotherPage('/')
                //ogout(idle)
              }
            }

            }
          />

          <ul style={{ fontWeight: '500', marginLeft: '-25px', paddingLeft: '7%', paddingTop: '7%' }}>
            <li style={{ borderBottom: '1px solid #67696b', fontSize: '1.5em', fontWeight:'bold', fontFamily:'Raleway' }}>Menu</li>
            {/* {this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/loanList')}>My Pipeline Beta <FontAwesome.FaExclamationCircle /></a></li>}
            {this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/loanList')}>My Pipeline Beta </a></li>} */}
            {/* {Object.keys(this.props.loginInfo.currentUser).length == 0  && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/leaderboard')}>My Profile<FontAwesome.FaExclamationCircle /></a></li>}
            {Object.keys(this.props.loginInfo.currentUser).length > 0  && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/myProfile')}>My Profile</a></li>} */}
            {/* <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/directory')}>Directory</a></li>  */}
            { <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/dashboard')}>Dashboard</a></li>}
            { <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/leaderboard')}>Leaderboard</a></li>}
            {this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/snapshot')}>Weekly Snapshot<FontAwesome.FaExclamationCircle /> </a></li>}
            {this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/snapshot')}>Weekly Snapshot</a></li>}

            {/*Reporting Section*/}
            {this.state.reportingCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleReportingCollapse}>Reporting   <FontAwesome.FaAngleUp /></a></li>}
            {!this.state.reportingCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleReportingCollapse}>Reporting   <FontAwesome.FaAngleDown /></a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/trends')}>Trends Report<FontAwesome.FaExclamationCircle /> </a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/trends')}>Trends Report</a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/bankReport')}>Funding Report <FontAwesome.FaExclamationCircle /></a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/bankReport')}>Funding Report</a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/report')}>Custom Report <FontAwesome.FaExclamationCircle /></a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/report')}>Custom Report</a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/pipelineReport')}>Pipeline Report <FontAwesome.FaExclamationCircle /></a></li>}
            {this.state.reportingCollapse && this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/pipelineReport')}>Pipeline Report</a></li>}
            {this.state.reportingCollapse &&this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/closingReport')}>Closing Reports<FontAwesome.FaExclamationCircle /> </a></li>}
            {this.state.reportingCollapse &&this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/closingReport')}>Closing Reports</a></li>}
            {this.state.reportingCollapse &&this.props.loginInfo.encompassLink !== 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/closingBonus')}>Closing Bonus<FontAwesome.FaExclamationCircle /> </a></li>}
            {this.state.reportingCollapse &&this.props.loginInfo.encompassLink === 'LINKED' && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/closingBonus')}>Closing Bonus</a></li>}
            {this.state.reportingCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/fundings')}>UDM Report</a></li>}
            {/* <li style={{ marginTop: '8px', fontSize: '0.8em' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/issues')}>Issues</a></li> */}

            {/*HR Section*/}
            {/* {this.state.hRCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleHRCollapse}>HR   <FontAwesome.FaAngleUp /></a></li>}
            {!this.state.hRCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleHRCollapse}>HR   <FontAwesome.FaAngleDown /></a></li>}
            {this.state.hRCollapse &&  <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/hrAdminPortal')}>Admin Portal</a></li>}
            {this.state.hRCollapse &&  <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/hrHiringPortal')}>Hiring Portal</a></li>} */}

            {/*Activity Section*/}
            {/* {this.state.activityCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleActivitiesCollapse}>Activities Beta   <FontAwesome.FaAngleUp /></a></li>}
            {!this.state.activityCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleActivitiesCollapse}>Activities Beta  <FontAwesome.FaAngleDown /></a></li>}
            {this.state.activityCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/myActivities')}>My Activities</a></li>}
            {this.state.activityCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/pendingActivities')}>Pending Activities</a></li>} */}
            {/* Generic Section*/}

            {this.state.toolCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleToolsCollapse}>Tools   <FontAwesome.FaAngleUp /></a></li>}
            {!this.state.toolCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.toggleToolsCollapse}>Tools  <FontAwesome.FaAngleDown /></a></li>}
            {this.state.toolCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/mortgageCalculator')}>Calculator</a></li>}
            {this.state.toolCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/exclusionaryListCreator')}>Exclusionary Files</a></li>}
            {this.state.toolCollapse && <li style={{ marginTop: '8px', fontSize: '0.8em', paddingLeft: '7%', fontFamily:'Raleway' }}><a className="menuItem" onClick={() => this.props.goToAnotherPage('/loanProcessorScorecard')}>Loan Processor Scorecard</a></li>}
            <li style={{ marginTop: '8px', fontSize: '0.8em', fontWeight:'bold', fontFamily:'Raleway' }}><a className="menuItem" onClick={this.logout}>Logout</a></li>
          </ul>

        </Menu>
        <main style={{ width: '100%' }}>
          <StickyHeader
            header={<div style={{ backgroundColor: '#343a40', height: '80px' }}>
              <div style={{ padding: '18px' }}>
                <a onClick={() => this.toggleMenu()} style={{ fontSize: '2em', color: '#c9c9c9' }}><i className="fa fa-bars menuButton"></i></a>
                <span style={{ fontSize: '2em', fontWeight: '100', color: '#c9c9c9', marginLeft: '15px' }} >
                  Athena Online
              </span>
              </div>
            </div>
            }
          />
          <br /><br />
          <br /><br />
          {this.props.comp}
          <br /><br /><br /><br />
          <Footer />
        </main>

      </div>

    );
  }


}

function mapStateToProps(state) {
  return {
    loginInfo: state.loginReducer,
    pipelineInfo: state.pipelineReducer
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutUser: logoutUser,
      goToAnotherPage: (params) => push(params)
    }
    , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(MenuSideBar);
