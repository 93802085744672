import React, { Component } from 'react';
import ReactStars from 'react-stars'
import * as FontAwesome from 'react-icons/lib/fa';
import { Col, Row, Form, Grid, Table, Button } from 'react-bootstrap';
import USAMap from "react-usa-map";
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getSurvey } from '../../actions/communicationActions';
import { Icon, Message } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class LicensingView extends Component {
    constructor(props) {
        super();
        this.state = {

        }

    }
    statesCustomConfig() {
var states = {}
        this.props.license.licensedStates.map((state)=>{
            states[state] = {
                fill: '#467444' 
              }
        });
        return states
    };


    render() {
        var licensed
        return (
            <Grid>
                <br />
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontSize: '3em', fontFamily: 'Raleway' }}>{'NMLS#: '+this.props.license.nmls}</span>
                </Row>
                <br />
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontSize: '2.5em', fontFamily: 'Raleway' }}>Licensed States</span>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <USAMap customize={this.statesCustomConfig()} onClick={this.mapHandler} />
                </Row>
            </Grid >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        communicationInfo: state.communicationReducer

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            getSurvey: getSurvey
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(LicensingView);
