import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { numberWithCommas, getTotalDollars } from '../../../helpers/getDollars'
import DetailTable from './DetailTable'
import 'react-table/react-table.css'
import { Grid, Col, Row, ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CSVLink } from 'react-csv';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import windowSize from 'react-window-size';
import * as FontAwesome from 'react-icons/lib/fa';
import { customSortWithMoney, customFilter } from '../../../helpers/tableHelpers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const printTooltip = (
    <Tooltip id="tooltip">
        <strong>Print Table</strong> 
    </Tooltip>
);

const csvTooltip = (
    <Tooltip id="tooltip">
    <strong>Export to CSV</strong> 
    </Tooltip>
);

const closeTooltip = (
    <Tooltip id="tooltip">
        <strong>Close Table</strong> 
    </Tooltip>
);
const date = new Date();
//Components are DUMB and should only display the data that was passed from the container that it is within
class LeaderboardTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: '',
            showTable: false
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.getNewData = this.getNewData.bind(this);
        this.printTable = this.printTable.bind(this);

        this.getLeaders = this.getLeaders.bind(this);
    }

    printTable() {

        var data = [];

        data.push([{ text: 'LO Name', bold: true }, { text: 'Number of Loans', bold: true }, { text: 'Total Cost Value', bold: true }]);


        this.state.leadersForTable.map((loan) => {
            data.push([
                { text: loan.name },
                { text: loan.numOfLoans },
                { text: loan.totalDollars },
            ])
        });
        const { vfs } = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        var month = date.getMonth() + 1;
        var documentDefinition = {
            header: {
                columns: [
                    { text: this.props.reportType + ' (' + month + '/' + date.getDate() + '/' + date.getFullYear() + '@' + date.toLocaleTimeString() + ')', style: 'title' }
                ]
            },
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: ['33%', '33%', '33%'],
                        body: data
                    },
                    style: 'table'
                },

            ],
            styles: {
                table: {
                    alignment: 'center',
                    margin: [0, 20, 0, 0]
                },
                title: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 15, 0, 0]
                }
            }
        };

        pdfMake.createPdf(documentDefinition).print();
    }
    getNewData() {
        var leadersToDisplay = [];
        var names = Object.keys(this.props.leaders);
        if (names.length > 0) {
            //Determine Table Page Size
            if (names.length > 20) {
                this.setState({ tablePageSize: 20 });
            }
            else {
                this.setState({ tablePageSize: names.length });
            }
            //Create Data For Table
            for (var i = 0; i < names.length; i++) {
                var name = names[i];
                var numOfLoans = this.props.leaders[name].count;
                //Get Total Dollar amount for leader
                var totalDollars = this.props.leaders[name].totalDollars;
                var loans = this.props.leaders[name].loans;
                var formattedObject = {
                    name: name,
                    numOfLoans: numOfLoans,
                    totalDollars: totalDollars,
                    loans: loans
                }

                leadersToDisplay.push(formattedObject)
            }
            this.setState({ leadersForTable: leadersToDisplay });
        }

    }

    componentWillMount() {
        this.setState({ currentLeaders: this.props.leaders })
        this.getNewData()

    }

    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }

    getLeaders(loanList) {

        var leaders = {};
        //Creates Object of LOs with their associated Loans
        if (loanList.length > 0) {
            loanList.map((loan) => {
                if (leaders[loan['LoanNumber']]) {
                    leaders[loan['LoanNumber']].push(loan)
                }
                else {
                    leaders[loan['LoanNumber']] = [loan]
                }
            });

        }
        //Create Object that contains name, count of loans, total dollar value of each loan
        var finalLeaderInfo = {}
        Object.keys(leaders).map((leader) => {
            finalLeaderInfo[leader] = {
                totalDollars: getTotalDollars(leaders[leader]),
                date: leaders[leader][0][this.props.field],
                borrowerName: leaders[leader][0]['BorrowerFirstName'] + ' ' + leaders[leader][0]['BorrowerLastName']
            }

        })
        return finalLeaderInfo;
    }
    showDetails(e) {

        var leaderList = this.getLeaders(e.value)
        this.props.showDetailModal(leaderList);
    }


    translateHeader(id) {
        if (id === "CX.TEAMCOLOR") {
            return "Team"
        }
        else if (id === "ORGID") {
            return "Branch"
        }
        else if (id === "317") {
            return "LO"
        }
        else if (id === "2574") {
            return "Underwriter"
        }
        else if (id === "2019") {
            return "Shipper"
        }
        else if (id === "1855") {
            return "Closer"
        }
        else if (id === "362") {
            return "Processor"
        }
        else if (id === "LoanTeamMember.Name.Processor Assistant") {
            return "Processor Assistant"
        }
        else {
            return id;
        }


    }
    getLeaderTotalDollars() {
        var keys = Object.keys(this.props.leaders)
        var totalDollars = 0;

        for (var i = 0; i < keys.length; i++) {

            var dollarAsString = this.props.leaders[keys[i]]['totalDollars'].split('.')[0].replace(/,/g, '').substr(1);
            var dollarAsInt = parseFloat(dollarAsString);
            totalDollars = totalDollars + dollarAsInt;
        }
        return totalDollars;
    }
    getLeaderTotalCount() {
        var keys = Object.keys(this.props.leaders)
        var totalCount = 0;

        for (var i = 0; i < keys.length; i++) {

            totalCount = totalCount + this.props.leaders[keys[i]]['count'];
        }
        return totalCount;
    }


    render() {

        if (this.state.currentLeaders !== this.props.leaders) {
            this.getNewData();
            this.setState({ currentLeaders: this.props.leaders });

        }
        var totalDollars = this.getLeaderTotalDollars();
        var totalCount = this.getLeaderTotalCount();
        const loanColumnHeader = [
            {
                Header: this.translateHeader(this.props.columnHeader),
                accessor: 'name',
            },
            {
                Header: 'Number Of Loans',
                accessor: 'numOfLoans'
            },
            {
                Header: 'Total Cost Value',
                accessor: 'totalDollars',
            },
            {
                Header: '',
                accessor: 'loans',
                filterable: false,
                minWidth: 50,
                Cell: row => (
                    <div align='center'>
                        <button className='btn btn-link' onClick={this.showDetails.bind(this, row)}>
                            <span className="fa fa-info-circle" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                        </button>
                    </div>
                )
            },

        ];
        const loanCSVHeaders = [
            'name',
            'numOfLoans',
            'totalDollars'
        ];

        return (
            <div style={{ marginTop: 25 }}>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <p style={{ fontSize: '2rem' }}>{this.props.reportType}</p>
                        <p>{this.props.dayOfWeek}</p>
                    </Col>
                    <Col style={{ float: 'right' }}>
                        <ButtonGroup>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={printTooltip}>
                                <Button bsStyle="default" onClick={this.printTable}>
                                    <span style={{ color: '#428bca', fontSize: 30 }}><FontAwesome.FaPrint /></span>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={csvTooltip}>
                            <CSVLink data={this.state.leadersForTable} headers={loanCSVHeaders} filename={this.props.reportType + ' Table' + date.getHours() + date.getMinutes() + date.getSeconds() + '_' + date.getMonth() + date.getDate() + date.getFullYear() + '.csv'} className="btn btn-default">
                                <span style={{ color: '#5cb85c', fontSize: 30 }}><FontAwesome.FaTable /></span>
                            </CSVLink>
                            </OverlayTrigger>
                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={closeTooltip}>
                            <Button bsStyle="default" onClick={this.props.hideTableView}>
                                <span style={{ color: '#d9534f', fontSize: 30 }}><FontAwesome.FaClose /></span>
                            </Button>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} >
                        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', fontFamily:'Raleway' }}>Total Number of Loans: {totalCount}</p>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', fontFamily:'Raleway' }}>Total Dollars: {'$' + numberWithCommas(totalDollars) + '.00'}</p>
                    </Col>
                </Row>
                <hr />
                {window.innerWidth > 600 &&
                    <ReactTable
                        id='CurrentTable'
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: this.translateHeader(this.props.columnHeader),
                                accessor: 'name',
                                minWidth: 150
                            },
                            {
                                Header: 'Number Of Loans',
                                accessor: 'numOfLoans'
                            },
                            {
                                Header: 'Total Cost Value',
                                accessor: 'totalDollars',
                            },
                            {
                                Header: '',
                                accessor: 'loans',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showDetails.bind(this, row)}>
                                            <span className="fa fa-info-circle" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },

                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSortWithMoney}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }
                {window.innerWidth <= 600 &&
                    <ReactTable
                        id='CurrentTable'
                        data={this.state.leadersForTable}
                        columns={[
                            {
                                Header: this.translateHeader(this.props.columnHeader),
                                accessor: 'name',
                                minWidth: 150
                            },
                            {
                                Header: '#',
                                accessor: 'numOfLoans',
                                minWidth: 30
                            },
                            {
                                Header: 'Cost',
                                accessor: 'totalDollars',
                            },
                            {
                                Header: '',
                                accessor: 'loans',
                                filterable: false,
                                minWidth: 50,
                                Cell: row => (
                                    <div align='center'>
                                        <button className='btn btn-link' onClick={this.showDetails.bind(this, row)}>
                                            <span className="fa fa-info-circle" style={{ color: '#2746c4', fontSize: 25 }} ></span>
                                        </button>
                                    </div>
                                )
                            },

                        ]}
                        pageSize={this.state.tablePageSize}
                        className="-striped -highlight"
                        sortable={true}
                        filterable={true}
                        previousText='Previous Page'
                        nextText='Next Page'
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        defaultFilterMethod={customFilter}
                        defaultSortMethod={customSortWithMoney}
                        style={{
                            height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        defaultSorted={[
                            {
                                id: "numOfLoans",
                                desc: true
                            }
                        ]}
                    />
                }

                <hr />
                <hr />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(windowSize(LeaderboardTable));