/**
 * This action is used to login the user.
 * @param {object} payload username and password in an object
 */
export const loginUser = (payload) => {
    return {
        type: 'ATHENA_LOGIN',
        payload: payload
    }
};

export const logoutUser = (token) => {

    return {
        type: 'LOGOUT_USER',
        token: token
    }
};

export const initialize = () => {

    return {
        type: 'INITIALIZE'
    }
};

export const introspectToken = (token) => {

    return {
        type: 'INTROSPECT_TOKEN',
        token:token
    }
};

export const encompassLink = (payload) => {
    
        return {
            type: 'ENCOMPASS_LINK',
            payload: payload
        }
    };
    export const encompassLinkReset = (payload) => {
        
            return {
                type: 'ENCOMPASS_LINK_RESET'
            }
        };