import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, DropdownButton, MenuItem, Label, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../../../actions/loginActions';
import { getSingleLoanFromWarehouse } from '../../../../actions/pipelineActions';
import { DatePickerInput } from 'rc-datepicker';

class BorrowerInformationForm extends Component {
    constructor(props) {
        super();
        this.state = {
            currentData: {
                AuthorizedCreditReport: null,
                BorrowerType: '',
                BorrowerFirstName: '',
                BorrowerMiddleName: '',
                BorrowerLastName: '',
                Suffix: '',
                SSN: '',
                DOB: '',
                MaritalStatus: '',
                DateAuthorized: '',
                HomePhone: '',
                WorkPhone: '',
                CellPhone: '',
                HomeEmail: '',
                WorkEmail: '',
                Dependents: '',
                Ages: '',
                YrsOfSchool: '',
                IsCoBorrower: null,
                IncomeAssetsBorrowersSpouse: null,
                IncomeAssetsNotBorrowers: null

            },
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.preFillForm = this.preFillForm.bind(this);
    }

    componentDidMount() {
        //This guid value is set from the LoanList View
        //The LoanCompare View uses that guid to display the information
        this.preFillForm();

    };

    handleChange(e) {
        let change = this.state.currentData
        change[e.target.name] = e.target.value
        this.setState({ currentData: change })
        this.props.getBorrowerInfo(change);
    }

    handleDropdownChange(field, value) {
        let change = this.state.currentData
        change[field] = value
        this.setState({ currentData: change })
        this.props.getBorrowerInfo(change);
    }
    handleDateChange(field, date) {
        if (date !== 'Invalid date') {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            let change = this.state.currentData;
            change[field] = newMonth + '/' + newDate + '/' + newYear;
            this.setState({ currentData: change })
            this.props.getBorrowerInfo(change);
        }
    }

    handleCheckBoxChange(field) {
        let change = this.state.currentData
        change[field] = !change[field]
        this.setState({ currentData: change })
        this.props.getBorrowerInfo(change);
    }


    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    preFillForm() {
        if (this.props.pipelineInfo.singleLoanFromEncompass) {
            //Save the current Data to the changesObject

            var current = {
                BorrowerType: 'Individual', //this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4008'],
                BorrowerFirstName: this.jsUcfirst(this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4000'].toLowerCase()),
                BorrowerMiddleName: this.jsUcfirst(this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4001'].toLowerCase()),
                BorrowerLastName: this.jsUcfirst(this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4002'].toLowerCase()),
                Suffix: this.jsUcfirst(this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4003']),
                SSN: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.65'],
                DOB: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1402'].split(' ')[0],
                MaritalStatus: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.52'],
                DateAuthorized: '1/1/2009', //this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4074'].split(' ')[0],
                HomePhone: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.66'],
                WorkPhone: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.FE0117'],
                CellPhone: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1490'],
                HomeEmail: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1240'],
                WorkEmail: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1178'],
                Dependents: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.53'],
                Ages: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.54'],
                YrsOfSchool: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.39'],
                IsCoBorrower: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.3840'],
                IncomeAssetsBorrowersSpouse: true,//this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.35'],
                IncomeAssetsNotBorrowers: false, //this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.307'],
                AuthorizedCreditReport: true//this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.4073']

            }
            this.setState(
                {
                    currentData: current,
                }
            )
        }

    }
    validateNotNull(name) {
        if (name) {
            const length = name.length;
            if (length > 0) return null
            else return 'error'
        }
        else {
            return 'error'
        }
    }

    validateDate(date) {
        if (isNaN(Date.parse(date))) {
            return 'error'
        } else {
            return 'success'
        }

    }





    render() {
        return (
            <div>
                <Grid>
                    <h1>
                        <Label>Borrower Information</Label>
                    </h1>
                    <br />
                    <form>
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.BorrowerFirstName)}>
                                    <ControlLabel>First Name</ControlLabel>
                                    <FormControl type="text" name='BorrowerFirstName' value={this.state.currentData.BorrowerFirstName} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.BorrowerMiddleName)}>
                                    <ControlLabel>Middle Name</ControlLabel>
                                    <FormControl type="text" name='BorrowerMiddleName' bsSize="sm" value={this.state.currentData.BorrowerMiddleName} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.BorrowerLastName)}>
                                    <ControlLabel>Last Name</ControlLabel>
                                    <FormControl type="text" name='BorrowerLastName' value={this.state.currentData.BorrowerLastName} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Suffix</ControlLabel>
                                    <FormControl type="text" name='Suffix' bsSize="sm" value={this.state.currentData.Suffix} onChange={this.handleChange.bind(this)} style={{ width: '20%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>SSN</ControlLabel>
                                    <FormControl type="text" name='SSN' value={this.state.currentData.SSN} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateDate(this.state.currentData.DOB)}>
                                    <ControlLabel>DOB</ControlLabel>
                                    <DatePickerInput
                                        onChange={this.handleDateChange.bind(this, 'DOB')}
                                        value={this.state.currentData.DOB}
                                        className='my-custom-datepicker-component' />
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Borrower Type*</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='BorrowerType'
                                        title={this.state.currentData.BorrowerType === undefined ? '' : this.state.currentData.BorrowerType}
                                        key={this.state.currentData.BorrowerType}
                                        id='BorrowerTypeDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'BorrowerType')}>
                                        <MenuItem eventKey="Individual">Individual</MenuItem>
                                        <MenuItem eventKey="Co-Signer">Co-Signer</MenuItem>
                                        <MenuItem eventKey="Title Only">Title Only</MenuItem>
                                        <MenuItem eventKey="Non Title Spouse">Non Title Spouse</MenuItem>
                                        <MenuItem eventKey="Trustee">Trustee</MenuItem>
                                        <MenuItem eventKey="Title Only Trustee">Title Only Trustee</MenuItem>
                                        <MenuItem eventKey="Settlor Trustee">Settlor Trustee</MenuItem>
                                        <MenuItem eventKey="Settlor">Settlor</MenuItem>
                                        <MenuItem eventKey="Title Only Settlor Trustee">Title Only Settlor Trustee</MenuItem>
                                        <MenuItem eventKey="Officer">Officer</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.DateAuthorized)}>
                                    <ControlLabel>Date Authorized*</ControlLabel>
                                    <DatePickerInput
                                        onChange={this.handleDateChange.bind(this, 'DateAuthorized')}
                                        value={this.state.currentData.DateAuthorized}
                                        className='my-custom-datepicker-component' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.CellPhone)}>
                                    <ControlLabel>Cell Phone</ControlLabel>
                                    <FormControl type="text" name='CellPhone' bsSize="sm" value={this.state.currentData.CellPhone} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Home Phone</ControlLabel>
                                    <FormControl type="text" name='HomePhone' bsSize="sm" value={this.state.currentData.HomePhone} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText">
                                    <ControlLabel>Work Phone</ControlLabel>
                                    <FormControl type="text" name='WorkPhone' value={this.state.currentData.WorkPhone} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.HomeEmail)}>
                                    <ControlLabel>Home Email</ControlLabel>
                                    <FormControl type="text" name='HomeEmail' bsSize="sm" value={this.state.currentData.HomeEmail} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Work Email</ControlLabel>
                                    <FormControl type="text" name='WorkEmail' bsSize="sm" value={this.state.currentData.WorkEmail} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Marital Status</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='MaritalStatus'
                                        title={this.state.currentData.MaritalStatus === undefined ? '' : this.state.currentData.MaritalStatus}
                                        key={this.state.MaritalStatusKey}
                                        id='MaritalStatusDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'MaritalStatus')}>
                                        <MenuItem eventKey="Married">Married</MenuItem>
                                        <MenuItem eventKey="Unmarried">Unmarried</MenuItem>
                                        <MenuItem eventKey="Seperated">Seperated</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText">
                                    <ControlLabel>Dependents</ControlLabel>
                                    <FormControl type="text" name='Dependents' value={this.state.currentData.Dependents} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText">
                                    <ControlLabel>Ages</ControlLabel>
                                    <FormControl type="text" name='Ages' value={this.state.currentData.Ages} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText">
                                    <ControlLabel>YrsOfSchool</ControlLabel>
                                    <FormControl type="text" name='YrsOfSchool' value={this.state.currentData.YrsOfSchool} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Co-Borrower?</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='IsCoBorrower'
                                        title={this.state.currentData.IsCoBorrower}
                                        key={this.state.currentData.IsCoBorrower}
                                        id='IsCoBorrowerDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'IsCoBorrower')}>
                                        <MenuItem eventKey="Y">Y</MenuItem>
                                        <MenuItem eventKey="N">N</MenuItem>

                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={8}>
                                <Row>
                                    <FormGroup controlId="formBasicText" >
                                        <Checkbox
                                            onClick={this.handleCheckBoxChange.bind(this, 'IncomeAssetsBorrowersSpouse')}>The income/assets of borrower's spouse will not be used to qualify for the loan* </Checkbox>
                                        <FormControl.Feedback></FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup controlId="formBasicText" >
                                        <Checkbox
                                            onClick={this.handleCheckBoxChange.bind(this, 'IncomeAssetsNotBorrower')}>The income/assets of a person other than the borrower will be used to qualify for the loan* </Checkbox>
                                        <FormControl.Feedback></FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                            </Col>
                        </Row>
                       <hr/>
                    </form>
                    {this.state.formStatus === 'SAVING' &&
                        <form>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                                <Message.Header>Just one second</Message.Header>
                            </Message>
                        </form>}
                </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSingleLoanFromWarehouse: getSingleLoanFromWarehouse,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(BorrowerInformationForm);
