//Static Data
//ERROR CODES NEED TO BE GENERATED FOR THIS REDUCER/SAGA

//Initial Data
const initialState = {
    getActivitiesStatus:'NOT_STARTED',
    addSubmissionStatus:'NOT_STARTED',
    errorMessage: '',
    activitiesData: [],
    selectedActivity:{},
    uploadActivityImageStatus:'NOT_STARTED',
    uploadActivityImageErrorMessage:'',
    activityImageUrl:''
  }
  

  
  export default (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_ACTIVITIES':
        return {
          ...state,
          getActivitiesStatus:'NOT_STARTED',
          errorMessage: '',
          activitiesData: {},
          uploadActivityImageStatus:'NOT_STARTED',
          uploadActivityImageErrorMessage:'',
          activityImageUrl:''
  
        }

        case 'GET_ACTIVITIES_STARTED':
        return {
          ...state,
          getActivitiesStatus:'STARTED',
          
        }
        case 'GET_ACTIVITIES_SUCCEEDED':
        return {
          ...state,
          getActivitiesStatus:'SUCCEEDED',
          activitiesData: action.activitiesData
        }
        case 'GET_ACTIVITIES_FAILED':
        return {
          ...state,
          getActivitiesStatus:'FAILED',
          errorMEssage: action.errorMessage
        }
        ////////////////////////////////////////////////////////////////////
        //ADD SUBMISSION
        ////////////////////////////////////////////////////////////////////
        case 'ADD_SUBMISSION_STARTED':
        return {
          ...state,
          addSubmissionStatus:'STARTED',
          
        }
        case 'ADD_SUBMISSION_SUCCEEDED':
        return {
          ...state,
          addSubmissionStatus:'SUCCEEDED',

        }
        case 'ADD_SUBMISSION_FAILED':
        return {
          ...state,
          addSubmissionStatus:'FAILED',
          errorMEssage: action.errorMessage
        }
        ////////////////////////////////////////////////////////////////////
        //UPSERT
        ////////////////////////////////////////////////////////////////////

        case 'UPSERT_ACTIVITY_STARTED':
            return {
                ...state,
                upsertActivityStatus: 'STARTED',
            }
        case 'UPSERT_ACTIVITY_ERRORED':
            return {
                ...state,
                upsertActivityStatus: 'ERRORED',
                upsertActivityErrorMessage: action.upsertActivityErrorMessage
            }
        case 'UPSERT_ACTIVITY_SUCCEEDED':
            return {
                ...state,
                upsertActivityStatus: 'SUCCEEDED',
            }
        ////////////////////////////////////////////////////////////////////
        //DELETE
        ////////////////////////////////////////////////////////////////////
        case 'DELETE_ACTIVITY_STARTED':
            return {
                ...state,
                deleteActivityStatus: 'STARTED',
            }
        case 'DELETE_ACTIVITY_ERRORED':
            return {
                ...state,
                deleteActivityStatus: 'ERRORED',
                deleteActivityErrorMessage: action.deleteActivityErrorMessage
            }
        case 'DELETE_ACTIVITY_SUCCEEDED':
            return {
                ...state,
                deleteActivityStatus: 'SUCCEEDED',
            }
        ////////////////////////////////////////////////////////////////////
        //SET SELECTED APPLICANT
        ////////////////////////////////////////////////////////////////////
        case 'SET_SELECTED_ACTIVITY':
        return {
            ...state,
            selectedActivity:action.payload.selectedActivity,
            selectedActivityStatus: 'SUCCEEDED',
        }
         ////////////////////////////////////////////////////////////////////
        //UPLOAD IMAGE
        ////////////////////////////////////////////////////////////////////

        case 'UPLOAD_ACTIVITY_IMAGE_STARTED':
            return {
                ...state,
                uploadActivityImageStatus: 'STARTED',
                activityImageUrl:''
            }
        case 'UPLOAD_ACTIVITY_IMAGE_ERRORED':
            return {
                ...state,
                uploadActivityImageStatus: 'ERRORED',
                uploadActivityImageErrorMessage: action.uploadActivityImageErrorMessage
            }
        case 'UPLOAD_ACTIVITY_IMAGE_SUCCEEDED':
            return {
                ...state,
                uploadActivityImageStatus: 'SUCCEEDED',
                activityImageUrl:action.url
            }

      default:
        return state
    }
  }
  