
import React, { Component } from 'react';
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import DetailTable from '../../tables/pipeline_tables/DetailTable'
import Modal from 'react-modal';

const detailModalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '400px',
        overlfow: 'scroll'
    }
};


class LeaderTableDetailModal extends Component {
    constructor(props) {
        super();
        this.state = {
            issueDate: '',
            loanNumber: '',
            borrower: '',
            department: '',
            contact: '',
            detailModalStyle: {
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    top: '55%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    minWidth: '400px',
                    overlfow: 'scroll'
                }
            },
            issueNumber: ''
        }

    }


    render() {
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                closeTimeoutMS={500}
                onRequestClose={this.props.closeModal}
                style={this.state.detailModalStyle}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                    <span aria-hidden="false">&times;</span>
                </button>
                <DetailTable details={this.props.details} columnHeader="Loan Number" reportType="Details" hideTableView={this.props.closeModal} />
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LeaderTableDetailModal);


