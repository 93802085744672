import React, { Component } from 'react';
import { Grid, Nav, NavItem, Row, Button } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import * as Octicons from 'react-icons/lib/go';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../actions/loginActions';
import ApplicationForm from './ApplicationForm';
import ITForm from './ITForm';
import EmploymentInfoForm from './EmploymentInfoForm';
import NotesForm from './NotesForm';
import LicensingForm from './LicensingForm';
import { upsertHRData, setSelectedApplicant } from '../../actions/hrActions';
class HiringView extends Component {
    constructor(props) {
        super();
        this.state = {
            hrPage: 'ApplicationInfo',
            gotCompStatus: false,
            formStatus: 'EDITING',
            width: 0,
            height: 0
        }
        this.handleSelect = this.handleSelect.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    handleSelect(hrPage) {
        this.setState({ hrPage: hrPage })
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        //Anytime we go to create a new Applicant we will create an entry in the Colelction
        var upsertData =
        {
            _id: this.props.hrInfo.selectedApplicant._id ? this.props.hrInfo.selectedApplicant._id : this.makeId(),
            collection: 'applicants',
            data: {
                personalInfo: {},
                employmentInfo: {},
                hiringInfo: {
                    hiringStatus:'Not Started'
                },
                licensingInfo: {},
                itInfo: {}
            }
        }
        this.props.upsertHRData(upsertData);
        this.props.setSelectedApplicant({ selectedApplicant: upsertData });

    }

    makeId() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        return (
            <div>
                {(this.props.hrInfo.selectedApplicantStatus === 'SUCCEEDED') &&
                    <Grid>
                        {(!this.props.hrInfo.selectedApplicant.data.personalInfo.firstName || !this.props.hrInfo.selectedApplicant.data.personalInfo.lastName) && <h1> New Applicant</h1>}
                        {(this.props.hrInfo.selectedApplicant.data.personalInfo.firstName  && this.props.hrInfo.selectedApplicant.data.personalInfo.lastName) && <h1>{this.props.hrInfo.selectedApplicant.data.personalInfo.firstName+' '+ this.props.hrInfo.selectedApplicant.data.personalInfo.lastName}</h1>}
                        <Button bsStyle='link' onClick={() => this.props.goToAnotherPage({
                            pathname: '/hrHiringPortal'
                        })}>
                            <span style={{ color: '#5cb85c', fontSize: 20 }} ><Octicons.GoArrowLeft />Back To Hiring List</span>
                        </Button>
                        <br />
                        {this.state.width < 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                                <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center' }}><FontAwesome.FaListUl style={{ fontSize: 30 }} /></NavItem>
                                <NavItem eventKey="EmploymentInfo" style={{ textAlign: 'center' }}><FontAwesome.FaGlobe style={{ fontSize: 30 }} /></NavItem>
                                <NavItem eventKey="Licensing" style={{ textAlign: 'left' }}><Octicons.GoCreditCard style={{ fontSize: 30, color: '#5cb85c' }} /></NavItem>
                                <NavItem eventKey="IT" style={{ textAlign: 'center' }}><Octicons.GoDeviceDesktop style={{ fontSize: 30 }} /></NavItem>
                                <NavItem eventKey="Notes" style={{ textAlign: 'left' }}><Octicons.GoBook style={{ fontSize: 30 }} /></NavItem>


                            </Nav>
                            <hr />
                        </Row>}
                        {this.state.width > 768 && <Row>
                            <Nav bsStyle="tabs" activeKey={this.state.hrPage} onSelect={hrPage => this.handleSelect(hrPage)}>
                                <NavItem eventKey="ApplicationInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaListUl style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Application</Row></NavItem>
                                <NavItem eventKey="EmploymentInfo" style={{ textAlign: 'center', fontSize: 15 }}><Row><FontAwesome.FaGlobe style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Employment Info</Row></NavItem>
                                <NavItem eventKey="Licensing" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoCreditCard style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Licensing</Row></NavItem>
                                <NavItem eventKey="IT" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoDeviceDesktop style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>IT</Row></NavItem>
                                <NavItem eventKey="Notes" style={{ textAlign: 'center', fontSize: 15 }}><Row><Octicons.GoBook style={{ fontSize: 40, paddingRight: '10' }} /></Row><Row>Notes</Row></NavItem>
                            </Nav>
                            <hr />
                        </Row>}
                        <Row>
                            {this.state.hrPage === 'ApplicationInfo' && <ApplicationForm />}
                            {this.state.hrPage === 'EmploymentInfo' && <EmploymentInfoForm />}
                            {this.state.hrPage === 'IT' && <ITForm />}
                            {this.state.hrPage === 'Notes' && <NotesForm />}
                            {this.state.hrPage === 'Licensing' && <LicensingForm />}


                        </Row>

                    </Grid>}

                {(this.props.hrInfo.getAllHRDataStatus === 'NOT_STARTED' || this.props.hrInfo.getAllHRDataStatus === 'STARTED') &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}

            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HiringView);
