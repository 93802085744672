import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import DepartmentAddTable from '../../tables/hr_tables/hr_DepartmentAddTable';

const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%', 
        overlfow: 'scroll' 
    }
};

class AddDepartmentModal extends Component {
    constructor(props) {
        super();
        this.state = {
            locationsChecks: [],
            departmentName: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
    }
    componentDidMount() {

    }
    handleSubmit(event) {

        // do form stuff
        var departmentData = {
            departmentName: this.state.departmentName,
            locations: event,
            collection:'departments'
        };

        // this.props.addIssue(issueData);
        this.props.upsertHRData(departmentData)

        //Clears form on Submit
        this.setState({
            departmentName: '',
            departmentss: []
        });

        this.props.closeModal()
        return;

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var checks = [];
        checks = this.state.locationsChecks;
        if(checks.indexOf(value)!= -1){
            this.remove(checks, value)
        }
        else {
            checks.push(value)
        }
        this.setState({
            locationsChecks: checks
        });
    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    getUniqueCategory(obj){
        if(obj){
            var codes = obj.map((e) => {
                return e.IssueCategory
            })
            return [ ...new Set(codes)]
        }
        else {
            return undefined
        }

    }

    render() {
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                style={addModalStyles}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
            >
                <div>

                    <div className='try-registration-form'
                        style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                        <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float:'right' }} onClick={this.props.closeModal}>
                            <span aria-hidden="false">&times;</span>
                        </button>
                        <h1 style={{float: 'left'}}>Add New Department</h1>
                        
                        <br />
                        <br />
                        
                        <br />

                        <form onSubmit={this.handleSubmit} className='try-form' id='callback'>
            
                            <h3>Department Name</h3>
                            <input className='form-control' id='departmentName' type='text' name='departmentName' placeholder='Department Name' value={this.state.departmentName} onChange={this.handleInputChange} />

                            <br />
                            <h3>Locations:</h3>
                            <DepartmentAddTable  handleSubmit={this.handleSubmit}/>

                            <br />
                          
                            
                        </form>
                        <div id='error'></div>
                    </div>
                </div>
            </Modal>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
       hrInfo:state.hrReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(AddDepartmentModal);
