import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, DropdownButton, MenuItem, Label, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Form, Icon, Message } from 'semantic-ui-react';
import { introspectToken } from '../../../../actions/loginActions';
import { getSingleLoanFromWarehouse } from '../../../../actions/pipelineActions';

class BorrowerInformationForm extends Component {
    constructor(props) {
        super();
        this.state = {
            currentData: {
                Address: '',
                City: '',
                State: '',
                ZipCode: '',
                County: '',
                NumOfUnits: '',
                YrBuilt: '',
                EstimatedValue: '',
                AppraisedValue: '',
                LegalDescription: '',
                FHAPropertyType: '',
                FNMAPropertyType: '',
                PropertyForm: '',
                PropertyReview: ''

            },
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.preFillForm = this.preFillForm.bind(this);
    }

    componentDidMount() {
        //This guid value is set from the LoanList View
        //The LoanCompare View uses that guid to display the information
        this.preFillForm();

    };

    handleChange(e) {
        let change = this.state.currentData
        change[e.target.name] = e.target.value
        this.setState({ currentData: change })
        this.props.getPropertyInfo(change);
    }

    handleDropdownChange(field, value) {
        let change = this.state.currentData
        change[field] = value
        this.setState({ currentData: change })
        this.props.getPropertyInfo(change);
    }
    handleDateChange(field, date) {
        if (date !== 'Invalid date') {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            let change = this.state.currentData;
            change[field] = newMonth + '/' + newDate + '/' + newYear;
            this.setState({ currentData: change })
            this.props.getPropertyInfo(change);
        }
    }
    handleCheckBoxChange(field) {
        let change = this.state.currentData
        change[field] = !change[field]
        this.setState({ currentData: change })
        this.props.getPropertyInfo(change);
    }




    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    preFillForm() {
        if (this.props.pipelineInfo.singleLoanFromEncompass) {
            //Save the current Data to the changesObject
console.log('Status',this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.LOG.MS.CURRENTMILESTONE'])
            var current = {
                Address: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.11'],
                City: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.12'],
                State: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.14'],
                ZipCode: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.15'],
                County: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.13'],
                NumOfUnits: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.16'],
                YrBuilt: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.18'],
                EstimatedValue: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1821'],
                AppraisedValue: '200000.000',//this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.456'],
                LegalDescription: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.17'],
                FHAPropertyType: '1 Unit', //this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1966'],
                FNMAPropertyType: this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1041'],
                PropertyForm: 'FHA URAR',//this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.2356'],
                PropertyReview: 'No Appraisal',//this.props.pipelineInfo.singleLoanFromEncompass[0]['fields']['Fields.1541'],
            }
            this.setState(
                {
                    currentData: current,
                }
            )
        }

    }
    validateNotNull(name) {
        if (name) {
            const length = name.length;
            if (length > 0) return null
            else return 'error'
        }
        else {
            return 'error'
        }
    }

    validateDate(date) {
        if (isNaN(Date.parse(date))) {
            return 'error'
        } else {
            return 'success'
        }

    }

    render() {
        return (
            <div>
                <Grid>
                    <h1>
                        <Label>Property Information</Label>
                    </h1>
                    <br />
                    <form>
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.Address)}>
                                    <ControlLabel>Address</ControlLabel>
                                    <FormControl type="text" name='Address' value={this.state.currentData.Address} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.City)}>
                                    <ControlLabel>City</ControlLabel>
                                    <FormControl type="text" name='City' bsSize="sm" value={this.state.currentData.City} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.State)}>
                                    <ControlLabel>State</ControlLabel>
                                    <FormControl type="text" name='State' value={this.state.currentData.State} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Zip</ControlLabel>
                                    <FormControl type="text" name='ZipCode' bsSize="sm" value={this.state.currentData.ZipCode} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>County</ControlLabel>
                                    <FormControl type="text" name='County' value={this.state.currentData.County} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={4} >
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Num Of Units</ControlLabel>
                                    <FormControl type="text" name='NumOfUnits' value={this.state.currentData.NumOfUnits} onChange={this.handleChange.bind(this)} style={{ width: '50%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>

                            <Col sm={12} md={4}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Year Built</ControlLabel>
                                    <FormControl type="text" name='YrBuilt' value={this.state.currentData.YrBuilt} onChange={this.handleChange.bind(this)} style={{ width: '50%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" validationState={this.validateNotNull(this.state.currentData.EstimatedValue)}>
                                    <ControlLabel>Estimated Value</ControlLabel>
                                    <FormControl type="text" name='EstimatedValue' bsSize="sm" value={this.state.currentData.EstimatedValue} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Appraised Value*</ControlLabel>
                                    <FormControl type="text" name='AppraisedValue' bsSize="sm" value={this.state.currentData.AppraisedValue} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroup controlId="formBasicText">
                                    <ControlLabel>LegalDescription</ControlLabel>
                                    <FormControl type="text" name='LegalDescription' value={this.state.currentData.LegalDescription} onChange={this.handleChange.bind(this)} style={{ width: '100%' }} />
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup controlId="formBasicText" >
                                    <ControlLabel>FHA Property Type*</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='FHAPropertyType'
                                        title={this.state.currentData.FHAPropertyType === undefined ? '' : this.state.currentData.FHAPropertyType}
                                        key={this.state.FHAPropertyType}
                                        id='FHAPropertyTypeDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'FHAPropertyType')}>
                                        <MenuItem eventKey="1 Unit">1 Unit</MenuItem>
                                        <MenuItem eventKey="2 Unit">2 Units</MenuItem>
                                        <MenuItem eventKey="3-4 Units">3-4 Units</MenuItem>
                                        <MenuItem eventKey="Condominium">Condominium</MenuItem>
                                        <MenuItem eventKey="Co-Operative">Co-Operative</MenuItem>
                                        <MenuItem eventKey="Manufactured Housing">Manufactured Housing</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                            <FormGroup controlId="formBasicText" >
                                    <ControlLabel>FNMA Property Type</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='FNMAPropertyType'
                                        title={this.state.currentData.FNMAPropertyType === undefined ? '' : this.state.currentData.FNMAPropertyType}
                                        key={this.state.FNMAPropertyType}
                                        id='FNMAPropertyTypeDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'FNMAPropertyType')}>
                                        <MenuItem eventKey="Attached">Attached</MenuItem>
                                        <MenuItem eventKey="High Rise Condominium">High Rise Condominum</MenuItem>
                                        <MenuItem eventKey="Condominium">Condominium</MenuItem>
                                        <MenuItem eventKey="Co-Operative">Co-Operative</MenuItem>
                                        <MenuItem eventKey="Manufactured Housing">Manufactured Housing</MenuItem>
                                        <MenuItem eventKey="PUD">PUD</MenuItem>
                                        <MenuItem eventKey="Detached Condo">Detached Condo</MenuItem>
                                        <MenuItem eventKey="Mfd Home/Condo/PUD/Co-Op">Mfd Home/Condo/PUD/Co-Op</MenuItem>
                                        <MenuItem eventKey="MH Select">MH Select</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                            <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Property Form*</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='PropertyForm'
                                        title={this.state.currentData.PropertyForm === undefined ? '' : this.state.currentData.PropertyForm}
                                        key={this.state.PropertyForm}
                                        id='PropertyFormDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'PropertyForm')}>
                                        <MenuItem eventKey="FHA URAR">FHA URAR</MenuItem>
                                        <MenuItem eventKey="URAR">URAR</MenuItem>
                                        <MenuItem eventKey="2055 Drive-By">2055 Drive-By</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={3}>
                            <FormGroup controlId="formBasicText" >
                                    <ControlLabel>Property Review*</ControlLabel>
                                    <br />
                                    <DropdownButton
                                        style={{ width: '100%' }}
                                        bsStyle="default"
                                        name='PropertyReview'
                                        title={this.state.currentData.PropertyReview === undefined ? '' : this.state.currentData.PropertyReview}
                                        key={this.state.PropertyReview}
                                        id='PropertyReviewDropDown'
                                        onSelect={this.handleDropdownChange.bind(this, 'PropertyReview')}>
                                        <MenuItem eventKey="Exterior/Interior">Exterior/Interior</MenuItem>
                                        <MenuItem eventKey="Exterior Only">Exterior Only</MenuItem>
                                        <MenuItem eventKey="No Appraisal">No Appraisal</MenuItem>
                                    </DropdownButton>
                                    <FormControl.Feedback></FormControl.Feedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/
                        >
                    </form>
                    {this.state.formStatus === 'SAVING' &&
                        <form>
                            <Message icon  >
                                <Icon name='circle notched' loading />
                                <Message.Header>Just one second</Message.Header>
                            </Message>
                        </form>}
                </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSingleLoanFromWarehouse: getSingleLoanFromWarehouse,
            introspectToken: introspectToken,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(BorrowerInformationForm);
