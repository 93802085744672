import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import * as FontAwesome from 'react-icons/lib/fa';
import { Col, Row, Form, Grid, Table, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { uploadExclusionaryFile } from '../../actions/communicationActions';
import { Icon, Message } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class ExclusionaryView extends Component {
    constructor(props) {
        super();
        this.state = {
            files: {},
            errorList: [],
            resultMsg: '',
            filesToUpload: [],
            numberOfFilesThatHaveBeenAdded: 0,
        }
        this.onDrop = this.onDrop.bind(this)
        this.addFilesToTemplate = this.addFilesToTemplate.bind(this)
        this.writeFile = this.writeFile.bind(this)
        this.generateErrors = this.generateErrors.bind(this)
        this.generatePreviews = this.generatePreviews.bind(this)

    }




    componentDidMount() {
        var moment = require('moment');
        var twix = require('twix');
        var Sugar = require('sugar-date')
        var In30Years = Sugar.Date.create('in 30 years');

        var itr = moment.twix(Date.now(), In30Years).iterate(1, "month");
        var monthMap = {}
        var counter = 1;
        while (itr.hasNext()) {
            var dateInterval = itr.next().toDate()
            var month = dateInterval.getMonth() + 1
            var year = dateInterval.getFullYear()
            var finalDateTime = month + '/' + year
            monthMap[counter] = finalDateTime
            counter++;
        }

        this.setState({ monthMap: monthMap })
    }


    async onDrop(acceptedFiles, rejectedFiles) {
        var that = this;

        that.setState({ loadingFiles: true, numberOfFilesThatHaveBeenAdded: acceptedFiles.length + this.state.numberOfFilesThatHaveBeenAdded, uploadingMessage:'' })

        for (const file of acceptedFiles) {
            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls') || file.name.endsWith('.xlsm')) {
                const reader = new FileReader();
                reader.onload =  () => {
                    var files = that.state.files;
                    var totalFileSize = that.state.totalFileSize
                    totalFileSize = totalFileSize + file.size
                    files[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };
                    that.setState({ files: files, totalFileSize: totalFileSize })
                };
                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');

                reader.readAsArrayBuffer(file)
            }
            else {
                that.fileTypeError()
                that.setState({ numberOfFilesThatHaveBeenAdded: this.state.numberOfFilesThatHaveBeenAdded - 1,loadingFiles: false  })
            }
        }
        that.setState({loadingFiles: false })


    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    generatePreviews() {
        var filesPreview = [];
        if (this.state.files) {
            //All files have been added to the list
            if (Object.keys(this.state.files).length == this.state.numberOfFilesThatHaveBeenAdded && this.state.loadingFiles) {
                this.setState({ loadingFiles: false })
            }
            Object.keys(this.state.files).forEach((element) => {
                filesPreview.push(
                    <tr>
                        <td><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                        <td><b style={{ fontSize: '1.25rem' }}>{this.formatBytes(this.state.files[element].size, 2)}</b></td>
                        <td><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                            <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                        </Button></td>
                    </tr>
                )
            })
        }

        return filesPreview;
    }
    generateErrors() {
        var filesPreview = [];
        if (this.state.errorList) {
            this.state.errorList.forEach((element) => {
                filesPreview.push(
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <b style={{ fontSize: '1.25rem', color: 'red' }}>{element}</b>
                    </Row>
                )
            })
        }

        return filesPreview;
    }
    removePreview(file) {
        var files = this.state.files;
        var totalFileSize = this.state.totalFileSize;
        totalFileSize = totalFileSize - files[file].size
        delete files[file];

        this.setState({ files: files, totalFileSize: totalFileSize, numberOfFilesThatHaveBeenAdded: this.state.numberOfFilesThatHaveBeenAdded - 1 })
    }
    async addFilesToTemplate() {
        var XLSX = require('xlsx')
        var headerdata = ["Watch List Name", "NameID", "Action", "Company Name", "Participant Full Name", "Nickname", "SSN", "WatchlistRole", "Alias name", "Alias Company", "Participant ID", "License Number", "EIN", "Address", "City", "State", "Zip", "Address Type", "Score State", "Identifier", "Phone", "Email", "IP Address", "Notes", "Article Title", "Article URL", "Article Date", "Article Text", "Date/Time Added", "Risk Level (HIGH, MEDIUM, PASS )"]
        //Lets us know we have begun the upload
        await this.setState({ addingFiles: true })
        if (this.state.files) {
            Object.keys(this.state.files).forEach((element) => {

                var fileNameToParse = element.split('.')[0];

                console.log("Processing: " + element);

                var workbook = XLSX.read(this.state.files[element].data, { type: 'base64' });

                //Generate JSON for all supported Worksheets using the XLSX package
                //See the XLSX documentation for the parameters that are being passed into the sheet_to_json method
                //This is gathering all of the data from the unformatted sheet
                var freedomCompanyJson = XLSX.utils.sheet_to_json(workbook.Sheets["Company"], { range: 1 });
                var freedomIndividualJson = XLSX.utils.sheet_to_json(workbook.Sheets["Individual"], { range: 1 });
                var plazaJson = XLSX.utils.sheet_to_json(workbook.Sheets["PHM Exclusionary Master"], { range: 2 });
                var wellsFargoEntitiesJson = XLSX.utils.sheet_to_json(workbook.Sheets["Any Role-Entities List"], { range: 1 });
                var wellsFargoIndividualJson = XLSX.utils.sheet_to_json(workbook.Sheets["Any Role-Individuals List"], { range: 1 });
                var wellsFargoSettlementAgentsJson = XLSX.utils.sheet_to_json(workbook.Sheets["Settlement Agents List"], { range: 13 });
                var ditechCompanyJson = XLSX.utils.sheet_to_json(workbook.Sheets["Companies"], { range: 0 });
                var ditechIndividualJson = XLSX.utils.sheet_to_json(workbook.Sheets["Individuals"], { range: 0 });
                var chaseAppraiserJson = XLSX.utils.sheet_to_json(workbook.Sheets["Appraisers"], { range: 4 });
                var mrcIndividualJson = XLSX.utils.sheet_to_json(workbook.Sheets["Appraiser Exclusionary List"], { range: 9 });
                var mrcCompanieJson = XLSX.utils.sheet_to_json(workbook.Sheets["Companies"], { range: 0 });

                //First checks to ensure that JSON actually contains data
                //Takes JSON and reformats it to the Data Verify Format
                if (freedomCompanyJson.length > 0 || freedomIndividualJson.length > 0) {
                    this.addFreedomFilesToTemplate(headerdata, freedomIndividualJson, freedomCompanyJson, fileNameToParse);
                }
                else if (wellsFargoIndividualJson.length > 0 || wellsFargoEntitiesJson.length > 0 || wellsFargoSettlementAgentsJson > 0) {
                    this.addWellsFargoFilesToTemplate(headerdata, wellsFargoIndividualJson, wellsFargoEntitiesJson, wellsFargoSettlementAgentsJson, fileNameToParse);
                }
                else if (plazaJson.length > 0) {
                    this.addPlazaFilesToTemplate(headerdata, plazaJson, fileNameToParse);
                }
                else if (mrcIndividualJson.length > 0 && mrcCompanieJson.length > 0) {
                    this.addMRCFilesToTemplate(headerdata, mrcIndividualJson, mrcCompanieJson, fileNameToParse);
                }
                else if (ditechCompanyJson.length > 0 && ditechIndividualJson.length > 0) {
                    this.addDitechFilesToTemplate(headerdata, ditechIndividualJson, ditechCompanyJson, fileNameToParse);
                }
                else if (chaseAppraiserJson.length > 0) {
                    this.addChaseFilesToTemplate(headerdata, chaseAppraiserJson, fileNameToParse);
                }
                else {
                    //Error Handling
                    if (element.split('.')[1] !== 'xlsx' && element.split('.')[1] !== 'xlsm' && element.split('.')[1] !== 'xls' && element.split('.')[1] !== 'xlm') {
                        this.state.errorList.push("ERROR: " + element + " is not a valid file type.  File must be .xldx, .xlsm, ,xls, .xlm");
                    }
                    else {
                        this.state.errorList.push("ERROR:" + element + " does not have a valid worksheet name.");
                    }
                }

            })

            if (this.state.errorList.length > 0) {
                var errors = ''
                for (var i = 0; i < this.state.errorList.length; i++) {
                    errors = errors + this.state.errorList[i] + " \n";
                }
                this.setState({ files: {} })

            }
            else {
                this.props.uploadExclusionaryFile({ fileData: this.state.filesToUpload })
                this.setState({ files: {}, filesToUpload: [], uploadingMessage: 'SUCCEEDED',addingFiles: true })
                //Clear out upload files list once complete
              

            }

        }
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type Freedom
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addFreedomFilesToTemplate(headerdata, individualJson, companyJson, fileNameToParse) {
        //Add Column Headers
        var freedomCompanyData = [];
        freedomCompanyData.push(headerdata);
        for (var i = 0; i < companyJson.length; i++) {
            var addedDate = this.formatDate(companyJson[i]["Effective Date Started "]);
            var watchlistItem = ["Freedom  - Entity All Roles", "", "REPLACE", companyJson[i]["Company Name"], "", "", "", companyJson[i]["Role"], "", "", "", companyJson[i]["License Number"], "", companyJson[i]["Address"], companyJson[i]["City"], companyJson[i]["Licensed State "], "", "COMPANY", "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            freedomCompanyData.push(watchlistItem);
        }
        this.writeFile(freedomCompanyData, '/' + fileNameToParse + '-COMPANY-DataVerifyFormat.xls');


        //Add Column Headers
        var freedomIndividualData = [];
        freedomIndividualData.push(headerdata);
        //Add Data Specific for Watchlist Type

        for (var i = 0; i < individualJson.length; i++) {
            var fullName = individualJson[i]["First Name"] + " " + individualJson[i]["Middle Name"] + " " + individualJson[i]["Last Name"];
            var addedDate = this.formatDate(individualJson[i]["Effective Date Started "]);
            var watchlistItem = ["Freedom - Individual All Roles", "", "REPLACE", "", fullName, "", "", individualJson[i]["Role"], "", "", "", individualJson[i]["License Number"], "", individualJson[i]["Address"], individualJson[i]["City"], individualJson[i]["Licensed State "], "", "PARTICIPANT", "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            freedomIndividualData.push(watchlistItem);
        }
        this.writeFile(freedomIndividualData, '/' + fileNameToParse + '-INDIVIDUAL-DataVerifyFormat.xls');
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type Ditech
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addDitechFilesToTemplate(headerdata, individualJson, companyJson, fileNameToParse) {
        //Add Column Headers
        var ditechCompanyData = [];
        ditechCompanyData.push(headerdata);
        for (var i = 0; i < companyJson.length; i++) {
            var addedDate = this.formatDate(companyJson[i]["Date of Inclusion"]);
            var fullAddress = (companyJson[i]["Address Line 2"]) ? companyJson[i]["Address Line 1"] + " " + companyJson[i]["Address Line 2"] : companyJson[i]["Address Line 1"];
            var watchlistItem = ["Ditech  - Entity All Roles", "", "REPLACE", companyJson[i]["Company Name"], "", "", "", "", "", "", "", companyJson[i]["License Number"], "", fullAddress, companyJson[i]["City"], companyJson[i]["State"], companyJson[i]["Zip"], "COMPANY", "", "", "", "", "", companyJson[i]["Comments"], "", "", "", "", addedDate, ""]
            ditechCompanyData.push(watchlistItem);
        }
        this.writeFile(ditechCompanyData, '/' + fileNameToParse + '-COMPANY-DataVerifyFormat.xls');


        //Add Column Headers
        var ditechIndividualData = [];
        ditechIndividualData.push(headerdata);
        //Add Data Specific for Watchlist Type
        for (var i = 0; i < individualJson.length; i++) {
            var fullName = individualJson[i]["Middle Initial or Name"] ? individualJson[i]["First Name"] + " " + individualJson[i]["Middle Initial or Name"] + " " + individualJson[i]["Last Name"] : individualJson[i]["First Name"] + " " + individualJson[i]["Last Name"];
            var addedDate = this.formatDate(individualJson[i]["Date of Inclusion"]);
            var fullAddress = (individualJson[i]["Address Line 2"]) ? individualJson[i]["Address Line 1"] + " " + individualJson[i]["Address Line 2"] : individualJson[i]["Address Line 1"];
            var watchlistItem = ["Ditech - Individual All Roles", "", "REPLACE", "", fullName, "", "", "", "", "", "", individualJson[i]["License Number"], "", fullAddress, individualJson[i]["City"], individualJson[i]["State"], individualJson[i]["Zip"], "PARTICIPANT", "", "", "", "", "", individualJson[i]["Comments"], "", "", "", "", addedDate, ""]
            ditechIndividualData.push(watchlistItem);
        }

        this.writeFile(ditechIndividualData, '/' + fileNameToParse + '-INDIVIDUAL-DataVerifyFormat.xls');
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type MRC
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addMRCFilesToTemplate(headerdata, individualJson, companyJson, fileNameToParse) {

        //Add Column Headers
        var mrcCompanyData = [];
        mrcCompanyData.push(headerdata);
        for (var i = 0; i < companyJson.length; i++) {
            var addedDate = companyJson[i]["Date of Inclusion"];
            var fullAddress = (companyJson[i]["Address Line 2"]) ? companyJson[i]["Address Line 1"] + " " + companyJson[i]["Address Line 2"] : companyJson[i]["Address Line 1"];
            var watchlistItem = ["MRC - Company", "", "REPLACE", companyJson[i]["Company Name"], "", "", "", "", "", "", "", companyJson[i]["License Number"], "", fullAddress, companyJson[i]["City"], companyJson[i]["State"], companyJson[i]["Zip"], "COMPANY", "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            mrcCompanyData.push(watchlistItem);
        }
        this.writeFile(mrcCompanyData, '/' + fileNameToParse + '-COMPANY-DataVerifyFormat.xls');


        //Add Column Headers
        var mrcIndividualData = [];
        mrcIndividualData.push(headerdata);
        //Add Data Specific for Watchlist Type
        for (var i = 0; i < individualJson.length; i++) {
            var fullName = individualJson[i]["First Name"] + " " + individualJson[i]["Last Name "];
            var addedDate = individualJson[i]["Date "];
            var watchlistItem = ["MRC - Appraiser", "", "REPLACE", "", fullName, "", "", "", "", "", "", individualJson[i]["License"], "", "", "", individualJson[i]["State"], "", "PARTICIPANT", "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            mrcIndividualData.push(watchlistItem);
        }

        this.writeFile(mrcIndividualData, '/' + fileNameToParse + '-INDIVIDUAL-DataVerifyFormat.xls');
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type Chase
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addChaseFilesToTemplate(headerdata, chaseJson, fileNameToParse) {
        //Add Column Headers
        var chaseData = [];
        chaseData.push(headerdata);
        //Add Data Specific for Watchlist Type
        for (var i = 0; i < chaseJson.length; i++) {
            var fullName = chaseJson[i]["First"] + " " + chaseJson[i]["Last Name"];
            var addedDate = this.formatDate(chaseJson[i]["Effective Inelgible Date"]);
            var watchlistItem = ["Chase Watch List", "", "REPLACE", "", fullName, "", "", "Appraiser", "", "", "", chaseJson[i]["License Number"], "", chaseJson[i]["Street Address"], chaseJson[i]["City"], chaseJson[i]["State"], chaseJson[i]["Zip"], "PARTICIPANT", "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            chaseData.push(watchlistItem);
        }

        this.writeFile(chaseData, '/' + fileNameToParse + '-DataVerifyFormat.xls');

    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type Wells Fargo
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addWellsFargoFilesToTemplate(headerdata, individualJson, companyJson, settlementJson, fileNameToParse) {
        //Add Column Headers
        var wellsCompanyData = [];
        wellsCompanyData.push(headerdata);
        //Add Data Specific for Watchlist Type
        for (var i = 0; i < companyJson.length; i++) {
            var addedDate = this.formatDate(companyJson[i]["Date of Inclusion"]);
            var fullAddress = (companyJson[i]["Address Line 2"]) ? companyJson[i]["Address Line 1"] + " " + companyJson[i]["Address Line 2"] : companyJson[i]["Address Line 1"];
            var comment = (companyJson[i]["Comments"]) ? 'Comments: ' + companyJson[i]["Comments"] : ''
            var alias = (companyJson[i]["aka/dba"]) ? ' Alias:' + companyJson[i]["aka/dba"] : ''
            var watchlistItem = ["Wells Fargo – Entities All Roles", "", "REPLACE", companyJson[i]["Company Name"], "", "", "", companyJson[i]["License Type"], "", "", "", companyJson[i]["License Number"], "", fullAddress, companyJson[i]["City"], companyJson[i]["State"], companyJson[i]["ZIP"], "COMPANY", companyJson[i]["State"], "", "", "", "", comment + alias, "", "", "", "", addedDate, ""]
            wellsCompanyData.push(watchlistItem);
        }
        this.writeFile(wellsCompanyData, '/' + fileNameToParse + '-ENTITIES-DataVerifyFormat.xls');

        //Add Column Headers
        var wellsIndividualData = [];
        wellsIndividualData.push(headerdata);
        //Add Data Specific for Watchlist Type

        for (var i = 0; i < individualJson.length; i++) {

            var fullName = individualJson[i]["Middle Initial or Name"] ? individualJson[i]["First Name"] + " " + individualJson[i]["Middle Initial or Name"] + " " + individualJson[i]["Last Name"] : individualJson[i]["First Name"] + " " + individualJson[i]["Last Name"];
            var addedDate = this.formatDate(individualJson[i]["Date of Inclusion"]);
            var fullAddress = (individualJson[i]["Address Line 2"]) ? individualJson[i]["Address Line 1"] + " " + individualJson[i]["Address Line 2"] : individualJson[i]["Address Line 1"];
            var comment = (individualJson[i]["Comments"]) ? 'Comments: ' + individualJson[i]["Comments"] : ''
            var alias = (individualJson[i]["aka/dba"]) ? ' Alias:' + individualJson[i]["aka/dba"] : ''
            var watchlistItem = ["Wells Fargo – Individual All Roles", "", "REPLACE", "", fullName, "", "", individualJson[i]["License Type"], "", "", "", individualJson[i]["License Number"], "", fullAddress, individualJson[i]["City"], individualJson[i]["State"], individualJson[i]["ZIP"], "PARTICIPANT", individualJson[i]["State"], "", "", "", "", comment + alias, "", "", "", "", addedDate, ""];
            wellsIndividualData.push(watchlistItem);
        }
        this.writeFile(wellsIndividualData, '/' + fileNameToParse + '-INDIVIDUALS-DataVerifyFormat.xls');


        //Add Column Headers
        var wellsSettlementlData = [];
        wellsSettlementlData.push(headerdata);
        //Add Data Specific for Watchlist Type

        for (var i = 0; i < settlementJson.length; i++) {
            var fullName = settlementJson[i]["Settlement Indicator"] === 'Settlement Agent Employee' ? settlementJson[i]["Company Name"] : '';
            var companyName = settlementJson[i]["Settlement Indicator"] === 'Settlement Agent Company' ? settlementJson[i]["Company Name"] : '';
            var settlerType = settlementJson[i]["Settlement Indicator"] === 'Settlement Agent Employee' ? 'PARTICIPANT' : 'COMPANY';
            var addedDate = this.formatDate(settlementJson[i]["Date Added"]);

            var watchlistItem = ["Wells Fargo – Settlement Agents", "", "REPLACE", companyName, fullName, "", "", "", "", "", "", "", "", settlementJson[i]["Address"], settlementJson[i]["City"], settlementJson[i]["State"], "", settlerType, settlementJson[i]["State"], "", "", "", "", "", "", "", "", "", addedDate, ""];
            wellsSettlementlData.push(watchlistItem);
        }
        this.writeFile(wellsSettlementlData, '/' + fileNameToParse + '-SETTLEMENT-AGENTS-DataVerifyFormat.xls');
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    // Source Watchlist Type Plaza
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    addPlazaFilesToTemplate(headerdata, plazaJson, fileNameToParse) {
        //Add Column Headers
        var plazaData = [];
        plazaData.push(headerdata);
        //Add Data Specific for Watchlist Type

        for (var i = 0; i < plazaJson.length; i++) {
            var fullName = (plazaJson[i]["FirstName"]) ? plazaJson[i]["FirstName"] + " " + plazaJson[i]["Last Name/Company Name"] : '';
            var companyName = (plazaJson[i]["FirstName"]) ? '' : plazaJson[i]["Last Name/Company Name"];
            var addressType = (fullName) ? 'PARTICIPANT' : 'COMPANY';
            var fullAddress = (plazaJson[i]["Address 2"]) ? plazaJson[i]["Address 1"] + " " + plazaJson[i]["Address 2"] : plazaJson[i]["Address 1"];
            var addedDate = this.formatDate(plazaJson[i]["Date added"]);
            var watchlistItem = ["Plaza Watch List", "", "REPLACE", companyName, fullName, "", "", plazaJson[i]["Role"], "", "", plazaJson[i]["ClientID"], plazaJson[i]["LicenseNumber"], "", fullAddress, plazaJson[i]["City"], plazaJson[i]["State"], plazaJson[i]["ZIP"], addressType, "", "", "", "", "", "", "", "", "", "", addedDate, ""]
            plazaData.push(watchlistItem);
        }

        this.writeFile(plazaData, '/' + fileNameToParse + '-DataVerifyFormat.xls');

    }
   /////////////////////////////////////////////////////
    //Write Files to xlsx
    /////////////////////////////////////////////////////
    writeFile(filedata, outFileName) {
        //Add File to Upload List


        var XLSX = require('xlsx');
        var ws_name = "ExclusionList";

        /* set up workbook objects -- some of these will not be required in the future */
        var wb = {}
        wb.Sheets = {};
        wb.Props = {};
        wb.SSF = {};
        wb.SheetNames = [];

        /* create worksheet: */
        var ws = {}
 
        /* the range object is used to keep track of the range of the sheet */
        var range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };

        /* Iterate through each element in the structure */
        for (var R = 0; R != filedata.length; ++R) {
            if (range.e.r < R) range.e.r = R;
            for (var C = 0; C != filedata[R].length; ++C) {
                if (range.e.c < C) range.e.c = C;

                /* create cell object: .v is the actual data */
                var cell = { v: filedata[R][C] };
                if (cell.v == null) continue;

                /* create the correct cell reference */
                var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

                /* determine the cell type */
                if (typeof cell.v === 'number') cell.t = 'n';
                else if (typeof cell.v === 'boolean') cell.t = 'b';
                else cell.t = 's';

                /* add to structure */
                ws[cell_ref] = cell;
            }
        }
        ws['!ref'] = XLSX.utils.encode_range(range);

        /* add worksheet to workbook */
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;

        var wopts = { bookType: 'xls', bookSST: false, type: 'base64' };
        /* write file in base64 encoding*/
        var wbData = XLSX.write(wb, wopts);
        this.state.filesToUpload.push({ name: outFileName, wbData: wbData })
        console.log("File Converted:", outFileName);
    }
    Uint8ToString(u8a) {
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
            c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
        }
        return c.join("");
    }
    /////////////////////////////////////////////////////
    //Formats Data
    /////////////////////////////////////////////////////
    formatDate(date) {

        if (date && !Number.isInteger(date) && date.indexOf('-') != -1) {
            var dateParts = date.split('-');

            if (dateParts.length == 3) {
                return dateParts[0] + "/" + dateParts[1] + "/" + dateParts[2]
            }
            else {
                return date;
            }
        }
        else {
            return date
        }
    }
    fileTypeError = () => {
        confirmAlert({
            title: 'Uh Oh',
            message: 'Only .xls, .xlsm and .xlsx files can be used',
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => { }
                },

            ]
        })
    };
    render() {
        var attachedPreviews = this.generatePreviews();
        if (this.state.addingFiles && (this.props.communicationInfo.uploadExclusionaryFileStatus == 'SUCCEEDED'||this.props.communicationInfo.uploadExclusionaryFileStatus == 'ERRORED')) {
            this.setState({ addingFiles: false })
        }
        return (
            <div>
                {this.state.loadingFiles && <Message icon  >
                    <Icon loading name='certificate' />
                </Message>}
                {this.state.addingFiles && <Message icon  >
                    <Icon loading name='certificate' />
                </Message>}
                <Grid>
                    <h1>Exclusion File Creator</h1>
                    <br />
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ fontSize: 25, fontFamily: 'Raleway' }} >This tool is used to create Exclusionary Lists for Data Verify.  You can add files for Freedom, Wells Fargo, Ditech, Plaza, Mr. Cooper, and Chase.  If there are additional Exclusionary Lists that you would like to include, contact the IT Department.</span>
                    </Row>
                    <br />
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col md={12} sm={12}>
                            <h3>Exclusionary Files:</h3>
                            <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                <div>
                                    <br />
                                    {!this.state.loadingFiles && <a> <h2>Click here or drag in files that will be sent to Data Verify</h2></a>}
                                    {this.state.loadingFiles && <a> <h2>Adding Files...</h2></a>}
                                    <br />
                                </div>
                            </Dropzone>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={{ paddingLeft: 40 }}>
                            <h2><b>Attached Files:</b></h2>
                            <Table responsive style={{ alignSelf: 'left' }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Size</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachedPreviews}
                                </tbody>
                            </Table>

                        </Col>
                    </Row>

                    <br />
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button bsStyle='success' onClick={this.addFilesToTemplate}>
                            <span style={{ color: '#fff', fontSize: 30, fontFamily: 'Raleway' }} >Generate Exclusionary List</span>
                        </Button>
                    </Row>

                    <br />
                    {this.state.uploadingMessage == 'SUCCEEDED' &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}><h1>All Files Downloaded For Your records</h1>    </Row>}
                    
                    {this.props.communicationInfo.uploadExclusionaryFileStatus == 'SUCCEEDED'  &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}><h1>All Files Uploaded to DataVerify</h1>    </Row>}
                    {this.props.communicationInfo.uploadExclusionaryFileStatus == 'ERRORED' &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}><h1>Upload Failed</h1>    </Row>}
                    {this.props.communicationInfo.uploadExclusionaryFileStatus == 'ERRORED' &&
                        <Row style={{ display: 'flex', justifyContent: 'center' }}><h5>{this.props.communicationInfo.uploadExclusionaryFileErrorMessage}}</h5>    </Row>}



                </Grid>

            </div >

        )
    }
}

function mapStateToProps(state) {
    return {
        communicationInfo: state.communicationReducer

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            uploadExclusionaryFile: uploadExclusionaryFile
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(ExclusionaryView);
