import React, { Component } from 'react';
import { Col, Grid, Panel, Row, Table, Button, ControlLabel, FormControl, FormGroup, ButtonGroup } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getAllHRData, upsertHRData, setSelectedApplicant } from '../../actions/hrActions';
import Dropzone from 'react-dropzone';
import TrainingDropdown from '../dropdowns/TrainingDropdown';
class RolesForm extends Component {
    constructor(props) {
        super();
        this.state = {

            openPersonal: false,
            openContact: false,
            openMisc: false,
            files: {},
            filesPreview: [],
            totalFileSize: 0
        }
        this.handlePersonalInfoInputChange = this.handlePersonalInfoInputChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.removePreview = this.removePreview.bind(this);
        this.generatePreviews = this.generatePreviews.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleHiringInfoDropDownChange = this.handleHiringInfoDropDownChange.bind(this);
    }


    componentDidMount() {
        this.setState({
            firstName: this.props.hrInfo.selectedApplicant.data.personalInfo.firstName ? this.props.hrInfo.selectedApplicant.data.personalInfo.firstName : '',
            middleInitial: this.props.hrInfo.selectedApplicant.data.personalInfo.middleInitial ? this.props.hrInfo.selectedApplicant.data.personalInfo.middleInitial : '',
            lastName: this.props.hrInfo.selectedApplicant.data.personalInfo.lastName ? this.props.hrInfo.selectedApplicant.data.personalInfo.lastName : '',
            preferredName: this.props.hrInfo.selectedApplicant.data.personalInfo.preferredName ? this.props.hrInfo.selectedApplicant.data.personalInfo.preferredName : '',
            address1: this.props.hrInfo.selectedApplicant.data.personalInfo.address1 ? this.props.hrInfo.selectedApplicant.data.personalInfo.address1 : '',
            address2: this.props.hrInfo.selectedApplicant.data.personalInfo.address2 ? this.props.hrInfo.selectedApplicant.data.personalInfo.address2 : '',
            city: this.props.hrInfo.selectedApplicant.data.personalInfo.city ? this.props.hrInfo.selectedApplicant.data.personalInfo.city : '',
            state: this.props.hrInfo.selectedApplicant.data.personalInfo.state ? this.props.hrInfo.selectedApplicant.data.personalInfo.state : '',
            zipCode: this.props.hrInfo.selectedApplicant.data.personalInfo.zipCode ? this.props.hrInfo.selectedApplicant.data.personalInfo.zipCode : '',
            homePhone: this.props.hrInfo.selectedApplicant.data.personalInfo.homePhone ? this.props.hrInfo.selectedApplicant.data.personalInfo.homePhone : '',
            cellPhone: this.props.hrInfo.selectedApplicant.data.personalInfo.cellPhone ? this.props.hrInfo.selectedApplicant.data.personalInfo.cellPhone : '',
            email: this.props.hrInfo.selectedApplicant.data.personalInfo.email ? this.props.hrInfo.selectedApplicant.data.personalInfo.email : '',
            trainingInGR: this.props.hrInfo.selectedApplicant.data.hiringInfo.trainingInGR,
            files: this.props.hrInfo.selectedApplicant.data.personalInfo.files ? this.props.hrInfo.selectedApplicant.data.personalInfo.files : {},
            totalFileSize: this.getTotalFileSize(this.props.hrInfo.selectedApplicant.data.personalInfo.files)
        })
    };

    getTotalFileSize(files) {
        var totalFileSize = 0;
        if (files) {
            Object.keys(files).forEach((element) => {
                totalFileSize += files[element].size
            })
        }
        return this.formatBytes(totalFileSize, 2);
    }
    makeId() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

   

    handlePersonalInfoInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.personalInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }

    onKeyPress(event) {
        if (event.which === 13) {
            event.preventDefault();
        }
    }
    generatePreviews() {

        var filesPreview = [];
        if (this.state.files) {
            Object.keys(this.state.files).forEach((element) => {
                filesPreview.push(
                    <tr>
                        <td><b style={{ fontSize: '1.25rem' }}>{element}</b></td>
                        <td><b style={{ fontSize: '1.25rem' }}>{this.formatBytes(this.state.files[element].size, 2)}</b></td>
                        <td><Button bsStyle="link" onClick={() => this.removePreview(element)}>
                            <span style={{ color: '#ff0000', fontSize: 20 }} ><FontAwesome.FaClose /></span>
                        </Button></td>
                    </tr>
                )
            })
        }

        return filesPreview;
    }
    removePreview(file) {
        var files = this.state.files;
        var totalFileSize = this.state.totalFileSize;
        totalFileSize = totalFileSize - files[file].size
        delete files[file];

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.personalInfo.files = files
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.setState({ files: files, totalFileSize: totalFileSize })
    }
    onDrop(acceptedFiles, rejectedFiles) {
        var that = this;
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                var files = that.state.files;
                var totalFileSize = that.state.totalFileSize
                totalFileSize = totalFileSize + file.size

                if (file.size > 11000000) {
                    alert('File size exceeds 10MB.')
                }
                else {

                    files[file.name] = {
                        data: that.arrayBufferToBase64(reader.result),
                        size: file.size
                    };

                    var newObj = that.props.hrInfo.selectedApplicant.data;
                    newObj.personalInfo.files = files
                    var updatedObj = {
                        data: newObj,
                        _id: that.props.hrInfo.selectedApplicant._id,
                        collection: 'applicants'
                    }

                    that.props.upsertHRData(updatedObj)
                    that.props.setSelectedApplicant({ selectedApplicant: updatedObj });
                    that.setState({ files: files, totalFileSize: totalFileSize })
                }
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.readAsArrayBuffer(file)

        });
    }
    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getValidationState(value) {
        if (value) {
            const length = value.length;
            if (length > 0) {
                return 'success';
            }
        }
        else {
            return 'warning'
        }
    }

    handleHiringInfoDropDownChange(name, value) {
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }
    validateForm() {
        var newObj = this.props.hrInfo.selectedApplicant.data;
        //Good to Bad or Bad to Good
        if (((!this.state.firstName || !this.state.lastName || !this.state.address1 || !this.state.city || !this.state.state || !this.state.zipCode || !this.state.cellPhone || !this.state.email) && this.state.applicantInfoCompleted) || ((this.state.firstName && this.state.lastName && this.state.address1 && this.state.city && this.state.state && this.state.zipCode && this.state.cellPhone && this.state.email) && !this.state.applicantInfoCompleted)){
            newObj.hiringInfo.applicantInfoCompleted = !this.state.applicantInfoCompleted
            var updatedObj = {
                data: newObj,
                _id: this.props.hrInfo.selectedApplicant._id,
                collection: 'applicants'
            }
            this.props.upsertHRData(updatedObj)
            this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
            this.setState({ applicantInfoCompleted: !this.state.applicantInfoCompleted })
            return;
        }

    }
    render() {
        this.validateForm();
        var attachedPreviews = this.generatePreviews();
        return (
            <div>
                <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                    <h1 style={{ float: 'left' }}>Applicant Information</h1>
                    <br />
                    <br />
                    <form onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                        <Grid>
                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openPersonal: !this.state.openPersonal, openContact: false, openMisc: false, openDocuments: false })}>Personal Information
                                        &nbsp;{this.state.openPersonal && <FontAwesome.FaArrowCircleDown />}{!this.state.openPersonal && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openPersonal}>
                                <Panel.Collapse>
                                    <Row>
                                        <Col md={5} sm={10}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.firstName)}  >
                                                <ControlLabel>First Name</ControlLabel>
                                                <FormControl type="text" name='firstName' value={this.state.firstName} placeholder="First Name" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={1} sm={2}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>M.I.</ControlLabel>
                                                <FormControl type="text" name='middleInitial' value={this.state.middleInitial} placeholder="M.I." onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.lastName)}  >
                                                <ControlLabel>Last Name</ControlLabel>
                                                <FormControl type="text" name='lastName' value={this.state.lastName} placeholder="Last Name" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText">
                                                <ControlLabel>Preferred Name</ControlLabel>
                                                <FormControl type="text" name='preferredName' value={this.state.preferredName} placeholder="Preferred Name" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup> </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.address1)}  >
                                                <ControlLabel>Address</ControlLabel>
                                                <FormControl type="text" name='address1' value={this.state.address1} placeholder="Address" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText"   >
                                                <ControlLabel>Address Cont'd</ControlLabel>
                                                <FormControl type="text" name='address2' value={this.state.address2} placeholder="Address Cont'd" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.city)}  >
                                                <ControlLabel>City</ControlLabel>
                                                <FormControl type="text" name='city' value={this.state.city} placeholder="City" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.state)}  >
                                                <ControlLabel>State</ControlLabel>
                                                <FormControl type="text" name='state' value={this.state.state} placeholder="State" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.zipCode)}  >
                                                <ControlLabel>Zip</ControlLabel>
                                                <FormControl type="text" name='zipCode' value={this.state.zipCode} placeholder="Zip" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Panel.Collapse>
                            </Panel>

                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openContact: !this.state.openContact, openPersonal: false, openMisc: false, openDocuments: false })}>Contact Information
                                        &nbsp;{this.state.openContact && <FontAwesome.FaArrowCircleDown />}{!this.state.openContact && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openContact}>
                                <Panel.Collapse>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.cellPhone)}  >
                                                <ControlLabel>Cell Phone</ControlLabel>
                                                <FormControl type="text" name='cellPhone' value={this.state.cellPhone} placeholder="Cell Phone" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText">
                                                <ControlLabel>Home Phone</ControlLabel>
                                                <FormControl type="text" name='homePhone' value={this.state.homePhone} placeholder="Home Phone" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.email)}  >
                                                <ControlLabel>Email</ControlLabel>
                                                <FormControl type="text" name='email' value={this.state.email} placeholder="Email" onChange={this.handlePersonalInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Panel.Collapse>
                            </Panel>

                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openMisc: !this.state.openMisc, openPersonal: false, openContact: false, openDocuments: false })}>Misc Information
                                        &nbsp;{this.state.openMisc && <FontAwesome.FaArrowCircleDown />}{!this.state.openMisc && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openMisc}>
                                <Panel.Collapse>
                                    <Row>
                                        <h3>Training Location</h3>
                                        <TrainingDropdown setStatusValue={(value) => {
                                                    if (value)
                                                        this.handleHiringInfoDropDownChange('trainingLocation', value)
                                                    else
                                                        this.handleHiringInfoDropDownChange('trainingLocation', '')
                                                }} value={this.state.trainingLocation} />
                                    </Row>
                                </Panel.Collapse>
                            </Panel>
                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openContact: false, openPosition: false, openMisc: false, openDocuments: !this.state.openDocuments })}>Document Information
                                        &nbsp;{this.state.openDocuments && <FontAwesome.FaArrowCircleDown />}{!this.state.openDocuments && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openDocuments}>
                                <Panel.Collapse>
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <h3>Upload Resume and additional documents</h3>
                                            <Dropzone style={{ paddingLeft: 40, width: '70rem', height: '10rem', borderStyle: 'dashed', borderWidth: '1px' }} onDrop={(files) => this.onDrop(files)}>
                                                <div>
                                                    <br />
                                                    <a> <h2>Click here or Drag in required documents</h2></a>
                                                    <h5>Combined File Size must be less than 10MB</h5>
                                                    <br />
                                                </div>
                                            </Dropzone>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col style={{ paddingLeft: 40 }}>
                                            <h2><b>AttachedFiles:</b></h2>
                                            <Table responsive style={{ alignSelf: 'left' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Size</th>
                                                        <th> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {attachedPreviews}
                                                </tbody>
                                            </Table>

                                        </Col>
                                    </Row>
                                    <br />
                                </Panel.Collapse>
                            </Panel>
                        </Grid>
                        <br />
                    </form>
                    <br />

                    <div id='error'></div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData: getAllHRData,
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(RolesForm);
