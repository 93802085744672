
import React, { Component } from 'react';
import { Button, Col, DropdownButton, Grid, MenuItem, ProgressBar, Row, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip as TooltipRechart } from 'recharts';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../../actions/loginActions';
import { changeLocation } from '../../../actions/globalActions';
import { getPipeline, getPipelineReport, getTrends, resetPipeline, updateProgress } from '../../../actions/pipelineActions';
import LinkAppModal from '../../modals/general_modals/LinkAppModal';

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const graphColors = ['#045eb7', '#2c7883', '#2d973c', '#bd9c18', '#9f5813', '#b0290e', '#bd3776', '#8c29a1']

var pullThroughTooltip = (
    <Tooltip id="tooltip">
        Shows data by percentage of loans that have gone from one milestone to another
    </Tooltip>
);
var countTooltip = (
    <Tooltip id="tooltip">
        Shows data by number of loans
    </Tooltip>
);
var totalTooltip = (
    <Tooltip id="tooltip">
        Shows yearly total breakdown of the data
    </Tooltip>
);
var monthlyComparisonTooltip = (
    <Tooltip id="tooltip">
        Shows monthly total breakdown of the data
    </Tooltip>
);

class TrendsView extends Component {
    constructor(props) {
        super();
        this.state = {
            appStatusText: 'Funded',
            filterID: '1997',
            field: 'FundsReleasedDate',
            graphData: (<h1>Table</h1>),
            graphInterval: 'Year',
            xAxisLabel: 'Year',
            loading: false,
            isLoading: true,
            hasError: false,
            showTable: false,
            showDetailModal: false,
            showLinkAppModal: false,
            isProcessed: false,
            showCount: false,
            chartDisplaying: 'Total',
            chartNotDisplaying: 'Amount',
            amountGraphData: undefined,
            countGraphData: undefined,
            currentLoan: undefined,
            totalGraphDataDisplay: (
                <div style={{ borderColor: 'white', borderWidth: '3px', borderStyle: 'solid' }}>
                    <h1 style={{ textAlign: 'center' }}>Select Values to display on Graph</h1>
                    <br />
                </div>),
            dataForTable: {},
            currentDateRange: '5 Years',
            chartWidth: 0,
            limitMessage: '',
            gotLinkStatus: false,
            valueType: 'Count'


        }
        this.getThePipeline = this.getThePipeline.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.showLinkAppModal = this.showLinkAppModal.bind(this);
        this.hideLinkAppModal = this.hideLinkAppModal.bind(this);
        this.logout = this.logout.bind(this);
        this.compareGraph = this.compareGraph.bind(this)
        this.createSortedData = this.createSortedData.bind(this);

    }

    componentDidMount() {

        if (this.props.loginInfo.encompassLink === 'LINKED') {
            this.setState({ showLinkAppModal: false })
            this.getThePipeline(this.state.filterID, this.state.field, this.state.valueType)
        }
        this.setState({
            chartWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // remove this on unmount 
    }

    updateDimensions(event) {
        this.setState({
            chartWidth: event.target.innerWidth
        })
    }
    logout() {
        this.props.logoutUser();
        this.props.goToAnotherPage('/');
    }

    getThePipeline(filter, field, valueType) {
        var Sugar = require('sugar-date')

        var searchStartData = Sugar.Date.create('5 Years ago');
        var searchStartYear = searchStartData.getFullYear();
        var searchStartDate = '1/1/' + searchStartYear;

        var today = new Date();
        var todayDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear()

        var pipelineParam = {
            startDate: searchStartDate,
            endDate: todayDate,
            filter: filter,
            field: field,
            valueType: valueType
        }
        this.props.getTrends(pipelineParam);
    }
    changeFilter(filter) {
        if (filter === 'PreApp') {
            this.setState({ appStatusText: 'Pre-Application', filterID: '745', field: 'PreApplicationDate' });
            this.getThePipeline('745', 'PreApplicationDate', this.state.valueType)
        }
        else if (filter === 'App') {
            this.setState({ appStatusText: 'Application', filterID: '3142', field: 'ApplicationDate' });
            this.getThePipeline('3142', 'ApplicationDate', this.state.valueType)
        }
        else if (filter === 'Proc') {
            this.setState({ appStatusText: 'Submitted to Processing', filterID: 'LOG.MS.DATE.Processing', field: 'SubmittedToProcessingDate' });

            this.getThePipeline('LOG.MS.DATE.Processing', 'SubmittedToProcessingDate', this.state.valueType)
        }
        else if (filter === 'UW') {
            this.setState({ appStatusText: 'Submitted to Underwriting', filterID: '2298', field: 'UWSubmittedDate' });
            this.getThePipeline('2298', 'UWSubmittedDate', this.state.valueType)
        }
        else if (filter === 'Funded') {
            this.setState({ appStatusText: 'Funded', filterID: '1997', field: 'FundsReleasedDate' });
            this.getThePipeline('1999', 'FundsReleasedDate', this.state.valueType)
        }
        else if (filter === 'Locked') {
            this.setState({ appStatusText: 'Locked', filterID: '2149', field: 'BuySideLockedDate' });
            this.getThePipeline('2149', 'BuySideLockedDate', this.state.valueType)
        }
    }

    getDayOfWeek(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')

        var date = new Date(dateStringParts[2], dateStringParts[0] - 1, dateStringParts[1]);
        var todayDate = new Date();
        if (date.getDate() === todayDate.getDate()) {
            return 'Today'
        }
        else {
            return days[date.getDay()]
        }
    }

    getMonth(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')

        var date = new Date(dateStringParts[2], dateStringParts[0], dateStringParts[1]);
        return months[dateStringParts[0] - 1]
    }
    getYear(dateString) {
        var dayOfWeek = ''
        var dateStringParts = dateString.split('/')
        return dateStringParts[2]
    }
    compareGraph() {
        var lines = [];
        var totalsGraphData = this.props.pipelineInfo.trendData[0];
        var compGraphData = this.props.pipelineInfo.trendData[1];
        var allYears = [];
        //Get Every Year
        var lines = totalsGraphData.map((aYear, index) => {
            var theYear = aYear.date;
            var lineColor = '';
            //Want to start with the same colors for 5 values that are being compared, but after that we will need to dynamically generate colors
            if (graphColors[index]) {

                lineColor = graphColors[index];
            }
            else {
                lineColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            }
            return (<Line type="monotone" dataKey={theYear} stroke={lineColor} key={index} />)
        })
        this.setState({
            totalGraphDataDisplay: (
                <div>
                    <ResponsiveContainer width='90%' aspect={3.0}>
                        <LineChart data={totalsGraphData}
                            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                            <XAxis dataKey="date" tickLine={false} stroke='#282828' />
                            <YAxis tickLine={false} stroke='#282828' />
                            <TooltipRechart animationEasing='ease-out' wrapperStyle={{ backgroundColor: '#d7d7d7' }}
                                itemSorter={(values) => {
                                    //TODO How do you actually Sort
                                }} />
                            <Line type="monotone" dataKey={this.state.valueType === 'Count' ? 'Count' : 'Percentage'} stroke={graphColors[0]} />
                        </LineChart>
                    </ResponsiveContainer>
                    <h3 style={{ textAlign: 'center', color: '#282828' }}>Year</h3>
                </div>),
            compGraphDataDisplay: (
                <div>
                    <ResponsiveContainer width='90%' aspect={3.0}>
                        <LineChart data={compGraphData}
                            margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                            <XAxis dataKey="month" tickLine={false} stroke='#282828' />
                            <YAxis tickLine={false} stroke='#282828' />
                            <TooltipRechart animationEasing='ease-out' wrapperStyle={{ backgroundColor: '#d7d7d7' }}
                                itemSorter={(values) => {
                                    //TODO How do you actually Sort
                                }} />
                            {lines}
                        </LineChart>
                    </ResponsiveContainer>
                    <h3 style={{ textAlign: 'center', color: '#282828' }}>Month</h3>
                </div>)

        })

    }
    createSortedData(year, loans) {

        var sortedData = {}
        sortedData[year] = {
            'Jan': 0,
            'Feb': 0,
            'Mar': 0,
            'Apr': 0,
            'May': 0,
            'Jun': 0,
            'Jul': 0,
            'Aug': 0,
            'Sep': 0,
            'Oct': 0,
            'Nov': 0,
            'Dec': 0
        };

        // console.log(loans)

        loans.map((loan) => {

            var month = loan['saveState'][0]['loanData'][this.state.field].split('-')[1];

            if (month == '01') {
                sortedData[year]['Jan'] = sortedData[year]['Jan'] + 1;
            }
            if (month == '02') {
                sortedData[year]['Feb'] = sortedData[year]['Feb'] + 1;
            }
            if (month == '03') {
                sortedData[year]['Mar'] = sortedData[year]['Mar'] + 1;
            }
            if (month == '04') {
                sortedData[year]['Apr'] = sortedData[year]['Apr'] + 1;
            }
            if (month == '05') {
                sortedData[year]['May'] = sortedData[year]['May'] + 1;
            }
            if (month == '06') {
                sortedData[year]['Jun'] = sortedData[year]['Jun'] + 1;
            }
            if (month == '07') {
                sortedData[year]['Jul'] = sortedData[year]['Jul'] + 1;
            }
            if (month == '08') {
                sortedData[year]['Aug'] = sortedData[year]['Aug'] + 1;
            }
            if (month == '09') {
                sortedData[year]['Sep'] = sortedData[year]['Jan'] + 1;
            }
            if (month == '10') {
                sortedData[year]['Oct'] = sortedData[year]['Oct'] + 1;
            }
            if (month == '11') {
                sortedData[year]['Nov'] = sortedData[year]['Nov'] + 1;
            }
            if (month == '12') {
                sortedData[year]['Dec'] = sortedData[year]['Dec'] + 1;
            }

        })

        return (sortedData)
    }
    handleToggle() {
        if (this.state.chartDisplaying == 'Total') {
            this.setState({ chartDisplaying: 'Comp' })
        }
        else {
            this.setState({ chartDisplaying: 'Total' })
        }
    }
    handleValueToggle() {
        if (this.state.valueType == 'Count') {
            this.setState({ valueType: 'Percent' })
            this.getThePipeline(this.state.filterID, this.state.field, 'Percent')
        }
        else {
            this.setState({ valueType: 'Count' })
            this.getThePipeline(this.state.filterID, this.state.field, 'Count')
        }
    }
    hideLinkAppModal() {
        this.getThePipeline(this.state.filterID, this.state.field);
        this.setState({ showLinkAppModal: false });
    }
    showLinkAppModal(detailList) {
        this.setState({ showLinkAppModal: true })
    }
    linkAppModal() {
        return (<LinkAppModal modalIsOpen={this.state.showLinkAppModal} closeModal={this.hideLinkAppModal} appName='Encompass' />)
    }
    render() {

        //Check Encompass Linkage
        if (this.props.loginInfo.encompassLink === 'LINKED' && !this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: false })
            //If Linked then lets make sure the token hasnt expired
        }
        else if (!this.state.gotLinkStatus && this.props.loginInfo.athenaLoginStatus === 'NOT_STARTED') {
            this.props.goToAnotherPage('/login')
        }
        else if (!this.state.gotLinkStatus) {
            this.setState({ gotLinkStatus: true, showLinkAppModal: true })
        }


        //Sets SnapShot to Today by default and set gotPipeStatus to true
        if (this.props.pipelineInfo.getTrendsStatus === 'SUCCEEDED' && !this.state.gotPipeStatus) {
            //console.log('succeeded', this.props.pipelineInfo.trendData)
            this.compareGraph();
            this.setState({ gotPipeStatus: true, isLoading: false })
            this.props.updateProgress(99);
        }
        //Handle Failed Scenarios
        if (this.props.pipelineInfo.getTrendsStatus === 'ERRORED' && !this.state.gotPipeStatus) {
            alert(this.props.pipelineInfo.getTrendsErrorMessage)
            this.setState({ gotPipeStatus: true });
        }


        //Reset gotPipeStatus to false
        if ((this.props.pipelineInfo.getTrendsStatus === 'STARTED' || this.props.pipelineInfo.getTrendsStatus === 'NOT_STARTED') && this.state.gotPipeStatus) {
            this.setState({ gotPipeStatus: false, isLoading: true, showTable: false })
        }

        var linkAppModal = this.linkAppModal()

        if (window.innerWidth <= 600) {
            //Mobile
            var barRatioSize = 1;
            var graphStyle = {
                flex: '1 0 0px',
                minHeight: 0,
                marginBottom: -50,
                marginTop: 10
            };
        }
        else {
            // Desktop
            var barRatioSize = 0.5;
            var graphStyle = {
                flex: '1 0 0px',
                minHeight: 0,
                marginBottom: -50,
                marginRight: 50,
                marginLeft: 50,
                marginTop: 25
            };
        }
        //console.log('HELP', this.props)
        return (
            <div>
                {linkAppModal}
                {/*MOBILE <Grid style={{ width: "85%", color: '#282828' }}> */}
                <Grid style={{ width: "90%", color: '#282828' }}>
                    <br />
                    {/* <Row style={{ paddingLeft: '15%', paddingRight: '15%' }}> */}
                    <Col>
                        <h3 style={{ fontSize: '2rem', textAlign: 'left' }}>{this.state.appStatusText} Trend </h3>
                        {this.state.chartDisplaying == 'Comp' && <h4 style={{ fontSize: '2rem', textAlign: 'left' }}>Month Comparison by {this.state.valueType} of past 5 years</h4>}
                        {this.state.chartDisplaying == 'Total' && <h4 style={{ fontSize: '2rem', textAlign: 'left' }}>Totals by {this.state.valueType} of past 5 years </h4>}
                        {this.state.appStatusText === "Application" && this.state.valueType === "Percent" && <h4 style={{ fontSize: '1rem', textAlign: 'left' }}>Pre-Application to Application</h4>}
                        {this.state.appStatusText !== "Application" && this.state.valueType === "Percent" && <h4 style={{ fontSize: '1rem', textAlign: 'left' }}>Application to {this.state.appStatusText}</h4>}

                    </Col>
                    {/* </Row> */}
                    {this.state.isLoading &&
                        <div style={{ width: '100%', paddingTop: '50px' }}>
                            <Row>
                                <ProgressBar active striped bsStyle="info" now={this.props.pipelineInfo.progress} label={`${this.props.pipelineInfo.progress}%`} />
                            </Row>
                            {/* <Row>
                                <Col style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    <h3 style={{ textAlign: 'center' }}> {this.props.pipelineInfo.progress + '%'}</h3>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <p style={{ textAlign: 'center' }}>{this.props.pipelineInfo.progressText}
                                    </p>
                                </Col>
                            </Row>
                            <br /><br />
                            <br /><br />
                            <br /><br />
                        </div>}


                    {!this.state.isLoading &&
                        <div>
                            <Row>
                                <Col xs={12} sm={12} md={4}>
                                    <DropdownButton bsSize="large" bsStyle="info" title={this.state.appStatusText} id="dropdown-size-medium" className="reportButton" >
                                        {this.state.appStatusText !== "Pre-Application" && <MenuItem className="menuItem" eventKey={1.1} onSelect={() => this.changeFilter('PreApp')}>Pre-Applications</MenuItem>}
                                        {this.state.appStatusText !== "Application" && <MenuItem eventKey={1.2} onSelect={() => this.changeFilter('App')}>Applications</MenuItem>}
                                        {this.state.appStatusText !== "Submitted to Processing" && <MenuItem eventKey={1.3} onSelect={() => this.changeFilter('Proc')}>Submitted to Processing</MenuItem>}
                                        {this.state.appStatusText !== "Submitted to Underwriting" && <MenuItem eventKey={1.4} onSelect={() => this.changeFilter('UW')}>Submitted to Underwriting</MenuItem>}
                                        {this.state.appStatusText !== "Locked" && <MenuItem eventKey={1.5} onSelect={() => this.changeFilter('Locked')}>Locked</MenuItem>}
                                        {this.state.appStatusText !== "Funded" && <MenuItem eventKey={1.6} onSelect={() => this.changeFilter('Funded')}>Funded</MenuItem>}
                                    </DropdownButton>
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    {this.state.chartDisplaying == 'Total' &&
                                        <ButtonGroup>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={totalTooltip}>
                                                <Button bsStyle="success">Showing Total</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={monthlyComparisonTooltip}>
                                                <Button onClick={() => this.handleToggle()}>Show Comparison</Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    }
                                    {this.state.chartDisplaying == 'Comp' &&
                                        <ButtonGroup>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={totalTooltip}>
                                                <Button onClick={() => this.handleToggle()}>Show Total</Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={monthlyComparisonTooltip}>
                                                <Button bsStyle="success">Showing Comparison</Button>
                                            </OverlayTrigger>
                                        </ButtonGroup>
                                    }
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                        {this.state.valueType === 'Count' &&
                                            <ButtonGroup>
                                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={countTooltip}>
                                                    <Button bsStyle="warning">Showing Count</Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={pullThroughTooltip}>
                                                    <Button onClick={() => this.handleValueToggle()}>Show Percentage</Button>
                                                </OverlayTrigger>
                                            </ButtonGroup>
                                        }
                                        {this.state.valueType === 'Percent' &&
                                            <ButtonGroup>
                                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={countTooltip}>
                                                    <Button onClick={() => this.handleValueToggle()}>Show Count</Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger style={{ textAlign: 'right' }} placement="top" overlay={pullThroughTooltip}>
                                                    <Button bsStyle="warning">Showing Percentage</Button>
                                                </OverlayTrigger>
                                            </ButtonGroup>
                                        }
                                </Col>
                            </Row>
                            <br />
                            {!this.state.isLoading && <div>
                                <Row style={{ flex: '1 0 0px', minHeight: 0, marginBottom: 0 }}>
                                    {this.state.chartDisplaying == 'Total' && <div>
                                        {this.state.totalGraphDataDisplay}
                                    </div>
                                    }
                                    {this.state.chartDisplaying == 'Comp' && <div>
                                        {this.state.compGraphDataDisplay}
                                    </div>
                                    }
                                </Row>
                                <br />
                                <br />
                            </div>
                            }

                            <br />
                            <Row >
                                <Col>
                                    <h6 style={{ textAlign: 'center' }}>{this.state.limitMessage}</h6>
                                </Col>
                            </Row>
                        </div>}



                    {this.state.showTable && this.state.tableView}
                    <br />
                </Grid>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        encompassInfo: state.encompassReducer,
        pipelineInfo: state.pipelineReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeLocation: changeLocation,
            getPipeline: getPipeline,
            resetPipeline: resetPipeline,
            updateProgress: updateProgress,
            logoutUser: logoutUser,
            getPipelineReport: getPipelineReport,
            getTrends: getTrends,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(TrendsView);
