import React, { Component } from 'react';
import ReactStars from 'react-stars'
import * as FontAwesome from 'react-icons/lib/fa';
import { Col, Row, Nav, NavItem, Grid, Table, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getSurvey } from '../../actions/communicationActions';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SurveyView from './SurveyView';
import LicensingView from './LicensingView';

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 3
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '100%',
        height: '80%',
        overlfow: 'scroll'
    }
};

class ProfileCardView extends Component {
    constructor(props) {
        super();
        this.state = {
            profilePage: 'SurveyInfo',
            width: 0,
            height: 0
        }

    }


    getAddressFromId(id) {
        if (this.props.employmentInfo.allLocationData) {
            for (var i = 0; i < this.props.employmentInfo.allLocationData.length; i++) {
                var location = this.props.employmentInfo.allLocationData[i]
                if (location._id == id) {
                    if(location.address2)
                    {
                        return {line1:location.address1 + ' ' + location.address2, line2: location.city + ', ' + location.state+ ' ' + location.zip}
                       
                    }
                    else
                    {
                        return {line1:location.address1,line2: location.city + ', ' + location.state+ ' ' + location.zip}
                    }
                    break;
                }
            }
            return 'HELP'
        }
        else {
            return 'No Location on File'
        }
    }

    formatPhone(num) {
        var newNum = num.replace('(', '').replace(')', '').replace(/-/g, '').replace(' ', '')
        return newNum
    }


    render() {
        var address = this.getAddressFromId(this.props.user.employmentInfo.location)
        return (
            <Grid>
                <br />
                <Row style={{ textAlign: 'center' }}>
                    <Col md={3}>
                        <img style={{ width: '12em', height: '12em' }} src={this.props.user.employmentInfo.picture} alt="" />
                    </Col>
                    <Col md={3}>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontSize: '2.5em', fontWeight: 'bold', fontFamily: 'Raleway' }}>{this.props.user.firstName + ' ' + this.props.user.lastName}</span>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontSize: '1.5em', fontWeight: 'bold', fontFamily: 'Raleway' }}>{this.props.user.employmentInfo.title}</span>
                        </Row>
                        {!this.props.showStars && <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <ReactStars count={5} size={40} value={this.props.average.toFixed(2)} color2={'#ffd700'} edit={false} />
                        </Row>}
                        {!this.props.showStars &&<Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ fontSize: '1.5em', fontWeight: 'bold', fontFamily: 'Raleway' }}>{this.props.average.toFixed(2) + ' / 5'}</span>
                        </Row>}
                    </Col>
                    <Col md={5} xsOffset={1} xs={11}>
               
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontWeight: 'bold', fontFamily: 'Raleway' }}>Department: </span>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>{this.props.user.employmentInfo.departmentName}</span>
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontWeight: 'bold', fontFamily: 'Raleway' }}>Region: </span>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>{this.props.user.employmentInfo.region}  </span>
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            {this.props.user.employmentInfo.cellPhone && <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>Cell: <a href={"tel:" + this.formatPhone(this.props.user.employmentInfo.cellPhone)}>{this.props.user.employmentInfo.cellPhone}</a></span>}
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>Work: <a href={"tel:" + this.formatPhone(this.props.user.employmentInfo.workPhone)}>{this.props.user.employmentInfo.workPhone}</a></span>
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}><a href={"mailto:" + this.props.user.itInfo.encompassEmail}>{this.props.user.itInfo.encompassEmail}</a></span>
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>{address.line1}</span>
                        </Row>
                        <Row style={{ textAlign: 'left' }}>
                            <span style={{ fontSize: '1.2em', fontFamily: 'Raleway' }}>{address.line2}</span>
                        </Row>
                    </Col>
                </Row>
            </Grid >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        communicationInfo: state.communicationReducer,
        employmentInfo: state.employeeReducer

    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            getSurvey: getSurvey
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardView);
