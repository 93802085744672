//STATUS- NOT_STARTED, STARTED, FAILED, ERRORED,SUCCEEDED, EXPIRED


//Initial Data
const initialState = {
  athenaLoginStatus: 'NOT_STARTED',
  athenaLoginErrorMessage: '',
  athenaUsername: '',
  athenaPassword: '',
  athenaLinkedToEncompass: false,
  athenaGroups: [],
  encompassLink: 'NOT_LINKED',
  encompassLinkStatus: 'NOT_STARTED',
  encompassLinkErrorMessage: '',
  encompassTokenStatus: '',
  encompassUsername: '',
  encompassPassword: '',
  currentToken: '',
  tokenStatus: '',
  errorMessage: '',
  adUserData: {},
  currentUser:{},

}


export default (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LOCATION':
      return {
        ...state,
        location: action.location
      }

    case 'ENCOMPASS_TOKEN_EXPIRED':
      return {
        ...state,
        encompassTokenStatus: 'EXPIRED'

      }
    case 'RESET_ATHENA_LOGIN':
      return {
        ...state,
        athenaLoginStatus: 'NOT_STARTED',
        athenaLoginErrorMessage: '',
        athenaUsername: '',
        currentUser:{},
        athenaPassword: '',
        athenaLinkedToEncompass: false,
        athenaGroups: [],
        errorMessage: '',

      }
    case 'RESET_ENCOMPASS_LOGIN':
      return {
        ...state,
        encompassLoginStatus: 'NOT_STARTED',
        encompassLinkStatus: 'NOT_STARTED',
        encompassUsername: '',
        currentUser:{},
        encompassPassword: '',
        currentToken: '',
        tokenStatus: '',
        errorMessage: '',
      }
    ////////////////////////////////
    //ATHENA AUTHORIZATION SCENARIOS
    ////////////////////////////////
    case 'ATHENA_AUTH_STARTED':
      return {
        ...state,
        athenaLoginStatus: 'STARTED'
      }
    case 'INVALID_LOGIN_CREDENTIALS':
      return {
        ...state,
        athenaLoginStatus: 'ERRORED',
        athenaLoginErrorMessage: action.athenaLoginErrorMessage
      }
    case 'AD_SERVER_ERRORED':
      return {
        ...state,
        athenaLoginStatus: 'ERRORED',
        athenaLoginErrorMessage: action.athenaLoginErrorMessage
      }
    case 'ATHENA_GET_USER_DB_CALL_ERRORED':
      return {
        ...state,
        athenaLoginStatus: 'ERRORED',
        athenaLoginErrorMessage: action.athenaLoginErrorMessage
      }
    case 'ATHENA_AUTH_SUCCEEDED':
      return {
        ...state,
        athenaLoginStatus: 'SUCCEEDED',
        athenaUsername: action.athenaUsername,
        athenaGroups: action.athenaGroups,
        adUserData: action.adUserData,
        currentUser:action.currentUser
      }
      case 'UPDATE_CURRENT_USER':
      return {
        ...state,
        currentUser:action.currentUser
      }

    ////////////////////////////////
    //Used To Set link status during login
    ////////////////////////////////
    case 'IS_ENCOMPASS_LINKED_TRUE':
      return {
        ...state,
        encompassLink: 'LINKED'
      }
    case 'IS_ENCOMPASS_LINKED_FALSE':
      return {
        ...state,
        encompassLink: 'NOT_LINKED'
      }
    ////////////////////////////////
    //ENCOMPASS LINK SCENARIOS
    ////////////////////////////////
    case 'ENCOMPASS_LINK_STARTED':
      return {
        ...state,
        encompassLinkStatus: 'STARTED'
      }
    case 'ATHENA_LNK_USER_DB_CALL_ERRORED':
      return {
        ...state,
        encompassLinkStatus: 'ERRORED',
        encompassLinkErrorMessage: action.encompassLinkErrorMessage
      }
    case 'ENCOMPASS_LINK_FAILED':
      return {
        ...state,
        encompassLinkStatus: 'FAILED',
        encompassLinkErrorMessage: action.encompassLinkErrorMessage
      }
    case 'ENCOMPASS_LINK_SUCCEEDED':
      return {
        ...state,
        encompassLinkStatus: 'SUCCEEDED',
        encompassLink: 'LINKED',
        currentToken: action.currentToken
      }
    case 'ENCOMPASS_LINK_RESET':
      return {
        ...state,
        encompassLink: 'NOT_LINKED',
        encompassLinkStatus: 'NOT_STARTED',
      }

    default:
      return state
  }
}
