import React, { Component } from 'react';
import { Button, ButtonGroup, Grid, Row, Col, Checkbox } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getAllHRData, setSelectedApplicant, upsertHRData } from '../../actions/hrActions';
import { createTicket, getTicket } from '../../actions/communicationActions';
import { Form, Icon, Message } from 'semantic-ui-react';

class ITForm extends Component {
    constructor(props) {
        super();
        this.state = {
            iTCredentials: [],
            emailDistroGroups: [],
            gotTicketStatus: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkCredential = this.checkCredential.bind(this);
        this.checkEmailDistroGroup = this.checkEmailDistroGroup.bind(this);
        this.handleEquipmentRequestedToggle = this.handleEquipmentRequestedToggle.bind(this);
        this.generateCredentialSection = this.generateCredentialSection.bind(this)
        this.generateEmailDistroGroupSection = this.generateEmailDistroGroupSection.bind(this)
    }

    componentDidMount() {
        if (this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo && Object.keys(this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo).length > 0) {
            var applicantObj = {
                data: this.props.hrInfo.selectedApplicant.data,
                _id: this.props.hrInfo.selectedApplicant._id,
                collection: 'applicants'
            }
            var getTicketData = {
                trackingId: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.trackingId,
                applicantData: applicantObj
            }
            this.props.getTicket(getTicketData);
        }

        //skip if employment info from the selectedApplicant is empty
        if (Object.keys(this.props.hrInfo.selectedApplicant.data.hiringInfo).length > 0 && this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials) {

            //Checks all of ther boxes
            this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials.map((credential) => {
                this.setState({ [credential]: true })
            })
            this.props.hrInfo.selectedApplicant.data.itInfo.emailDistroGroups.map((emailDistroGroup) => {
                this.setState({ [emailDistroGroup]: true })
            })
            this.setState({
                iTCredentials: this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials,
                equipmentRequested: this.props.hrInfo.selectedApplicant.data.hiringInfo.equipmentRequested,
            })
        }
        else {
            this.setState({
                iTCredentials: [],
                equipmentRequested: false,
            })
        }
    }


    handleSubmit(event) {
        event.preventDefault();

        var userName = this.props.hrInfo.selectedApplicant.data.personalInfo.firstName + ' ' + this.props.hrInfo.selectedApplicant.data.personalInfo.lastName;
        var directReportEmail = '';
        var directReportName = '';
        for (var j = 0; j < this.props.hrInfo.allEmployeesData.length; j++) {
            if (this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport == this.props.hrInfo.allEmployeesData[j]._id) {
                directReportEmail = this.props.hrInfo.allEmployeesData[j].itInfo.aliasEmails[0];
                directReportName = this.props.hrInfo.allEmployeesData[j].firstName + ' ' + this.props.hrInfo.allEmployeesData[j].lastName;
                break;
            }
        }
        var credsNeeded = '<ul>'
        this.props.hrInfo.selectedApplicant.data.hiringInfo.iTCredentials.map((cred) => {
            var lineItem = '<li>' + cred + '</li>';
            credsNeeded += lineItem
        })
        credsNeeded += '</ul>';

        var distroList = '<ul>'
        this.props.hrInfo.selectedApplicant.data.itInfo.emailDistroGroups.map((distro) => {
            var lineItem = '<li>' + distro + '</li>';
            distroList += lineItem
        })
        distroList += '</ul>';

        var equiment = this.state.equipmentRequested ? 'Equipment and Setup Requested' : 'No equipment needed, but please reach out for setup';
        var ticketMessage = '<p><b>Credentials: </b></p>' + credsNeeded + '<br/><p><b>Distribution List: </b></p>' + distroList + '<br/><p><b>Equipment: </b></p>' + equiment + '<br/><p>Thank you,</p>';

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.iTInfoCompleted = true
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        var createTicketData = {
            ticketData: {
                "name": directReportName,
                "email": 'dhudson@vandykmortgage.com',//directReportEmail,
                "category": 42,
                "priority": 2,
                "subject": "New Employee Setup: " + userName,
                "message": ticketMessage,
                "html": true
            },
            applicantData: updatedObj
        }

        //this will upsert and reset applicant
        this.props.createTicket(createTicketData)
    }


    checkCredential(e) {
        var iTCredentials = this.state.iTCredentials;
        var index = iTCredentials.indexOf(e.target.name);
        if (index === -1) {
            iTCredentials.push(e.target.name)
        }
        else {
            iTCredentials.splice(index, 1);
        }
        this.setState({ iTCredentials: iTCredentials })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.iTCredentials = iTCredentials;
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

        //For The Actual Check Mark
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        });

    }
    checkEmailDistroGroup(e) {
        var emailDistroGroups = this.state.emailDistroGroups;
        var index = emailDistroGroups.indexOf(e.target.name);
        if (index === -1) {
            emailDistroGroups.push(e.target.name)
        }
        else {
            emailDistroGroups.splice(index, 1);
        }
        this.setState({ emailDistroGroups: emailDistroGroups })

        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.itInfo.emailDistroGroups = emailDistroGroups;
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

        //For The Actual Check Mark
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        });
    }

    onKeyPress(event) {
        if (event.which === 13) {
            event.preventDefault();
        }
    }

    generateCredentialSection() {
        var credCols = this.props.hrInfo.allCredentialsData.map((credential,i) => {
            var cred = credential.Credential;
            return (<Col md={4} sm={12}>
                <Checkbox name={cred} key={'credCheckbox'+cred} checked={this.state[cred]} onChange={this.checkCredential}><h3>{cred}</h3></Checkbox>
            </Col>)
        })

        var resetAt3 = 0;
        var credRows = { 0: [] }
        var currentRow = 0;
        for (var i = 0; i < credCols.length; i++) {

            if (resetAt3 < 3) {
                credRows[currentRow].push(credCols[i])
                resetAt3++;
            }
            else {
                resetAt3 = 0;
                currentRow += 1;
                credRows[currentRow] = [credCols[i]]
                resetAt3++;

            }

        }
        //Ensuring that only 3 are on a line at a time
        return Object.keys(credRows).map((row) => {
            return (
                <Row>
                    {credRows[row]}
                </Row>
            )
        })
    }

    generateEmailDistroGroupSection() {
        var groupCols = this.props.hrInfo.allEmailDistroGroupsData.map((group) => {
            var group = group.DistroGroup;

            return (<Col md={4} sm={12}>
                <Checkbox name={group} checked={this.state[group]} onChange={this.checkEmailDistroGroup}><h3>{group}</h3></Checkbox>
            </Col>)
        })

        var resetAt3 = 0;
        var groupRows = { 0: [] }
        var currentRow = 0;
        for (var i = 0; i < groupCols.length; i++) {

            if (resetAt3 < 3) {
                groupRows[currentRow].push(groupCols[i])
                resetAt3++;
            }
            else {
                resetAt3 = 0;
                currentRow += 1;
                groupRows[currentRow] = [groupCols[i]]
                resetAt3++;

            }

        }
        //Ensuring that only 3 are on a line at a time
        return Object.keys(groupRows).map((row) => {
            return (
                <Row>
                    {groupRows[row]}
                </Row>
            )
        })
    }
    handleEquipmentRequestedToggle() {
        this.setState({ equipmentRequested: !this.state.equipmentRequested })
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo.equipmentRequested = true
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
    }

    render() {
        var credentials = this.generateCredentialSection()
        var emailDistroGroups = this.generateEmailDistroGroupSection()
        return (
            <div>
                {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo && Object.keys(this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo).length === 0 && this.props.hrInfo.selectedApplicant.data.hiringInfo.iTInfoCompleted &&
                    <Message icon  >
                        <Icon name='circle notched' loading />
                    </Message>}
                {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo && Object.keys(this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo).length > 0 &&
                    <Grid>
                        <Row><h1 style={{ float: 'left' }}>Ticket Created!</h1></Row>
                        <Row>  <h3 style={{ float: 'left' }}><a target="_blank" href={"https://helpdesk.vdmc.net/ticket.php?track=" + this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.trackingId}>View ticket {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.trackingId}</a></h3></Row>
                        {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId === 0 && <Row> <h3 style={{ float: 'left', color: '#FF0000' }}>Status: New</h3></Row>}
                        {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId === 5 && <Row> <h3 style={{ float: 'left', color: '#000000' }}>Status: On Hold</h3></Row>}
                        {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId === 3 && <Row> <h3 style={{ float: 'left', color: '#578556' }}>Status: Closed Resolved</h3></Row>}
                        {this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId !== 0 && this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId !== 3 && this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo.statusId !== 5 && <Row> <h3 style={{ float: 'left', color: '#999999' }}>Status: In Progress</h3></Row>}


                    </Grid>}

                {(!this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo || Object.keys(this.props.hrInfo.selectedApplicant.data.hiringInfo.iTTicketInfo).length === 0) && <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>

                    <br />
                    <br />
                    <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                        <br />
                        <Grid>
                            <Row>
                                <h1 style={{ float: 'left' }}>Credentials Requested:</h1>
                                <h4>Hipchat, E-mail, Seafile, and Network/Intranet Credentials will be created for all users and do not need to be requsted.</h4>
                            </Row>
                            <br />
                            {credentials}
                            <hr />
                            <br />

                            <Row>
                                <h1 style={{ float: 'left' }}>Email Distribution Lists:</h1>
                                <h4>Select the distribution lists that this applicant will be a part of.</h4>
                            </Row>
                            <br />
                            {emailDistroGroups}
                            <br />
                            <hr />
                            <Row>
                                <h1>Requesting Equipment?</h1>
                                {this.state.equipmentRequested &&
                                    <ButtonGroup>
                                        <Button bsStyle="warning">Equipment and Setup Requested</Button>
                                        <Button onClick={this.handleEquipmentRequestedToggle}>No equipment needed, but please reach out for setup</Button>
                                    </ButtonGroup>
                                }
                                {!this.state.equipmentRequested &&
                                    <ButtonGroup>
                                        <Button onClick={this.handleEquipmentRequestedToggle}>Equipment and Setup Requested</Button>
                                        <Button bsStyle="warning">No equipment needed, but please reach out for setup</Button>
                                    </ButtonGroup>
                                }
                            </Row>
                        </Grid>
                        <br />
                        <button className='form-control btn btn-success'>Submit Ticket to IT</button>
                    </form>
                    <br />
                    <br />
                    <div id='error'></div>
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        communicationInfo: state.communicationReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData: getAllHRData,
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            createTicket: createTicket,
            getTicket: getTicket,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(ITForm);
