import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Col, Row, ButtonGroup, Button } from 'react-bootstrap'
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import * as FontAwesome from 'react-icons/lib/fa';
import { customSortWithMoney, customFilter} from '../../../helpers/tableHelpers';



const date = new Date();
//Components are DUMB and should only display the data that was passed from the container that it is within
class DetailTable extends Component {

    constructor(props) {
        super();
        this.state = {
            tablePageSize: 10,
            currentPage: 1,
            leadersForTable: [],
            currentLeaders: [],
            oldLeaders: [],
            appStatus: ''
        }
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.getNewData = this.getNewData.bind(this);
        this.printTable = this.printTable.bind(this);

    }

    printTable() {

        var data = [];

        data.push([{ text: 'LO Name', bold: true }, { text: 'Number of Loans', bold: true }, { text: 'Total Cost Value', bold: true }]);

        this.state.leadersForTable.map((loan) => {
            data.push([
                { text: loan.loName },
                { text: loan.numOfLoans },
                { text: loan.totalDollars },
                { text: loan.borrowerName},
            ])
        });
        const { vfs } = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        var month = date.getMonth() + 1;
        var documentDefinition = {
            header: {
                columns: [
                    { text: this.props.reportType + ' (' + month + '/' + date.getDate() + '/' + date.getFullYear() + '@' + date.toLocaleTimeString() + ')', style: 'title' }
                ]
            },
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: ['33%', '33%', '33%'],
                        body: data
                    },
                    style: 'table'
                },

            ],
            styles: {
                table: {
                    alignment: 'center',
                    margin: [0, 20, 0, 0]
                },
                title: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 15, 0, 0]
                }
            }
        };

        pdfMake.createPdf(documentDefinition).print();
    }

    getNewData() {

        var leadersToDisplay = [];
        if(this.props.details){
        var loanNumbers = Object.keys(this.props.details);

        if (loanNumbers[0] !== 'dispatchConfig' && loanNumbers.length > 0) {

            //Create Data For Table
            for (var i = 0; i < loanNumbers.length; i++) {
                var loanNumber = loanNumbers[i];
                var totalDollars = this.props.details[loanNumber].totalDollars;
                var date= this.props.details[loanNumber].date;
                var borrowerName= this.props.details[loanNumber].borrowerName;
                var formattedObject = {
                    loanNumber: loanNumber,
                    date:date,
                    totalDollars: totalDollars,
                    borrowerName: borrowerName
                }

                leadersToDisplay.push(formattedObject)
            }
            this.setState({ leadersForTable: leadersToDisplay });
        }
    }

    }

    componentWillMount() {
        this.setState({ currentLeaders: this.props.details })
        this.getNewData()

    }

    handlePageSizeChange(event) {
        this.setState({ tablePageSize: event })
    }



    render() {

        if (this.state.currentLeaders !== this.props.details) {
            this.getNewData();
            this.setState({ currentLeaders: this.props.details });
        }


        const loanColumnHeader = [
            {
                Header: 'Borrower Name' ,
                accessor: 'borrowerName',
            },
            {
                Header: 'Created Date' ,
                accessor: 'date',
            },
            {
                Header: 'Total Cost Value',
                accessor: 'totalDollars',
            },
           

        ];
       

        return (
            <div style={{padding: '5px'}}>
                <Row>
                    <Col style={{float: 'left'}}>
                        <p style={{fontSize: '2rem' }}>{this.props.reportType}</p>
                    </Col>
               
                </Row>
                <hr />
                <ReactTable
                    id='CurrentTable'
                    data={this.state.leadersForTable}
                    columns={loanColumnHeader}
                    pageSize={this.state.tablePageSize}
                    className="-striped -highlight"
                    sortable={true}
                    filterable={true}
                    previousText='Previous Page'
                    nextText='Next Page'
                    onPageChange={this.handlePageChange}
                    onPageSizeChange={this.handlePageSizeChange}
                    defaultFilterMethod={customFilter}
                    defaultSortMethod={customSortWithMoney}
                    style={{
                        height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                      }}
                />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(DetailTable);