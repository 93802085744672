const settings = {
  URL: 'https://athena.vdmc.net',
  PORT: ':3500',
  QUIZPORT:':3100',
  ENDPOINTS: {
    pipeline: '/encompass/pipeline/athena',
    issue: '/utility/database/issues',
    add_issue: '/utility/database/issues/insert',
    resolve_issue: '/utility/database/issues/update',
    fundings: '/utility/database/fundings',
    locator: '/utility/database/employee',
    employees: '/utility/database/allEmployees',
    create_ticket:'/utility/createTicket',
    upload_exclusionary_file:'/utility/uploadExclusionaryFile',
    get_ticket:'/utility/getTicket',
    issue_token: '/encompass/token/issue/athena',
    revoke_token: '/encompass/token/revoke',
    introspection_token: '/encompass/token/introspection',
    athena_auth: '/authentication',
    encompass_link: '/authentication/encompass',
    app_link_status: '/utility/link/status',
    app_link_update: '/utility/link/update',
    get_ad_user_data: '/utility/getADUserData',
    mongo_find: '/utility/mongo/find',
    mongo_count: '/utility/mongo/count',
    mongo_upsert_one: '/utility/mongo/upsertOne',
    mongo_upsert_many: '/utility/mongo/upsertMany',
    mongo_delete_one: '/utility/mongo/deleteOne',
    mongo_delete_many: '/utility/mongo/deleteMany',
    send_email:'/communication/sendEmail',
    send_sms:'/communication/sendSMS',
    audit: '/audit/AO/vaAudit',
    upload_activity_image:'/uploadImage',
    get_surveys:'/socialSurvey'
  } 
}

export default settings