import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import EditRegionModal from '../modals/hr_modals/EditRegionModal';
import RegionsTable from '../tables/hr_tables/RegionsTable';
import AddRegionModal from '../modals/hr_modals/AddRegionModal';
import { getAllHRData} from '../../actions/hrActions';

class RegionsForm extends Component {
    constructor(props) {
        super();
        this.state = {
            borrowerInfo: {},
            gotCompStatus: false,
            formStatus: 'EDITING'
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }


    componentDidMount() {
    };


    handleSubmit() {
        this.setState({ formStatus: 'SAVING' })
        setTimeout(
            function () {
                this.setState({ formStatus: 'SUCCESS' })
            }
                .bind(this),
            3000
        );

    }


    openAddModal() {
        this.setState({
            addModalIsOpen: true
        });
    }

    closeAddModal() {
        this.setState({ addModalIsOpen: false });
    }

    getAddRegionModal() {
        return (<AddRegionModal modalIsOpen={this.state.addModalIsOpen} closeModal={this.closeAddModal.bind(this)} data={this.props.hrInfo}/>)
    }


    openEditModal(row) {
        this.setState({
            editModalIsOpen: true,
            selectedRow:row,
            clearData:false
        });
    }

    closeEditModal() {
        this.props.getAllHRData({collection:'regions'});
        this.setState({ editModalIsOpen: false, clearData:true ,firstPass:false, selectedRow:undefined});
    }

    getEditRegionModal(){
        return (<EditRegionModal modalIsOpen={this.state.editModalIsOpen} closeModal={this.closeEditModal.bind(this)} selectedRow={this.state.selectedRow} clearData={this.state.clearData} />)
      }

    render() {

        var addRegionModal = this.getAddRegionModal();
        var editRegionModal = this.getEditRegionModal();
        return (
            
            <div>
                {addRegionModal}
                {editRegionModal}
                <h1>Regions</h1>
                    <RegionsTable data={this.props.hrInfo} openAddModal={this.openAddModal} openEditModal={this.openEditModal} closeEditModal={this.closeEditModal}/>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData:getAllHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(RegionsForm);
