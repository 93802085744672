import React, { Component } from 'react';
import { Col, Row, Grid, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getActivities, addSubmission, resetActivities, upsertActivity, deleteActivity } from '../../actions/activityActions';
import QuestionsTable from '../tables/activity_tables/QuestionsTable'
import AddQuestionModal from '../modals/activity_modals/AddQuestionModal'
import EditQuestionModal from '../modals/activity_modals/EditQuestionModal'
const allOrNothingTooltip = (
    <Tooltip id="tooltip">
        <strong>This activity will give all points for participation </strong>
    </Tooltip>
);

const percentageTooltip = (
    <Tooltip id="tooltip">
        <strong>This activity will give a final score based on how many the user gets right or wrong. </strong>
    </Tooltip>
);

class QuestionsInfoCard extends Component {
    constructor(props) {
        super();
        this.state = {
            addQuestionModalOpen: false,
            editQuestionModalOpen: false,
            selectedQuestion: {},
            gotQuestions: false

        }
        this.openAddModal = this.openAddModal.bind(this)
        this.openEditModal = this.openEditModal.bind(this)
        this.closeAddModal = this.closeAddModal.bind(this)
        this.closeEditModal = this.closeEditModal.bind(this)
        this.setOldQuestions = this.setOldQuestions.bind(this)
    }

    componentWillMount() {

        this.setState({ questions: this.props.questions, gotQuestions: false })
    }
    openAddModal() {

        this.setState({ addQuestionModalOpen: true, clearModal: false })

    }

    openEditModal(selectedQuestion) {
        this.setState({ editQuestionModalOpen: true, selectedQuestion: selectedQuestion, clearModal: false })
    }

    closeAddModal() {
        this.setState({ addQuestionModalOpen: false, gotQuestions: false, clearModal: true })
    }

    closeEditModal() {
        this.setState({ editQuestionModalOpen: false, clearModal: true })
    }
    setOldQuestions() {
        this.setState({ oldQuestion: this.props.questions, gotQuestions: true })
    }

    render() {
        var table = <QuestionsTable questions={this.state.questions} openAddModal={this.openAddModal} openEditModal={this.openEditModal} deleteQuestion={this.props.deleteQuestion} />;

        //This is done to refresh the table
        if (!this.state.gotQuestions && this.state.oldQuestions != this.props.questions) {
            this.forceUpdate()
            this.setOldQuestions()

            table = <div />

        }
        return (
            <Grid style={{ minHeight: this.setState.minHeight }}>
                <EditQuestionModal modalOpen={this.state.editQuestionModalOpen} closeModal={this.closeEditModal} selectedQuestion={this.state.selectedQuestion} updateQuestion={this.props.updateQuestion} clearModal={this.state.clearModal}/>
                <AddQuestionModal modalOpen={this.state.addQuestionModalOpen} closeModal={this.closeAddModal} addQuestion={this.props.addQuestion} clearModal={this.state.clearModal} />
                <Row>
                    {table}
                </Row>
                <br />
                <br />
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        activitiesInfo: state.activityReducer,
        hrInfo: state.hrReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivities: getActivities,
            addSubmission: addSubmission,
            upsertActivity: upsertActivity,
            resetActivities: resetActivities,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionsInfoCard);
