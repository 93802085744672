//Initial Data
const initialState = {
    sendEmailStatus: 'NOT_STARTED',
    sendEmailErrorMessage: '',
    sendSMSStatus: 'NOT_STARTED',
    sendSMSErrorMessage: '',
    createTicketStatus: 'NOT_STARTED',
    createTicketErrorMessage: '',
    getTicketStatus: 'NOT_STARTED',
    getTicketErrorMessage: '',
    uploadExclusionaryFileStatus: 'NOT_STARTED',
    uploadExclusionaryFileErrorMessage: '',
    iTTicketInfo: {},
    getSurveyStatus: 'NOT_STARTED',
    getSurveyErrorMessage: '',
    currentUserSurveys:[]

}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'COMMUNICATION_RESET':
            return {
                ...state,
                sendEmailStatus: 'NOT_STARTED',
                sendEmailErrorMessage: '',
                sendSMSStatus: 'NOT_STARTED',
                sendSMSErrorMessage: '',
                createTicketStatus: 'NOT_STARTED',
                createTicketErrorMessage: '',
                getTicketStatus: 'NOT_STARTED',
                getTicketErrorMessage: '',
                uploadExclusionaryFileStatus: 'NOT_STARTED',
                uploadExclusionaryFileErrorMessage: '',
                iTTicketInfo: {},
                getSurveyStatus: 'NOT_STARTED',
                getSurveyErrorMessage: '',
                currentUserSurveys:[]
            }
        case 'SEND_EMAIL_RESET':
            return {
                ...state,
                sendEmailStatus: 'NOT_STARTED',
                sendEmailErrorMessage: '',
            }
        case 'SEND_EMAIL_STARTED':
            return {
                ...state,
                sendEmailStatus: 'STARTED',
            }
        case 'SEND_EMAIL_SUCCEEDED':
            return {
                ...state,
                sendEmailStatus: 'SUCCEEDED',
            }
        case 'SEND_EMAIL_ERRORED':
            return {
                ...state,
                sendEmailStatus: 'ERRORED',
                sendEmailErrorMessage: action.sendEmailErrorMessage
            }
        case 'SEND_SMS_RESET':
            return {
                ...state,
                sendSMSStatus: 'NOT_STARTED',
            }
        case 'SEND_SMS_STARTED':
            return {
                ...state,
                sendSMSStatus: 'STARTED',
            }
        case 'SEND_SMS_SUCCEEDED':
            return {
                ...state,
                sendSMSStatus: 'SUCCEEDED',
            }
        case 'SEND_SMS_ERRORED':
            return {
                ...state,
                sendSMSStatus: 'ERRORED',
                sendSMSErrorMessage: action.sendSMSErrorMessage
            }
        case 'CREATE_TICKET_RESET':
            return {
                ...state,
                createTicketStatus: 'NOT_STARTED',
                createTicketErrorMessage: '',
                iTTicketInfo: {},
                iTTicketTrackingId: ''
            }
        case 'CREATE_TICKET_STARTED':
            return {
                ...state,
                createTicketStatus: 'STARTED',
            }
        case 'CREATE_TICKET_SUCCEEDED':
            return {
                ...state,
                createTicketStatus: 'SUCCEEDED',
            }
        case 'CREATE_TICKET_ERRORED':
            return {
                ...state,
                createTicketStatus: 'ERRORED',
                createTicketErrorMessage: action.createTicketErrorMessage
            }
        case 'GET_TICKET_RESET':
            return {
                ...state,
                getTicketStatus: 'NOT_STARTED',
                getTicketErrorMessage: '',
            }
        case 'GET_TICKET_STARTED':
            return {
                ...state,
                getTicketStatus: 'STARTED',
            }
        case 'GET_TICKET_SUCCEEDED':
            return {
                ...state,
                getTicketStatus: 'SUCCEEDED',
                iTTicketInfo: action.iTTicketInfo
            }
        case 'GET_TICKET_ERRORED':
            return {
                ...state,
                getTicketStatus: 'ERRORED',
                getTicketErrorMessage: action.getTicketErrorMessage
            }
        case 'UPLOAD_EXCLUSIONARY_FILE_STARTED':

            return {
                ...state,
                uploadExclusionaryFileStatus: 'STARTED',
            }
        case 'UPLOAD_EXCLUSIONARY_FILE_SUCCEEDED':
            return {
                ...state,
                uploadExclusionaryFileStatus: 'SUCCEEDED',
            }
        case 'UPLOAD_EXCLUSIONARY_FILE_ERRORED':
            return {
                ...state,
                uploadExclusionaryFileStatus: 'ERRORED',
                uploadExclusionaryFileErrorMessage: action.uploadExclusionaryFileErrorMessage
            }
        case 'GET_SURVEY_STARTED':
            return {
                ...state,
                getSurveyStatus: 'STARTED',
            }
        case 'GET_SURVEY_SUCCEEDED':
            return {
                ...state,
                getSurveyStatus: 'SUCCEEDED',
                currentUserSurveys: action.currentUserSurveys
            }
        case 'GET_SURVEY_ERRORED':
            return {
                ...state,
                getSurveyStatus: 'ERRORED',
                getSurveyErrorMessage: action.getSurveyErrorMessage
            }
        default:
            return state
    }
};