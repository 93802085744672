import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData, getAllHRData } from '../../actions/hrActions';
import Autosuggest from 'react-autosuggest';



function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

class TitleDropdown extends Component {
    constructor(props) {
        super();
        this.state = {
            value: '',
            suggestions: [],
        };

        this.getSuggestions = this.getSuggestions.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
    }

    componentDidMount() {
                 this.props.getAllHRData({collection:'titles'});
        if (this.props.value) {
            this.setState({ value: this.props.value })
        }
    }
    getSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }
        return this.props.hrInfo.allTitlesData.filter(title => {
            if (title.Title.toLowerCase().indexOf(value.toLowerCase())!= -1) { return true }
        })

    }
    getSuggestionValue(suggestion) {
        this.props.setTitleValue(suggestion.Title)
        return suggestion.Title;
    }
    renderSuggestion(suggestion) {
        return (
            <div id={suggestion.Title}> <span>{suggestion.Title}</span></div>
        );


    }
    onChange = (event, { newValue, method }) => {
        //Gets all suggestion when the field value changes
        //When there is only one then that means we have found the one we want.
        var suggestion = this.getSuggestions(newValue);
        if (suggestion.length !== 1) {
            this.props.setTitleValue('')
        }
        else {

            this.props.setTitleValue(suggestion[0].Title)
        }
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });

        this.props.setTitleValue()
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionHighlighted = (value) => {
        if (value.suggestion) {
            var nonHighlightedArray = this.state.suggestions;
            var index = nonHighlightedArray.indexOf(value.suggestion.Title);
            if (index > -1) {
                nonHighlightedArray.splice(index, 1);
            }

            nonHighlightedArray.map((suggestion) => {
                document.getElementById(suggestion.Title).style.backgroundColor = 'rgba(0, 0, 0, 0)';
            })
            document.getElementById(value.suggestion.Title).style.backgroundColor = '#dddddd';
        }

    };

    render() {
        if (this.props.value && !this.state.gotValue) {
            this.setState({ value: this.props.value, gotValue: true })
        }
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Search Titles",
            value,
            onChange: this.onChange,
            style: { width: '90%', height: '50px', borderRadius: '5px', paddingLeft: '5px' }
        };
        return (<Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            inputProps={inputProps} />);
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            getAllHRData:getAllHRData,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(TitleDropdown);
