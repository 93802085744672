import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { resetPipeline } from '../actions/pipelineActions';
import LoanListView from '../components/pipelineComponents/loanRevComponents/LoanListView';
import MenuSideBar from '../components/layoutComponents/MenuSideBar';

class DashboardContainer extends Component {
    componentWillMount() {
    }

    componentDidMount() {
        this.props.resetPipeline();

    }

    render() {

        return (
            <div id="loanCompareOuterContainer">
                <MenuSideBar comp={<LoanListView />} />
            </div>
        )
    }
}

//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
    };
}

//This function links functions/dispatch to the props of the component being processed.
//In this case we are:
//changeUser - grabbing a function from actions.js file 
//goToAnotherPage - utilizing the push command from react-router to go to a page specified in the params
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            goToAnotherPage: (params) => push(params),
            resetPipeline: resetPipeline
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
