import React, { Component } from 'react';
import * as FontAwesome from 'react-icons/lib/fa';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
//Components are DUMB and should only display the data that was passed from the container that it is within
class LeaderboardReportCard extends Component {
    constructor(props) {
        super();
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    generateCard(data, displayingType, valueType) {
        var message = "";
        var previousRank = data.Rank + data.PositionChange

        if (data.PositionChange === 0 || previousRank === 0) {
            message = "No Change in Rank"
        }
        else if (data.PositionChange > 0) {
            message = "Moved up from " + previousRank
        }
        else if (data.PositionChange < 0) {
            message = "Moved down from " + previousRank
        }

        var tooltip = (
            <Tooltip id="tooltip">
                {message}
            </Tooltip>
        );

        return (
            <Col xs={4} sm={2} md={2} style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
                {(data.PositionChange > 0 && previousRank !== 0) &&
                    <div style={this.state.width < 768 ? { height: '8rem', backgroundColor: '#20a65a', width: '8rem' } : { height: '6rem', backgroundColor: '#20a65a', width: '7rem' }}>
                        <p style={{ textAlign: 'center', paddingTop: '.5rem' }}>
                            {valueType === 'Percentage' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} % Rank</span>}
                            {valueType === 'Count' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} {valueType} Rank</span>}<br />
                            <OverlayTrigger placement="top" overlay={tooltip}>
                                <span style={{ color: '#1a884b', fontSize: '1.5rem' }}><FontAwesome.FaSortAsc /></span>
                            </OverlayTrigger>
                            <span style={{ color: '#fff', fontSize: '2rem', fontWeight: 'bold' }}>{data.Rank}</span><br />
                            <span style={{ color: '#fff', fontSize: '1rem' }}>{data[valueType]}</span>
                        </p>
                    </div>
                }
                {(data.PositionChange === 0 || previousRank === 0) &&
                    <div style={this.state.width < 768 ? { height: '8rem', backgroundColor: '#26bfef', width: '8rem' } : { height: '6rem', backgroundColor: '#26bfef', width: '7rem' }}>
                        <p style={{ textAlign: 'center', paddingTop: '.5rem' }}>
                            {valueType === 'Percentage' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} % Rank</span>}
                            {valueType === 'Count' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} {valueType} Rank</span>}<br />
                            <OverlayTrigger placement="left" overlay={tooltip}>
                                <span style={{ color: '#219cc2', fontSize: '1.5rem' }}><FontAwesome.FaMinus /></span>
                            </OverlayTrigger>
                            <span style={{ color: '#fff', fontSize: '2rem', fontWeight: 'bold' }}>{data.Rank}</span><br />
                            <span style={{ color: '#fff', fontSize: '1rem' }}>{data[valueType]}</span>
                        </p>
                    </div>
                }
                {(data.PositionChange < 0 && previousRank !== 0) &&
                    <div style={this.state.width < 768 ? { height: '8rem', backgroundColor: '#d4412f', width: '8rem' } : { height: '6rem', backgroundColor: '#d4412f', width: '7rem' }}>
                        <p style={{ textAlign: 'center', paddingTop: '.5rem' }}>
                            {valueType === 'Percentage' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} % Rank</span>}
                            {valueType === 'Count' && <span style={{ color: '#fff', fontSize: '.75rem', fontWeight: 'bold' }}>{displayingType} {valueType} Rank</span>}<br />
                            <OverlayTrigger placement="bottom" overlay={tooltip}>
                                <span style={{ color: '#9f302d', fontSize: '1.5rem' }}><FontAwesome.FaSortDesc /></span>
                            </OverlayTrigger>
                            <span style={{ color: '#fff', fontSize: '2rem', fontWeight: 'bold' }}>{data.Rank}</span><br />
                            <span style={{ color: '#fff', fontSize: '1rem' }}>{data[valueType]}</span>
                        </p>
                    </div>
                }</Col>)
    }

    render() {
        var AllIndividualCountByYear = this.generateCard(this.props.myStats.AllIndividualCountByYear, 'My', 'Count');
        var AllIndividualPercentageByYear = this.generateCard(this.props.myStats.AllIndividualPercentageByYear, 'My', 'Percentage');
        var AllBranchesCountByYear = this.generateCard(this.props.myStats.AllBranchesCountByYear, 'Br.', 'Count');
        var AllBranchesPercentageByYear = this.generateCard(this.props.myStats.AllBranchesPercentageByYear, 'Br.', 'Percentage');
        var AllRegionslCountByYear = this.generateCard(this.props.myStats.AllRegionsCountByYear, 'Rgn.', 'Count');
        var AllRegionslPercentageByYear = this.generateCard(this.props.myStats.AllRegionsPercentageByYear, 'Rgn.', 'Percentage');
        return (
            <Row>
                <Row>
                    <Col sm={4} md={4}>
                        <Row>
                            <Col smOffset={4} mdOffset={4}>
                                <h4><strong>Personal:</strong> </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6} >
                                {AllIndividualCountByYear}
                            </Col>
                            <Col sm={6} md={6}>
                                {AllIndividualPercentageByYear}
                            </Col>
                        </Row>
                    </Col>
                    {this.state.width > 768 && <Col sm={4} md={4}>
                        <Row>  <Col smOffset={4} mdOffset={4}>
                            <h4><strong>Branch:</strong> {this.props.myStats.Branch}  </h4>
                        </Col>

                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                {AllBranchesCountByYear}
                            </Col>
                            <Col sm={6} md={6}>
                                {AllBranchesPercentageByYear}
                            </Col>
                        </Row>
                    </Col>}
                    {this.state.width > 768 && <Col sm={4} md={4}>
                        <Row>
                            <Col smOffset={4} mdOffset={4}>
                                <h4><strong>Region:</strong> {this.props.myStats.Region}</h4>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                {AllRegionslCountByYear}
                            </Col>
                            <Col sm={6} md={6}>
                                {AllRegionslPercentageByYear}
                            </Col>
                        </Row>
                    </Col>}
                </Row>
            </Row>
        )

    }
}
//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        locatorInfo: state.locatorReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardReportCard);