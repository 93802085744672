import React, { Component } from 'react';
import { Col, Row, Checkbox, Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getActivities, addSubmission, resetActivities } from '../../actions/activityActions';
import { upsertHRData } from '../../actions/hrActions';
import CountTo from 'react-count-to';
const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];


class FundingsView extends Component {
    constructor(props) {
        super();
        this.state = {
            isLoading: false,
            currentActivity: {},
            activities: [],
            gotActivities: false,
            oldTotalPoints: 0,
            didReset: false,
            1: false,
            2: false,
            3: false


        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateCheckBox = this.updateCheckBox.bind(this)
        this.generateActivity = this.generateActivity.bind(this)

    }


    componentWillMount() {
        this.props.resetActivities()

    }
    componentDidMount() {
        this.props.getActivities({ collection: 'activities' })
    }



    handleSubmit(event) {
        event.preventDefault();
        if (this.state['1'] == true && this.state['2'] == false && this.state['3'] == false) {

            //////////////////////////////////////////
            // Add Submission to the activities object
            //////////////////////////////////////////

            //Get All Current Submissions
            var submissions = this.state.currentActivity.submissions
            //ensure that user has not already submitted an entry
            var resubmit = submissions.map((submission) => {
                if (submission.employeeId == this.props.loginInfo.currentUser._id) {
                    return true;
                }
            })
            if (resubmit.length > 0) {
                alert('Uh Oh ' + this.props.loginInfo.currentUser.firstName + ' you have already played this activity.  Here is another activity you can play...')
                var activities = this.state.activities
                activities.shift();
                this.setState({ activities: activities, currentActivity: activities[0] })
                return
            }
            
            var newSubmission = {
                submittedDate: new Date().toISOString(),
                totalPointsAwarded: this.state.currentActivity.pointValue,
                employeeId: this.props.loginInfo.currentUser._id,
                username: this.props.loginInfo.currentUser.userName,
                activityName: this.state.currentActivity.activityName,
                activityId: this.state.currentActivity._id,

            }
            submissions.push(newSubmission)
            var allSubmission = submissions
            var activityData = {
                data: {
                    activityName: this.state.currentActivity.activityName,
                    departments: this.state.currentActivity.departments,
                    pointValue: this.state.currentActivity.pointValue,
                    createdBy: this.state.currentActivity.createdBy,
                    approvedBy: this.state.currentActivity.approvedBy,
                    activityStatus: this.state.currentActivity.activityStatus,
                    activityType: this.state.currentActivity.activityType,
                    submissions: allSubmission,
                },
                _id: this.state.currentActivity._id,
                collection: 'activities'
            };

            //////////////////////////////////////////
            // Add Submission to the Employee Object
            //////////////////////////////////////////

            var oldTotalPoints = this.props.loginInfo.currentUser.gamingInfo.totalPoints
            var newTotalPoints = this.props.loginInfo.currentUser.gamingInfo.totalPoints + this.state.currentActivity.pointValue

            var newActivity = this.props.loginInfo.currentUser.gamingInfo.activities
            newActivity.push(newSubmission)

            var employeeData = {
                data: {
                    itInfo: this.props.loginInfo.currentUser.itInfo,
                    licensingInfo: this.props.loginInfo.currentUser.licensingInfo,
                    employmentInfo: this.props.loginInfo.currentUser.employmentInfo,
                    gamingInfo:
                    {
                        totalPoints: newTotalPoints,
                        activities: newActivity
                    },
                    firstName: this.props.loginInfo.currentUser.firstName,
                    lastName: this.props.loginInfo.currentUser.lastName,
                    userName: this.props.loginInfo.currentUser.userName,

                },
                _id: this.props.loginInfo.currentUser._id,
                collection: 'employees'
            };

            var submissionData = {
                activityData: activityData,
                employeeData: employeeData
            }

            //Perform Actual DB Changes
            this.props.addSubmission(submissionData)


            //removes first element from array and sets current activity to the next activity
            var activities = this.state.activities
            activities.shift();
            this.setState({ activities: activities, currentActivity: activities[0], oldTotalPoints: oldTotalPoints })

            alert('Congrats! ' + this.props.loginInfo.currentUser.firstName + ' you won the Activity of the Day! Play Another Activity!')
            this.setState({
                1: false,
                2: false,
                3: false
            })

        }
        else {
            alert('Uh Oh ' + this.props.loginInfo.currentUser.firstName + ' you did not win.  Try Again')
            this.setState({
                1: false,
                2: false,
                3: false
            })
        }

        return
    }

    updateCheckBox(e) {

        //For The Actual Check Mark
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        });

    }

    generateActivity() {

        return (
            <Row>
                <Col md={4} sm={12}>
                    <Checkbox name='1' checked={this.state['1']} onChange={this.updateCheckBox}><h3>Pick Me</h3></Checkbox>
                </Col>
                <Col md={4} sm={12}>
                    <Checkbox name='2' checked={this.state['2']} onChange={this.updateCheckBox}><h3>Not Me</h3></Checkbox>
                </Col>
                <Col md={4} sm={12}>
                    <Checkbox name='3' checked={this.state['3']} onChange={this.updateCheckBox}><h3>Not Me</h3></Checkbox>
                </Col>
            </Row>
        )
    }

    render() {

        var checks = this.generateActivity()
        if (this.props.activitiesInfo.getActivitiesStatus == 'SUCCEEDED' && this.state.didReset && !this.state.gotActivities) {

            var activities = this.props.activitiesInfo.activitiesData
            this.setState({ activities: activities, gotActivities: true, currentActivity: activities[0] })
        }
        if (this.props.activitiesInfo.getActivitiesStatus == 'STARTED' && !this.state.didReset) {
            this.setState({ didReset: true })
        }
        var createdBy = this.props.hrInfo.allEmployeesData.filter(employee => {
            if (this.state.currentActivity.createdBy == employee._id) { return true }
        })[0]

        var approvedBy = this.props.hrInfo.allEmployeesData.filter(employee => {
            if (this.state.currentActivity.approvedBy == employee._id) { return true }
        })[0]

        return (
            <div>
                <Grid>
                    <form onSubmit={this.handleSubmit}>
                        <br />
                        <h3>Total Points: <CountTo to={this.props.loginInfo.currentUser.gamingInfo.totalPoints} from={this.state.oldTotalPoints} speed={1500} /></h3>
                        <hr />
                        <h2>Activity of the Day:</h2>
                        <h1>{this.state.currentActivity ? this.state.currentActivity.activityName : ''}</h1>
                        <br />

                        <div>
                            {checks}
                        </div>

                        <button className='form-control btn btn-success'>Submit Answer</button>
                    </form>
                    <br />
                    <h4>Created By: {createdBy ? createdBy.firstName + ' ' + createdBy.lastName : ''}</h4>
                    <h4>Approved By: {approvedBy ? approvedBy.firstName + ' ' + approvedBy.lastName : ''}</h4>
                </Grid>

            </div >

        )
    }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginReducer,
        activitiesInfo: state.activityReducer,
        hrInfo: state.hrReducer,
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getActivities: getActivities,
            addSubmission: addSubmission,
            upsertHRData: upsertHRData,
            resetActivities: resetActivities,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(FundingsView);
