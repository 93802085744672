import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { upsertHRData } from '../../../actions/hrActions';
//import { getEmployees, addEmployee, deleteEmployee, getEmployeeNotes, addEmployeeNote, getAllEmployeeNotes } from '../../actions/employeeActions';
import { addIssue, getCodes, getContacts } from '../../../actions/issueActions';
import RoleEditTable from '../../tables/hr_tables/hr_RoleEdittable';


const addModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        top: '60%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        minWidth: '70%',
        height: '70%', 
        overlfow: 'scroll' 
    }
};

class EditRoleModal extends Component {
    constructor(props) {
        super();
        this.state = {
            regionChecks: [],
            roleName: '',
            firstPass: true
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.remove = this.remove.bind(this);
        this.clearOutModal = this.clearOutModal.bind(this);

    }

    handleSubmit(updatedRegions) {

        // do form stuff
        var roleData = {
            roleName: this.state.roleName,
            regions: updatedRegions,
            collection:'roles',
        };
        this.props.upsertHRData(roleData)

        //Clears form on Submit

        this.clearOutModal()
        this.props.closeModal()
        return;

    }

    clearOutModal() {

        this.props.hrInfo.allRegionsData.map((val) => {
            var tempName = 'checkbox' + val.Region
            this.setState({
                [tempName]: false
            })
        })
        this.setState({
            roleName: '',
            regions: [],
            dataIsCleared: true
        });

    }


    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value
        });
    }

    handleCheckChange(event) {

        const target = event.target;
        const name = target.name;
        const value = target.value;

        var checks = [];
        checks = this.state.regionChecks;
        if (checks.indexOf(value)!= -1) {
            this.remove(checks, value)
            this.setState({
                regionChecks: checks,
                [name]: false
            });
        }
        else {
            checks.push(value)
            this.setState({
                regionChecks: checks,
                [name]: true
            });
        }

    }

    remove(array, element) {
        const index = array.indexOf(element);
        array.splice(index, 1);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }



    render() {
        //THE CLEARDATA PROPERTY IS TRUE WHEN WE CLOSE
        if (this.props.clearData && !this.state.dataIsCleared) {
            this.clearOutModal();
        }
        var rowData = this.state.selectedRow;

        //POPULATES THE REGIONS WHEN SOMETHING CHANGED
        if (this.props.selectedRow != this.state.selectedRow) {
            rowData = this.props.selectedRow;
            if (rowData) {
                rowData.Region.map((region) => {
                    var tempName = 'checkbox' + region
                    this.setState({
                        [tempName]: true
                    })
                })
                this.setState({
                    roleName: this.props.selectedRow.Role,
                    regionChecks: this.props.selectedRow.Region,
                    selectedRow: this.props.selectedRow,
                    dataIsCleared: false

                })
            }
        }




        return (

            <div>
                {rowData &&
                    <Modal
                        isOpen={this.props.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.props.closeModal}
                        style={addModalStyles}
                        ariaHideApp={false}
                        shouldCloseOnOverlayClick={false}
                    >
                        <div>

                            <div className='try - registration - form'
                                style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                                <button type="button" className="close" aria-label="Close" style={{ color: '#272d33', float: 'right' }} onClick={this.props.closeModal}>
                                    <span aria-hidden="false">&times;</span>
                                </button>
                                <h1 style={{ float: 'left' }}>Edit Role</h1>
                                <br />
                                <br />
                                <h3>Role Name</h3>
                                <input className='form - control' id='roleName' type='text' name='roleName' placeholder='Role Name' value={this.state.roleName} onChange={this.handleInputChange} />
                                <br />
                                <RoleEditTable selectedRows={this.props.selectedRow.Region} handleSubmit={this.handleSubmit}/>
                                <br />
                                <div id='error'></div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        )
    }
}


//This function links data from the store to the props of the component being processed.
function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer,
        issueInfo: state.issueReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

            upsertHRData: upsertHRData,
            addIssue: addIssue,
            getContacts: getContacts,
            getCodes: getCodes,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}

//Connect links the props and dispatch to the store.  Without this the props will not be passed to the current component
export default connect(mapStateToProps, mapDispatchToProps)(EditRoleModal);
