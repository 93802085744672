///////////////////////////////////
///Pipeline Actions
///////////////////////////////////



export const getIssues = (issueParam) => {


    return {
        type: 'GET_ISSUES',
        payload: issueParam
    }
};

export const addIssue = (issueParam) => {


    return {
        type: 'ADD_ISSUE',
        payload: issueParam
    }
};

export const resolveIssue = (issueParam) => {

    return {
        type: 'RESOLVE_ISSUE',
        payload: issueParam
    }
};

export const getContacts = (issueParam) => {


    return {
        type: 'GET_CONTACTS',
        payload: issueParam
    }
};

export const getCodes = (issueParam) => {

    return {
        type: 'GET_CODES',
        payload: issueParam
    }
};

export const resetIssues = () => {
    return {
        type: 'RESET_PIPELINE_STATUS'
    }
};
