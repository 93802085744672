import { DatePickerInput } from 'rc-datepicker';
import React, { Component } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, HelpBlock, Panel, Row } from 'react-bootstrap';
import * as FontAwesome from 'react-icons/lib/fa';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { getAllHRData, setSelectedApplicant, upsertHRData } from '../../actions/hrActions';
import DepartmentDropdown from '../dropdowns/DepartmentDropdown';
import EmployeeDropdown from '../dropdowns/EmployeeDropdown';
import EmploymentStatusDropdown from '../dropdowns/EmploymentStatusDropdown';
import LocationDropdown from '../dropdowns/LocationDropdown';
import TitleDropdown from '../dropdowns/TitleDropdown';

class RolesForm extends Component {
    constructor(props) {
        super();
        this.state = {

            openPosition: false,
            openContact: false,
            openDocuments: false,
            fileData: {},
            filesPreview: [],
            totalFileSize: 0
        }

        this.handleEmploymentInfoInputChange = this.handleEmploymentInfoInputChange.bind(this)
        this.handleHiringInfoInputChange = this.handleHiringInfoInputChange.bind(this)
        this.handleOfferLetterDateChange = this.handleOfferLetterDateChange.bind(this)
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
    }


    componentDidMount() {
        //skip if employment info from the selectedApplicant is empty
        if (Object.keys(this.props.hrInfo.selectedApplicant.data.employmentInfo).length > 0) {
            var location = this.getLocationById(this.props.hrInfo.selectedApplicant.data.employmentInfo.location)
            var directReportName = this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport ? this.getEmployeeById(this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport).firstName + ' ' + this.getEmployeeById(this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport).lastName : ''
            if (location) {
                var address = location.address1 + ' ' + location.address2 + ', ' + location.city + ', ' + location.state + ' ' + location.zip
            }
            var department = this.props.hrInfo.selectedApplicant.data.employmentInfo.department ? this.props.hrInfo.selectedApplicant.data.employmentInfo.department.split(' ').length > 1 ? this.props.hrInfo.selectedApplicant.data.employmentInfo.department.split(' ')[1] : this.props.hrInfo.selectedApplicant.data.employmentInfo.department : '';
            var region = '';
            for (var i = 0; i < this.props.hrInfo.allDepartmentsData.length; i++) {
                if (this.props.hrInfo.allDepartmentsData[i].Name === department) {
                    region = this.props.hrInfo.allDepartmentsData[i].Region
                }
            }
            this.setState({
                location: this.props.hrInfo.selectedApplicant.data.employmentInfo.location,
                locationAddress: address,
                title: this.props.hrInfo.selectedApplicant.data.employmentInfo.title,
                department: department,
                directReport: this.props.hrInfo.selectedApplicant.data.employmentInfo.directReport,
                directReportName: directReportName,
                region: region,
                workEmail: this.props.hrInfo.selectedApplicant.data.employmentInfo.workEmail,
                workPhone: this.props.hrInfo.selectedApplicant.data.employmentInfo.workPhone,
                website: this.props.hrInfo.selectedApplicant.data.employmentInfo.website,
                employmentStatus: this.props.hrInfo.selectedApplicant.data.employmentInfo.employmentStatus,
                offerLetterSentDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSentDate ? this.props.hrInfo.selectedApplicant.data.hiringInfo.offerLetterSentDate.split('T')[0] : '',
                estimatedStartDate: this.props.hrInfo.selectedApplicant.data.hiringInfo.estimatedStartDate,
                wage: this.props.hrInfo.selectedApplicant.data.hiringInfo.wage,
                nmls: this.props.hrInfo.selectedApplicant.data.employmentInfo.nmls,
                employmentInfoCompleted: this.props.hrInfo.selectedApplicant.data.hiringInfo.employmentInfoCompleted
            })
        }
    }
    handleOfferLetterDateChange(date) {
        var today = new Date();
        if (date !== 'Invalid date' && today.getTime() < date.getTime()) {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            var propDate = newYear + '-' + newMonth + '-' + newDate;
            var value = propDate + 'T00:00:00.000Z'
            //Ensure that Estimated Start Date is a week after the offer letter start date
            if (this.state.estimatedStartDate) {
                var oneWeek = 604800000;
                var propOfferLetterDate = date.getTime()
                var estStartDate = Date.parse(this.state.estimatedStartDate)
                if (estStartDate - oneWeek <= propOfferLetterDate) {
                    alert(propDate + ' is an invalid Offer Letter Date.  The Offer Letter Date must be at least one week before Estimated Start Date.')
                    return;
                }
            }
            var newObj = this.props.hrInfo.selectedApplicant.data;
            newObj.hiringInfo.offerLetterSentDate = value
            var updatedObj = {
                data: newObj,
                _id: this.props.hrInfo.selectedApplicant._id,
                collection: 'applicants'
            }
            this.props.upsertHRData(updatedObj)
            this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

            this.setState({ offerLetterSentDate: value.split('T')[0], })
        }
        else if (date !== 'Invalid date' && today.getTime() > date.getTime()) {
            alert('Start Date selected is out of range.  Only Dates after today can be used.')

        }

    }

    handleStartDateChange(date) {

        var today = new Date();
        if (date !== 'Invalid date' && today.getTime() < date.getTime()) {
            var newMonth = date.getMonth() + 1;
            var newDate = date.getDate();
            var newYear = date.getFullYear();
            var propDate = newYear + '-' + newMonth + '-' + newDate;
            var value = propDate + 'T00:00:00.000Z'
            //Check to ensure that Offer Letter sent date is a week before start date
            if (this.state.offerLetterSentDate) {
                var oneWeek = 604800000;
                var propEstStartDate = date.getTime()
                var offerLetterDate = Date.parse(this.state.offerLetterSentDate)

                if (offerLetterDate + oneWeek > propEstStartDate) {
                    alert(propDate + ' is an invalid Estimated Start Date.  The Estimated Start Date must be at least one week after Offer Letter Date (' + this.state.offerLetterSentDate.split('T')[0] + ').')
                    return;
                }
            }

            var newObj = this.props.hrInfo.selectedApplicant.data;
            newObj.hiringInfo.estimatedStartDate = value
            var updatedObj = {
                data: newObj,
                _id: this.props.hrInfo.selectedApplicant._id,
                collection: 'applicants'
            }
            this.props.upsertHRData(updatedObj)
            this.props.setSelectedApplicant({ selectedApplicant: updatedObj });

            this.setState({ estimatedStartDate: value.split('T')[0], })
        }
        else if (date !== 'Invalid date' && today.getTime() > date.getTime()) {
            alert('Start Date selected is out of range.  Only Dates after today can be used.')

        }
        else {
            this.setState({ endDateError: true })
        }
    }
    getEmployeeById(id) {
        for (var i = 0; i < this.props.hrInfo.allEmployeesData.length; i++) {
            if (this.props.hrInfo.allEmployeesData[i]._id === id) {
                return this.props.hrInfo.allEmployeesData[i];
            }
        }
    }
    getLocationById(id) {
        for (var i = 0; i < this.props.hrInfo.allLocationsData.length; i++) {
            if (this.props.hrInfo.allLocationsData[i]._id === id) {
                return this.props.hrInfo.allLocationsData[i];
            }
        }
    }
    makeId() {
        var text = "";
        var possible = "ABCDEF0123456789";

        for (var i = 0; i < 24; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    onKeyPress(event) {
        if (event.which === 13) {
            event.preventDefault();
        }
    }
    handleEmploymentInfoInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.employmentInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }
    handleHiringInfoInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.hiringInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }
        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }
    handleEmploymentInfoDropDownChange(name, value) {
        var newObj = this.props.hrInfo.selectedApplicant.data;
        newObj.employmentInfo[name] = value
        var updatedObj = {
            data: newObj,
            _id: this.props.hrInfo.selectedApplicant._id,
            collection: 'applicants'
        }

        this.props.upsertHRData(updatedObj)
        this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
        this.setState({
            [name]: value
        });
    }
    getValidationState(value) {
        if (value) {
            const length = value.length;
            if (length > 0) {
                return 'success';
            }
        }
        else {
            return 'warning'
        }
    }
    validateForm() {
        var newObj = this.props.hrInfo.selectedApplicant.data;
        //Good to Bad or Bad to Good
        if (((!this.state.location || !this.state.locationAddress || !this.state.title || !this.state.department || !this.state.directReport) && this.state.employmentInfoCompleted) || ((this.state.location && this.state.locationAddress && this.state.title && this.state.department && this.state.directReport) && !this.state.employmentInfoCompleted)) {
            newObj.hiringInfo.employmentInfoCompleted = !this.state.employmentInfoCompleted
            var updatedObj = {
                data: newObj,
                _id: this.props.hrInfo.selectedApplicant._id,
                collection: 'applicants'
            }
            this.props.upsertHRData(updatedObj)
            this.props.setSelectedApplicant({ selectedApplicant: updatedObj });
            this.setState({ employmentInfoCompleted: !this.state.employmentInfoCompleted })
            return;
        }

    }
    render() {
        this.validateForm();
        return (

            <div>

                <div className='try - registration - form'
                    style={{ margin: 'auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, color: '#000', height: 'auto' }}>
                    <h1 style={{ float: 'left' }}>Employment Information</h1>
                    <br />
                    <br />
                    <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress} className='try-form' id='callback'>
                        <br />
                        <Grid>
                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openPosition: !this.state.openPosition, openContact: false, openDocuments: false })}>Position Information
                                        &nbsp;{this.state.openPosition && <FontAwesome.FaArrowCircleDown />}{!this.state.openPosition && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openPosition}>
                                <Panel.Collapse>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.title)}  >
                                                <ControlLabel>Title</ControlLabel>
                                                <TitleDropdown setTitleValue={(value) => {
                                                    if (value)
                                                        this.handleEmploymentInfoDropDownChange('title', value)
                                                    else
                                                        this.handleEmploymentInfoDropDownChange('title', '')
                                                }} value={this.state.title} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.department)}  >
                                                <ControlLabel>Department</ControlLabel>
                                                <DepartmentDropdown setDepartmentValue={(value, regionValue) => {
                                                    if (value) {
                                                        this.handleEmploymentInfoDropDownChange('department', value)
                                                        this.handleEmploymentInfoDropDownChange('region', regionValue)
                                                    }
                                                    else {
                                                        this.handleEmploymentInfoDropDownChange('department', '')
                                                        this.handleEmploymentInfoDropDownChange('region', '')
                                                    }
                                                }} value={this.state.department} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.region)}  >
                                                <ControlLabel>Region</ControlLabel>
                                                <FormControl type="text" name='region' value={this.state.region} placeholder="Region" />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.directReportName)}  >
                                                <ControlLabel>Direct Report</ControlLabel>
                                                <EmployeeDropdown setEmployeeValue={(id, value) => {
                                                    if (value) {
                                                        this.handleEmploymentInfoDropDownChange('directReport', id)
                                                        this.setState({ 'directReportName': value })//This is not in the MongoCollection we only care about the id
                                                    }
                                                    else {
                                                        this.handleEmploymentInfoDropDownChange('directReport', '')
                                                        this.setState({ 'directReportName': '' })
                                                    }
                                                }} value={this.state.directReportName} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText" validationState={this.getValidationState(this.state.locationAddress)}  >
                                                <ControlLabel>Location</ControlLabel>
                                                <LocationDropdown setLocationValue={(id, value) => {
                                                    if (value) {
                                                        this.handleEmploymentInfoDropDownChange('location', id)
                                                        this.setState({ 'locationAddress': value })//This is not in the MongoCollection we only care about the id
                                                    }
                                                    else {
                                                        this.handleEmploymentInfoDropDownChange('location', '')
                                                        this.setState({ 'locationAddress': '' })
                                                    }
                                                }} value={this.state.locationAddress} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText">
                                                <ControlLabel>Status</ControlLabel>
                                                <br />
                                                <EmploymentStatusDropdown setStatusValue={(value) => {
                                                    if (value)
                                                        this.handleEmploymentInfoDropDownChange('employmentStatus', value)
                                                    else
                                                        this.handleEmploymentInfoDropDownChange('employmentStatus', '')
                                                }} value={this.state.employmentStatus} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText">
                                                <ControlLabel>Offer Letter Sent Date</ControlLabel>
                                                {this.state.offerLetterSentDate &&
                                                    <DatePickerInput
                                                        onChange={this.handleOfferLetterDateChange}
                                                        value={this.state.offerLetterSentDate}
                                                        className='my-custom-datepicker-component'
                                                    />}
                                                {!this.state.offerLetterSentDate &&
                                                    <FormControl type="text" value='' placeholder="Populated Once Offer Letter Email Sent" />}
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText">
                                                <ControlLabel>Estimated Start Date</ControlLabel>
                                                <DatePickerInput
                                                    onChange={this.handleStartDateChange}
                                                    value={this.state.estimatedStartDate}
                                                    className='my-custom-datepicker-component'
                                                /> <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>Wage</ControlLabel>
                                                <FormControl type="text" name='wage' value={this.state.wage} placeholder="Wage" onChange={this.handleHiringInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>NMLS #</ControlLabel>
                                                <FormControl type="text" name='nmls' value={this.state.nmls} placeholder="NMLS" onChange={this.handleEmploymentInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Panel.Collapse>
                            </Panel>

                            <span style={{ fontSize: 20, fontWeight: 'normal' }} onClick={() => this.setState({ openContact: !this.state.openContact, openPosition: false, openDocuments: false })}>Contact Information
                                        &nbsp;{this.state.openContact && <FontAwesome.FaArrowCircleDown />}{!this.state.openContact && <FontAwesome.FaArrowCircleRight />}
                            </span>
                            <br />
                            <Panel id="collapsible-panel-example-1" expanded={this.state.openContact}>
                                <Panel.Collapse>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>Email</ControlLabel>
                                                <FormControl type="text" name='workEmail' value={this.state.workEmail} placeholder="Email" onChange={this.handleEmploymentInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} sm={12}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>Phone</ControlLabel>
                                                <FormControl type="text" name='workPhone' value={this.state.workPhone} placeholder="Phone" onChange={this.handleEmploymentInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup> </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <FormGroup controlId="formBasicText"  >
                                                <ControlLabel>Website</ControlLabel>
                                                <FormControl type="text" name='website' value={this.state.website} placeholder="Website" onChange={this.handleEmploymentInfoInputChange} />
                                                <FormControl.Feedback />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                </Panel.Collapse>
                            </Panel>

                        </Grid>
                        <br />

                    </form>
                    <br />

                    <div id='error'></div>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        hrInfo: state.hrReducer
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllHRData: getAllHRData,
            upsertHRData: upsertHRData,
            setSelectedApplicant: setSelectedApplicant,
            goToAnotherPage: (params) => push(params)
        }
        , dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(RolesForm);
